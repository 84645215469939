/* File: frontend/src/pages/EmbeddedInsurance/components/ProductBuilder/styles/UnderwritingRules.module.css */

.underwritingRules {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-lg);
}

.sectionHeader {
  text-align: center;
  margin-bottom: var(--spacing-lg);
}

.sectionTitle {
  font-size: var(--font-size-xl);
  font-weight: var(--font-weight-bold);
  color: var(--text-primary);
  margin-bottom: var(--spacing-sm);
}

.sectionDescription {
  color: var(--text-secondary);
  max-width: 600px;
  margin: 0 auto;
}

.rulesContainer {
  display: grid;
  grid-template-columns: 300px 1fr;
  gap: var(--spacing-lg);
  height: 500px;
}

.rulesList {
  border: 1px solid var(--color-border);
  border-radius: var(--border-radius-md);
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.rulesHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: var(--spacing-md);
  background-color: var(--bg-secondary);
  border-bottom: 1px solid var(--color-border);
}

.rulesTitle {
  font-size: var(--font-size-md);
  font-weight: var(--font-weight-semibold);
  color: var(--text-primary);
  margin: 0;
}

.addRuleButton {
  display: flex;
  align-items: center;
  gap: var(--spacing-xs);
  padding: var(--spacing-xs) var(--spacing-sm);
  background-color: var(--color-primary);
  color: white;
  border: none;
  border-radius: var(--border-radius-sm);
  font-size: var(--font-size-xs);
  cursor: pointer;
  transition: background-color var(--transition-fast);
}

.addRuleButton:hover {
  background-color: var(--color-primary-dark);
}

.rulesListItems {
  flex: 1;
  overflow-y: auto;
  padding: var(--spacing-sm);
}

.ruleItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: var(--spacing-sm);
  border: 1px solid var(--color-border);
  border-radius: var(--border-radius-md);
  margin-bottom: var(--spacing-sm);
  cursor: pointer;
  transition: all var(--transition-fast);
}

.ruleItem:hover {
  border-color: var(--color-primary-light);
  background-color: var(--bg-hover);
}

.activeRule {
  border-color: var(--color-primary);
  background-color: var(--color-primary-50);
}

.ruleInfo {
  flex: 1;
}

.ruleType {
  font-weight: var(--font-weight-medium);
  color: var(--text-primary);
  margin-bottom: var(--spacing-xxs);
}

.rulePreview {
  font-size: var(--font-size-sm);
  color: var(--text-secondary);
}

.actionBadge {
  display: inline-block;
  padding: 2px 6px;
  border-radius: var(--border-radius-sm);
  font-size: var(--font-size-xs);
  font-weight: var(--font-weight-medium);
  text-transform: uppercase;
}

.decline {
  background-color: var(--color-error-bg);
  color: var(--color-error);
}

.refer {
  background-color: var(--color-warning-bg);
  color: var(--color-warning);
}

.approve {
  background-color: var(--color-success-bg);
  color: var(--color-success);
}

.ruleActions {
  display: flex;
  gap: var(--spacing-xs);
}

.editButton, .deleteButton {
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: var(--border-radius-sm);
  border: none;
  cursor: pointer;
  transition: all var(--transition-fast);
}

.editButton {
  background-color: var(--color-primary-bg);
  color: var(--color-primary);
}

.editButton:hover {
  background-color: var(--color-primary);
  color: white;
}

.deleteButton {
  background-color: var(--color-error-bg);
  color: var(--color-error);
}

.deleteButton:hover {
  background-color: var(--color-error);
  color: white;
}

.ruleDetails {
  border: 1px solid var(--color-border);
  border-radius: var(--border-radius-md);
  overflow: hidden;
  padding: var(--spacing-lg);
  background-color: var(--bg-primary);
}

.noRuleSelected {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: var(--text-tertiary);
  gap: var(--spacing-md);
}

.noRuleSelected i {
  font-size: var(--font-size-2xl);
}

.viewRuleTitle, .editRuleTitle {
  font-size: var(--font-size-lg);
  font-weight: var(--font-weight-semibold);
  color: var(--text-primary);
  margin-bottom: var(--spacing-lg);
}

.rulePreview {
  margin-bottom: var(--spacing-lg);
}

.editRuleForm {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-lg);
}

.ruleEditor {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-md);
}

.formGroup {
  margin-bottom: var(--spacing-md);
}

.formRow {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: var(--spacing-md);
}

.formLabel {
  display: block;
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-medium);
  color: var(--text-secondary);
  margin-bottom: var(--spacing-xs);
}

.formInput, .formSelect {
  width: 100%;
  padding: var(--spacing-sm);
  border: 1px solid var(--color-border);
  border-radius: var(--border-radius-md);
  font-size: var(--font-size-base);
}

.editActions {
  display: flex;
  justify-content: flex-end;
  gap: var(--spacing-md);
  margin-top: var(--spacing-lg);
}

.cancelButton, .saveButton {
  padding: var(--spacing-sm) var(--spacing-lg);
  border-radius: var(--border-radius-md);
  font-size: var(--font-size-base);
  font-weight: var(--font-weight-medium);
  cursor: pointer;
  transition: all var(--transition-fast);
}

.cancelButton {
  background-color: var(--bg-secondary);
  color: var(--text-primary);
  border: 1px solid var(--color-border);
}

.cancelButton:hover {
  background-color: var(--bg-hover);
}

.saveButton {
  background-color: var(--color-primary);
  color: white;
  border: none;
}

.saveButton:hover {
  background-color: var(--color-primary-dark);
}

.addRuleForm {
  padding: var(--spacing-md);
  background-color: var(--bg-secondary);
  border-bottom: 1px solid var(--color-border);
}

.addRuleTitle {
  font-size: var(--font-size-md);
  font-weight: var(--font-weight-semibold);
  color: var(--text-primary);
  margin-bottom: var(--spacing-md);
}

.ruleTemplates {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-sm);
  margin-bottom: var(--spacing-md);
}

.ruleTemplate {
  padding: var(--spacing-sm);
  background-color: var(--bg-primary);
  border: 1px solid var(--color-border);
  border-radius: var(--border-radius-md);
  cursor: pointer;
  transition: all var(--transition-fast);
}

.ruleTemplate:hover {
  border-color: var(--color-primary);
  background-color: var(--color-primary-50);
}

.templateName {
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-semibold);
  color: var(--text-primary);
  margin-bottom: var(--spacing-xxs);
}

.templateDescription {
  font-size: var(--font-size-xs);
  color: var(--text-secondary);
  margin: 0;
}

@media (max-width: 768px) {
  .rulesContainer {
    grid-template-columns: 1fr;
    height: auto;
  }
  
  .rulesList, .ruleDetails {
    height: 300px;
  }
  
  .formRow {
    grid-template-columns: 1fr;
  }
  
  .editActions {
    flex-direction: column;
  }
  
  .cancelButton, .saveButton {
    width: 100%;
  }
}