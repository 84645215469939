/* src/pages/Capabilities/Capabilities.module.css */
.capabilitiesContainer {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
  font-family: 'Instrument Sans', sans-serif;
}

.tableWrapper {
  position: relative; /* Add this */
}

.title {
  font-size: 2.5rem;
  color: #3667F8;
  margin-bottom: 1rem;
  text-align: center;
  padding: 1rem 0;
}

.description {
  margin-bottom: 2rem;
  line-height: 1.6;
}

.tabs {
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-bottom: 2rem;
}

.tab {
  padding: 8px 10px;
  font-size: 14px;
  font-weight: 500;
  border: 1px solid #f7f7f8;
  border-radius: 10px;
  background-color: #fff;
  cursor: pointer;
  transition: all 0.3s ease;
}

.tab:hover {
  background-color: #f0f0f0;
}

.activeTab {
  background-color: #dbe6fe;
  border-color: #dbe6fe;
}

.filters {
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-bottom: 2rem;
}

.filterSelect {
  padding: 0.5rem 1rem;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: white;
  cursor: pointer;
}

.tableContainer {
  overflow-x: auto;
}

.capabilitiesTable {
  width: 100%;
  border-collapse: collapse;
  background-color: #fff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.capabilitiesTable th,
.capabilitiesTable td {
  padding: 1rem;
  text-align: left;
  border-bottom: 1px solid #e0e0e0;
}

.capabilitiesTable th {
  background-color: #f5f5f5;
  font-weight: 600;
  color: #333;
  text-align: center;
}

.capabilitiesTable thead {
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: #f5f5f5;
}

.capabilitiesTable tr:nth-child(even) {
  background-color: #f9f9f9;
}

.capabilitiesTable tr:hover {
  background-color: #f0f0f0;
}

@media (max-width: 768px) {
  .tabs {
    flex-wrap: wrap;
  }

  .tab {
    flex: 1 1 calc(50% - 0.5rem);
  }

  .filters {
    flex-direction: column;
  }

  .capabilitiesTable th,
  .capabilitiesTable td {
    padding: 0.5rem;
    font-size: 0.9rem;
  }
}