/* File: frontend/src/pages/Auth/SignOutInactivity.module.css */

.signOutContainer,
.inactivityContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: calc(100vh - var(--header-height) - var(--spacing-2xl));
  padding: var(--spacing-xl);
  box-sizing: border-box;
}

.signOutContent,
.inactivityContent {
  width: 100%;
  max-width: 480px;
  margin: 0 auto;
  animation: fadeIn 0.3s ease-out;
}

.signOutInfo,
.inactivityInfo {
  margin-top: var(--spacing-lg);
  width: 100%;
}

.lastActiveTime {
  background-color: var(--color-warning-bg);
  border: 1px solid var(--color-warning-border);
  border-radius: var(--border-radius-lg);
  padding: var(--spacing-md);
  margin-bottom: var(--spacing-md);
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xxs);
}

.infoLabel {
  color: var(--color-warning);
  font-size: var(--font-size-xs);
  font-weight: var(--font-weight-medium);
  text-transform: uppercase;
}

.infoValue {
  color: var(--text-primary);
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-medium);
}

.countdownWrapper {
  text-align: center;
}

.countdown {
  width: 100%;
  height: 4px;
  background-color: var(--bg-secondary);
  border-radius: var(--border-radius-full);
  overflow: hidden;
  margin-bottom: var(--spacing-sm);
}

.countdownBar {
  height: 100%;
  background-color: var(--color-warning);
  border-radius: var(--border-radius-full);
  transition: width 1s linear;
}

.countdownText {
  color: var(--text-secondary);
  font-size: var(--font-size-sm);
  animation: pulse 2s infinite;
}

/* Responsive styles */
@media (min-width: 1200px) {
  .signOutContainer,
  .inactivityContainer {
    padding: var(--spacing-2xl);
  }

  .signOutContent,
  .inactivityContent {
    max-width: 520px;
  }
}

@media (max-width: 768px) {
  .signOutContainer,
  .inactivityContainer {
    padding: var(--spacing-lg);
  }

  .lastActiveTime {
    padding: var(--spacing-sm);
  }
}

@media (max-width: 480px) {
  .signOutContainer,
  .inactivityContainer {
    padding: var(--spacing-md);
  }

  .lastActiveTime {
    padding: var(--spacing-xs);
  }

  .infoLabel,
  .infoValue,
  .countdownText {
    font-size: var(--font-size-xs);
  }
}

/* Animations */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes pulse {
  0%, 100% { opacity: 1; }
  50% { opacity: 0.7; }
}

/* Print styles */
@media print {
  .signOutContainer,
  .inactivityContainer {
    min-height: auto;
    padding: 0;
  }

  .countdownWrapper {
    display: none;
  }
}

/* Dark mode support */
@media (prefers-color-scheme: dark) {
  .lastActiveTime {
    background-color: var(--color-warning-bg);
    border-color: var(--color-warning-border);
  }

  .countdown {
    background-color: var(--bg-tertiary);
  }
}