/* File: /frontend/src/pages/Quote/Quote.module.css */

.quotePage {
    width: 100%;
    max-width: 100vw;
    min-height: 100vh;
    background-color: var(--color-black);
    color: var(--color-white);
    font-family: var(--font-primary);
    /* Remove this line that was causing issues */
    /* overflow-x: hidden; */
    position: relative;
}

/* Chat Section Styles */
/* .chatSection {
  padding: 0;
  margin: 0;
  width: 100%;
  transition: opacity 0.3s ease;
}
  
.chatSection.visible {
    opacity: 1;
    transform: translateY(0);
}
  
.chatSection::before {
    content: '';
    position: absolute;
    top: -50px;
    left: 0;
    width: 100%;
    height: 50px;
    background: linear-gradient(to bottom, transparent, var(--color-white));
    z-index: 1;
} */
  
.chatContainer {
    max-width: 800px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%; /* Ensure it takes full width of its container */
    padding: 0 var(--spacing-sm); /* Add some padding for smaller screens */
}
  
.aiLogoWrapper {
    display: flex;
    justify-content: center;
    margin-bottom: var(--spacing-md);
}
  
.aiLogo {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 48px;
    height: 48px;
    background-color: var(--color-primary);
    color: var(--color-white);
    border-radius: 50%;
    font-size: 18px;
    font-weight: var(--font-weight-semibold);
}
  
.sectionTitle {
    font-size: var(--font-size-2xl);
    font-weight: var(--font-weight-bold);
    margin-bottom: var(--spacing-sm);
    text-align: center;
    color: var(--text-primary);
}
  
.sectionSubtitle {
    font-size: var(--font-size-lg);
    color: var(--text-secondary);
    margin-bottom: var(--spacing-xl);
    text-align: center;
    max-width: 600px;
    padding: 0 var(--spacing-sm); /* Add some padding for smaller screens */
}
  
.securityNote {
    display: flex;
    align-items: center;
    margin-top: var(--spacing-md);
    padding: var(--spacing-xs) var(--spacing-md);
    background-color: var(--bg-secondary);
    border-radius: var(--border-radius-md);
    max-width: 500px;
    width: 100%; /* Take full width on smaller screens */
}
  
.securityIcon {
    font-size: 20px;
    margin-right: var(--spacing-sm);
    flex-shrink: 0; /* Prevent icon from shrinking */
}
  
.securityNote p {
    font-size: var(--font-size-sm);
    color: var(--text-secondary);
    margin: 0;
}
  
/* Features Section Styles */
.featuresSection {
    padding: var(--spacing-3xl) var(--spacing-md);
    background-color: var(--color-gray-1300);
    position: relative;
}

/* Animation classes for sections that come into view */
.featuresSection,
.testimonialsSection {
  opacity: 0;
  transform: translateY(30px);
  transition: opacity 0.6s ease, transform 0.6s ease;
}

.featuresSection.visible,
.testimonialsSection.visible {
  opacity: 1;
  transform: translateY(0);
}

/* When chat is open, ensure no background interaction */
body.chat-open {
  overflow: hidden;
  /* This prevents iOS Safari bouncing effect */
  position: fixed;
  width: 100%;
  height: 100%;
}
  
.sectionHeader {
    text-align: center;
    margin-bottom: var(--spacing-2xl);
    padding: 0 var(--spacing-sm); /* Add some padding for smaller screens */
}
  
.sectionHeader h2 {
    font-size: var(--font-size-3xl);
    font-weight: var(--font-weight-bold);
    margin-bottom: var(--spacing-sm);
    color: var(--color-white);
}
  
.sectionHeader p {
    font-size: var(--font-size-lg);
    color: rgba(255, 255, 255, 0.7);
    max-width: 600px;
    margin: 0 auto;
}
  
.featureCards {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
    gap: var(--spacing-lg);
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 var(--spacing-sm); /* Add some padding for smaller screens */
}
  
.featureCard {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: var(--spacing-lg);
    background-color: rgba(255, 255, 255, 0.05);
    border-radius: var(--border-radius-lg);
    border: 1px solid rgba(255, 255, 255, 0.1);
    text-align: center;
    transition: transform var(--transition-normal), 
                box-shadow var(--transition-normal),
                background-color var(--transition-normal);
    height: 100%; /* Ensure all cards in a row have the same height */
}
  
.featureCard:hover {
    transform: translateY(-5px);
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.3);
    background-color: rgba(255, 255, 255, 0.08);
}
  
.featureIcon {
    font-size: 36px;
    margin-bottom: var(--spacing-md);
}
  
.featureCard h3 {
    font-size: var(--font-size-lg);
    margin-bottom: var(--spacing-sm);
    color: var(--color-white);
    font-weight: var(--font-weight-semibold);
}
  
.featureCard p {
    font-size: var(--font-size-base);
    color: rgba(255, 255, 255, 0.7);
    line-height: var(--line-height-normal);
    /* Add this to push content to the top and create equal-height cards */
    margin-top: auto;
}
  
/* Testimonials Section Styles */
.testimonialsSection {
    padding: var(--spacing-3xl) var(--spacing-md);
    background-color: var(--color-black);
    text-align: center;
    position: relative;
    overflow: hidden;
}
  
.testimonialsSection::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: radial-gradient(circle at center, rgba(35, 72, 237, 0.05) 0%, transparent 70%);
    pointer-events: none;
}
  
.testimonials {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
    gap: var(--spacing-lg);
    max-width: 1200px;
    margin: var(--spacing-xl) auto 0;
    padding: 0 var(--spacing-sm); /* Add some padding for smaller screens */
}
  
.testimonial {
    display: flex;
    flex-direction: column;
    padding: var(--spacing-lg);
    background-color: rgba(255, 255, 255, 0.03);
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: var(--border-radius-lg);
    transition: transform var(--transition-normal);
    height: 100%; /* Ensure all testimonials in a row have the same height */
}
  
.testimonial:hover {
    transform: translateY(-5px);
}
  
.quote {
    font-size: var(--font-size-lg);
    line-height: var(--line-height-relaxed);
    margin-bottom: var(--spacing-md);
    font-style: italic;
    color: rgba(255, 255, 255, 0.9);
    flex-grow: 1; /* Allow quotes to fill available space */
}
  
.quote::before {
    content: '"';
    font-size: 48px;
    line-height: 0;
    vertical-align: -0.4em;
    color: var(--color-tertiary);
    opacity: 0.5;
    margin-right: 4px;
}
  
.author {
    font-weight: var(--font-weight-semibold);
    color: var(--color-tertiary);
    margin-top: auto; /* Push author to the bottom of the card */
}
  
/* Responsive adjustments */
@media (max-width: 992px) {
    .featureCards, .testimonials {
        grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    }
}

@media (max-width: 768px) {
    /* .chatSection, */
    .featuresSection,
    .testimonialsSection {
        padding: var(--spacing-2xl) var(--spacing-md);
    }
    
    .sectionHeader h2 {
        font-size: var(--font-size-2xl);
    }
    
    .sectionHeader p {
        font-size: var(--font-size-base);
    }
    
    .featureCards, .testimonials {
        grid-template-columns: 1fr; /* Switch to single column on mobile */
        gap: var(--spacing-md);
    }
    
    .quote {
        font-size: var(--font-size-base);
    }
    
    .securityNote {
        flex-direction: column;
        text-align: center;
        padding: var(--spacing-sm);
    }
    
    .securityIcon {
        margin-right: 0;
        margin-bottom: var(--spacing-xs);
    }
}
  
@media (max-width: 480px) {
    /* .chatSection, */
    .featuresSection,
    .testimonialsSection {
        padding: var(--spacing-xl) var(--spacing-xs);
    }
    
    .sectionTitle {
        font-size: var(--font-size-xl);
    }
    
    .sectionSubtitle {
        font-size: var(--font-size-base);
        padding: 0 var(--spacing-xs);
    }
    
    .sectionHeader h2 {
        font-size: var(--font-size-xl);
    }
    
    .featureIcon {
        font-size: 30px;
    }
    
    .featureCard, .testimonial {
        padding: var(--spacing-md);
    }
}

/* Add support for ultra-wide screens */
@media (min-width: 1800px) {
    .featureCards, .testimonials {
        grid-template-columns: repeat(3, 1fr);
        max-width: 1400px;
    }
    
    .chatContainer {
        max-width: 1000px;
    }
}