/* File: frontend/src/pages/Status/components/StatusOverview.module.css */

.overview {
    background: var(--bg-primary);
    border-radius: var(--border-radius-lg);
    padding: var(--spacing-md);
    margin-bottom: var(--spacing-md);
    box-shadow: var(--shadow-sm);
}

.header {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-md);
}

.statusInfo {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: var(--spacing-sm);
}

.statusInfo h1 {
    margin: 0;
    font-size: var(--font-size-xl);
    font-weight: var(--font-weight-semibold);
    color: var(--text-primary);
}

.status {
    display: flex;
    align-items: center;
    gap: var(--spacing-xxs);
    padding: var(--spacing-xxs) var(--spacing-sm);
    border-radius: var(--border-radius-full);
    font-weight: var(--font-weight-medium);
}

.statusIndicator {
    width: var(--spacing-xxxs);
    height: var(--spacing-xxxs);
    border-radius: var(--border-radius-full);
}

.statusOk {
    background-color: var(--color-success-bg);
    color: var(--color-success);
}

.statusOk .statusIndicator {
    background-color: var(--color-success);
}

.statusDegraded {
    background-color: var(--color-warning-bg);
    color: var(--color-warning);
}

.statusDegraded .statusIndicator {
    background-color: var(--color-warning);
}

.statusError {
    background-color: var(--color-error-bg);
    color: var(--color-error);
}

.statusError .statusIndicator {
    background-color: var(--color-error);
}

.statusUnknown {
    background-color: var(--bg-secondary);
    color: var(--text-secondary);
}

.statusUnknown .statusIndicator {
    background-color: var(--text-tertiary);
}

.metadata {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: var(--spacing-sm);
    padding-top: var(--spacing-sm);
    border-top: 1px solid var(--color-border);
}

.metadataItem {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-xxxs);
}

.label {
    font-size: var(--font-size-xs);
    color: var(--text-secondary);
    text-transform: uppercase;
    font-weight: var(--font-weight-semibold);
}

.value {
    font-size: var(--font-size-sm);
    color: var(--text-primary);
}

.loading {
    padding: var(--spacing-lg);
    text-align: center;
    color: var(--text-secondary);
    background: var(--bg-primary);
    border-radius: var(--border-radius-lg);
}

@media (max-width: 768px) {
    .statusInfo {
        flex-direction: column;
        align-items: flex-start;
        gap: var(--spacing-sm);
    }

    .metadata {
        grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    }
}