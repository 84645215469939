/* File: frontend/src/pages/InvestorPitch/components/slides/TechnologySlide/TechCard.module.css */

.card {
    background-color: var(--color-gray-1200);
    border-radius: var(--border-radius-lg);
    padding: var(--spacing-xl);
    border: 1px solid var(--color-gray-1100);
    display: flex;
    align-items: flex-start;
    gap: var(--spacing-lg);
    height: 100%;
  }
  
  .iconWrapper {
    width: 56px;
    height: 56px;
    border-radius: var(--border-radius-lg);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
  }
  
  .icon {
    width: 28px;
    height: 28px;
    color: var(--color-white);
  }
  
  .content {
    flex: 1;
  }
  
  .title {
    font-size: var(--font-size-xl);
    font-weight: var(--font-weight-bold);
    color: var(--text-on-primary);
    margin-bottom: var(--spacing-sm);
  }
  
  .description {
    font-size: var(--font-size-base);
    color: var(--color-gray-400);
    line-height: 1.5;
  }
  
  @media (max-width: 768px) {
    .card {
      padding: var(--spacing-lg);
    }
  
    .iconWrapper {
      width: 48px;
      height: 48px;
    }
  
    .icon {
      width: 24px;
      height: 24px;
    }
  
    .title {
      font-size: var(--font-size-lg);
    }
  
    .description {
      font-size: var(--font-size-sm);
    }
  }