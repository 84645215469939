/* File: frontend/src/pages/SuperAdmin/components/Dashboard/Dashboard.module.css */

.dashboard {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-lg);
}

.title {
  font-size: var(--font-size-2xl);
  font-weight: var(--font-weight-bold);
  color: var(--text-primary);
  margin: 0 0 var(--spacing-md) 0;
}

.statsGrid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: var(--spacing-md);
}

.activitySection, .quickActions {
  background-color: var(--bg-primary);
  border-radius: var(--border-radius-md);
  padding: var(--spacing-md);
  box-shadow: var(--shadow-sm);
}

.sectionTitle {
  font-size: var(--font-size-lg);
  font-weight: var(--font-weight-semibold);
  color: var(--text-primary);
  margin: 0 0 var(--spacing-md) 0;
  padding-bottom: var(--spacing-xs);
  border-bottom: 1px solid var(--color-border);
}

.activityList {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-sm);
}

.activityItem {
  display: flex;
  align-items: flex-start;
  padding: var(--spacing-sm);
  border-radius: var(--border-radius-sm);
  background-color: var(--bg-secondary);
  transition: background-color var(--transition-fast);
}

.activityItem:hover {
  background-color: var(--bg-hover);
}

.activityIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background-color: var(--color-primary-100);
  color: var(--color-primary);
  margin-right: var(--spacing-sm);
  flex-shrink: 0;
}

.activityContent {
  flex: 1;
}

.activityText {
  font-size: var(--font-size-sm);
  color: var(--text-primary);
  margin-bottom: var(--spacing-xxs);
}

.activityTime {
  font-size: var(--font-size-xs);
  color: var(--text-tertiary);
}

.viewAllContainer {
  display: flex;
  justify-content: center;
  margin-top: var(--spacing-md);
}

.viewAllButton {
  padding: var(--spacing-xs) var(--spacing-md);
  background-color: transparent;
  color: var(--color-primary);
  border: 1px solid var(--color-primary);
  border-radius: var(--border-radius-md);
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-medium);
  cursor: pointer;
  transition: all var(--transition-fast);
}

.viewAllButton:hover {
  background-color: var(--color-primary-100);
}

.actionButtons {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: var(--spacing-md);
}

.actionButton {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: var(--spacing-md);
  background-color: var(--bg-secondary);
  border: 1px solid var(--color-border);
  border-radius: var(--border-radius-md);
  color: var(--text-primary);
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-medium);
  cursor: pointer;
  transition: all var(--transition-fast);
}

.actionButton:hover {
  background-color: var(--bg-hover);
  border-color: var(--color-primary);
  color: var(--color-primary);
}

.actionButton i {
  font-size: var(--font-size-xl);
  margin-bottom: var(--spacing-xs);
}

@media (max-width: 1200px) {
  .statsGrid {
    grid-template-columns: repeat(3, 1fr);
  }
  
  .actionButtons {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .statsGrid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 480px) {
  .statsGrid, .actionButtons {
    grid-template-columns: 1fr;
  }
}