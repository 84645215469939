/* File: frontend/src/pages/InvestorPitch/components/slides/TechnicalArchitectureSlide/TechnicalArchitectureSlide.module.css */

.container {
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: var(--spacing-xl);
  }
  
  .header {
    text-align: center;
    margin-bottom: var(--spacing-xl);
  }
  
  .title {
    font-size: var(--font-size-4xl);
    font-weight: var(--font-weight-bold);
    color: var(--text-on-primary);
    margin-bottom: var(--spacing-md);
  }
  
  .subtitle {
    font-size: var(--font-size-xl);
    color: var(--color-gray-400);
  }
  
  .content {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-3xl);
  }
  
  .sectionTitle {
    font-size: var(--font-size-2xl);
    font-weight: var(--font-weight-bold);
    color: var(--text-on-primary);
    margin-bottom: var(--spacing-xl);
  }
  
  .metricsGrid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: var(--spacing-xl);
  }
  
  .deploymentGrid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: var(--spacing-xl);
  }
  
  @media (max-width: 768px) {
    .metricsGrid,
    .deploymentGrid {
      grid-template-columns: 1fr;
      gap: var(--spacing-lg);
    }
  }