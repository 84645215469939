/* File: frontend/src/pages/Auth/VerificationFailure.module.css */

.verificationContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: calc(100vh - var(--header-height) - var(--spacing-2xl));
  padding: var(--spacing-xl);
  box-sizing: border-box;
}

.verificationContent {
  width: 100%;
  max-width: 480px;
  margin: 0 auto;
  animation: fadeIn 0.3s ease-out;
}

/* Success specific styles */
.successInfo {
  margin-top: var(--spacing-lg);
  width: 100%;
}

.verificationDetails {
  background-color: var(--color-success-bg);
  border: 1px solid var(--color-success-border);
  border-radius: var(--border-radius-lg);
  padding: var(--spacing-md);
  margin-bottom: var(--spacing-md);
}

/* Failure specific styles */
.failureInfo {
  margin-top: var(--spacing-lg);
  width: 100%;
}

.errorDetails {
  background-color: var(--color-error-bg);
  border: 1px solid var(--color-error-border);
  border-radius: var(--border-radius-lg);
  padding: var(--spacing-md);
  margin-bottom: var(--spacing-md);
}

/* Shared info styles */
.infoItem,
.errorItem {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xxs);
  margin-bottom: var(--spacing-sm);
}

.infoItem:last-child,
.errorItem:last-child {
  margin-bottom: 0;
}

.infoLabel,
.errorLabel {
  font-size: var(--font-size-xs);
  font-weight: var(--font-weight-medium);
  text-transform: uppercase;
}

.infoLabel {
  color: var(--color-success);
}

.errorLabel {
  color: var(--color-error);
}

.infoValue,
.errorValue {
  color: var(--text-primary);
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-medium);
  word-break: break-all;
}

/* Countdown styles */
.countdownWrapper {
  text-align: center;
  margin-bottom: var(--spacing-md);
}

.countdown {
  width: 100%;
  height: 4px;
  background-color: var(--bg-secondary);
  border-radius: var(--border-radius-full);
  overflow: hidden;
  margin-bottom: var(--spacing-sm);
}

.countdownBar {
  height: 100%;
  background-color: var(--color-success);
  border-radius: var(--border-radius-full);
  transition: width 1s linear;
}

.countdownText {
  color: var(--text-secondary);
  font-size: var(--font-size-sm);
  animation: pulse 2s infinite;
}

/* Button styles */
.actionButtons {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-sm);
}

.continueButton,
.contactButton,
.tryAgainButton {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: var(--spacing-sm);
  padding: var(--spacing-sm) var(--spacing-md);
  border-radius: var(--border-radius-md);
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-medium);
  transition: all var(--transition-normal);
  cursor: pointer;
}

.continueButton,
.contactButton {
  background-color: var(--color-primary);
  color: var(--color-white);
  border: none;
}

.tryAgainButton {
  background-color: var(--bg-primary);
  color: var(--text-primary);
  border: 1px solid var(--color-border);
}

.continueButton:hover,
.contactButton:hover {
  background-color: var(--color-primary-dark);
}

.tryAgainButton:hover {
  background-color: var(--bg-secondary);
  border-color: var(--color-border-hover);
}

.spinner {
  width: 16px;
  height: 16px;
  border: 2px solid var(--color-white);
  border-top-color: transparent;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

/* Responsive styles */
@media (min-width: 1200px) {
  .verificationContainer {
    padding: var(--spacing-2xl);
  }

  .verificationContent {
    max-width: 520px;
  }
}

@media (max-width: 768px) {
  .verificationContainer {
    padding: var(--spacing-lg);
  }

  .verificationDetails,
  .errorDetails {
    padding: var(--spacing-sm);
  }
}

@media (max-width: 480px) {
  .verificationContainer {
    padding: var(--spacing-md);
  }

  .verificationDetails,
  .errorDetails {
    padding: var(--spacing-xs);
  }

  .infoLabel,
  .errorLabel,
  .infoValue,
  .errorValue,
  .countdownText {
    font-size: var(--font-size-xs);
  }
}

/* Animations */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes pulse {
  0%, 100% { opacity: 1; }
  50% { opacity: 0.7; }
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

/* Print styles */
@media print {
  .verificationContainer {
    min-height: auto;
    padding: 0;
  }

  .countdownWrapper,
  .actionButtons {
    display: none;
  }
}

/* Dark mode support */
@media (prefers-color-scheme: dark) {
  .verificationDetails {
    background-color: var(--color-success-bg);
    border-color: var(--color-success-border);
  }

  .errorDetails {
    background-color: var(--color-error-bg);
    border-color: var(--color-error-border);
  }

  .countdown {
    background-color: var(--bg-tertiary);
  }
}