/* File: frontend/src/pages/AI1/components/ChatInput/ChatInput.module.css */

.inputContainer {
  padding: var(--spacing-md) var(--spacing-md) 0 var(--spacing-md);
  position: sticky;
  bottom: 0;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-lg);
  transition: transform 0.5s ease-out;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
}

.welcomeSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--spacing-xl);
  margin-bottom: var(--spacing-xl);
}

.greeting {
  color: var(--color-dark-gray);
  font-size: var(--font-size-xl);
  text-align: center;
  font-weight: var(--font-weight-medium);
}
  
.form {
  display: flex;
  gap: var(--spacing-sm);
  align-items: flex-end;
  max-width: 800px;
  margin: 0 auto;
  width: 100%;
  position: relative;
}

.input {
  flex: 1;
  padding: var(--spacing-md) var(--spacing-lg);
  border: 1px solid var(--color-border);
  border-radius: var(--border-radius-xl); /* Increased border radius */
  resize: none;
  min-height: 60px; /* Increased height */
  max-height: 200px;
  font-family: inherit;
  font-size: var(--font-size-md);
  line-height: 1.5;
  background-color: var(--color-white);
  box-shadow: var(--shadow-sm);
}

.input:focus {
  outline: none;
  border-color: var(--color-primary);
  box-shadow: var(--focus-ring);
}

.sendButton {
  background-color: var(--color-primary);
  border: none;
  border-radius: var(--border-radius-full);
  width: 44px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color var(--transition-fast);
}

.sendButton:hover {
  background-color: var(--color-primary-dark);
}

.sendButton img {
  filter: brightness(0) invert(1);
}

.selectors {
  display: flex;
  gap: var(--spacing-sm);
  align-items: center;
  max-width: 800px;
  margin: 0 auto;
  width: 100%;
  padding-left: var(--spacing-xl); /* Indent from the left */
  margin-top: calc(-1 * var(--spacing-xs)); /* Pull up slightly */
}

.selectorItem {
  position: relative;
}

.selectorButton {
  padding: var(--spacing-xs) var(--spacing-sm);
  border: 1px solid var(--color-border);
  border-radius: var(--border-radius-md);
  background-color: var(--color-white);
  color: var(--text-secondary);
  cursor: pointer;
  font-size: var(--font-size-sm);
  transition: all var(--transition-fast);
  display: flex;
  align-items: center;
  gap: var(--spacing-xs);
}
.selectorButton::before {
  content: '';
  display: inline-block;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: var(--text-secondary);
}

.selectorButton:hover {
  background-color: var(--bg-hover);
  color: var(--text-primary);
}

.trainingButton {
  padding: var(--spacing-xs) var(--spacing-sm);
  border: none;
  background: none;
  color: var(--color-primary);
  cursor: pointer;
  font-size: var(--font-size-sm);
  margin-left: auto; /* Push to the right */
}

.trainingButton:hover {
  color: var(--color-primary-dark);
}

.configText {
  text-align: center;
  color: var(--text-secondary);
  font-size: var(--font-size-sm);
  margin-top: var(--spacing-sm);
  padding: 0 var(--spacing-md);
}

.inlineSelector {
  background: none;
  border: none;
  padding: 2px 6px;
  margin: 0 2px;
  color: var(--color-primary);
  font-weight: var(--font-weight-medium);
  cursor: pointer;
  border-radius: var(--border-radius-sm);
  transition: all var(--transition-fast);
  position: relative;
}

.inlineSelector:hover {
  background-color: var(--color-primary-100);
}

.inlineSelector::after {
  content: '▼';
  font-size: 8px;
  margin-left: 4px;
  position: relative;
  top: -1px;
}
  
/* Optional: Add a subtle animation on hover */
.inlineSelector:hover::after {
  transform: translateY(1px);
}