/* File: frontend/src/components/DrivingScore/AxisSelector.module.css */

.dropdownForm {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 8px;
  }
  
  .label {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 2px;
  }
  
  .label1 {
    position: relative;
    line-height: 22px;
    font-weight: 500;
  }
  
  .icons18Scale {
    width: 14px;
    height: 14px;
    overflow: hidden;
    flex-shrink: 0;
  }
  
  .dropdownBase {
    align-self: stretch;
    box-shadow: 0px 1px 2px rgba(228, 229, 231, 0.24);
    border-radius: 12px;
    background-color: #fff;
    border: 1px solid #e9eaec;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 13px 12px;
    color: #1f2228;
  }
  
  .select {
    flex: 1;
    border: none;
    background: transparent;
    font-size: 14px;
    color: #1f2228;
    outline: none;
    appearance: none;
  }
  
  .inputDropdownChevron {
    width: 12px;
    height: 12px;
  }
  
  .placeholder {
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
  }