/* File: frontend/src/styles/base/utilities.css */

/* Display */
.d-none { display: none; }
.d-inline { display: inline; }
.d-inline-block { display: inline-block; }
.d-block { display: block; }
.d-flex { display: flex; }
.d-inline-flex { display: inline-flex; }

/* Flex utilities */
.flex-row { flex-direction: row; }
.flex-column { flex-direction: column; }
.flex-wrap { flex-wrap: wrap; }
.flex-nowrap { flex-wrap: nowrap; }
.justify-content-start { justify-content: flex-start; }
.justify-content-end { justify-content: flex-end; }
.justify-content-center { justify-content: center; }
.justify-content-between { justify-content: space-between; }
.justify-content-around { justify-content: space-around; }
.align-items-start { align-items: flex-start; }
.align-items-end { align-items: flex-end; }
.align-items-center { align-items: center; }
.align-items-baseline { align-items: baseline; }
.align-items-stretch { align-items: stretch; }

/* Margin utilities */
.m-0 { margin: 0; }
.m-1 { margin: var(--spacing-xxs); }
.m-2 { margin: var(--spacing-xs); }
.m-3 { margin: var(--spacing-sm); }
.m-4 { margin: var(--spacing-md); }
.m-5 { margin: var(--spacing-lg); }
.m-6 { margin: var(--spacing-xl); }

.mt-0 { margin-top: 0; }
.mt-1 { margin-top: var(--spacing-xxs); }
.mt-2 { margin-top: var(--spacing-xs); }
.mt-3 { margin-top: var(--spacing-sm); }
.mt-4 { margin-top: var(--spacing-md); }
.mt-5 { margin-top: var(--spacing-lg); }
.mt-6 { margin-top: var(--spacing-xl); }

.mr-0 { margin-right: 0; }
.mr-1 { margin-right: var(--spacing-xxs); }
.mr-2 { margin-right: var(--spacing-xs); }
.mr-3 { margin-right: var(--spacing-sm); }
.mr-4 { margin-right: var(--spacing-md); }
.mr-5 { margin-right: var(--spacing-lg); }
.mr-6 { margin-right: var(--spacing-xl); }

.mb-0 { margin-bottom: 0; }
.mb-1 { margin-bottom: var(--spacing-xxs); }
.mb-2 { margin-bottom: var(--spacing-xs); }
.mb-3 { margin-bottom: var(--spacing-sm); }
.mb-4 { margin-bottom: var(--spacing-md); }
.mb-5 { margin-bottom: var(--spacing-lg); }
.mb-6 { margin-bottom: var(--spacing-xl); }

.ml-0 { margin-left: 0; }
.ml-1 { margin-left: var(--spacing-xxs); }
.ml-2 { margin-left: var(--spacing-xs); }
.ml-3 { margin-left: var(--spacing-sm); }
.ml-4 { margin-left: var(--spacing-md); }
.ml-5 { margin-left: var(--spacing-lg); }
.ml-6 { margin-left: var(--spacing-xl); }

.mx-0 { margin-left: 0; margin-right: 0; }
.mx-1 { margin-left: var(--spacing-xxs); margin-right: var(--spacing-xxs); }
.mx-2 { margin-left: var(--spacing-xs); margin-right: var(--spacing-xs); }
.mx-3 { margin-left: var(--spacing-sm); margin-right: var(--spacing-sm); }
.mx-4 { margin-left: var(--spacing-md); margin-right: var(--spacing-md); }
.mx-5 { margin-left: var(--spacing-lg); margin-right: var(--spacing-lg); }
.mx-6 { margin-left: var(--spacing-xl); margin-right: var(--spacing-xl); }

.my-0 { margin-top: 0; margin-bottom: 0; }
.my-1 { margin-top: var(--spacing-xxs); margin-bottom: var(--spacing-xxs); }
.my-2 { margin-top: var(--spacing-xs); margin-bottom: var(--spacing-xs); }
.my-3 { margin-top: var(--spacing-sm); margin-bottom: var(--spacing-sm); }
.my-4 { margin-top: var(--spacing-md); margin-bottom: var(--spacing-md); }
.my-5 { margin-top: var(--spacing-lg); margin-bottom: var(--spacing-lg); }
.my-6 { margin-top: var(--spacing-xl); margin-bottom: var(--spacing-xl); }

/* Padding utilities */
.p-0 { padding: 0; }
.p-1 { padding: var(--spacing-xxs); }
.p-2 { padding: var(--spacing-xs); }
.p-3 { padding: var(--spacing-sm); }
.p-4 { padding: var(--spacing-md); }
.p-5 { padding: var(--spacing-lg); }
.p-6 { padding: var(--spacing-xl); }

.pt-0 { padding-top: 0; }
.pt-1 { padding-top: var(--spacing-xxs); }
.pt-2 { padding-top: var(--spacing-xs); }
.pt-3 { padding-top: var(--spacing-sm); }
.pt-4 { padding-top: var(--spacing-md); }
.pt-5 { padding-top: var(--spacing-lg); }
.pt-6 { padding-top: var(--spacing-xl); }

.pr-0 { padding-right: 0; }
.pr-1 { padding-right: var(--spacing-xxs); }
.pr-2 { padding-right: var(--spacing-xs); }
.pr-3 { padding-right: var(--spacing-sm); }
.pr-4 { padding-right: var(--spacing-md); }
.pr-5 { padding-right: var(--spacing-lg); }
.pr-6 { padding-right: var(--spacing-xl); }

.pb-0 { padding-bottom: 0; }
.pb-1 { padding-bottom: var(--spacing-xxs); }
.pb-2 { padding-bottom: var(--spacing-xs); }
.pb-3 { padding-bottom: var(--spacing-sm); }
.pb-4 { padding-bottom: var(--spacing-md); }
.pb-5 { padding-bottom: var(--spacing-lg); }
.pb-6 { padding-bottom: var(--spacing-xl); }

.pl-0 { padding-left: 0; }
.pl-1 { padding-left: var(--spacing-xxs); }
.pl-2 { padding-left: var(--spacing-xs); }
.pl-3 { padding-left: var(--spacing-sm); }
.pl-4 { padding-left: var(--spacing-md); }
.pl-5 { padding-left: var(--spacing-lg); }
.pl-6 { padding-left: var(--spacing-xl); }

.px-0 { padding-left: 0; padding-right: 0; }
.px-1 { padding-left: var(--spacing-xxs); padding-right: var(--spacing-xxs); }
.px-2 { padding-left: var(--spacing-xs); padding-right: var(--spacing-xs); }
.px-3 { padding-left: var(--spacing-sm); padding-right: var(--spacing-sm); }
.px-4 { padding-left: var(--spacing-md); padding-right: var(--spacing-md); }
.px-5 { padding-left: var(--spacing-lg); padding-right: var(--spacing-lg); }
.px-6 { padding-left: var(--spacing-xl); padding-right: var(--spacing-xl); }

.py-0 { padding-top: 0; padding-bottom: 0; }
.py-1 { padding-top: var(--spacing-xxs); padding-bottom: var(--spacing-xxs); }
.py-2 { padding-top: var(--spacing-xs); padding-bottom: var(--spacing-xs); }
.py-3 { padding-top: var(--spacing-sm); padding-bottom: var(--spacing-sm); }
.py-4 { padding-top: var(--spacing-md); padding-bottom: var(--spacing-md); }
.py-5 { padding-top: var(--spacing-lg); padding-bottom: var(--spacing-lg); }
.py-6 { padding-top: var(--spacing-xl); padding-bottom: var(--spacing-xl); }

/* Text alignment */
.text-left { text-align: left; }
.text-center { text-align: center; }
.text-right { text-align: right; }

/* Text transform */
.text-lowercase { text-transform: lowercase; }
.text-uppercase { text-transform: uppercase; }
.text-capitalize { text-transform: capitalize; }

/* Font weight */
.font-light { font-weight: 300; }
.font-normal { font-weight: var(--font-weight-regular); }
.font-medium { font-weight: var(--font-weight-medium); }
.font-semibold { font-weight: var(--font-weight-semibold); }
.font-bold { font-weight: var(--font-weight-bold); }

/* Font size */
.text-xs { font-size: var(--font-size-xs); }
.text-sm { font-size: var(--font-size-sm); }
.text-base { font-size: var(--font-size-base); }
.text-lg { font-size: var(--font-size-lg); }
.text-xl { font-size: var(--font-size-xl); }
.text-2xl { font-size: var(--font-size-2xl); }
.text-3xl { font-size: var(--font-size-3xl); }
.text-4xl { font-size: var(--font-size-4xl); }

/* Line height */
.leading-none { line-height: 1; }
.leading-tight { line-height: var(--line-height-tight); }
.leading-snug { line-height: var(--line-height-snug); }
.leading-normal { line-height: var(--line-height-normal); }
.leading-relaxed { line-height: var(--line-height-relaxed); }
.leading-loose { line-height: var(--line-height-loose); }

/* Colors */
.text-primary { color: var(--text-primary); }
.text-secondary { color: var(--text-secondary); }
.text-tertiary { color: var(--text-tertiary); }
.text-white { color: var(--color-white); }
.text-black { color: var(--color-black); }

.bg-primary { background-color: var(--bg-primary); }
.bg-secondary { background-color: var(--bg-secondary); }
.bg-tertiary { background-color: var(--bg-tertiary); }
.bg-white { background-color: var(--color-white); }
.bg-black { background-color: var(--color-black); }

/* Border radius */
.rounded-none { border-radius: 0; }
.rounded-sm { border-radius: var(--border-radius-sm); }
.rounded-md { border-radius: var(--border-radius-md); }
.rounded-lg { border-radius: var(--border-radius-lg); }
.rounded-xl { border-radius: var(--border-radius-xl); }
.rounded-full { border-radius: var(--border-radius-full); }

/* Opacity */
.opacity-0 { opacity: 0; }
.opacity-25 { opacity: 0.25; }
.opacity-50 { opacity: 0.5; }
.opacity-75 { opacity: 0.75; }
.opacity-100 { opacity: 1; }

/* Cursor */
.cursor-pointer { cursor: pointer; }
.cursor-not-allowed { cursor: not-allowed; }

/* Overflow */
.overflow-auto { overflow: auto; }
.overflow-hidden { overflow: hidden; }
.overflow-visible { overflow: visible; }
.overflow-scroll { overflow: scroll; }

/* Position */
.relative { position: relative; }
.absolute { position: absolute; }
.fixed { position: fixed; }
.sticky { position: sticky; }

/* Width and Height */
.w-full { width: 100%; }
.h-full { height: 100%; }

/* File: frontend/src/styles/base/utilities.css */
/* Z-index */
.z-0 { z-index: 0; }
.z-10 { z-index: 10; }
.z-20 { z-index: 20; }
.z-30 { z-index: 30; }
.z-40 { z-index: 40; }
.z-50 { z-index: 50; }

/* Transitions */
.transition { transition: var(--transition-normal); }
.transition-fast { transition: var(--transition-fast); }
.transition-slow { transition: var(--transition-slow); }

/* Responsiveness */
@media (max-width: 768px) {
  .md\:d-none { display: none; }
  .md\:d-block { display: block; }
  .md\:flex-column { flex-direction: column; }
  /* Add more as needed */
}

@media (max-width: 480px) {
  .sm\:d-none { display: none; }
  .sm\:d-block { display: block; }
  .sm\:flex-column { flex-direction: column; }
  /* Add more as needed */
}