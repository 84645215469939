/* File: frontend/src/pages/UMS/components/UserSearchResults/UserSearchResults.module.css */

.container {
  background-color: var(--bg-primary);
  border-radius: var(--border-radius-lg);
  box-shadow: var(--shadow-card);
  padding: var(--spacing-md);
}

.resultsHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: var(--spacing-md);
}

.title {
  font-size: var(--font-size-lg);
  font-weight: var(--font-weight-semibold);
  color: var(--text-primary);
  margin: 0;
}

.resultCount {
  font-size: var(--font-size-sm);
  color: var(--text-secondary);
}

.loading {
  text-align: center;
  padding: var(--spacing-md);
  color: var(--text-secondary);
}

.resultsList {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-md);
}

.resultCard {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: var(--spacing-md);
  border-radius: var(--border-radius-md);
  border: 1px solid var(--color-border);
  transition: background-color var(--transition-fast);
}

.resultCard:hover {
  background-color: var(--bg-secondary);
}

.resultInfo {
  flex: 1;
}

.resultRow {
  display: flex;
  margin-bottom: var(--spacing-xs);
}

.resultRow:last-child {
  margin-bottom: 0;
}

.resultField {
  margin-right: var(--spacing-lg);
  min-width: 200px;
}

.fieldLabel {
  font-weight: var(--font-weight-medium);
  color: var(--text-secondary);
  margin-right: var(--spacing-xxs);
}

.fieldValue {
  color: var(--text-primary);
}

.active {
  color: var(--color-success);
  font-weight: var(--font-weight-semibold);
}

.pending {
  color: var(--color-info);
  font-weight: var(--font-weight-semibold);
}

.suspended, .deactivated {
  color: var(--color-error);
  font-weight: var(--font-weight-semibold);
}

.locked {
  color: var(--color-warning);
  font-weight: var(--font-weight-semibold);
}

.unknown {
  color: var(--text-tertiary);
  font-weight: var(--font-weight-semibold);
}

.resultActions {
  display: flex;
  align-items: center;
}

.viewButton {
  padding: var(--button-padding-small);
  background-color: var(--button-secondary-bg);
  color: var(--button-secondary-text);
  border: 1px solid var(--button-secondary-border);
  border-radius: var(--button-border-radius-md);
  font-size: var(--button-font-size-small);
  font-weight: var(--font-weight-medium);
  cursor: pointer;
  transition: background-color var(--transition-fast);
}

.viewButton:hover {
  background-color: var(--button-secondary-hover-bg);
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: var(--spacing-lg);
  gap: var(--spacing-md);
}

.paginationButton {
  padding: var(--button-padding-small);
  background-color: var(--button-tertiary-bg);
  color: var(--button-tertiary-text);
  border: 1px solid var(--button-tertiary-border);
  border-radius: var(--button-border-radius-md);
  font-size: var(--button-font-size-small);
  font-weight: var(--font-weight-medium);
  cursor: pointer;
  transition: background-color var(--transition-fast);
}

.paginationButton:hover:not(:disabled) {
  background-color: var(--button-tertiary-hover-bg);
}

.paginationButton:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.pageInfo {
  font-size: var(--font-size-sm);
  color: var(--text-secondary);
}

@media (max-width: 768px) {
  .resultsHeader {
    flex-direction: column;
    align-items: flex-start;
  }
  
  .resultCount {
    margin-top: var(--spacing-xs);
  }
  
  .resultCard {
    flex-direction: column;
    align-items: flex-start;
  }
  
  .resultRow {
    flex-direction: column;
  }
  
  .resultField {
    margin-right: 0;
    margin-bottom: var(--spacing-xs);
  }
  
  .resultActions {
    margin-top: var(--spacing-sm);
    width: 100%;
  }
  
  .viewButton {
    width: 100%;
  }
}