/* File: frontend/src/pages/InvestorPitch/components/slides/MarketPosition/MarketPosition.module.css */

.container {
  height: 100%;
  display: flex;
  flex-direction: column;
  /* gap: var(--spacing-xl); */
}

.header {
  text-align: center;
  margin-bottom: var(--spacing-sm);
}

.title {
  font-size: var(--font-size-4xl);
  font-weight: var(--font-weight-bold);
  color: var(--text-on-primary);
  margin-bottom: var(--spacing-md);
}

.subtitle {
  font-size: var(--font-size-xl);
  color: var(--color-gray-400);
  max-width: 80%;
  margin: 0 auto;
}

.content {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  gap: var(--spacing-lg);
  flex: 1;
  padding: 0 var(--spacing-lg);
}

.allegorySection {
  flex: 1.5;
  display: flex;
  align-items: center;
  justify-content: center;
}

.allegoryContainer {
  background: var(--color-gray-1200);
  border-radius: var(--border-radius-lg);
  border: 1px solid var(--color-gray-1100);
  padding: var(--spacing-xl);
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-2xl);
}

.logoWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
}

.logo {
  width: 80%;
  max-width: 300px;
  height: auto;
}

.capabilities {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: var(--spacing-md);
}

.capabilityCard {
  background: var(--color-gray-1100);
  border-radius: var(--border-radius-md);
  padding: var(--spacing-md);
  border: 1px solid var(--color-gray-1000);
  min-height: 80px;
  display: flex;
  align-items: center;
}

.capabilityText {
  color: var(--color-primary-500);
  font-size: var(--font-size-lg);
  font-weight: var(--font-weight-medium);
  display: block;
}

.comparisonContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 var(--spacing-xl);
}

.arrow {
  font-size: 3rem;
  color: var(--color-primary-500);
  font-weight: var(--font-weight-bold);
}

.competitorsSection {
  flex: 1;
  background: var(--color-gray-1200);
  border-radius: var(--border-radius-lg);
  border: 1px solid var(--color-gray-1100);
  padding: var(--spacing-lg);
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xl);
}

.competitorCategory {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-md);
}

.categoryTitle {
  font-size: var(--font-size-lg);
  color: var(--color-gray-400);
  font-weight: var(--font-weight-bold);
  letter-spacing: 0.1em;
  margin-bottom: var(--spacing-sm);
}

.companies {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: var(--spacing-xs);
}

.company {
  font-size: var(--font-size-xs);
  color: var(--color-gray-300);
  text-decoration: none;
  padding: var(--spacing-xxs) var(--spacing-xs);
  background: var(--color-gray-1100);
  border-radius: var(--border-radius-sm);
  text-align: center;
  transition: all 0.2s ease;
  letter-spacing: 0.05em;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 32px;
  cursor: pointer;
}

.company:hover {
  color: var(--color-primary-500);
  background: var(--color-gray-1000);
}

.categoryTitle {
  font-size: var(--font-size-sm);
  color: var(--color-gray-400);
  font-weight: var(--font-weight-bold);
  letter-spacing: 0.1em;
  margin-bottom: var(--spacing-xs);
}

/* Modal styles */
.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  backdrop-filter: blur(3px);
}

.modalContent {
  background: var(--color-gray-1100);
  border-radius: var(--border-radius-lg);
  border: 1px solid var(--color-gray-900);
  width: 90%;
  max-width: 600px;
  max-height: 90vh;
  overflow-y: auto;
  position: relative;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.5);
}

.closeButton {
  position: absolute;
  top: 15px;
  right: 15px;
  background: transparent;
  border: none;
  color: var(--color-gray-400);
  font-size: 24px;
  cursor: pointer;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  transition: all 0.2s ease;
}

.closeButton:hover {
  background: var(--color-gray-1000);
  color: var(--color-primary-500);
}

.modalHeader {
  padding: var(--spacing-lg) var(--spacing-lg) var(--spacing-md);
  border-bottom: 1px solid var(--color-gray-1000);
}

.modalTitle {
  font-size: var(--font-size-2xl);
  color: var(--color-primary-500);
  font-weight: var(--font-weight-bold);
  margin: 0;
  letter-spacing: 0.05em;
}

.modalBody {
  padding: var(--spacing-lg);
}

.modalInfo {
  display: flex;
  flex-wrap: wrap;
  gap: var(--spacing-lg);
  margin-bottom: var(--spacing-lg);
  padding-bottom: var(--spacing-lg);
  border-bottom: 1px solid var(--color-gray-1000);
}

.infoItem {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xs);
}

.infoLabel {
  font-size: var(--font-size-sm);
  color: var(--color-gray-400);
  font-weight: var(--font-weight-medium);
}

.infoValue {
  font-size: var(--font-size-lg);
  color: var(--color-gray-200);
  font-weight: var(--font-weight-bold);
}

.valuationDate {
  font-size: var(--font-size-sm);
  color: var(--color-gray-500);
  font-weight: var(--font-weight-normal);
  margin-left: var(--spacing-xs);
}

.strengthsWeaknesses {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: var(--spacing-lg);
}

.section {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-md);
}

.sectionTitle {
  font-size: var(--font-size-lg);
  color: var(--color-gray-300);
  font-weight: var(--font-weight-bold);
  margin: 0;
}

.bulletList {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-md);
}

.bulletItem {
  position: relative;
  padding-left: var(--spacing-lg);
  color: var(--color-gray-400);
  font-size: var(--font-size-base);
  line-height: 1.5;
}

.bulletItem::before {
  content: "•";
  position: absolute;
  left: 0;
  color: var(--color-primary-500);
  font-weight: bold;
}

@media (max-width: 1200px) {
  .content {
    flex-direction: column;
  }

  .allegorySection {
    width: 100%;
  }

  .capabilities {
    grid-template-columns: repeat(2, 1fr);
  }

  .comparisonContainer {
    transform: rotate(90deg);
    padding: var(--spacing-md) 0;
  }

  .competitorsSection {
    width: 100%;
  }

  .companies {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 768px) {
  .title {
    font-size: var(--font-size-3xl);
  }

  .subtitle {
    font-size: var(--font-size-lg);
  }

  .content {
    padding: 0;
  }

  .capabilities {
    grid-template-columns: 1fr;
  }

  .capabilityText {
    font-size: var(--font-size-base);
  }

  .companies {
    grid-template-columns: repeat(3, 1fr);
    gap: var(--spacing-xs);
  }

  .company {
    font-size: var(--font-size-xs);
    padding: var(--spacing-xs);
  }
  
  .strengthsWeaknesses {
    grid-template-columns: 1fr;
  }
  
  .modalContent {
    width: 95%;
    max-height: 80vh;
  }
}

@media (prefers-reduced-motion: reduce) {
  .company {
    transition: none;
  }
}