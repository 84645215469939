/* File: frontend/src/pages/PropertyInsight/components/PropertySearchPanel/PropertySearchPanel.module.css */

.searchPanelsContainer {
  display: flex;
  gap: var(--spacing-lg);
  margin-bottom: var(--spacing-lg);
}

.searchPanel {
  flex: 1;
  border-radius: 24px;
  overflow: hidden;
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  padding: 24px;
}

.panelContent {
  border-radius: 18px;
  background-color: rgba(255, 255, 255, 0.55);
  backdrop-filter: blur(14.59px);
  border: 1.7px solid rgba(54, 103, 248, 0.3);
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  height: 100%;
}

.panelHeader {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.iconContainer {
  width: 60px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.panelIcon {
  width: 60px;
  height: 50px;
  object-fit: contain;
}

.headerContent {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.panelTitle {
  font-size: 24px;
  font-weight: 500;
  color: var(--text-primary);
  margin: 0;
  line-height: 30px;
}

.panelDescription {
  font-size: 14px;
  color: var(--text-secondary);
  margin: 0;
  line-height: 17px;
}

.searchForm {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.searchInputContainer {
  display: flex;
  gap: 10px;
}

.searchInput {
  flex: 1;
  padding: 12px 14px;
  border-radius: 12px;
  border: 1px solid var(--color-border);
  background-color: var(--bg-primary);
  font-size: 14px;
  color: var(--text-primary);
}

.searchInput::placeholder {
  color: var(--text-tertiary);
}

.searchButton {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 12px 14px;
  border-radius: 12px;
  background-color: var(--color-primary);
  border: 1px solid var(--color-primary-dark);
  color: var(--color-white);
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  transition: background-color var(--transition-fast);
  box-shadow: 0px 4px 8px rgba(29, 43, 139, 0.08), 0px 1px 2px rgba(29, 43, 139, 0.08), 0px 4px 4px rgba(255, 255, 255, 0.15) inset, 0px 2px 0px rgba(255, 255, 255, 0.15) inset;
}

.searchButton:hover:not(:disabled) {
  background-color: var(--color-primary-dark);
}

.searchButton:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.searchIcon {
  width: 16px;
  height: 16px;
}

.uploadButton {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 12px 14px;
  border-radius: 12px;
  background-color: var(--bg-primary);
  border: 1px solid var(--color-border);
  color: var(--text-primary);
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color var(--transition-fast);
  margin-top: auto;
}

.uploadButton:hover {
  background-color: var(--bg-hover);
}

.chevronIcon {
  width: 16px;
  height: 16px;
}

@media (max-width: 768px) {
  .searchPanelsContainer {
    flex-direction: column;
  }
  
  .searchPanel {
    width: 100%;
  }
}