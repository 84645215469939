/* File: frontend/src/components/Dashboard/AllegoryIntelligenceInitiator.module.css */

.aiContainer {
  position: relative;
  min-height: 200px;
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: var(--color-white);
  border-radius: var(--border-radius-xl);
  transition: all 0.3s ease;
}

.card {
  background-color: var(--color-white);
  border-radius: var(--border-radius-xl);
  padding: var(--spacing-xl);
  box-shadow: var(--shadow-sm);
  display: flex;
  flex-direction: column;
  gap: var(--spacing-lg);
  margin-bottom: var(--spacing-md);
}

.header {
  text-align: center;
  margin-bottom: var(--spacing-lg);
}

.titleWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: var(--spacing-sm);
  margin-bottom: var(--spacing-sm);
}

.icon {
  color: var(--color-primary);
}

.title {
  font-size: var(--font-size-2xl);
  font-weight: var(--font-weight-semibold);
  color: var(--text-primary);
  margin: 0;
}

.subtitle {
  font-size: var(--font-size-md);
  color: var(--text-secondary);
  margin: 0;
}

.content {
  max-width: 800px;
  margin: 0 auto;
  width: 100%;
  padding: var(--spacing-lg);
}

.content :global(.inputContainer) {
  padding: 0;
}

.loadingState {
  position: relative;
  width: 100%;
  min-height: 300px;
  display: flex;
  flex-direction: column;
  padding: var(--spacing-xl);
  background-color: var(--color-white);
  border-radius: var(--border-radius-xl);
}

.messagePreview {
  margin-bottom: var(--spacing-xl);
  opacity: 0.8;
}

.userMessage {
  display: flex;
  align-items: flex-start;
  gap: var(--spacing-md);
  padding: var(--spacing-md);
  background-color: var(--color-gray-50);
  border-radius: var(--border-radius-lg);
  margin-bottom: var(--spacing-lg);
}

.userIcon {
  background-color: var(--color-primary);
  color: var(--color-white);
  padding: var(--spacing-xs) var(--spacing-sm);
  border-radius: var(--border-radius-full);
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-medium);
}

.userMessage p {
  margin: 0;
  font-size: var(--font-size-md);
  line-height: var(--line-height-relaxed);
  color: var(--text-primary);
}

/* Responsive Design */
@media (max-width: 768px) {
  .card {
    padding: var(--spacing-lg);
  }

  .title {
    font-size: var(--font-size-xl);
  }

  .subtitle {
    font-size: var(--font-size-sm);
  }

  .loadingState {
    padding: var(--spacing-lg);
  }

  .content {
    padding: var(--spacing-md);
  }
}

@media (max-width: 480px) {
  .card {
    padding: var(--spacing-md);
  }

  .header {
    margin-bottom: var(--spacing-md);
  }

  .loadingState {
    padding: var(--spacing-md);
  }

  .userMessage {
    flex-direction: column;
    gap: var(--spacing-sm);
  }
}