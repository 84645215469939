/* File: frontend/src/components/DrivingScore/DrivingScoreMaps.module.css */

.card {
    width: 100%;
    position: relative;
    box-shadow: 0px 13px 4px rgba(0, 0, 0, 0), 0px 8px 3px rgba(0, 0, 0, 0), 0px 5px 3px rgba(0, 0, 0, 0.02), 0px 2px 2px rgba(0, 0, 0, 0.03), 0px 1px 1px rgba(0, 0, 0, 0.03);
    border-radius: 16px;
    background-color: #fff;
    border: 2px solid #f7f7f8;
    overflow: hidden;
    display: flex;
    flex-direction: column;
  }
  
  .cardStandartTitleRow {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 20px;
    border-bottom: 1px solid #f7f7f8;
  }
  
  .textParent {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .text {
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
    color: #14151a;
  }
  
  .text1 {
    font-size: 12px;
    line-height: 16px;
    color: #9ea2ad;
  }
  
  .button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 9px;
    border-radius: 10px;
    background-color: #fff;
    border: 1px solid #e9eaec;
  }
  
  .cardStandartContent {
    display: flex;
    flex-direction: column;
  }
  
  .cardMappingContainer {
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .cardsTableSystem {
    display: flex;
    gap: 6px;
  }
  
  .cardsTableSystemItem, .cardsTableSystemItem1 {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 12px;
    gap: 12px;
    border-radius: 16px;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  .cardsTableSystemItem {
    background-color: #f7f7f8;
    border: 1px solid #e9eaec;
  }
  
  .cardsTableSystemItem1 {
    background-color: #fff;
    border: 1px solid #f7f7f8;
  }
  
  .cardsTableSystemItem:hover, .cardsTableSystemItem1:hover, .active {
    background-color: #f7f7f8;
    border: 1px solid #e9eaec;
  }
  
  .cardsTableSystemIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    border-radius: 12px;
    background-color: #fff;
    border: 1px solid #e9eaec;
  }
  
  .texts {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }
  
  .attentiveDriving, .attentiveDriving1 {
    font-size: 12px;
    line-height: 12px;
    font-weight: 600;
    color: #1f2228;
  }
  
  .attentiveDrivingIs, .attentiveDrivingIs1 {
    font-size: 11px;
    color: #5e636e;
  }
  
  .cardMappingContainerC {
    position: relative;
    height: 684px;
    border-radius: 16px;
    background-color: #fff;
    border: 1px solid #e9eaec;
    overflow: hidden;
  }
  
  .mapContainer {
    width: 100%;
    height: 100%;
  }
  
  @media (max-width: 768px) {
    .cardsTableSystem {
      flex-direction: column;
    }
  
    .cardMappingContainerC {
      height: 400px;
    }
  }