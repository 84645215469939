/* File: frontend/src/pages/TripDetail/components/TripVehicle/TripVehicle.module.css */

.container {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-md);
}

.loading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: var(--spacing-xl);
  min-height: 200px;
}

.loadingSpinner {
  border: 3px solid var(--color-border);
  border-top: 3px solid var(--color-primary);
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 1s linear infinite;
  margin-bottom: var(--spacing-md);
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.error {
  background-color: var(--color-error-bg);
  color: var(--color-error);
  padding: var(--spacing-md);
  border-radius: var(--border-radius-md);
  margin-bottom: var(--spacing-md);
}

.noVehicle {
  background-color: var(--bg-secondary);
  border-radius: var(--border-radius-md);
  padding: var(--spacing-lg);
  text-align: center;
  color: var(--text-secondary);
}

.vehicleCard {
  background-color: var(--bg-primary);
  border-radius: var(--border-radius-md);
  border: 1px solid var(--color-border);
  overflow: hidden;
}

.vehicleHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: var(--spacing-md);
  background-color: var(--bg-secondary);
  border-bottom: 1px solid var(--color-border);
}

.vehicleTitle {
  font-size: var(--font-size-lg);
  font-weight: var(--font-weight-semibold);
  color: var(--text-primary);
  margin: 0;
}

.primaryBadge {
  background-color: var(--color-success-bg);
  color: var(--color-success);
  padding: 2px 8px;
  border-radius: 4px;
  font-size: var(--font-size-xs);
  font-weight: var(--font-weight-medium);
}

.vehicleDetails {
  padding: var(--spacing-md);
}

.detailsGrid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: var(--spacing-md);
  margin-bottom: var(--spacing-md);
}

.detailItem {
  display: flex;
  flex-direction: column;
}

.detailLabel {
  font-size: var(--font-size-sm);
  color: var(--text-secondary);
  margin-bottom: var(--spacing-xxs);
}

.detailValue {
  color: var(--text-primary);
  font-weight: var(--font-weight-medium);
}

.vehicleActions {
  display: flex;
  justify-content: flex-end;
}

.viewVehicleButton {
  padding: var(--button-padding-small);
  background-color: var(--button-secondary-bg);
  color: var(--button-secondary-text);
  border: 1px solid var(--button-secondary-border);
  border-radius: var(--button-border-radius-md);
  font-size: var(--button-font-size-small);
  font-weight: var(--font-weight-medium);
  cursor: pointer;
  transition: background-color var(--transition-fast);
}

.viewVehicleButton:hover {
  background-color: var(--button-secondary-hover-bg);
}

.tripVehicleInfo {
  background-color: var(--bg-secondary);
  border-radius: var(--border-radius-md);
  padding: var(--spacing-md);
}

.infoTitle {
  font-size: var(--font-size-md);
  font-weight: var(--font-weight-semibold);
  color: var(--text-primary);
  margin-top: 0;
  margin-bottom: var(--spacing-md);
}

.infoGrid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: var(--spacing-md);
}

.infoItem {
  display: flex;
  flex-direction: column;
}

.infoLabel {
  font-size: var(--font-size-sm);
  color: var(--text-secondary);
  margin-bottom: var(--spacing-xxs);
}

.infoValue {
  color: var(--text-primary);
  font-weight: var(--font-weight-medium);
}

@media (max-width: 992px) {
  .detailsGrid, .infoGrid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 576px) {
  .detailsGrid, .infoGrid {
    grid-template-columns: 1fr;
  }
}