/* File: frontend/src/components/Home/ProductDevSection.module.css */

.productDevSection {
  min-height: 100vh;
  background-color: var(--color-black);
  color: var(--color-white);
  display: flex;
  align-items: center;
  padding: var(--spacing-lg) 0;
  box-sizing: border-box;
}

.container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: var(--spacing-lg);
  width: 100%;
  max-width: var(--breakpoint-xl);
  margin: 0 auto;
  padding: 0 var(--spacing-lg);
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.title {
  font-size: clamp(2rem, 5vw, 3rem);
  margin-bottom: var(--spacing-md);
  line-height: 1.355;
}

.description {
  font-size: clamp(1rem, 2vw, 1.25rem);
  line-height: 1.45;
  margin-bottom: var(--spacing-lg);
}

.ctaButton {
  background-color: transparent;
  color: var(--color-primary);
  transition: background-color var(--transition-normal), color var(--transition-normal);
  padding: clamp(0.5rem, 2vw, 1rem) clamp(1rem, 4vw, 2rem);
  font-size: clamp(0.875rem, 2vw, 1rem);
  min-width: 150px;
  align-self: flex-start;
}

.ctaButton:hover {
  background-color: var(--color-primary);
  color: var(--color-white);
}

.animationContainer {
  width: 100%;
  height: clamp(300px, 50vh, 500px);
  position: relative;
  overflow: hidden;
  border-radius: var(--border-radius-lg);
  background-color: var(--color-black);
  contain: content;
  content-visibility: auto;
  contain-intrinsic-size: 300px;
}

.particleCanvas {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  touch-action: pan-y;
  pointer-events: none;
  transform: translateZ(0);
  -webkit-transform: translateZ(0);
  will-change: transform;
  image-rendering: -webkit-optimize-contrast;
  image-rendering: crisp-edges;
}

.refreshButton {
  position: absolute;
  bottom: var(--spacing-sm);
  right: var(--spacing-sm);
  background: none;
  border: none;
  cursor: pointer;
  color: var(--color-white);
  opacity: 0.7;
  transition: opacity var(--transition-fast);
  padding: var(--spacing-xs);
}

.refreshButton:hover {
  opacity: 1;
}

.animationPlaceholder {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--color-black);
  color: var(--color-white);
  font-size: var(--font-size-lg);
}

@media (max-width: 1024px) {
  .container {
    grid-template-columns: 1fr;
    display: flex;
    flex-direction: column-reverse;
  }

  .content {
    text-align: center;
  }

  .ctaButton {
    align-self: center;
  }
}

@media (max-width: 768px) {
  .productDevSection {
    padding: var(--spacing-md) 0;
  }

  .container {
    padding: 0 var(--spacing-md);
  }
}

@media (max-aspect-ratio: 1/1) {
  .container {
    grid-template-columns: 1fr;
  }
}

@media (prefers-reduced-motion: reduce) {
  .ctaButton,
  .refreshButton {
    transition: none;
  }
}