/* File: frontend/src/pages/Status/components/AwsMetrics.module.css */

.container {
    background: var(--bg-primary);
    border-radius: var(--border-radius-lg);
    padding: var(--spacing-md);
    margin-bottom: var(--spacing-md);
    box-shadow: var(--shadow-sm);
}

.title {
    margin: 0 0 var(--spacing-md) 0;
    font-size: var(--font-size-lg);
    font-weight: var(--font-weight-semibold);
    color: var(--text-primary);
}

.lambdaFunctions {
    margin-bottom: var(--spacing-lg);
}

.lambdaFunctions h3,
.serviceMetrics h3 {
    margin: 0 0 var(--spacing-sm) 0;
    font-size: var(--font-size-base);
    font-weight: var(--font-weight-semibold);
    color: var(--text-secondary);
}

.functionsList {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: var(--spacing-sm);
}

.functionCard {
    background: var(--bg-secondary);
    border-radius: var(--border-radius-md);
    padding: var(--spacing-sm);
}

.functionHeader {
    margin-bottom: var(--spacing-sm);
}

.functionName {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: var(--spacing-xxs);
}

.displayName {
    font-weight: var(--font-weight-medium);
    color: var(--text-primary);
}

.functionGroup {
    font-size: var(--font-size-xs);
    color: var(--text-secondary);
    text-transform: uppercase;
}

.status {
    padding: var(--spacing-xxxs) var(--spacing-xxs);
    border-radius: var(--border-radius-full);
    font-size: var(--font-size-xs);
    font-weight: var(--font-weight-semibold);
    text-transform: uppercase;
}

.statusOk {
    background-color: var(--color-success-bg);
    color: var(--color-success);
}

.statusDegraded {
    background-color: var(--color-warning-bg);
    color: var(--color-warning);
}

.statusError {
    background-color: var(--color-error-bg);
    color: var(--color-error);
}

.statusUnknown {
    background-color: var(--bg-tertiary);
    color: var(--text-secondary);
}

.functionMetrics {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: var(--spacing-xs);
    margin-bottom: var(--spacing-sm);
}

.metric {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: var(--font-size-sm);
}

.metric span:first-child {
    color: var(--text-secondary);
}

.metric span:last-child {
    font-weight: var(--font-weight-medium);
    color: var(--text-primary);
}

.metric .error {
    color: var(--color-error);
}

.functionDescription {
    font-size: var(--font-size-sm);
    color: var(--text-secondary);
    line-height: var(--line-height-normal);
}

.serviceMetrics {
    margin-bottom: var(--spacing-lg);
}

.metricsGrid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: var(--spacing-sm);
}

.metricCard {
    background: var(--bg-secondary);
    border-radius: var(--border-radius-md);
    padding: var(--spacing-sm);
    display: flex;
    flex-direction: column;
    gap: var(--spacing-xxs);
}

.metricLabel {
    font-size: var(--font-size-xs);
    color: var(--text-secondary);
    text-transform: uppercase;
}

.metricValue {
    font-size: var(--font-size-lg);
    font-weight: var(--font-weight-semibold);
    color: var(--text-primary);
}

.loading {
    padding: var(--spacing-lg);
    text-align: center;
    color: var(--text-secondary);
}

@media (max-width: 768px) {
    .functionsList {
        grid-template-columns: 1fr;
    }

    .metricsGrid {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 480px) {
    .metricsGrid {
        grid-template-columns: 1fr;
    }
}