/* File: frontend/src/pages/TripDetail/components/TripSpeed/TripSpeed.module.css */

.container {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-md);
}

.statsGrid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: var(--spacing-md);
}

.statCard {
  display: flex;
  align-items: center;
  padding: var(--spacing-md);
  background-color: var(--bg-secondary);
  border-radius: var(--border-radius-md);
  transition: transform var(--transition-normal);
}

.statCard:hover {
  transform: translateY(-5px);
}

.statIcon {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: var(--spacing-sm);
  color: var(--color-primary);
}

.statIcon svg {
  width: 24px;
  height: 24px;
}

.statContent {
  flex: 1;
}

.statValue {
  font-size: var(--font-size-lg);
  font-weight: var(--font-weight-bold);
  color: var(--text-primary);
  margin-bottom: var(--spacing-xxs);
}

.statLabel {
  font-size: var(--font-size-sm);
  color: var(--text-secondary);
}

.chartContainer {
  height: 400px;
  background-color: var(--bg-secondary);
  border-radius: var(--border-radius-md);
  overflow: hidden;
  position: relative;
}

.loading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.loadingSpinner {
  border: 3px solid var(--color-border);
  border-top: 3px solid var(--color-primary);
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 1s linear infinite;
  margin-bottom: var(--spacing-md);
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.chartError {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  color: var(--color-error);
  text-align: center;
  padding: var(--spacing-md);
}

.chartPlaceholder {
  height: 100%;
  width: 100%;
}

.mockChart {
  height: 100%;
  width: 100%;
  background-color: #f8f8f8;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: var(--spacing-md);
}

.mockSpeedLine {
  position: absolute;
  width: 80%;
  height: 60%;
  top: 20%;
  left: 10%;
  border: 2px solid var(--color-primary);
  border-right: none;
  border-left: none;
  border-radius: 50%;
}

.mockSpeedLimitLine {
  position: absolute;
  width: 80%;
  height: 2px;
  top: 40%;
  left: 10%;
  background-color: var(--color-error);
}

.mockChartText {
  position: absolute;
  font-size: var(--font-size-lg);
  color: var(--text-tertiary);
  text-align: center;
}

.mockLegend {
  position: absolute;
  bottom: var(--spacing-md);
  right: var(--spacing-md);
  display: flex;
  gap: var(--spacing-md);
  background-color: rgba(255, 255, 255, 0.8);
  padding: var(--spacing-sm);
  border-radius: var(--border-radius-sm);
}

.mockLegendItem {
  display: flex;
  align-items: center;
  gap: var(--spacing-xs);
}

.mockLegendColor {
  width: 16px;
  height: 4px;
}

.mockLegendLabel {
  font-size: var(--font-size-sm);
  color: var(--text-secondary);
}

.speedDataContainer {
  background-color: var(--bg-primary);
  border-radius: var(--border-radius-md);
  border: 1px solid var(--color-border);
  overflow: hidden;
}

.speedDataTitle {
  font-size: var(--font-size-md);
  font-weight: var(--font-weight-semibold);
  padding: var(--spacing-sm);
  background-color: var(--bg-secondary);
  border-bottom: 1px solid var(--color-border);
  margin: 0;
}

.tableContainer {
  overflow-x: auto;
  max-height: 300px;
  overflow-y: auto;
}

.speedDataTable {
  width: 100%;
  border-collapse: collapse;
}

.speedDataTable th, .speedDataTable td {
  padding: var(--spacing-sm);
  text-align: left;
  border-bottom: 1px solid var(--color-border-light);
}

.speedDataTable th {
  background-color: var(--bg-secondary);
  font-weight: var(--font-weight-semibold);
  color: var(--text-secondary);
  position: sticky;
  top: 0;
  z-index: 1;
}

.speedingRow {
  background-color: var(--color-error-bg);
}

@media (max-width: 992px) {
  .statsGrid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 576px) {
  .statsGrid {
    grid-template-columns: 1fr;
  }
  
  .chartContainer {
    height: 300px;
  }
}