/* File: frontend/src/pages/InvestorPitch/components/slides/ValuationModelSlide/ValuationModelSlide.module.css */

.container {
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: var(--spacing-lg);
}

.header {
  text-align: center;
  margin-bottom: var(--spacing-md);
}

.title {
  font-size: var(--font-size-4xl);
  font-weight: var(--font-weight-bold);
  color: var(--text-on-primary);
  margin-bottom: var(--spacing-sm);
  background: linear-gradient(135deg, var(--color-primary), var(--color-blue-510));
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}

.subtitle {
  font-size: var(--font-size-xl);
  color: var(--color-gray-400);
  max-width: 80%;
  margin: 0 auto;
}

.content {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.tabsContainer {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
}

.tabs {
  display: flex;
  border-bottom: 1px solid var(--color-gray-1000);
  margin-bottom: var(--spacing-md);
}

.tabButton {
  background: transparent;
  border: none;
  padding: var(--spacing-sm) var(--spacing-lg);
  font-size: var(--font-size-base);
  color: var(--color-gray-400);
  cursor: pointer;
  transition: all 0.2s ease;
  position: relative;
}

.tabButton:hover {
  color: var(--color-primary-500);
}

.activeTab {
  color: var(--color-primary-500);
  font-weight: var(--font-weight-semibold);
}

.activeTab::after {
  content: '';
  position: absolute;
  bottom: -1px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: var(--color-primary-500);
}

.tabContentContainer {
  flex: 1;
  overflow-y: auto;
  padding-right: var(--spacing-sm);
}

.tabContent {
  height: 100%;
}

/* Scenario selector styles */
.scenarioSelector {
  display: flex;
  align-items: center;
  margin-bottom: var(--spacing-md);
  background: var(--color-gray-1200);
  border-radius: var(--border-radius-md);
  padding: var(--spacing-sm) var(--spacing-md);
  border: 1px solid var(--color-gray-1100);
}

.scenarioLabel {
  font-size: var(--font-size-sm);
  color: var(--color-gray-400);
  margin-right: var(--spacing-md);
  white-space: nowrap;
}

.scenarioButtons {
  display: flex;
  gap: var(--spacing-xs);
}

.scenarioButton {
  background: var(--color-gray-1100);
  border: 1px solid var(--color-gray-1000);
  border-radius: var(--border-radius-sm);
  padding: var(--spacing-xxs) var(--spacing-sm);
  font-size: var(--font-size-sm);
  color: var(--color-gray-400);
  cursor: pointer;
  transition: all 0.2s ease;
}

.scenarioButton:hover {
  background: var(--color-gray-1000);
  color: var(--color-gray-300);
}

.activeScenario {
  background: var(--color-primary-900);
  border-color: var(--color-primary-700);
  color: var(--color-primary-500);
  font-weight: var(--font-weight-medium);
}

.tableContainer {
  background: var(--color-gray-1200);
  border-radius: var(--border-radius-lg);
  border: 1px solid var(--color-gray-1100);
  padding: var(--spacing-lg);
  height: 100%;
  overflow-y: auto;
}

.tableTitle {
  font-size: var(--font-size-xl);
  font-weight: var(--font-weight-bold);
  color: var(--color-primary-500);
  margin-bottom: var(--spacing-lg);
  text-align: center;
}

.valuationTable {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: var(--spacing-xl);
}

.valuationTable th {
  text-align: left;
  padding: var(--spacing-sm);
  border-bottom: 1px solid var(--color-gray-1000);
  color: var(--color-gray-300);
  font-weight: var(--font-weight-semibold);
}

.valuationTable td {
  padding: var(--spacing-sm);
  border-bottom: 1px solid var(--color-gray-1100);
  color: var(--color-gray-400);
}

.totalRow {
  font-weight: var(--font-weight-bold);
}

.totalRow td {
  color: var(--color-gray-200);
  border-top: 1px solid var(--color-gray-900);
}

.keyMetrics {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: var(--spacing-md);
  margin-bottom: var(--spacing-xl);
}

.metricCard {
  background: var(--color-gray-1100);
  border-radius: var(--border-radius-md);
  padding: var(--spacing-md);
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xs);
  border: 1px solid var(--color-gray-1000);
}

.metricLabel {
  font-size: var(--font-size-sm);
  color: var(--color-gray-400);
}

.metricValue {
  font-size: var(--font-size-lg);
  font-weight: var(--font-weight-bold);
  color: var(--color-gray-200);
}

.highlightMetric {
  color: var(--color-primary-500);
}

.valuationNotes {
  background: var(--color-gray-1100);
  border-radius: var(--border-radius-md);
  padding: var(--spacing-md);
  border: 1px solid var(--color-gray-1000);
}

.valuationNotes h4 {
  font-size: var(--font-size-base);
  font-weight: var(--font-weight-semibold);
  color: var(--color-gray-300);
  margin-bottom: var(--spacing-sm);
}

.valuationNotes ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.valuationNotes li {
  position: relative;
  padding-left: var(--spacing-lg);
  margin-bottom: var(--spacing-xs);
  color: var(--color-gray-400);
  font-size: var(--font-size-sm);
}

.valuationNotes li::before {
  content: "•";
  position: absolute;
  left: 0;
  color: var(--color-primary-500);
  font-weight: bold;
}

.valuationNotes strong {
  color: var(--color-primary-500);
  font-weight: var(--font-weight-semibold);
}

/* Insurance tab specific styles */
.insuranceMetrics {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: var(--spacing-xl);
  margin-bottom: var(--spacing-xl);
}

.metricGroup {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-md);
}

.metricGroupTitle {
  font-size: var(--font-size-base);
  font-weight: var(--font-weight-semibold);
  color: var(--color-gray-300);
  margin-bottom: var(--spacing-xs);
}

/* Combined valuation tab specific styles */
.valuationBreakdown {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: var(--spacing-xl);
  padding: var(--spacing-lg) 0;
}

.valuationComponent {
  flex: 1;
  background: var(--color-gray-1100);
  border-radius: var(--border-radius-md);
  padding: var(--spacing-md);
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: var(--spacing-sm);
  border: 1px solid var(--color-gray-1000);
  min-width: 200px;
}

.componentTitle {
  font-size: var(--font-size-sm);
  color: var(--color-gray-400);
  margin: 0;
}

.componentValue {
  font-size: var(--font-size-xl);
  font-weight: var(--font-weight-bold);
  color: var(--color-gray-200);
}

.totalValue {
  color: var(--color-primary-500);
  font-size: var(--font-size-2xl);
}

.componentDescription {
  font-size: var(--font-size-xs);
  color: var(--color-gray-500);
  max-width: 200px;
}

.valuationPlus, .valuationEquals {
  font-size: var(--font-size-2xl);
  color: var(--color-gray-400);
  margin: 0 var(--spacing-md);
}

.seriesADetails {
  margin-top: var(--spacing-xl);
}

.seriesATitle {
  font-size: var(--font-size-lg);
  font-weight: var(--font-weight-semibold);
  color: var(--color-primary-500);
  margin-bottom: var(--spacing-md);
  text-align: center;
}

.seriesAMetrics {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: var(--spacing-md);
  margin-bottom: var(--spacing-xl);
}

/* Terms tab specific styles */
.termsSection {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: var(--spacing-xl);
  margin-bottom: var(--spacing-xl);
}

.termsDetails, .exitScenarios {
  background: var(--color-gray-1100);
  border-radius: var(--border-radius-md);
  padding: var(--spacing-md);
  border: 1px solid var(--color-gray-1000);
}

.termsTitle, .exitTitle {
  font-size: var(--font-size-base);
  font-weight: var(--font-weight-semibold);
  color: var(--color-primary-500);
  margin-bottom: var(--spacing-md);
  text-align: center;
}

.termsTable {
  width: 100%;
  border-collapse: collapse;
}

.termsTable td {
  padding: var(--spacing-xs) var(--spacing-sm);
  border-bottom: 1px solid var(--color-gray-1100);
  color: var(--color-gray-400);
  font-size: var(--font-size-sm);
}

.termsTable td:first-child {
  font-weight: var(--font-weight-medium);
  color: var(--color-gray-300);
  width: 40%;
}

.exitScenario {
  margin-bottom: var(--spacing-md);
}

.exitValuation {
  font-size: var(--font-size-base);
  font-weight: var(--font-weight-semibold);
  color: var(--color-gray-300);
  margin-bottom: var(--spacing-xs);
}

.exitBreakdown {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xs);
}

.exitBar {
  display: flex;
  height: 24px;
  width: 100%;
  border-radius: var(--border-radius-sm);
  overflow: hidden;
}

.seriesAPortion {
  background-color: var(--color-primary-700);
  display: flex;
  align-items: center;
  justify-content: center;
}

.commonPortion {
  background-color: var(--color-gray-900);
  display: flex;
  align-items: center;
  justify-content: center;
}

.exitLabel {
  font-size: var(--font-size-xs);
  color: var(--color-gray-200);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.exitValues {
  display: flex;
  justify-content: space-between;
  font-size: var(--font-size-xs);
  color: var(--color-gray-400);
}

.exitValue {
  display: flex;
  flex-direction: column;
  gap: 2px;
}

/* Fund Distribution styles */
.fundDistribution {
  /* margin-top: var(--spacing-xl); */
  background: var(--color-gray-1100);
  border-radius: var(--border-radius-md);
  padding: var(--spacing-md);
  margin-bottom: var(--spacing-xl);
  border: 1px solid var(--color-gray-1000);
}

.distributionTitle {
  font-size: var(--font-size-base);
  font-weight: var(--font-weight-semibold);
  color: var(--color-primary-500);
  margin-bottom: var(--spacing-md);
  text-align: center;
}

.distributionChart {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-md);
}

.distributionItem {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xs);
}

.distributionBar {
  height: 24px;
  background: linear-gradient(90deg, var(--color-primary-700), var(--color-primary-600));
  border-radius: var(--border-radius-sm);
  display: flex;
  align-items: center;
  padding-right: var(--spacing-xs);
  justify-content: flex-end;
}

.distributionLabel {
  font-size: var(--font-size-xs);
  color: var(--color-white);
  font-weight: var(--font-weight-semibold);
}

.distributionDetails {
  display: flex;
  justify-content: space-between;
  font-size: var(--font-size-sm);
}

.distributionCategory {
  color: var(--color-gray-300);
}

.distributionAmount {
  color: var(--color-gray-400);
  font-weight: var(--font-weight-medium);
}

@media (max-width: 768px) {
  .distributionDetails {
    flex-direction: column;
    gap: 2px;
  }
}

/* Responsive adjustments */
@media (max-width: 1200px) {
  .keyMetrics, .seriesAMetrics {
    grid-template-columns: repeat(2, 1fr);
  }
  
  .insuranceMetrics {
    grid-template-columns: 1fr;
    gap: var(--spacing-lg);
  }
  
  .valuationBreakdown {
    flex-direction: column;
    gap: var(--spacing-md);
  }
  
  .valuationPlus, .valuationEquals {
    margin: var(--spacing-xs) 0;
  }
  
  .termsSection {
    grid-template-columns: 1fr;
    gap: var(--spacing-lg);
  }
  
  .scenarioSelector {
    flex-direction: column;
    align-items: flex-start;
    gap: var(--spacing-xs);
  }
  
  .scenarioLabel {
    margin-right: 0;
    margin-bottom: var(--spacing-xs);
  }
}

@media (max-width: 768px) {
  .title {
    font-size: var(--font-size-3xl);
  }
  
  .subtitle {
    font-size: var(--font-size-lg);
    max-width: 100%;
  }
  
  .keyMetrics, .seriesAMetrics {
    grid-template-columns: 1fr;
  }
  
  .tabButton {
    padding: var(--spacing-xs) var(--spacing-sm);
    font-size: var(--font-size-sm);
  }
  
  .scenarioButtons {
    flex-wrap: wrap;
  }
}