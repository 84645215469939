/* File: frontend/src/components/Home/IntroSection.module.css */

.introSection {
  min-height: 100vh;
  background: linear-gradient(135deg, #f8f9fe 0%, #eef1f8 100%);
  color: var(--color-black);
  padding: var(--spacing-xl) 0;
  box-sizing: border-box;
  overflow: hidden;
  position: relative;
}

.container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: var(--spacing-xl);
  width: 100%;
  max-width: var(--breakpoint-xl);
  margin: 0 auto;
  padding: 0 var(--spacing-lg);
  align-items: center;
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 2;
}

.partnerTag {
  font-size: var(--font-size-sm);
  font-weight: 600;
  color: var(--color-primary);
  margin-bottom: var(--spacing-md);
  letter-spacing: 1.5px;
  display: inline-block;
}

.title {
  font-size: clamp(2rem, 5vw, 3.5rem);
  line-height: 1.2;
  margin-bottom: var(--spacing-md);
  color: var(--color-black);
  font-weight: 700;
}

.description {
  font-size: clamp(1rem, 2vw, 1.125rem);
  line-height: 1.6;
  margin-bottom: var(--spacing-lg);
  color: var(--color-gray-700);
}

.statContainer {
  display: flex;
  gap: var(--spacing-xl);
  margin-bottom: var(--spacing-xl);
}

.statItem {
  display: flex;
  flex-direction: column;
}

.statNumber {
  font-size: clamp(1.5rem, 3vw, 2.5rem);
  font-weight: 700;
  color: var(--color-primary);
  line-height: 1;
}

.statLabel {
  font-size: var(--font-size-sm);
  color: var(--color-gray-600);
  margin-top: var(--spacing-xs);
}

.ctaContainer {
  display: flex;
  gap: var(--spacing-md);
}

.primaryButton {
  background-color: var(--color-primary);
  color: var(--color-white);
  padding: var(--spacing-md) var(--spacing-lg);
  border-radius: var(--border-radius-md);
  font-weight: 600;
  transition: all 0.3s ease;
}

.primaryButton:hover {
  background-color: var(--color-primary-dark);
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(55, 93, 251, 0.2);
}

.secondaryButton {
  background-color: transparent;
  color: var(--color-primary);
  border: 1px solid var(--color-primary);
  padding: var(--spacing-md) var(--spacing-lg);
  border-radius: var(--border-radius-md);
  font-weight: 600;
  transition: all 0.3s ease;
}

.secondaryButton:hover {
  background-color: rgba(55, 93, 251, 0.1);
  transform: translateY(-2px);
}

.imageContainer {
  position: relative;
  height: 500px;
  width: 100%;
}

.featureCard {
  background: white;
  border-radius: var(--border-radius-lg);
  padding: var(--spacing-lg);
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.08);
  position: absolute;
  width: 280px;
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.6s ease, transform 0.6s ease;
}

.featureCard h3 {
  font-size: var(--font-size-md);
  margin: var(--spacing-md) 0 var(--spacing-sm);
  color: var(--color-black);
}

.featureCard p {
  font-size: var(--font-size-sm);
  color: var(--color-gray-600);
  line-height: 1.5;
}

.iconWrapper {
  width: 48px;
  height: 48px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--color-primary-light);
  color: var(--color-primary);
}

.card1 {
  top: 10%;
  left: 5%;
  transition-delay: 0.1s;
}

.card2 {
  top: 40%;
  left: 25%;
  transition-delay: 0.3s;
}

.card3 {
  top: 65%;
  left: 10%;
  transition-delay: 0.5s;
}

.animate {
  opacity: 1;
  transform: translateY(0);
}

@media (max-width: 1024px) {
  .container {
    grid-template-columns: 1fr;
    gap: var(--spacing-xl);
  }

  .imageContainer {
    height: 400px;
    margin-top: var(--spacing-lg);
    order: 2;
  }

  .content {
    text-align: center;
    order: 1;
  }

  .statContainer {
    justify-content: center;
  }

  .ctaContainer {
    justify-content: center;
  }
  
  .card1 {
    top: 5%;
    left: 10%;
  }
  
  .card2 {
    top: 30%;
    left: 40%;
  }
  
  .card3 {
    top: 60%;
    left: 20%;
  }
}

@media (max-width: 768px) {
  .introSection {
    padding: var(--spacing-lg) 0;
  }

  .imageContainer {
    height: 450px;
  }
  
  .featureCard {
    width: 240px;
  }
  
  .ctaContainer {
    flex-direction: column;
    gap: var(--spacing-sm);
  }
  
  .statContainer {
    flex-direction: column;
    gap: var(--spacing-md);
    align-items: center;
  }
}

@media (max-width: 480px) {
  .imageContainer {
    height: 500px;
  }
  
  .featureCard {
    width: 220px;
  }
  
  .card1 {
    top: 5%;
    left: 5%;
  }
  
  .card2 {
    top: 35%;
    left: 20%;
  }
  
  .card3 {
    top: 65%;
    left: 5%;
  }
}

@media (prefers-reduced-motion: reduce) {
  .featureCard {
    transition: none;
  }
  
  .primaryButton, .secondaryButton {
    transition: none;
  }
}