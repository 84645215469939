/* File: frontend/src/pages/SuperAdmin/components/AgreementManagement/AgreementDetail/AgreementDetail.module.css */

.container {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-md);
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: var(--spacing-md);
}

.headerContent {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xs);
}

.agreementTitle {
  font-size: var(--font-size-xl);
  font-weight: var(--font-weight-bold);
  color: var(--text-primary);
  margin: 0;
}

.agreementId {
  font-size: var(--font-size-sm);
  color: var(--text-secondary);
  font-family: var(--font-family-mono);
}

.statusContainer {
  display: flex;
  gap: var(--spacing-sm);
  margin-top: var(--spacing-xs);
}

.statusBadge, .typeBadge {
  display: inline-block;
  padding: 4px 8px;
  border-radius: 12px;
  font-size: var(--font-size-xs);
  font-weight: var(--font-weight-medium);
  text-transform: capitalize;
}

.statusActive {
  background-color: var(--color-success-bg);
  color: var(--color-success);
}

.statusExpired {
  background-color: var(--color-error-bg);
  color: var(--color-error);
}

.statusTerminated {
  background-color: var(--color-error-bg);
  color: var(--color-error);
}

.statusPending {
  background-color: var(--color-warning-bg);
  color: var(--color-warning);
}

.typeProduction {
  background-color: var(--color-primary-100);
  color: var(--color-primary);
}

.typeTrial {
  background-color: var(--color-info-bg);
  color: var(--color-info);
}

.typePilot {
  background-color: var(--color-warning-bg);
  color: var(--color-warning);
}

.headerActions {
  display: flex;
  gap: var(--spacing-sm);
}

.backButton, .editButton {
  display: flex;
  align-items: center;
  gap: var(--spacing-xs);
  padding: var(--spacing-sm) var(--spacing-md);
  border-radius: var(--border-radius-md);
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-medium);
  cursor: pointer;
  transition: all var(--transition-fast);
}

.backButton {
  background-color: var(--bg-secondary);
  color: var(--text-primary);
  border: 1px solid var(--color-border);
}

.backButton:hover:not(:disabled) {
  background-color: var(--bg-hover);
}

.backButton:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.editButton {
  background-color: var(--color-primary);
  color: white;
  border: none;
}

.editButton:hover {
  background-color: var(--color-primary-dark);
}

.tabs {
  display: flex;
  border-bottom: 1px solid var(--color-border);
  margin-bottom: var(--spacing-md);
  overflow-x: auto;
}

.tab {
  padding: var(--spacing-sm) var(--spacing-md);
  background: none;
  border: none;
  border-bottom: 2px solid transparent;
  font-size: var(--font-size-base);
  color: var(--text-secondary);
  cursor: pointer;
  transition: all var(--transition-fast);
  white-space: nowrap;
}

.tab:hover {
  color: var(--text-primary);
}

.activeTab {
  color: var(--color-primary);
  border-bottom-color: var(--color-primary);
  font-weight: var(--font-weight-medium);
}

.tabContent {
  background-color: var(--bg-primary);
  border-radius: var(--border-radius-md);
  padding: var(--spacing-md);
}

.section {
  margin-bottom: var(--spacing-lg);
}

.section:last-child {
  margin-bottom: 0;
}

.sectionTitle {
  font-size: var(--font-size-lg);
  font-weight: var(--font-weight-semibold);
  color: var(--text-primary);
  margin: 0 0 var(--spacing-md) 0;
  padding-bottom: var(--spacing-xs);
  border-bottom: 1px solid var(--color-border-light);
}

.infoGrid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: var(--spacing-md);
}

.infoItem {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xxs);
}

.infoLabel {
  font-size: var(--font-size-sm);
  color: var(--text-secondary);
  font-weight: var(--font-weight-medium);
}

.infoValue {
  color: var(--text-primary);
  word-break: break-word;
}

.notesContainer {
  background-color: var(--bg-secondary);
  border-radius: var(--border-radius-md);
  padding: var(--spacing-md);
}

.notes {
  margin: 0 0 var(--spacing-md) 0;
  line-height: 1.6;
}

.termsNote {
  display: flex;
  align-items: center;
  padding-top: var(--spacing-sm);
  border-top: 1px solid var(--color-border-light);
}

.termsNoteLabel {
  font-weight: var(--font-weight-medium);
  margin-right: var(--spacing-sm);
}

.renewalInfo {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: var(--spacing-md);
  background-color: var(--bg-secondary);
  border-radius: var(--border-radius-md);
  padding: var(--spacing-md);
}

.renewalItem {
  display: flex;
  align-items: center;
}

.renewalLabel {
  font-weight: var(--font-weight-medium);
  color: var(--text-secondary);
  margin-right: var(--spacing-sm);
}

.renewalValue {
  color: var(--text-primary);
}

.termsContainer {
  background-color: var(--bg-secondary);
  border-radius: var(--border-radius-md);
  padding: var(--spacing-md);
}

.termsText {
  margin: 0;
  line-height: 1.8;
  white-space: pre-line;
}

.productsTable, .subscriptionsTable {
  width: 100%;
  border-collapse: collapse;
}

.productsTable th, .productsTable td,
.subscriptionsTable th, .subscriptionsTable td {
  padding: var(--spacing-sm);
  text-align: left;
  border-bottom: 1px solid var(--color-border-light);
}

.productsTable th, .subscriptionsTable th {
  background-color: var(--bg-secondary);
  color: var(--text-secondary);
  font-weight: var(--font-weight-semibold);
  font-size: var(--font-size-sm);
}

.tableActions {
  display: flex;
  gap: var(--spacing-xs);
}

.actionButton {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 28px;
  background-color: var(--bg-secondary);
  border: 1px solid var(--color-border);
  border-radius: var(--border-radius-sm);
  color: var(--text-secondary);
  cursor: pointer;
  transition: all var(--transition-fast);
}

.actionButton:hover {
  background-color: var(--bg-hover);
  color: var(--color-primary);
  border-color: var(--color-primary);
}

.documentsList {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-md);
}

.documentItem {
  display: flex;
  align-items: center;
  padding: var(--spacing-md);
  background-color: var(--bg-secondary);
  border-radius: var(--border-radius-md);
  border: 1px solid var(--color-border-light);
}

.documentIcon {
  font-size: var(--font-size-xl);
  color: var(--color-primary);
  margin-right: var(--spacing-md);
}

.documentInfo {
  flex: 1;
}

.documentName {
  font-weight: var(--font-weight-medium);
  margin-bottom: var(--spacing-xxs);
}

.documentUrl {
  font-size: var(--font-size-sm);
  color: var(--text-secondary);
  word-break: break-all;
}

.documentActions {
  margin-left: var(--spacing-md);
}

.documentButton {
  display: flex;
  align-items: center;
  gap: var(--spacing-xs);
  padding: var(--spacing-xs) var(--spacing-sm);
  background-color: var(--bg-primary);
  border: 1px solid var(--color-border);
  border-radius: var(--border-radius-sm);
  font-size: var(--font-size-xs);
  color: var(--text-primary);
  text-decoration: none;
  cursor: pointer;
  transition: all var(--transition-fast);
}

.documentButton:hover {
  background-color: var(--bg-hover);
  color: var(--color-primary);
  border-color: var(--color-primary);
}

.uploadContainer {
  display: flex;
  justify-content: center;
  margin-top: var(--spacing-lg);
}

.uploadButton {
  display: flex;
  align-items: center;
  gap: var(--spacing-xs);
  padding: var(--spacing-sm) var(--spacing-md);
  background-color: var(--color-primary);
  color: white;
  border: none;
  border-radius: var(--border-radius-md);
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-medium);
  cursor: pointer;
  transition: background-color var(--transition-fast);
}

.uploadButton:hover {
  background-color: var(--color-primary-dark);
}

.emptyState {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: var(--spacing-xl);
  background-color: var(--bg-secondary);
  border-radius: var(--border-radius-md);
  color: var(--text-secondary);
  font-style: italic;
}

.loading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: var(--spacing-xl);
  min-height: 300px;
}

.spinner {
  border: 3px solid var(--color-border);
  border-top: 3px solid var(--color-primary);
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 1s linear infinite;
  margin-bottom: var(--spacing-md);
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.error, .notFound {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: var(--spacing-xl);
  background-color: var(--bg-secondary);
  border-radius: var(--border-radius-md);
  min-height: 200px;
}

.error {
  color: var(--color-error);
}

.notFound {
  color: var(--text-secondary);
}

.errorMessage {
  display: flex;
  align-items: center;
  gap: var(--spacing-sm);
  padding: var(--spacing-md);
  background-color: var(--color-error-bg);
  color: var(--color-error);
  border-radius: var(--border-radius-md);
  margin-bottom: var(--spacing-md);
}

.errorMessage i {
  font-size: var(--font-size-lg);
}

/* Form styles for editing */
.form {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-lg);
}

.formSection {
  background-color: var(--bg-primary);
  border-radius: var(--border-radius-md);
  border: 1px solid var(--color-border);
  padding: var(--spacing-md);
}

.formGrid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: var(--spacing-md);
}

.formGroup {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xs);
}

.formGroupFull {
  grid-column: 1 / -1;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xs);
}

.label {
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-medium);
  color: var(--text-secondary);
}

.required {
  color: var(--color-error);
}

.input, .select, .textarea {
  padding: var(--spacing-sm);
  border: 1px solid var(--color-border);
  border-radius: var(--border-radius-md);
  font-size: var(--font-size-base);
  color: var(--text-primary);
  background-color: var(--bg-primary);
  transition: border-color var(--transition-fast), box-shadow var(--transition-fast);
}

.input:focus, .select:focus, .textarea:focus {
  outline: none;
  border-color: var(--color-primary);
  box-shadow: 0 0 0 2px var(--color-primary-100);
}

.input:disabled, .select:disabled, .textarea:disabled {
  background-color: var(--bg-secondary);
  cursor: not-allowed;
}

.textarea {
  resize: vertical;
  min-height: 80px;
}

.checkboxContainer {
  display: flex;
  align-items: center;
  gap: var(--spacing-xs);
}

.checkbox {
  width: 18px;
  height: 18px;
  cursor: pointer;
}

.checkbox:disabled {
  cursor: not-allowed;
}

.checkboxLabel {
  font-size: var(--font-size-base);
  color: var(--text-primary);
  cursor: pointer;
}

.formActions {
  display: flex;
  justify-content: flex-end;
  gap: var(--spacing-md);
  margin-top: var(--spacing-md);
}

.cancelButton, .submitButton {
  padding: var(--spacing-sm) var(--spacing-lg);
  border-radius: var(--border-radius-md);
  font-size: var(--font-size-base);
  font-weight: var(--font-weight-medium);
  cursor: pointer;
  transition: all var(--transition-fast);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: var(--spacing-xs);
  min-width: 120px;
}

.cancelButton {
  background-color: var(--bg-secondary);
  color: var(--text-primary);
  border: 1px solid var(--color-border);
}

.cancelButton:hover:not(:disabled) {
  background-color: var(--bg-hover);
}

.submitButton {
  background-color: var(--color-primary);
  color: white;
  border: none;
}

.submitButton:hover:not(:disabled) {
  background-color: var(--color-primary-dark);
}

.cancelButton:disabled, .submitButton:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

@media (max-width: 992px) {
  .infoGrid, .formGrid, .renewalInfo {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 768px) {
  .header {
    flex-direction: column;
    gap: var(--spacing-md);
  }
  
  .headerActions {
    width: 100%;
    flex-wrap: wrap;
  }
  
  .backButton, .editButton {
    flex: 1;
    justify-content: center;
  }
  
  .tabs {
    flex-wrap: nowrap;
    overflow-x: auto;
  }
  
  .tab {
    padding: var(--spacing-xs) var(--spacing-sm);
  }
  
  .documentItem {
    flex-direction: column;
    align-items: flex-start;
    gap: var(--spacing-md);
  }
  
  .documentActions {
    margin-left: 0;
    width: 100%;
  }
  
  .documentButton {
    width: 100%;
    justify-content: center;
  }
  
  .productsTable, .subscriptionsTable {
    display: block;
    overflow-x: auto;
  }
  
  .formActions {
    flex-direction: column;
  }
  
  .cancelButton, .submitButton {
    width: 100%;
  }
}