/* File: frontend/src/components/Home/IntroSection.module.css */

.introSection {
  height: 50vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--bg-tertiary);
  color: var(--color-black);
  text-align: center;
  padding: var(--spacing-lg);
}

.title {
  font-size: var(--font-size-3xl);
  margin-bottom: var(--spacing-md);
}

.description {
  font-size: var(--font-size-lg);
  margin-bottom: var(--spacing-lg);
  max-width: 600px;
}

.ctaButton {
  padding: var(--spacing-lg);
  font-size: var(--font-size-base);
}

@media (max-width: 768px) {
  .introSection {
    height: auto;
    min-height: 50vh;
  }

  .title {
    font-size: var(--font-size-2xl);
  }

  .description {
    font-size: var(--font-size-base);
  }

  .ctaButton {
    padding: var(--spacing-md);
    font-size: var(--font-size-base);
  }
}

@media (max-width: 480px) {
  .title {
    font-size: var(--font-size-xl);
  }

  .description {
    font-size: var(--font-size-sm);
  }

  .ctaButton {
    padding: var(--spacing-sm);
    font-size: var(--font-size-sm);
  }
}