/* File: frontend/src/pages/Dashboard/Dashboard.module.css */

.dashboard {
  display: flex;
  flex-direction: column;
  background-color: var(--bg-secondary);
  min-height: 100%;
  width: 100%;
}

.pageContent {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-lg);
  padding: var(--spacing-lg);
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
}

.cardContainer {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: var(--spacing-lg);
  width: 100%;
}

/* Large Desktop (1440px and above) */
@media (min-width: 1441px) {
  .pageContent {
    padding: var(--spacing-xl);
  }
}

/* Desktop (1024px - 1440px) */
@media (max-width: 1440px) {
  .pageContent {
    max-width: 1200px;
  }
}

/* Tablet Landscape (768px - 1023px) */
@media (max-width: 1023px) {
  .pageContent {
    padding: var(--spacing-md);
    gap: var(--spacing-md);
    max-width: 960px;
  }

  .cardContainer {
    gap: var(--spacing-md);
    grid-template-columns: repeat(2, 1fr);
  }
}

/* Tablet Portrait (601px - 767px) */
@media (max-width: 767px) {
  .pageContent {
    padding: var(--spacing-sm);
    gap: var(--spacing-sm);
  }

  .cardContainer {
    grid-template-columns: 1fr;
    gap: var(--spacing-sm);
  }
}

/* Mobile (600px and below) */
@media (max-width: 600px) {
  .dashboard {
    padding: 0;
  }

  .pageContent {
    padding: var(--spacing-xs);
    gap: var(--spacing-xs);
  }

  .cardContainer {
    margin-top: var(--spacing-xs);
  }

  /* Ensure cards stack properly on mobile */
  .cardContainer > * {
    width: 100%;
    margin-bottom: var(--spacing-xs);
  }
}