/* File: frontend/src/pages/InvestorPitch/components/slides/InsuranceFutureSlide/InsuranceFutureSlide.module.css */

.container {
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: var(--spacing-xl);
  }
  
  .header {
    text-align: center;
    margin-bottom: var(--spacing-2xl);
  }
  
  .title {
    font-size: var(--font-size-5xl);
    font-weight: var(--font-weight-bold);
    color: var(--text-on-primary);
    margin-bottom: var(--spacing-lg);
    line-height: var(--line-height-tight);
  }
  
  .subtitle {
    font-size: var(--font-size-2xl);
    color: var(--color-gray-400);
    max-width: 800px;
    margin: 0 auto;
    line-height: var(--line-height-relaxed);
  }
  
  .statsGrid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: var(--spacing-xl);
    padding: 0 var(--spacing-xl);
  }
  
  .description {
    font-size: var(--font-size-lg);
    color: var(--color-gray-300);
    max-width: 900px;
    margin: var(--spacing-2xl) auto 0;
    text-align: center;
    line-height: var(--line-height-relaxed);
  }
  
  .highlight {
    color: var(--color-primary);
    font-weight: var(--font-weight-semibold);
  }
  
  @media (max-width: var(--breakpoint-md)) {
    .title {
      font-size: var(--font-size-4xl);
    }
  
    .subtitle {
      font-size: var(--font-size-xl);
      max-width: 600px;
    }
  
    .statsGrid {
      grid-template-columns: 1fr;
      gap: var(--spacing-lg);
      padding: 0;
    }
  
    .description {
      font-size: var(--font-size-base);
      max-width: 600px;
      margin-top: var(--spacing-xl);
    }
  }
  
  @media (max-width: var(--breakpoint-sm)) {
    .title {
      font-size: var(--font-size-3xl);
    }
  
    .subtitle {
      font-size: var(--font-size-lg);
    }
  }