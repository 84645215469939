/* File: frontend/src/pages/AI1/components/AgentSelector/AgentSelector.module.css */

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: var(--z-index-modal);
}

.modal {
  background-color: var(--color-white);
  border-radius: var(--border-radius-lg);
  width: 90%;
  max-width: 500px;
  max-height: 90vh;
  overflow-y: auto;
  box-shadow: var(--shadow-lg);
}

.header {
  position: sticky;
  top: 0;
  background-color: var(--color-white);
  padding: var(--spacing-md);
  border-bottom: 1px solid var(--color-border);
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 1;
}

.header h3 {
  margin: 0;
  font-size: var(--font-size-lg);
  color: var(--text-primary);
  font-weight: var(--font-weight-semibold);
}

.closeButton {
  background: none;
  border: none;
  cursor: pointer;
  padding: var(--spacing-xxs);
  color: var(--text-tertiary);
  transition: color var(--transition-fast);
}

.closeButton:hover {
  color: var(--text-primary);
}

.searchContainer {
  position: sticky;
  top: var(--header-height);
  background-color: var(--color-white);
  padding: var(--spacing-md);
  border-bottom: 1px solid var(--color-border);
  z-index: 1;
}

.searchInput {
  width: 100%;
  padding: var(--spacing-sm);
  border: 1px solid var(--color-border);
  border-radius: var(--border-radius-md);
  font-size: var(--font-size-base);
  transition: all var(--transition-fast);
}

.searchInput:focus {
  outline: none;
  border-color: var(--color-primary);
  box-shadow: var(--focus-ring);
}

.content {
  padding: var(--spacing-md);
  display: flex;
  flex-direction: column;
  gap: var(--spacing-md);
}

.categoryGroup {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-sm);
}

.categoryTitle {
  margin: 0;
  padding: var(--spacing-xs) 0;
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-medium);
  color: var(--text-secondary);
  text-transform: uppercase;
  letter-spacing: 0.05em;
}

.agentButton {
  width: 100%;
  padding: var(--spacing-sm) var(--spacing-md);
  border: 1px solid var(--color-border);
  border-radius: var(--border-radius-md);
  background-color: var(--color-white);
  text-align: left;
  cursor: pointer;
  transition: all var(--transition-fast);
}

.agentButton:hover:not(.disabled) {
  background-color: var(--bg-hover);
  border-color: var(--color-border-hover);
  box-shadow: var(--shadow-sm);
}

.agentButton.selected {
  border-color: var(--color-primary);
  background-color: var(--color-primary-100);
}

.agentButton.disabled {
  background-color: var(--bg-disabled);
  border-color: var(--color-border);
  cursor: not-allowed;
  opacity: 0.8;
}

.agentButton.disabled:hover {
  box-shadow: none;
}

.agentInfo {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xxs);
}

.agentHeader {
  display: flex;
  align-items: center;
  gap: var(--spacing-sm);
  margin-bottom: var(--spacing-xxs);
}

.agentName {
  font-weight: var(--font-weight-medium);
  color: var(--text-primary);
  font-size: var(--font-size-base);
}

.agentDescription {
  font-size: var(--font-size-sm);
  color: var(--text-secondary);
  line-height: var(--line-height-snug);
}

.agentTags {
  display: flex;
  gap: var(--spacing-xs);
  margin-left: auto;
}

.tagIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: var(--border-radius-sm);
  transition: all var(--transition-fast);
}

.tagIcon[title="Premium"] {
  background-color: var(--color-orange-110);
  color: var(--color-orange-310);
}

.tagIcon[title="Enterprise"] {
  background-color: var(--color-blue-110);
  color: var(--color-blue-310);
}

.tagIcon img {
  width: 16px;
  height: 16px;
}

.tagIcon:hover::after {
  content: attr(title);
  position: absolute;
  background-color: var(--color-gray-1200);
  color: var(--color-white);
  padding: var(--spacing-xxs) var(--spacing-xs);
  border-radius: var(--border-radius-sm);
  font-size: var(--font-size-xs);
  white-space: nowrap;
  transform: translateY(24px);
  z-index: var(--z-index-tooltip);
}

.tag {
  display: inline-flex;
  align-items: center;
  gap: var(--spacing-xxs);
  padding: var(--spacing-xxxs) var(--spacing-xs);
  border-radius: var(--border-radius-sm);
  font-size: var(--font-size-xs);
  font-weight: var(--font-weight-medium);
  line-height: var(--line-height-tight);
}

.premiumTag {
  background-color: var(--color-yellow-110);
  color: var(--color-yellow-310);
  border: 1px solid var(--color-yellow-210);
}

.enterpriseTag {
  background-color: var(--color-blue-110);
  color: var(--color-blue-310);
  border: 1px solid var(--color-blue-210);
}

.accessRequirement {
  margin-top: var(--spacing-xs);
  padding: var(--spacing-xs);
  background-color: var(--color-hint-bg);
  border-radius: var(--border-radius-sm);
  font-size: var(--font-size-xs);
  color: var(--color-hint-text);
  line-height: var(--line-height-snug);
  text-align: center;
}

.noResults {
  text-align: center;
  padding: var(--spacing-lg);
  color: var(--text-secondary);
  font-size: var(--font-size-sm);
  font-style: italic;
}