/* File: frontend/src/components/StepIndicator/StepIndicator.module.css */

.stepSignupGroup {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-md);
  font-family: var(--font-primary);
  width: 100%;
}

.stepSignup {
  position: relative;
  width: 100%;
  border-radius: var(--border-radius-xl);
  background-color: var(--bg-primary);
  border: 1px solid var(--color-border);
  padding: var(--spacing-lg);
  transition: var(--transition-normal);
}

/* .stepSignup::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 20px;
  right: 20px;
  height: 2px;
  background-color: var(--color-border);
  transform: translateY(-50%);
  z-index: 0;
} */

.stepSignup.active {
  background-color: var(--color-info-bg);
  border-color: var(--color-info-border);
  box-shadow: var(--shadow-sm);
}

.stepSignup.completed {
  background-color: var(--color-success-bg);
  border-color: var(--color-success-border);
}

.stepContent {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--spacing-sm);
  z-index: 1;
}

.badgeSignup {
  width: 40px;
  height: 40px;
  border-radius: var(--border-radius-full);
  background-color: var(--bg-primary);
  border: 1px solid var(--color-border);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: var(--transition-normal);
}

.stepSignup.active .badgeSignup {
  border-color: var(--color-primary);
  background-color: var(--color-primary);
  box-shadow: var(--shadow-sm);
}

.stepSignup.completed .badgeSignup {
  border-color: var(--color-success);
  background-color: var(--color-success);
}

.badgeNumber {
  font-size: var(--font-size-lg);
  font-weight: var(--font-weight-semibold);
  color: var(--text-primary);
  line-height: var(--line-height-tight);
}

.checkmark {
  font-size: var(--font-size-lg);
  color: var(--color-white);
  line-height: var(--line-height-tight);
}

.stepSignup.active .badgeNumber {
  color: var(--color-white);
}

.stepLabel {
  font-size: var(--font-size-lg);
  font-weight: var(--font-weight-medium);
  color: var(--text-primary);
  line-height: var(--line-height-snug);
}

/* Large screens (1200px and up) */
@media (min-width: 1200px) {
  .stepSignup {
    padding: var(--spacing-xl);
  }
}

/* Medium-large screens (1024px and down) */
@media (max-width: 1024px) {
  .stepSignupGroup {
    flex-direction: row;
    gap: var(--spacing-sm);
  }

  .stepSignup {
    flex: 1;
    padding: var(--spacing-md);
  }

  .stepContent {
    align-items: center;
    text-align: center;
  }

  /* .stepSignup::before {
    left: -50%;
    right: 50%;
    top: 20px;
    width: 100%;
    height: 2px;
  } */
}

/* Tablet screens (768px and down) */
@media (max-width: 768px) {
  .stepSignup {
    padding: var(--spacing-sm);
  }

  .badgeSignup {
    width: 32px;
    height: 32px;
  }

  .badgeNumber,
  .checkmark {
    font-size: var(--font-size-base);
  }

  .stepLabel {
    font-size: var(--font-size-sm);
    display: none; /* Hide labels on mobile */
  }
}

/* Mobile screens (480px and down) */
@media (max-width: 480px) {
  .stepSignupGroup {
    gap: var(--spacing-xs);
  }

  .stepSignup {
    padding: var(--spacing-xs);
  }

  .badgeSignup {
    width: 28px;
    height: 28px;
  }

  .badgeNumber,
  .checkmark {
    font-size: var(--font-size-sm);
  }
}

/* Animations */
@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes scaleIn {
  from { transform: scale(0.9); }
  to { transform: scale(1); }
}

.stepSignup {
  animation: fadeIn 0.3s ease-out;
}

.badgeSignup {
  animation: scaleIn 0.3s ease-out;
}

/* Print styles */
@media print {
  .stepSignupGroup {
    display: none;
  }
}



/* // File: frontend/src/components/StepIndicator/StepIndicator.module.css */
/* .stepIndicator {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 1rem 0;
  position: relative;
}

.step {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  flex: 1;
}

.stepNumber {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: var(--color-background);
  border: 2px solid var(--color-border);
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  color: var(--color-text-secondary);
  transition: all 0.3s ease;
  z-index: 1;
}

.stepLabel {
  margin-top: 0.5rem;
  font-size: 0.875rem;
  color: var(--color-text-secondary);
  text-align: center;
  transition: color 0.3s ease;
}

.connector {
  position: absolute;
  top: 16px;
  left: 50%;
  width: 100%;
  height: 2px;
  background-color: var(--color-border);
  z-index: 0;
}

.step.completed .stepNumber {
  background-color: var(--color-primary);
  border-color: var(--color-primary);
  color: white;
}

.step.completed .stepLabel {
  color: var(--color-primary);
}

.step.current .stepNumber {
  border-color: var(--color-primary);
  color: var(--color-primary);
}

.step.current .stepLabel {
  color: var(--color-primary);
  font-weight: 600;
}

.checkmark {
  width: 16px;
  height: 16px;
  fill: currentColor;
}

@media (max-width: 480px) {
  .stepLabel {
    font-size: 0.75rem;
  }
  
  .stepNumber {
    width: 28px;
    height: 28px;
  }
} */