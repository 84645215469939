/* File: frontend/src/pages/SuperAdmin/components/common/SuperAdminPagination/SuperAdminPagination.module.css */

.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: var(--spacing-xs);
}

.pageButton {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 32px;
  height: 32px;
  padding: 0 var(--spacing-xs);
  background-color: var(--bg-primary);
  border: 1px solid var(--color-border);
  border-radius: var(--border-radius-sm);
  color: var(--text-primary);
  font-size: var(--font-size-sm);
  cursor: pointer;
  transition: all var(--transition-fast);
}

.pageButton:hover:not(:disabled) {
  background-color: var(--bg-hover);
  border-color: var(--color-primary);
  color: var(--color-primary);
}

.pageButton:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.activePage {
  background-color: var(--color-primary);
  border-color: var(--color-primary);
  color: white;
}

.activePage:hover {
  background-color: var(--color-primary) !important;
  color: white !important;
}

.ellipsis {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 32px;
  height: 32px;
  color: var(--text-secondary);
  font-size: var(--font-size-sm);
}