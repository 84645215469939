/* File: frontend/src/pages/AnimationGeneration/InsuranceModel.module.css */

.insurance-model {
  padding: 1.5rem;
  background: #ffffff;
  border-radius: 12px;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
}

.insurance-model__title {
  font-size: 1.5rem;
  font-weight: 600;
  color: #1f2937;
  margin-bottom: 1.5rem;
}

.insurance-model__chart {
  height: 550px;
  margin-bottom: 2rem;
  background: #f9fafb;
  border-radius: 8px;
  padding: 1rem;
  border: 1px solid #e5e7eb;
}

.insurance-model__stats {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1.5rem;
  margin-top: var(--spacing-xl);
}

.insurance-model__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: var(--spacing-md);
}

.insurance-model__select {
    padding: var(--spacing-xs) var(--spacing-sm);
    border-radius: var(--border-radius-md);
    border: 1px solid var(--color-border);
    background: var(--bg-primary);
    color: var(--text-primary);
    font-size: var(--font-size-sm);
}

.summary-table {
    width: 100%;
    margin-bottom: var(--spacing-lg);
    border-collapse: collapse;
    background: var(--bg-primary);
    border-radius: var(--border-radius-md);
    overflow: hidden;
}

.summary-table th,
.summary-table td {
    padding: var(--spacing-sm);
    text-align: left;
    border-bottom: 1px solid var(--color-border);
}

.summary-table th {
    background: var(--bg-secondary);
    font-weight: var(--font-weight-semibold);
    color: var(--text-primary);
}

.summary-table tbody tr:hover {
    background: var(--bg-secondary);
}

.summary-table tfoot {
    font-weight: var(--font-weight-semibold);
    background: var(--bg-secondary);
}

.methodology-container {
    background: var(--bg-primary);
    border-radius: var(--border-radius-lg);
    padding: var(--spacing-lg);
    margin-top: var(--spacing-xl);
    margin-bottom: var(--spacing-xl);
    border: 1px solid var(--color-border);
}

.methodology-title {
    font-size: var(--font-size-xl);
    color: var(--text-primary);
    margin-bottom: var(--spacing-lg);
}

.methodology-section {
    margin-bottom: var(--spacing-xl);
}

.methodology-section h4 {
    color: var(--text-primary);
    font-size: var(--font-size-lg);
    margin-bottom: var(--spacing-md);
}

.workflow-examples {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: var(--spacing-md);
    margin-top: var(--spacing-md);
}

.workflow-category {
    background: var(--bg-secondary);
    padding: var(--spacing-md);
    border-radius: var(--border-radius-md);
}

.workflow-category h5 {
    color: var(--text-primary);
    margin-bottom: var(--spacing-sm);
}

.parameters-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: var(--spacing-md);
}

.parameter-card {
    background: var(--bg-secondary);
    padding: var(--spacing-md);
    border-radius: var(--border-radius-md);
}

.parameter-table {
    width: 100%;
}

.parameter-table td {
    padding: var(--spacing-xs);
}

.methodology-highlight {
    background: var(--color-primary-100);
    padding: var(--spacing-md);
    border-radius: var(--border-radius-md);
    margin: var(--spacing-md) 0;
}

.methodology-benefits {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: var(--spacing-md);
    margin-top: var(--spacing-md);
}

.methodology-benefits li {
    background: var(--bg-secondary);
    padding: var(--spacing-sm);
    border-radius: var(--border-radius-sm);
}

/* Waterfall Chart Styles */
.waterfall-container {
    background: var(--bg-primary);
    border-radius: var(--border-radius-lg);
    padding: var(--spacing-lg);
    margin: var(--spacing-lg) 0;
    border: 1px solid var(--color-border);
    box-shadow: var(--shadow-sm);
}

.waterfall-title {
    font-size: var(--font-size-xl);
    color: var(--text-primary);
    margin-bottom: var(--spacing-md);
    font-weight: var(--font-weight-semibold);
    font-family: var(--font-primary);
}

.waterfall-chart {
    background: var(--bg-secondary);
    border-radius: var(--border-radius-md);
    padding: var(--spacing-md);
    border: 1px solid var(--color-border);
}

.waterfall-tooltip {
    background: var(--bg-primary);
    border: 1px solid var(--color-border);
    border-radius: var(--border-radius-md);
    padding: var(--spacing-sm);
    box-shadow: var(--shadow-md);
}

.tooltip-label {
    font-size: var(--font-size-sm);
    color: var(--text-primary);
    font-weight: var(--font-weight-semibold);
    margin: 0 0 var(--spacing-xxs);
    font-family: var(--font-primary);
}

.tooltip-value {
    font-size: var(--font-size-sm);
    color: var(--text-secondary);
    margin: 0 0 var(--spacing-xxxs);
    font-family: var(--font-primary);
}

.tooltip-percentage {
    font-size: var(--font-size-xs);
    color: var(--text-tertiary);
    margin: 0;
    font-family: var(--font-primary);
}

/* Statistical Parameters Styles */
.stats-parameters {
    background: var(--bg-primary);
    border-radius: var(--border-radius-lg);
    padding: var(--spacing-lg);
    margin-top: var(--spacing-xl);
    border: 1px solid var(--color-border);
}

.stats-parameters__title {
    font-size: var(--font-size-xl);
    color: var(--text-primary);
    margin-bottom: var(--spacing-lg);
}

.stats-parameters__grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: var(--spacing-md);
}

.stats-card {
    background: var(--bg-secondary);
    border-radius: var(--border-radius-md);
    padding: var(--spacing-md);
}

.stats-card h4 {
    color: var(--text-primary);
    font-size: var(--font-size-lg);
    margin-bottom: var(--spacing-md);
}

.stats-card table {
    width: 100%;
}

.stats-card td {
    padding: var(--spacing-xs);
    color: var(--text-secondary);
}

.stats-card td:first-child {
    font-weight: var(--font-weight-medium);
    width: 40%;
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .stats-parameters__grid {
        grid-template-columns: 1fr;
    }
    
    .waterfall-container {
        padding: var(--spacing-md);
        margin: var(--spacing-md) 0;
    }

    .waterfall-chart {
        padding: var(--spacing-sm);
    }

    .waterfall-title {
        font-size: var(--font-size-lg);
    }
}

@media (max-width: 480px) {
  .insurance-model__stats {
    grid-template-columns: 1fr;
  }

  .waterfall-container {
    padding: var(--spacing-sm);
    margin: var(--spacing-sm) 0;
    }

    .waterfall-chart {
        padding: var(--spacing-xs);
    }

    .waterfall-title {
        font-size: var(--font-size-md);
    }
}

.stats-card {
  padding: 1.25rem;
  background: #f9fafb;
  border-radius: 8px;
  border: 1px solid #e5e7eb;
}

.stats-card__title {
  font-size: 1.125rem;
  font-weight: 600;
  color: #1f2937;
  margin-bottom: 1rem;
}

.stats-card p {
  font-size: 0.875rem;
  color: #4b5563;
  line-height: 1.6;
  margin-bottom: 0.75rem;
}

.stats-card p:last-child {
  margin-bottom: 0;
}

/* Chart customization */
:global(.recharts-cartesian-grid-horizontal line) {
  stroke: #e5e7eb;
}

:global(.recharts-cartesian-grid-vertical line) {
  stroke: #e5e7eb;
  stroke-opacity: 0;
}

:global(.recharts-line) path {
  stroke-width: 2px;
}

:global(.recharts-tooltip-wrapper) {
  background: #ffffff;
  border: 1px solid #e5e7eb;
  border-radius: 6px;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
}

:global(.recharts-default-tooltip) {
  background-color: #ffffff !important;
  border: none !important;
  border-radius: 6px;
  padding: 0.75rem !important;
}

:global(.recharts-tooltip-item) {
  color: #1f2937 !important;
  font-size: 0.875rem !important;
}

:global(.recharts-legend-item-text) {
  color: #1f2937 !important;
  font-size: 0.875rem !important;
}

:global(.recharts-xAxis .recharts-label),
:global(.recharts-yAxis .recharts-label) {
  fill: #4b5563;
  font-size: 0.875rem;
}

:global(.recharts-cartesian-axis-tick-value) {
  fill: #6b7280;
  font-size: 0.75rem;
}