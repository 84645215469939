/* File: frontend/src/pages/InvestorPitch/components/slides/SavingHasNoLanguageSlide/SavingHasNoLanguageSlide.module.css */

.container {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xl);
  padding: var(--spacing-md);
}

.header {
  text-align: center;
  margin-bottom: var(--spacing-md);
}

.title {
  font-size: var(--font-size-4xl);
  font-weight: var(--font-weight-bold);
  color: var(--text-on-primary);
  margin-bottom: var(--spacing-md);
}

.subtitle {
  font-size: var(--font-size-xl);
  color: var(--color-gray-400);
  max-width: 80%;
  margin: 0 auto;
  line-height: 1.4;
}

/* Video container area */
.videoContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: var(--spacing-md) 0;
}

.videoWrapper {
  position: relative;
  width: 80%;
  max-width: 1000px;
  padding-top: 45%; /* Adjusted for typical video aspect ratio */
  background-color: #000;
  border-radius: var(--border-radius-lg);
  overflow: hidden;
  box-shadow: var(--shadow-lg);
  cursor: pointer;
}

.video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
  background-color: #000;
}

/* Loading overlay/spinner when video isn't fully loaded yet */
.loadingOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
}

.loadingSpinner {
  width: 48px;
  height: 48px;
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-top-color: var(--color-primary-500);
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

.playOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.videoWrapper:hover .playOverlay {
  opacity: 1;
}

.playButton {
  width: 80px;
  height: 80px;
  background-color: rgba(35, 72, 237, 0.8);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.2s ease, background-color 0.2s ease;
}

.playButton:hover {
  transform: scale(1.1);
  background-color: var(--color-primary);
}

.errorMessage {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  text-align: center;
  padding: var(--spacing-lg);
}

.retryButton {
  margin-top: var(--spacing-md);
  padding: var(--spacing-sm) var(--spacing-lg);
  background-color: var(--color-primary);
  color: white;
  border: none;
  border-radius: var(--border-radius-md);
  cursor: pointer;
  font-weight: var(--font-weight-medium);
}

.retryButton:hover {
  background-color: var(--color-primary-dark);
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* CTA section */
.ctaSection {
  display: flex;
  justify-content: center;
  margin-top: var(--spacing-md);
}

.ctaButton {
  display: inline-block;
  padding: var(--spacing-md) var(--spacing-lg);
  font-size: var(--font-size-lg);
  font-weight: var(--font-weight-bold);
  color: #fff;
  background-color: var(--color-primary-700);
  border-radius: var(--border-radius-md);
  text-decoration: none;
  position: relative;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3), 
              0 0 15px var(--color-primary-700);
  transition: box-shadow 0.3s ease;
}

.ctaButton:hover {
  box-shadow: 0 0 20px var(--color-gray-700),
              0 0 10px rgba(0, 0, 0, 0.4);
  color: #fff;
}

@media (max-width: 1024px) {
  .videoWrapper {
    width: 90%;
  }
  
  .playButton {
    width: 64px;
    height: 64px;
  }
}

@media (max-width: 768px) {
  .title {
    font-size: var(--font-size-3xl);
  }
  
  .subtitle {
    font-size: var(--font-size-lg);
    max-width: 90%;
  }
  
  .videoWrapper {
    width: 100%;
    padding-top: 56.25%; /* 16:9 aspect ratio */
  }
  
  .loadingSpinner {
    width: 36px;
    height: 36px;
    border-width: 3px;
  }
  
  .playButton {
    width: 56px;
    height: 56px;
  }
  
  .ctaButton {
    font-size: var(--font-size-base);
    padding: var(--spacing-sm) var(--spacing-md);
  }
}

@media (max-width: 480px) {
  .title {
    font-size: var(--font-size-2xl);
  }
  
  .subtitle {
    font-size: var(--font-size-base);
    max-width: 100%;
  }
  
  .videoWrapper {
    padding-top: 56.25%; /* Maintain 16:9 aspect ratio */
  }
  
  .loadingSpinner {
    width: 32px;
    height: 32px;
    border-width: 3px;
  }
  
  .playButton {
    width: 48px;
    height: 48px;
  }
}