/* MetricsChart.module.css */
.chartWithInsights {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xl);
}

.chartSection {
  width: 100%;
}

.insightsContainer {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: var(--spacing-lg);
}

.insightCard {
  padding: var(--spacing-md);
  background: var(--color-gray-100);
  border-radius: var(--border-radius-md);
  border: 1px solid var(--color-border);
}

.insightTitle {
  font-size: var(--font-size-lg);
  font-weight: 600;
  color: var(--color-primary);
  margin-bottom: var(--spacing-md);
}

.insightList {
  list-style: none;
  padding: 0;
  margin: 0;
}

.insightItem {
  display: flex;
  align-items: center;
  padding: var(--spacing-xs) 0;
  color: var(--color-gray-900);
  font-size: var(--font-size-base);
}

.insightItem::before {
  content: "•";
  color: var(--color-primary);
  font-weight: bold;
  margin-right: var(--spacing-sm);
}