/* File: frontend/src/components/layout/MainLayout/MainLayout.module.css */

.mainLayout {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.mainContent {
  flex: 1;
  transition: all var(--transition-normal);
}

/* Mobile menu open state */
.mainLayout.mobileMenuOpen {
  height: 100vh; /* Lock the height when mobile menu is open */
  overflow: hidden; /* Prevent scrolling when mobile menu is open */
}

.mainLayout.mobileMenuOpen .mainContent {
  filter: blur(4px);
  pointer-events: none; /* Prevent interaction with content when menu is open */
}

@media (max-width: 768px) {
  .mainLayout {
    /* padding-top: 60px; Match header height */
  }
}

/* @media (max-width: 1024px) {
  .mainContent {
    padding-left: var(--spacing-md);
    padding-right: var(--spacing-md);
  }
}

@media (max-width: 768px) {
  .mainContent {
    padding-left: var(--spacing-sm);
    padding-right: var(--spacing-sm);
  }
} */