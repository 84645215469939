/* File: frontend/src/components/Auth/EmailVerification.module.css */

.emailVerification {
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  padding: var(--spacing-xl) var(--spacing-lg);
  box-sizing: border-box;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: var(--spacing-lg);
  animation: fadeIn 0.3s ease-out;
}

.emailIcon {
  width: 120px;
  height: 120px;
  object-fit: contain;
  margin-bottom: var(--spacing-md);
}

.textContent {
  max-width: 480px;
}

.title {
  font-size: var(--font-size-2xl);
  font-weight: var(--font-weight-semibold);
  color: var(--text-primary);
  margin-bottom: var(--spacing-sm);
  line-height: var(--line-height-tight);
}

.description {
  font-size: var(--font-size-base);
  color: var(--text-secondary);
  line-height: var(--line-height-normal);
  margin-bottom: var(--spacing-md);
}

.emailSentInfo {
  width: 100%;
  max-width: 400px;
  background-color: var(--bg-secondary);
  border-radius: var(--border-radius-lg);
  padding: var(--spacing-md);
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xxs);
}

.emailAddress {
  font-weight: var(--font-weight-medium);
  color: var(--text-primary);
  word-break: break-all;
}

.emailClientList {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: var(--spacing-sm);
  width: 100%;
  max-width: 440px;
}

.emailClientButton {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: var(--spacing-sm) var(--spacing-md);
  background-color: var(--bg-primary);
  border: 1px solid var(--color-border);
  border-radius: var(--border-radius-md);
  font-size: var(--font-size-sm);
  color: var(--text-primary);
  text-decoration: none;
  transition: all var(--transition-normal);
}

.emailClientButton:hover {
  background-color: var(--bg-secondary);
  border-color: var(--color-border-hover);
  box-shadow: var(--shadow-sm);
}

.emailClientIcon {
  width: 20px;
  height: 20px;
  margin-right: var(--spacing-sm);
  object-fit: contain;
}

.resendSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--spacing-xs);
}

.resendText {
  font-size: var(--font-size-sm);
  color: var(--text-secondary);
}

.resendButton {
  background: none;
  border: none;
  color: var(--color-primary);
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-medium);
  cursor: pointer;
  padding: var(--spacing-xs) var(--spacing-sm);
  border-radius: var(--border-radius-sm);
  transition: all var(--transition-normal);
}

.resendButton:hover:not(:disabled) {
  background-color: var(--bg-secondary);
  color: var(--color-primary-dark);
}

.resendButton:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

/* Loading state */
.loading {
  opacity: 0.7;
  pointer-events: none;
}

/* Responsive styles */
@media (min-width: 1200px) {
  .emailVerification {
    padding: var(--spacing-2xl) var(--spacing-xl);
  }

  .emailIcon {
    width: 140px;
    height: 140px;
  }
}

@media (max-width: 768px) {
  .emailVerification {
    padding: var(--spacing-lg) var(--spacing-md);
  }

  .emailIcon {
    width: 100px;
    height: 100px;
  }

  .title {
    font-size: var(--font-size-xl);
  }

  .description {
    font-size: var(--font-size-sm);
  }

  .emailClientList {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 480px) {
  .emailVerification {
    padding: var(--spacing-md) var(--spacing-sm);
  }

  .emailIcon {
    width: 80px;
    height: 80px;
  }

  .emailSentInfo {
    padding: var(--spacing-sm);
  }

  .emailClientButton {
    padding: var(--spacing-xs) var(--spacing-sm);
  }
}

/* Animations */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Print styles */
@media print {
  .emailVerification {
    padding: 0;
  }

  .emailClientList,
  .resendSection {
    display: none;
  }
}