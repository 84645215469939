/* charts/v2/types/ColumnChart.module.css */
.chartContainer {
    width: 100%;
    background: var(--color-white);
    border-radius: var(--border-radius-lg);
    padding: var(--spacing-lg);
    box-shadow: var(--shadow-card);
}

.tooltip {
    background-color: var(--color-white);
    border: 1px solid var(--color-border);
    padding: var(--spacing-md);
    border-radius: var(--border-radius-md);
    box-shadow: var(--shadow-lg);
    min-width: 160px;
}

.tooltipLabel {
    font-family: var(--font-primary);
    font-weight: var(--font-weight-semibold);
    margin-bottom: var(--spacing-xs);
    color: var(--text-primary);
    font-size: var(--font-size-sm);
    line-height: var(--line-height-snug);
}

.tooltipRow {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: var(--spacing-sm);
    margin: var(--spacing-xxs) 0;
    font-size: var(--font-size-sm);
    line-height: var(--line-height-snug);
}

.tooltipMetric {
    font-weight: var(--font-weight-medium);
    color: var(--text-secondary);
}

.tooltipValue {
    font-family: var(--font-primary);
    font-weight: var(--font-weight-semibold);
    color: var(--text-primary);
}

.tableContainer {
    margin-top: var(--spacing-xl);
    overflow-x: auto;
    border: 1px solid var(--color-border);
    border-radius: var(--border-radius-lg);
    background: var(--color-white);
    box-shadow: var(--shadow-sm);
}

.dataTable {
    width: 100%;
    border-collapse: collapse;
    font-family: var(--font-primary);
    font-size: var(--font-size-sm);
}

.dataTable th,
.dataTable td {
    padding: var(--spacing-md);
    text-align: right;
    border-bottom: 1px solid var(--color-border);
    line-height: var(--line-height-snug);
}

.dataTable th {
    background-color: var(--bg-secondary);
    font-weight: var(--font-weight-semibold);
    color: var(--text-primary);
    white-space: nowrap;
}

.dataTable th:first-child,
.dataTable td:first-child {
    text-align: left;
    position: sticky;
    left: 0;
    background: var(--color-white);
    border-right: 1px solid var(--color-border);
    font-weight: var(--font-weight-medium);
}

.dataTable th:first-child {
    background: var(--bg-secondary);
    z-index: 1;
}

.dataTable tr:hover td {
    background-color: var(--bg-hover);
}

.totalRow {
    font-weight: var(--font-weight-semibold);
    background-color: var(--bg-secondary);
}

.rowTotal {
    font-weight: var(--font-weight-semibold);
    color: var(--color-primary);
    background-color: var(--bg-secondary);
    border-left: 1px solid var(--color-border);
}

.grandTotal {
    font-weight: var(--font-weight-bold);
    color: var(--color-primary);
    background-color: var(--color-primary-light);
}