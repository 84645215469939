/* File: frontend/src/components/MileMonitor/MileMonitorContainer.module.css */

.mileMonitorContainer {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .section {
    margin-bottom: 40px;
  }
  
  .chart {
    width: 100%;
    max-width: 581px;
    border: 1px solid #F7F7F8;
    padding: 20px;
    margin-bottom: 20px;
  }
  
  .chartTabs {
    display: flex;
    gap: 10px;
    margin-bottom: 20px;
  }
  
  .chartTabs button {
    padding: 8px 16px;
    background-color: #F7F7F8;
    border: none;
    border-radius: 12px;
    cursor: pointer;
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-weight: 500;
  }
  
  .chartTabs button.active {
    background-color: white;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.03);
  }
  
  .insightSection {
    display: flex;
    align-items: center;
    gap: 14px;
    padding: 4px;
    background-color: #DBE6FE;
    border-radius: 14px;
    margin-top: 20px;
  }
  
  .insightIcon {
    padding: 8px;
    background-color: white;
    border-radius: 10px;
  }
  
  .iconInner {
    width: 10.65px;
    height: 14px;
    position: relative;
    border: 2px solid #1D2B8B;
  }
  
  .iconInner::before {
    content: '';
    position: absolute;
    width: 4.67px;
    height: 4.67px;
    left: 2.92px;
    top: 3.11px;
    background: linear-gradient(180deg, rgba(32.87, 43.42, 127.87, 0.60) 0%, rgba(22, 29, 85, 0.60) 100%);
  }
  
  .insightText {
    flex: 1;
    color: #3667F8;
    font-size: 14px;
    font-family: Inter, sans-serif;
    font-weight: 500;
    line-height: 18px;
  }
  
  @media (max-width: 768px) {
    .chart {
      max-width: 100%;
    }
  
    .chartTabs {
      flex-direction: column;
    }
  }