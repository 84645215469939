/* File: frontend/src/pages/SuperAdmin/components/CommunicationManagement/CommunicationLogs/CommunicationLogs.module.css */

.container {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-md);
    position: relative;
  }
  
  .toolbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: var(--spacing-md);
    flex-wrap: wrap;
    gap: var(--spacing-md);
  }
  
  .filters {
    display: flex;
    align-items: center;
    gap: var(--spacing-md);
    flex: 1;
    flex-wrap: wrap;
}

.filterGroup {
  display: flex;
  align-items: center;
  gap: var(--spacing-xs);
}

.filterLabel {
  font-size: var(--font-size-sm);
  color: var(--text-secondary);
}

.filterSelect {
  padding: var(--spacing-xs) var(--spacing-sm);
  border: 1px solid var(--color-border);
  border-radius: var(--border-radius-sm);
  background-color: var(--bg-primary);
  color: var(--text-primary);
  font-size: var(--font-size-sm);
}

.dateRangeFilters {
  display: flex;
  gap: var(--spacing-md);
}

.dateInput {
  padding: var(--spacing-xs) var(--spacing-sm);
  border: 1px solid var(--color-border);
  border-radius: var(--border-radius-sm);
  background-color: var(--bg-primary);
  color: var(--text-primary);
  font-size: var(--font-size-sm);
}

.exportButton {
  display: flex;
  align-items: center;
  gap: var(--spacing-xs);
  padding: var(--spacing-sm) var(--spacing-md);
  background-color: var(--bg-secondary);
  color: var(--text-primary);
  border: 1px solid var(--color-border);
  border-radius: var(--border-radius-md);
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-medium);
  cursor: pointer;
  transition: all var(--transition-fast);
}

.exportButton:hover {
  background-color: var(--bg-hover);
  color: var(--color-primary);
  border-color: var(--color-primary);
}

.error {
  padding: var(--spacing-md);
  background-color: var(--color-error-bg);
  color: var(--color-error);
  border-radius: var(--border-radius-md);
  margin-bottom: var(--spacing-md);
}

.pagination {
  display: flex;
  justify-content: center;
  margin-top: var(--spacing-md);
}

.typeCell {
  display: flex;
  align-items: center;
  gap: var(--spacing-xs);
}

.typeCell i {
  color: var(--color-primary);
}

.recipientCell {
  font-weight: var(--font-weight-medium);
}

.templateCell {
  color: var(--color-primary);
}

.statusBadge {
  display: inline-block;
  padding: 2px 8px;
  border-radius: 12px;
  font-size: var(--font-size-xs);
  font-weight: var(--font-weight-medium);
  text-transform: capitalize;
}

.statusSent {
  background-color: var(--color-success-bg);
  color: var(--color-success);
}

.statusFailed {
  background-color: var(--color-error-bg);
  color: var(--color-error);
}

.statusPending {
  background-color: var(--color-warning-bg);
  color: var(--color-warning);
}

.actions {
  display: flex;
  gap: var(--spacing-xs);
}

.actionButton {
  display: flex;
  align-items: center;
  gap: var(--spacing-xxs);
  padding: var(--spacing-xs) var(--spacing-sm);
  background-color: var(--bg-secondary);
  border: 1px solid var(--color-border);
  border-radius: var(--border-radius-sm);
  font-size: var(--font-size-xs);
  color: var(--text-primary);
  cursor: pointer;
  transition: all var(--transition-fast);
}

.actionButton:hover {
  background-color: var(--bg-hover);
  color: var(--color-primary);
  border-color: var(--color-primary);
}

/* Modal Styles */
.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal {
  background-color: var(--bg-primary);
  border-radius: var(--border-radius-lg);
  width: 90%;
  max-width: 800px;
  max-height: 90vh;
  display: flex;
  flex-direction: column;
  box-shadow: var(--shadow-lg);
}

.modalHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: var(--spacing-md);
  border-bottom: 1px solid var(--color-border);
}

.modalTitle {
  font-size: var(--font-size-lg);
  font-weight: var(--font-weight-semibold);
  color: var(--text-primary);
  margin: 0;
}

.closeButton {
  background: none;
  border: none;
  color: var(--text-secondary);
  font-size: var(--font-size-lg);
  cursor: pointer;
  transition: color var(--transition-fast);
}

.closeButton:hover {
  color: var(--color-error);
}

.modalContent {
  padding: var(--spacing-md);
  overflow-y: auto;
  flex: 1;
}

.detailSection {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-md);
}

.detailHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: var(--spacing-sm);
}

.detailType {
  display: flex;
  align-items: center;
  gap: var(--spacing-xs);
  font-size: var(--font-size-lg);
  font-weight: var(--font-weight-medium);
  color: var(--text-primary);
}

.detailType i {
  color: var(--color-primary);
}

.detailGrid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: var(--spacing-md);
}

.detailItem {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xxs);
}

.detailLabel {
  font-size: var(--font-size-sm);
  color: var(--text-secondary);
  font-weight: var(--font-weight-medium);
}

.detailValue {
  color: var(--text-primary);
  word-break: break-word;
}

.contentBox {
  padding: var(--spacing-md);
  background-color: var(--bg-secondary);
  border-radius: var(--border-radius-md);
  border: 1px solid var(--color-border-light);
  margin-top: var(--spacing-xs);
}

.emailContent {
  max-height: 300px;
  overflow-y: auto;
}

.smsContent {
  font-family: var(--font-family-mono);
  font-size: var(--font-size-sm);
  white-space: pre-wrap;
}

.errorMessage {
  color: var(--color-error);
  padding: var(--spacing-sm);
  background-color: var(--color-error-bg);
  border-radius: var(--border-radius-sm);
  margin-top: var(--spacing-xs);
}

.modalFooter {
  display: flex;
  justify-content: flex-end;
  gap: var(--spacing-md);
  padding: var(--spacing-md);
  border-top: 1px solid var(--color-border);
}

.closeModalButton, .resendButton {
  padding: var(--spacing-sm) var(--spacing-lg);
  border-radius: var(--border-radius-md);
  font-size: var(--font-size-base);
  font-weight: var(--font-weight-medium);
  cursor: pointer;
  transition: all var(--transition-fast);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: var(--spacing-xs);
}

.closeModalButton {
  background-color: var(--bg-secondary);
  color: var(--text-primary);
  border: 1px solid var(--color-border);
}

.closeModalButton:hover {
  background-color: var(--bg-hover);
}

.resendButton {
  background-color: var(--color-primary);
  color: white;
  border: none;
}

.resendButton:hover {
  background-color: var(--color-primary-dark);
}

@media (max-width: 992px) {
  .detailGrid {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 768px) {
  .toolbar {
    flex-direction: column;
    align-items: stretch;
  }
  
  .filters {
    flex-direction: column;
    align-items: stretch;
  }
  
  .dateRangeFilters {
    flex-direction: column;
  }
  
  .exportButton {
    width: 100%;
    justify-content: center;
  }
  
  .modal {
    width: 95%;
    max-height: 95vh;
  }
  
  .modalFooter {
    flex-direction: column;
  }
  
  .closeModalButton, .resendButton {
    width: 100%;
  }
}