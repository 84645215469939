/* File: frontend/src/pages/SuperAdmin/components/AuditLogs/AgreementsAuditLog/AgreementsAuditLog.module.css */

.container {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-md);
}

.toolbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: var(--spacing-md);
}

.filters {
  display: flex;
  align-items: center;
  gap: var(--spacing-md);
  flex: 1;
}

.filterGroup {
  display: flex;
  align-items: center;
  gap: var(--spacing-xs);
}

.filterLabel {
  font-size: var(--font-size-sm);
  color: var(--text-secondary);
}

.filterSelect {
  padding: var(--spacing-xs) var(--spacing-sm);
  border: 1px solid var(--color-border);
  border-radius: var(--border-radius-sm);
  background-color: var(--bg-primary);
  color: var(--text-primary);
  font-size: var(--font-size-sm);
}

.error {
  padding: var(--spacing-md);
  background-color: var(--color-error-bg);
  color: var(--color-error);
  border-radius: var(--border-radius-md);
  margin-bottom: var(--spacing-md);
}

.pagination {
  display: flex;
  justify-content: center;
  margin-top: var(--spacing-md);
}

.enterpriseName {
  font-weight: var(--font-weight-medium);
  color: var(--color-primary);
}

.idCell {
  font-family: var(--font-family-mono);
  font-size: var(--font-size-sm);
  color: var(--text-secondary);
}

.actionTypeBadge {
  display: inline-block;
  padding: 2px 8px;
  border-radius: 12px;
  font-size: var(--font-size-xs);
  font-weight: var(--font-weight-medium);
}

.typeInsert {
  background-color: var(--color-success-bg);
  color: var(--color-success);
}

.typeUpdate {
  background-color: var(--color-primary-100);
  color: var(--color-primary);
}

.typeDelete {
  background-color: var(--color-error-bg);
  color: var(--color-error);
}

.actions {
  display: flex;
  gap: var(--spacing-xs);
}

.viewButton {
  display: flex;
  align-items: center;
  gap: var(--spacing-xxs);
  padding: var(--spacing-xs) var(--spacing-sm);
  background-color: var(--bg-secondary);
  border: 1px solid var(--color-border);
  border-radius: var(--border-radius-sm);
  font-size: var(--font-size-xs);
  color: var(--text-primary);
  cursor: pointer;
  transition: all var(--transition-fast);
}

.viewButton:hover {
  background-color: var(--bg-hover);
  color: var(--color-primary);
  border-color: var(--color-primary);
}

/* Details Modal Styles */
.detailsOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.detailsModal {
  background-color: var(--bg-primary);
  border-radius: var(--border-radius-lg);
  width: 90%;
  max-width: 800px;
  max-height: 90vh;
  display: flex;
  flex-direction: column;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
}

.detailsHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: var(--spacing-md) var(--spacing-lg);
  border-bottom: 1px solid var(--color-border);
}

.detailsTitle {
  font-size: var(--font-size-lg);
  font-weight: var(--font-weight-semibold);
  color: var(--text-primary);
  margin: 0;
}

.closeButton {
  background: none;
  border: none;
  color: var(--text-secondary);
  font-size: var(--font-size-lg);
  cursor: pointer;
  transition: color var(--transition-fast);
}

.closeButton:hover {
  color: var(--text-primary);
}

.detailsContent {
  padding: var(--spacing-lg);
  overflow-y: auto;
  flex: 1;
}

.detailsSection {
  margin-bottom: var(--spacing-lg);
}

.detailsSection:last-child {
  margin-bottom: 0;
}

.detailsSectionTitle {
  font-size: var(--font-size-base);
  font-weight: var(--font-weight-semibold);
  color: var(--text-primary);
  margin: 0 0 var(--spacing-md) 0;
  padding-bottom: var(--spacing-xs);
  border-bottom: 1px solid var(--color-border-light);
}

.detailsGrid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: var(--spacing-md);
}

.detailsItem {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xxs);
}

.detailsLabel {
  font-size: var(--font-size-sm);
  color: var(--text-secondary);
  font-weight: var(--font-weight-medium);
}

.detailsValue {
  color: var(--text-primary);
  word-break: break-word;
}

.changesList {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-md);
}

.changeItem {
  padding: var(--spacing-md);
  background-color: var(--bg-secondary);
  border-radius: var(--border-radius-md);
  border: 1px solid var(--color-border-light);
}

.changeField {
  font-weight: var(--font-weight-semibold);
  color: var(--text-primary);
  margin-bottom: var(--spacing-sm);
  padding-bottom: var(--spacing-xs);
  border-bottom: 1px solid var(--color-border-light);
}

.changeValues {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-sm);
}

.changeOldValue, .changeNewValue {
  display: flex;
  align-items: flex-start;
  gap: var(--spacing-sm);
}

.changeLabel {
  font-size: var(--font-size-sm);
  color: var(--text-secondary);
  font-weight: var(--font-weight-medium);
  min-width: 40px;
}

.changeValue {
  color: var(--text-primary);
  word-break: break-word;
}

.changeOldValue .changeValue {
  text-decoration: line-through;
  color: var(--color-error);
}

.changeNewValue .changeValue {
  color: var(--color-success);
}

.detailsFooter {
  padding: var(--spacing-md) var(--spacing-lg);
  border-top: 1px solid var(--color-border);
  display: flex;
  justify-content: flex-end;
}

.closeDetailsButton {
  padding: var(--spacing-sm) var(--spacing-lg);
  background-color: var(--bg-secondary);
  color: var(--text-primary);
  border: 1px solid var(--color-border);
  border-radius: var(--border-radius-md);
  font-size: var(--font-size-base);
  font-weight: var(--font-weight-medium);
  cursor: pointer;
  transition: all var(--transition-fast);
}

.closeDetailsButton:hover {
  background-color: var(--bg-hover);
}

@media (max-width: 992px) {
  .detailsGrid {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 768px) {
  .toolbar {
    flex-direction: column;
    align-items: stretch;
    gap: var(--spacing-sm);
  }
  
  .filters {
    flex-direction: column;
    align-items: stretch;
  }
  
  .detailsModal {
    width: 95%;
    max-height: 95vh;
  }
}