/* File: frontend/src/pages/InvestorPitch/components/slides/InvestmentSlide/ProjectionCard.module.css */

.container {
    background-color: var(--color-gray-1200);
    border-radius: var(--border-radius-lg);
    padding: var(--spacing-xl);
    border: 1px solid var(--color-gray-1100);
    height: 100%;
  }
  
  .header {
    text-align: center;
    margin-bottom: var(--spacing-xl);
  }
  
  .year {
    font-size: var(--font-size-lg);
    font-weight: var(--font-weight-bold);
    color: var(--text-on-primary);
    margin-bottom: var(--spacing-sm);
  }
  
  .target {
    font-size: var(--font-size-3xl);
    font-weight: var(--font-weight-bold);
    margin-bottom: var(--spacing-xs);
  }
  
  .label {
    font-size: var(--font-size-sm);
    color: var(--color-gray-400);
  }
  
  .highlights {
    list-style: none;
    padding: 0;
    margin: 0;
    color: var(--color-gray-400);
    font-size: var(--font-size-base);
    line-height: 1.6;
  }
  
  .highlights li {
    position: relative;
    padding-left: var(--spacing-lg);
    margin-bottom: var(--spacing-sm);
  }
  
  .highlights li::before {
    content: "•";
    position: absolute;
    left: 0;
    color: var(--color-primary-500);
  }

  .metrics {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-md);
    margin: var(--spacing-lg) 0;
  }
  
  .gwp,
  .revenue {
    text-align: center;
  }
  
  .value {
    font-size: var(--font-size-3xl);
    font-weight: var(--font-weight-bold);
    margin-bottom: var(--spacing-xs);
  }
  
  .percentage {
    font-size: var(--font-size-lg);
    font-weight: var(--font-weight-medium);
    margin-bottom: var(--spacing-xs);
  }
  
  .label {
    font-size: var(--font-size-sm);
    color: var(--color-gray-400);
  }
  
  .revenue {
    border-top: 1px solid var(--color-gray-1100);
    padding-top: var(--spacing-md);
    margin-top: var(--spacing-md);
  }
  
  @media (max-width: 768px) {
    .container {
      padding: var(--spacing-lg);
    }
  
    .target {
      font-size: var(--font-size-2xl);
    }
  }