/* File: frontend/src/pages/InvestorPitch/components/slides/GlobalHiringSlide/CompensationCard.module.css */

.container {
    background-color: var(--color-gray-1200);
    border-radius: var(--border-radius-lg);
    padding: var(--spacing-xl);
    border: 1px solid var(--color-gray-1100);
    display: flex;
    flex-direction: column;
    gap: var(--spacing-xl);
  }
  
  .item {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-sm);
  }
  
  .itemHeader {
    display: flex;
    align-items: center;
    gap: var(--spacing-sm);
  }
  
  .icon {
    width: 20px;
    height: 20px;
    color: var(--color-primary-500);
  }
  
  .itemTitle {
    font-size: var(--font-size-base);
    font-weight: var(--font-weight-bold);
    color: var(--text-on-primary);
  }
  
  .range {
    font-size: var(--font-size-lg);
    color: var(--color-primary-500);
    font-weight: var(--font-weight-bold);
    margin-left: calc(20px + var(--spacing-sm));
  }
  
  .details {
    font-size: var(--font-size-sm);
    color: var(--color-gray-400);
    margin-left: calc(20px + var(--spacing-sm));
  }
  
  @media (max-width: 768px) {
    .container {
      padding: var(--spacing-lg);
    }
  
    .range {
      font-size: var(--font-size-base);
    }
  }