/* File: frontend/src/pages/InvestorPitch/components/slides/DataRoomSlide/DataRoomSlide.module.css */

.container {
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: var(--spacing-lg);
}

.header {
  text-align: center;
  margin-bottom: var(--spacing-md);
}

.title {
  font-size: var(--font-size-4xl);
  font-weight: var(--font-weight-bold);
  color: var(--text-on-primary);
  margin-bottom: var(--spacing-sm);
  background: linear-gradient(135deg, var(--color-primary), var(--color-blue-510));
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}

.subtitle {
  font-size: var(--font-size-xl);
  color: var(--color-gray-400);
  max-width: 80%;
  margin: 0 auto;
}

.content {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-lg);
  overflow: hidden;
}

.corporateNote {
  background: var(--color-gray-1100);
  border-radius: var(--border-radius-lg);
  border: 1px solid var(--color-gray-1000);
  padding: var(--spacing-md);
  display: flex;
  gap: var(--spacing-md);
  align-items: flex-start;
}

.noteIcon {
  font-size: var(--font-size-2xl);
  line-height: 1;
  padding-top: var(--spacing-xs);
}

.noteContent {
  flex: 1;
}

.noteContent h4 {
  font-size: var(--font-size-lg);
  font-weight: var(--font-weight-semibold);
  color: var(--color-primary-500);
  margin-bottom: var(--spacing-xs);
}

.noteContent p {
  font-size: var(--font-size-base);
  color: var(--color-gray-300);
  line-height: 1.5;
}

.dataRoomContainer {
  display: flex;
  flex: 1;
  gap: var(--spacing-lg);
  overflow: hidden;
}

.categoriesContainer {
  width: 220px;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xs);
}

.categoryButton {
  background: var(--color-gray-1100);
  border: 1px solid var(--color-gray-1000);
  border-radius: var(--border-radius-md);
  padding: var(--spacing-sm) var(--spacing-md);
  text-align: left;
  font-size: var(--font-size-base);
  color: var(--color-gray-400);
  cursor: pointer;
  transition: all 0.2s ease;
}

.categoryButton:hover {
  background: var(--color-gray-1000);
  color: var(--color-gray-300);
}

.activeCategory {
  background: var(--color-primary-900);
  border-color: var(--color-primary-700);
  color: var(--color-primary-500);
  font-weight: var(--font-weight-medium);
}

.documentsContainer {
  flex: 1;
  background: var(--color-gray-1200);
  border-radius: var(--border-radius-lg);
  border: 1px solid var(--color-gray-1100);
  padding: var(--spacing-lg);
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.categoryTitle {
  font-size: var(--font-size-xl);
  font-weight: var(--font-weight-bold);
  color: var(--color-primary-500);
  margin-bottom: var(--spacing-lg);
  padding-bottom: var(--spacing-sm);
  border-bottom: 1px solid var(--color-gray-1000);
}

.documentsList {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-md);
  overflow-y: auto;
  padding-right: var(--spacing-sm);
}

.documentCard {
  background: var(--color-gray-1100);
  border-radius: var(--border-radius-md);
  border: 1px solid var(--color-gray-1000);
  padding: var(--spacing-md);
  display: flex;
  gap: var(--spacing-md);
  align-items: center;
  cursor: pointer;
  transition: all 0.2s ease;
}

.documentCard:hover:not(.comingSoon) {
  background: var(--color-gray-1000);
  border-color: var(--color-gray-900);
}

.comingSoon {
  opacity: 0.7;
  cursor: default;
}

.documentIcon {
  font-size: var(--font-size-2xl);
  color: var(--color-gray-400);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
}

.documentInfo {
  flex: 1;
}

.documentTitle {
  font-size: var(--font-size-lg);
  font-weight: var(--font-weight-semibold);
  color: var(--color-gray-200);
  margin-bottom: var(--spacing-xs);
  display: flex;
  align-items: center;
  gap: var(--spacing-sm);
}

.comingSoonTag {
  font-size: var(--font-size-xs);
  font-weight: var(--font-weight-normal);
  color: var(--color-gray-400);
  background: var(--color-gray-1000);
  padding: 2px var(--spacing-xs);
  border-radius: var(--border-radius-sm);
}

.documentDescription {
  font-size: var(--font-size-sm);
  color: var(--color-gray-400);
  line-height: 1.4;
}

.downloadButton {
  background: var(--color-primary-900);
  color: var(--color-primary-500);
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-medium);
  padding: var(--spacing-xs) var(--spacing-sm);
  border-radius: var(--border-radius-sm);
  border: 1px solid var(--color-primary-800);
  transition: all 0.2s ease;
}

.documentCard:hover .downloadButton {
  background: var(--color-primary-800);
  color: var(--color-primary-400);
}

.accessNote {
  text-align: center;
  font-size: var(--font-size-base);
  color: var(--color-gray-400);
  padding: var(--spacing-sm);
}

.emailLink {
  color: var(--color-primary-500);
  text-decoration: none;
  font-weight: var(--font-weight-medium);
  transition: color 0.2s ease;
}

.emailLink:hover {
  color: var(--color-primary-400);
  text-decoration: underline;
}

/* Responsive adjustments */
@media (max-width: 1200px) {
  .dataRoomContainer {
    flex-direction: column;
  }
  
  .categoriesContainer {
    width: 100%;
    flex-direction: row;
    flex-wrap: wrap;
  }
  
  .categoryButton {
    flex: 1;
    min-width: 150px;
    text-align: center;
  }
}

@media (max-width: 768px) {
  .title {
    font-size: var(--font-size-3xl);
  }
  
  .subtitle {
    font-size: var(--font-size-lg);
    max-width: 100%;
  }
  
  .documentCard {
    flex-direction: column;
    align-items: flex-start;
  }
  
  .documentIcon {
    margin-bottom: var(--spacing-xs);
  }
  
  .downloadButton {
    margin-top: var(--spacing-sm);
    align-self: flex-end;
  }
}