/* File: frontend/src/pages/AI1/components/ChatContainer/ChatContainer.module.css */

.container {
  flex: 1;
  overflow-y: auto;
  padding: 0 var(--spacing-md);
  background-color: var(--bg-secondary);
  scroll-behavior: smooth;
}

.messagesWrapper {
  max-width: 800px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-md);
}

.messageWrapper {
  display: flex;
  flex-direction: column;
  transition: all 0.3s ease-out;
}

.fastSlideUp {
  animation: fastSlideUpAnimation 0.3s cubic-bezier(0.15, 0.85, 0.35, 1) forwards;
}

.loadingIndicator {
  display: flex;
  gap: 4px;
  padding: var(--spacing-sm);
  align-self: flex-start;
}

.dot {
  width: 8px;
  height: 8px;
  background-color: var(--color-primary);
  border-radius: 50%;
  animation: bounce 1.4s infinite ease-in-out;
}

.dot:nth-child(1) { animation-delay: -0.32s; }
.dot:nth-child(2) { animation-delay: -0.16s; }

@keyframes bounce {
  0%, 80%, 100% { transform: scale(0); }
  40% { transform: scale(1); }
}

@keyframes fastSlideUpAnimation {
  0% {
    transform: translateY(100vh);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}