/* File: frontend/src/pages/EmbeddedInsurance/EmbeddedInsurance.module.css */

.container {
  max-width: var(--breakpoint-xl);
  margin: 0 auto;
  padding: clamp(0.5rem, 3vw, 1.5rem);
  font-family: var(--font-primary);
  min-height: 120vh;
}

.content {
  margin-top: var(--spacing-md);
  background-color: var(--bg-primary);
  border-radius: var(--border-radius-lg);
  box-shadow: var(--shadow-card);
  overflow: hidden;
}

@media (max-width: 768px) {
  .container {
    padding: var(--spacing-sm);
  }
  
  .content {
    margin-top: var(--spacing-sm);
  }
}