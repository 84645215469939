/* File: /frontend/src/pages/Quote/components/ChatInterface/ChatInterface.module.css */

.chatOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000; /* Ensure it's above everything else */
  display: flex;
  justify-content: center;
  align-items: center;
  animation: fadeIn 0.3s ease-out;
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

.chatContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  background-color: white;
  overflow: hidden;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.15);
  position: relative;
}

.chatHeader {
  padding: 16px 20px;
  background-color: #2348ED;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 10;
}

.headerContent {
  display: flex;
  align-items: center;
}

.headerControls {
  display: flex;
  gap: 8px;
}

.controlButton {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  border: none;
  background-color: rgba(255, 255, 255, 0.2);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.2s ease;
}

.controlButton:hover {
  background-color: rgba(255, 255, 255, 0.3);
}

.controlButton.active {
  background-color: white;
  color: #2348ED;
}

.controlButton.locked {
  background-color: #ff4d4f;
}

.controlButton:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.aiLogo {
  width: 36px;
  height: 36px;
  background-color: white;
  color: #2348ED;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  margin-right: 12px;
  font-size: 16px;
}

.headerText h2 {
  font-size: 16px;
  margin: 0;
  font-weight: 600;
}

.headerText p {
  font-size: 12px;
  margin: 2px 0 0;
  opacity: 0.8;
}

.chatContent {
  flex: 1;
  position: relative;
  display: flex;
  overflow: hidden;
}

.messagesContainer {
  flex: 1;
  overflow-y: auto;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  background-color: #f5f7fb;
  height: 100%;
}

.message {
  display: flex;
  flex-direction: column;
  max-width: 70%; /* Reduced from 80% to make bubbles narrower */
}

.user {
  align-self: flex-end;
}

.ai {
  align-self: flex-start;
}

/* For very long words or URLs, ensure they don't overflow */
.messageBubble {
  padding: 12px 16px;
  border-radius: 18px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  font-size: 16px;
  line-height: 1.5;
  white-space: pre-wrap;
  word-wrap: break-word;
  overflow-wrap: break-word;
  word-break: break-word;
}

.user .messageBubble {
  background-color: #2348ED;
  color: white;
  border-bottom-right-radius: 4px;
}

.ai .messageBubble {
  background-color: white;
  color: #333;
  border-bottom-left-radius: 4px;
}

.messageTime {
  font-size: 12px;
  color: #999;
  margin-top: 4px;
  padding: 0 4px;
}

.typingIndicator {
  display: flex;
  align-items: center;
  column-gap: 4px;
  padding: 4px 8px;
}

.typingIndicator span {
  width: 8px;
  height: 8px;
  background-color: #999;
  border-radius: 50%;
  animation: typing 1.4s infinite ease-in-out both;
}

.typingIndicator span:nth-child(1) {
  animation-delay: -0.32s;
}

.typingIndicator span:nth-child(2) {
  animation-delay: -0.16s;
}

.inputForm {
  display: flex;
  padding: 16px;
  border-top: 1px solid #eee;
  background-color: #f5f7fb;
  box-shadow: 0 -4px 6px rgba(0, 0, 0, 0.05);
  z-index: 5;
}

.chatInput {
  flex: 1;
  padding: 12px 16px;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 24px;
  outline: none;
  transition: border-color 0.2s ease;
}

.chatInput:focus {
  border-color: #2348ED;
  box-shadow: 0 0 0 3px rgba(35, 72, 237, 0.1);
}

.chatInput:disabled {
  background-color: #f5f5f5;
  cursor: not-allowed;
}

.sendButton {
  width: 44px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #2348ED;
  color: white;
  border: none;
  border-radius: 50%;
  margin-left: 8px;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.sendButton:hover {
  background-color: #1a38c0;
}

.sendButton:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

/* Lock message styles */
.lockMessage {
  position: absolute;
  bottom: 70px;
  left: 0;
  right: 0;
  background-color: #fff7e6;
  color: #d48806;
  padding: 8px 16px;
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  border-top: 1px solid #ffe58f;
  animation: slideUp 0.3s ease;
}

@keyframes slideUp {
  from { transform: translateY(100%); }
  to { transform: translateY(0); }
}

/* Side panel styles */
.summaryPanel, .marketPanel {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 350px;
  background-color: white;
  box-shadow: -2px 0 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  z-index: 5;
  animation: slideIn 0.3s ease;
  overflow: hidden;
}

@keyframes slideIn {
  from { transform: translateX(100%); }
  to { transform: translateX(0); }
}

.summaryPanelHeader, .marketPanelHeader {
  padding: 16px;
  background-color: #f0f2f5;
  border-bottom: 1px solid #e8e8e8;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.summaryPanelHeader h3, .marketPanelHeader h3 {
  margin: 0;
  font-size: 16px;
  font-weight: 600;
  color: #333;
}

.closeButton {
  background: none;
  border: none;
  font-size: 24px;
  line-height: 1;
  cursor: pointer;
  color: #999;
}

.closeButton:hover {
  color: #333;
}

.summaryContent, .marketContent {
  flex: 1;
  padding: 16px;
  overflow-y: auto;
}

.summaryItem {
  margin-bottom: 16px;
  padding-bottom: 16px;
  border-bottom: 1px solid #f0f0f0;
  display: flex;
  justify-content: space-between;
}

.summaryItem:last-child {
  border-bottom: none;
}

.summaryLabel {
  font-weight: 500;
  color: #666;
}

.summaryValue {
  font-weight: 600;
  color: #333;
}

.summaryValue.highlight {
  color: #2348ED;
  font-weight: 700;
}

.emptyState {
  color: #999;
  text-align: center;
  margin-top: 40px;
}

.checkoutButton {
  display: block;
  width: 100%;
  padding: 12px;
  margin-top: 24px;
  background-color: #2348ED;
  color: white;
  border: none;
  border-radius: 6px;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.checkoutButton:hover {
  background-color: #1a38c0;
}

/* Market Comparison Styles */
.marketRange {
  padding: 16px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
}

.marketTitle {
  font-weight: 600;
  margin-bottom: 16px;
  color: #333;
}

.rangeBar {
  position: relative;
  margin-bottom: 24px;
  padding-top: 20px;
}

.rangeIndicator {
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
  position: relative;
}

.rangeLow, .rangeHigh {
  font-size: 14px;
  color: #666;
  font-weight: 500;
}

.rangeBarInner {
  height: 8px;
  background: linear-gradient(to right, #52c41a, #faad14, #ff4d4f);
  border-radius: 4px;
  position: relative;
}

.allegoryMarker {
  position: absolute;
  top: -5px;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.markerLabel {
  background-color: #2348ED;
  color: white;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 12px;
  margin-bottom: 4px;
  font-weight: 500;
}

.markerAmount {
  font-weight: 700;
  color: #2348ED;
  font-size: 16px;
}

.marketNote {
  font-size: 12px;
  color: #999;
  font-style: italic;
  margin-top: 16px;
}

/* Visualization styles */
.visualization {
  width: 100%;
  margin: 8px 0;
}

.savingsComparison {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
}

.comparisonItem {
  text-align: center;
  flex: 1;
}

.comparisonAmount {
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 4px;
}

.user .comparisonAmount {
  color: white;
}

.comparisonLabel {
  font-size: 14px;
  opacity: 0.8;
}

.arrow {
  font-size: 24px;
  margin: 0 12px;
  color: #2348ED;
}

.savingsSummary {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgba(35, 72, 237, 0.1);
  border-radius: 12px;
  padding: 12px;
  margin-top: 8px;
}

.savingsBadge {
  background-color: #2348ED;
  color: white;
  padding: 6px 16px;
  border-radius: 30px;
  font-weight: 600;
  margin-bottom: 8px;
}

.savingsPercentage {
  font-size: 14px;
  color: #2348ED;
}

@keyframes typing {
  0%, 80%, 100% { transform: scale(0.6); }
  40% { transform: scale(1); }
}

@media (min-width: 1200px) {
  .messagesContainer {
    padding-left: 20%;
    padding-right: 20%;
  }
  
  .message {
    max-width: 70%;
  }

  .inputForm {
    padding-left: 20%;
    padding-right: 20%;
    padding-top: 20px;
    padding-bottom: 20px;
  }
}

/* For very large screens like in your screenshot */
@media (min-width: 1600px) {
  .messagesContainer {
    padding-left: 28%;
    padding-right: 28%;
  }
  
  .message {
    max-width: 65%;
  }

  .inputForm {
    padding-left: 28%;
    padding-right: 28%;
    padding-top: 24px;
    padding-bottom: 24px;
  }
}

/* Responsive adjustments for smaller screens */
@media (max-width: 768px) {
  .message {
    max-width: 90%;
  }
  
  .summaryPanel, .marketPanel {
    width: 100%;
    height: 70%;
  }
  
  .headerControls {
    gap: 4px;
  }
  
  .controlButton {
    width: 32px;
    height: 32px;
  }
}

/* Very small screens */
@media (max-width: 480px) {
  .headerText h2 {
    font-size: 14px;
  }
  
  .headerText p {
    font-size: 10px;
  }
  
  .aiLogo {
    width: 30px;
    height: 30px;
    font-size: 14px;
  }
  
  .messageTime {
    font-size: 10px;
  }
}