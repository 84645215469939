/* File: frontend/src/pages/SuperAdmin/components/UserManagement/UserDetail/UserDetail.module.css */

.container {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-md);
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: var(--spacing-md);
}

.headerContent {
  display: flex;
  align-items: flex-start;
  gap: var(--spacing-md);
}

.userAvatar {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  overflow: hidden;
  border: 2px solid var(--color-border);
  background-color: var(--bg-secondary);
}

.avatarImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.userInfo {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xs);
}

.userName {
  font-size: var(--font-size-xl);
  font-weight: var(--font-weight-bold);
  color: var(--text-primary);
  margin: 0;
}

.userEmail {
  font-size: var(--font-size-base);
  color: var(--text-secondary);
}

.userMeta {
  display: flex;
  align-items: center;
  gap: var(--spacing-xs);
  font-size: var(--font-size-sm);
  color: var(--text-tertiary);
}

.userRole {
  text-transform: capitalize;
}

.separator {
  color: var(--text-tertiary);
}

.userOrg {
  font-weight: var(--font-weight-medium);
}

.verificationStatus {
  display: flex;
  align-items: center;
  gap: var(--spacing-md);
  margin-top: var(--spacing-xs);
}

.verificationBadge {
  display: inline-block;
  padding: 4px 8px;
  border-radius: 12px;
  font-size: var(--font-size-xs);
  font-weight: var(--font-weight-medium);
  text-transform: capitalize;
}

.statusVerified {
  background-color: var(--color-success-bg);
  color: var(--color-success);
}

.statusPending {
  background-color: var(--color-warning-bg);
  color: var(--color-warning);
}

.statusRejected {
  background-color: var(--color-error-bg);
  color: var(--color-error);
}

.verificationActions {
  display: flex;
  gap: var(--spacing-xs);
}

.verifyButton, .rejectButton {
  padding: var(--spacing-xs) var(--spacing-sm);
  border-radius: var(--border-radius-sm);
  font-size: var(--font-size-xs);
  font-weight: var(--font-weight-medium);
  cursor: pointer;
  transition: all var(--transition-fast);
}

.verifyButton {
  background-color: var(--color-success-bg);
  color: var(--color-success);
  border: 1px solid var(--color-success);
}

.verifyButton:hover {
  background-color: var(--color-success);
  color: white;
}

.rejectButton {
  background-color: var(--color-error-bg);
  color: var(--color-error);
  border: 1px solid var(--color-error);
}

.rejectButton:hover {
  background-color: var(--color-error);
  color: white;
}

.headerActions {
  display: flex;
  gap: var(--spacing-sm);
}

.backButton {
  display: flex;
  align-items: center;
  gap: var(--spacing-xs);
  padding: var(--spacing-sm) var(--spacing-md);
  background-color: var(--bg-secondary);
  color: var(--text-primary);
  border: 1px solid var(--color-border);
  border-radius: var(--border-radius-md);
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-medium);
  cursor: pointer;
  transition: all var(--transition-fast);
}

.backButton:hover {
  background-color: var(--bg-hover);
}

.tabs {
  display: flex;
  border-bottom: 1px solid var(--color-border);
  margin-bottom: var(--spacing-md);
  overflow-x: auto;
}

.tab {
  padding: var(--spacing-sm) var(--spacing-md);
  background: none;
  border: none;
  border-bottom: 2px solid transparent;
  font-size: var(--font-size-base);
  color: var(--text-secondary);
  cursor: pointer;
  transition: all var(--transition-fast);
  white-space: nowrap;
}

.tab:hover {
  color: var(--text-primary);
}

.activeTab {
  color: var(--color-primary);
  border-bottom-color: var(--color-primary);
  font-weight: var(--font-weight-medium);
}

.tabContent {
  background-color: var(--bg-primary);
  border-radius: var(--border-radius-md);
  padding: var(--spacing-md);
}

.section {
  margin-bottom: var(--spacing-lg);
}

.section:last-child {
  margin-bottom: 0;
}

.sectionTitle {
  font-size: var(--font-size-lg);
  font-weight: var(--font-weight-semibold);
  color: var(--text-primary);
  margin: 0 0 var(--spacing-md) 0;
  padding-bottom: var(--spacing-xs);
  border-bottom: 1px solid var(--color-border-light);
}

.infoGrid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: var(--spacing-md);
}

.infoItem {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xxs);
}

.infoLabel {
  font-size: var(--font-size-sm);
  color: var(--text-secondary);
  font-weight: var(--font-weight-medium);
}

.infoValue {
  color: var(--text-primary);
  word-break: break-word;
}

.permissionsList {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-sm);
}

.permissionItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: var(--spacing-sm);
  background-color: var(--bg-secondary);
  border-radius: var(--border-radius-md);
  border: 1px solid var(--color-border-light);
}

.permissionResource {
  display: flex;
  align-items: center;
  gap: var(--spacing-sm);
  font-weight: var(--font-weight-medium);
  text-transform: capitalize;
}

.permissionResource i {
  color: var(--color-primary);
}

.permissionActions {
  display: flex;
  gap: var(--spacing-xs);
}

.permissionAction {
  padding: 2px 8px;
  background-color: var(--color-primary-100);
  color: var(--color-primary);
  border-radius: 12px;
  font-size: var(--font-size-xs);
  text-transform: capitalize;
}

.permissionsActions {
  display: flex;
  justify-content: flex-end;
  margin-top: var(--spacing-md);
}

.editPermissionsButton {
  display: flex;
  align-items: center;
  gap: var(--spacing-xs);
  padding: var(--spacing-sm) var(--spacing-md);
  background-color: var(--color-primary);
  color: white;
  border: none;
  border-radius: var(--border-radius-md);
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-medium);
  cursor: pointer;
  transition: background-color var(--transition-fast);
}

.editPermissionsButton:hover {
  background-color: var(--color-primary-dark);
}

.activityList {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-md);
}

.activityItem {
  display: flex;
  gap: var(--spacing-md);
  padding: var(--spacing-md);
  background-color: var(--bg-secondary);
  border-radius: var(--border-radius-md);
  border: 1px solid var(--color-border-light);
}

.activityIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: var(--color-primary-100);
  color: var(--color-primary);
  flex-shrink: 0;
}

.activityContent {
  flex: 1;
}

.activityHeader {
  display: flex;
  justify-content: space-between;
  margin-bottom: var(--spacing-xs);
}

.activityType {
  font-weight: var(--font-weight-semibold);
  color: var(--text-primary);
}

.activityTime {
  font-size: var(--font-size-sm);
  color: var(--text-tertiary);
}

.activityDetails {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xxs);
}

.activityDetail {
  display: flex;
  font-size: var(--font-size-sm);
}

.activityDetailLabel {
  color: var(--text-secondary);
  margin-right: var(--spacing-xs);
  font-weight: var(--font-weight-medium);
}

.activityDetailValue {
  color: var(--text-primary);
}

.emptyState {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: var(--spacing-xl);
  background-color: var(--bg-secondary);
  border-radius: var(--border-radius-md);
  color: var(--text-secondary);
  font-style: italic;
}

.loading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: var(--spacing-xl);
  min-height: 300px;
}

.spinner {
  border: 3px solid var(--color-border);
  border-top: 3px solid var(--color-primary);
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 1s linear infinite;
  margin-bottom: var(--spacing-md);
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.error, .notFound {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: var(--spacing-xl);
  background-color: var(--bg-secondary);
  border-radius: var(--border-radius-md);
  min-height: 200px;
}

.error {
  color: var(--color-error);
}

.notFound {
  color: var(--text-secondary);
}

@media (max-width: 992px) {
  .infoGrid {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 768px) {
  .header {
    flex-direction: column;
    gap: var(--spacing-md);
  }
  
  .headerContent {
    width: 100%;
  }
  
  .headerActions {
    width: 100%;
  }
  
  .backButton {
    width: 100%;
    justify-content: center;
  }
  
  .tabs {
    flex-wrap: nowrap;
    overflow-x: auto;
  }
  
  .tab {
    padding: var(--spacing-xs) var(--spacing-sm);
  }
  
  .permissionItem {
    flex-direction: column;
    align-items: flex-start;
    gap: var(--spacing-sm);
  }
  
  .permissionActions {
    flex-wrap: wrap;
  }
}