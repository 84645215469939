/* File: frontend/src/pages/InvestorPitch/components/slides/LeadershipSlide/LeaderProfile.module.css */

.container {
  background-color: var(--color-gray-1200);
  border-radius: var(--border-radius-lg);
  padding: var(--spacing-xl);
  border: 1px solid var(--color-gray-1100);
  display: flex;
  flex-direction: column;
  gap: var(--spacing-lg);
  height: 100%;
  position: relative;
  overflow: hidden;
}

.container::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 4px;
  background: linear-gradient(90deg, var(--color-primary), var(--color-blue-310));
}

.profile {
  display: flex;
  align-items: center;
  gap: var(--spacing-lg);
}

.nameSection {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
}

.nameRole {
  flex: 1;
}

.socialLinks {
  display: flex;
  gap: var(--spacing-sm);
  margin-left: var(--spacing-md);
}

.socialLink {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: var(--border-radius-md);
  background-color: var(--color-gray-1100);
  transition: background-color 0.2s ease;
}

.socialLink:hover {
  background-color: var(--color-gray-1000);
}

.socialIcon {
  width: 18px;
  height: 18px;
  filter: brightness(0) invert(1);
}

.avatar {
  width: 96px;
  height: 96px;
  border-radius: var(--border-radius-lg);
  background: linear-gradient(135deg, var(--color-blue-110), var(--color-blue-310));
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  box-shadow: var(--shadow-md);
}

.avatarIcon {
  width: 48px;
  height: 48px;
  color: var(--color-white);
}

.avatarPhoto {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.info {
  flex: 1;
}

.name {
  font-size: var(--font-size-2xl);
  font-weight: var(--font-weight-bold);
  color: var(--text-on-primary);
  margin-bottom: var(--spacing-xs);
}

.role {
  font-size: var(--font-size-lg);
  color: var(--color-primary-500);
}

.highlights {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: var(--spacing-md);
}

.highlight {
  display: flex;
  align-items: center;
  gap: var(--spacing-md);
  padding: var(--spacing-sm);
  border-radius: var(--border-radius-md);
  background-color: var(--color-gray-1100);
  border: 1px solid var(--color-gray-1000);
}

.highlightIcon {
  display: flex;
  align-items: center;
  justify-content: center;
}

.highlightText {
  font-size: var(--font-size-base);
  color: var(--text-on-primary);
  line-height: 1.4;
}

.achievements {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-md);
}

.achievement {
  display: flex;
  align-items: flex-start;
  gap: var(--spacing-md);
  padding: var(--spacing-sm);
  border-radius: var(--border-radius-md);
  background-color: var(--color-gray-1100);
  border: 1px solid var(--color-gray-1000);
}

.bullet {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: var(--color-primary-500);
  margin-top: 6px;
  flex-shrink: 0;
}

.achievementText {
  color: var(--color-gray-300);
  font-size: var(--font-size-base);
  line-height: 1.5;
}

@media (max-width: 1200px) {
  .container {
    padding: var(--spacing-lg);
  }
  
  .highlights {
    gap: var(--spacing-sm);
  }
}

@media (max-width: 992px) {
  .name {
    font-size: var(--font-size-xl);
  }
  
  .role {
    font-size: var(--font-size-base);
  }
  
  .avatar {
    width: 80px;
    height: 80px;
  }
  
  .avatarIcon {
    width: 40px;
    height: 40px;
  }
}

@media (max-width: 768px) {
  .container {
    padding: var(--spacing-md);
    gap: var(--spacing-md);
  }
  
  .profile {
    gap: var(--spacing-md);
  }
  
  .highlights {
    grid-template-columns: 1fr;
  }
  
  .nameSection {
    flex-direction: column;
    gap: var(--spacing-sm);
  }
  
  .socialLinks {
    margin-left: 0;
  }
  
  .avatar {
    width: 72px;
    height: 72px;
  }
  
  .avatarIcon {
    width: 36px;
    height: 36px;
  }
}

@media (max-width: 480px) {
  .profile {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  
  .nameSection {
    align-items: center;
  }
  
  .name {
    font-size: var(--font-size-lg);
  }
  
  .role {
    font-size: var(--font-size-sm);
  }
  
  .highlight, .achievement {
    padding: var(--spacing-xs);
  }
  
  .highlightText, .achievementText {
    font-size: var(--font-size-sm);
  }
}