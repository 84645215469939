/* File: frontend/src/pages/Auth/EmailVerification.module.css */

.emailVerificationContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: calc(100vh - var(--header-height) - var(--spacing-2xl));
  padding: var(--spacing-lg);
  box-sizing: border-box;
}

.emailVerificationContent {
  width: 100%;
  max-width: 480px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-lg);
  animation: fadeIn 0.3s ease-out;
}

/* Large screens (1200px and up) */
@media (min-width: 1200px) {
  .emailVerificationContainer {
    padding: var(--spacing-xl);
  }

  .emailVerificationContent {
    max-width: 520px;
  }
}

/* Medium-large screens (1024px and down) */
@media (max-width: 1024px) {
  .emailVerificationContainer {
    padding: var(--spacing-lg);
  }

  .emailVerificationContent {
    max-width: 480px;
  }
}

/* Tablet screens (768px and down) */
@media (max-width: 768px) {
  .emailVerificationContainer {
    padding: var(--spacing-md);
    min-height: calc(100vh - var(--header-height) - var(--spacing-xl));
  }

  .emailVerificationContent {
    max-width: 440px;
    gap: var(--spacing-md);
  }
}

/* Mobile screens (480px and down) */
@media (max-width: 480px) {
  .emailVerificationContainer {
    padding: var(--spacing-sm);
    min-height: calc(100vh - var(--header-height) - var(--spacing-lg));
  }

  .emailVerificationContent {
    max-width: 100%;
    gap: var(--spacing-sm);
  }
}

/* Animations */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Loading state */
.emailVerificationContent.loading {
  opacity: 0.7;
  pointer-events: none;
}

/* Error state */
.emailVerificationContent.error {
  animation: shake 0.4s ease-in-out;
}

@keyframes shake {
  0%, 100% { transform: translateX(0); }
  25% { transform: translateX(-5px); }
  75% { transform: translateX(5px); }
}

/* Print styles */
@media print {
  .emailVerificationContainer {
    min-height: auto;
    padding: 0;
  }

  .emailVerificationContent {
    max-width: 100%;
  }
}