/* File: frontend/src/pages/QMS/QuoteDetail/components/QuoteProducts/QuoteProducts.module.css */

.container {
  display: flex;
  flex-direction: column;
}

.productTabs {
  display: flex;
  border-bottom: 1px solid var(--color-border);
  margin-bottom: var(--spacing-md);
  overflow-x: auto;
}

.productTab {
  padding: var(--spacing-sm) var(--spacing-md);
  background: none;
  border: none;
  border-bottom: 2px solid transparent;
  font-size: var(--font-size-base);
  font-weight: var(--font-weight-medium);
  color: var(--text-secondary);
  cursor: pointer;
  transition: all var(--transition-fast);
  white-space: nowrap;
}

.productTab:hover {
  color: var(--text-primary);
}

.activeProductTab {
  color: var(--color-primary);
  border-bottom-color: var(--color-primary);
}

.productContent {
  flex: 1;
}

.noProducts {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: var(--spacing-xl);
  color: var(--text-secondary);
  font-style: italic;
  text-align: center;
}

@media (max-width: 768px) {
  .productTabs {
    padding-bottom: var(--spacing-xs);
  }
  
  .productTab {
    padding: var(--spacing-xs) var(--spacing-sm);
    font-size: var(--font-size-sm);
  }
}