/* File: frontend/src/components/Sidebar/SidebarNavigation.module.css */

.categoryWrapper {
  margin-bottom: var(--spacing-xs);
}

.categoryHeader {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: var(--spacing-sm);
  border-radius: var(--border-radius-md);
  transition: all var(--transition-normal);
  color: var(--color-gray-500);
  text-decoration: none;
}

.categoryHeader:hover {
  background-color: var(--color-gray-1200);
  color: var(--color-gray-300);
}

.active .categoryHeader {
  color: var(--color-primary-700);
  background-color: var(--color-gray-1200);
}

.iconWrapper {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}

.categoryIcon {
  width: 24px;
  height: 24px;
  transition: transform var(--transition-normal);
}

.categoryName {
  flex: 1;
  margin-left: var(--spacing-xs);
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-medium);
}

.chevronIcon {
  width: 16px;
  height: 16px;
  margin-left: var(--spacing-sm);
}

.dragHandle {
  cursor: move;
  margin-right: var(--spacing-xs);
  color: var(--color-gray-600);
  font-size: var(--font-size-lg);
  user-select: none;
}

.navList {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xs);
  list-style-type: none;
  padding: 0;
  margin: var(--spacing-xs) 0 0 48px;
}

/* Non-expanded state */
.categoryWrapper:not(.expanded) .categoryHeader {
  justify-content: center;
  padding: var(--spacing-xs);
}

.categoryWrapper:not(.expanded) .categoryIcon {
  width: 20px;
  height: 20px;
}

.categoryWrapper:not(.expanded) .categoryHeader:hover .categoryIcon {
  transform: scale(1.1);
}

/* Hover effects */
.categoryHeader:hover .categoryIcon {
  transform: scale(1.1);
}

.active .categoryIcon,
.active .categoryName {
  color: var(--color-primary-700);
}

@media (max-width: 768px) {
  .categoryWrapper:not(.expanded) .categoryHeader {
    padding: var(--spacing-sm);
    justify-content: flex-start;
  }
  
  .categoryName {
    display: block;
  }
}