/* File: frontend/src/pages/InvestorPitch/components/slides/ClosingSlideInv/ClosingSlideInv.module.css */

.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xl);
  padding: var(--spacing-xl);
}

.titleContainer {
  text-align: center;
  margin-bottom: var(--spacing-lg);
}

.title {
  font-size: var(--font-size-4xl);
  font-weight: var(--font-weight-bold);
  line-height: 1.2;
  background: linear-gradient(135deg, var(--color-primary), var(--color-blue-510));
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  padding: 0 var(--spacing-md);
}

.contentContainer {
  display: flex;
  flex-direction: row;
  gap: var(--spacing-xl);
  flex: 1;
}

/* LEFT container */
.leftBox {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xl);
  background: var(--color-gray-1200);
  padding: var(--spacing-xl);
  border-radius: var(--border-radius-lg);
  box-shadow: var(--shadow-lg);
  border: 1px solid var(--color-gray-1100);
  position: relative;
  overflow: hidden;
}

.leftBox::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 4px;
  background: linear-gradient(90deg, var(--color-primary), var(--color-blue-310));
}

.raisingContainer {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xs);
}

.label {
  font-size: var(--font-size-sm);
  color: var(--color-gray-400);
  text-transform: uppercase;
  letter-spacing: 1px;
}

.amount {
  font-size: var(--font-size-3xl);
  font-weight: var(--font-weight-bold);
  color: var(--color-primary-500);
}

.messageContainer {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-md);
}

.prompt {
  font-size: var(--font-size-xl);
  color: var(--text-on-primary);
  line-height: 1.4;
}

.supercycle {
  font-size: var(--font-size-lg);
  font-weight: var(--font-weight-semibold);
  color: var(--color-primary-500);
  line-height: 1.4;
}

.ctaContainer {
  margin-top: auto;
}

.ctaButton {
  width: 100%;
  background-color: var(--color-primary-700);
  color: white;
  border: none;
  border-radius: var(--border-radius-md);
  padding: var(--spacing-md) var(--spacing-lg);
  font-size: var(--font-size-lg);
  font-weight: var(--font-weight-semibold);
  text-align: center;
  cursor: pointer;
  text-decoration: none;
  transition: all 0.2s ease;
  box-shadow: 0 4px 12px rgba(35, 72, 237, 0.3);
}

.ctaButton:hover {
  background-color: var(--color-primary-600);
  box-shadow: 0 6px 24px rgba(35, 72, 237, 0.4);
}

/* RIGHT container */
.rightBox {
  flex: 1;
  background: var(--color-gray-1200);
  padding: var(--spacing-xl);
  border-radius: var(--border-radius-lg);
  box-shadow: var(--shadow-lg);
  border: 1px solid var(--color-gray-1100);
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.detailsList {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-md);
}

.detailsList li {
  padding: var(--spacing-sm);
  border-radius: var(--border-radius-md);
  background-color: var(--color-gray-1100);
  border: 1px solid var(--color-gray-1000);
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xxs);
}

.highlightItem {
  background: linear-gradient(145deg, rgba(35, 72, 237, 0.1), rgba(35, 72, 237, 0.05));
  border-color: rgba(35, 72, 237, 0.3);
}

.detailLabel {
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-medium);
  color: var(--color-gray-400);
}

.detailValue {
  font-size: var(--font-size-lg);
  font-weight: var(--font-weight-semibold);
  color: var(--text-on-primary);
}

/* Responsive styles */
@media (max-width: 1200px) {
  .title {
    font-size: var(--font-size-3xl);
  }
  
  .amount {
    font-size: var(--font-size-2xl);
  }
  
  .prompt {
    font-size: var(--font-size-lg);
  }
  
  .supercycle {
    font-size: var(--font-size-base);
  }
  
  .detailValue {
    font-size: var(--font-size-md);
  }
  
  .leftBox, .rightBox {
    padding: var(--spacing-lg);
  }
}

@media (max-width: 992px) {
  .contentContainer {
    flex-direction: column;
  }
  
  .title {
    font-size: var(--font-size-2xl);
  }
  
  .leftBox {
    gap: var(--spacing-lg);
  }
}

@media (max-width: 768px) {
  .container {
    padding: var(--spacing-lg);
    gap: var(--spacing-lg);
  }
  
  .title {
    font-size: var(--font-size-xl);
  }
  
  .amount {
    font-size: var(--font-size-xl);
  }
  
  .prompt {
    font-size: var(--font-size-base);
  }
  
  .supercycle {
    font-size: var(--font-size-sm);
  }
  
  .ctaButton {
    padding: var(--spacing-sm) var(--spacing-md);
    font-size: var(--font-size-base);
  }
  
  .detailsList {
    gap: var(--spacing-sm);
  }
  
  .detailsList li {
    padding: var(--spacing-xs);
  }
  
  .detailLabel {
    font-size: var(--font-size-xs);
  }
  
  .detailValue {
    font-size: var(--font-size-base);
  }
}

@media (max-width: 480px) {
  .container {
    padding: var(--spacing-md);
  }
  
  .title {
    font-size: var(--font-size-lg);
  }
  
  .leftBox, .rightBox {
    padding: var(--spacing-md);
  }
  
  .amount {
    font-size: var(--font-size-lg);
  }
  
  .prompt, .supercycle {
    text-align: center;
  }
}