/* File: frontend/src/components/Pagination/Pagination.module.css */

.pagination {
  display: flex;
  align-items: center;
  gap: var(--spacing-sm);
}

.navButton,
.pageNumber {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--bg-primary);
  border: 1px solid var(--color-border);
  border-radius: var(--border-radius-md);
  cursor: pointer;
  transition: all var(--transition-fast);
  font-family: var(--font-primary);
  font-weight: var(--font-weight-medium);
  color: var(--text-primary);
}

.navButton:hover,
.pageNumber:hover {
  background-color: var(--bg-hover);
}

.navButton:focus,
.pageNumber:focus {
  outline: none;
  box-shadow: var(--focus-ring);
}

.navButton:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.pageNumber.active {
  background-color: var(--color-primary);
  color: var(--color-white);
  border-color: var(--color-primary);
}

.medium {
  width: 40px;
  height: 40px;
  font-size: var(--font-size-base);
}

.small {
  width: 32px;
  height: 32px;
  font-size: var(--font-size-sm);
}

.xsmall {
  width: 24px;
  height: 24px;
  font-size: var(--font-size-xs);
}

.srOnly {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}

@media (max-width: 768px) {
  .pagination {
    gap: var(--spacing-xs);
  }

  .medium {
    width: 36px;
    height: 36px;
  }

  .small {
    width: 28px;
    height: 28px;
  }

  .xsmall {
    width: 20px;
    height: 20px;
  }
}