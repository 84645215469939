/* File: frontend/src/components/SearchBar/SearchBar.module.css */

.searchBar {
    margin-bottom: var(--spacing-lg);
  }
  
  .searchInput {
    width: 100%;
    padding: var(--spacing-sm) var(--spacing-md);
    font-size: var(--font-size-base);
    border: 1px solid var(--color-border);
    border-radius: var(--border-radius-md);
    background-color: var(--color-background);
    color: var(--color-text);
    transition: border-color var(--transition-fast), box-shadow var(--transition-fast);
  }
  
  .searchInput:focus {
    outline: none;
    border-color: var(--color-primary);
    box-shadow: var(--focus-ring);
  }
  
  .searchInput::placeholder {
    color: var(--color-text-tertiary);
  }