/* File: frontend/src/pages/TripDetail/components/TripLocation/TripLocation.module.css */

.container {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-md);
}

.locationsGrid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: var(--spacing-md);
}

.locationCard {
  background-color: var(--bg-secondary);
  border-radius: var(--border-radius-md);
  overflow: hidden;
  border: 1px solid var(--color-border);
}

.locationHeader {
  display: flex;
  align-items: center;
  gap: var(--spacing-sm);
  padding: var(--spacing-sm);
  background-color: var(--bg-tertiary);
  border-bottom: 1px solid var(--color-border);
}

.locationIcon {
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: var(--bg-primary);
}

.startIcon {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: var(--color-success);
}

.endIcon {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: var(--color-error);
}

.locationTitle {
  font-weight: var(--font-weight-semibold);
  color: var(--text-primary);
}

.locationContent {
  padding: var(--spacing-md);
}

.addressSection, .coordinatesSection, .placeIdSection {
  margin-bottom: var(--spacing-md);
}

.addressSection:last-child, .coordinatesSection:last-child, .placeIdSection:last-child {
  margin-bottom: 0;
}

.sectionTitle {
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-medium);
  color: var(--text-secondary);
  margin-bottom: var(--spacing-xxs);
}

.addressText {
  color: var(--text-primary);
}

.coordinatesText {
  font-family: monospace;
  color: var(--text-primary);
  font-size: var(--font-size-sm);
}

.placeIdText {
  color: var(--text-primary);
  font-size: var(--font-size-sm);
  word-break: break-all;
}

.mapContainer {
  height: 300px;
  background-color: var(--bg-secondary);
  border-radius: var(--border-radius-md);
  overflow: hidden;
  position: relative;
}

.mapPlaceholder {
  height: 100%;
  width: 100%;
}

.mockMap {
  height: 100%;
  width: 100%;
  background-color: #e8e8e8;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mockRoute {
  width: 80%;
  height: 2px;
  background: linear-gradient(90deg, var(--color-success) 0%, var(--color-error) 100%);
  position: relative;
}

.mockStartPoint {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: var(--color-success);
}

.mockEndPoint {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate(50%, -50%);
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: var(--color-error);
}

.mockMapText {
  position: absolute;
  font-size: var(--font-size-lg);
  color: var(--text-tertiary);
  text-align: center;
}

.midpointCard {
  background-color: var(--bg-secondary);
  border-radius: var(--border-radius-md);
  overflow: hidden;
  border: 1px solid var(--color-border);
}

.midpointHeader {
  display: flex;
  align-items: center;
  gap: var(--spacing-sm);
  padding: var(--spacing-sm);
  background-color: var(--bg-tertiary);
  border-bottom: 1px solid var(--color-border);
}

.midpointIcon {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: var(--color-warning);
}

.midpointTitle {
  font-weight: var(--font-weight-semibold);
  color: var(--text-primary);
}

.midpointContent {
  padding: var(--spacing-md);
}

.midpointCoordinates {
  font-family: monospace;
  color: var(--text-primary);
  font-size: var(--font-size-sm);
  text-align: center;
}

.distanceCard {
  background-color: var(--bg-secondary);
  border-radius: var(--border-radius-md);
  overflow: hidden;
  border: 1px solid var(--color-border);
}

.distanceHeader {
  padding: var(--spacing-sm);
  background-color: var(--bg-tertiary);
  border-bottom: 1px solid var(--color-border);
}

.distanceTitle {
  font-weight: var(--font-weight-semibold);
  color: var(--text-primary);
}

.distanceContent {
  padding: var(--spacing-md);
  display: flex;
  justify-content: space-around;
}

.distanceItem {
  text-align: center;
}

.distanceLabel {
  font-size: var(--font-size-sm);
  color: var(--text-secondary);
  margin-bottom: var(--spacing-xxs);
}

.distanceValue {
  font-size: var(--font-size-lg);
  font-weight: var(--font-weight-semibold);
  color: var(--text-primary);
}

@media (max-width: 768px) {
  .locationsGrid {
    grid-template-columns: 1fr;
  }
  
  .distanceContent {
    flex-direction: column;
    gap: var(--spacing-md);
  }
}