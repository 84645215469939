/* File: frontend/src/pages/About/About.module.css */

.aboutContainer {
    max-width: var(--breakpoint-lg);
    margin: 0 auto;
    padding: clamp(1rem, 5vw, 2rem);
    font-family: 'Instrument Sans', sans-serif;
    color: var(--color-text);
}
  
.title {
  font-size: clamp(2rem, 5vw, 2.5rem);
  color: var(--color-primary);
  margin-bottom: clamp(1rem, 3vw, 1.5rem);
}

.mission {
   margin-bottom: clamp(1.5rem, 5vw, 2rem);
}

.mission h2 {
    font-size: clamp(1.5rem, 4vw, 1.8rem);
    color: var(--color-primary-dark);
    margin-bottom: clamp(0.75rem, 2vw, 1rem);
}

.mission p {
    line-height: 1.6;
    margin-bottom: clamp(0.75rem, 2vw, 1rem);
    font-size: clamp(1rem, 2vw, 1.1rem);
}

@media (max-width: 768px) {
  .aboutContainer {
    padding: 1rem;
  }

  .title {
    font-size: 2rem;
  }

  .mission h2 {
    font-size: 1.5rem;
  }
}