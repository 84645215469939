/* File: frontend/src/pages/AutoInsight/VehicleDetail/components/VehicleOwnership/VehicleOwnership.module.css */

.container {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-md);
}

.grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: var(--spacing-md);
}

.card {
  background-color: var(--bg-primary);
  border-radius: var(--border-radius-md);
  border: 1px solid var(--color-border);
  overflow: hidden;
}

.cardTitle {
  font-size: var(--font-size-md);
  font-weight: var(--font-weight-semibold);
  padding: var(--spacing-sm);
  background-color: var(--bg-secondary);
  border-bottom: 1px solid var(--color-border);
  margin: 0;
}

.cardContent {
  padding: var(--spacing-sm);
}

.ownersList, .lienholdersList {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-md);
}

.ownerItem, .lienholderItem, .driverItem {
  padding: var(--spacing-sm);
  border-radius: var(--border-radius-sm);
  border: 1px solid var(--color-border);
  background-color: var(--bg-secondary);
}

.ownerHeader, .driverHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: var(--spacing-xs);
}

.ownerName, .lienholderName, .driverName {
  font-weight: var(--font-weight-semibold);
  font-size: var(--font-size-base);
}

.primaryBadge {
  background-color: var(--color-success-bg);
  color: var(--color-success);
  padding: 2px 8px;
  border-radius: 4px;
  font-size: var(--font-size-xs);
  font-weight: var(--font-weight-medium);
}

.ownerDetails, .lienholderDetails, .driverDetails {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xxs);
}

.ownerDetail, .lienholderDetail, .driverDetail {
  display: flex;
  justify-content: space-between;
  font-size: var(--font-size-sm);
}

.ownerDetailLabel, .lienholderDetailLabel, .driverDetailLabel {
  color: var(--text-secondary);
}

.ownerDetailValue, .lienholderDetailValue, .driverDetailValue {
  color: var(--text-primary);
  font-weight: var(--font-weight-medium);
}

.driversGrid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: var(--spacing-md);
}

.ownershipInfo {
  background-color: var(--bg-primary);
  border-radius: var(--border-radius-md);
  border: 1px solid var(--color-border);
  padding: var(--spacing-md);
  display: flex;
  flex-wrap: wrap;
  gap: var(--spacing-md);
}

.infoGroup {
  flex: 1;
  min-width: 200px;
}

.infoLabel {
  font-weight: var(--font-weight-medium);
  color: var(--text-secondary);
  margin-bottom: var(--spacing-xxs);
}

.infoValue {
  color: var(--text-primary);
  font-weight: var(--font-weight-semibold);
}

.noData {
  color: var(--text-secondary);
  font-style: italic;
  text-align: center;
  padding: var(--spacing-md);
}

@media (max-width: 768px) {
  .grid {
    grid-template-columns: 1fr;
  }
  
  .driversGrid {
    grid-template-columns: 1fr;
  }
}