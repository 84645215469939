/* File: frontend/src/components/Breadcrumb/Breadcrumb.module.css */

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: var(--breadcrumb-padding-y) var(--breadcrumb-padding-x);
  font-family: var(--font-primary);
  font-size: var(--breadcrumb-font-size);
  line-height: var(--breadcrumb-line-height);
}

.breadcrumb-list {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  list-style: none;
  margin: 0;
  padding: 0;
}

.breadcrumb-item {
  display: flex;
  align-items: center;
}

.breadcrumb-step {
  display: flex;
  align-items: center;
}

.breadcrumb-text {
  color: var(--breadcrumb-text-color);
  font-size: var(--breadcrumb-font-size);
  line-height: var(--breadcrumb-line-height);
  background: none;
  border: none;
  padding: 0;
  transition: var(--transition-fast);
  cursor: pointer;
}

.breadcrumb-text:hover:not(:disabled) {
  color: var(--breadcrumb-active-text-color);
}

.breadcrumb-text:disabled {
  cursor: default;
}

.breadcrumb-text-active {
  color: var(--breadcrumb-active-text-color);
  font-weight: var(--font-weight-semibold);
}

.breadcrumb-separator {
  width: var(--breadcrumb-separator-size);
  height: var(--breadcrumb-separator-size);
  margin: 0 var(--breadcrumb-separator-margin);
  color: var(--breadcrumb-text-color);
}

@media (max-width: 480px) {
  .breadcrumb {
    padding: var(--spacing-sm) var(--spacing-md);
  }

  .breadcrumb-text {
    font-size: var(--font-size-xs);
  }

  .breadcrumb-separator {
    width: 10px;
    height: 10px;
    margin: 0 var(--spacing-xs);
  }
}