/* File: frontend/src/components/DrivingScore/UnderwritingMatrix.module.css */

.controls {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 20px;
  }

  .dropdownFormParent {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 10px;
  }

  .buttonParent {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-start;
  }

  .button {
    box-shadow: 0px 4px 1px rgba(0, 0, 0, 0), 0px 2px 1px rgba(0, 0, 0, 0), 0px 1px 1px rgba(0, 0, 0, 0.02), 0px 1px 1px rgba(0, 0, 0, 0.03);
    border-radius: 10px;
    background-color: #fff;
    border: 1px solid #e9eaec;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 9px 10px;
    gap: 8px;
    font-family: 'Instrument Sans', sans-serif;
    font-size: 14px;
    color: #1f2228;
    cursor: pointer;
  }

  .icons18Refresh {
    width: 14px;
    height: 14px;
    overflow: hidden;
    flex-shrink: 0;
  }
  
  .submit {
    position: relative;
    line-height: 22px;
    font-weight: 500;
  }
  
  .dropdownContainer {
    flex: 1;
  }
  
  .refreshButton {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 9px 10px;
    background-color: #fff;
    border: 1px solid #e9eaec;
    border-radius: 10px;
    cursor: pointer;
  }
  
  .tableContainer {
    width: 100%;
    overflow-x: auto;
  }
  
  .tableContainer table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .tableContainer th,
  .tableContainer td {
    width: 33px;
    height: 33px;
    border: 1px solid #ccc;
    text-align: center;
  }
  
  .tableContainer th {
    background-color: #f0f0f0;
    font-weight: bold;
  }
  
  .tooltip {
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    z-index: 1000;
  }
  
  .insightContainer {
    display: flex;
    align-items: center;
    background-color: #dbe6fe;
    padding: 16px;
    border-radius: 8px;
    margin-top: 16px;
  }
  
  .insightIcon {
    display: flex;
    align-items: center;
    margin-right: 16px;
  }
  
  .insightText {
    color: #3667f8;
    font-weight: 500;
  }
  
  .downloadSection {
    margin-top: 16px;
  }