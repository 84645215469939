/* File: frontend/src/pages/EmbeddedInsurance/components/ApiExplorer/styles/ApiResponses.module.css */

.section {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-lg);
}

.sectionTitle {
  font-size: var(--font-size-md);
  font-weight: var(--font-weight-semibold);
  color: var(--text-primary);
  margin: 0;
  padding-bottom: var(--spacing-xs);
  border-bottom: 1px solid var(--color-border-light);
}

.responseTabs {
  display: flex;
  flex-wrap: wrap;
  gap: var(--spacing-xs);
}

.responseTab {
  display: flex;
  align-items: center;
  gap: var(--spacing-xs);
  padding: var(--spacing-xs) var(--spacing-sm);
  border: 1px solid var(--color-border);
  border-radius: var(--border-radius-md);
  background: var(--bg-primary);
  cursor: pointer;
  transition: all var(--transition-fast);
}

.responseTab:hover {
  border-color: var(--color-primary);
}

.activeTab {
  background: var(--color-primary-bg);
  border-color: var(--color-primary);
}

.statusCode {
  font-family: var(--font-secondary);
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-semibold);
}

.statusText {
  font-size: var(--font-size-xs);
  color: var(--text-secondary);
}

.success .statusCode { color: var(--color-success); }
.redirect .statusCode { color: var(--color-warning); }
.clientError .statusCode { color: var(--color-error); }
.serverError .statusCode { color: var(--color-error); }
.default .statusCode { color: var(--text-primary); }

.responseContent {
  background: var(--bg-secondary);
  border-radius: var(--border-radius-lg);
  border: 1px solid var(--color-border);
  overflow: hidden;
}

.responseDescription {
  padding: var(--spacing-md);
  font-size: var(--font-size-sm);
  color: var(--text-secondary);
  line-height: var(--line-height-relaxed);
  margin: 0;
  border-bottom: 1px solid var(--color-border);
}

.responseExample {
  background: var(--color-gray-900);
}

.responseExampleHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--spacing-sm) var(--spacing-md);
  background: var(--color-gray-800);
  border-bottom: 1px solid var(--color-gray-700);
}

.responseExampleTitle {
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-medium);
  color: var(--color-white);
  margin: 0;
}

.contentType {
  font-family: var(--font-secondary);
  font-size: var(--font-size-xs);
  color: var(--color-gray-400);
  padding: 2px var(--spacing-xs);
  background: var(--color-gray-700);
  border-radius: var(--border-radius-sm);
}

.responseExampleBody {
  margin: 0;
}

@media (max-width: 768px) {
  .responseTabs {
    flex-direction: column;
  }
  
  .responseTab {
    width: 100%;
  }
}