/* File: frontend/src/pages/InvestorPitch/components/slides/ScientificArticlesSlide/ScientificArticlesSlide.module.css */

.container {
  padding: var(--spacing-md);
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xl);
  width: 100%;
  height: 100%;
}

.header {
  max-width: 800px;
  margin: 0 auto;
}

.title {
  font-size: var(--font-size-4xl);
  font-weight: var(--font-weight-bold);
  color: var(--text-on-primary);
  margin-bottom: var(--spacing-md);
  background: linear-gradient(135deg, var(--color-primary), var(--color-blue-510));
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}

.subtitle {
  font-size: var(--font-size-xl);
  color: var(--color-gray-400);
  line-height: 1.4;
}

.cardGrid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: var(--spacing-xl);
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  flex: 1;
}

.cardWrapper {
  height: 100%;
  display: flex;
}

.card {
  background-color: var(--color-gray-1200);
  border-radius: var(--border-radius-lg);
  overflow: hidden;
  box-shadow: var(--shadow-md);
  text-decoration: none;
  display: flex;
  flex-direction: column;
  transition: all 0.3s ease;
  border: 1px solid var(--color-gray-1100);
  height: 100%;
  width: 100%;
}

.card:hover {
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.3);
  border-color: var(--color-primary-700);
}

.card:hover .overlay {
  opacity: 1;
}

.imageContainer {
  position: relative;
  overflow: hidden;
}

.image {
  width: 100%;
  aspect-ratio: 16 / 9;
  object-fit: cover;
  transition: transform 0.5s ease;
}

.card:hover .image {
  transform: scale(1.05);
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(35, 72, 237, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.externalIcon {
  color: white;
  width: 32px;
  height: 32px;
  filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.5));
}

.cardBody {
  padding: var(--spacing-lg);
  display: flex;
  flex-direction: column;
  gap: var(--spacing-md);
  text-align: left;
  flex: 1;
}

.articleTitle {
  font-size: var(--font-size-xl);
  font-weight: var(--font-weight-bold);
  color: var(--text-on-primary);
  line-height: 1.3;
}

.articleSubtitle {
  font-size: var(--font-size-base);
  color: var(--color-gray-300);
  line-height: 1.5;
  flex: 1;
}

.articleMeta {
  font-size: var(--font-size-sm);
  color: var(--color-gray-500);
  display: flex;
  justify-content: space-between;
  border-top: 1px solid var(--color-gray-1100);
  padding-top: var(--spacing-sm);
  margin-top: auto;
}

.date {
  color: var(--color-gray-500);
}

.author {
  color: var(--color-primary-500);
  font-weight: var(--font-weight-medium);
}

@media (max-width: 1200px) {
  .cardGrid {
    grid-template-columns: repeat(3, 1fr);
    gap: var(--spacing-lg);
  }
  
  .articleTitle {
    font-size: var(--font-size-lg);
  }
  
  .cardBody {
    padding: var(--spacing-md);
    gap: var(--spacing-sm);
  }
}

@media (max-width: 992px) {
  .title {
    font-size: var(--font-size-3xl);
  }
  
  .subtitle {
    font-size: var(--font-size-lg);
  }
  
  .cardGrid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .title {
    font-size: var(--font-size-2xl);
  }
  
  .subtitle {
    font-size: var(--font-size-md);
  }
  
  .articleTitle {
    font-size: var(--font-size-base);
  }
  
  .articleSubtitle {
    font-size: var(--font-size-sm);
  }
  
  .articleMeta {
    font-size: var(--font-size-xs);
    flex-direction: column;
    gap: var(--spacing-xxs);
  }
}

@media (max-width: 576px) {
  .cardGrid {
    grid-template-columns: 1fr;
    gap: var(--spacing-md);
  }
  
  .container {
    padding: var(--spacing-sm);
  }
}