/* src/components/TripDetails/TripEventsMaps.module.css */
.card {
  flex: 1;
  width: 100%;
  position: relative;
  box-shadow: 0px 13px 4px rgba(0, 0, 0, 0), 0px 8px 3px rgba(0, 0, 0, 0), 0px 5px 3px rgba(0, 0, 0, 0.02), 0px 2px 2px rgba(0, 0, 0, 0.03), 0px 1px 1px rgba(0, 0, 0, 0.03);
  border-radius: 16px;
  background-color: #fff;
  border: 2px solid #f7f7f8;
  box-sizing: border-box;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  text-align: left;
  font-size: 18px;
  color: #14151a;
  font-family: 'Instrument Sans';
}

.cardStandartTitleRow {
  align-self: stretch;
  border: 1px solid #f7f7f8;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 20px;
  gap: 16px;
}

.text {
  flex: 1;
  position: relative;
  line-height: 24px;
  font-weight: 500;
}

.button {
  box-shadow: 0px 4px 1px rgba(0, 0, 0, 0), 0px 2px 1px rgba(0, 0, 0, 0), 0px 1px 1px rgba(0, 0, 0, 0.02), 0px 1px 1px rgba(0, 0, 0, 0.03);
  border-radius: 10px;
  background-color: #fff;
  border: 1px solid #e9eaec;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 9px;
}

.icons18MoreHorizontal {
  width: 14px;
  position: relative;
  height: 14px;
  overflow: hidden;
  flex-shrink: 0;
}

.cardMappingContainer {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 20px;
  gap: 10px;
  font-size: 12px;
  color: #1f2228;
  font-family: Inter;
}

.cardsTableSystem {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 6px;
}

.cardsTableSystemItem {
  align-self: stretch;
  flex: 1;
  border-radius: 16px;
  background-color: #fff;
  border: 1px solid #f7f7f8;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 12px;
  gap: 12px;
  cursor: pointer;
}

.cardsTableSystemItem.active {
  background-color: #f7f7f8;
  border: 1px solid #e9eaec;
  box-shadow: 0px 7px 2px rgba(0, 0, 0, 0), 0px 5px 2px rgba(0, 0, 0, 0.01), 0px 3px 2px rgba(0, 0, 0, 0.03), 0px 1px 1px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.05);
}

.cardsTableSystemIcon {
  border-radius: 12px;
  background-color: #fff;
  border: 1px solid #e9eaec;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 10px;
}

.icons18MapPinned {
  width: 18px;
  position: relative;
  height: 18px;
  overflow: hidden;
  flex-shrink: 0;
}

.texts {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 4px;
}

.attentiveDriving {
  align-self: stretch;
  position: relative;
  line-height: 12px;
  font-weight: 600;
}

.attentiveDrivingIs {
  align-self: stretch;
  position: relative;
  font-size: 11px;
  font-weight: 500;
  color: #5e636e;
}

.cardMappingContainerC {
  border-radius: 16px;
  background-color: #f7f7f8;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  font-size: 14px;
  color: #fff;
  font-family: Barlow;
}

.cardMappingContainerC1 {
  width: 864px;
  position: relative;
  box-shadow: 0px 0px 0px 7px #fff inset, 0px 0px 0px 8px rgba(0, 0, 0, 0.05) inset, 0px 8px 15px 4px rgba(255, 255, 255, 0.5) inset, 0px 5px 1px rgba(0, 0, 0, 0), 0px 3px 1px rgba(0, 0, 0, 0), 0px 2px 1px rgba(0, 0, 0, 0.02), 0px 1px 1px rgba(0, 0, 0, 0.03);
  border-radius: 16px;
  background-color: #fff;
  border: 1px solid #e9eaec;
  box-sizing: border-box;
  height: 650px;
  overflow: hidden;
  flex-shrink: 0;
}

.cardMappingContainerC2 {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  left: 0%;
  overflow: hidden;
}

.zoomInOut {
  position: absolute;
  top: 16px;
  right: 16px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(4px);
  border-radius: 10px;
  background-color: rgba(20, 21, 26, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.15);
  box-sizing: border-box;
  width: 44px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 6px;
  gap: 6px;
}

.frameParent {
  position: absolute;
  right: 16px;
  bottom: 16px;
  border-radius: 7px;
  background-color: #fff;
  border: 0.7px solid #e8edfa;
  box-sizing: border-box;
  width: 112px;
  height: 128.8px;
  font-size: 9.8px;
  color: #0a1e53;
}

.frameDiv {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 10px 14px;
  gap: 8px;
  font-size: 12px;
  color: #9ea2ad;
  font-family: Inter;
}

/* Add more styles as needed */