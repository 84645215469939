/* File: frontend/src/components/Home/HeroSection.module.css */

.heroSection {
  min-height: 100vh;
  position: relative;
  background-color: var(--color-black);
  color: var(--color-white);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: var(--spacing-lg) var(--spacing-md);
  box-sizing: border-box;
}

.animationContainer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.particleCanvas {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
  touch-action: pan-y;
  pointer-events: none;
}

.animationPlaceholder {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--color-black);
  color: var(--color-white);
  font-size: var(--font-size-lg);
}

.content {
  position: relative;
  z-index: 1;
  text-align: center;
  max-width: 640px;
  width: 100%;
  padding-top: 40vh;
  padding-right: var(--spacing-md);
  padding-left: var(--spacing-md);
}

.title {
  font-size: clamp(2rem, 5vw, var(--font-size-4xl));
  margin-bottom: var(--spacing-md);
  line-height: 1.2;
}

.description {
  font-size: clamp(1rem, 2vw, var(--font-size-lg));
  margin-bottom: var(--spacing-lg);
  line-height: 1.45;
}

.ctaButton {
  padding: clamp(var(--spacing-md), 2vw, var(--spacing-lg));
  font-size: clamp(var(--font-size-sm), 2vw, var(--font-size-base));
}

@media (max-width: 768px) {
  .heroSection {
    padding: var(--spacing-md) var(--spacing-sm);
  }
}

@media (prefers-reduced-motion: reduce) {
  .ctaButton {
    transition: none;
  }
}