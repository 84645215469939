/* File: frontend/src/pages/TripDetail/components/TripEngagement/TripEngagement.module.css */

.container {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-md);
}

.versionSelector {
  display: flex;
  align-items: center;
  gap: var(--spacing-md);
  background-color: var(--bg-secondary);
  padding: var(--spacing-sm);
  border-radius: var(--border-radius-md);
}

.versionLabel {
  font-weight: var(--font-weight-medium);
  color: var(--text-secondary);
}

.versionButtons {
  display: flex;
  gap: var(--spacing-xs);
}

.versionButton {
  padding: var(--spacing-xs) var(--spacing-sm);
  background: none;
  border: 1px solid var(--color-border);
  border-radius: var(--border-radius-sm);
  font-size: var(--font-size-sm);
  color: var(--text-secondary);
  cursor: pointer;
  transition: all var(--transition-fast);
}

.versionButton:hover {
  background-color: var(--bg-hover);
}

.activeVersion {
  background-color: var(--color-primary);
  color: var(--color-white);
  border-color: var(--color-primary);
}

.engagementContent {
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-template-rows: auto 1fr;
  gap: var(--spacing-md);
}

.scoreCard {
  grid-column: 1;
  grid-row: 1;
  background-color: var(--bg-secondary);
  border-radius: var(--border-radius-md);
  padding: var(--spacing-md);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.scoreValue {
  font-size: var(--font-size-3xl);
  font-weight: var(--font-weight-bold);
  color: var(--color-primary);
  margin-bottom: var(--spacing-xs);
}

.scoreLabel {
  font-size: var(--font-size-base);
  color: var(--text-secondary);
}

.chartContainer {
  grid-column: 2;
  grid-row: 1 / span 2;
  height: 300px;
  background-color: var(--bg-secondary);
  border-radius: var(--border-radius-md);
  overflow: hidden;
  position: relative;
}

.loading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.loadingSpinner {
  border: 3px solid var(--color-border);
  border-top: 3px solid var(--color-primary);
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 1s linear infinite;
  margin-bottom: var(--spacing-md);
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.chartError {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  color: var(--color-error);
  text-align: center;
  padding: var(--spacing-md);
}

.chartPlaceholder {
  height: 100%;
  width: 100%;
}

.mockChart {
  height: 100%;
  width: 100%;
  background-color: #f8f8f8;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: var(--spacing-md);
}

.mockChartText {
  font-size: var(--font-size-lg);
  color: var(--text-tertiary);
  text-align: center;
}

.factorsContainer {
  grid-column: 1;
  grid-row: 2;
  background-color: var(--bg-secondary);
  border-radius: var(--border-radius-md);
  padding: var(--spacing-md);
}

.factorsTitle {
  font-size: var(--font-size-md);
  font-weight: var(--font-weight-semibold);
  color: var(--text-primary);
  margin-top: 0;
  margin-bottom: var(--spacing-md);
}

.factorsGrid {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-md);
}

.factorCard {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xs);
}

.factorName {
  font-size: var(--font-size-sm);
  color: var(--text-secondary);
}

.factorBar {
  height: 8px;
  background-color: var(--bg-primary);
  border-radius: 4px;
  overflow: hidden;
}

.factorProgress {
  height: 100%;
  background-color: var(--color-primary);
  border-radius: 4px;
}

.factorValue {
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-semibold);
  color: var(--text-primary);
  text-align: right;
}

.noEngagement {
  background-color: var(--bg-secondary);
  border-radius: var(--border-radius-md);
  padding: var(--spacing-lg);
  text-align: center;
  color: var(--text-secondary);
}

.engagementInfo {
  background-color: var(--bg-primary);
  border-radius: var(--border-radius-md);
  border: 1px solid var(--color-border);
  padding: var(--spacing-md);
}

.infoTitle {
  font-size: var(--font-size-md);
  font-weight: var(--font-weight-semibold);
  color: var(--text-primary);
  margin-top: 0;
  margin-bottom: var(--spacing-sm);
}

.infoText {
  color: var(--text-secondary);
  margin-bottom: var(--spacing-md);
}

.infoFactors {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: var(--spacing-md);
}

.infoFactor {
  background-color: var(--bg-secondary);
  border-radius: var(--border-radius-sm);
  padding: var(--spacing-sm);
}

.infoFactorTitle {
  font-weight: var(--font-weight-semibold);
  color: var(--text-primary);
  margin-bottom: var(--spacing-xs);
}

.infoFactorText {
  font-size: var(--font-size-sm);
  color: var(--text-secondary);
  margin: 0;
}

@media (max-width: 768px) {
  .engagementContent {
    grid-template-columns: 1fr;
    grid-template-rows: auto auto auto;
  }
  
  .scoreCard {
    grid-column: 1;
    grid-row: 1;
  }
  
  .chartContainer {
    grid-column: 1;
    grid-row: 2;
  }
  
  .factorsContainer {
    grid-column: 1;
    grid-row: 3;
  }
}