/* File: frontend/src/pages/InvestorPitch/components/slides/PartnershipsSlide/IntegrationTimeline.module.css */

.container {
    background-color: var(--color-gray-1200);
    border-radius: var(--border-radius-lg);
    padding: var(--spacing-xl);
    border: 1px solid var(--color-gray-1100);
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: var(--spacing-xl);
  }
  
  .highlight {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: var(--spacing-md);
  }
  
  .iconWrapper {
    width: 56px;
    height: 56px;
    border-radius: var(--border-radius-lg);
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--color-gray-1100);
  }
  
  .icon {
    width: 28px;
    height: 28px;
  }
  
  .content {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-xs);
  }
  
  .title {
    font-size: var(--font-size-lg);
    font-weight: var(--font-weight-bold);
    color: var(--text-on-primary);
  }
  
  .description {
    font-size: var(--font-size-sm);
    color: var(--color-gray-400);
    line-height: 1.5;
  }
  
  @media (max-width: 768px) {
    .container {
      grid-template-columns: 1fr;
      padding: var(--spacing-lg);
    }
  }