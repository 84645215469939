/* File: frontend/src/pages/InvestorPitch/components/cards/StatCard.module.css */

.card {
    background-color: var(--color-gray-1200);
    border-radius: var(--border-radius-lg);
    padding: var(--spacing-xl);
    text-align: center;
    border: 1px solid var(--color-gray-1100);
    transition: all var(--transition-fast);
  }
  
  .value {
    font-size: var(--font-size-4xl);
    font-weight: var(--font-weight-bold);
    margin-bottom: var(--spacing-md);
    line-height: 1;
  }
  
  .title {
    font-size: var(--font-size-lg);
    color: var(--text-on-primary);
    line-height: 1.4;
  }
  
  @media (max-width: 768px) {
    .card {
      padding: var(--spacing-lg);
    }
  
    .value {
      font-size: var(--font-size-3xl);
    }
  
    .title {
      font-size: var(--font-size-base);
    }
  }