/* File: frontend/src/pages/InvestorPitch/components/slides/ProductEvolutionSlide/RoadmapCard.module.css */

.container {
  background-color: var(--color-gray-1200);
  border-radius: var(--border-radius-lg);
  border: 1px solid var(--color-gray-1100);
  overflow: hidden;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.header {
  padding: var(--spacing-md);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.version {
  font-size: var(--font-size-xl);
  font-weight: var(--font-weight-bold);
  color: var(--color-white);
  margin: 0;
}

.date {
  display: flex;
  align-items: center;
  gap: var(--spacing-xs);
  font-size: var(--font-size-sm);
  color: var(--color-white);
  opacity: 0.9;
}

.icon {
  width: 16px;
  height: 16px;
}

.content {
  padding: var(--spacing-md);
  display: flex;
  flex-direction: column;
  flex: 1;
}

.focus {
  font-size: var(--font-size-lg);
  font-weight: var(--font-weight-bold);
  color: var(--text-on-primary);
  margin-bottom: var(--spacing-md);
  padding-bottom: var(--spacing-md);
  border-bottom: 1px solid var(--color-gray-1100);
}

.features {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-sm);
  flex: 1;
}

.feature {
  display: flex;
  align-items: flex-start;
  gap: var(--spacing-sm);
  color: var(--color-gray-400);
  font-size: var(--font-size-sm);
  line-height: 1.4;
}

.checkIcon {
  width: 16px;
  height: 16px;
  color: var(--color-primary-500);
  flex-shrink: 0;
  margin-top: 2px;
}

@media (max-width: 992px) {
  .version {
    font-size: var(--font-size-lg);
  }
  
  .focus {
    font-size: var(--font-size-base);
  }
}

@media (max-width: 480px) {
  .header {
    padding: var(--spacing-sm);
  }
  
  .content {
    padding: var(--spacing-sm);
  }
  
  .version {
    font-size: var(--font-size-base);
  }
  
  .date {
    font-size: var(--font-size-xs);
  }
  
  .focus {
    font-size: var(--font-size-sm);
    margin-bottom: var(--spacing-sm);
    padding-bottom: var(--spacing-sm);
  }
  
  .feature {
    font-size: var(--font-size-xs);
  }
  
  .checkIcon {
    width: 14px;
    height: 14px;
  }
}