/* File: frontend/src/pages/InvestorPitch/components/slides/GoToMarketSlide/DeviceMockups.module.css */

/* Macbook Mockup */
.macbookContainer {
  width: 100%;
  max-width: 320px;
  display: flex;
  flex-direction: column;
  align-items: center;
  filter: drop-shadow(0 10px 20px rgba(0, 0, 0, 0.3));
  transform-origin: bottom center;
}

.macbookTop {
  width: 100%;
  background-color: #1e1e1e;
  border-radius: 10px 10px 0 0;
  padding: 10px 10px 0;
  border: 1px solid #444;
  border-bottom: none;
}

.macbookScreen {
  width: 100%;
  aspect-ratio: 16/10;
  background-color: #000;
  overflow: hidden;
  border-radius: 2px;
}

.macbookBottom {
  width: 110%;
  height: 12px;
  background: linear-gradient(to bottom, #1e1e1e, #444);
  border-radius: 0 0 20px 20px;
  position: relative;
  border: 1px solid #444;
  border-top: none;
}

.macbookTrackpad {
  width: 40%;
  height: 4px;
  background-color: #333;
  margin: 0 auto;
  border-radius: 0 0 4px 4px;
}

/* iPad Mockup */
.ipadContainer {
  width: 100%;
  max-width: 180px;
  background-color: #1e1e1e;
  border-radius: 20px;
  padding: 20px 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 0 0 1px #444;
  border: 1px solid #444;
  filter: drop-shadow(0 10px 20px rgba(0, 0, 0, 0.3));
}

.ipadCamera {
  width: 6px;
  height: 6px;
  background-color: #333;
  border-radius: 50%;
  margin-bottom: 10px;
}

.ipadScreen {
  width: 100%;
  aspect-ratio: 3/4;
  background-color: #000;
  overflow: hidden;
  border-radius: 2px;
  margin-bottom: 10px;
}

.ipadHomeButton {
  width: 30px;
  height: 30px;
  border: 1px solid #444;
  border-radius: 50%;
}

/* iPhone Mockup */
.iphoneContainer {
  width: 100%;
  max-width: 160px;
  background-color: #1e1e1e;
  border-radius: 30px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 0 0 1px #444;
  border: 1px solid #444;
  filter: drop-shadow(0 10px 20px rgba(0, 0, 0, 0.3));
}

.iphoneNotch {
  width: 50%;
  height: 20px;
  background-color: #1e1e1e;
  border-radius: 0 0 10px 10px;
  margin-bottom: 5px;
  position: relative;
  z-index: 1;
}

.iphoneScreen {
  width: 100%;
  aspect-ratio: 9/19.5;
  background-color: #000;
  overflow: hidden;
  border-radius: 20px;
  margin-bottom: 5px;
  position: relative;
  top: -10px;
}

.iphoneHomeIndicator {
  width: 30%;
  height: 4px;
  background-color: #444;
  border-radius: 2px;
  margin-top: -5px;
}

/* API Mockup */
.apiContainer {
  width: 100%;
  max-width: 280px;
  background-color: #1e1e1e;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 0 0 1px #444;
  border: 1px solid #444;
  filter: drop-shadow(0 10px 20px rgba(0, 0, 0, 0.3));
}

.apiHeader {
  height: 30px;
  background-color: #2d2d2d;
  display: flex;
  align-items: center;
  padding: 0 10px;
  gap: 6px;
}

.apiDot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
}

.apiScreen {
  display: flex;
  height: 240px;
}

.apiSidebar {
  width: 50px;
  background-color: #252525;
  padding: 10px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.apiSidebarItem {
  width: 30px;
  height: 30px;
  background-color: #333;
  border-radius: 4px;
}

.apiContent {
  flex: 1;
  background-color: #1a1a1a;
  overflow: hidden;
  padding: 15px;
}