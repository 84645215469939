/* File: frontend/src/pages/Status/components/ServicesList.module.css */

.container {
    background: var(--bg-primary);
    border-radius: var(--border-radius-lg);
    padding: var(--spacing-md);
    margin-bottom: var(--spacing-md);
    box-shadow: var(--shadow-sm);
}

.title {
    margin: 0 0 var(--spacing-md) 0;
    font-size: var(--font-size-lg);
    font-weight: var(--font-weight-semibold);
    color: var(--text-primary);
}

.servicesList {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: var(--spacing-sm);
}

.serviceCard {
    background: var(--bg-secondary);
    border-radius: var(--border-radius-md);
    padding: var(--spacing-sm);
    transition: var(--transition-fast);
}

.serviceCard:hover {
    transform: translateY(-2px);
}

.serviceHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: var(--spacing-xxs);
}

.serviceName {
    display: flex;
    align-items: center;
    gap: var(--spacing-xs);
}

.displayName {
    font-weight: var(--font-weight-medium);
    color: var(--text-primary);
}

.status {
    padding: var(--spacing-xxxs) var(--spacing-xxs);
    border-radius: var(--border-radius-full);
    font-size: var(--font-size-xs);
    font-weight: var(--font-weight-semibold);
    text-transform: uppercase;
}

.statusOk {
    background-color: var(--color-success-bg);
    color: var(--color-success);
}

.statusDegraded {
    background-color: var(--color-warning-bg);
    color: var(--color-warning);
}

.statusError {
    background-color: var(--color-error-bg);
    color: var(--color-error);
}

.statusUnknown {
    background-color: var(--bg-tertiary);
    color: var(--text-secondary);
}

.latency {
    display: flex;
    align-items: center;
    gap: var(--spacing-xxs);
    font-size: var(--font-size-sm);
}

.latencyLabel {
    color: var(--text-secondary);
}

.latencyValue {
    font-weight: var(--font-weight-medium);
    color: var(--text-primary);
}

.details {
    margin-top: var(--spacing-sm);
    padding-top: var(--spacing-sm);
    border-top: 1px solid var(--color-border);
}

.detailItem {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: var(--spacing-xxxs) 0;
    font-size: var(--font-size-sm);
}

.detailLabel {
    color: var(--text-secondary);
}

.detailValue {
    font-weight: var(--font-weight-medium);
    color: var(--text-primary);
}

.additionalInfo {
    margin-top: var(--spacing-sm);
    padding-top: var(--spacing-sm);
    border-top: 1px solid var(--color-border);
    font-size: var(--font-size-sm);
}

.quotas {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    gap: var(--spacing-xxs);
}

.quotaItem, .infoItem {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: var(--spacing-xxxs) 0;
}

.quotaItem span:first-child,
.infoItem span:first-child {
    color: var(--text-secondary);
}

.quotaItem span:last-child,
.infoItem span:last-child {
    font-weight: var(--font-weight-medium);
    color: var(--text-primary);
}

.loading {
    padding: var(--spacing-lg);
    text-align: center;
    color: var(--text-secondary);
}

.empty {
    padding: var(--spacing-lg);
    text-align: center;
    color: var(--text-secondary);
    background: var(--bg-secondary);
    border-radius: var(--border-radius-md);
}

@media (max-width: 768px) {
    .servicesList {
        grid-template-columns: 1fr;
    }

    .serviceHeader {
        flex-direction: column;
        align-items: flex-start;
        gap: var(--spacing-xxs);
    }
}