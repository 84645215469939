/* File: frontend/src/pages/InvestorPitch/components/slides/TechnologySlide/TechnologySlide.module.css */

.container {
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: var(--spacing-xl);
  }
  
  .header {
    text-align: center;
    margin-bottom: var(--spacing-xl);
  }
  
  .title {
    font-size: var(--font-size-4xl);
    font-weight: var(--font-weight-bold);
    color: var(--text-on-primary);
    margin-bottom: var(--spacing-md);
  }
  
  .subtitle {
    font-size: var(--font-size-xl);
    color: var(--color-gray-400);
  }
  
  .techGrid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: var(--spacing-xl);
    padding: 0 var(--spacing-lg);
  }
  
  @media (max-width: 768px) {
    .techGrid {
      grid-template-columns: 1fr;
      gap: var(--spacing-lg);
      padding: 0;
    }
  }