/* File: frontend/src/pages/SuperAdmin/components/common/SuperAdminSidebar/SuperAdminSidebar.module.css */

.sidebar {
  width: 260px;
  background-color: var(--bg-primary);
  border-right: 1px solid var(--color-border);
  display: flex;
  flex-direction: column;
  height: calc(100vh - 64px); /* Adjust based on header height */
  position: sticky;
  top: 64px; /* Adjust based on header height */
}

.navigation {
  flex: 1;
  overflow-y: auto;
  padding: var(--spacing-md) 0;
}

.navList {
  list-style: none;
  padding: 0;
  margin: 0;
}

.navItem {
  margin-bottom: 2px;
}

.navButton {
  display: flex;
  align-items: center;
  width: 100%;
  padding: var(--spacing-sm) var(--spacing-md);
  background: none;
  border: none;
  text-align: left;
  font-size: var(--font-size-base);
  color: var(--text-secondary);
  cursor: pointer;
  transition: all var(--transition-fast);
  border-left: 3px solid transparent;
}

.navButton:hover {
  background-color: var(--bg-hover);
  color: var(--text-primary);
}

.navButton.active {
  background-color: var(--color-primary-100);
  color: var(--color-primary);
  border-left-color: var(--color-primary);
  font-weight: var(--font-weight-medium);
}

.navIcon {
  width: 20px;
  margin-right: var(--spacing-sm);
  text-align: center;
}

.sidebarFooter {
  padding: var(--spacing-md);
  border-top: 1px solid var(--color-border);
}

.helpButton {
  display: flex;
  align-items: center;
  width: 100%;
  padding: var(--spacing-sm);
  background: none;
  border: 1px solid var(--color-border);
  border-radius: var(--border-radius-md);
  font-size: var(--font-size-sm);
  color: var(--text-secondary);
  cursor: pointer;
  transition: all var(--transition-fast);
}

.helpButton:hover {
  background-color: var(--bg-hover);
  color: var(--text-primary);
}

.helpButton i {
  margin-right: var(--spacing-sm);
}

@media (max-width: 768px) {
  .sidebar {
    width: 100%;
    height: auto;
    position: static;
  }
  
  .navigation {
    padding: var(--spacing-sm) 0;
  }
  
  .navButton {
    padding: var(--spacing-xs) var(--spacing-sm);
  }
}