/* File: frontend/src/components/layout/DashboardLayout/PageTitleControls.module.css */

.controlsContainer {
  display: flex;
  align-items: flex-end;
  gap: 16px;
}

.controlGroup {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.controlLabel {
  font-size: 12px;
  color: #6E7179;
  font-weight: 400;
}

.controlContent {
  display: flex;
  align-items: center;
}

.selectWrapper {
  position: relative;
  min-width: 120px;
}

.select {
  appearance: none;
  padding: 8px 32px 8px 12px;
  border: 1px solid #E9EAEC;
  border-radius: 8px;
  background-color: #FFFFFF;
  font-size: 14px;
  color: #14151A;
  width: 100%;
  height: 38px;
  cursor: pointer;
}

.selectArrow {
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 8px 12px;
  border: 1px solid #E9EAEC;
  border-radius: 8px;
  background-color: #FFFFFF;
  font-size: 14px;
  color: #14151A;
  cursor: pointer;
  height: 38px;
  transition: background-color 0.2s;
}

.button:hover:not(:disabled) {
  background-color: #F7F7F8;
}

.button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.primaryButton {
  background-color: #3667F8;
  color: #FFFFFF;
  border: none;
}

.primaryButton:hover:not(:disabled) {
  background-color: #2A52D3;
}

.buttonIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
}

.refreshButton, .exportButton {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 8px 12px;
  border: 1px solid #E9EAEC;
  border-radius: 8px;
  background-color: #FFFFFF;
  font-size: 14px;
  color: #14151A;
  cursor: pointer;
  height: 38px;
  transition: background-color 0.2s;
}

.refreshButton:hover:not(:disabled), .exportButton:hover {
  background-color: #F7F7F8;
}

.refreshButton:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.refreshIcon, .exportIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
}

.spinning {
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@media (max-width: 768px) {
  .controlsContainer {
    flex-wrap: wrap;
    width: 100%;
  }
  
  .controlGroup {
    flex: 1;
    min-width: 120px;
  }
  
  .button, .refreshButton, .exportButton {
    flex: 1;
  }
}