/* File: frontend/src/pages/AIAgents/components/CategorySection.module.css */

.container {
    position: relative;
    width: 100%;
    max-width: 800px;
    margin: 0 auto var(--spacing-3xl);
    padding: var(--spacing-2xl) var(--spacing-md);
  }
  
  .header {
    margin-bottom: var(--spacing-2xl);
    text-align: center;
  }
  
  .title {
    font-family: var(--font-primary);
    font-size: var(--font-size-3xl);
    font-weight: var(--font-weight-bold);
    line-height: var(--line-height-tight);
    margin-bottom: var(--spacing-md);
    background: linear-gradient(
      135deg,
      var(--color-blue-310),
      var(--color-blue-510)
    );
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
  }
  
  .description {
    font-family: var(--font-primary);
    font-size: var(--font-size-lg);
    font-weight: var(--font-weight-regular);
    line-height: var(--line-height-relaxed);
    color: var(--color-gray-400);
    max-width: 600px;
    margin: 0 auto;
  }
  
  .agentsGrid {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-2xl);
    width: 100%;
    max-width: 700px;
    margin: var(--spacing-2xl) auto 0;
  }
  
  /* Animation classes */
  .fadeIn {
    opacity: 0;
    animation: fadeIn var(--transition-normal) forwards;
  }
  
  .slideUp {
    opacity: 0;
    transform: translateY(var(--spacing-md));
    animation: slideUp var(--transition-normal) forwards;
  }
  
  /* Animations */
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  @keyframes slideUp {
    from {
      opacity: 0;
      transform: translateY(var(--spacing-md));
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  /* Responsive Breakpoints */
  @media (max-width: var(--breakpoint-lg)) {
    .container {
      padding: var(--spacing-xl) var(--spacing-md);
    }
  
    .title {
      font-size: var(--font-size-2xl);
    }
  
    .description {
      font-size: var(--font-size-base);
    }
  }
  
  @media (max-width: var(--breakpoint-md)) {
    .container {
      padding: var(--spacing-lg) var(--spacing-md);
    }
  
    .agentsGrid {
      gap: var(--spacing-xl);
    }
  
    .title {
      font-size: var(--font-size-xl);
    }
  }
  
  @media (max-width: var(--breakpoint-sm)) {
    .container {
      padding: var(--spacing-md);
    }
  
    .header {
      margin-bottom: var(--spacing-xl);
    }
  
    .agentsGrid {
      gap: var(--spacing-lg);
    }
  
    .description {
      font-size: var(--font-size-sm);
    }
  }