/* /charts/v2/types/PieChart.module.css */
.pieChartContainer {
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.tooltip {
  background-color: var(--color-gray-0);
  border: 1px solid var(--color-border);
  padding: var(--spacing-sm);
  border-radius: var(--border-radius-md);
  box-shadow: var(--shadow-sm);
}

.tooltipLabel {
  font-weight: 600;
  margin-bottom: var(--spacing-xs);
  color: var(--color-gray-900);
  font-size: var(--font-size-sm);
}

.tooltipValue {
  font-size: var(--font-size-sm);
  color: var(--color-gray-900);
  font-family: var(--font-family-mono);
}

.legendContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: var(--spacing-xs);
  padding: var(--spacing-xl) var(--spacing-md);
}

.legendItem {
  display: flex;
  align-items: center;
  gap: var(--spacing-xs);
}

.legendColor {
  width: 12px;
  height: 12px;
  border-radius: 50%;
}

.legendText {
  font-size: var(--font-size-sm);
  color: var(--color-gray-900);
}