/* File: frontend/src/pages/InvestorPitch/components/slides/DemoSlide/VideoPlayer.module.css */

.container {
    position: relative;
    width: 100%;
    padding-top: 56.25%; /* 16:9 Aspect Ratio */
  }
  
  .video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    outline: none;
  }
  
  .video::-webkit-media-controls-panel {
    background-image: linear-gradient(transparent, rgba(0, 0, 0, 0.7));
  }