/* File: frontend/src/pages/InvestorPitch/components/slides/TechnicalArchitectureSlide/DeploymentOption.module.css */

.container {
    background-color: var(--color-gray-1200);
    border-radius: var(--border-radius-lg);
    padding: var(--spacing-xl);
    border: 1px solid var(--color-gray-1100);
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: var(--spacing-lg);
    height: 100%;
  }
  
  .iconWrapper {
    width: 64px;
    height: 64px;
    border-radius: var(--border-radius-lg);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .icon {
    width: 32px;
    height: 32px;
    color: var(--color-white);
  }
  
  .title {
    font-size: var(--font-size-xl);
    font-weight: var(--font-weight-bold);
    color: var(--text-on-primary);
  }
  
  .description {
    font-size: var(--font-size-base);
    color: var(--color-gray-400);
    line-height: 1.5;
  }
  
  @media (max-width: 768px) {
    .container {
      padding: var(--spacing-lg);
    }
  
    .iconWrapper {
      width: 56px;
      height: 56px;
    }
  
    .icon {
      width: 28px;
      height: 28px;
    }
  
    .title {
      font-size: var(--font-size-lg);
    }
  
    .description {
      font-size: var(--font-size-sm);
    }
  }