/* File: frontend/src/pages/InvestorPitch/components/slides/HiringSlide/HiringSlide.module.css */

.container {
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-lg);
  padding: var(--spacing-md);
  width: 100%;
  position: relative;
  overflow: hidden;
}

.container::before {
  content: '';
  position: absolute;
  top: 0; left: 0; right: 0; bottom: 0;
  background: radial-gradient(
    circle at 50% 50%, 
    rgba(35, 72, 237, 0.05) 0%, 
    transparent 70%
  );
  opacity: 0.4;
  z-index: 0;
}

.header {
  text-align: center;
  margin-bottom: var(--spacing-md);
  position: relative;
  z-index: 1;
}

.title {
  font-size: var(--font-size-4xl);
  font-weight: var(--font-weight-bold);
  color: var(--text-on-primary);
  margin-bottom: var(--spacing-md);
  background: linear-gradient(135deg, var(--color-primary), var(--color-blue-510));
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}

.subtitle {
  font-size: var(--font-size-xl);
  color: var(--color-gray-400);
  max-width: 800px;
  margin: 0 auto;
}

.content {
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 1;
}

.navigationControls {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: var(--spacing-md);
  margin-bottom: var(--spacing-lg);
}

.navButton {
  background: transparent;
  border: none;
  color: var(--color-gray-400);
  cursor: pointer;
  padding: var(--spacing-xs);
  border-radius: var(--border-radius-full);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease;
}

.navButton:hover {
  color: var(--color-primary);
  background-color: rgba(35, 72, 237, 0.1);
}

.indicators {
  display: flex;
  gap: var(--spacing-sm);
}

.indicator {
  width: 10px;
  height: 10px;
  border-radius: var(--border-radius-full);
  background-color: var(--color-gray-800);
  border: none;
  cursor: pointer;
  transition: all 0.2s ease;
}

.activeIndicator {
  background-color: var(--color-primary);
  width: 20px;
}

/* Mission Section */
.missionSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 100%;
  padding: var(--spacing-xl) 0;
}

.missionContent {
  max-width: 800px;
  margin: 0 auto;
}

.missionTitle {
  font-size: var(--font-size-4xl);
  font-weight: var(--font-weight-bold);
  line-height: 1.2;
  margin-bottom: var(--spacing-xl);
  color: var(--text-on-primary);
}

.gradientText {
  background: linear-gradient(135deg, var(--color-primary), var(--color-blue-310));
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}

.buttonWrapper {
  margin-top: var(--spacing-2xl);
}

.ctaButton {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: var(--spacing-md) var(--spacing-xl);
  font-size: var(--font-size-lg);
  font-weight: var(--font-weight-bold);
  text-decoration: none;
  border-radius: var(--border-radius-full);
  background: linear-gradient(
    135deg,
    var(--color-primary) 0%,
    var(--color-blue-310) 100%
  );
  color: var(--color-white);
  transition: all 0.3s ease;
  box-shadow: 0 5px 15px rgba(35, 72, 237, 0.2);
  position: relative;
  overflow: hidden;
}

.ctaButton::before {
  content: '';
  position: absolute;
  top: 0; left: 0; right: 0; bottom: 0;
  background: linear-gradient(
    135deg,
    rgba(255, 255, 255, 0.2) 0%,
    transparent 100%
  );
  opacity: 0;
  transition: opacity 0.2s ease;
}

.ctaButton:hover::before {
  opacity: 1;
}

/* Locations Section */
.locationsSection {
  padding: var(--spacing-md) 0;
}

.locationsGrid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: var(--spacing-xl);
  margin: 0 auto;
  max-width: 1200px;
}

.locationCard {
  background-color: var(--color-gray-1200);
  border-radius: var(--border-radius-lg);
  padding: var(--spacing-lg);
  border: 1px solid var(--color-gray-1100);
  display: flex;
  flex-direction: column;
  gap: var(--spacing-md);
  transition: all 0.3s ease;
}

.locationCard:hover {
  transform: translateY(-5px);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.2);
  border-color: rgba(35, 72, 237, 0.3);
}

.locationHeader {
  display: flex;
  align-items: center;
  gap: var(--spacing-md);
}

.locationIconWrapper {
  width: 48px;
  height: 48px;
  border-radius: var(--border-radius-lg);
  display: flex;
  align-items: center;
  justify-content: center;
}

.locationIcon {
  width: 24px;
  height: 24px;
  color: var(--color-white);
}

.locationInfo {
  flex: 1;
}

.locationCity {
  font-size: var(--font-size-xl);
  font-weight: var(--font-weight-bold);
  color: var(--text-on-primary);
  margin: 0;
}

.locationCountry {
  font-size: var(--font-size-sm);
  color: var(--color-gray-400);
  margin: 0;
}

.locationTimeline {
  display: flex;
  align-items: center;
  gap: var(--spacing-sm);
  color: var(--color-gray-400);
  font-size: var(--font-size-sm);
}

.timelineIcon {
  width: 16px;
  height: 16px;
}

.locationHeadcount {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-sm);
}

.headcountHeader {
  display: flex;
  align-items: center;
  gap: var(--spacing-sm);
  color: var(--text-on-primary);
  font-size: var(--font-size-sm);
}

.headcountIcon {
  width: 16px;
  height: 16px;
}

.progressBarContainer {
  width: 100%;
  height: 6px;
  background-color: var(--color-gray-1100);
  border-radius: var(--border-radius-full);
  overflow: hidden;
}

.progressBar {
  height: 100%;
  border-radius: var(--border-radius-full);
  transition: width 1s ease;
}

.locationRoles {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-sm);
}

.locationRole {
  font-size: var(--font-size-sm);
  color: var(--color-gray-400);
  padding-left: var(--spacing-md);
  position: relative;
}

.locationRole::before {
  content: "•";
  position: absolute;
  left: 0;
  color: var(--color-primary);
}

/* Positions Section */
.positionsSection {
  padding: var(--spacing-md) 0;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xl);
}

.sectionTitle {
  text-align: center;
  color: var(--text-on-primary);
  font-size: var(--font-size-2xl);
  margin: 0 0 var(--spacing-lg);
  font-weight: var(--font-weight-bold);
}

.counter {
  color: var(--color-primary);
  position: relative;
  display: inline-block;
}

.counter::after {
  content: '';
  position: absolute;
  bottom: -4px;
  left: 0;
  width: 100%;
  height: 2px;
  background: var(--color-primary);
  transform-origin: left;
  animation: lineGrow 0.6s ease-out forwards;
}

@keyframes lineGrow {
  from { transform: scaleX(0); }
  to { transform: scaleX(1); }
}

.positionsGrid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: var(--spacing-md);
  margin: 0 auto;
  max-width: 1200px;
}

.positionCard {
  background-color: var(--color-gray-1200);
  border-radius: var(--border-radius-md);
  padding: var(--spacing-md);
  border: 1px solid var(--color-gray-1100);
  display: flex;
  align-items: center;
  gap: var(--spacing-md);
  transition: all 0.3s ease;
}

.positionIcon {
  color: var(--color-primary);
}

.blue .positionIcon {
  color: var(--color-blue-310);
}

.green .positionIcon {
  color: var(--color-green-310);
}

.orange .positionIcon {
  color: var(--color-orange-310);
}

.positionTitle {
  font-weight: var(--font-weight-medium);
  color: var(--text-on-primary);
  font-size: var(--font-size-base);
}

.timelineContainer {
  margin-top: var(--spacing-xl);
  background-color: var(--color-gray-1200);
  border-radius: var(--border-radius-lg);
  padding: var(--spacing-lg);
  border: 1px solid var(--color-gray-1100);
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}

.timelineTitle {
  font-size: var(--font-size-lg);
  font-weight: var(--font-weight-bold);
  color: var(--text-on-primary);
  margin-bottom: var(--spacing-lg);
  text-align: center;
}

.timeline {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-lg);
}

.timelinePhase {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-sm);
}

.phaseHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.phasePeriod {
  font-family: monospace;
  font-size: var(--font-size-sm);
  color: var(--text-on-primary);
  font-weight: var(--font-weight-medium);
}

.phaseHires {
  font-size: var(--font-size-sm);
  color: var(--color-gray-400);
}

.phaseContent {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-sm);
}

.phaseName {
  font-size: var(--font-size-base);
  color: var(--text-on-primary);
}

/* Compensation Section */
.compensationSection {
  padding: var(--spacing-md) 0;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xl);
  align-items: center;
}

.compensationGrid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: var(--spacing-xl);
  margin: 0 auto;
  max-width: 1200px;
  width: 100%;
}

.compensationCard {
  background-color: var(--color-gray-1200);
  border-radius: var(--border-radius-lg);
  padding: var(--spacing-xl);
  border: 1px solid var(--color-gray-1100);
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: var(--spacing-md);
  transition: all 0.3s ease;
  height: 100%;
}

.compensationIconWrapper {
  margin-bottom: var(--spacing-sm);
  animation: pulse 2s infinite ease-in-out;
}

@keyframes pulse {
  0%, 100% { transform: scale(1); }
  50% { transform: scale(1.1); }
}

.compensationTitle {
  font-size: var(--font-size-lg);
  font-weight: var(--font-weight-bold);
  color: var(--text-on-primary);
  margin: 0;
}

.compensationRange {
  font-size: var(--font-size-xl);
  font-weight: var(--font-weight-bold);
  margin: var(--spacing-xs) 0;
}

.compensationDetails {
  font-size: var(--font-size-sm);
  color: var(--color-gray-400);
  line-height: 1.5;
  margin: 0;
}

/* Responsive styles */
@media (max-width: 1200px) {
  .locationsGrid,
  .positionsGrid,
  .compensationGrid {
    grid-template-columns: repeat(2, 1fr);
    gap: var(--spacing-lg);
  }
  
  .missionTitle {
    font-size: var(--font-size-3xl);
  }
}

@media (max-width: 992px) {
  .title {
    font-size: var(--font-size-3xl);
  }
  
  .subtitle {
    font-size: var(--font-size-lg);
    max-width: 90%;
  }
  
  .missionTitle {
    font-size: var(--font-size-2xl);
  }
  
  .ctaButton {
    padding: var(--spacing-sm) var(--spacing-lg);
    font-size: var(--font-size-base);
  }
}

@media (max-width: 768px) {
  .locationsGrid,
  .positionsGrid,
  .compensationGrid {
    grid-template-columns: 1fr;
    gap: var(--spacing-md);
  }
  
  .title {
    font-size: var(--font-size-2xl);
  }
  
  .subtitle {
    font-size: var(--font-size-md);
    max-width: 100%;
  }
  
  .missionTitle {
    font-size: var(--font-size-xl);
  }
  
  .locationIconWrapper,
  .compensationIconWrapper {
    width: 40px;
    height: 40px;
  }
  
  .locationIcon {
    width: 20px;
    height: 20px;
  }
  
  .locationCity {
    font-size: var(--font-size-lg);
  }
  
  .compensationTitle {
    font-size: var(--font-size-base);
  }
  
  .compensationRange {
    font-size: var(--font-size-lg);
  }
}

@media (max-width: 480px) {
  .container {
    padding: var(--spacing-sm);
  }
  
  .title {
    font-size: var(--font-size-xl);
  }
  
  .subtitle {
    font-size: var(--font-size-base);
  }
  
  .missionTitle {
    font-size: var(--font-size-lg);
  }
  
  .ctaButton {
    padding: var(--spacing-xs) var(--spacing-md);
    font-size: var(--font-size-sm);
  }
  
  .locationCard,
  .compensationCard,
  .timelineContainer {
    padding: var(--spacing-md);
  }
  
  .compensationRange {
    font-size: var(--font-size-base);
  }
  
  .compensationDetails {
    font-size: var(--font-size-xs);
  }
}