/* File: frontend/src/pages/PropertyDetail/PropertyDetail.module.css */

.container {
  padding: var(--spacing-lg);
  max-width: 1200px;
  margin: 0 auto;
}

.loading, .error, .notFound {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: var(--spacing-xl);
  text-align: center;
}

.error {
  color: var(--color-error);
}

.notFound {
  color: var(--text-secondary);
}

.backButton {
  margin-top: var(--spacing-md);
  padding: var(--button-padding-medium);
  background-color: var(--button-secondary-bg);
  color: var(--button-secondary-text);
  border: 1px solid var(--button-secondary-border);
  border-radius: var(--button-border-radius-md);
  font-size: var(--button-font-size-medium);
  font-weight: var(--font-weight-medium);
  cursor: pointer;
  transition: background-color var(--transition-fast);
}

.backButton:hover {
  background-color: var(--button-secondary-hover-bg);
}

.content {
  margin-top: var(--spacing-lg);
}

.tabs {
  display: flex;
  border-bottom: 1px solid var(--color-border);
  margin-bottom: var(--spacing-md);
  overflow-x: auto;
}

.tab {
  padding: var(--spacing-sm) var(--spacing-md);
  background: none;
  border: none;
  border-bottom: 2px solid transparent;
  font-size: var(--font-size-base);
  font-weight: var(--font-weight-medium);
  color: var(--text-secondary);
  cursor: pointer;
  transition: all var(--transition-fast);
  white-space: nowrap;
}

.tab:hover {
  color: var(--text-primary);
}

.activeTab {
  color: var(--color-primary);
  border-bottom-color: var(--color-primary);
}

.tabContent {
  background-color: var(--bg-primary);
  border-radius: var(--border-radius-lg);
  box-shadow: var(--shadow-card);
  padding: var(--spacing-md);
}

@media (max-width: 768px) {
  .container {
    padding: var(--spacing-md);
  }
  
  .tabs {
    padding-bottom: var(--spacing-xs);
  }
  
  .tab {
    padding: var(--spacing-xs) var(--spacing-sm);
    font-size: var(--font-size-sm);
  }
}