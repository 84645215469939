/* File: frontend/src/components/API/ApiParameters.module.css */

.section {
    margin-bottom: var(--spacing-xl);
  }
  
  .sectionTitle {
    font-size: var(--font-size-md);
    font-weight: var(--font-weight-semibold);
    color: var(--text-primary);
    margin-bottom: var(--spacing-lg);
  }
  
  .parameterGroup {
    margin-bottom: var(--spacing-lg);
  }
  
  .parameterGroup:last-child {
    margin-bottom: 0;
  }
  
  .groupTitle {
    font-size: var(--font-size-sm);
    font-weight: var(--font-weight-medium);
    color: var(--text-secondary);
    margin-bottom: var(--spacing-md);
  }
  
  .parameters {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-md);
  }
  
  .parameter {
    padding: var(--spacing-md);
    background: var(--bg-secondary);
    border-radius: var(--border-radius-md);
    border: 1px solid var(--color-border);
  }
  
  .parameterHeader {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: var(--spacing-xs);
  }
  
  .parameterInfo {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-xs);
  }
  
  .parameterName {
    font-family: var(--font-secondary);
    font-size: var(--font-size-sm);
    font-weight: var(--font-weight-medium);
    color: var(--text-primary);
  }
  
  .parameterBadges {
    display: flex;
    gap: var(--spacing-xs);
    flex-wrap: wrap;
  }
  
  .requiredBadge {
    font-size: var(--font-size-xs);
    padding: 2px var(--spacing-xs);
    border-radius: var(--border-radius-sm);
    background: var(--color-error-bg);
    color: var(--color-error);
    font-weight: var(--font-weight-medium);
  }
  
  .typeBadge {
    font-size: var(--font-size-xs);
    padding: 2px var(--spacing-xs);
    border-radius: var(--border-radius-sm);
    background: var(--color-primary-bg);
    color: var(--color-primary);
    font-weight: var(--font-weight-medium);
  }
  
  .parameterDescription {
    font-size: var(--font-size-sm);
    color: var(--text-secondary);
    line-height: var(--line-height-relaxed);
    margin-bottom: var(--spacing-sm);
  }
  
  .enumValues {
    margin-top: var(--spacing-sm);
    font-size: var(--font-size-sm);
  }
  
  .enumLabel {
    color: var(--text-secondary);
    margin-bottom: var(--spacing-xs);
    display: block;
  }
  
  .enumList {
    display: flex;
    flex-wrap: wrap;
    gap: var(--spacing-xs);
  }
  
  .enumValue {
    font-family: var(--font-secondary);
    font-size: var(--font-size-xs);
    padding: 2px var(--spacing-xs);
    background: var(--bg-tertiary);
    border-radius: var(--border-radius-sm);
    color: var(--text-secondary);
  }
  
  @media (max-width: 768px) {
    .parameterHeader {
      flex-direction: column;
      gap: var(--spacing-xs);
    }
  
    .parameter {
      padding: var(--spacing-sm);
    }
  }