/* src/components/KnowledgeTransfer/ParameterSelector.module.css */
.parameterSelector {
    background-color: #f5f5f5;
    border-radius: 8px;
    padding: 20px;
    margin-bottom: 20px;
  }
  
  .parameter {
    margin-bottom: 15px;
  }
  
  .parameter label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
  }
  
  .parameter input[type="range"] {
    width: 100%;
  }
  
  .parameter select {
    width: 100%;
    padding: 5px;
  }
  
  .toneOption {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .toneOption label {
    flex: 1;
  }
  
  .toneOption input[type="range"] {
    flex: 2;
    margin: 0 10px;
  }
  
  .toneOption span {
    flex: 0 0 30px;
    text-align: right;
  }