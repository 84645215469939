/* File: frontend/src/pages/UMS/UserPortfolio/components/AIInsights/AIInsights.module.css */

.container {
  padding: var(--spacing-md);
}

.header {
  margin-bottom: var(--spacing-lg);
  text-align: center;
}

.title {
  font-size: var(--font-size-lg);
  font-weight: var(--font-weight-bold);
  color: var(--text-primary);
  margin-bottom: var(--spacing-xs);
}

.subtitle {
  color: var(--text-secondary);
  font-size: var(--font-size-sm);
}

.insightsList {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: var(--spacing-md);
}

.insightCard {
  background-color: var(--bg-primary);
  border-radius: var(--border-radius-md);
  box-shadow: var(--shadow-md);
  padding: var(--spacing-md);
  border-top: 4px solid var(--color-primary);
  transition: transform var(--transition-normal);
}

.insightCard:hover {
  transform: translateY(-5px);
}

.insightCard.positive {
  border-top-color: var(--color-success);
}

.insightCard.negative {
  border-top-color: var(--color-error);
}

.insightCard.neutral {
  border-top-color: var(--color-info);
}

.insightHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: var(--spacing-sm);
}

.insightCategory {
  display: flex;
  align-items: center;
  gap: var(--spacing-xs);
  font-size: var(--font-size-xs);
  color: var(--text-secondary);
  font-weight: var(--font-weight-medium);
}

.insightDate {
  font-size: var(--font-size-xs);
  color: var(--text-tertiary);
}

.insightTitle {
  font-size: var(--font-size-md);
  font-weight: var(--font-weight-semibold);
  color: var(--text-primary);
  margin-bottom: var(--spacing-sm);
}

.insightDescription {
  color: var(--text-secondary);
  font-size: var(--font-size-sm);
  line-height: 1.5;
  margin-bottom: var(--spacing-md);
}

.recommendationsSection {
  background-color: var(--bg-secondary);
  border-radius: var(--border-radius-sm);
  padding: var(--spacing-sm);
  margin-bottom: var(--spacing-md);
}

.recommendationsTitle {
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-semibold);
  color: var(--text-primary);
  margin-bottom: var(--spacing-xs);
}

.recommendationsList {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.recommendationItem {
  position: relative;
  padding-left: 20px;
  margin-bottom: var(--spacing-xs);
  font-size: var(--font-size-sm);
  color: var(--text-secondary);
}

.recommendationItem:before {
  content: '•';
  position: absolute;
  left: 0;
  color: var(--color-primary);
}

.recommendationItem:last-child {
  margin-bottom: 0;
}

.insightActions {
  display: flex;
  gap: var(--spacing-sm);
  border-top: 1px solid var(--color-border-light);
  padding-top: var(--spacing-sm);
}

.actionButton {
  display: flex;
  align-items: center;
  gap: var(--spacing-xxs);
  padding: var(--spacing-xs) var(--spacing-sm);
  background: none;
  border: none;
  font-size: var(--font-size-xs);
  color: var(--text-secondary);
  cursor: pointer;
  border-radius: var(--border-radius-sm);
  transition: background-color var(--transition-fast);
}

.actionButton:hover {
  background-color: var(--bg-secondary);
  color: var(--text-primary);
}

.loadingContainer, .errorContainer, .noDataContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: var(--spacing-xl);
  text-align: center;
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  border-top: 4px solid var(--color-primary);
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
  margin-bottom: var(--spacing-md);
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.errorContainer {
  color: var(--color-error);
}

.noDataContainer {
  color: var(--text-secondary);
}

@media (max-width: 768px) {
  .insightsList {
    grid-template-columns: 1fr;
  }
}