/* File: frontend/src/pages/SuperAdmin/components/EnterpriseManagement/EnterpriseEdit/EnterpriseEdit.module.css */

.container {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-md);
}

.header {
  display: flex;
  justify-content: flex-start;
  margin-bottom: var(--spacing-md);
}

.backButton {
  display: flex;
  align-items: center;
  gap: var(--spacing-xs);
  padding: var(--spacing-sm) var(--spacing-md);
  background-color: var(--bg-secondary);
  color: var(--text-primary);
  border: 1px solid var(--color-border);
  border-radius: var(--border-radius-md);
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-medium);
  cursor: pointer;
  transition: all var(--transition-fast);
}

.backButton:hover:not(:disabled) {
  background-color: var(--bg-hover);
}

.backButton:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.errorMessage {
  display: flex;
  align-items: center;
  gap: var(--spacing-sm);
  padding: var(--spacing-md);
  background-color: var(--color-error-bg);
  color: var(--color-error);
  border-radius: var(--border-radius-md);
  margin-bottom: var(--spacing-md);
}

.errorMessage i {
  font-size: var(--font-size-lg);
}

.form {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-lg);
}

.formSection {
  background-color: var(--bg-primary);
  border-radius: var(--border-radius-md);
  border: 1px solid var(--color-border);
  padding: var(--spacing-md);
}

.sectionTitle {
  font-size: var(--font-size-lg);
  font-weight: var(--font-weight-semibold);
  color: var(--text-primary);
  margin: 0 0 var(--spacing-md) 0;
  padding-bottom: var(--spacing-xs);
  border-bottom: 1px solid var(--color-border-light);
}

.formGrid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: var(--spacing-md);
}

.formGroup {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xs);
}

.label {
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-medium);
  color: var(--text-secondary);
}

.required {
  color: var(--color-error);
}

.input, .select {
  padding: var(--spacing-sm);
  border: 1px solid var(--color-border);
  border-radius: var(--border-radius-md);
  font-size: var(--font-size-base);
  color: var(--text-primary);
  background-color: var(--bg-primary);
  transition: border-color var(--transition-fast), box-shadow var(--transition-fast);
}

.input:focus, .select:focus {
  outline: none;
  border-color: var(--color-primary);
  box-shadow: 0 0 0 2px var(--color-primary-100);
}

.input:disabled, .select:disabled {
  background-color: var(--bg-secondary);
  cursor: not-allowed;
}

.formActions {
  display: flex;
  justify-content: flex-end;
  gap: var(--spacing-md);
  margin-top: var(--spacing-md);
}

.cancelButton, .submitButton {
  padding: var(--spacing-sm) var(--spacing-lg);
  border-radius: var(--border-radius-md);
  font-size: var(--font-size-base);
  font-weight: var(--font-weight-medium);
  cursor: pointer;
  transition: all var(--transition-fast);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: var(--spacing-xs);
  min-width: 120px;
}

.cancelButton {
  background-color: var(--bg-secondary);
  color: var(--text-primary);
  border: 1px solid var(--color-border);
}

.cancelButton:hover:not(:disabled) {
  background-color: var(--bg-hover);
}

.submitButton {
  background-color: var(--color-primary);
  color: white;
  border: none;
}

.submitButton:hover:not(:disabled) {
  background-color: var(--color-primary-dark);
}

.cancelButton:disabled, .submitButton:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.spinner {
  display: inline-block;
  width: 16px;
  height: 16px;
  border: 2px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top-color: white;
  animation: spin 1s linear infinite;
}

.loading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: var(--spacing-xl);
  min-height: 300px;
}

.loading .spinner {
  width: 30px;
  height: 30px;
  border: 3px solid var(--color-border);
  border-top: 3px solid var(--color-primary);
  margin-bottom: var(--spacing-md);
}

.error {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: var(--spacing-xl);
  background-color: var(--bg-secondary);
  border-radius: var(--border-radius-md);
  color: var(--color-error);
  min-height: 200px;
  gap: var(--spacing-md);
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@media (max-width: 768px) {
  .formGrid {
    grid-template-columns: 1fr;
  }
  
  .formActions {
    flex-direction: column;
  }
  
  .cancelButton, .submitButton {
    width: 100%;
  }
}