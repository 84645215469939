/* File: frontend/src/components/layout/AuthLayout/AuthLayout.module.css */

.authLayout {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: var(--bg-secondary);
  padding: var(--spacing-lg);
  box-sizing: border-box;
  transition: opacity 0.3s ease-in-out;
}

.transitioning {
  opacity: 0.5;
}

.authContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
  background-color: var(--color-white);
  border-radius: var(--border-radius-xl);
  box-shadow: var(--shadow-md);
  overflow: visible;
}

.bottomSection {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1000px;
  /* margin-top: var(--spacing-md); */
  margin: 0 auto;
  padding: var(--spacing-md);
  border-top: 1px solid var(--color-border);
}

.headerRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: var(--spacing-lg) var(--spacing-lg);
  border-bottom: 1px solid var(--color-border);
}

.pageTitle {
  font-size: var(--font-size-2xl);
  font-weight: var(--font-weight-semibold);
  color: var(--text-primary);
  margin: 0;
}

.homeButton {
  display: flex;
  align-items: center;
  padding: var(--spacing-xs) var(--spacing-sm);
  background-color: var(--color-white);
  border: 1px solid var(--color-border);
  border-radius: var(--border-radius-sm);
  text-decoration: none;
  color: var(--text-primary);
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-medium);
  transition: var(--transition-fast);
}

.homeButton:hover {
  background-color: var(--bg-secondary);
  box-shadow: var(--shadow-sm);
}

.buttonIcon {
  width: var(--button-icon-size-small);
  height: var(--button-icon-size-small);
  margin-right: var(--spacing-xs);
}

.contentRow {
  flex: 1;
  padding: var(--spacing-sm) var(--spacing-lg);
  display: flex;
  position: relative;
  z-index: 1;
  overflow: visible;
  transition: all 0.3s ease-in-out;
}

.footerRow {
  display: flex;
  justify-content: space-between;
  padding: var(--spacing-lg) var(--spacing-lg);
  border-top: 1px solid var(--color-border);
}

.footerRow.alignNextRight {
  justify-content: flex-end;
}

.footerRow.alignNextRight .backButton {
  display: none;
}

.backButton, .nextButton {
  display: flex;
  align-items: center;
  padding: var(--button-padding-medium);
  border-radius: var(--button-border-radius-md);
  font-size: var(--button-font-size-medium);
  font-weight: var(--font-weight-medium);
  cursor: pointer;
  transition: opacity 0.2s ease-in-out, background-color 0.2s ease-in-out;
}

.backButton {
  background-color: var(--color-white);
  color: var(--text-primary);
  border: 1px solid var(--color-border);
}

.backButton:hover {
  background-color: var(--bg-secondary);
}

.nextButton {
  background-color: var(--button-primary-bg);
  color: var(--button-primary-text);
  border: none;
}

.nextButton:hover {
  background-color: var(--button-primary-hover-bg);
}

.nextButton:disabled {
  background-color: var(--color-bg-disabled);
  color: var(--text-tertiary);
  cursor: not-allowed;
}

.poweredByAllegory {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: var(--spacing-xs);
  color: var(--text-tertiary);
  font-size: var(--font-size-sm);
}

.poweredByAllegory img {
  height: 20px;
}

@keyframes loading {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(100%);
  }
}

@media (max-width: 786px) {
  .authLayout {
    padding: var(--spacing-md);
  }

  .authContainer {
    max-width: 100%;
  }

  .headerRow, .footerRow {
    padding: var(--spacing-md) var(--spacing-lg);
  }

  .contentRow {
    padding: var(--spacing-sm) var(--spacing-lg);
  }

  .pageTitle {
    font-size: var(--font-size-xl);
  }

  .bottomSection {
    flex-direction: row;
    align-items: flex-start;
    gap: var(--spacing-md);
  }

  .poweredByAllegory {
    flex-direction: column; /* Stack powered by and logo vertically */
    align-items: flex-end; /* Align to the right */
    gap: var(--spacing-xs);
    margin-left: auto; /* Push to the right */
  }

  .poweredByAllegory img {
    height: 16px; /* Slightly smaller logo on mobile */
  }

  .footerRow.alignNextRight {
    justify-content: flex-end;
  }
}

@media (max-width: 480px) {
  .authLayout {
    padding: 0;
    /* height: 100vh; Force full height on mobile */
    overflow: auto; /* Allow scrolling if needed */
  }

  .authContainer {
    border-radius: 0;
    box-shadow: none;
    min-height: 100%; /* Ensure container fills the height */
  }

  /* .homeButton span {
    display: none;
  } */

  .homeButton {
    padding: var(--spacing-xs);
  }

  .backButton, .nextButton {
    padding: var(--button-padding-small);
    font-size: var(--button-font-size-small);
  }

  .bottomSection {
    padding: var(--spacing-sm) var(--spacing-md);
  }

  .footerRow.alignNextRight {
    justify-content: flex-end;
  }
}