/* File: frontend/src/pages/AutoInsight/VehicleDetail/components/VehicleClaims/VehicleClaims.module.css */

.container {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-md);
}

.claimSummary {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: var(--spacing-md);
}

.summaryCard {
  background-color: var(--bg-primary);
  border-radius: var(--border-radius-md);
  border: 1px solid var(--color-border);
  padding: var(--spacing-md);
  text-align: center;
}

.summaryTitle {
  font-size: var(--font-size-sm);
  color: var(--text-secondary);
  margin-bottom: var(--spacing-xs);
}

.summaryValue {
  font-size: var(--font-size-2xl);
  font-weight: var(--font-weight-bold);
  color: var(--text-primary);
}

.openClaims {
  color: var(--color-warning);
}

.card {
  background-color: var(--bg-primary);
  border-radius: var(--border-radius-md);
  border: 1px solid var(--color-border);
  overflow: hidden;
}

.cardTitle {
  font-size: var(--font-size-md);
  font-weight: var(--font-weight-semibold);
  padding: var(--spacing-sm);
  background-color: var(--bg-secondary);
  border-bottom: 1px solid var(--color-border);
  margin: 0;
}

.cardContent {
  padding: var(--spacing-sm);
}

.claimsList {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-md);
}

.claimItem {
  padding: var(--spacing-md);
  border-radius: var(--border-radius-md);
  border: 1px solid var(--color-border);
  background-color: var(--bg-secondary);
}

.claimHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: var(--spacing-sm);
}

.claimType {
  font-weight: var(--font-weight-semibold);
  font-size: var(--font-size-lg);
}

.claimStatus {
  padding: 4px 8px;
  border-radius: 4px;
  font-size: var(--font-size-xs);
  font-weight: var(--font-weight-medium);
}

.open, .in {
  background-color: var(--color-warning-bg);
  color: var(--color-warning);
}

.closed, .settled {
  background-color: var(--color-success-bg);
  color: var(--color-success);
}

.denied {
  background-color: var(--color-error-bg);
  color: var(--color-error);
}

.claimDetails {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: var(--spacing-sm);
  margin-bottom: var(--spacing-md);
}

.claimDetail {
  display: flex;
  flex-direction: column;
}

.claimDetailLabel {
  font-size: var(--font-size-sm);
  color: var(--text-secondary);
  margin-bottom: var(--spacing-xxs);
}

.claimDetailValue {
  font-weight: var(--font-weight-medium);
}

.claimDescription {
  margin-bottom: var(--spacing-md);
  padding-bottom: var(--spacing-md);
  border-bottom: 1px solid var(--color-border-light);
}

.claimDescriptionLabel {
  font-weight: var(--font-weight-semibold);
  margin-bottom: var(--spacing-xxs);
}

.claimDescriptionValue {
  font-size: var(--font-size-sm);
  line-height: 1.5;
}

.claimActions {
  display: flex;
  gap: var(--spacing-sm);
}

.viewDetailsButton {
  padding: var(--button-padding-small);
  background-color: var(--button-secondary-bg);
  color: var(--button-secondary-text);
  border: 1px solid var(--button-secondary-border);
  border-radius: var(--button-border-radius-md);
  font-size: var(--button-font-size-small);
  font-weight: var(--font-weight-medium);
  cursor: pointer;
  transition: background-color var(--transition-fast);
}

.viewDetailsButton:hover {
  background-color: var(--button-secondary-hover-bg);
}

.updateButton {
  padding: var(--button-padding-small);
  background-color: var(--button-primary-bg);
  color: var(--button-primary-text);
  border: none;
  border-radius: var(--button-border-radius-md);
  font-size: var(--button-font-size-small);
  font-weight: var(--font-weight-medium);
  cursor: pointer;
  transition: background-color var(--transition-fast);
}

.updateButton:hover {
  background-color: var(--button-primary-hover-bg);
}

.noClaims {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: var(--spacing-xl);
  text-align: center;
  color: var(--text-secondary);
  background-color: var(--bg-secondary);
  border-radius: var(--border-radius-md);
  min-height: 200px;
}

.noClaimsDescription {
  max-width: 600px;
  margin-top: var(--spacing-md);
  color: var(--text-tertiary);
  font-size: var(--font-size-sm);
}

.fileClaimContainer {
  display: flex;
  justify-content: center;
  margin-top: var(--spacing-md);
}

.fileClaimButton {
  padding: var(--button-padding-medium);
  background-color: var(--button-primary-bg);
  color: var(--button-primary-text);
  border: none;
  border-radius: var(--button-border-radius-md);
  font-size: var(--button-font-size-medium);
  font-weight: var(--font-weight-medium);
  cursor: pointer;
  transition: background-color var(--transition-fast);
}

.fileClaimButton:hover {
  background-color: var(--button-primary-hover-bg);
}

@media (max-width: 768px) {
  .claimSummary {
    grid-template-columns: 1fr;
  }
  
  .claimDetails {
    grid-template-columns: 1fr;
  }
  
  .claimActions {
    flex-direction: column;
  }
  
  .viewDetailsButton, .updateButton {
    width: 100%;
  }
}