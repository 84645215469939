/* File: frontend/src/components/JobApplicationForm/CountrySelect.module.css */

.selectCountryContainer {
    z-index: 1; /* Lower than position select */
  }
  
.countrySelect {
  position: relative;
  z-index: var(--z-index-dropdown);
}

.countryOption {
  display: flex;
  align-items: center;
  gap: var(--spacing-sm);
  /* padding: var(--dropdown-item-padding); */
  color: var(--text-primary);
}

.flag {
  font-size: var(--font-size-lg);
  line-height: 1;
}

.countryName {
  flex: 1;
}

.selectedCountry {
  display: flex;
  align-items: center;
  gap: var(--spacing-sm);
}

.countryOption:hover {
  background-color: var(--dropdown-item-hover-background);
}