/* File: frontend/src/pages/InvestorPitch/components/slides/ObsessedWithDataSlide/ObsessedWithDataSlide.module.css */

.container {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: var(--spacing-md);
  gap: var(--spacing-lg);
}

.header {
  text-align: center;
  margin-bottom: var(--spacing-md);
}

.title {
  font-size: var(--font-size-4xl);
  font-weight: var(--font-weight-bold);
  color: var(--text-on-primary);
  margin-bottom: var(--spacing-md);
}

.subtitle {
  font-size: var(--font-size-xl);
  color: var(--color-gray-400);
  max-width: 800px;
  margin: 0 auto;
  line-height: 1.5;
}

/* Grid layout for the cards */
.grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: var(--spacing-lg);
  width: 100%;
  max-width: 1200px;
}

/* Individual video card */
.card {
  background-color: var(--color-gray-1200);
  border-radius: var(--border-radius-lg);
  overflow: hidden;
  box-shadow: var(--shadow-md);
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  border: 1px solid var(--color-gray-1100);
}

.card:hover {
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.4);
  border-color: var(--color-gray-1000);
}

/* Video thumbnail container */
.cardVideoContainer {
  position: relative;
  width: 100%;
  aspect-ratio: 16 / 9;
  background-color: #000;
  overflow: hidden;
}

/* Poster image */
.cardPoster {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* Play icon wrapper */
.playIconWrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.3);
  transition: background-color 0.2s ease;
}

.card:hover .playIconWrapper {
  background-color: rgba(0, 0, 0, 0.5);
}

/* Overlay play icon */
.playIcon {
  color: white;
  font-size: 3rem;
  opacity: 0.9;
  filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.5));
  transition: transform 0.2s ease, color 0.2s ease;
}

.card:hover .playIcon {
  transform: scale(1.1);
  color: var(--color-primary-500);
}

/* Card title */
.cardTitle {
  padding: var(--spacing-md);
  font-size: var(--font-size-md);
  font-weight: var(--font-weight-semibold);
  color: var(--text-on-primary);
  background-color: var(--color-gray-1200);
  text-align: center;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Modal styles */
.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.9);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  padding: var(--spacing-lg);
}

.modalContent {
  position: relative;
  width: 90%;
  max-width: 1200px;
  aspect-ratio: 16/9;
  background: #000;
  border-radius: var(--border-radius-lg);
  overflow: hidden;
  box-shadow: var(--shadow-lg);
}

.modalVideo {
  width: 100%;
  height: 100%;
  object-fit: contain;
  background-color: #000;
}

.closeButton {
  position: absolute;
  top: 16px;
  right: 16px;
  background-color: rgba(0, 0, 0, 0.7);
  border: none;
  color: white;
  font-size: 24px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.2s ease;
  z-index: 1;
}

.closeButton:hover {
  background-color: var(--color-primary-700);
}

.modalLoading {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1;
}

.loadingSpinner {
  width: 48px;
  height: 48px;
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-top-color: var(--color-primary-500);
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

.modalError {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  text-align: center;
  padding: var(--spacing-lg);
  z-index: 1;
}

.retryButton {
  margin-top: var(--spacing-md);
  padding: var(--spacing-sm) var(--spacing-lg);
  background-color: var(--color-primary-700);
  color: white;
  border: none;
  border-radius: var(--border-radius-md);
  cursor: pointer;
  font-weight: var(--font-weight-medium);
}

.retryButton:hover {
  background-color: var(--color-primary-600);
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* Responsive tweaks */
@media (max-width: 1200px) {
  .grid {
    grid-template-columns: repeat(2, 1fr);
  }
  
  .playIcon {
    font-size: 2.5rem;
  }
}

@media (max-width: 768px) {
  .container {
    padding: var(--spacing-sm);
  }
  
  .title {
    font-size: var(--font-size-3xl);
  }

  .subtitle {
    font-size: var(--font-size-lg);
    max-width: 90%;
  }

  .grid {
    gap: var(--spacing-md);
  }

  .cardTitle {
    font-size: var(--font-size-sm);
    padding: var(--spacing-sm);
  }
  
  .playIcon {
    font-size: 2rem;
  }
  
  .modalContent {
    width: 95%;
  }
  
  .closeButton {
    top: 8px;
    right: 8px;
    width: 32px;
    height: 32px;
    font-size: 20px;
  }
  
  .loadingSpinner {
    width: 36px;
    height: 36px;
    border-width: 3px;
  }
}

@media (max-width: 480px) {
  .grid {
    grid-template-columns: 1fr;
  }
  
  .title {
    font-size: var(--font-size-2xl);
  }
  
  .subtitle {
    font-size: var(--font-size-base);
    max-width: 100%;
  }
  
  .modalOverlay {
    padding: var(--spacing-sm);
  }
  
  .modalContent {
    width: 100%;
  }
  
  .loadingSpinner {
    width: 32px;
    height: 32px;
    border-width: 3px;
  }
}