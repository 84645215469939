/* File: frontend/src/pages/UMS/UserDetail/components/UserPermissions/UserPermissions.module.css */

.container {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-md);
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: var(--spacing-md);
}

.title {
  font-size: var(--font-size-lg);
  font-weight: var(--font-weight-semibold);
  color: var(--text-primary);
  margin: 0;
}

.editButton {
  padding: var(--button-padding-small);
  background-color: var(--button-primary-bg);
  color: var(--button-primary-text);
  border: none;
  border-radius: var(--button-border-radius-md);
  font-size: var(--button-font-size-small);
  font-weight: var(--font-weight-medium);
  cursor: pointer;
  transition: background-color var(--transition-fast);
}

.editButton:hover {
  background-color: var(--button-primary-hover-bg);
}

.editActions {
  display: flex;
  gap: var(--spacing-sm);
}

.cancelButton {
  padding: var(--button-padding-small);
  background-color: var(--button-tertiary-bg);
  color: var(--button-tertiary-text);
  border: 1px solid var(--button-tertiary-border);
  border-radius: var(--button-border-radius-md);
  font-size: var(--button-font-size-small);
  font-weight: var(--font-weight-medium);
  cursor: pointer;
  transition: background-color var(--transition-fast);
}

.cancelButton:hover {
  background-color: var(--button-tertiary-hover-bg);
}

.saveButton {
  padding: var(--button-padding-small);
  background-color: var(--button-success-bg);
  color: var(--button-success-text);
  border: none;
  border-radius: var(--button-border-radius-md);
  font-size: var(--button-font-size-small);
  font-weight: var(--font-weight-medium);
  cursor: pointer;
  transition: background-color var(--transition-fast);
}

.saveButton:hover {
  background-color: var(--button-success-hover-bg);
}

.permissionsGrid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: var(--spacing-md);
}

.permissionCard {
  background-color: var(--bg-primary);
  border-radius: var(--border-radius-md);
  border: 1px solid var(--color-border);
  overflow: hidden;
}

.cardTitle {
  font-size: var(--font-size-md);
  font-weight: var(--font-weight-semibold);
  padding: var(--spacing-sm);
  background-color: var(--bg-secondary);
  border-bottom: 1px solid var(--color-border);
  margin: 0;
}

.permissionList {
  padding: var(--spacing-md);
}

.permissionItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: var(--spacing-sm);
}

.permissionItem:last-child {
  margin-bottom: 0;
}

.permissionLabel {
  font-weight: var(--font-weight-medium);
  color: var(--text-primary);
}

.permissionToggle {
  padding: 4px 12px;
  border-radius: 16px;
  font-size: var(--font-size-xs);
  font-weight: var(--font-weight-semibold);
  text-align: center;
  min-width: 60px;
  cursor: pointer;
  transition: all var(--transition-fast);
}

.enabled {
  background-color: var(--color-success-bg);
  color: var(--color-success);
}

.disabled {
  background-color: var(--color-error-bg);
  color: var(--color-error);
}

.editingNote {
  text-align: center;
  color: var(--text-secondary);
  font-style: italic;
  margin: var(--spacing-sm) 0;
}

.permissionHistory {
  margin-top: var(--spacing-lg);
}

.historyTitle {
  font-size: var(--font-size-md);
  font-weight: var(--font-weight-semibold);
  margin-bottom: var(--spacing-sm);
}

.historyTable {
  width: 100%;
  border-collapse: collapse;
}

.historyTable th, .historyTable td {
  padding: var(--spacing-sm);
  text-align: left;
  border-bottom: 1px solid var(--color-border);
}

.historyTable th {
  background-color: var(--bg-secondary);
  font-weight: var(--font-weight-semibold);
  color: var(--text-secondary);
}

.historyTable tr:hover {
  background-color: var(--bg-hover);
}

.noHistory {
  padding: var(--spacing-md);
  text-align: center;
  color: var(--text-secondary);
  background-color: var(--bg-secondary);
  border-radius: var(--border-radius-md);
}

@media (max-width: 768px) {
  .permissionsGrid {
    grid-template-columns: 1fr;
  }
  
  .permissionItem {
    flex-direction: column;
    align-items: flex-start;
    gap: var(--spacing-xs);
  }
  
  .permissionToggle {
    align-self: flex-end;
  }
  
  .historyTable {
    display: block;
    overflow-x: auto;
  }
}