/* File: frontend/src/styles/pages/Home.module.css */

.homePage {
  background-color: var(--bg-primary);
  color: var(--text-primary);
}

.section {
  padding: var(--spacing-3xl) 0;
}

.fadeIn {
  animation: fadeIn var(--transition-normal);
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@media (max-width: 768px) {
  .section {
    padding: var(--spacing-2xl) 0;
  }
}