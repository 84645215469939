/* File: frontend/src/pages/InvestorPitch/components/slides/PartnershipsSlide/FlagshipPartner.module.css */

.container {
    background-color: var(--color-gray-1200);
    border-radius: var(--border-radius-lg);
    padding: var(--spacing-xl);
    border: 1px solid var(--color-gray-1100);
  }
  
  .header {
    display: flex;
    align-items: center;
    gap: var(--spacing-lg);
    margin-bottom: var(--spacing-xl);
  }
  
  .iconWrapper {
    width: 64px;
    height: 64px;
    border-radius: var(--border-radius-lg);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .icon {
    width: 32px;
    height: 32px;
    color: var(--color-white);
  }
  
  .info {
    flex: 1;
  }
  
  .name {
    font-size: var(--font-size-2xl);
    font-weight: var(--font-weight-bold);
    color: var(--text-on-primary);
    margin-bottom: var(--spacing-xs);
  }
  
  .type {
    font-size: var(--font-size-base);
    color: var(--color-gray-400);
  }
  
  .details {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: var(--spacing-lg);
  }
  
  .detail {
    display: flex;
    align-items: center;
    gap: var(--spacing-sm);
    color: var(--text-on-primary);
  }
  
  .checkIcon {
    width: 18px;
    height: 18px;
    color: var(--color-primary-500);
  }
  
  @media (max-width: 768px) {
    .details {
      grid-template-columns: 1fr;
    }
  }