/* File: frontend/src/pages/AutoInsight/VehicleDetail/components/VehicleSafety/VehicleSafety.module.css */

.container {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-md);
}

.grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: var(--spacing-md);
}

.card {
  background-color: var(--bg-primary);
  border-radius: var(--border-radius-md);
  border: 1px solid var(--color-border);
  overflow: hidden;
}

.cardTitle {
  font-size: var(--font-size-md);
  font-weight: var(--font-weight-semibold);
  padding: var(--spacing-sm);
  background-color: var(--bg-secondary);
  border-bottom: 1px solid var(--color-border);
  margin: 0;
}

.cardContent {
  padding: var(--spacing-sm);
}

.featuresGrid, .adasGrid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: var(--spacing-md);
}

.featureItem {
  display: flex;
  flex-direction: column;
}

.featureLabel {
  font-weight: var(--font-weight-medium);
  color: var(--text-secondary);
  margin-bottom: var(--spacing-xxs);
}

.featureValue {
  color: var(--text-primary);
}

.automationContainer {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-md);
}

.automationLevel {
  display: flex;
  flex-direction: column;
  margin-bottom: var(--spacing-md);
}

.automationLabel {
  font-weight: var(--font-weight-medium);
  color: var(--text-secondary);
  margin-bottom: var(--spacing-xxs);
}

.automationValue {
  color: var(--text-primary);
  font-size: var(--font-size-lg);
  font-weight: var(--font-weight-bold);
}

.automationDescription {
  background-color: var(--bg-secondary);
  border-radius: var(--border-radius-md);
  padding: var(--spacing-md);
}

.automationTitle {
  font-size: var(--font-size-base);
  font-weight: var(--font-weight-semibold);
  margin-top: 0;
  margin-bottom: var(--spacing-sm);
  color: var(--text-primary);
}

.automationList {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.automationListItem {
  margin-bottom: var(--spacing-xs);
  display: flex;
  align-items: flex-start;
}

.automationListItem:last-child {
  margin-bottom: 0;
}

.automationListLevel {
  font-weight: var(--font-weight-semibold);
  margin-right: var(--spacing-xxs);
  color: var(--color-primary);
}

.automationListText {
  color: var(--text-primary);
}

.noData {
  color: var(--text-secondary);
  font-style: italic;
  text-align: center;
  padding: var(--spacing-md);
}

@media (max-width: 768px) {
  .grid, .featuresGrid, .adasGrid {
    grid-template-columns: 1fr;
  }
}