/* File: frontend/src/components/DrivingScore/DrivingScoreContainer.module.css */

.container {
  /* max-width: 1200px; */
  /* margin: 0 auto; */
  /* padding: 20px; */
  gap: 24px;
}

.upperSection {
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
}

.leftColumn {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.rightColumn {
  flex: 1;
}

.section {
  margin-bottom: 40px;
}

.section h2 {
  font-size: 24px;
  margin-bottom: 20px;
  color: #14151A;
}

@media (max-width: 1200px) {
  .upperSection {
    flex-direction: column;
  }
}