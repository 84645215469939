/* File: frontend/src/components/API/ApiEndpoint.module.css */

.endpoint {
    background: var(--color-white);
    border-radius: var(--border-radius-lg);
    box-shadow: var(--shadow-sm);
    border: 1px solid var(--color-border);
    transition: box-shadow var(--transition-fast);
  }
  
  .endpoint:hover {
    box-shadow: var(--shadow-md);
  }
  
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: var(--spacing-md) var(--spacing-lg);
    cursor: pointer;
    user-select: none;
  }
  
  .headerContent {
    flex: 1;
    min-width: 0;
  }
  
  .methodAndPath {
    display: flex;
    align-items: center;
    gap: var(--spacing-md);
    margin-bottom: var(--spacing-xs);
  }
  
  .methodBadge {
    padding: var(--spacing-xxs) var(--spacing-xs);
    border-radius: var(--border-radius-sm);
    font-size: var(--font-size-xs);
    font-weight: var(--font-weight-semibold);
    text-transform: uppercase;
    flex-shrink: 0;
  }
  
  .methodGet { background: var(--color-success-bg); color: var(--color-success); }
  .methodPost { background: var(--color-primary-bg); color: var(--color-primary); }
  .methodPut { background: var(--color-warning-bg); color: var(--color-warning); }
  .methodDelete { background: var(--color-error-bg); color: var(--color-error); }
  .methodPatch { background: var(--color-info-bg); color: var(--color-info); }
  .methodDefault { background: var(--bg-secondary); color: var(--text-secondary); }
  
  .path {
    font-family: var(--font-secondary);
    font-size: var(--font-size-sm);
    color: var(--text-primary);
    font-weight: var(--font-weight-medium);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  
  .summary {
    font-size: var(--font-size-sm);
    color: var(--text-secondary);
    line-height: var(--line-height-snug);
  }
  
  .expandButton {
    background: none;
    border: none;
    padding: var(--spacing-xs);
    color: var(--text-tertiary);
    cursor: pointer;
    transition: transform var(--transition-fast);
    flex-shrink: 0;
  }
  
  .expandButton:hover {
    color: var(--text-secondary);
  }
  
  .expandButton.expanded {
    transform: rotate(180deg);
  }
  
  .content {
    border-top: 1px solid var(--color-border);
    padding: var(--spacing-lg);
  }
  
  .description {
    color: var(--text-secondary);
    font-size: var(--font-size-sm);
    line-height: var(--line-height-relaxed);
    margin-bottom: var(--spacing-lg);
  }
  
  .description p {
    margin-bottom: var(--spacing-xs);
  }
  
  .description p:last-child {
    margin-bottom: 0;
  }
  
  .section {
    margin-bottom: var(--spacing-xl);
  }
  
  .section:last-child {
    margin-bottom: 0;
  }
  
  .sectionTitle {
    font-size: var(--font-size-md);
    font-weight: var(--font-weight-semibold);
    color: var(--text-primary);
    margin-bottom: var(--spacing-md);
  }
  
  @media (max-width: 768px) {
    .header {
      padding: var(--spacing-md);
    }
  
    .methodAndPath {
      flex-direction: column;
      align-items: flex-start;
      gap: var(--spacing-xs);
    }
  
    .path {
      font-size: var(--font-size-xs);
    }
  
    .content {
      padding: var(--spacing-md);
    }
  }