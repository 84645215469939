/* File: frontend/src/pages/AI1/components/AIHeader/AIHeader.module.css */

.header {
    text-align: center;
    margin-bottom: var(--spacing-xl);
  }
  
  .titleWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: var(--spacing-sm);
    margin-bottom: var(--spacing-sm);
  }
  
  .icon {
    width: 24px;
    height: 24px;
    color: var(--color-primary);
  }
  
  .title {
    font-size: var(--font-size-4xl);
    font-weight: var(--font-weight-semibold);
    color: var(--text-primary);
    margin: 0;
  }
  
  .subtitle {
    font-size: var(--font-size-md);
    color: var(--text-secondary);
    margin: 0;
  }