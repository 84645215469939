/* File: frontend/src/pages/PropertyDetail/components/PropertySafety/PropertySafety.module.css */

.container {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-md);
}

.grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: var(--spacing-md);
}

.card {
  background-color: var(--bg-primary);
  border-radius: var(--border-radius-md);
  border: 1px solid var(--color-border);
  overflow: hidden;
}

.cardTitle {
  font-size: var(--font-size-md);
  font-weight: var(--font-weight-semibold);
  padding: var(--spacing-sm);
  background-color: var(--bg-secondary);
  border-bottom: 1px solid var(--color-border);
  margin: 0;
}

.cardContent {
  padding: var(--spacing-sm);
}

.featuresGrid, .fireProtectionGrid, .additionalFeaturesGrid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: var(--spacing-md);
}

.featureItem {
  display: flex;
  flex-direction: column;
}

.featureLabel {
  font-weight: var(--font-weight-medium);
  color: var(--text-secondary);
  margin-bottom: var(--spacing-xxs);
}

.featureValue {
  color: var(--text-primary);
  font-weight: var(--font-weight-semibold);
}

.safetyRecommendations {
  background-color: var(--bg-primary);
  border-radius: var(--border-radius-md);
  border: 1px solid var(--color-border);
  overflow: hidden;
}

.recommendationsTitle {
  font-size: var(--font-size-md);
  font-weight: var(--font-weight-semibold);
  padding: var(--spacing-sm);
  background-color: var(--bg-secondary);
  border-bottom: 1px solid var(--color-border);
  margin: 0;
}

.recommendationsList {
  padding: var(--spacing-md);
  display: flex;
  flex-direction: column;
  gap: var(--spacing-md);
}

.recommendationItem {
  display: flex;
  gap: var(--spacing-md);
  padding: var(--spacing-md);
  background-color: var(--color-warning-bg);
  border-radius: var(--border-radius-md);
  border-left: 4px solid var(--color-warning);
}

.recommendationIcon {
  font-size: var(--font-size-xl);
}

.recommendationContent {
  flex: 1;
}

.recommendationTitle {
  font-weight: var(--font-weight-semibold);
  margin-bottom: var(--spacing-xs);
  color: var(--color-warning-dark);
}

.recommendationDescription {
  font-size: var(--font-size-sm);
  color: var(--text-primary);
}

.noRecommendations {
  text-align: center;
  padding: var(--spacing-md);
  color: var(--color-success);
  font-weight: var(--font-weight-medium);
  background-color: var(--color-success-bg);
  border-radius: var(--border-radius-md);
}

.noData {
  color: var(--text-secondary);
  font-style: italic;
  text-align: center;
  padding: var(--spacing-md);
}

@media (max-width: 768px) {
  .grid {
    grid-template-columns: 1fr;
  }
  
  .featuresGrid, .fireProtectionGrid, .additionalFeaturesGrid {
    grid-template-columns: 1fr;
  }
}