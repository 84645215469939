.container {
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 0 var(--spacing-3xl);
    /* gap: var(--spacing-2xl); */
  }
  
  .header {
    text-align: center;
    margin-bottom: var(--spacing-2xl);
  }
  
  .title {
    font-size: var(--font-size-4xl);
    font-weight: var(--font-weight-bold);
    color: var(--text-on-primary);
    margin-bottom: var(--spacing-md);
    line-height: 1.2;
  }
  
  .subtitle {
    font-size: var(--font-size-xl);
    color: var(--color-gray-400);
  }
  
  .content {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-3xl);
    max-width: 1200px;
    margin: 0 auto;
    width: 100%;
  }
  
  .methodologyBox {
    background: var(--bg-secondary);
    border-radius: var(--border-radius-lg);
    padding: var(--spacing-2xl);
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: transform 0.3s ease;
    position: relative;
    overflow: hidden;
  }
  
  .boxContent {
    flex: 1;
    text-align: left;
    position: relative;
    z-index: 1;
  }
  
  .boxTitle {
    font-size: var(--font-size-2xl);
    font-weight: var(--font-weight-semibold);
    color: var(--text-primary);
    margin-bottom: var(--spacing-md);
  }
  
  .workflowIcon {
    font-size: var(--font-size-5xl);
    margin-left: var(--spacing-2xl);
    opacity: 0.8;
  }
  
  .benefitsGrid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: var(--spacing-xl);
  }
  
  .benefitCard {
    background: var(--bg-secondary);
    border-radius: var(--border-radius-lg);
    padding: var(--spacing-xl);
    display: flex;
    align-items: flex-start;
    gap: var(--spacing-lg);
    transition: transform 0.3s ease;
    position: relative;
    overflow: hidden;
  }
  
  .benefitIcon {
    font-size: var(--font-size-3xl);
    line-height: 1;
  }
  
  .benefitTitle {
    font-size: var(--font-size-xl);
    font-weight: var(--font-weight-semibold);
    margin-bottom: var(--spacing-xs);
  }
  
  .benefitDescription {
    font-size: var(--font-size-base);
    color: var(--text-secondary);
    line-height: var(--line-height-relaxed);
  }
  
  @media (max-width: 1024px) {
    .container {
      padding: var(--spacing-xl);
    }
    
    .benefitsGrid {
      gap: var(--spacing-lg);
    }
  }
  
  @media (max-width: 768px) {
    .benefitsGrid {
      grid-template-columns: 1fr;
    }
  
    .title {
      font-size: var(--font-size-3xl);
    }
  
    .subtitle {
      font-size: var(--font-size-lg);
    }
  
    .boxTitle {
      font-size: var(--font-size-xl);
    }
  
    .methodologyBox {
      flex-direction: column;
      text-align: center;
      padding: var(--spacing-xl);
    }
  
    .workflowIcon {
      margin: var(--spacing-lg) 0 0;
    }
  
    .boxContent {
      text-align: center;
    }
  }