/* File: frontend/src/pages/InvestorPitch/components/navigation/SlideQuickNav.module.css */

.mainContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 8px 0;
}

.container {
  position: relative;
}

.counter {
  background: var(--color-gray-1200);
  border: 1px solid var(--color-gray-1100);
  border-radius: 12px;
  color: var(--color-gray-400);
  padding: 8px 12px;
  font-family: var(--font-mono);
  font-size: var(--font-size-sm);
  cursor: pointer;
  transition: all var(--transition-fast);
}

.counter:hover {
  background: var(--color-gray-1100);
}

.logoContainer {
  margin-left: 12px;
}

.menu {
  position: absolute;
  top: calc(100% + 8px);
  left: 0;
  background: var(--color-gray-1200);
  border: 1px solid var(--color-gray-1100);
  border-radius: 14px;
  padding: 8px;
  min-width: 240px;
  max-height: calc(100vh - 200px);
  overflow-y: auto;
  z-index: var(--z-index-header);
  scrollbar-width: thin;
  scrollbar-color: var(--color-gray-1000) var(--color-gray-1200);
}

.menuItem {
  width: 100%;
  text-align: left;
  padding: 8px 12px;
  color: var(--text-on-primary);
  background: transparent;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: all var(--transition-fast);
}

.menuItem:hover {
  background: var(--color-gray-1100);
}

@media (max-width: 768px) {
  .menu {
    min-width: 200px;
    max-width: 80vw;
  }
}

@media (max-width: 480px) {
  .counter {
    padding: 6px 10px;
    font-size: 12px;
  }
  
  .menuItem {
    padding: 6px 10px;
  }
}