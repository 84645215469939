.container {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* padding: var(--spacing-3xl); */
  background-color: var(--background-primary);
}

.header {
  text-align: center;
  margin-bottom: var(--spacing-2xl);
}

.title {
  font-size: var(--font-size-5xl);
  font-weight: var(--font-weight-bold);
  color: var(--text-on-primary);
  line-height: var(--line-height-tight);
}

.goalsContainer {
  display: flex;
  flex-direction: row;
  gap: var(--spacing-xl);
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  padding: 0 var(--spacing-xl);
}

.goalItem {
  flex: 1;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: var(--spacing-xl);
  border-radius: var(--border-radius-lg);
  overflow: hidden;
  transition: transform 0.3s ease;
}

.goalBackground {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0.1;
  transition: opacity 0.3s ease;
}

.goalItem:hover .goalBackground {
  opacity: 0.15;
}

.goalIcon {
  font-size: var(--font-size-4xl);
  margin-bottom: var(--spacing-lg);
  position: relative;
  z-index: 1;
}

.goalTitle {
  font-size: var(--font-size-2xl);
  font-weight: var(--font-weight-medium);
  color: var(--text-on-primary);
  line-height: var(--line-height-snug);
  margin-bottom: var(--spacing-md);
  position: relative;
  z-index: 1;
}

.footer {
  text-align: center;
  margin-top: var(--spacing-3xl);
}

.footerText {
  font-size: var(--font-size-xl);
  color: var(--color-gray-400);
  max-width: 800px;
  margin: 0 auto;
  line-height: var(--line-height-relaxed);
}

@media (max-width: 768px) {
  .goalsContainer {
    flex-direction: column;
    padding: 0 var(--spacing-md);
  }

  .title {
    font-size: var(--font-size-4xl);
  }

  .goalTitle {
    font-size: var(--font-size-xl);
  }

  .goalIcon {
    font-size: var(--font-size-3xl);
  }

  .footerText {
    font-size: var(--font-size-lg);
    max-width: 600px;
  }
}

@media (max-width: 480px) {
  .container {
    padding: var(--spacing-xl);
  }

  .title {
    font-size: var(--font-size-3xl);
  }

  .goalTitle {
    font-size: var(--font-size-lg);
  }

  .footerText {
    font-size: var(--font-size-base);
  }
}