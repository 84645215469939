/* File: frontend/src/pages/UMS/UserPortfolio/components/PortfolioMaps/PortfolioMaps.module.css */

.container {
  padding: var(--spacing-md);
}

.mapOptions {
  display: flex;
  gap: var(--spacing-md);
  margin-bottom: var(--spacing-md);
}

.mapOption {
  flex: 1;
  display: flex;
  align-items: flex-start;
  padding: var(--spacing-md);
  border-radius: var(--border-radius-md);
  background-color: var(--bg-secondary);
  border: 1px solid var(--color-border);
  cursor: pointer;
  transition: all var(--transition-fast);
}

.mapOption:hover {
  background-color: var(--bg-hover);
}

.mapOption.active {
  background-color: var(--color-primary-100);
  border-color: var(--color-primary-300);
}

.mapOptionIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: var(--border-radius-sm);
  background-color: var(--bg-primary);
  margin-right: var(--spacing-sm);
  color: var(--color-primary);
}

.mapOptionContent {
  flex: 1;
}

.mapOptionTitle {
  font-size: var(--font-size-base);
  font-weight: var(--font-weight-semibold);
  margin: 0 0 var(--spacing-xxs) 0;
}

.mapOptionDescription {
  font-size: var(--font-size-sm);
  color: var(--text-secondary);
  margin: 0;
}

.mapContainer {
  height: 500px;
  border-radius: var(--border-radius-md);
  border: 1px solid var(--color-border);
  overflow: hidden;
  background-color: var(--bg-secondary);
}

@media (max-width: 768px) {
  .mapOptions {
    flex-direction: column;
  }
}