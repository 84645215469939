/* File: frontend/src/pages/Auth/SignInFailure.module.css */

.signInFailureContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: calc(100vh - var(--header-height) - var(--spacing-2xl));
  padding: var(--spacing-xl);
  box-sizing: border-box;
}

.signInFailureContent {
  width: 100%;
  max-width: 480px;
  margin: 0 auto;
  animation: fadeIn 0.3s ease-out;
}

.errorDetails {
  margin-top: var(--spacing-lg);
  width: 100%;
}

.errorInfo {
  background-color: var(--color-error-bg);
  border: 1px solid var(--color-error-border);
  border-radius: var(--border-radius-lg);
  padding: var(--spacing-md);
  display: flex;
  flex-direction: column;
  gap: var(--spacing-sm);
}

.errorItem {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xxs);
}

.errorLabel {
  color: var(--color-error);
  font-size: var(--font-size-xs);
  font-weight: var(--font-weight-medium);
  text-transform: uppercase;
}

.errorValue {
  color: var(--text-primary);
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-medium);
  word-break: break-all;
}

.errorMessage {
  margin-top: var(--spacing-sm);
  padding-top: var(--spacing-sm);
  border-top: 1px solid var(--color-error-border);
  color: var(--text-primary);
  font-size: var(--font-size-sm);
  line-height: var(--line-height-normal);
}

.actionButtons {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-sm);
  margin-top: var(--spacing-lg);
}

.retryButton,
.contactButton {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: var(--spacing-sm);
  padding: var(--spacing-sm) var(--spacing-md);
  border-radius: var(--border-radius-md);
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-medium);
  transition: all var(--transition-normal);
  cursor: pointer;
}

.retryButton {
  background-color: var(--color-primary);
  color: var(--color-white);
  border: none;
}

.retryButton:hover:not(:disabled) {
  background-color: var(--color-primary-dark);
}

.retryButton:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.contactButton {
  background-color: var(--bg-primary);
  color: var(--text-primary);
  border: 1px solid var(--color-border);
}

.contactButton:hover {
  background-color: var(--bg-secondary);
  border-color: var(--color-border-hover);
}

.spinner {
  width: 16px;
  height: 16px;
  border: 2px solid var(--color-white);
  border-top-color: transparent;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

/* Responsive styles */
@media (min-width: 1200px) {
  .signInFailureContainer {
    padding: var(--spacing-2xl);
  }

  .signInFailureContent {
    max-width: 520px;
  }
}

@media (max-width: 768px) {
  .signInFailureContainer {
    padding: var(--spacing-lg);
  }

  .errorInfo {
    padding: var(--spacing-sm);
  }
}

@media (max-width: 480px) {
  .signInFailureContainer {
    padding: var(--spacing-md);
  }

  .errorInfo {
    padding: var(--spacing-xs);
  }

  .errorLabel,
  .errorValue,
  .errorMessage {
    font-size: var(--font-size-xs);
  }

  .actionButtons {
    gap: var(--spacing-xs);
  }
}

/* Animations */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

/* Print styles */
@media print {
  .signInFailureContainer {
    min-height: auto;
    padding: 0;
  }

  .actionButtons {
    display: none;
  }
}

/* Dark mode support */
@media (prefers-color-scheme: dark) {
  .errorInfo {
    background-color: var(--color-error-bg);
    border-color: var(--color-error-border);
  }

  .contactButton {
    background-color: var(--bg-secondary);
    border-color: var(--color-border);
  }
}