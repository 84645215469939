/* File: frontend/src/components/form/auth/SignUpForm.module.css */

.signUpForm {
  display: flex;
  width: 100%;
  max-width: var(--breakpoint-md); /* 768px - reasonable max-width for forms */
  margin: 0 auto;
  padding: var(--spacing-md) 0;
}

.formContent {
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: var(--spacing-lg);
}

.formFields {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-lg);
  width: 100%;
  animation: fadeIn 0.3s ease-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Large screens (1200px and up) */
@media (min-width: 1200px) {
  .signUpForm {
      padding: var(--spacing-lg) var(--spacing-xl);
  }

  .formFields {
      gap: var(--spacing-xl);
  }
}

/* Medium-large screens (1024px and down) */
@media (max-width: 1024px) {
  .signUpForm {
      max-width: 600px;
      padding: var(--spacing-md);
  }

  .formContent {
      gap: var(--spacing-md);
  }

  .formFields {
      gap: var(--spacing-md);
  }
}

/* Tablet screens (768px and down) */
@media (max-width: 768px) {
  .signUpForm {
      padding: var(--spacing-sm);
  }

  .formContent {
      gap: var(--spacing-sm);
  }

  .formFields {
      gap: var(--spacing-sm);
  }
}

/* Mobile screens (480px and down) */
@media (max-width: 480px) {
  .signUpForm {
      padding: var(--spacing-xs);
  }

  .formContent {
      gap: var(--spacing-xs);
  }

  .formFields {
      gap: var(--spacing-xs);
  }
}

/* Small mobile screens (360px and down) */
@media (max-width: 360px) {
  .signUpForm {
      padding: var(--spacing-xxs);
  }
}