/* File: frontend/src/components/API/ApiSecurity.module.css */

.section {
    margin-bottom: var(--spacing-xl);
  }
  
  .sectionTitle {
    font-size: var(--font-size-md);
    font-weight: var(--font-weight-semibold);
    color: var(--text-primary);
    margin-bottom: var(--spacing-lg);
  }
  
  .securitySchemes {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-md);
  }
  
  .securityRequirement {
    background: var(--bg-secondary);
    border-radius: var(--border-radius-lg);
    border: 1px solid var(--color-border);
    overflow: hidden;
  }
  
  .requirementType {
    padding: var(--spacing-sm) var(--spacing-md);
    background: var(--color-warning-bg);
    color: var(--color-warning);
    font-size: var(--font-size-sm);
    font-weight: var(--font-weight-medium);
    border-bottom: 1px solid var(--color-border);
  }
  
  .scheme {
    padding: var(--spacing-md);
  }
  
  .scheme + .scheme {
    border-top: 1px solid var(--color-border);
  }
  
  .schemeDetails {
    display: flex;
    gap: var(--spacing-md);
    align-items: flex-start;
  }
  
  .schemeIcon {
    font-size: var(--font-size-xl);
    line-height: 1;
  }
  
  .schemeInfo {
    flex: 1;
  }
  
  .schemeTitle {
    font-size: var(--font-size-sm);
    font-weight: var(--font-weight-semibold);
    color: var(--text-primary);
    margin-bottom: var(--spacing-xxs);
  }
  
  .schemeDescription {
    font-size: var(--font-size-sm);
    color: var(--text-secondary);
    line-height: var(--line-height-relaxed);
    margin-bottom: var(--spacing-xs);
  }
  
  .headerName {
    display: inline-block;
    font-family: var(--font-secondary);
    font-size: var(--font-size-xs);
    padding: 2px var(--spacing-xs);
    background: var(--bg-tertiary);
    border-radius: var(--border-radius-sm);
    color: var(--text-secondary);
  }
  
  .scopes {
    margin-top: var(--spacing-md);
  }
  
  .scopesTitle {
    font-size: var(--font-size-xs);
    font-weight: var(--font-weight-medium);
    color: var(--text-secondary);
    margin-bottom: var(--spacing-xs);
  }
  
  .scopesList {
    display: flex;
    flex-wrap: wrap;
    gap: var(--spacing-xs);
  }
  
  .scope {
    font-size: var(--font-size-xs);
    padding: 2px var(--spacing-xs);
    background: var(--color-primary-bg);
    color: var(--color-primary);
    border-radius: var(--border-radius-sm);
    font-weight: var(--font-weight-medium);
  }
  
  .securityNote {
    margin-top: var(--spacing-lg);
    display: flex;
    gap: var(--spacing-md);
    padding: var(--spacing-md);
    background: var(--color-info-bg);
    border-radius: var(--border-radius-md);
    border: 1px solid var(--color-info);
  }
  
  .noteIcon {
    font-size: var(--font-size-xl);
    line-height: 1;
  }
  
  .noteText {
    font-size: var(--font-size-sm);
    color: var(--color-info);
    line-height: var(--line-height-relaxed);
  }
  
  .noteText a {
    color: var(--color-info);
    text-decoration: underline;
    font-weight: var(--font-weight-medium);
  }
  
  .noteText a:hover {
    text-decoration: none;
  }
  
  @media (max-width: 768px) {
    .schemeDetails {
      flex-direction: column;
      gap: var(--spacing-sm);
    }
  
    .schemeIcon {
      font-size: var(--font-size-lg);
    }
  
    .securityNote {
      flex-direction: column;
      gap: var(--spacing-sm);
    }
  }