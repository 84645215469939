/* File: frontend/src/pages/SuperAdmin/components/PitchAccessManager/PitchAccessManager.module.css */

.container {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-lg);
}

.title {
  font-size: var(--font-size-2xl);
  font-weight: var(--font-weight-bold);
  color: var(--text-primary);
  margin: 0 0 var(--spacing-lg) 0;
}

.controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: var(--spacing-lg);
}

.deckSelector {
  display: flex;
  align-items: center;
  gap: var(--spacing-sm);
}

.label {
  font-size: var(--font-size-sm);
  color: var(--text-secondary);
}

.select {
  padding: var(--spacing-sm) var(--spacing-md);
  border: 1px solid var(--color-border);
  border-radius: var(--border-radius-md);
  background-color: var(--bg-primary);
  color: var(--text-primary);
  font-size: var(--font-size-sm);
}

.searchContainer {
  position: relative;
  width: 300px;
}

.searchInput {
  width: 100%;
  padding: var(--spacing-sm) var(--spacing-sm) var(--spacing-sm) var(--spacing-xl);
  border: 1px solid var(--color-border);
  border-radius: var(--border-radius-md);
  font-size: var(--font-size-sm);
  color: var(--text-primary);
  background-color: var(--bg-primary);
}

.searchContainer i {
  position: absolute;
  left: var(--spacing-sm);
  top: 50%;
  transform: translateY(-50%);
  color: var(--text-tertiary);
}

.error, .success {
  padding: var(--spacing-md);
  border-radius: var(--border-radius-md);
  margin-bottom: var(--spacing-md);
  display: flex;
  align-items: center;
  gap: var(--spacing-sm);
}

.error {
  background-color: var(--color-error-bg);
  color: var(--color-error);
}

.success {
  background-color: var(--color-success-bg);
  color: var(--color-success);
}

.sectionTitle {
  font-size: var(--font-size-lg);
  font-weight: var(--font-weight-semibold);
  color: var(--text-primary);
  margin: 0 0 var(--spacing-md) 0;
  padding-bottom: var(--spacing-xs);
  border-bottom: 1px solid var(--color-border-light);
}

.addAccessSection {
  background-color: var(--bg-secondary);
  border-radius: var(--border-radius-md);
  padding: var(--spacing-lg);
  margin-bottom: var(--spacing-lg);
}

.addAccessForm {
  display: flex;
  gap: var(--spacing-md);
  align-items: flex-end;
}

.formGroup {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xs);
}

.input {
  padding: var(--spacing-sm) var(--spacing-md);
  border: 1px solid var(--color-border);
  border-radius: var(--border-radius-md);
  font-size: var(--font-size-sm);
  color: var(--text-primary);
  background-color: var(--bg-primary);
}

.addButton {
  display: flex;
  align-items: center;
  gap: var(--spacing-xs);
  padding: var(--spacing-sm) var(--spacing-md);
  background-color: var(--color-primary);
  color: white;
  border: none;
  border-radius: var(--border-radius-md);
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-medium);
  cursor: pointer;
  transition: background-color var(--transition-fast);
  height: 38px;
}

.addButton:hover:not(:disabled) {
  background-color: var(--color-primary-dark);
}

.addButton:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.spinner {
  width: 16px;
  height: 16px;
  border: 2px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top-color: white;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.tableContainer {
  overflow-x: auto;
  border: 1px solid var(--color-border);
  border-radius: var(--border-radius-md);
}

.accessTable {
  width: 100%;
  border-collapse: collapse;
}

.accessTable th,
.accessTable td {
  padding: var(--spacing-sm) var(--spacing-md);
  text-align: left;
  border-bottom: 1px solid var(--color-border-light);
}

.accessTable th {
  background-color: var(--bg-secondary);
  font-weight: var(--font-weight-semibold);
  color: var(--text-primary);
}

.accessTable tr:last-child td {
  border-bottom: none;
}

.accessTable tr:hover td {
  background-color: var(--bg-hover);
}

.statusBadge {
  display: inline-block;
  padding: 2px 8px;
  border-radius: 12px;
  font-size: var(--font-size-xs);
  font-weight: var(--font-weight-medium);
}

.statusActive {
  background-color: var(--color-success-bg);
  color: var(--color-success);
}

.statusExpired {
  background-color: var(--color-warning-bg);
  color: var(--color-warning);
}

.statusRevoked {
  background-color: var(--color-error-bg);
  color: var(--color-error);
}

.actions {
  display: flex;
  gap: var(--spacing-xs);
}

.revokeButton,
.extendButton {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 28px;
  border-radius: var(--border-radius-sm);
  font-size: var(--font-size-sm);
  cursor: pointer;
  transition: all var(--transition-fast);
  border: none;
}

.revokeButton {
  background-color: var(--color-error-bg);
  color: var(--color-error);
}

.revokeButton:hover:not(:disabled) {
  background-color: var(--color-error);
  color: white;
}

.extendButton {
  background-color: var(--color-warning-bg);
  color: var(--color-warning);
}

.extendButton:hover:not(:disabled) {
  background-color: var(--color-warning);
  color: white;
}

.revokeButton:disabled,
.extendButton:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.loading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: var(--spacing-xl);
  gap: var(--spacing-md);
}

.emptyState {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: var(--spacing-xl);
  background-color: var(--bg-secondary);
  border-radius: var(--border-radius-md);
  color: var(--text-secondary);
  text-align: center;
  gap: var(--spacing-md);
}

.emptyState i {
  font-size: var(--font-size-3xl);
  opacity: 0.5;
}

@media (max-width: 1024px) {
  .controls {
    flex-direction: column;
    align-items: flex-start;
    gap: var(--spacing-md);
  }
  
  .searchContainer {
    width: 100%;
  }
  
  .addAccessForm {
    flex-direction: column;
    gap: var(--spacing-md);
  }
  
  .addButton {
    width: 100%;
  }
}