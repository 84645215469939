/* File: frontend/src/pages/SuperAdmin/SuperAdmin.module.css */

.container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: var(--bg-secondary);
}

.content {
  display: flex;
  flex: 1;
}

.mainContent {
  flex: 1;
  padding: var(--spacing-lg);
  overflow-y: auto;
}

.sectionContent {
  background-color: var(--bg-primary);
  border-radius: var(--border-radius-lg);
  box-shadow: var(--shadow-card);
  padding: var(--spacing-lg);
  margin-top: var(--spacing-md);
}

@media (max-width: 768px) {
  .content {
    flex-direction: column;
  }
  
  .mainContent {
    padding: var(--spacing-md);
  }
  
  .sectionContent {
    padding: var(--spacing-md);
  }
}