/* File: frontend/src/pages/UMS/UserPortfolio/components/PortfolioOverview/UwDecisions.module.css */

.container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: var(--spacing-md);
}

.matrixContainer {
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: var(--spacing-lg);
}

.yAxisLabel {
  writing-mode: vertical-lr;
  transform: rotate(180deg);
  text-align: center;
  font-size: var(--font-size-sm);
  color: var(--text-secondary);
  margin-right: var(--spacing-sm);
}

.xAxisLabel {
  text-align: center;
  font-size: var(--font-size-sm);
  color: var(--text-secondary);
  margin-top: var(--spacing-sm);
}

.matrix {
  border-collapse: collapse;
}

.matrix th {
  padding: var(--spacing-xs);
  font-size: var(--font-size-xs);
  color: var(--text-secondary);
  font-weight: var(--font-weight-medium);
}

.matrix td {
  width: 30px;
  height: 30px;
  border: 1px solid var(--color-border);
  cursor: pointer;
  transition: transform var(--transition-fast);
}

.matrix td:hover {
  transform: scale(1.1);
  z-index: 1;
  box-shadow: var(--shadow-md);
}

.tooltip {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--bg-primary);
  border-radius: var(--border-radius-md);
  box-shadow: var(--shadow-lg);
  width: 250px;
  z-index: 10;
  overflow: hidden;
}

.tooltipHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: var(--spacing-sm) var(--spacing-md);
  background-color: var(--bg-secondary);
  border-bottom: 1px solid var(--color-border);
}

.tooltipTitle {
  font-weight: var(--font-weight-semibold);
  font-size: var(--font-size-sm);
}

.tooltipClose {
  background: none;
  border: none;
  font-size: var(--font-size-lg);
  color: var(--text-secondary);
  cursor: pointer;
  padding: 0;
  line-height: 1;
}

.tooltipContent {
  padding: var(--spacing-md);
}

.tooltipRow {
  display: flex;
  justify-content: space-between;
  margin-bottom: var(--spacing-sm);
}

.tooltipLabel {
  font-weight: var(--font-weight-medium);
  color: var(--text-secondary);
}

.tooltipValue {
  font-weight: var(--font-weight-semibold);
}

.campaignButton {
  width: 100%;
  padding: var(--spacing-sm);
  background-color: var(--button-primary-bg);
  color: var(--button-primary-text);
  border: none;
  border-radius: var(--border-radius-sm);
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-medium);
  cursor: pointer;
  margin-top: var(--spacing-md);
  transition: background-color var(--transition-fast);
}

.campaignButton:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.campaignButton:not(:disabled):hover {
  background-color: var(--button-primary-hover-bg);
}

.legend {
  margin-top: var(--spacing-md);
  text-align: center;
}

.legendTitle {
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-medium);
  margin-bottom: var(--spacing-sm);
  color: var(--text-secondary);
}

.legendItems {
  display: flex;
  justify-content: center;
  gap: var(--spacing-md);
}

.legendItem {
  display: flex;
  align-items: center;
  gap: var(--spacing-xxs);
}

.legendColor {
  width: 16px;
  height: 16px;
  border-radius: 4px;
}

.legendLabel {
  font-size: var(--font-size-xs);
  color: var(--text-secondary);
}

.loadingContainer, .errorContainer, .noDataContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: var(--spacing-xl);
  text-align: center;
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  border-top: 4px solid var(--color-primary);
  width: 30px;
  height: 30px;
  animation: spin 1s linear infinite;
  margin-bottom: var(--spacing-sm);
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.errorContainer {
  color: var(--color-error);
}

.noDataContainer {
  color: var(--text-secondary);
}

@media (max-width: 768px) {
  .matrixContainer {
    flex-direction: column;
  }
  
  .yAxisLabel {
    writing-mode: horizontal-tb;
    transform: none;
    margin-right: 0;
    margin-bottom: var(--spacing-sm);
  }
  
  .matrix td {
    width: 20px;
    height: 20px;
  }
  
  .legendItems {
    flex-wrap: wrap;
  }
}