/* File: frontend/src/pages/QMS/QuoteDetail/components/QuoteConversation/QuoteConversation.module.css */

.container {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-lg);
}

.card {
  background-color: var(--bg-primary);
  border-radius: var(--border-radius-md);
  border: 1px solid var(--color-border);
  overflow: hidden;
}

.cardHeader {
  padding: var(--spacing-sm);
  background-color: var(--bg-secondary);
  border-bottom: 1px solid var(--color-border);
}

.cardTitle {
  font-size: var(--font-size-md);
  font-weight: var(--font-weight-semibold);
  margin: 0;
}

.cardContent {
  padding: var(--spacing-md);
}

.infoGrid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: var(--spacing-md);
}

.infoGroup {
  display: flex;
  flex-direction: column;
}

.infoLabel {
  font-weight: var(--font-weight-medium);
  color: var(--text-secondary);
  margin-bottom: var(--spacing-xxs);
}

.infoValue {
  color: var(--text-primary);
  word-break: break-word;
}

.completed {
  color: var(--color-success);
  font-weight: var(--font-weight-semibold);
}

.in_progress {
  color: var(--color-info);
  font-weight: var(--font-weight-semibold);
}

.failed {
  color: var(--color-error);
  font-weight: var(--font-weight-semibold);
}

.sectionTitle {
  font-size: var(--font-size-md);
  font-weight: var(--font-weight-semibold);
  margin: 0 0 var(--spacing-md) 0;
}

.messagesList {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-md);
  max-height: 600px;
  overflow-y: auto;
  padding-right: var(--spacing-xs);
}

.messageCard {
  border-radius: var(--border-radius-md);
  padding: var(--spacing-md);
  max-width: 80%;
}

.user {
  align-self: flex-end;
  background-color: var(--color-primary-100);
  border: 1px solid var(--color-primary-200);
}

.assistant {
  align-self: flex-start;
  background-color: var(--bg-secondary);
  border: 1px solid var(--color-border);
}

.messageHeader {
  display: flex;
  justify-content: space-between;
  margin-bottom: var(--spacing-xs);
}

.messageRole {
  font-weight: var(--font-weight-semibold);
  color: var(--text-secondary);
}

.messageTime {
  font-size: var(--font-size-xs);
  color: var(--text-tertiary);
}

.messageContent {
  white-space: pre-wrap;
  word-break: break-word;
  margin-bottom: var(--spacing-xs);
}

.messageUsage {
  font-size: var(--font-size-xs);
  color: var(--text-tertiary);
  margin-top: var(--spacing-xs);
  padding-top: var(--spacing-xs);
  border-top: 1px dashed var(--color-border-light);
}

.usageLabel {
  font-weight: var(--font-weight-medium);
}

.usageValue {
  font-family: monospace;
}

.noData, .noMessages {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: var(--spacing-xl);
  color: var(--text-secondary);
  font-style: italic;
  text-align: center;
}

@media (max-width: 768px) {
  .infoGrid {
    grid-template-columns: 1fr;
  }
  
  .messageCard {
    max-width: 90%;
  }
}