/* src/components/common/CustomTable.module.css */
.tableContainer {
    width: 100%;
    background-color: #fff;
    border-radius: 16px;
    box-shadow: 0px 13px 4px rgba(0, 0, 0, 0), 0px 8px 3px rgba(0, 0, 0, 0), 0px 5px 3px rgba(0, 0, 0, 0.02), 0px 2px 2px rgba(0, 0, 0, 0.03), 0px 1px 1px rgba(0, 0, 0, 0.03);
    border: 2px solid #f7f7f8;
    overflow: hidden;
  }
  
  .cardStandartTitleRow {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    border-bottom: 1px solid #f7f7f8;
  }
  
  .text {
    font-size: 18px;
    font-weight: 500;
    color: #14151a;
  }
  
  .searchInputParent {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
  .searchInput {
    display: flex;
    align-items: center;
    background-color: #fff;
    border: 1px solid #e9eaec;
    border-radius: 10px;
    overflow: hidden;
  }
  
  .searchCustomersInsightsOrWrapper {
    padding: 0 8px;
  }
  
  .searchCustomersInsights {
    border: none;
    outline: none;
    font-size: 14px;
    color: #babdc5;
  }
  
  .button, .button1 {
    background: none;
    border: none;
    cursor: pointer;
    padding: 6px;
  }
  
  .cardStandartContent {
    padding: 0 20px 20px;
  }
  
  .tableBasic {
    border: 1px solid #f7f7f8;
    border-radius: 10px;
    overflow: hidden;
  }
  
  .tableColumnTitleCellParent {
    display: flex;
    background-color: #f7f7f8;
  }
  
  .tableColumnTitleCell {
    flex: 1;
    display: flex;
    align-items: center;
    padding: 14px 12px;
    gap: 6px;
  }
  
  .columnTitle {
    font-size: 14px;
    font-weight: 500;
    color: #464a53;
  }
  
  .tableRowCellParent {
    display: flex;
    border-bottom: 1px solid #f7f7f8;
  }
  
  .tableRowCell {
    flex: 1;
    padding: 16px 12px;
  }
  
  .paginationGroup {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 14px;
    gap: 10px;
  }
  
  .pagination {
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    background: none;
    cursor: pointer;
  }
  
  .paginationParent {
    display: flex;
    gap: 6px;
  }
  
  .pagination1 {
    background-color: #eff4ff;
    border: 1px solid #dbe6fe;
    color: #161d55;
  }
  
  .pagination2 {
    background-color: #fff;
    border: 1px solid #e9eaec;
    color: #1f2228;
  }
  
  .div5 {
    font-size: 12px;
    font-weight: 500;
  }
  
  /* Add responsive styles */
  @media (max-width: 768px) {
    .cardStandartTitleRow {
      flex-direction: column;
      align-items: flex-start;
      gap: 10px;
    }
  
    .searchInputParent {
      width: 100%;
    }
  
    .searchInput {
      flex: 1;
    }
  
    .tableBasic {
      overflow-x: auto;
    }
  
    .tableColumnTitleCellParent,
    .tableRowCellParent {
      min-width: 800px; /* Adjust based on your content */
    }
  }