/* File: frontend/src/pages/SuperAdmin/components/ProductManagement/ProductFeatures/ProductFeatures.module.css */

.container {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-md);
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: var(--spacing-md);
}

.backButton {
  display: flex;
  align-items: center;
  gap: var(--spacing-xs);
  padding: var(--spacing-sm) var(--spacing-md);
  background-color: var(--bg-secondary);
  color: var(--text-primary);
  border: 1px solid var(--color-border);
  border-radius: var(--border-radius-md);
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-medium);
  cursor: pointer;
  transition: all var(--transition-fast);
}

.backButton:hover:not(:disabled) {
  background-color: var(--bg-hover);
}

.backButton:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.productInfo {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.productName {
  font-size: var(--font-size-xl);
  font-weight: var(--font-weight-bold);
  color: var(--text-primary);
  margin: 0;
}

.productMeta {
  display: flex;
  align-items: center;
  gap: var(--spacing-xs);
  font-size: var(--font-size-sm);
  color: var(--text-secondary);
}

.productId {
  font-family: var(--font-family-mono);
}

.separator {
  color: var(--text-tertiary);
}

.productCategory {
  text-transform: capitalize;
}

.addButton {
  display: flex;
  align-items: center;
  gap: var(--spacing-xs);
  padding: var(--spacing-sm) var(--spacing-md);
  background-color: var(--color-primary);
  color: white;
  border: none;
  border-radius: var(--border-radius-md);
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-medium);
  cursor: pointer;
  transition: background-color var(--transition-fast);
}

.addButton:hover:not(:disabled) {
  background-color: var(--color-primary-dark);
}

.addButton:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.errorMessage {
  display: flex;
  align-items: center;
  gap: var(--spacing-sm);
  padding: var(--spacing-md);
  background-color: var(--color-error-bg);
  color: var(--color-error);
  border-radius: var(--border-radius-md);
  margin-bottom: var(--spacing-md);
}

.errorMessage i {
  font-size: var(--font-size-lg);
}

.formContainer {
  background-color: var(--bg-primary);
  border-radius: var(--border-radius-md);
  border: 1px solid var(--color-border);
  padding: var(--spacing-md);
  margin-bottom: var(--spacing-md);
}

.formTitle {
  font-size: var(--font-size-lg);
  font-weight: var(--font-weight-semibold);
  color: var(--text-primary);
  margin: 0 0 var(--spacing-md) 0;
  padding-bottom: var(--spacing-xs);
  border-bottom: 1px solid var(--color-border-light);
}

.form {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-md);
}

.formGrid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: var(--spacing-md);
}

.formGroup {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xs);
}

.formGroupFull {
  grid-column: 1 / -1;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xs);
}

.label {
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-medium);
  color: var(--text-secondary);
}

.required {
  color: var(--color-error);
}

.input, .select, .textarea {
  padding: var(--spacing-sm);
  border: 1px solid var(--color-border);
  border-radius: var(--border-radius-md);
  font-size: var(--font-size-base);
  color: var(--text-primary);
  background-color: var(--bg-primary);
  transition: border-color var(--transition-fast), box-shadow var(--transition-fast);
}

.input:focus, .select:focus, .textarea:focus {
  outline: none;
  border-color: var(--color-primary);
  box-shadow: 0 0 0 2px var(--color-primary-100);
}

.input:disabled, .select:disabled, .textarea:disabled {
  background-color: var(--bg-secondary);
  cursor: not-allowed;
}

.textarea {
  resize: vertical;
  min-height: 80px;
}

.formActions {
  display: flex;
  justify-content: flex-end;
  gap: var(--spacing-md);
  margin-top: var(--spacing-md);
}

.cancelButton, .submitButton {
  padding: var(--spacing-sm) var(--spacing-lg);
  border-radius: var(--border-radius-md);
  font-size: var(--font-size-base);
  font-weight: var(--font-weight-medium);
  cursor: pointer;
  transition: all var(--transition-fast);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: var(--spacing-xs);
  min-width: 120px;
}

.cancelButton {
  background-color: var(--bg-secondary);
  color: var(--text-primary);
  border: 1px solid var(--color-border);
}

.cancelButton:hover:not(:disabled) {
  background-color: var(--bg-hover);
}

.submitButton {
  background-color: var(--color-primary);
  color: white;
  border: none;
}

.submitButton:hover:not(:disabled) {
  background-color: var(--color-primary-dark);
}

.cancelButton:disabled, .submitButton:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.featuresContainer {
  background-color: var(--bg-primary);
  border-radius: var(--border-radius-md);
  border: 1px solid var(--color-border);
  padding: var(--spacing-md);
}

.featuresTitle {
  font-size: var(--font-size-lg);
  font-weight: var(--font-weight-semibold);
  color: var(--text-primary);
  margin: 0 0 var(--spacing-md) 0;
  padding-bottom: var(--spacing-xs);
  border-bottom: 1px solid var(--color-border-light);
}

.featureCount {
  font-size: var(--font-size-base);
  color: var(--text-secondary);
  font-weight: var(--font-weight-normal);
  margin-left: var(--spacing-xs);
}

.featuresList {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-md);
}

.featureItem {
  padding: var(--spacing-md);
  background-color: var(--bg-secondary);
  border-radius: var(--border-radius-md);
  border: 1px solid var(--color-border-light);
}

.featureHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: var(--spacing-sm);
}

.featureName {
  font-size: var(--font-size-lg);
  font-weight: var(--font-weight-semibold);
  color: var(--text-primary);
}

.featureBadges {
  display: flex;
  gap: var(--spacing-xs);
}

.featureTypeBadge, .statusBadge {
  display: inline-block;
  padding: 4px 8px;
  border-radius: 12px;
  font-size: var(--font-size-xs);
  font-weight: var(--font-weight-medium);
  text-transform: capitalize;
}

.typeCore {
  background-color: var(--color-success-bg);
  color: var(--color-success);
}

.typeAdvanced {
  background-color: var(--color-info-bg);
  color: var(--color-info);
}

.typePremium {
  background-color: var(--color-warning-bg);
  color: var(--color-warning);
}

.statusActive {
  background-color: var(--color-success-bg);
  color: var(--color-success);
}

.statusInactive {
  background-color: var(--color-error-bg);
  color: var(--color-error);
}

.featureDescription {
  font-size: var(--font-size-base);
  color: var(--text-primary);
  margin-bottom: var(--spacing-md);
}

.featureActions {
  display: flex;
  gap: var(--spacing-sm);
}

.editButton, .deleteButton {
  display: flex;
  align-items: center;
  gap: var(--spacing-xs);
  padding: var(--spacing-xs) var(--spacing-sm);
  border-radius: var(--border-radius-sm);
  font-size: var(--font-size-xs);
  cursor: pointer;
  transition: all var(--transition-fast);
}

.editButton {
  background-color: var(--bg-primary);
  color: var(--text-primary);
  border: 1px solid var(--color-border);
}

.editButton:hover:not(:disabled) {
  background-color: var(--bg-hover);
  color: var(--color-primary);
  border-color: var(--color-primary);
}

.deleteButton {
  background-color: var(--color-error-bg);
  color: var(--color-error);
  border: 1px solid var(--color-error);
}

.deleteButton:hover:not(:disabled) {
  background-color: var(--color-error);
  color: white;
}

.editButton:disabled, .deleteButton:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.emptyState {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: var(--spacing-xl);
  background-color: var(--bg-secondary);
  border-radius: var(--border-radius-md);
  color: var(--text-secondary);
  font-style: italic;
  gap: var(--spacing-md);
}

.addFirstButton {
  padding: var(--spacing-sm) var(--spacing-lg);
  background-color: var(--color-primary);
  color: white;
  border: none;
  border-radius: var(--border-radius-md);
  font-size: var(--font-size-base);
  font-weight: var(--font-weight-medium);
  cursor: pointer;
  transition: background-color var(--transition-fast);
}

.addFirstButton:hover:not(:disabled) {
  background-color: var(--color-primary-dark);
}

.addFirstButton:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.loading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: var(--spacing-xl);
  min-height: 300px;
}

.spinner {
  border: 3px solid var(--color-border);
  border-top: 3px solid var(--color-primary);
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 1s linear infinite;
  margin-bottom: var(--spacing-md);
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.error {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: var(--spacing-xl);
  background-color: var(--bg-secondary);
  border-radius: var(--border-radius-md);
  color: var(--color-error);
  min-height: 200px;
  gap: var(--spacing-md);
}

@media (max-width: 992px) {
  .formGrid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .header {
    flex-direction: column;
    gap: var(--spacing-md);
  }
  
  .backButton, .addButton {
    width: 100%;
    justify-content: center;
  }
  
  .formGrid {
    grid-template-columns: 1fr;
  }
  
  .formActions {
    flex-direction: column;
  }
  
  .cancelButton, .submitButton {
    width: 100%;
  }
  
  .featureHeader {
    flex-direction: column;
    align-items: flex-start;
    gap: var(--spacing-sm);
  }
  
  .featureActions {
    flex-direction: column;
  }
  
  .editButton, .deleteButton {
    width: 100%;
    justify-content: center;
  }
}