/* File: frontend/src/pages/InvestorPitch/components/slides/AnimatedHiringSlide/AnimatedHiringSlide.module.css */

/* 
   MAIN CONTAINER 
   (dark background, full height, etc.)
*/
.container {
  min-height: 100vh;
  background: var(--color-gray-1300);
  padding: var(--spacing-xl) var(--spacing-3xl);
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  overflow: hidden;
}

/* 
   OPTIONAL BACKGROUND PULSE 
   (the radial gradient that pulses in/out)
*/
.container::before {
  content: '';
  position: absolute;
  top: 0; left: 0; right: 0; bottom: 0;
  background: radial-gradient(
    circle at 50% 50%, 
    var(--color-gray-1200) 0%, 
    transparent 70%
  );
  opacity: 0.4;
  animation: backgroundPulse 8s ease-in-out infinite;
  z-index: 0;
}

@keyframes backgroundPulse {
  0%, 100% {
    transform: scale(1);
    opacity: 0.4;
  }
  50% {
    transform: scale(1.2);
    opacity: 0.6;
  }
}

/* 
   NEW HEADER ALWAYS VISIBLE 
   (outside AnimatePresence)
*/
.header {
  width: 100%;
  text-align: center;
  margin-bottom: var(--spacing-4xl);
  z-index: 2; /* above background pulses */
}

.title {
  font-size: var(--font-size-4xl);
  font-weight: var(--font-weight-bold);
  color: var(--text-on-primary);
  margin-bottom: var(--spacing-sm);
}

.subtitle {
  font-size: var(--font-size-xl);
  color: var(--color-gray-400);
  max-width: 700px;
  margin: 0 auto;
  line-height: 1.5;
}

/* 
   SECTION 0: Title Section 
*/
.titleSection {
  text-align: center;
  position: relative;
  z-index: 1;
  margin-bottom: var(--spacing-4xl);
}

/* Title gradient words */
.titleGreen {
  background: linear-gradient(
    135deg, 
    var(--color-green-310) 0%, 
    var(--color-green-210) 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  display: inline-block;
}

.titleBlue {
  background: linear-gradient(
    135deg, 
    var(--color-blue-310) 0%, 
    var(--color-blue-210) 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  display: inline-block;
}

.titleSection h1 {
  font-size: var(--font-size-4xl);
  font-weight: var(--font-weight-bold);
  line-height: 1.2;
  margin-bottom: var(--spacing-xl);
  filter: drop-shadow(0 0 30px rgba(56, 199, 147, 0.1));
}

.titleSection h2 {
  color: var(--color-gray-400);
  font-size: var(--font-size-xl);
  font-weight: var(--font-weight-regular);
  opacity: 0.8;
}

/* 3D Button */
.buttonWrapper {
  margin-top: var(--spacing-3xl);
  perspective: 1000px;
  position: relative;
}

.ctaButton3D {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: var(--spacing-lg) var(--spacing-2xl);
  font-size: var(--font-size-lg);
  font-weight: var(--font-weight-semibold);
  text-decoration: none;
  border-radius: var(--border-radius-full);
  background: linear-gradient(
    135deg,
    var(--color-green-310) 0%,
    var(--color-blue-310) 100%
  );
  color: var(--color-gray-1300);
  transform-style: preserve-3d;
  transform: translateZ(0);
  transition: transform 0.2s ease-out;
  cursor: pointer;
  overflow: hidden;
}

.buttonContent {
  position: relative;
  z-index: 1;
  transform: translateZ(20px);
  background: linear-gradient(
    to right,
    var(--color-gray-1300),
    var(--color-gray-1200)
  );
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: var(--font-weight-bold);
}

.buttonShadow {
  position: absolute;
  top: 0; left: 0;
  width: 100%; height: 100%;
  background: var(--color-gray-1300);
  transform: translateZ(-20px);
  border-radius: var(--border-radius-full);
  opacity: 0.2;
  transition: all 0.2s ease-out;
}

.buttonGlow {
  position: absolute;
  top: -50%; left: -50%;
  width: 200%; height: 200%;
  background: radial-gradient(
    circle at center,
    rgba(255, 255, 255, 0.8) 0%,
    transparent 50%
  );
  transform: translateZ(5px);
  opacity: 0;
  transition: opacity 0.3s ease-out;
  pointer-events: none;
}

.ctaButton3D:hover .buttonGlow {
  opacity: 0.1;
  animation: rotateGlow 3s linear infinite;
}

.ctaButton3D:hover .buttonShadow {
  transform: translateZ(-30px);
  opacity: 0.3;
}

.ctaButton3D::before {
  content: '';
  position: absolute;
  top: 0; left: 0; right: 0; bottom: 0;
  background: linear-gradient(
    135deg,
    rgba(255, 255, 255, 0.2) 0%,
    transparent 100%
  );
  border-radius: var(--border-radius-full);
  opacity: 0;
  transition: opacity 0.2s ease-out;
}

.ctaButton3D:hover::before {
  opacity: 1;
}

@keyframes rotateGlow {
  from { transform: translateZ(5px) rotate(0deg); }
  to { transform: translateZ(5px) rotate(360deg); }
}

@media (max-width: 768px) {
  .ctaButton3D {
    padding: var(--spacing-md) var(--spacing-xl);
    font-size: var(--font-size-base);
  }
}

/* 
   SECTION 1: Locations 
*/
.locationsSection {
  width: 100%;
  max-width: 1200px;
  perspective: 1000px;
  margin: 0 auto;
}

.locationsGrid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: var(--spacing-xl);
  transform-style: preserve-3d;
}

.locationCard {
  background: rgba(20, 21, 26, 0.7);
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  padding: var(--spacing-xl);
  border-radius: var(--border-radius-lg);
  position: relative;
  overflow: hidden;
  transform-style: preserve-3d;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}
.locationCard:hover {
  border-color: rgba(255, 255, 255, 0.2);
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.2);
  transform: translateY(-5px);
}

.locationCard::before {
  content: '';
  position: absolute;
  top: 0; left: 0; right: 0; bottom: 0;
  background: linear-gradient(
    45deg,
    transparent 0%,
    rgba(255, 255, 255, 0.03) 100%
  );
  transform: translateZ(-1px);
}

.locationIcon {
  width: 48px; height: 48px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: var(--spacing-md);
  position: relative;
}

.locationPulse {
  position: absolute;
  top: 50%; left: 50%;
  transform: translate(-50%, -50%);
  width: 100%; height: 100%;
  border-radius: 50%;
  background: var(--color-blue-310);
  opacity: 0;
}

/* 
   SECTION 2: Positions 
*/
.positionsSection {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}

.positionsSection h2 {
  text-align: center;
  color: var(--color-blue-310);
  font-size: var(--font-size-2xl);
  margin-bottom: var(--spacing-3xl);
  position: relative;
}

.counter {
  display: inline-block;
  position: relative;
}
.counter::after {
  content: '';
  position: absolute;
  bottom: -8px; left: 0;
  width: 100%;
  height: 2px;
  background: var(--color-blue-310);
  transform-origin: left;
  animation: lineGrow 0.6s ease-out forwards;
}
@keyframes lineGrow {
  from { transform: scaleX(0); }
  to { transform: scaleX(1); }
}

.positionsGrid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: var(--spacing-md);
  margin: 0 auto;
}

.positionCard {
  background: rgba(20, 21, 26, 0.7);
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  padding: var(--spacing-lg);
  border-radius: var(--border-radius-md);
  display: flex;
  align-items: center;
  gap: var(--spacing-md);
  transform-origin: center;
  transition: all 0.3s ease;
}
.positionCard:hover {
  transform: translateY(-2px);
  border-color: rgba(255, 255, 255, 0.2);
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.2);
}

.positionIcon {
  position: relative;
  z-index: 1;
}

.positionTitle {
  font-weight: var(--font-weight-semibold);
  color: var(--color-gray-200);
}

/* 
   SECTION 3: Compensation 
*/
.compensationSection {
  width: 100%;
  max-width: 1200px;
  perspective: 1000px;
  margin: 0 auto;
}

.compensationSection h2 {
  text-align: center;
  color: var(--color-blue-310);
  font-size: var(--font-size-2xl);
  margin-bottom: var(--spacing-3xl);
}

.compensationGrid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: var(--spacing-xl);
  transform-style: preserve-3d;
}

.compensationCard {
  background: rgba(20, 21, 26, 0.7);
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  padding: var(--spacing-xl);
  border-radius: var(--border-radius-lg);
  text-align: center;
  position: relative;
  overflow: hidden;
  transition: all 0.3s ease;
}
.compensationCard:hover {
  border-color: rgba(255, 255, 255, 0.2);
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.2);
}

.compensationRange {
  font-size: var(--font-size-lg);
  font-weight: var(--font-weight-bold);
  color: var(--color-blue-310);
  margin: var(--spacing-sm) 0;
}

.compensationHighlights {
  list-style: none;
  padding: 0;
  margin: var(--spacing-md) 0 0;
}
.compensationHighlights li {
  margin: var(--spacing-xs) 0;
  color: var(--color-gray-400);
  font-size: var(--font-size-sm);
}

/* 
   GRADIENT UTILS
   (if you have .blue, .green, .orange classes used)
*/
.blue .positionIcon,
.blue .compensationIcon {
  color: var(--color-blue-310);
}
.green .positionIcon,
.green .compensationIcon {
  color: var(--color-green-310);
}
.orange .positionIcon,
.orange .compensationIcon {
  color: var(--color-orange-310);
}

/* 
   RESPONSIVE 
*/
@media (max-width: 1024px) {
  .locationsGrid,
  .positionsGrid,
  .compensationGrid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .container {
    padding: var(--spacing-xl);
  }

  .titleSection h1 {
    font-size: var(--font-size-3xl);
  }

  .locationsGrid,
  .positionsGrid,
  .compensationGrid {
    grid-template-columns: 1fr;
  }
}

/* 
   EXAMPLE UTILITY ANIMATIONS 
*/
.fadeIn {
  animation: fadeIn 0.5s ease-out forwards;
}
@keyframes fadeIn {
  from { opacity: 0; transform: translateY(20px); }
  to   { opacity: 1; transform: translateY(0); }
}

.slideIn {
  animation: slideIn 0.5s ease-out forwards;
}
@keyframes slideIn {
  from { opacity: 0; transform: translateX(-20px); }
  to   { opacity: 1; transform: translateX(0); }
}

@keyframes iconGlow {
  0%, 100% { transform: scale(1); opacity: 0.2; }
  50% { transform: scale(1.2); opacity: 0.3; }
}

@keyframes rotateGlow {
  from { transform: translateZ(5px) rotate(0deg); }
  to   { transform: translateZ(5px) rotate(360deg); }
}
