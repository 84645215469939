/* File: frontend/src/pages/EmbeddedInsurance/components/ProductBuilder/styles/ProductBuilder.module.css */

.productBuilder {
  padding: var(--spacing-lg);
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xl);
}

.stepIndicator {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: var(--spacing-xl);
}

.step {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--spacing-xs);
  cursor: pointer;
  position: relative;
  z-index: 1;
}

.stepIcon {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: var(--bg-secondary);
  border: 2px solid var(--color-border);
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--text-secondary);
  font-size: var(--font-size-lg);
  transition: all var(--transition-fast);
}

.stepLabel {
  font-size: var(--font-size-sm);
  color: var(--text-secondary);
  text-align: center;
  max-width: 120px;
}

.stepConnector {
  flex: 1;
  height: 2px;
  background-color: var(--color-border);
  margin: 0 var(--spacing-sm);
  position: relative;
  z-index: 0;
  transition: background-color var(--transition-fast);
}

.activeStep .stepIcon {
  background-color: var(--color-primary-bg);
  border-color: var(--color-primary);
  color: var(--color-primary);
}

.activeStep .stepLabel {
  color: var(--color-primary);
  font-weight: var(--font-weight-semibold);
}

.completedStep .stepIcon {
  background-color: var(--color-primary);
  border-color: var(--color-primary);
  color: white;
}

.completedStep .stepLabel {
  color: var(--text-primary);
  font-weight: var(--font-weight-medium);
}

.completed {
  background-color: var(--color-primary);
}

.stepContent {
  min-height: 400px;
  background-color: var(--bg-secondary);
  border-radius: var(--border-radius-lg);
  border: 1px solid var(--color-border);
  padding: var(--spacing-lg);
}

.stepActions {
  display: flex;
  justify-content: space-between;
  margin-top: var(--spacing-lg);
}

.backButton, .nextButton {
  display: flex;
  align-items: center;
  gap: var(--spacing-xs);
  padding: var(--spacing-sm) var(--spacing-lg);
  border-radius: var(--border-radius-md);
  font-size: var(--font-size-base);
  font-weight: var(--font-weight-medium);
  cursor: pointer;
  transition: all var(--transition-fast);
}

.backButton {
  background-color: var(--bg-secondary);
  color: var(--text-primary);
  border: 1px solid var(--color-border);
}

.backButton:hover:not(:disabled) {
  background-color: var(--bg-hover);
}

.nextButton {
  background-color: var(--color-primary);
  color: white;
  border: none;
}

.nextButton:hover:not(:disabled) {
  background-color: var(--color-primary-dark);
}

.backButton:disabled, .nextButton:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

@media (max-width: 768px) {
  .stepIndicator {
    flex-direction: column;
    gap: var(--spacing-md);
    align-items: flex-start;
  }
  
  .step {
    flex-direction: row;
    width: 100%;
    gap: var(--spacing-md);
  }
  
  .stepConnector {
    width: 2px;
    height: 20px;
    margin: var(--spacing-xs) 0 var(--spacing-xs) 24px;
  }
  
  .stepActions {
    flex-direction: column;
    gap: var(--spacing-md);
  }
  
  .backButton, .nextButton {
    width: 100%;
    justify-content: center;
  }
}