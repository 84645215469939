/* File: frontend/src/components/API/ApiSidebar.module.css */

.sidebar {
    position: fixed;
    top: calc(var(--header-height) + var(--spacing-5xl));
    left: max(calc((100% - var(--breakpoint-xl)) / 2 + var(--spacing-xl)), var(--spacing-xl));
    width: 280px;
    height: calc(100vh - var(--header-height) - var(--spacing-6xl));
    background: var(--color-white);
    border-radius: var(--border-radius-lg);
    box-shadow: var(--shadow-lg);
    /* z-index: var(--z-index-sticky); */
    z-index: calc(var(--z-index-header)-1);
  }
  
  .sidebarContent {
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }
  
  .sidebarTitle {
    font-size: var(--font-size-lg);
    font-weight: var(--font-weight-semibold);
    color: var(--text-primary);
    padding: var(--spacing-lg) var(--spacing-lg) var(--spacing-md);
    border-bottom: 1px solid var(--color-border);
  }
  
  .nav {
    flex: 1;
    overflow-y: auto;
    padding: var(--spacing-md) var(--spacing-md) var(--spacing-md) var(--spacing-lg);
    scrollbar-width: thin;
    scrollbar-color: var(--color-primary) transparent;
  }
  
  .nav::-webkit-scrollbar {
    width: 4px;
  }
  
  .nav::-webkit-scrollbar-track {
    background: transparent;
  }
  
  .nav::-webkit-scrollbar-thumb {
    background-color: var(--color-primary);
    border-radius: 2px;
  }
  
  .navItem {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-xxs);
    width: 100%;
    padding: var(--spacing-sm) var(--spacing-md);
    margin-bottom: var(--spacing-xs);
    border-radius: var(--border-radius-md);
    background: transparent;
    border: none;
    text-align: left;
    cursor: pointer;
    transition: all var(--transition-fast);
    position: relative;
  }
  
  .navItem::before {
    content: '';
    position: absolute;
    left: calc(var(--spacing-md) * -1);
    top: 50%;
    height: 0;
    width: 3px;
    background: var(--color-primary);
    transform: translateY(-50%);
    transition: height var(--transition-fast);
  }
  
  .navItem:hover {
    background: var(--color-primary-light);
    transform: translateX(4px);
  }
  
  .navItem:hover::before {
    height: 30%;
  }
  
  .navItem.active {
    background: var(--color-primary);
    transform: translateX(8px);
  }
  
  .navItem.active::before {
    height: 70%;
  }
  
  .tagName {
    font-size: var(--font-size-sm);
    font-weight: var(--font-weight-semibold);
    color: var(--text-primary);
  }
  
  .active .tagName {
    color: var(--color-white);
  }
  
  .tagDescription {
    font-size: var(--font-size-xs);
    color: var(--text-secondary);
    line-height: var(--line-height-snug);
  }
  
  .active .tagDescription {
    color: var(--color-white);
    opacity: 0.8;
  }
  
  @media (max-width: 1024px) {
    .sidebar {
      left: var(--spacing-md);
      width: 240px;
    }
  }
  
  @media (max-width: 768px) {
    .sidebar {
      position: sticky;
      top: 0;
      left: 0;
      width: 100%;
      height: auto;
      max-height: none;
      margin-bottom: var(--spacing-xl);
      background: var(--color-white);
    }
  
    .sidebarContent {
      height: auto;
    }
  
    .sidebarTitle {
      padding: var(--spacing-md);
    }
  
    .nav {
      padding: var(--spacing-md);
      overflow-x: auto;
      overflow-y: hidden;
      display: flex;
      flex-direction: row;
      scroll-snap-type: x mandatory;
      -webkit-overflow-scrolling: touch;
    }
  
    .nav::-webkit-scrollbar {
      height: 4px;
      width: auto;
    }
  
    .navItem {
      flex: 0 0 auto;
      min-width: 200px;
      margin-bottom: 0;
      margin-right: var(--spacing-sm);
      scroll-snap-align: start;
      transform: none !important;
    }
  
    .navItem::before {
      left: 0;
      top: auto;
      bottom: calc(var(--spacing-xxs) * -1);
      width: 100%;
      height: 3px !important;
      transform: none;
    }
  
    .navItem:hover {
      transform: none !important;
    }
  }