/* File: frontend/src/pages/InvestorPitch/components/slides/CompetitiveAdvantageSlide/CompetitiveAdvantageSlide.module.css */

.container {
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: var(--spacing-xl);
  }
  
  .header {
    text-align: center;
    margin-bottom: var(--spacing-xl);
  }
  
  .title {
    font-size: var(--font-size-4xl);
    font-weight: var(--font-weight-bold);
    color: var(--text-on-primary);
    margin-bottom: var(--spacing-md);
    background: linear-gradient(to right, var(--color-primary-500), var(--color-primary-700));
    -webkit-background-clip: text;
    background-clip: text;
    color: var(--texton-on-primary);
  }
  
  .subtitle {
    font-size: var(--font-size-xl);
    color: var(--color-gray-400);
  }
  
  .content {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-2xl);
    flex: 1;
  }
  
  .differentiators {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: var(--spacing-xl);
  }
  
  .detailsSection {
    display: grid;
    grid-template-columns: 1.5fr 1fr;
    gap: var(--spacing-xl);
  }
  
  .sectionTitle {
    font-size: var(--font-size-2xl);
    font-weight: var(--font-weight-bold);
    color: var(--text-on-primary);
    margin-bottom: var(--spacing-xl);
    display: flex;
    align-items: center;
    gap: var(--spacing-sm);
  }
  
  .sectionTitle::before {
    content: '';
    width: 4px;
    height: 24px;
    background: linear-gradient(
      to bottom,
      var(--color-primary-500),
      var(--color-primary-700)
    );
    border-radius: var(--border-radius-sm);
  }
  
  /* Responsive Design */
  @media (max-width: 1200px) {
    .differentiators {
      grid-template-columns: repeat(2, 1fr);
    }
  
    .detailsSection {
      grid-template-columns: 1fr;
      gap: var(--spacing-2xl);
    }
  }
  
  @media (max-width: 768px) {
    .differentiators {
      grid-template-columns: 1fr;
    }
  
    .title {
      font-size: var(--font-size-3xl);
    }
  
    .subtitle {
      font-size: var(--font-size-lg);
    }
  
    .content {
      gap: var(--spacing-xl);
    }
  }
  
  /* Print Styles */
  @media print {
    .container {
      height: auto;
      page-break-after: always;
    }
  
    .detailsSection {
      grid-template-columns: 1fr;
    }
  }
  
  /* High Contrast Mode */
  @media (prefers-contrast: high) {
    .title {
      background: none;
      color: var(--text-on-primary);
    }
  
    .sectionTitle::before {
      background: var(--color-primary);
    }
  }
  
  /* Reduced Motion */
  @media (prefers-reduced-motion: reduce) {
    .container [data-animate] {
      transition: none;
    }
  }