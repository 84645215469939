/* File: frontend/src/pages/InvestorPitch/components/slides/ClosingSlide/ClosingSlide.module.css */

.container {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: var(--spacing-3xl) 0;
  }
  
  .content {
    max-width: 900px;
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    gap: var(--spacing-xl);
  }
  
  .logo {
    text-align: center;
    margin-bottom: var(--spacing-2xl);
  }
  
  .logoImage {
    height: 60px;
    width: auto;
  }
  
  .contactSection {
    text-align: center;
    margin-bottom: var(--spacing-2xl);
  }
  
  .contactTitle {
    font-size: var(--font-size-2xl);
    font-weight: var(--font-weight-bold);
    color: var(--text-on-primary);
    margin-bottom: var(--spacing-xl);
  }
  
  .contactGrid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: var(--spacing-xl);
  }
  
  .contactCard {
    background: var(--color-gray-1200);
    border: 1px solid var(--color-gray-1100);
    border-radius: var(--border-radius-lg);
    padding: var(--spacing-lg);
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: var(--spacing-md);
    transition: all var(--transition-fast);
  }
  
  .contactCard:hover {
    transform: translateY(-4px);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  }
  
  .iconWrapper {
    width: 48px;
    height: 48px;
    border-radius: var(--border-radius-lg);
    background: var(--color-gray-1100);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .icon {
    width: 24px;
    height: 24px;
  }
  
  .contactType {
    font-size: var(--font-size-base);
    font-weight: var(--font-weight-bold);
    color: var(--text-on-primary);
    margin-bottom: var(--spacing-xs);
  }
  
  .email {
    font-size: var(--font-size-sm);
    color: var(--color-primary-700);
    text-decoration: none;
    transition: color var(--transition-fast);
  }
  
  .email:hover {
    color: var(--color-primary-400);
  }
  
  .disclaimerSection {
    text-align: center;
  }
  
  .disclaimerTitle {
    font-size: var(--font-size-lg);
    font-weight: var(--font-weight-bold);
    color: var(--text-on-primary);
    margin-bottom: var(--spacing-md);
  }
  
  .disclaimer {
    font-size: var(--font-size-sm);
    color: var(--color-gray-400);
    line-height: 1.6;
    white-space: pre-line;
    text-align: justify;
  }
  
  @media (max-width: 768px) {
    .contactGrid {
      grid-template-columns: 1fr;
    }
  
    .content {
      padding: 0 var(--spacing-lg);
    }
  }