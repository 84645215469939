/* File: frontend/src/pages/SuperAdmin/components/common/SuperAdminHeader/SuperAdminHeader.module.css */

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: var(--spacing-md) var(--spacing-lg);
  background-color: var(--bg-primary);
  box-shadow: var(--shadow-sm);
  z-index: 10;
}

.logoContainer {
  display: flex;
  align-items: center;
}

.logo {
  height: 36px;
  margin-right: var(--spacing-sm);
}

.title {
  font-size: var(--font-size-lg);
  font-weight: var(--font-weight-bold);
  color: var(--text-primary);
  margin: 0;
}

.userActions {
  display: flex;
  align-items: center;
  gap: var(--spacing-md);
}

.searchContainer {
  position: relative;
  width: 300px;
}

.searchInput {
  width: 100%;
  padding: var(--spacing-sm) var(--spacing-md);
  padding-right: 40px;
  border: 1px solid var(--color-border);
  border-radius: var(--border-radius-md);
  font-size: var(--font-size-sm);
  color: var(--text-primary);
  background-color: var(--bg-secondary);
}

.searchButton {
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  color: var(--text-secondary);
  cursor: pointer;
}

.notifications {
  position: relative;
}

.notificationButton {
  background: none;
  border: none;
  font-size: var(--font-size-lg);
  color: var(--text-secondary);
  cursor: pointer;
  padding: var(--spacing-xs);
}

.notificationBadge {
  position: absolute;
  top: -5px;
  right: -5px;
  background-color: var(--color-error);
  color: white;
  font-size: var(--font-size-xs);
  font-weight: var(--font-weight-bold);
  width: 18px;
  height: 18px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.userProfile {
  display: flex;
  align-items: center;
  gap: var(--spacing-xs);
  cursor: pointer;
}

.userAvatar {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  object-fit: cover;
}

.userName {
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-medium);
  color: var(--text-primary);
}

.userMenuButton {
  background: none;
  border: none;
  color: var(--text-secondary);
  cursor: pointer;
}

@media (max-width: 768px) {
  .header {
    padding: var(--spacing-sm);
    flex-direction: column;
    gap: var(--spacing-sm);
  }
  
  .searchContainer {
    width: 100%;
  }
  
  .userActions {
    width: 100%;
    justify-content: space-between;
  }
}