/* File: frontend/src/pages/AutoInsight/VehicleDetail/components/VehicleUsage/VehicleUsage.module.css */

.container {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-md);
}

.grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: var(--spacing-md);
}

.card {
  background-color: var(--bg-primary);
  border-radius: var(--border-radius-md);
  border: 1px solid var(--color-border);
  overflow: hidden;
}

.cardTitle {
  font-size: var(--font-size-md);
  font-weight: var(--font-weight-semibold);
  padding: var(--spacing-sm);
  background-color: var(--bg-secondary);
  border-bottom: 1px solid var(--color-border);
  margin: 0;
}

.cardContent {
  padding: var(--spacing-sm);
}

.usageInfo {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-md);
}

.infoGroup {
  display: flex;
  flex-direction: column;
}

.infoLabel {
  font-weight: var(--font-weight-medium);
  color: var(--text-secondary);
  margin-bottom: var(--spacing-xxs);
}

.infoValue {
  color: var(--text-primary);
}

.usageSection {
  border-top: 1px solid var(--color-border-light);
  padding-top: var(--spacing-sm);
}

.usageSectionTitle {
  font-size: var(--font-size-base);
  font-weight: var(--font-weight-semibold);
  margin-top: 0;
  margin-bottom: var(--spacing-sm);
  color: var(--text-primary);
}

.usageGrid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: var(--spacing-md);
}

.usageItem {
  display: flex;
  flex-direction: column;
}

.usageLabel {
  font-weight: var(--font-weight-medium);
  color: var(--text-secondary);
  margin-bottom: var(--spacing-xxs);
}

.usageValue {
  color: var(--text-primary);
}

.odometerCurrent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: var(--spacing-md);
}

.odometerValue {
  font-size: var(--font-size-3xl);
  font-weight: var(--font-weight-bold);
  color: var(--text-primary);
  margin-bottom: var(--spacing-md);
}

.odometerUnit {
  font-size: var(--font-size-lg);
  font-weight: var(--font-weight-medium);
  color: var(--text-secondary);
  margin-left: var(--spacing-xxs);
}

.odometerDetails {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xs);
  width: 100%;
}

.odometerDetail {
  display: flex;
  justify-content: space-between;
}

.odometerDetailLabel {
  font-weight: var(--font-weight-medium);
  color: var(--text-secondary);
}

.odometerDetailValue {
  color: var(--text-primary);
}

.tableContainer {
  overflow-x: auto;
}

.table {
  width: 100%;
  border-collapse: collapse;
}

.table th, .table td {
  padding: var(--spacing-sm);
  text-align: left;
  border-bottom: 1px solid var(--color-border);
}

.table th {
  background-color: var(--bg-secondary);
  font-weight: var(--font-weight-semibold);
  color: var(--text-secondary);
}

.table tr:hover {
  background-color: var(--bg-hover);
}

.noData {
  color: var(--text-secondary);
  font-style: italic;
  text-align: center;
  padding: var(--spacing-md);
}

@media (max-width: 768px) {
  .grid, .usageGrid {
    grid-template-columns: 1fr;
  }
}