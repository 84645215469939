/* File: frontend/src/pages/QMS/QuoteDetail/components/QuoteSystem/QuoteSystem.module.css */

.container {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-md);
}

.card {
  background-color: var(--bg-primary);
  border-radius: var(--border-radius-md);
  border: 1px solid var(--color-border);
  overflow: hidden;
}

.cardHeader {
  padding: var(--spacing-sm);
  background-color: var(--bg-secondary);
  border-bottom: 1px solid var(--color-border);
}

.cardTitle {
  font-size: var(--font-size-md);
  font-weight: var(--font-weight-semibold);
  margin: 0;
}

.cardContent {
  padding: var(--spacing-md);
}

.infoGroup {
  margin-bottom: var(--spacing-md);
}

.infoGroup:last-child {
  margin-bottom: 0;
}

.infoLabel {
  font-weight: var(--font-weight-medium);
  color: var(--text-secondary);
  margin-bottom: var(--spacing-xs);
}

.infoValue {
  color: var(--text-primary);
  word-break: break-word;
}

.referencesList, .flagsList, .reasonsList {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.referencesList li, .flagsList li, .reasonsList li {
  margin-bottom: var(--spacing-xs);
}

.referencesList li:last-child, .flagsList li:last-child, .reasonsList li:last-child {
  margin-bottom: 0;
}

.referenceLabel, .flagLabel, .integrationLabel {
  font-weight: var(--font-weight-medium);
  margin-right: var(--spacing-xxs);
}

.flagValue {
  display: inline-block;
  padding: 2px 8px;
  border-radius: 4px;
  font-size: var(--font-size-xs);
  font-weight: var(--font-weight-medium);
}

.flagTrue {
  background-color: var(--color-error-bg);
  color: var(--color-error);
}

.flagFalse {
  background-color: var(--color-success-bg);
  color: var(--color-success);
}

.integrationItem {
  margin-bottom: var(--spacing-xs);
}

.integrationItem:last-child {
  margin-bottom: 0;
}

.eligibilityStatus {
  display: inline-block;
  padding: 2px 8px;
  border-radius: 4px;
  font-size: var(--font-size-xs);
  font-weight: var(--font-weight-medium);
}

.eligible {
  background-color: var(--color-success-bg);
  color: var(--color-success);
}

.ineligible {
  background-color: var(--color-error-bg);
  color: var(--color-error);
}

.referral {
  background-color: var(--color-warning-bg);
  color: var(--color-warning);
}

.pending {
  background-color: var(--color-info-bg);
  color: var(--color-info);
}

.reasonItem {
  padding: var(--spacing-xs);
  background-color: var(--bg-secondary);
  border-radius: var(--border-radius-sm);
  margin-bottom: var(--spacing-xs);
}

.noData {
  color: var(--text-secondary);
  font-style: italic;
  text-align: center;
  padding: var(--spacing-md);
}