/* File: frontend/src/pages/SupportedCountries/SupportedCountries.module.css */

.container {
    max-width: var(--breakpoint-xl);
    margin: 0 auto;
    padding: clamp(1rem, 5vw, 2rem);
    font-family: var(--font-primary);
  }
  
  .header {
    text-align: center;
    margin-bottom: var(--spacing-2xl);
  }
  
  .title {
    font-size: var(--font-size-4xl);
    font-weight: var(--font-weight-bold);
    color: var(--text-primary);
    margin-bottom: var(--spacing-md);
  }
  
  .description {
    font-size: var(--font-size-lg);
    color: var(--text-secondary);
    max-width: 800px;
    margin: 0 auto;
    line-height: var(--line-height-relaxed);
  }
  
  .mapCard {
    background-color: var(--color-white);
    border-radius: var(--border-radius-xl);
    padding: var(--spacing-xl);
    box-shadow: var(--shadow-sm);
    margin-bottom: var(--spacing-2xl);
  }
  
  .mapHeader {
    margin-bottom: var(--spacing-lg);
  }
  
  .categoryButtons {
    display: flex;
    gap: var(--spacing-sm);
    flex-wrap: wrap;
    justify-content: center;
  }
  
  .categoryButton {
    padding: var(--spacing-sm) var(--spacing-lg);
    border-radius: var(--border-radius-full);
    border: 1px solid var(--color-primary-light);
    background: var(--color-white);
    color: var(--color-primary);
    font-size: var(--font-size-sm);
    font-weight: var(--font-weight-medium);
    cursor: pointer;
    transition: all var(--transition-fast);
  }
  
  .categoryButton:hover:not(.active) {
    background: var(--color-primary-light);
  }
  
  .categoryButton.active {
    background: var(--color-primary);
    color: var(--color-white);
    border-color: var(--color-primary);
  }
  
  .mapSection {
    position: relative;
    height: 600px;
    border-radius: var(--border-radius-lg);
    overflow: hidden;
    background-color: var(--color-gray-900);
  }
  
  .mapLoading {
    position: absolute;
    inset: 0;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--color-white);
    font-size: var(--font-size-md);
    gap: var(--spacing-sm);
    z-index: 1;
  }
  
  .loadingSpinner {
    width: var(--spacing-md);
    height: var(--spacing-md);
    border: 2px solid var(--color-white);
    border-top: 2px solid transparent;
    border-radius: 50%;
    animation: spin 1s linear infinite;
  }
  
  .statsSection {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: var(--spacing-xl);
    max-width: 900px;
    margin: 0 auto;
  }
  
  .stat {
    text-align: center;
    padding: var(--spacing-lg);
    background-color: var(--color-white);
    border-radius: var(--border-radius-lg);
    box-shadow: var(--shadow-sm);
    transition: transform var(--transition-fast);
  }
  
  .stat:hover {
    transform: translateY(-4px);
    box-shadow: var(--shadow-md);
  }
  
  .stat h3 {
    font-size: var(--font-size-3xl);
    font-weight: var(--font-weight-bold);
    color: var(--color-primary);
    margin-bottom: var(--spacing-xs);
  }
  
  .stat p {
    font-size: var(--font-size-md);
    color: var(--text-secondary);
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
  @media (max-width: 1024px) {
    .mapSection {
      height: 500px;
    }
  }
  
  @media (max-width: 768px) {
    .title {
      font-size: var(--font-size-3xl);
    }
  
    .description {
      font-size: var(--font-size-md);
    }
  
    .mapCard {
      padding: var(--spacing-lg);
    }
  
    .mapHeader {
      margin-bottom: var(--spacing-md);
    }
  
    .categoryButtons {
      width: 100%;
      overflow-x: auto;
      padding-bottom: var(--spacing-xs);
      -webkit-overflow-scrolling: touch;
    }
  
    .categoryButton {
      white-space: nowrap;
    }
  
    .mapSection {
      height: 400px;
    }
  
    .statsSection {
      grid-template-columns: 1fr;
      gap: var(--spacing-md);
    }
  }
  
  @media (max-width: 480px) {
    .mapCard {
      padding: var(--spacing-md);
    }
  
    .mapSection {
      height: 300px;
    }
  
    .stat h3 {
      font-size: var(--font-size-2xl);
    }
  }