/* File: frontend/src/pages/EmbeddedInsurance/components/ApiExplorer/styles/ApiEndpoint.module.css */

.endpoint {
  display: flex;
  flex-direction: column;
  border: 1px solid var(--color-border);
  border-radius: var(--border-radius-lg);
  overflow: hidden;
}

.header {
  padding: var(--spacing-md) var(--spacing-lg);
  background-color: var(--bg-secondary);
  border-bottom: 1px solid var(--color-border);
}

.headerContent {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xs);
}

.methodAndPath {
  display: flex;
  align-items: center;
  gap: var(--spacing-md);
}

.methodBadge {
  padding: var(--spacing-xxs) var(--spacing-xs);
  border-radius: var(--border-radius-sm);
  font-size: var(--font-size-xs);
  font-weight: var(--font-weight-semibold);
  text-transform: uppercase;
}

.methodGet {
  background-color: var(--color-success-bg);
  color: var(--color-success);
}

.methodPost {
  background-color: var(--color-primary-bg);
  color: var(--color-primary);
}

.methodPut {
  background-color: var(--color-warning-bg);
  color: var(--color-warning);
}

.methodDelete {
  background-color: var(--color-error-bg);
  color: var(--color-error);
}

.methodPatch {
  background-color: var(--color-info-bg);
  color: var(--color-info);
}

.methodDefault {
  background-color: var(--bg-tertiary);
  color: var(--text-secondary);
}

.path {
  font-family: var(--font-secondary);
  font-size: var(--font-size-sm);
  color: var(--text-primary);
  font-weight: var(--font-weight-medium);
}

.summary {
  font-size: var(--font-size-sm);
  color: var(--text-secondary);
}

.tabs {
  display: flex;
  border-bottom: 1px solid var(--color-border);
}

.tab {
  display: flex;
  align-items: center;
  gap: var(--spacing-xs);
  padding: var(--spacing-sm) var(--spacing-md);
  background: none;
  border: none;
  border-bottom: 2px solid transparent;
  color: var(--text-secondary);
  font-size: var(--font-size-sm);
  cursor: pointer;
  transition: all var(--transition-fast);
}

.tab:hover {
  color: var(--text-primary);
}

.activeTab {
  color: var(--color-primary);
  border-bottom-color: var(--color-primary);
}

.content {
  padding: var(--spacing-lg);
  flex: 1;
  overflow-y: auto;
}

.documentation {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-lg);
}

.description {
  color: var(--text-secondary);
  line-height: var(--line-height-relaxed);
}

.section {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-md);
}

.sectionTitle {
  font-size: var(--font-size-md);
  font-weight: var(--font-weight-semibold);
  color: var(--text-primary);
  margin: 0;
  padding-bottom: var(--spacing-xs);
  border-bottom: 1px solid var(--color-border-light);
}

.requestBody {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-md);
}

.schema {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-sm);
}

.schemaTitle {
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-medium);
  color: var(--text-secondary);
  margin: 0;
}

.codeSamples {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-md);
}

.codeSampleTabs {
  display: flex;
  border-bottom: 1px solid var(--color-border);
}

.codeSampleTab {
  padding: var(--spacing-xs) var(--spacing-md);
  background: none;
  border: none;
  border-bottom: 2px solid transparent;
  color: var(--text-secondary);
  font-size: var(--font-size-sm);
  cursor: pointer;
  transition: all var(--transition-fast);
}

.codeSampleTab:hover {
  color: var(--text-primary);
}

.activeCodeSampleTab {
  color: var(--color-primary);
  border-bottom-color: var(--color-primary);
}

.codeSampleContent {
  margin-top: var(--spacing-md);
}

.tryIt {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-lg);
}

.tryItHeader {
  margin-bottom: var(--spacing-md);
}

.tryItTitle {
  font-size: var(--font-size-md);
  font-weight: var(--font-weight-semibold);
  color: var(--text-primary);
  margin: 0 0 var(--spacing-xs) 0;
}

.tryItDescription {
  color: var(--text-secondary);
  font-size: var(--font-size-sm);
}

.tryItForm {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-lg);
}

.tryItParameters {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-md);
}

.tryItSectionTitle {
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-semibold);
  color: var(--text-primary);
  margin: 0 0 var(--spacing-sm) 0;
}

.tryItParameter {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xs);
}

.tryItParameterLabel {
  font-size: var(--font-size-sm);
  color: var(--text-secondary);
}

.required {
  color: var(--color-error);
  margin-left: var(--spacing-xxs);
}

.tryItParameterInput {
  padding: var(--spacing-sm);
  border: 1px solid var(--color-border);
  border-radius: var(--border-radius-md);
  font-size: var(--font-size-sm);
}

.tryItRequestBody {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-sm);
}

.tryItRequestBodyInput {
  padding: var(--spacing-sm);
  border: 1px solid var(--color-border);
  border-radius: var(--border-radius-md);
  font-family: var(--font-secondary);
  font-size: var(--font-size-sm);
  resize: vertical;
}

.tryItActions {
  display: flex;
  justify-content: flex-end;
}

.tryItButton {
  display: flex;
  align-items: center;
  gap: var(--spacing-xs);
  padding: var(--spacing-sm) var(--spacing-md);
  background-color: var(--color-primary);
  color: white;
  border: none;
  border-radius: var(--border-radius-md);
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-medium);
  cursor: pointer;
  transition: background-color var(--transition-fast);
}

.tryItButton:hover {
  background-color: var(--color-primary-dark);
}

.tryItResponse {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-sm);
}

.tryItResponsePlaceholder {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: var(--spacing-sm);
  padding: var(--spacing-lg);
  background-color: var(--bg-secondary);
  border: 1px solid var(--color-border);
  border-radius: var(--border-radius-md);
  color: var(--text-tertiary);
}

@media (max-width: 768px) {
  .header {
    padding: var(--spacing-md);
  }
  
  .content {
    padding: var(--spacing-md);
  }
  
  .methodAndPath {
    flex-direction: column;
    align-items: flex-start;
    gap: var(--spacing-xs);
  }
  
  .tabs {
    overflow-x: auto;
  }
  
  .tab {
    padding: var(--spacing-xs) var(--spacing-sm);
    white-space: nowrap;
  }
}