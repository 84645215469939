/* File: frontend/src/components/UploadSection/UploadArea.module.css */

.uploadArea {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: var(--spacing-xl);
    border: 2px dashed var(--color-border);
    border-radius: var(--border-radius-lg);
    background-color: var(--bg-primary);
    transition: all 0.3s ease;
  }
  
  .uploadArea.active {
    border-color: var(--color-primary);
    background-color: var(--color-primary-light);
  }
  
  .icon {
    width: 48px;
    height: 48px;
    margin-bottom: var(--spacing-md);
  }
  
  .content {
    text-align: center;
  }
  
  .instruction {
    font-size: var(--font-size-md);
    margin-bottom: var(--spacing-sm);
  }
  
  .chooseFile {
    color: var(--color-primary);
    text-decoration: underline;
    background: none;
    border: none;
    cursor: pointer;
  }
  
  .supportedFile {
    font-size: var(--font-size-sm);
    color: var(--text-secondary);
  }
  