/* File: frontend/src/pages/InvestorPitch/components/slides/DemoSlide/DemoSlide.module.css */

.container {
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: var(--spacing-xl);
  }
  
  .header {
    text-align: center;
    margin-bottom: var(--spacing-xl);
  }
  
  .title {
    font-size: var(--font-size-4xl);
    font-weight: var(--font-weight-bold);
    color: var(--text-on-primary);
    margin-bottom: var(--spacing-md);
    background: linear-gradient(to right, var(--color-primary-500), var(--color-primary-700));
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
  }
  
  .subtitle {
    font-size: var(--font-size-xl);
    color: var(--color-gray-400);
  }
  
  .videoGrid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: var(--spacing-xl);
    padding: 0 var(--spacing-xl);
  }
  
  .videoCard {
    background: var(--color-gray-1200);
    border: 1px solid var(--color-gray-1100);
    border-radius: var(--border-radius-lg);
    overflow: hidden;
    cursor: pointer;
  }
  
  .thumbnailContainer {
    position: relative;
    width: 100%;
    padding-top: 56.25%; /* 16:9 Aspect Ratio */
  }
  
  .thumbnail {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
  
  .playButton {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 48px;
    height: 48px;
    background: rgba(0, 0, 0, 0.7);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all var(--transition-fast);
  }
  
  .videoCard:hover .playButton {
    background: var(--color-primary);
    transform: translate(-50%, -50%) scale(1.1);
  }
  
  .playIcon {
    width: 24px;
    height: 24px;
    color: var(--color-white);
  }
  
  .duration {
    position: absolute;
    bottom: var(--spacing-sm);
    right: var(--spacing-sm);
    background: rgba(0, 0, 0, 0.8);
    padding: var(--spacing-xs) var(--spacing-sm);
    border-radius: var(--border-radius-sm);
    font-size: var(--font-size-xs);
    color: var(--color-white);
  }
  
  .videoInfo {
    padding: var(--spacing-lg);
    display: flex;
    gap: var(--spacing-md);
  }
  
  .iconWrapper {
    width: 40px;
    height: 40px;
    border-radius: var(--border-radius-lg);
    background: var(--color-gray-1100);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
  }
  
  .icon {
    width: 20px;
    height: 20px;
  }
  
  .textContent {
    flex: 1;
  }
  
  .videoTitle {
    font-size: var(--font-size-lg);
    font-weight: var(--font-weight-bold);
    color: var(--text-on-primary);
    margin-bottom: var(--spacing-xs);
  }
  
  .videoDescription {
    font-size: var(--font-size-sm);
    color: var(--color-gray-400);
    line-height: 1.5;
  }
  
  .videoOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.9);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
  
  .videoModal {
    position: relative;
    width: 90%;
    max-width: 1200px;
    background: var(--color-gray-1200);
    border-radius: var(--border-radius-lg);
    overflow: hidden;
  }
  
  .closeButton {
    position: absolute;
    top: var(--spacing-md);
    right: var(--spacing-md);
    width: 36px;
    height: 36px;
    border-radius: 50%;
    background: rgba(0, 0, 0, 0.5);
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    z-index: 1;
    transition: all var(--transition-fast);
  }
  
  .closeButton:hover {
    background: var(--color-gray-1100);
  }
  
  .closeIcon {
    width: 20px;
    height: 20px;
    color: var(--color-white);
  }
  
  @media (max-width: 1024px) {
    .videoGrid {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  
  @media (max-width: 768px) {
    .videoGrid {
      grid-template-columns: 1fr;
      padding: 0 var(--spacing-md);
    }
  }