/* File: frontend/src/pages/InvestorPitch/components/common/ProgressBar.module.css */

.container {
    width: 100%;
    height: 8px;
    background-color: var(--color-gray-1100);
    border-radius: var(--border-radius-full);
    overflow: hidden;
  }
  
  .progress {
    height: 100%;
    border-radius: var(--border-radius-full);
  }