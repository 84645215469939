/* File: frontend/src/pages/AI1/components/TemplateSelector/TemplateSelector.module.css */

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: var(--z-index-modal);
  }
  
  .modal {
    background-color: var(--color-white);
    border-radius: var(--border-radius-lg);
    width: 90%;
    max-width: 500px;
    max-height: 90vh;
    overflow-y: auto;
  }
  
  .header {
    padding: var(--spacing-md);
    border-bottom: 1px solid var(--color-border);
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .header h3 {
    margin: 0;
    font-size: var(--font-size-lg);
    color: var(--text-primary);
  }
  
  .closeButton {
    background: none;
    border: none;
    cursor: pointer;
    padding: var(--spacing-xxs);
  }
  
  .content {
    padding: var(--spacing-md);
    display: flex;
    flex-direction: column;
    gap: var(--spacing-sm);
  }
  
  .templateButton {
    padding: var(--spacing-sm) var(--spacing-md);
    border: 1px solid var(--color-border);
    border-radius: var(--border-radius-md);
    background-color: var(--color-white);
    text-align: left;
    cursor: pointer;
    transition: all var(--transition-fast);
  }
  
  .templateButton:hover {
    background-color: var(--bg-hover);
  }
  
  .templateButton.selected {
    border-color: var(--color-primary);
    background-color: var(--color-primary-100);
  }
  
  .createButton {
    display: flex;
    align-items: center;
    gap: var(--spacing-sm);
    padding: var(--spacing-sm) var(--spacing-md);
    border: 1px dashed var(--color-border);
    border-radius: var(--border-radius-md);
    background-color: var(--bg-secondary);
    cursor: pointer;
    color: var(--text-secondary);
  }
  
  .createButton:hover {
    background-color: var(--bg-hover);
  }