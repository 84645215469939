/* File: frontend/src/pages/InvestorPitch/components/slides/GoToMarketSlide/GoToMarketSlide.module.css */

.container {
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xl);
  padding: var(--spacing-md);
}

.header {
  text-align: center;
  margin-bottom: var(--spacing-md);
}

.title {
  font-size: var(--font-size-4xl);
  font-weight: var(--font-weight-bold);
  color: var(--text-on-primary);
  margin-bottom: var(--spacing-md);
}

.subtitle {
  font-size: var(--font-size-xl);
  color: var(--color-gray-400);
  max-width: 80%;
  margin: 0 auto;
  line-height: 1.4;
}

.content {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xl);
  flex: 1;
}

.channelsGrid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  background-color: var(--color-gray-1200);
  border-radius: var(--border-radius-lg);
  border: 1px solid var(--color-gray-1100);
  overflow: hidden;
}

.channelItem {
  border-right: 1px solid var(--color-gray-1100);
}

.channelItem:last-child {
  border-right: none;
}

.deviceWrapper {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: var(--spacing-md);
}

.videoWrapper {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  cursor: pointer;
}

.channelVideo {
  width: 100%;
  height: 100%;
  object-fit: cover;
  background-color: #000;
}

.loadingOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.7);
}

.loadingSpinner {
  width: 30px;
  height: 30px;
  border: 3px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top-color: var(--color-primary-500);
  animation: spin 1s linear infinite;
}

.playOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.3);
  opacity: 0;
  transition: opacity 0.3s ease;
}

.videoWrapper:hover .playOverlay {
  opacity: 1;
}

.playButton {
  width: 48px;
  height: 48px;
  background-color: rgba(35, 72, 237, 0.8);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.2s ease, background-color 0.2s ease;
}

.playButton:hover {
  transform: scale(1.1);
  background-color: var(--color-primary);
}

.errorMessage {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  text-align: center;
  padding: var(--spacing-sm);
  font-size: var(--font-size-sm);
}

.retryButton {
  margin-top: var(--spacing-sm);
  padding: var(--spacing-xs) var(--spacing-sm);
  background-color: var(--color-primary);
  color: white;
  border: none;
  border-radius: var(--border-radius-sm);
  cursor: pointer;
  font-weight: var(--font-weight-medium);
  font-size: var(--font-size-xs);
}

.retryButton:hover {
  background-color: var(--color-primary-dark);
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.statsContainer {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: var(--spacing-lg);
  padding: var(--spacing-lg);
  background: linear-gradient(135deg, var(--color-gray-1200), var(--color-gray-1100));
  border-radius: var(--border-radius-lg);
  border: 1px solid var(--color-gray-1000);
}

.statItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: var(--spacing-xs);
}

.statValue {
  font-size: var(--font-size-3xl);
  font-weight: var(--font-weight-bold);
  color: var(--color-primary-500);
}

.statLabel {
  font-size: var(--font-size-sm);
  color: var(--color-gray-300);
}

.apiContent {
  padding: var(--spacing-md);
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.apiCodeLines {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-md);
}

.apiCodeLine {
  font-family: monospace;
  font-size: var(--font-size-sm);
  color: var(--color-gray-200);
}

.apiFunction {
  color: var(--color-primary-500);
  font-weight: var(--font-weight-bold);
}

.apiComment {
  color: var(--color-gray-500);
}

@media (max-width: 1200px) {
  .channelsGrid {
    grid-template-columns: repeat(2, 1fr);
  }
  
  .channelItem:nth-child(2n) {
    border-right: none;
  }
  
  .channelItem:nth-child(1), .channelItem:nth-child(2) {
    border-bottom: 1px solid var(--color-gray-1100);
  }
  
  .statsContainer {
    grid-template-columns: repeat(2, 1fr);
  }
  
  .playButton {
    width: 40px;
    height: 40px;
  }
}

@media (max-width: 768px) {
  .title {
    font-size: var(--font-size-3xl);
  }
  
  .subtitle {
    font-size: var(--font-size-lg);
    max-width: 90%;
  }
  
  .channelsGrid {
    grid-template-columns: 1fr;
  }
  
  .channelItem {
    border-right: none;
    border-bottom: 1px solid var(--color-gray-1100);
  }
  
  .channelItem:last-child {
    border-bottom: none;
  }
  
  .statsContainer {
    grid-template-columns: 1fr;
  }
  
  .playButton {
    width: 36px;
    height: 36px;
  }
  
  .loadingSpinner {
    width: 24px;
    height: 24px;
    border-width: 2px;
  }
}

@media (max-width: 480px) {
  .container {
    padding: var(--spacing-sm);
  }
  
  .title {
    font-size: var(--font-size-2xl);
  }
  
  .subtitle {
    font-size: var(--font-size-base);
    max-width: 100%;
  }
  
  .deviceWrapper {
    padding: var(--spacing-sm);
  }
  
  .playButton {
    width: 32px;
    height: 32px;
  }
  
  .apiCodeLine {
    font-size: var(--font-size-xs);
  }
}