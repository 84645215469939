/* File: frontend/src/components/form/LanguageSelector/LanguageSelector.module.css  */
.languageSelector {
  display: flex;
  align-items: center;
  position: relative;
}
  
.languageSelector :global(.Dropdown_selected) {
  background-color: --color-gray-0;
  color: var(--text-tertiary);
  border: none;
  padding: var(--spacing-xs) var(--spacing-sm);
  font-size: var(--font-size-sm);
}
  
.languageSelector :global(.Dropdown_chevron) {
  width: 12px;
  height: 12px;
}
  
.languageSelector :global(.Dropdown_options) {
  background-color: var(--color-gray-0);
  border: 1px solid var(--color-border);
  border-radius: var(--border-radius-md);
  box-shadow: var(--shadow-md);
  margin-top: var(--spacing-xs);
}
  
.languageSelector :global(.Dropdown_option) {
  color: var(--text-primary);
  padding: var(--spacing-xs) var(--spacing-sm);
  font-size: var(--font-size-sm);
}

.languageSelector :global(.Dropdown_option:hover) {
  background-color: var(--color-gray-0);
}

.languageSelector :global(.Dropdown_option.selected) {
  background-color: var(--color-gray-0);
}

/* Mobile specific styles */
@media (max-width: 768px) {
  .languageSelector :global(.Dropdown_options) {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    max-height: 50vh;
    margin: 0;
    padding-bottom: env(safe-area-inset-bottom);
    background-color: var(--bg-primary);
    border-radius: var(--border-radius-lg) var(--border-radius-lg) 0 0;
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
  }

  .languageSelector :global(.Dropdown_option) {
    padding: var(--spacing-md);
    font-size: var(--font-size-base);
  }
}