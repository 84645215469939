/* File: frontend/src/pages/SuperAdmin/components/UserManagement/UserVerification/UserVerification.module.css */

.container {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-md);
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: var(--spacing-md);
}

.backButton {
  display: flex;
  align-items: center;
  gap: var(--spacing-xs);
  padding: var(--spacing-sm) var(--spacing-md);
  background-color: var(--bg-secondary);
  color: var(--text-primary);
  border: 1px solid var(--color-border);
  border-radius: var(--border-radius-md);
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-medium);
  cursor: pointer;
  transition: all var(--transition-fast);
}

.backButton:hover {
  background-color: var(--bg-hover);
}

.headerTitle {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title {
  font-size: var(--font-size-xl);
  font-weight: var(--font-weight-bold);
  color: var(--text-primary);
  margin: 0;
}

.subtitle {
  font-size: var(--font-size-sm);
  color: var(--text-secondary);
}

.bulkActions {
  display: flex;
  gap: var(--spacing-sm);
}

.bulkVerifyButton, .bulkRejectButton {
  display: flex;
  align-items: center;
  gap: var(--spacing-xs);
  padding: var(--spacing-sm) var(--spacing-md);
  border-radius: var(--border-radius-md);
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-medium);
  cursor: pointer;
  transition: all var(--transition-fast);
}

.bulkVerifyButton {
  background-color: var(--color-success-bg);
  color: var(--color-success);
  border: 1px solid var(--color-success);
}

.bulkVerifyButton:hover:not(:disabled) {
  background-color: var(--color-success);
  color: white;
}

.bulkRejectButton {
  background-color: var(--color-error-bg);
  color: var(--color-error);
  border: 1px solid var(--color-error);
}

.bulkRejectButton:hover:not(:disabled) {
  background-color: var(--color-error);
  color: white;
}

.bulkVerifyButton:disabled, .bulkRejectButton:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.selectedCount {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 20px;
  height: 20px;
  background-color: var(--color-success);
  color: white;
  border-radius: 10px;
  font-size: var(--font-size-xs);
  padding: 0 var(--spacing-xs);
}

.error {
  padding: var(--spacing-md);
  background-color: var(--color-error-bg);
  color: var(--color-error);
  border-radius: var(--border-radius-md);
  margin-bottom: var(--spacing-md);
}

.pagination {
  display: flex;
  justify-content: center;
  margin-top: var(--spacing-md);
}

.checkboxHeader, .checkboxCell {
  display: flex;
  justify-content: center;
  align-items: center;
}

.checkbox {
  width: 18px;
  height: 18px;
  cursor: pointer;
}

.idCell {
  font-family: var(--font-family-mono);
  font-size: var(--font-size-sm);
  color: var(--text-secondary);
}

.nameCell {
  font-weight: var(--font-weight-medium);
  color: var(--color-primary);
}

.roleCell {
  text-transform: capitalize;
}

.actions {
  display: flex;
  gap: var(--spacing-xs);
}

.viewButton, .verifyButton, .rejectButton {
  display: flex;
  align-items: center;
  gap: var(--spacing-xxs);
  padding: var(--spacing-xs) var(--spacing-sm);
  border-radius: var(--border-radius-sm);
  font-size: var(--font-size-xs);
  cursor: pointer;
  transition: all var(--transition-fast);
}

.viewButton {
  background-color: var(--bg-secondary);
  color: var(--text-primary);
  border: 1px solid var(--color-border);
}

.viewButton:hover {
  background-color: var(--bg-hover);
  color: var(--color-primary);
  border-color: var(--color-primary);
}

.verifyButton {
  background-color: var(--color-success-bg);
  color: var(--color-success);
  border: 1px solid var(--color-success);
}

.verifyButton:hover {
  background-color: var(--color-success);
  color: white;
}

.rejectButton {
  background-color: var(--color-error-bg);
  color: var(--color-error);
  border: 1px solid var(--color-error);
}

.rejectButton:hover {
  background-color: var(--color-error);
  color: white;
}

.emptyState {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: var(--spacing-xl);
  background-color: var(--bg-secondary);
  border-radius: var(--border-radius-md);
  min-height: 300px;
}

.emptyIcon {
  font-size: var(--font-size-4xl);
  color: var(--color-success);
  margin-bottom: var(--spacing-md);
}

.emptyTitle {
  font-size: var(--font-size-xl);
  font-weight: var(--font-weight-semibold);
  color: var(--text-primary);
  margin: 0 0 var(--spacing-sm) 0;
}

.emptyDescription {
  font-size: var(--font-size-base);
  color: var(--text-secondary);
  text-align: center;
  max-width: 500px;
  margin: 0 0 var(--spacing-lg) 0;
}

.backToUsersButton {
  padding: var(--spacing-sm) var(--spacing-lg);
  background-color: var(--color-primary);
  color: white;
  border: none;
  border-radius: var(--border-radius-md);
  font-size: var(--font-size-base);
  font-weight: var(--font-weight-medium);
  cursor: pointer;
  transition: background-color var(--transition-fast);
}

.backToUsersButton:hover {
  background-color: var(--color-primary-dark);
}

@media (max-width: 992px) {
  .header {
    flex-wrap: wrap;
    gap: var(--spacing-md);
  }
  
  .headerTitle {
    order: -1;
    width: 100%;
    margin-bottom: var(--spacing-sm);
  }
  
  .backButton, .bulkActions {
    flex: 1;
  }
}

@media (max-width: 768px) {
  .header {
    flex-direction: column;
    align-items: stretch;
  }
  
  .backButton {
    width: 100%;
    justify-content: center;
    margin-bottom: var(--spacing-sm);
  }
  
  .bulkActions {
    flex-direction: column;
    gap: var(--spacing-sm);
  }
  
  .actions {
    flex-direction: column;
    gap: var(--spacing-xs);
  }
  
  .viewButton, .verifyButton, .rejectButton {
    width: 100%;
    justify-content: center;
  }
}