/* File: frontend/src/pages/UMS/UserDetail/components/UserConnections/UserConnections.module.css */

.container {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-md);
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: var(--spacing-md);
}

.title {
  font-size: var(--font-size-lg);
  font-weight: var(--font-weight-semibold);
  color: var(--text-primary);
  margin: 0;
}

.addButton {
  padding: var(--button-padding-small);
  background-color: var(--button-primary-bg);
  color: var(--button-primary-text);
  border: none;
  border-radius: var(--button-border-radius-md);
  font-size: var(--button-font-size-small);
  font-weight: var(--font-weight-medium);
  cursor: pointer;
  transition: background-color var(--transition-fast);
}

.addButton:hover {
  background-color: var(--button-primary-hover-bg);
}

.connectionsList {
  overflow-x: auto;
}

.connectionsTable {
  width: 100%;
  border-collapse: collapse;
}

.connectionsTable th, .connectionsTable td {
  padding: var(--spacing-sm);
  text-align: left;
  border-bottom: 1px solid var(--color-border);
}

.connectionsTable th {
  background-color: var(--bg-secondary);
  font-weight: var(--font-weight-semibold);
  color: var(--text-secondary);
}

.connectionsTable tr:hover {
  background-color: var(--bg-hover);
}

.pending, .active, .declined, .blocked {
  display: inline-block;
  padding: 2px 8px;
  border-radius: 4px;
  font-size: var(--font-size-xs);
  font-weight: var(--font-weight-medium);
}

.pending {
  background-color: var(--color-info-bg);
  color: var(--color-info);
}

.active {
  background-color: var(--color-success-bg);
  color: var(--color-success);
}

.declined {
  background-color: var(--color-warning-bg);
  color: var(--color-warning);
}

.blocked {
  background-color: var(--color-error-bg);
  color: var(--color-error);
}

.actionButtons {
  display: flex;
  gap: var(--spacing-xs);
}

.acceptButton, .declineButton, .blockButton, .unblockButton, .resendButton, .removeButton {
  padding: var(--button-padding-xs);
  font-size: var(--button-font-size-xs);
  border-radius: var(--button-border-radius-sm);
  font-weight: var(--font-weight-medium);
  cursor: pointer;
  transition: background-color var(--transition-fast);
}

.acceptButton {
  background-color: var(--button-success-bg);
  color: var(--button-success-text);
  border: none;
}

.acceptButton:hover {
  background-color: var(--button-success-hover-bg);
}

.declineButton, .blockButton {
  background-color: var(--button-warning-bg);
  color: var(--button-warning-text);
  border: none;
}

.declineButton:hover, .blockButton:hover {
  background-color: var(--button-warning-hover-bg);
}

.removeButton {
  background-color: var(--button-danger-bg);
  color: var(--button-danger-text);
  border: none;
}

.removeButton:hover {
  background-color: var(--button-danger-hover-bg);
}

.resendButton, .unblockButton {
  background-color: var(--button-secondary-bg);
  color: var(--button-secondary-text);
  border: 1px solid var(--button-secondary-border);
}

.resendButton:hover, .unblockButton:hover {
  background-color: var(--button-secondary-hover-bg);
}

.noConnections {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: var(--spacing-xl);
  text-align: center;
  color: var(--text-secondary);
  background-color: var(--bg-secondary);
  border-radius: var(--border-radius-md);
}

.formCard {
  background-color: var(--bg-primary);
  border-radius: var(--border-radius-md);
  border: 1px solid var(--color-border);
  padding: var(--spacing-md);
  margin-bottom: var(--spacing-md);
}

.formTitle {
  font-size: var(--font-size-md);
  font-weight: var(--font-weight-semibold);
  margin: 0 0 var(--spacing-md) 0;
}

.form {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-md);
}

.formRow {
  display: flex;
  gap: var(--spacing-md);
}

.formGroup {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.label {
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-medium);
  color: var(--text-secondary);
  margin-bottom: var(--spacing-xxs);
}

.input, .select {
  padding: var(--spacing-sm);
  border: 1px solid var(--color-border);
  border-radius: var(--border-radius-md);
  font-size: var(--font-size-base);
  color: var(--text-primary);
  background-color: var(--bg-primary);
  transition: border-color var(--transition-fast);
}

.input:hover, .select:hover {
  border-color: var(--color-border-hover);
}

.input:focus, .select:focus {
  outline: none;
  box-shadow: var(--input-focus-box-shadow);
  border-color: var(--input-focus-border-color);
}

.formActions {
  display: flex;
  justify-content: flex-end;
  gap: var(--spacing-sm);
  margin-top: var(--spacing-sm);
}

.cancelButton {
  padding: var(--button-padding-small);
  background-color: var(--button-tertiary-bg);
  color: var(--button-tertiary-text);
  border: 1px solid var(--button-tertiary-border);
  border-radius: var(--button-border-radius-md);
  font-size: var(--button-font-size-small);
  font-weight: var(--font-weight-medium);
  cursor: pointer;
  transition: background-color var(--transition-fast);
}

.cancelButton:hover {
  background-color: var(--button-tertiary-hover-bg);
}

.submitButton {
  padding: var(--button-padding-small);
  background-color: var(--button-primary-bg);
  color: var(--button-primary-text);
  border: none;
  border-radius: var(--button-border-radius-md);
  font-size: var(--button-font-size-small);
  font-weight: var(--font-weight-medium);
  cursor: pointer;
  transition: background-color var(--transition-fast);
}

.submitButton:hover {
  background-color: var(--button-primary-hover-bg);
}

@media (max-width: 768px) {
  .formRow {
    flex-direction: column;
    gap: var(--spacing-sm);
  }
  
  .actionButtons {
    flex-wrap: wrap;
  }
}