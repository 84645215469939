/* File: frontend/src/components/form/FormGroup/FormGroup.module.css */

.formGroup {
  border: none;
  padding: 0;
  margin: 0;
  width: 100%;
  position: relative;
}

.title {
  font-size: var(--font-size-lg);
  font-weight: var(--font-weight-semibold);
  color: var(--text-primary);
  margin: 0 0 var(--spacing-sm) 0;
  padding: 0;
  width: 100%;
  display: flex;
  align-items: center;
  gap: var(--spacing-xxs);
}

.required {
  color: var(--color-error);
  margin-left: var(--spacing-xxxs);
}

.description {
  font-size: var(--font-size-base);
  line-height: var(--line-height-normal);
  color: var(--text-secondary);
  margin: 0 0 var(--spacing-md) 0;
  padding: 0;
}

.fields {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-md);
  width: 100%;
}

/* Large screens (1200px and up) */
@media (min-width: 1200px) {
  .fields {
      gap: var(--spacing-lg);
  }
}

/* Medium-large screens (1024px and down) */
@media (max-width: 1024px) {
  .title {
      font-size: var(--font-size-md);
  }

  .description {
      font-size: var(--font-size-sm);
      margin-bottom: var(--spacing-sm);
  }

  .fields {
      gap: var(--spacing-md);
  }
}

/* Tablet screens (768px and down) */
@media (max-width: 768px) {
  .title {
      font-size: var(--font-size-base);
      margin-bottom: var(--spacing-xs);
  }

  .description {
      font-size: var(--font-size-sm);
      margin-bottom: var(--spacing-sm);
  }

  .fields {
      gap: var(--spacing-sm);
  }
}

/* Mobile screens (480px and down) */
@media (max-width: 480px) {
  .title {
      font-size: var(--font-size-sm);
  }

  .description {
      font-size: var(--font-size-xs);
      margin-bottom: var(--spacing-xs);
  }

  .fields {
      gap: var(--spacing-xs);
  }
}

/* Animation for error states */
@keyframes shake {
  0%, 100% { transform: translateX(0); }
  25% { transform: translateX(-4px); }
  75% { transform: translateX(4px); }
}

.error {
  animation: shake 0.4s ease-in-out;
}

/* Focus styles for accessibility */
.formGroup:focus-within {
  outline: none;
}

.formGroup:focus-within .title {
  color: var(--color-primary);
}

/* Print styles */
@media print {
  .formGroup {
      break-inside: avoid;
      page-break-inside: avoid;
  }

  .title {
      color: black;
  }

  .description {
      color: #666;
  }
}