/* File: frontend/src/components/Settings/SettingsModal.module.css */

.modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.75);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: var(--z-index-modal);
    backdrop-filter: blur(4px);
  }
  
  .modalContent {
    background-color: var(--color-gray-1300);
    border-radius: var(--border-radius-lg);
    width: 100%;
    max-width: 480px;
    max-height: 90vh;
    overflow-y: auto;
    margin: var(--spacing-md);
  }
  
  .modalHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: var(--spacing-lg);
    border-bottom: 1px solid var(--color-gray-1200);
  }
  
  .modalHeader h2 {
    font-size: var(--font-size-lg);
    font-weight: var(--font-weight-semibold);
    color: var(--color-white);
  }
  
  .closeButton {
    /* background: none; */
    background-color: var(--color-gray-200);
    border: none;
    padding: var(--spacing-sm);
    cursor: pointer;
    color: var(--color-gray-1300);
    cursor: pointer;
    border-radius: var(--border-radius-md);
    transition: all var(--transition-normal);
    background-color: var(--color-gray-100);
  }
  
  .modalBody {
    padding: var(--spacing-lg);
  }
  
  .settingSection {
    margin-bottom: var(--spacing-lg);
  }
  
  .settingSection h3 {
    font-size: var(--font-size-base);
    font-weight: var(--font-weight-medium);
    color: var(--color-gray-300);
    margin-bottom: var(--spacing-sm);
  }
  
  .buttonGroup {
    display: flex;
    gap: var(--spacing-sm);
  }
  
  .button {
    flex: 1;
    padding: var(--spacing-sm) var(--spacing-md);
    background-color: var(--color-gray-1200);
    border: none;
    border-radius: var(--border-radius-md);
    color: var(--color-gray-400);
    font-size: var(--font-size-sm);
    cursor: pointer;
    transition: all var(--transition-normal);
  }
  
  .button.active {
    background-color: var(--color-primary);
    color: var(--color-white);
  }
  
  .description {
    font-size: var(--font-size-sm);
    color: var(--color-gray-500);
    margin-top: var(--spacing-sm);
  }