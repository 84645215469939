/* File: frontend/src/components/JobApplicationForm/PositionSelect.module.css */

.selectPositionContainer {
    z-index: 3;
}

.positionSelect {
    position: relative;
    z-index: var(--z-index-dropdown);
}
  
.categoryHeader {
  width: 100%;
  padding: var(--dropdown-item-padding);
  background-color: var(--color-primary-600);
  color: var(--text-on-primary);
  font-size: var(--font-size-md);
  font-weight: var(--font-weight-bold);
  text-transform: uppercase;
  letter-spacing: 0.5px;
  pointer-events: none;
  user-select: none;
  padding-top: var(--spacing-sm);
  padding-bottom: var(--spacing-sm);
  margin-top: var(--spacing-xxs);
}

.optionContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: var(--dropdown-item-padding);
  color: var(--text-primary);
  cursor: pointer;
}

.optionLabel {
  flex: 1;
}

.checkIcon {
  width: 18px;
  height: 18px;
  flex-shrink: 0;
  opacity: 0.8;
}
  
.optionContainer:hover {
  background-color: var(--dropdown-item-hover-background);
}

/* Selected items styling */
.selectedItems {
  display: flex;
  flex-wrap: wrap;
  gap: var(--spacing-xs);
}

.selectedItem {
  background-color: var(--bg-tertiary);
  padding: var(--spacing-xs) var(--spacing-sm);
  border-radius: var(--border-radius-sm);
  font-size: var(--font-size-sm);
  display: inline-flex;
  align-items: center;
  gap: var(--spacing-xs);
}