/* File: frontend/src/pages/EmbeddedInsurance/components/common/styles/Header.module.css */

.header {
  text-align: center;
  margin-bottom: var(--spacing-md);
  padding-top: var(--spacing-md);
  max-width: 900px;
  margin-left: auto;
  margin-right: auto;
}

.titleContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: var(--spacing-sm);
  margin-bottom: var(--spacing-xs);
}

.title {
  font-size: var(--font-size-2xl);
  font-weight: var(--font-weight-bold);
  color: var(--text-primary);
  margin: 0;
}

.badge {
  padding: 0.15rem 0.5rem;
  border-radius: 9999px;
  font-size: var(--font-size-xs);
  font-weight: var(--font-weight-medium);
  text-transform: uppercase;
  letter-spacing: 0.025em;
  background-color: var(--color-primary-light);
  color: var(--color-primary-dark);
}

.description {
  font-size: var(--font-size-md);
  color: var(--text-secondary);
  max-width: 800px;
  margin: 0 auto;
  line-height: 1.4;
}

@media (max-width: 768px) {
  .header {
    padding-top: var(--spacing-sm);
  }
  
  .title {
    font-size: var(--font-size-xl);
  }
  
  .description {
    font-size: var(--font-size-sm);
  }
}