/* File: frontend/src/pages/TripInsight/components/TripMaps/TripMaps.module.css */

.container {
  background-color: var(--bg-primary);
  border-radius: var(--border-radius-lg);
  box-shadow: var(--shadow-card);
  padding: var(--spacing-lg);
  margin-bottom: var(--spacing-lg);
}

.header {
  margin-bottom: var(--spacing-md);
}

.title {
  font-size: var(--font-size-lg);
  font-weight: var(--font-weight-semibold);
  color: var(--text-primary);
  margin: 0 0 var(--spacing-xs) 0;
}

.description {
  color: var(--text-secondary);
  margin: 0;
  font-size: var(--font-size-sm);
}

.content {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-md);
}

.mapTypeSelector {
  display: flex;
  gap: var(--spacing-md);
  flex-wrap: wrap;
}

.mapTypeOption {
  flex: 1;
  min-width: 200px;
  display: flex;
  align-items: flex-start;
  gap: var(--spacing-sm);
  padding: var(--spacing-md);
  border-radius: var(--border-radius-md);
  background-color: var(--bg-secondary);
  border: 1px solid var(--color-border);
  cursor: pointer;
  transition: all var(--transition-fast);
}

.mapTypeOption:hover {
  background-color: var(--bg-hover);
}

.active {
  background-color: var(--color-primary-100);
  border-color: var(--color-primary);
}

.mapTypeIcon {
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--color-primary);
}

.mapTypeIcon svg {
  width: 24px;
  height: 24px;
}

.mapTypeContent {
  flex: 1;
}

.mapTypeTitle {
  font-weight: var(--font-weight-semibold);
  color: var(--text-primary);
  margin-bottom: var(--spacing-xxs);
}

.mapTypeDescription {
  font-size: var(--font-size-xs);
  color: var(--text-secondary);
}

.mapFilters {
  display: flex;
  flex-wrap: wrap;
  gap: var(--spacing-md);
  padding: var(--spacing-md);
  background-color: var(--bg-secondary);
  border-radius: var(--border-radius-md);
}

.filterGroup {
  display: flex;
  align-items: center;
  gap: var(--spacing-sm);
}

.filterLabel {
  font-weight: var(--font-weight-medium);
  color: var(--text-secondary);
  white-space: nowrap;
}

.filterOptions {
  display: flex;
  gap: var(--spacing-xs);
}

.filterOption {
  padding: var(--spacing-xs) var(--spacing-sm);
  background: none;
  border: 1px solid var(--color-border);
  border-radius: var(--border-radius-sm);
  font-size: var(--font-size-sm);
  color: var(--text-secondary);
  cursor: pointer;
  transition: all var(--transition-fast);
}

.filterOption:hover {
  background-color: var(--bg-hover);
}

.activeFilter {
  background-color: var(--color-primary);
  color: var(--color-white);
  border-color: var(--color-primary);
}

.checkboxLabel {
  display: flex;
  align-items: center;
  gap: var(--spacing-xs);
  cursor: pointer;
  color: var(--text-secondary);
}

.checkbox {
  width: 16px;
  height: 16px;
  cursor: pointer;
}

.mapContainer {
  height: 500px;
  background-color: var(--bg-secondary);
  border-radius: var(--border-radius-md);
  overflow: hidden;
  position: relative;
}

.mapLoading, .mapError {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.loadingSpinner {
  border: 3px solid var(--color-border);
  border-top: 3px solid var(--color-primary);
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 1s linear infinite;
  margin-bottom: var(--spacing-md);
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.mapError {
  color: var(--color-error);
  text-align: center;
  padding: var(--spacing-md);
}

.mockMap {
  width: 100%;
  height: 100%;
  background-color: #e8e8e8;
  position: relative;
  overflow: hidden;
}

.mockMapContent {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mockMapText {
  font-size: var(--font-size-lg);
  color: var(--text-tertiary);
  text-align: center;
  z-index: 10;
}

.mockMapSubtext {
  font-size: var(--font-size-sm);
  margin-top: var(--spacing-sm);
}

.mockProvinces {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.mockProvince {
  position: absolute;
  background-color: rgba(var(--color-primary-rgb), 0.3);
  border: 2px solid var(--color-primary);
  border-radius: 50%;
  width: 100px;
  height: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transform: translate(-50%, -50%);
}

.mockProvinceLabel {
  font-weight: var(--font-weight-semibold);
  color: var(--text-primary);
}

.mockProvinceCount {
  font-size: var(--font-size-xs);
  color: var(--text-secondary);
}

.mockPoints {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.mockPoint {
  position: absolute;
  background-color: var(--color-primary);
  border-radius: 50%;
  width: 8px;
  height: 8px;
  transform: translate(-50%, -50%);
}

.mockHeatmap {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.mockHeatArea {
  position: absolute;
  background: radial-gradient(circle, rgba(255,0,0,0.7) 0%, rgba(255,0,0,0) 70%);
  border-radius: 50%;
  transform: translate(-50%, -50%);
}

.mockAccidents {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 5;
}

.mockAccident {
  position: absolute;
  background-color: var(--color-error);
  border-radius: 50%;
  width: 12px;
  height: 12px;
  transform: translate(-50%, -50%);
}

.mapLegend {
  background-color: var(--bg-secondary);
  border-radius: var(--border-radius-md);
  padding: var(--spacing-md);
}

.legendTitle {
  font-weight: var(--font-weight-semibold);
  color: var(--text-primary);
  margin-bottom: var(--spacing-sm);
}

.legendItems {
  display: flex;
  flex-wrap: wrap;
  gap: var(--spacing-md);
}

.legendItem {
  display: flex;
  align-items: center;
  gap: var(--spacing-xs);
}

.legendColor {
  width: 16px;
  height: 16px;
  border-radius: 4px;
}

.highDensity {
  background-color: rgba(255, 0, 0, 0.8);
}

.mediumDensity {
  background-color: rgba(255, 165, 0, 0.8);
}

.lowDensity {
  background-color: rgba(255, 255, 0, 0.8);
}

.accident {
  background-color: var(--color-error);
}

.legendLabel {
  font-size: var(--font-size-sm);
  color: var(--text-secondary);
}

.mapStats {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: var(--spacing-md);
}

.statCard {
  background-color: var(--bg-secondary);
  border-radius: var(--border-radius-md);
  padding: var(--spacing-md);
  text-align: center;
}

.statValue {
  font-size: var(--font-size-xl);
  font-weight: var(--font-weight-bold);
  color: var(--text-primary);
  margin-bottom: var(--spacing-xxs);
}

.statLabel {
  font-size: var(--font-size-sm);
  color: var(--text-secondary);
}

@media (max-width: 992px) {
  .mapTypeSelector {
    flex-direction: column;
  }
  
  .mapFilters {
    flex-direction: column;
    align-items: flex-start;
  }
  
  .mapStats {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 576px) {
  .filterOptions {
    flex-wrap: wrap;
  }
  
  .mapStats {
    grid-template-columns: 1fr;
  }
}