/* File: frontend/src/pages/AutoInsight/VehicleDetail/components/VehicleMaintenance/VehicleMaintenance.module.css */

.container {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-md);
}

.grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: var(--spacing-md);
}

.card {
  background-color: var(--bg-primary);
  border-radius: var(--border-radius-md);
  border: 1px solid var(--color-border);
  overflow: hidden;
}

.cardTitle {
  font-size: var(--font-size-md);
  font-weight: var(--font-weight-semibold);
  padding: var(--spacing-sm);
  background-color: var(--bg-secondary);
  border-bottom: 1px solid var(--color-border);
  margin: 0;
}

.cardContent {
  padding: var(--spacing-sm);
}

.summaryGrid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: var(--spacing-md);
}

.summaryItem {
  display: flex;
  flex-direction: column;
}

.summaryLabel {
  font-weight: var(--font-weight-medium);
  color: var(--text-secondary);
  margin-bottom: var(--spacing-xxs);
}

.summaryValue {
  color: var(--text-primary);
  font-size: var(--font-size-lg);
  font-weight: var(--font-weight-semibold);
}

.upcomingList {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-md);
}

.upcomingItem {
  display: flex;
  gap: var(--spacing-md);
  padding: var(--spacing-sm);
  border-radius: var(--border-radius-md);
  background-color: var(--bg-secondary);
}

.upcomingDate {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 80px;
  padding: var(--spacing-sm);
  background-color: var(--color-primary-100);
  border-radius: var(--border-radius-sm);
  color: var(--color-primary);
  font-weight: var(--font-weight-semibold);
}

.upcomingDetails {
  flex: 1;
}

.upcomingType {
  font-weight: var(--font-weight-semibold);
  color: var(--text-primary);
  margin-bottom: var(--spacing-xxs);
}

.upcomingDescription {
  color: var(--text-secondary);
  margin-bottom: var(--spacing-xxs);
  font-size: var(--font-size-sm);
}

.upcomingProvider {
  color: var(--text-tertiary);
  font-size: var(--font-size-sm);
}

.tableContainer {
  overflow-x: auto;
}

.table {
  width: 100%;
  border-collapse: collapse;
}

.table th, .table td {
  padding: var(--spacing-sm);
  text-align: left;
  border-bottom: 1px solid var(--color-border);
}

.table th {
  background-color: var(--bg-secondary);
  font-weight: var(--font-weight-semibold);
  color: var(--text-secondary);
}

.table tr:hover {
  background-color: var(--bg-hover);
}

.noData {
  color: var(--text-secondary);
  font-style: italic;
  text-align: center;
  padding: var(--spacing-md);
}

.addMaintenanceContainer {
  display: flex;
  justify-content: center;
  margin-top: var(--spacing-md);
}

.addMaintenanceButton {
  padding: var(--button-padding-medium);
  background-color: var(--button-primary-bg);
  color: var(--button-primary-text);
  border: none;
  border-radius: var(--button-border-radius-md);
  font-size: var(--button-font-size-medium);
  font-weight: var(--font-weight-medium);
  cursor: pointer;
  transition: background-color var(--transition-fast);
}

.addMaintenanceButton:hover {
  background-color: var(--button-primary-hover-bg);
}

@media (max-width: 768px) {
  .grid, .summaryGrid {
    grid-template-columns: 1fr;
  }
}