/* File: frontend/src/pages/TripDetail/components/TripEvents/TripEvents.module.css */

.container {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-md);
}

.eventFilters {
  display: flex;
  flex-wrap: wrap;
  gap: var(--spacing-xs);
  background-color: var(--bg-secondary);
  padding: var(--spacing-sm);
  border-radius: var(--border-radius-md);
}

.filterButton {
  padding: var(--spacing-xs) var(--spacing-sm);
  background: none;
  border: 1px solid var(--color-border);
  border-radius: var(--border-radius-sm);
  font-size: var(--font-size-sm);
  color: var(--text-secondary);
  cursor: pointer;
  transition: all var(--transition-fast);
}

.filterButton:hover {
  background-color: var(--bg-hover);
}

.activeFilter {
  background-color: var(--color-primary);
  color: var(--color-white);
  border-color: var(--color-primary);
}

.eventSummary {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: var(--spacing-md);
}

.summaryCard {
  background-color: var(--bg-secondary);
  border-radius: var(--border-radius-md);
  padding: var(--spacing-md);
  text-align: center;
}

.summaryTitle {
  font-size: var(--font-size-sm);
  color: var(--text-secondary);
  margin-bottom: var(--spacing-xs);
}

.summaryValue {
  font-size: var(--font-size-2xl);
  font-weight: var(--font-weight-bold);
  color: var(--text-primary);
}

.eventsContainer {
  background-color: var(--bg-primary);
  border-radius: var(--border-radius-md);
  border: 1px solid var(--color-border);
  padding: var(--spacing-md);
}

.eventsGrid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: var(--spacing-md);
}

.eventCard {
  background-color: var(--bg-secondary);
  border-radius: var(--border-radius-md);
  overflow: hidden;
  border: 1px solid var(--color-border);
}

.eventHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: var(--spacing-sm);
  background-color: var(--bg-tertiary);
  border-bottom: 1px solid var(--color-border);
}

.eventType {
  font-weight: var(--font-weight-semibold);
  color: var(--text-primary);
}

.eventSeverity {
  padding: 2px 8px;
  border-radius: 4px;
  font-size: var(--font-size-xs);
  font-weight: var(--font-weight-medium);
}

.low {
  background-color: var(--color-success-bg);
  color: var(--color-success);
}

.medium {
  background-color: var(--color-warning-bg);
  color: var(--color-warning);
}

.high {
  background-color: var(--color-error-bg);
  color: var(--color-error);
}

.eventDetails {
  padding: var(--spacing-sm);
}

.eventDetail {
  display: flex;
  justify-content: space-between;
  margin-bottom: var(--spacing-xs);
}

.eventDetail:last-child {
  margin-bottom: 0;
}

.eventDetailLabel {
  font-weight: var(--font-weight-medium);
  color: var(--text-secondary);
}

.eventDetailValue {
  color: var(--text-primary);
  text-align: right;
}

.noEvents {
  background-color: var(--bg-secondary);
  border-radius: var(--border-radius-md);
  padding: var(--spacing-lg);
  text-align: center;
  color: var(--text-secondary);
}

@media (max-width: 768px) {
  .eventSummary {
    grid-template-columns: 1fr;
  }
}