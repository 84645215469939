/* File: frontend/src/pages/InvestorPitch/components/slides/MarketEntrySlide/MarketReadiness.module.css */

.container {
    background-color: var(--color-gray-1200);
    border-radius: var(--border-radius-lg);
    padding: var(--spacing-xl);
    border: 1px solid var(--color-gray-1100);
  }
  
  .metrics {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-xl);
    margin-bottom: var(--spacing-xl);
  }
  
  .metric {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-sm);
  }
  
  .metricHeader {
    display: flex;
    align-items: center;
    gap: var(--spacing-sm);
    margin-bottom: var(--spacing-xs);
  }
  
  .iconWrapper {
    width: 32px;
    height: 32px;
    border-radius: var(--border-radius-lg);
    background-color: var(--color-gray-1100);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .icon {
    width: 16px;
    height: 16px;
  }
  
  .metricTitle {
    font-size: var(--font-size-base);
    color: var(--text-on-primary);
  }
  
  .value {
    font-size: var(--font-size-sm);
    color: var(--color-gray-400);
    text-align: right;
  }
  
  .highlights {
    border-top: 1px solid var(--color-gray-1100);
    padding-top: var(--spacing-xl);
  }
  
  .highlightsTitle {
    font-size: var(--font-size-lg);
    font-weight: var(--font-weight-bold);
    color: var(--text-on-primary);
    margin-bottom: var(--spacing-md);
  }
  
  .highlightsList {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .highlightsList li {
    position: relative;
    padding-left: var(--spacing-lg);
    margin-bottom: var(--spacing-sm);
    color: var(--color-gray-400);
    font-size: var(--font-size-sm);
  }
  
  .highlightsList li::before {
    content: "•";
    position: absolute;
    left: 0;
    color: var(--color-primary-500);
  }
  
  @media (max-width: 768px) {
    .container {
      padding: var(--spacing-lg);
    }
  }