/* File: frontend/src/pages/NotFound/NotFound.module.css */

.notFoundContainer {
    max-width: var(--breakpoint-xl);
    margin: 0 auto;
    padding: clamp(2rem, 5vw, 4rem);
    font-family: var(--font-family-primary);
    color: var(--color-text);
  }
  
  .mainContent {
    text-align: center;
    margin-bottom: clamp(3rem, 8vw, 5rem);
  }
  
  .title {
    font-size: clamp(2.5rem, 6vw, 3.5rem);
    color: var(--color-primary);
    margin-bottom: clamp(1rem, 3vw, 1.5rem);
  }
  
  .description {
    font-size: clamp(1.1rem, 2.5vw, 1.3rem);
    margin-bottom: clamp(2rem, 5vw, 3rem);
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
  }
  
  .actionButtons {
    display: flex;
    gap: var(--spacing-md);
    justify-content: center;
    margin-bottom: clamp(2rem, 5vw, 3rem);
  }
  
  .expertAnalysis {
    background-color: var(--color-background-light);
    border-radius: var(--border-radius-lg);
    padding: clamp(2rem, 5vw, 3rem);
    margin-bottom: clamp(2rem, 5vw, 3rem);
    box-shadow: var(--box-shadow-sm);
  }
  
  .expertTitle {
    font-size: clamp(1.8rem, 4vw, 2.2rem);
    color: var(--color-primary-dark);
    text-align: center;
    margin-bottom: var(--spacing-sm);
  }
  
  .expertSubtitle {
    text-align: center;
    font-size: clamp(1.1rem, 2.5vw, 1.3rem);
    margin-bottom: clamp(2rem, 5vw, 3rem);
  }
  
  .reasonsGrid {
    display: grid;
    gap: var(--spacing-lg);
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  }
  
  .reasonCard {
    background-color: var(--color-background);
    padding: var(--spacing-lg);
    border-radius: var(--border-radius-md);
    box-shadow: var(--box-shadow-xs);
    transition: transform 0.2s ease;
  }
  
  .reasonCard:hover {
    transform: translateY(-4px);
  }
  
  .reasonTitle {
    color: var(--color-primary);
    font-size: clamp(1.1rem, 2.5vw, 1.3rem);
    margin-bottom: var(--spacing-sm);
  }
  
  .reasonDescription {
    font-size: 1rem;
    line-height: 1.6;
  }
  
  .apology {
    text-align: center;
    font-size: 1.1rem;
    color: var(--color-text-muted);
  }
  
  @media (prefers-color-scheme: dark) {
    .expertAnalysis {
      background-color: var(--color-background-dark-light);
    }
  
    .reasonCard {
      background-color: var(--color-background-dark);
    }
  }