/* File: frontend/src/styles/layout/grid.css */

.container {
    width: 100%;
    padding-right: var(--spacing-md);
    padding-left: var(--spacing-md);
    margin-right: auto;
    margin-left: auto;
  }
  
  @media (min-width: 576px) {
    .container {
      max-width: 540px;
    }
  }
  
  @media (min-width: 768px) {
    .container {
      max-width: 720px;
    }
  }
  
  @media (min-width: 992px) {
    .container {
      max-width: 960px;
    }
  }
  
  @media (min-width: 1200px) {
    .container {
      max-width: 1140px;
    }
  }
  
  .row {
    display: flex;
    flex-wrap: wrap;
    margin-right: calc(var(--spacing-md) * -1);
    margin-left: calc(var(--spacing-md) * -1);
  }
  
  .col {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
    padding-right: var(--spacing-md);
    padding-left: var(--spacing-md);
  }
  
  /* Add more column classes as needed */
  .col-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  
  .col-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  
  .col-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  
  /* Add responsive column classes */
  @media (min-width: 768px) {
    .col-md-6 {
      flex: 0 0 50%;
      max-width: 50%;
    }
  
    .col-md-4 {
      flex: 0 0 33.333333%;
      max-width: 33.333333%;
    }
  
    .col-md-3 {
      flex: 0 0 25%;
      max-width: 25%;
    }
  }