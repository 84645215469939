/* File: frontend/src/components/Card/Card.module.css */

.card {
    width: 100%;
    box-shadow: 0px 5px 3px rgba(0, 0, 0, 0.02), 0px 2px 2px rgba(0, 0, 0, 0.03), 0px 1px 1px rgba(0, 0, 0, 0.03);
    border-radius: var(--border-radius-lg);
    background-color: var(--color-white);
    border: 2px solid var(--bg-secondary);
    overflow: hidden;
    display: flex;
    flex-direction: column;
}
  
.card__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: var(--spacing-lg);
    border-bottom: 1px solid var(--bg-secondary);
}
  
.card__title {
  font-size: var(--font-size-lg);
  font-weight: var(--font-weight-medium);
  line-height: 24px;
  color: var(--text-primary);
}
  
.card__action-button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: var(--spacing-xs);
  border-radius: var(--border-radius-sm);
  background-color: var(--color-white);
  border: 1px solid var(--color-border);
  cursor: pointer;
}
  
.card__content {
  padding: var(--spacing-lg);
}
  
.card__footer {
  padding: var(--spacing-md) var(--spacing-lg);
  border-top: 1px solid var(--bg-secondary);
}

.card__row {
    display: flex;
    flex-wrap: wrap;
}

.card__column {
    flex: 1;
    min-width: 300px;
    padding: var(--spacing-md);
}

@media (max-width: 768px) {
    .card__column {
      flex: 1 0 100%;
    }
}