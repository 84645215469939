/* File: frontend/src/pages/PropertyBulkUpload/PropertyBulkUpload.module.css */

.container {
  padding: var(--spacing-lg);
  max-width: 1200px;
  margin: 0 auto;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: var(--spacing-lg);
}

.titleContainer {
  flex: 1;
}

.title {
  font-size: var(--font-size-xl);
  font-weight: var(--font-weight-bold);
  color: var(--text-primary);
  margin: 0 0 var(--spacing-xs) 0;
}

.subtitle {
  color: var(--text-secondary);
  margin: 0;
}

.backButton {
  padding: var(--button-padding-small);
  background-color: var(--button-secondary-bg);
  color: var(--button-secondary-text);
  border: 1px solid var(--button-secondary-border);
  border-radius: var(--button-border-radius-md);
  font-size: var(--button-font-size-small);
  font-weight: var(--font-weight-medium);
  cursor: pointer;
  transition: background-color var(--transition-fast);
}

.backButton:hover {
  background-color: var(--button-secondary-hover-bg);
}

.uploadContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: var(--spacing-lg);
}

.uploadInstructions {
  background-color: var(--bg-primary);
  border-radius: var(--border-radius-lg);
  box-shadow: var(--shadow-card);
  padding: var(--spacing-lg);
}

.instructionsTitle {
  font-size: var(--font-size-lg);
  font-weight: var(--font-weight-semibold);
  color: var(--text-primary);
  margin: 0 0 var(--spacing-md) 0;
}

.instructionsList {
  margin: 0 0 var(--spacing-lg) 0;
  padding-left: var(--spacing-lg);
}

.instructionsList li {
  margin-bottom: var(--spacing-sm);
  color: var(--text-primary);
}

.templateSection {
  background-color: var(--bg-secondary);
  border-radius: var(--border-radius-md);
  padding: var(--spacing-md);
  margin-top: var(--spacing-md);
}

.templateTitle {
  font-size: var(--font-size-base);
  font-weight: var(--font-weight-semibold);
  color: var(--text-primary);
  margin: 0 0 var(--spacing-sm) 0;
}

.templateDescription {
  color: var(--text-secondary);
  margin: 0 0 var(--spacing-md) 0;
  font-size: var(--font-size-sm);
}

.templateLink {
  display: inline-block;
  padding: var(--button-padding-small);
  background-color: var(--button-primary-bg);
  color: var(--button-primary-text);
  border-radius: var(--button-border-radius-md);
  font-size: var(--button-font-size-small);
  font-weight: var(--font-weight-medium);
  text-decoration: none;
  transition: background-color var(--transition-fast);
}

.templateLink:hover {
  background-color: var(--button-primary-hover-bg);
}

.uploadForm {
  background-color: var(--bg-primary);
  border-radius: var(--border-radius-lg);
  box-shadow: var(--shadow-card);
  padding: var(--spacing-lg);
}

.uploadTitle {
  font-size: var(--font-size-lg);
  font-weight: var(--font-weight-semibold);
  color: var(--text-primary);
  margin: 0 0 var(--spacing-md) 0;
}

.fileInputContainer {
  margin-bottom: var(--spacing-md);
}

.fileInputLabel {
  display: block;
  width: 100%;
  padding: var(--spacing-md);
  background-color: var(--bg-secondary);
  border: 2px dashed var(--color-border);
  border-radius: var(--border-radius-md);
  text-align: center;
  cursor: pointer;
  transition: border-color var(--transition-fast);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.fileInputLabel:hover {
  border-color: var(--color-primary);
}

.fileInput {
  display: none;
}

.error {
  background-color: var(--color-error-bg);
  color: var(--color-error);
  padding: var(--spacing-sm);
  border-radius: var(--border-radius-md);
  margin-bottom: var(--spacing-md);
}

.progressContainer {
  margin-bottom: var(--spacing-md);
}

.progressBar {
  width: 100%;
  height: 8px;
  background-color: var(--bg-secondary);
  border-radius: 4px;
  overflow: hidden;
  margin-bottom: var(--spacing-xs);
}

.progressFill {
  height: 100%;
  background-color: var(--color-primary);
  transition: width 0.3s ease;
}

.progressText {
  text-align: right;
  font-size: var(--font-size-sm);
  color: var(--text-secondary);
}

.uploadButton {
  width: 100%;
  padding: var(--button-padding-medium);
  background-color: var(--button-primary-bg);
  color: var(--button-primary-text);
  border: none;
  border-radius: var(--button-border-radius-md);
  font-size: var(--button-font-size-medium);
  font-weight: var(--font-weight-medium);
  cursor: pointer;
  transition: background-color var(--transition-fast);
}

.uploadButton:hover:not(:disabled) {
  background-color: var(--button-primary-hover-bg);
}

.uploadButton:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.resultsContainer {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-lg);
}

.resultsSummary {
  background-color: var(--bg-primary);
  border-radius: var(--border-radius-lg);
  box-shadow: var(--shadow-card);
  padding: var(--spacing-lg);
  text-align: center;
}

.resultsTitle {
  font-size: var(--font-size-lg);
  font-weight: var(--font-weight-semibold);
  color: var(--text-primary);
  margin: 0 0 var(--spacing-md) 0;
}

.resultsStats {
  display: flex;
  justify-content: space-around;
  margin-bottom: var(--spacing-lg);
}

.resultsStat {
  text-align: center;
}

.resultsStatLabel {
  font-size: var(--font-size-sm);
  color: var(--text-secondary);
  margin-bottom: var(--spacing-xs);
}

.resultsStatValue {
  font-size: var(--font-size-2xl);
  font-weight: var(--font-weight-bold);
  color: var(--text-primary);
}

.successValue {
  color: var(--color-success);
}

.failedValue {
  color: var(--color-error);
}

.resultsActions {
  display: flex;
  justify-content: center;
  gap: var(--spacing-md);
}

.newUploadButton {
  padding: var(--button-padding-medium);
  background-color: var(--button-secondary-bg);
  color: var(--button-secondary-text);
  border: 1px solid var(--button-secondary-border);
  border-radius: var(--button-border-radius-md);
  font-size: var(--button-font-size-medium);
  font-weight: var(--font-weight-medium);
  cursor: pointer;
  transition: background-color var(--transition-fast);
}

.newUploadButton:hover {
  background-color: var(--button-secondary-hover-bg);
}

.viewPropertiesButton {
  padding: var(--button-padding-medium);
  background-color: var(--button-primary-bg);
  color: var(--button-primary-text);
  border: none;
  border-radius: var(--button-border-radius-md);
  font-size: var(--button-font-size-medium);
  font-weight: var(--font-weight-medium);
  cursor: pointer;
  transition: background-color var(--transition-fast);
}

.viewPropertiesButton:hover {
  background-color: var(--button-primary-hover-bg);
}

.errorsContainer {
  background-color: var(--bg-primary);
  border-radius: var(--border-radius-lg);
  box-shadow: var(--shadow-card);
  padding: var(--spacing-lg);
}

.errorsTitle {
  font-size: var(--font-size-lg);
  font-weight: var(--font-weight-semibold);
  color: var(--text-primary);
  margin: 0 0 var(--spacing-md) 0;
}

.errorsTable {
  margin-bottom: var(--spacing-md);
  overflow-x: auto;
}

.table {
  width: 100%;
  border-collapse: collapse;
}

.table th, .table td {
  padding: var(--spacing-sm);
  text-align: left;
  border-bottom: 1px solid var(--color-border);
}

.table th {
  background-color: var(--bg-secondary);
  font-weight: var(--font-weight-semibold);
  color: var(--text-secondary);
}

.downloadErrorsContainer {
  display: flex;
  justify-content: center;
  margin-top: var(--spacing-md);
}

.downloadErrorsButton {
  padding: var(--button-padding-small);
  background-color: var(--button-secondary-bg);
  color: var(--button-secondary-text);
  border: 1px solid var(--button-secondary-border);
  border-radius: var(--button-border-radius-md);
  font-size: var(--button-font-size-small);
  font-weight: var(--font-weight-medium);
  cursor: pointer;
  transition: background-color var(--transition-fast);
}

.downloadErrorsButton:hover {
  background-color: var(--button-secondary-hover-bg);
}

@media (max-width: 768px) {
  .header {
    flex-direction: column;
    align-items: flex-start;
  }
  
  .backButton {
    margin-top: var(--spacing-sm);
  }
  
  .uploadContainer {
    grid-template-columns: 1fr;
  }
  
  .resultsStats {
    flex-direction: column;
    gap: var(--spacing-md);
  }
  
  .resultsActions {
    flex-direction: column;
    gap: var(--spacing-sm);
  }
  
  .newUploadButton, .viewPropertiesButton {
    width: 100%;
  }
}