/* File: frontend/src/pages/TripInsight/components/ReportGenerator/ReportGenerator.module.css */

.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  padding: var(--spacing-md);
}

.modalContainer {
  background-color: var(--bg-primary);
  border-radius: var(--border-radius-lg);
  box-shadow: var(--shadow-card);
  width: 100%;
  max-width: 800px;
  max-height: 90vh;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}

.modalHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: var(--spacing-md);
  border-bottom: 1px solid var(--color-border);
}

.modalTitle {
  font-size: var(--font-size-lg);
  font-weight: var(--font-weight-semibold);
  color: var(--text-primary);
  margin: 0;
}

.closeButton {
  background: none;
  border: none;
  cursor: pointer;
  color: var(--text-secondary);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: var(--spacing-xs);
}

.closeButton svg {
  width: 20px;
  height: 20px;
}

.modalContent {
  padding: var(--spacing-md);
}

.reportForm {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-md);
}

.errorMessage {
  background-color: var(--color-error-bg);
  color: var(--color-error);
  padding: var(--spacing-md);
  border-radius: var(--border-radius-md);
  margin-bottom: var(--spacing-md);
}

.formSection {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-md);
}

.sectionTitle {
  font-size: var(--font-size-md);
  font-weight: var(--font-weight-semibold);
  color: var(--text-primary);
  margin: 0;
}

.reportTypeOptions {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: var(--spacing-md);
}

.reportTypeOption {
  display: flex;
  align-items: flex-start;
  gap: var(--spacing-sm);
  padding: var(--spacing-md);
  border-radius: var(--border-radius-md);
  background-color: var(--bg-secondary);
  border: 1px solid var(--color-border);
  cursor: pointer;
  transition: all var(--transition-fast);
}

.reportTypeOption:hover {
  background-color: var(--bg-hover);
}

.selectedType {
  background-color: var(--color-primary-100);
  border-color: var(--color-primary);
}

.radioInput {
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
}

.reportTypeIcon {
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--color-primary);
}

.reportTypeIcon svg {
  width: 24px;
  height: 24px;
}

.reportTypeContent {
  flex: 1;
}

.reportTypeName {
  font-weight: var(--font-weight-semibold);
  color: var(--text-primary);
  margin-bottom: var(--spacing-xxs);
}

.reportTypeDescription {
  font-size: var(--font-size-xs);
  color: var(--text-secondary);
}

.formRow {
  display: flex;
  gap: var(--spacing-md);
}

.formGroup {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xs);
}

.formLabel {
  font-weight: var(--font-weight-medium);
  color: var(--text-secondary);
}

.formSelect {
  padding: var(--spacing-sm);
  border: 1px solid var(--color-border);
  border-radius: var(--border-radius-md);
  background-color: var(--bg-primary);
  color: var(--text-primary);
}

.checkboxContainer {
  display: flex;
  align-items: center;
}

.checkboxLabel {
  display: flex;
  align-items: center;
  gap: var(--spacing-xs);
  cursor: pointer;
  color: var(--text-primary);
}

.checkbox {
  width: 16px;
  height: 16px;
  cursor: pointer;
}

.sectionHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.selectionActions {
  display: flex;
  gap: var(--spacing-xs);
}

.selectionAction {
  background: none;
  border: none;
  color: var(--color-primary);
  font-size: var(--font-size-sm);
  cursor: pointer;
  padding: var(--spacing-xxs) var(--spacing-xs);
}

.selectionAction:hover {
  text-decoration: underline;
}

.selectionGrid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: var(--spacing-sm);
  background-color: var(--bg-secondary);
  border-radius: var(--border-radius-md);
  padding: var(--spacing-md);
}

.selectionItem {
  display: flex;
  align-items: center;
  gap: var(--spacing-xs);
  cursor: pointer;
  color: var(--text-primary);
}

.formActions {
  display: flex;
  justify-content: flex-end;
  gap: var(--spacing-md);
  margin-top: var(--spacing-md);
}

.cancelButton {
  padding: var(--button-padding-medium);
  background-color: var(--button-secondary-bg);
  color: var(--button-secondary-text);
  border: 1px solid var(--button-secondary-border);
  border-radius: var(--button-border-radius-md);
  font-size: var(--button-font-size-medium);
  font-weight: var(--font-weight-medium);
  cursor: pointer;
  transition: background-color var(--transition-fast);
}

.cancelButton:hover:not(:disabled) {
  background-color: var(--button-secondary-hover-bg);
}

.submitButton {
  padding: var(--button-padding-medium);
  background-color: var(--button-primary-bg);
  color: var(--button-primary-text);
  border: none;
  border-radius: var(--button-border-radius-md);
  font-size: var(--button-font-size-medium);
  font-weight: var(--font-weight-medium);
  cursor: pointer;
  transition: background-color var(--transition-fast);
  display: flex;
  align-items: center;
  gap: var(--spacing-xs);
}

.submitButton:hover:not(:disabled) {
  background-color: var(--button-primary-hover-bg);
}

.submitButton:disabled, .cancelButton:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.buttonSpinner {
  width: 16px;
  height: 16px;
  border: 2px solid rgba(255, 255, 255, 0.3);
  border-top: 2px solid var(--color-white);
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.successMessage {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: var(--spacing-xl);
  text-align: center;
}

.successIcon {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: var(--color-success);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 32px;
  margin-bottom: var(--spacing-md);
}

.successTitle {
  font-size: var(--font-size-lg);
  font-weight: var(--font-weight-semibold);
  color: var(--text-primary);
  margin-bottom: var(--spacing-sm);
}

.successText {
  color: var(--text-secondary);
  max-width: 500px;
}

@media (max-width: 768px) {
  .formRow {
    flex-direction: column;
  }
  
  .reportTypeOptions {
    grid-template-columns: 1fr;
  }
}