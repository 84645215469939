/* File: frontend/src/pages/InvestorPitch/components/slides/GrowthSlide/GrowthIndicator.module.css */

.container {
  background-color: var(--color-gray-1200);
  border-radius: var(--border-radius-lg);
  padding: var(--spacing-xl);
  text-align: center;
  border: 1px solid var(--color-gray-1100);
  min-width: 240px;
}

.header {
  text-align: center;
}

.year {
  font-size: var(--font-size-lg);
  color: var(--text-on-primary);
  margin-bottom: var(--spacing-md);
  font-weight: var(--font-weight-medium);
}

.metrics {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-md);
  margin: var(--spacing-lg) 0;
}

.target {
  text-align: center;
  margin-bottom: var(--spacing-md);
}

.value {
  font-size: var(--font-size-4xl);
  font-weight: var(--font-weight-bold);
  line-height: 1;
  margin-bottom: var(--spacing-xs);
}

.percentage {
  font-size: var(--font-size-lg);
  font-weight: var(--font-weight-medium);
  margin-bottom: var(--spacing-xs);
}

.projectedValue {
  font-size: var(--font-size-2xl);
  font-weight: var(--font-weight-bold);
  margin-bottom: var(--spacing-xs);
}

.label {
  font-size: var(--font-size-sm);
  color: var(--color-gray-400);
}

.revenue {
  border-top: 1px solid var(--color-gray-1100);
  padding-top: var(--spacing-md);
  margin-top: var(--spacing-md);
}

@media (max-width: 768px) {
  .container {
    padding: var(--spacing-lg);
    min-width: unset;
    width: 100%;
  }

  .value {
    font-size: var(--font-size-3xl);
  }

  .projectedValue {
    font-size: var(--font-size-xl);
  }
}