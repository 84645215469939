/* File: frontend/src/pages/InvestorPitch/components/slides/InvestmentSlide/InvestmentSlide.module.css */

.container {
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: var(--spacing-xl);
  }
  
  .header {
    text-align: center;
    margin-bottom: var(--spacing-xl);
  }
  
  .title {
    font-size: var(--font-size-5xl);
    font-weight: var(--font-weight-bold);
    color: var(--text-on-primary);
    margin-bottom: var(--spacing-md);
    background: linear-gradient(to right, var(--color-primary-500), var(--color-primary-700));
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
  }
  
  .subtitle {
    font-size: var(--font-size-xl);
    color: var(--color-gray-400);
  }
  
  .content {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-3xl);
  }
  
  .sectionTitle {
    font-size: var(--font-size-2xl);
    font-weight: var(--font-weight-bold);
    color: var(--text-on-primary);
    margin-bottom: var(--spacing-xl);
  }
  
  .fundingGrid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: var(--spacing-xl);
  }
  
  .projectionsGrid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: var(--spacing-xl);
  }
  
  @media (max-width: 768px) {
    .fundingGrid,
    .projectionsGrid {
      grid-template-columns: 1fr;
      gap: var(--spacing-lg);
    }
  
    .title {
      font-size: var(--font-size-4xl);
    }
  }