/* charts/v2/core/ChartWrapper.module.css */
.chartContainer {
  /* padding: var(--spacing-xl); */
  background: var(--color-gray-0);
  border-radius: var(--border-radius-lg);
  box-shadow: var(--box-shadow-sm);
}

.chartTitle {
  font-size: var(--font-size-xl);
  color: var(--color-primary);
  margin-bottom: var(--spacing-md);
  font-weight: 600;
}

.chartSubtitle {
  font-size: var(--font-size-base);
  color: var(--color-gray-900);
  margin-bottom: var(--spacing-lg);
}