/* File: frontend/src/components/layout/DashboardLayout/DashboardLayout.module.css */

.dashboardLayout {
  display: flex;
  min-height: 100vh;
  /* Add support for iOS */
  min-height: -webkit-fill-available;
  background-color: var(--bg-secondary);
  position: relative;
}

.sidebarWrapper {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  z-index: var(--z-index-fixed);
  width: 72px;
  transition: width var(--transition-normal);
}

.sidebarExpanded .sidebarWrapper {
  width: 280px;
}

.mainContent {
  flex: 1;
  margin-left: 72px; /* Keep this fixed */
  transition: none; /* Remove margin transition */
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.sidebarExpanded .mainContent {
  margin-left: 72px;
}

.contentArea {
  flex: 1;
  padding: var(--spacing-lg) var(--spacing-lg) 0 var(--spacing-lg);
  background-color: var(--bg-secondary);
}

.contentWrapper {
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
}

/* Mobile styles */
@media (max-width: 768px) {
  .dashboardLayout {
    overflow-x: hidden;
    padding-top: env(safe-area-inset-top, 0);
    padding-bottom: env(safe-area-inset-bottom, 0);
  }

  .sidebarWrapper {
    transform: translateX(-100%);
    width: 280px;
    pointer-events: none;
    z-index: var(--z-index-modal);
  }

  .mobileMenuOpen .sidebarWrapper {
    transform: translateX(0);
    pointer-events: auto;
    box-shadow: var(--shadow-lg);
  }

  .mainContent {
    margin-left: 0;
    padding-bottom: env(safe-area-inset-bottom, 0);
  }

  .mobileMenuOpen .mainContent {
    margin-left: 0;
  }

  .contentArea {
    padding: var(--spacing-md);
  }

  /* Backdrop */
  .mobileMenuOpen::before {
    content: '';
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: var(--z-index-modal-backdrop);
    pointer-events: auto;
  }
}

@media (max-width: 480px) {
  .contentArea {
    padding: var(--spacing-sm);
  }
}