/* File: frontend/src/pages/Status/components/MetricsDisplay.module.css */

.container {
    background: var(--bg-primary);
    border-radius: var(--border-radius-lg);
    padding: var(--spacing-md);
    margin-bottom: var(--spacing-md);
    box-shadow: var(--shadow-sm);
}

.title {
    margin: 0 0 var(--spacing-md) 0;
    font-size: var(--font-size-lg);
    font-weight: var(--font-weight-semibold);
    color: var(--text-primary);
}

.metricsGrid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: var(--spacing-md);
}

.metricCard {
    background: var(--bg-secondary);
    border-radius: var(--border-radius-lg);
    padding: var(--spacing-md);
    display: flex;
    gap: var(--spacing-sm);
    transition: var(--transition-fast);
}

.metricCard:hover {
    transform: translateY(-2px);
}

.metricIcon {
    width: 48px;
    height: 48px;
    border-radius: var(--border-radius-full);
    background: var(--color-primary-100);
    color: var(--color-primary-700);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: var(--font-size-xl);
}

.metricContent {
    flex: 1;
}

.metricContent h3 {
    margin: 0 0 var(--spacing-xxs) 0;
    font-size: var(--font-size-sm);
    font-weight: var(--font-weight-semibold);
    color: var(--text-secondary);
    text-transform: uppercase;
}

.value {
    font-size: var(--font-size-xl);
    font-weight: var(--font-weight-bold);
    color: var(--text-primary);
    margin-bottom: var(--spacing-xxxs);
}

.label {
    font-size: var(--font-size-sm);
    color: var(--text-secondary);
}

.serviceHealth {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-xxs);
}

.healthItem {
    display: flex;
    align-items: center;
    gap: var(--spacing-xxs);
    font-size: var(--font-size-sm);
    color: var(--text-primary);
}

.healthDot {
    width: 8px;
    height: 8px;
    border-radius: var(--border-radius-full);
}

.healthy {
    background-color: var(--color-success);
}

.degraded {
    background-color: var(--color-warning);
}

.error {
    background-color: var(--color-error);
}

.awsSummary {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-xxs);
}

.awsItem {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: var(--font-size-sm);
}

.awsItem span:first-child {
    color: var(--text-secondary);
}

.awsItem span:last-child {
    font-weight: var(--font-weight-medium);
    color: var(--text-primary);
}

.loading {
    padding: var(--spacing-lg);
    text-align: center;
    color: var(--text-secondary);
}

@media (max-width: 768px) {
    .metricsGrid {
        grid-template-columns: 1fr;
    }
}