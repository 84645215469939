/* src/components/KnowledgeTransfer/TemplateManager.module.css */
.templateManager {
    background-color: #e6f7ff;
    border-radius: 8px;
    padding: 20px;
    margin-bottom: 20px;
  }
  
  .templateManager h3 {
    margin-top: 0;
  }
  
  .templateManager select {
    width: 100%;
    padding: 5px;
    margin-bottom: 10px;
  }
  
  .templateManager button {
    background-color: #1890ff;
    color: white;
    border: none;
    padding: 10px 15px;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .templateManager button:hover {
    background-color: #40a9ff;
  }