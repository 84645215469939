/* File: frontend/src/components/Home/MigrationSection.module.css */

.migrationSection {
  min-height: 100vh;
  background-color: var(--color-black);
  color: var(--color-white);
  display: flex;
  align-items: center;
  padding: var(--spacing-lg) 0;
  box-sizing: border-box;
}

.container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: var(--spacing-lg);
  width: 100%;
  max-width: var(--breakpoint-xl);
  margin: 0 auto;
  padding: 0 var(--spacing-lg);
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.title {
  font-size: clamp(2rem, 5vw, 3rem);
  margin-bottom: var(--spacing-md);
  line-height: 1.355;
}

.description {
  font-size: clamp(1rem, 2vw, 1.25rem);
  line-height: 1.45;
  margin-bottom: var(--spacing-lg);
}

.ctaButton {
  background-color: transparent;
  color: var(--color-primary);
  transition: background-color var(--transition-normal), color var(--transition-normal);
  padding: clamp(0.5rem, 2vw, 1rem) clamp(1rem, 4vw, 2rem);
  font-size: clamp(0.875rem, 2vw, 1rem);
  min-width: 150px;
  align-self: flex-start;
}

.ctaButton:hover {
  background-color: var(--color-primary);
  color: var(--color-white);
}

.ctaButton:disabled {
  background-color: var(--color-primary-light);
  border-color: var(--color-primary-light);
  cursor: not-allowed;
}

.animationWrapper {
  position: relative;
  width: 100%;
  margin-bottom: var(--spacing-lg);
  min-height: calc(clamp(300px, 50vh, 500px) + 60px); /* Add space for controls */
}

.animationContainer {
  width: 100%;
  height: clamp(300px, 58vh, 500px);
  /* height: 100%; */
  position: relative;
  overflow: visible;
  border-radius: var(--border-radius-lg);
  background-color: var(--color-black);
  margin-bottom: 60px;
  contain: paint;
  content-visibility: auto;
  contain-intrinsic-size: 300px;
}

.particleCanvas {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  touch-action: pan-y;
  pointer-events: none;
  transform: translateZ(0);
  -webkit-transform: translateZ(0);
  will-change: transform;
  image-rendering: -webkit-optimize-contrast;
  image-rendering: crisp-edges;
  overflow: hidden;
  border-radius: var(--border-radius-lg);
}

.controls {
  position: absolute;
  display: flex;
  gap: var(--spacing-xs);
  right: 0;
  bottom: 0;
  z-index: 10;
  padding: var(--spacing-sm);
}

.controls button {
  width: 36px;
  height: 36px;
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: var(--color-white);
  transition: all 0.3s ease;
  padding: 0;
  opacity: 0.7; /* Added back the opacity */
}

.controls button:hover {
  opacity: 1;
}

.animationPlaceholder {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--color-black);
  color: var(--color-white);
  font-size: var(--font-size-lg);
}

.loadingIndicator {
  position: relative;
  display: inline-block;
  opacity: 0;
  animation: fadeIn 0.3s ease-in forwards;
  animation-delay: 0.5s;
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@media (max-width: 1024px) {
  .animationWrapper {
    margin-bottom: var(--spacing-md); /* Less space on mobile */
    min-height: calc(clamp(250px, 40vh, 400px) + 50px);
  }

  .animationContainer {
    height: clamp(275px, 58vh, 450px);
    contain: strict;
    will-change: transform;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    margin-bottom: 50px;
  }

  .particleCanvas {
    height: 100%;
    transform: translateZ(0);
    -webkit-transform: translateZ(0);
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    perspective: 1000;
    -webkit-perspective: 1000;
  }

  .migrationSection {
    padding: var(--spacing-md) 0;
  }

  .container {
    grid-template-columns: 1fr;
  }

  .content {
    text-align: center;
  }

  .ctaButton {
    align-self: center;
  }
}

@media (max-width: 768px) {
  .animationWrapper {
    margin-bottom: var(--spacing-md); /* Less space on mobile */
    min-height: calc(clamp(250px, 40vh, 400px) + 50px);
  }

  .animationContainer {
    height: clamp(275px, 58vh, 450px);
    contain: strict;
    will-change: transform;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    margin-bottom: 50px;
  }

  .particleCanvas {
    height: 100%;
    transform: translateZ(0);
    -webkit-transform: translateZ(0);
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    perspective: 1000;
    -webkit-perspective: 1000;
  }

  .migrationSection {
    padding: var(--spacing-md) 0;
  }

  .container {
    padding: 0 var(--spacing-md);
  }

  .controls {
    /* bottom: -42px; */
    padding: var(--spacing-xs);
  }

  .controls button {
    width: 32px;
    height: 32px;
  }
}

@media (max-aspect-ratio: 1/1) {
  .container {
    grid-template-columns: 1fr;
  }
}

@media (prefers-reduced-motion: reduce) {
  .ctaButton,
  .controls button {
    transition: none;
  }
}