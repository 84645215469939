/* File: frontend/src/pages/InvestorPitch/components/slides/ProductEvolutionSlide/CapabilityMatrix.module.css */

.container {
  background-color: var(--color-gray-1200);
  border-radius: var(--border-radius-lg);
  padding: var(--spacing-md);
  border: 1px solid var(--color-gray-1100);
  height: 100%;
  display: flex;
  flex-direction: column;
}

.tableWrapper {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  flex: 1;
}

.matrix {
  width: 100%;
  border-collapse: collapse;
  min-width: 500px;
}

.headerCell {
  text-align: left;
  padding: var(--spacing-sm);
  color: var(--text-on-primary);
  font-weight: var(--font-weight-bold);
  font-size: var(--font-size-base);
  border-bottom: 1px solid var(--color-gray-1100);
}

.categoryRow {
  background-color: rgba(35, 72, 237, 0.1);
}

.categoryCell {
  padding: var(--spacing-sm);
  color: var(--color-primary-500);
  font-weight: var(--font-weight-bold);
  font-size: var(--font-size-base);
}

.featureRow:nth-child(even) {
  background-color: rgba(20, 20, 20, 0.2);
}

.featureCell {
  padding: var(--spacing-sm);
  color: var(--color-gray-300);
  font-size: var(--font-size-sm);
}

.valueCell {
  padding: var(--spacing-sm);
  text-align: center;
}

.checkIcon {
  width: 18px;
  height: 18px;
  color: var(--color-success);
}

.minusIcon {
  width: 18px;
  height: 18px;
  color: var(--color-gray-600);
}

@media (max-width: 992px) {
  .headerCell, .categoryCell {
    font-size: var(--font-size-sm);
  }
  
  .featureCell {
    font-size: var(--font-size-xs);
  }
  
  .checkIcon, .minusIcon {
    width: 16px;
    height: 16px;
  }
}

@media (max-width: 768px) {
  .container {
    padding: var(--spacing-sm);
  }
  
  .headerCell, .categoryCell, .featureCell, .valueCell {
    padding: var(--spacing-xs);
  }
}

@media (max-width: 480px) {
  .checkIcon, .minusIcon {
    width: 14px;
    height: 14px;
  }
}