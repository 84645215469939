/* File: frontend/src/pages/EmbeddedInsurance/components/ProductBuilder/styles/CoverageSelector.module.css */

.coverageSelector {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xl);
}

.sectionHeader {
  text-align: center;
  margin-bottom: var(--spacing-lg);
}

.sectionTitle {
  font-size: var(--font-size-xl);
  font-weight: var(--font-weight-bold);
  color: var(--text-primary);
  margin-bottom: var(--spacing-sm);
}

.sectionDescription {
  color: var(--text-secondary);
  max-width: 600px;
  margin: 0 auto;
}

.selectionTitle {
  font-size: var(--font-size-lg);
  font-weight: var(--font-weight-semibold);
  color: var(--text-primary);
  margin-bottom: var(--spacing-md);
  text-align: center;
}

/* Enhanced Wheel Styles */
.wheelContainer {
  position: relative;
  width: 100%;
  height: 350px;
  margin: var(--spacing-xl) 0;
  perspective: 1500px;
  transform-style: preserve-3d;
  overflow: visible; /* Allow the larger card to extend beyond container */
}

.productWheel {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  overflow-x: auto;
  scroll-behavior: smooth;
  scrollbar-width: none; /* Hide scrollbar for Firefox */
  -ms-overflow-style: none; /* Hide scrollbar for IE and Edge */
  -webkit-overflow-scrolling: touch; /* For smooth scrolling on iOS */
  padding: 50px 0;
  transform-style: preserve-3d;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.productWheel::-webkit-scrollbar {
  display: none;
}

.wheelTrack {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 0 25%;
  min-width: max-content;
  transform-style: preserve-3d;
  position: relative;
}

.wheelButton {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 200;
  background-color: var(--color-primary);
  color: white;
  border: none;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease;
}

.wheelButton:hover {
  transform: translateY(-50%) scale(1.05);
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.25);
}

.wheelButton:focus {
  outline: none;
}

.prevButton {
  left: 10px;
}

.nextButton {
  right: 10px;
}

.wheelPagination {
  display: flex;
  justify-content: center;
  gap: var(--spacing-xs);
  margin-top: var(--spacing-md);
}

.paginationDot {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: var(--color-border);
  border: none;
  padding: 0;
  cursor: pointer;
  transition: all 0.2s ease;
}

.activeDot {
  background-color: var(--color-primary);
  transform: scale(1.3);
}

/* Enhanced Product Card Styles */
.productCard {
  position: absolute;
  width: 240px; /* Increased width */
  height: 220px; /* Increased height */
  padding: var(--spacing-lg);
  background-color: var(--bg-primary);
  border: 1px solid var(--color-border);
  border-radius: var(--border-radius-lg);
  text-align: center;
  cursor: pointer;
  transition: all 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05);
  will-change: transform, opacity;
  transform-style: preserve-3d;
  backface-visibility: hidden;
}

/* Adjust size of content for the center card */
.activeCard .productIcon {
  transform: scale(1.2);
  margin-bottom: calc(var(--spacing-md) + 4px);
}

.activeCard .productName {
  font-size: calc(var(--font-size-md) + 2px);
  margin-bottom: calc(var(--spacing-sm) + 2px);
}

.hiddenCard {
  pointer-events: none;
  opacity: 0 !important;
}

.productCard:hover {
  border-color: var(--color-primary-light);
  /* Only apply hover effects to the active/center card */
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
}

/* Only apply transform effect on hover to the active card */
.activeCard:hover {
  transform: translateZ(10px) !important;
}

.activeCard {
  border: 2px solid var(--color-primary);
  box-shadow: 0 12px 30px rgba(0, 0, 0, 0.18);
  /* Enhanced highlight effect */
  background: linear-gradient(
    to bottom,
    var(--bg-primary) 0%,
    rgba(77, 150, 218, 0.15) 100%
  );
}

/* Card reflection effect */
.cardReflection {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 35%;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.2));
  transform: rotateX(180deg);
  opacity: 0.5;
  pointer-events: none;
}

.productIcon {
  font-size: var(--font-size-2xl);
  color: var(--color-primary);
  margin-bottom: var(--spacing-md);
  transition: transform 0.3s ease;
}

.activeCard .productIcon {
  transform: scale(1.1);
}

.productName {
  font-size: var(--font-size-md);
  font-weight: var(--font-weight-semibold);
  color: var(--text-primary);
  margin-bottom: var(--spacing-sm);
}

.productDescription {
  font-size: var(--font-size-sm);
  color: var(--text-secondary);
  line-height: var(--line-height-relaxed);
  /* To ensure text doesn't overflow */
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

/* Coverage Section Styles */
.coverageSelection {
  margin-top: var(--spacing-xl);
}

.coverageGrid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: var(--spacing-md);
}

.coverageCard {
  padding: var(--spacing-lg);
  background-color: var(--bg-primary);
  border: 1px solid var(--color-border);
  border-radius: var(--border-radius-lg);
  cursor: pointer;
  transition: all var(--transition-fast);
  position: relative;
  display: flex;
  flex-direction: column;
}

.coverageCard:hover {
  transform: translateY(-4px);
  box-shadow: var(--shadow-md);
  border-color: var(--color-primary-light);
}

.selectedCard {
  border-color: var(--color-primary);
  border-width: 2px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.coverageHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: var(--spacing-md);
}

.coverageName {
  font-size: var(--font-size-md);
  font-weight: var(--font-weight-semibold);
  color: var(--text-primary);
  margin: 0;
}

.coveragePremium {
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-bold);
  color: var(--color-primary);
  background-color: var(--color-primary-50);
  padding: var(--spacing-xs) var(--spacing-sm);
  border-radius: var(--border-radius-full);
}

.coverageDetails {
  flex: 1;
}

.coverageDescription {
  font-size: var(--font-size-sm);
  color: var(--text-secondary);
  line-height: var(--line-height-relaxed);
  margin-bottom: var(--spacing-md);
}

.coverageBenefits {
  list-style: none;
  padding: 0;
  margin: 0;
}

.coverageBenefit {
  display: flex;
  align-items: flex-start;
  gap: var(--spacing-sm);
  margin-bottom: var(--spacing-xs);
  font-size: var(--font-size-sm);
}

.coverageBenefit i {
  color: var(--color-success);
  margin-top: 4px;
}

.selectedIndicator {
  position: absolute;
  top: var(--spacing-sm);
  right: var(--spacing-sm);
  color: var(--color-primary);
  font-size: var(--font-size-lg);
}

/* Responsive styles */
@media (max-width: 768px) {
  .wheelTrack {
    padding: 0 15%;
  }
  
  .coverageGrid {
    grid-template-columns: 1fr;
  }
  
  .wheelButton {
    width: 36px;
    height: 36px;
  }
}

@media (max-width: 480px) {
  .wheelTrack {
    padding: 0 10%;
  }
  
  .productCard {
    width: 180px;
    height: 180px;
  }
  
  .wheelButton {
    width: 32px;
    height: 32px;
    font-size: var(--font-size-sm);
  }
}