/* File: frontend/src/pages/UMS/UMS.module.css */

.container {
  padding: var(--spacing-lg, 24px);
  max-width: 1200px;
  margin: 0 auto;
}

.title {
  font-size: var(--font-size-xl, 24px);
  font-weight: var(--font-weight-bold, 700);
  color: var(--text-primary, #1a202c);
  margin-bottom: var(--spacing-lg, 24px);
}

.error {
  background-color: var(--color-error-bg, #fde8e8);
  color: var(--color-error, #e53e3e);
  padding: var(--spacing-md, 16px);
  border-radius: var(--border-radius-md, 4px);
  margin-bottom: var(--spacing-md, 16px);
}

/* File: frontend/src/pages/UMS/UMS.module.css (updated) */

/* ... existing styles ... */

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: var(--spacing-lg);
}

.navigation {
  display: flex;
  gap: var(--spacing-md);
}

.navLink, .activeNavLink {
  padding: var(--spacing-sm) var(--spacing-md);
  border-radius: var(--border-radius-md);
  font-size: var(--font-size-base);
  font-weight: var(--font-weight-medium);
  text-decoration: none;
  transition: all var(--transition-fast);
}

.navLink {
  color: var(--text-secondary);
  background-color: transparent;
}

.navLink:hover {
  color: var(--text-primary);
  background-color: var(--bg-secondary);
}

.activeNavLink {
  color: var(--color-primary);
  background-color: var(--color-primary-100);
}

@media (max-width: 768px) {
  .container {
    padding: var(--spacing-md, 16px);
  }

  .header {
    flex-direction: column;
    align-items: flex-start;
    gap: var(--spacing-md);
  }
  
  .navigation {
    width: 100%;
  }
  
  .navLink, .activeNavLink {
    flex: 1;
    text-align: center;
  }
}