/* File: frontend/src/pages/TripInsight/components/LatestReports/LatestReports.module.css */

.container {
  background-color: var(--bg-primary);
  border-radius: var(--border-radius-lg);
  box-shadow: var(--shadow-card);
  padding: var(--spacing-lg);
  margin-bottom: var(--spacing-lg);
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: var(--spacing-lg);
}

.title {
  font-size: var(--font-size-lg);
  font-weight: var(--font-weight-semibold);
  color: var(--text-primary);
  margin: 0;
}

.headerActions {
  display: flex;
  gap: var(--spacing-md);
  align-items: center;
}

.searchContainer {
  width: 300px;
}

.searchInput {
  width: 100%;
  padding: var(--spacing-sm);
  border: 1px solid var(--color-border);
  border-radius: var(--border-radius-md);
  font-size: var(--font-size-sm);
  color: var(--text-primary);
}

.searchInput::placeholder {
  color: var(--text-tertiary);
}

.generateButton {
  padding: var(--button-padding-small);
  background-color: var(--button-primary-bg);
  color: var(--button-primary-text);
  border: none;
  border-radius: var(--button-border-radius-md);
  font-size: var(--button-font-size-small);
  font-weight: var(--font-weight-medium);
  cursor: pointer;
  transition: background-color var(--transition-fast);
}

.generateButton:hover {
  background-color: var(--button-primary-hover-bg);
}

.loading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: var(--spacing-xl);
  min-height: 200px;
}

.loadingSpinner {
  border: 3px solid var(--color-border);
  border-top: 3px solid var(--color-primary);
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 1s linear infinite;
  margin-bottom: var(--spacing-md);
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.tableContainer {
  margin-bottom: var(--spacing-lg);
  overflow-x: auto;
}

.table {
  width: 100%;
  border-collapse: collapse;
}

.table th {
  background-color: var(--bg-secondary);
  padding: var(--spacing-sm);
  text-align: left;
  font-weight: var(--font-weight-semibold);
  color: var(--text-secondary);
}

.table td {
  padding: var(--spacing-sm);
  border-bottom: 1px solid var(--bg-secondary);
}

.reportName {
  font-weight: var(--font-weight-medium);
  color: var(--text-primary);
}

.reportTime {
  font-size: var(--font-size-xs);
  color: var(--text-tertiary);
}

.reportType {
  display: inline-block;
  padding: 2px 8px;
  border-radius: var(--border-radius-sm);
  font-size: var(--font-size-xs);
  font-weight: var(--font-weight-medium);
}

.summary {
  background-color: var(--color-primary-100);
  color: var(--color-primary);
}

.behavior {
  background-color: var(--color-success-100);
  color: var(--color-success);
}

.scoring {
  background-color: var(--color-warning-100);
  color: var(--color-warning);
}

.route {
  background-color: var(--color-info-100);
  color: var(--color-info);
}

.speed {
  background-color: var(--color-error-100);
  color: var(--color-error);
}

.statusBadge {
  display: inline-block;
  padding: 4px 8px;
  border-radius: var(--border-radius-sm);
  font-size: var(--font-size-xs);
  font-weight: var(--font-weight-semibold);
}

.in_progress {
  background-color: var(--color-info-bg);
  color: var(--color-info);
}

.ready {
  background-color: var(--color-success-bg);
  color: var(--color-success);
}

.downloadLink {
  color: var(--color-primary);
  text-decoration: underline;
  font-weight: var(--font-weight-semibold);
}

.pendingMessage {
  color: var(--text-secondary);
  font-size: var(--font-size-sm);
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: var(--spacing-xs);
  margin-bottom: var(--spacing-lg);
}

.paginationArrow {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  background: none;
  border: none;
  cursor: pointer;
  color: var(--text-secondary);
}

.paginationArrow:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.pageNumber {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: var(--border-radius-sm);
  background-color: var(--bg-primary);
  border: 1px solid var(--color-border);
  font-size: var(--font-size-sm);
  color: var(--text-primary);
  cursor: pointer;
}

.activePage {
  background-color: var(--color-primary-100);
  border-color: var(--color-primary-200);
  color: var(--color-primary-dark);
}

.reportInfo {
  background-color: var(--bg-secondary);
  border-radius: var(--border-radius-md);
  padding: var(--spacing-md);
}

.infoTitle {
  font-size: var(--font-size-md);
  font-weight: var(--font-weight-semibold);
  color: var(--text-primary);
  margin-top: 0;
  margin-bottom: var(--spacing-sm);
}

.infoText {
  color: var(--text-secondary);
  margin-bottom: var(--spacing-md);
}

.reportTypes {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: var(--spacing-md);
}

.reportTypeItem {
  display: flex;
  gap: var(--spacing-sm);
  align-items: flex-start;
}

.reportTypeIcon {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin-top: 4px;
}

.reportTypeContent {
  flex: 1;
}

.reportTypeTitle {
  font-weight: var(--font-weight-semibold);
  color: var(--text-primary);
  margin-bottom: var(--spacing-xxs);
}

.reportTypeDescription {
  font-size: var(--font-size-sm);
  color: var(--text-secondary);
  margin: 0;
}

@media (max-width: 768px) {
  .header {
    flex-direction: column;
    align-items: flex-start;
    gap: var(--spacing-md);
  }
  
  .headerActions {
    width: 100%;
    flex-direction: column;
  }
  
  .searchContainer {
    width: 100%;
  }
  
  .generateButton {
    width: 100%;
  }
  
  .reportTypes {
    grid-template-columns: 1fr;
  }
}