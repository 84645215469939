/* File: frontend/src/pages/Auth/AccountCreation.module.css */

.accountCreationContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: calc(100vh - var(--header-height) - var(--spacing-2xl));
  padding: var(--spacing-xl);
  box-sizing: border-box;
}

.accountCreationContent {
  width: 100%;
  max-width: 480px;
  margin: 0 auto;
  animation: fadeIn 0.3s ease-out;
}

.contactInfoContainer {
  margin-top: var(--spacing-lg);
  width: 100%;
}

.contactInfoContent {
  background-color: var(--bg-secondary);
  border-radius: var(--border-radius-lg);
  padding: var(--spacing-md);
  text-align: center;
}

.contactLabel {
  display: block;
  color: var(--text-secondary);
  font-size: var(--font-size-sm);
  margin-bottom: var(--spacing-xxs);
}

.contactValue {
  display: block;
  color: var(--text-primary);
  font-size: var(--font-size-base);
  font-weight: var(--font-weight-medium);
  word-break: break-all;
}

/* Loading state */
.loadingContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: calc(100vh - var(--header-height));
}

.loadingSpinner {
  width: 40px;
  height: 40px;
  border: 3px solid var(--bg-secondary);
  border-top-color: var(--color-primary);
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

/* Responsive styles */
@media (min-width: 1200px) {
  .accountCreationContainer {
    padding: var(--spacing-2xl);
  }

  .accountCreationContent {
    max-width: 520px;
  }
}

@media (max-width: 768px) {
  .accountCreationContainer {
    padding: var(--spacing-lg);
    min-height: calc(100vh - var(--header-height) - var(--spacing-xl));
  }

  .contactInfoContent {
    padding: var(--spacing-sm);
  }
}

@media (max-width: 480px) {
  .accountCreationContainer {
    padding: var(--spacing-md);
    min-height: calc(100vh - var(--header-height) - var(--spacing-lg));
  }

  .contactInfoContent {
    padding: var(--spacing-xs);
  }

  .contactLabel {
    font-size: var(--font-size-xs);
  }

  .contactValue {
    font-size: var(--font-size-sm);
  }
}

/* Animations */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

/* Print styles */
@media print {
  .accountCreationContainer {
    min-height: auto;
    padding: 0;
  }

  .loadingContainer {
    display: none;
  }
}

/* Dark mode support */
@media (prefers-color-scheme: dark) {
  .contactInfoContent {
    background-color: var(--bg-tertiary);
  }

  .loadingSpinner {
    border-color: var(--bg-tertiary);
    border-top-color: var(--color-primary);
  }
}