/* File: frontend/src/components/Alert/Alert.module.css */

.alert {
  width: 100%;
  border-radius: var(--border-radius-md);
  display: flex;
  align-items: flex-start;
  padding: var(--spacing-md);
  gap: var(--spacing-md);
  font-family: var(--font-primary);
}

.alert_error {
  background-color: var(--color-error-bg);
  border: 1px solid var(--color-error-border);
  box-shadow: 0px 2px 6px var(--color-error-shadow);
}

.alert_warning {
  background-color: var(--color-warning-bg);
  border: 1px solid var(--color-warning-border);
  box-shadow: 0px 2px 6px var(--color-warning-shadow);
}

.alert_information {
  background-color: var(--color-info-bg);
  border: 1px solid var(--color-info-border);
  box-shadow: 0px 2px 6px var(--color-info-shadow);
}

.alert_success {
  background-color: var(--color-success-bg);
  border: 1px solid var(--color-success-border);
  box-shadow: 0px 2px 6px var(--color-success-shadow);
}

.alert_icon {
  width: 20px;
  height: 20px;
  flex-shrink: 0;
}

.alert_content {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-sm);
}

.alert_title {
  font-size: var(--font-size-base);
  font-weight: var(--font-weight-medium);
  line-height: var(--line-height-tight);
  color: var(--text-primary);
}

.alert_message {
  font-size: var(--font-size-sm);
  line-height: var(--line-height-normal);
  color: var(--text-secondary);
  font-family: var(--font-secondary);
}

.alert_close {
  background: none;
  border: none;
  cursor: pointer;
  padding: var(--spacing-xxs);
  transition: opacity var(--transition-fast);
}

.alert_close:hover,
.alert_close:focus {
  opacity: 0.7;
}

.alert_close:focus {
  outline: none;
  box-shadow: var(--focus-ring);
}

.close_icon {
  width: 12px;
  height: 12px;
}

@media (max-width: 768px) {
  .alert {
    padding: var(--spacing-sm);
    gap: var(--spacing-sm);
  }

  .alert_title {
    font-size: var(--font-size-sm);
  }

  .alert_message {
    font-size: var(--font-size-xs);
  }
}