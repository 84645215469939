/* File: frontend/src/components/LoadingSpinner/LoadingContainer.module.css */

.loadingContainer {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: var(--z-index-modal);
}

.fullScreen {
  position: fixed;
  width: 100vw;
  height: 100vh;
}

.transparent {
  background-color: transparent;
}

.spinnerWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--spacing-sm);
  padding: var(--spacing-md);
  background-color: var(--color-white);
  border-radius: var(--border-radius-lg);
  box-shadow: var(--shadow-lg);
}

.loadingMessage {
  color: var(--text-secondary);
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-medium);
  margin: 0;
}
