/* File: frontend/src/pages/QMS/QuoteDetail/components/QuoteHeader/QuoteHeader.module.css */

.container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: var(--spacing-lg);
  padding-bottom: var(--spacing-md);
  border-bottom: 1px solid var(--color-border);
}

.info {
  flex: 1;
}

.title {
  font-size: var(--font-size-xl);
  font-weight: var(--font-weight-bold);
  color: var(--text-primary);
  margin-bottom: var(--spacing-xs);
}

.meta {
  display: flex;
  flex-wrap: wrap;
  gap: var(--spacing-md);
}

.metaItem {
  display: flex;
  align-items: center;
}

.metaLabel {
  font-weight: var(--font-weight-medium);
  color: var(--text-secondary);
  margin-right: var(--spacing-xxs);
}

.metaValue {
  color: var(--text-primary);
}

.statusCompleted {
  color: var(--color-success);
  font-weight: var(--font-weight-semibold);
}

.statusCollecting {
  color: var(--color-info);
  font-weight: var(--font-weight-semibold);
}

.statusAbandoned {
  color: var(--color-error);
  font-weight: var(--font-weight-semibold);
}

.statusLocked {
  color: var(--text-secondary);
  font-weight: var(--font-weight-semibold);
}

.actions {
  display: flex;
  gap: var(--spacing-sm);
}

.backButton {
  padding: var(--button-padding-small);
  background-color: var(--button-secondary-bg);
  color: var(--button-secondary-text);
  border: 1px solid var(--button-secondary-border);
  border-radius: var(--button-border-radius-md);
  font-size: var(--button-font-size-small);
  font-weight: var(--font-weight-medium);
  cursor: pointer;
  transition: background-color var(--transition-fast);
}

.backButton:hover {
  background-color: var(--button-secondary-hover-bg);
}

.downloadButton {
  padding: var(--button-padding-small);
  background-color: var(--button-primary-bg);
  color: var(--button-primary-text);
  border: none;
  border-radius: var(--button-border-radius-md);
  font-size: var(--button-font-size-small);
  font-weight: var(--font-weight-medium);
  cursor: pointer;
  transition: background-color var(--transition-fast);
}

.downloadButton:hover {
  background-color: var(--button-primary-hover-bg);
}

@media (max-width: 768px) {
  .container {
    flex-direction: column;
  }
  
  .actions {
    margin-top: var(--spacing-md);
    width: 100%;
  }
  
  .backButton, .downloadButton {
    flex: 1;
  }
}