/* File: frontend/src/pages/Auth/VerifyEmail.module.css */

.verifyEmailContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: calc(100vh - var(--header-height) - var(--spacing-2xl));
  padding: var(--spacing-xl);
  box-sizing: border-box;
}

.verifyEmailContent {
  width: 100%;
  max-width: 480px;
  margin: 0 auto;
  animation: fadeIn 0.3s ease-out;
}

.loadingContent {
  margin-top: var(--spacing-lg);
  width: 100%;
}

.progressContainer {
  width: 100%;
  height: 4px;
  background-color: var(--bg-secondary);
  border-radius: var(--border-radius-full);
  overflow: hidden;
  margin-bottom: var(--spacing-md);
}

.progressBar {
  height: 100%;
  background-color: var(--color-info);
  border-radius: var(--border-radius-full);
  transition: width 0.5s ease-out;
}

.progressSteps {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-sm);
}

.step {
  display: flex;
  align-items: center;
  gap: var(--spacing-sm);
  color: var(--text-secondary);
  font-size: var(--font-size-sm);
  padding: var(--spacing-xs) var(--spacing-sm);
  border-radius: var(--border-radius-sm);
  transition: all var(--transition-normal);
}

.step::before {
  content: "";
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: 2px solid var(--color-border);
  background-color: var(--bg-primary);
  transition: all var(--transition-normal);
}

.step.completed {
  color: var(--color-info);
}

.step.completed::before {
  border-color: var(--color-info);
  background-color: var(--color-info);
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='white'%3E%3Cpath d='M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41L9 16.17z'/%3E%3C/svg%3E");
  background-size: 12px;
  background-position: center;
  background-repeat: no-repeat;
}

/* Responsive styles */
@media (min-width: 1200px) {
  .verifyEmailContainer {
    padding: var(--spacing-2xl);
  }

  .verifyEmailContent {
    max-width: 520px;
  }
}

@media (max-width: 768px) {
  .verifyEmailContainer {
    padding: var(--spacing-lg);
  }

  .step {
    font-size: var(--font-size-xs);
  }
}

@media (max-width: 480px) {
  .verifyEmailContainer {
    padding: var(--spacing-md);
  }

  .progressSteps {
    gap: var(--spacing-xs);
  }
}

/* Animations */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Print styles */
@media print {
  .verifyEmailContainer {
    min-height: auto;
    padding: 0;
  }

  .loadingContent {
    display: none;
  }
}

/* Continuing the dark mode support section */
@media (prefers-color-scheme: dark) {
  .progressContainer {
    background-color: var(--bg-tertiary);
  }

  .step::before {
    background-color: var(--bg-secondary);
    border-color: var(--color-border);
  }

  .step {
    color: var(--text-secondary);
  }

  .step.completed {
    color: var(--color-info);
  }

  .step.completed::before {
    background-color: var(--color-info);
    border-color: var(--color-info);
  }
}

/* Error state styles */
.error {
  color: var(--color-error);
}

.errorContainer {
  background-color: var(--color-error-bg);
  border: 1px solid var(--color-error-border);
  border-radius: var(--border-radius-lg);
  padding: var(--spacing-md);
  margin-top: var(--spacing-md);
}

.errorMessage {
  color: var(--color-error);
  font-size: var(--font-size-sm);
  line-height: var(--line-height-normal);
}

/* Success state styles */
.success {
  color: var(--color-success);
}

.successContainer {
  background-color: var(--color-success-bg);
  border: 1px solid var(--color-success-border);
  border-radius: var(--border-radius-lg);
  padding: var(--spacing-md);
  margin-top: var(--spacing-md);
}

/* Loading spinner animation */
@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}

/* Focus styles for accessibility */
.step:focus-within {
  outline: none;
  box-shadow: var(--focus-ring);
}

/* Hover states */
.step:hover {
  background-color: var(--bg-secondary);
}

/* Additional responsive adjustments */
@media (max-width: 360px) {
  .verifyEmailContainer {
    padding: var(--spacing-sm);
  }

  .step {
    padding: var(--spacing-xxs) var(--spacing-xs);
  }

  .step::before {
    width: 12px;
    height: 12px;
  }
}

/* High contrast mode support */
@media (forced-colors: active) {
  .progressBar {
    background-color: CanvasText;
  }

  .step::before {
    border: 2px solid CanvasText;
  }

  .step.completed::before {
    background-color: CanvasText;
  }
}