/* File: frontend/src/pages/Join/Join.module.css */

.joinContainer {
  max-width: var(--breakpoint-lg);
  margin: 0 auto;
  padding: clamp(1rem, 5vw, 2rem);
  font-family: 'Instrument Sans', sans-serif;
  color: var(--color-text);
}

.title {
  font-size: clamp(2rem, 5vw, 2.5rem);
  color: var(--color-primary);
  margin-bottom: clamp(1rem, 3vw, 1.5rem);
}

.introduction, .positions, .application, .offer, .closing {
  margin-bottom: clamp(1.5rem, 5vw, 2rem);
}

.sectionTitle {
  font-size: clamp(1.5rem, 4vw, 1.8rem);
  color: var(--color-primary-dark);
  margin-bottom: clamp(0.75rem, 2vw, 1rem);
}

.paragraph {
  line-height: 1.6;
  margin-bottom: clamp(0.75rem, 2vw, 1rem);
  font-size: clamp(1rem, 2vw, 1.1rem);
}

.list {
  padding-left: 1.5rem;
  margin-bottom: clamp(0.75rem, 2vw, 1rem);
}

.listItem {
  margin-bottom: clamp(0.25rem, 1vw, 0.5rem);
  font-size: clamp(0.9rem, 2vw, 1rem);
}

.application .orderedList {
  list-style-type: decimal;
}

.application .unorderedList {
  list-style-type: disc;
  margin-top: clamp(0.25rem, 1vw, 0.5rem);
  margin-left: 1rem;
}

.offer .list {
  list-style-type: none;
  padding-left: 0;
}

.offer .listItem {
  background-color: var(--color-background-light);
  padding: clamp(0.5rem, 2vw, 0.75rem) clamp(0.75rem, 2vw, 1rem);
  border-radius: var(--border-radius-sm);
  margin-bottom: clamp(0.25rem, 1vw, 0.5rem);
}

.closing {
  font-style: italic;
  font-weight: 500;
}

@media (prefers-color-scheme: dark) {
  .joinContainer {
    color: var(--color-text-dark);
    background-color: var(--color-background-dark);
  }

  .offer .listItem {
    background-color: var(--color-background-dark-light);
  }
}