/* File: frontend/src/pages/SuperAdmin/components/CommunicationManagement/CommunicationManagement.module.css */

.container {
  display: flex;
  flex-direction: column;
}

.title {
  font-size: var(--font-size-2xl);
  font-weight: var(--font-weight-bold);
  color: var(--text-primary);
  margin: 0 0 var(--spacing-lg) 0;
}

.tabs {
  display: flex;
  border-bottom: 1px solid var(--color-border);
  margin-bottom: var(--spacing-md);
}

.tab {
  display: flex;
  align-items: center;
  gap: var(--spacing-xs);
  padding: var(--spacing-sm) var(--spacing-md);
  background: none;
  border: none;
  border-bottom: 2px solid transparent;
  font-size: var(--font-size-base);
  color: var(--text-secondary);
  cursor: pointer;
  transition: all var(--transition-fast);
}

.tab:hover {
  color: var(--text-primary);
}

.activeTab {
  color: var(--color-primary);
  border-bottom-color: var(--color-primary);
  font-weight: var(--font-weight-medium);
}

.content {
  flex: 1;
}

@media (max-width: 768px) {
  .tabs {
    overflow-x: auto;
    white-space: nowrap;
  }
  
  .tab {
    padding: var(--spacing-sm) var(--spacing-sm);
  }
}