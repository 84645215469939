/* File: frontend/src/pages/InvestorPitch/components/slides/MarketImpactSlide/MarketCard.module.css */

.container {
    background-color: var(--color-gray-1200);
    border-radius: var(--border-radius-lg);
    padding: var(--spacing-xl);
    border: 1px solid var(--color-gray-1100);
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: var(--spacing-lg);
  }
  
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: var(--spacing-md);
  }
  
  .country {
    font-size: var(--font-size-xl);
    font-weight: var(--font-weight-bold);
    color: var(--text-on-primary);
  }
  
  .language {
    font-size: var(--font-size-sm);
    color: var(--color-gray-400);
  }
  
  .readiness {
    margin-bottom: var(--spacing-lg);
  }
  
  .readinessHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: var(--spacing-sm);
    font-size: var(--font-size-sm);
    color: var(--color-gray-400);
  }
  
  .readinessValue {
    font-weight: var(--font-weight-bold);
    color: var(--text-on-primary);
  }
  
  .highlights {
    list-style: none;
    padding: 0;
    margin: 0;
    color: var(--color-gray-400);
    font-size: var(--font-size-base);
    line-height: 1.6;
  }
  
  .highlights li {
    position: relative;
    padding-left: var(--spacing-lg);
    margin-bottom: var(--spacing-sm);
  }
  
  .highlights li::before {
    content: "•";
    position: absolute;
    left: 0;
    color: var(--color-primary-500);
  }
  
  @media (max-width: 768px) {
    .container {
      padding: var(--spacing-lg);
    }
  
    .country {
      font-size: var(--font-size-lg);
    }
  }