/* File: frontend/src/styles/base/typography.css */

@import url('https://fonts.googleapis.com/css2?family=Instrument+Sans:wght@400;500;600;700&family=Inter:wght@400;500;600;700&display=swap');

body {
  font-family: var(--font-primary);
  font-size: var(--font-size-base);
  line-height: var(--line-height-normal);
  color: var(--text-primary);
}

h1, h2, h3, h4, h5, h6 {
  font-weight: var(--font-weight-semibold);
  line-height: var(--line-height-tight);
  margin-bottom: var(--spacing-md);
}

h1 { font-size: var(--font-size-4xl); }
h2 { font-size: var(--font-size-3xl); }
h3 { font-size: var(--font-size-2xl); }
h4 { font-size: var(--font-size-xl); }
h5 { font-size: var(--font-size-lg); }
h6 { font-size: var(--font-size-base); }

/* Typography utility classes */
.font-primary { font-family: var(--font-primary); }
.font-secondary { font-family: var(--font-secondary); }

.font-weight-regular { font-weight: var(--font-weight-regular); }
.font-weight-medium { font-weight: var(--font-weight-medium); }
.font-weight-semibold { font-weight: var(--font-weight-semibold); }
.font-weight-bold { font-weight: var(--font-weight-bold); }

.text-xs { font-size: var(--font-size-xs); }
.text-sm { font-size: var(--font-size-sm); }
.text-base { font-size: var(--font-size-base); }
.text-lg { font-size: var(--font-size-lg); }
.text-xl { font-size: var(--font-size-xl); }
.text-2xl { font-size: var(--font-size-2xl); }
.text-3xl { font-size: var(--font-size-3xl); }
.text-4xl { font-size: var(--font-size-4xl); }

.leading-tight { line-height: var(--line-height-tight); }
.leading-snug { line-height: var(--line-height-snug); }
.leading-normal { line-height: var(--line-height-normal); }
.leading-relaxed { line-height: var(--line-height-relaxed); }
.leading-loose { line-height: var(--line-height-loose); }

/* Component-specific typography */
.badge-text {
  font-size: var(--badge-font-size);
  font-weight: var(--badge-font-weight);
  line-height: var(--badge-line-height);
}

.breadcrumb-text {
  font-family: var(--font-secondary);
  font-size: var(--breadcrumb-font-size);
  line-height: var(--breadcrumb-line-height);
  color: var(--breadcrumb-text-color);
}

.breadcrumb-text-active {
  font-weight: var(--font-weight-medium);
  color: var(--breadcrumb-active-text-color);
}

.button-text {
  font-family: var(--font-primary);
  font-weight: var(--font-weight-semibold);
  line-height: 1.5;
}

.button-text-xsmall { font-size: var(--button-font-size-xsmall); }
.button-text-small { font-size: var(--button-font-size-small); }
.button-text-medium { font-size: var(--button-font-size-medium); }
.button-text-large { font-size: var(--button-font-size-large); }