.container {
    height: 100%;
    display: flex;
    flex-direction: column;
    background-color: #14151A;
    /* gap: var(--spacing-xl); */
    padding: 0 var(--spacing-2xl);
  }
  
  .chartWrapper {
    flex-grow: 1;
    position: relative;
    margin-top: -40px; /* Pull up to create more snug layout */
  }
  

  .header {
    text-align: center;
    margin-bottom: var(--spacing-xl);
  }
  
  .title {
    font-size: var(--font-size-4xl);
    font-weight: var(--font-weight-bold);
    color: var(--text-on-primary);
    margin-bottom: var(--spacing-xs);
  }
  
  .subtitle {
    font-size: var(--font-size-xl);
    color: var(--color-gray-400);
    margin-bottom: var(--spacing-md);
  }


.highlight {
    font-size: var(--font-size-2xl);
    font-weight: var(--font-weight-semibold);
    color: var(--color-primary);
    margin-bottom: var(--spacing-lg);
  }
  
  .chartContainer {
    background: var(--bg-secondary);
    border-radius: var(--border-radius-lg);
    padding: var(--spacing-xl);
    height: 500px;
    margin: var(--spacing-lg) 0;
  }
  
  .tooltip {
    background: var(--color-gray-1200);
    border: 1px solid var(--color-gray-1000);
    border-radius: var(--border-radius-md);
    padding: var(--spacing-sm);
    box-shadow: var(--shadow-md);
  }
  
  .tooltipLabel {
    font-size: 12px;
    font-weight: var(--font-weight-medium);
    color: var(--color-gray-300);
    margin-bottom: var(--spacing-xxs);
  }
  
  :global(.recharts-cartesian-grid-horizontal) line,
  :global(.recharts-cartesian-grid-vertical) line {
    stroke: rgba(255, 255, 255, 0.1);
  }
  
  :global(.recharts-legend-item-text) {
    color: var(--color-gray-300) !important;
    font-size: 12px;
  }
  
  @media (max-width: var(--breakpoint-md)) {
    .title {
      font-size: var(--font-size-3xl);
    }
  
    .subtitle {
      font-size: var(--font-size-lg);
    }
  
    .chartContainer {
      height: 400px;
      padding: var(--spacing-lg);
    }
  }