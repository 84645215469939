/* File: frontend/src/pages/PropertyDetail/components/BuildingSystems/BuildingSystems.module.css */

.container {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-md);
}

.grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: var(--spacing-md);
}

.card {
  background-color: var(--bg-primary);
  border-radius: var(--border-radius-md);
  border: 1px solid var(--color-border);
  overflow: hidden;
}

.cardTitle {
  font-size: var(--font-size-md);
  font-weight: var(--font-weight-semibold);
  padding: var(--spacing-sm);
  background-color: var(--bg-secondary);
  border-bottom: 1px solid var(--color-border);
  margin: 0;
}

.cardContent {
  padding: var(--spacing-sm);
}

.infoGroup {
  display: flex;
  margin-bottom: var(--spacing-xs);
}

.infoGroup:last-child {
  margin-bottom: 0;
}

.infoLabel {
  font-weight: var(--font-weight-medium);
  color: var(--text-secondary);
  width: 180px;
  flex-shrink: 0;
}

.infoValue {
  color: var(--text-primary);
  word-break: break-word;
}

.secondaryGrid, .waterGrid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: var(--spacing-md);
}

.systemDiagram {
  background-color: var(--bg-primary);
  border-radius: var(--border-radius-md);
  border: 1px solid var(--color-border);
  overflow: hidden;
}

.diagramTitle {
  font-size: var(--font-size-md);
  font-weight: var(--font-weight-semibold);
  padding: var(--spacing-sm);
  background-color: var(--bg-secondary);
  border-bottom: 1px solid var(--color-border);
  margin: 0;
}

.diagramContent {
  padding: var(--spacing-md);
}

.diagramPlaceholder {
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--bg-secondary);
  border-radius: var(--border-radius-md);
  color: var(--text-secondary);
  font-style: italic;
}

.noData {
  color: var(--text-secondary);
  font-style: italic;
  text-align: center;
  padding: var(--spacing-md);
  background-color: var(--bg-secondary);
  border-radius: var(--border-radius-md);
  min-height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 768px) {
  .grid, .secondaryGrid, .waterGrid {
    grid-template-columns: 1fr;
  }
  
  .infoGroup {
    flex-direction: column;
  }
  
  .infoLabel {
    width: 100%;
    margin-bottom: var(--spacing-xxxs);
  }
}