/* File: frontend/src/pages/Quote/components/SavingsCalculator/SavingsCalculator.module.css */

.calculatorContainer {
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
  padding: var(--spacing-xl);
  background-color: var(--color-white);
  border-radius: var(--border-radius-lg);
  box-shadow: var(--shadow-lg);
  overflow: hidden;
}

.disabled {
  opacity: 0.5;
  cursor: not-allowed;
  pointer-events: none;
  box-shadow: none !important;
}

.disabled:hover {
  background-color: var(--color-tertiary);
  transform: none;
  box-shadow: none;
}

.dateInput {
  width: 100%;
  padding: var(--spacing-sm);
  border: 1px solid var(--color-border);
  border-radius: var(--border-radius-md);
  font-size: var(--font-size-md);
  font-family: var(--font-primary);
  transition: border-color var(--transition-fast);
  background-color: var(--bg-primary);
}

.dateInput:focus {
  border-color: var(--color-primary);
  outline: none;
}

.calculatorHeader {
  text-align: center;
  margin-bottom: var(--spacing-lg);
}

.calculatorHeader h2 {
  font-size: var(--font-size-2xl);
  font-weight: var(--font-weight-bold);
  color: var(--text-primary);
  margin-bottom: var(--spacing-xs);
}

.calculatorHeader p {
  font-size: var(--font-size-md);
  color: var(--text-secondary);
}

.calculatorContent {
  display: flex;
  flex-wrap: wrap;
  gap: var(--spacing-xl);
  margin-bottom: var(--spacing-xl);
}

.calculatorForm {
  flex: 1;
  min-width: 280px;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-md);
}

.formGroup {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xs);
  width: 100%;
}

.formGroup label {
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-medium);
  color: var(--text-secondary);
}

.dropdownWrapper {
  width: 100%;
  max-width: 100%;
}

.premiumInputGroup {
  display: flex;
  align-items: stretch; /* Changed from center to stretch */
  width: 100%;
  height: 58px;
}

.currencySymbol {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: var(--spacing-sm);
  background-color: var(--bg-secondary);
  border: 1px solid var(--color-border);
  border-right: none;
  border-radius: var(--border-radius-md) 0 0 var(--border-radius-md);
  color: var(--text-secondary);
  font-size: var(--font-size-md);
  height: 100%;
}

.premiumInput {
  flex: 1 1 32%;
  min-width: 60px;
  padding: var(--spacing-sm) var(--spacing-xxxs) var(--spacing-sm) var(--spacing-xs);
  border: 1px solid var(--color-border);
  border-radius: 0;
  border-right: none;
  font-size: var(--font-size-md);
  font-family: var(--font-primary);
  transition: border-color var(--transition-fast);
  background-color: var(--bg-primary);
  height: 100%;
}

.premiumInput::-webkit-outer-spin-button,
.premiumInput::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* For Firefox */
.premiumInput {
  -moz-appearance: textfield;
}

.premiumInput:focus {
  border-color: var(--color-primary);
  outline: none;
}

.premiumTypeDropdown {
  flex: 1 1 68%; /* flex-grow, flex-shrink, flex-basis */
  min-width: 120px; /* Minimum width to ensure dropdown content is readable */
}

.customDropdownWrapper {
  width: 100%;
  height: 100%;
}

.customDropdownWrapper > div {
  height: 100%;
}

.customDropdownWrapper > div > div {
  border-radius: 0 var(--border-radius-md) var(--border-radius-md) 0;
  height: 100%;
  border: 1px solid var(--color-border);
}

.checkboxContainer {
  display: flex;
  align-items: center;
  gap: var(--spacing-xs);
  cursor: pointer;
  margin-top: var(--spacing-xs);
}

.checkboxText {
  font-size: var(--font-size-sm);
  color: var(--text-secondary);
}

.chartContainer {
  flex: 2;
  min-width: 280px;
  height: 450px; /* Increased height to accommodate legend */
}

.chart {
  width: 100%;
  height: 100%;
}

.savingsHighlight {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  gap: var(--spacing-lg);
  background-color: var(--color-primary-100);
  padding: var(--spacing-lg);
  border-radius: var(--border-radius-lg);
  margin-top: var(--spacing-lg);
}

.savingsSummary {
  background-color: var(--color-primary);
  color: var(--color-white);
  padding: var(--spacing-md);
  border-radius: var(--border-radius-md);
  min-width: 150px;
  text-align: center;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.savingAmount {
  font-size: var(--font-size-2xl);
  font-weight: var(--font-weight-bold);
  line-height: 1.2;
}

.savingPeriod {
  font-size: var(--font-size-sm);
  opacity: 0.8;
  margin-top: var(--spacing-xxs);
}

.savingsDetails {
  flex: 1;
  min-width: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.savingsTitle {
  font-size: var(--font-size-lg);
  font-weight: var(--font-weight-semibold);
  color: var(--color-primary);
  margin-bottom: var(--spacing-sm);
}

.savingsBreakdown {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xs);
}

.savingsItem {
  display: flex;
  align-items: center;
  gap: var(--spacing-sm);
  font-size: var(--font-size-sm);
  color: var(--text-secondary);
  flex-wrap: wrap;
}

.savingsItem span {
  color: var(--text-secondary);
}

.savingsItem strong {
  font-weight: var(--font-weight-semibold);
  color: var(--text-primary);
}

.savingsPercentage {
  color: var(--color-tertiary) !important;
  font-weight: var(--font-weight-semibold);
}

.ctaContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 250px;
  margin-left: auto;
}

.ctaButton {
  width: 100%;
  padding: var(--spacing-sm) var(--spacing-lg);
  background-color: var(--color-tertiary);
  color: var(--color-white);
  border: none;
  border-radius: var(--border-radius-full);
  font-size: var(--font-size-base);
  font-weight: var(--font-weight-semibold);
  cursor: pointer;
  transition: all var(--transition-normal);
  box-shadow: 0 4px 20px rgba(56, 199, 147, 0.3);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-family: var(--font-primary);
}

.ctaButton:hover {
  background-color: #2d9f75; /* Darker shade of tertiary color */
  transform: translateY(-2px);
  box-shadow: 0 6px 25px rgba(56, 199, 147, 0.4);
}

.arrow {
  display: inline-block;
  margin-left: var(--spacing-xs);
  transition: transform var(--transition-normal);
}

.ctaButton:hover .arrow {
  transform: translateX(5px);
}

/* Add these responsive improvements to your CSS */

/* Base responsive improvements */
:root {
  /* You could add responsive scaling for your spacing variables */
  --mobile-scale: 0.85; /* Scale factor for mobile */
}

/* Enhanced responsive adjustments for savings highlight */
@media (max-width: 992px) {
  .savingsHighlight {
    flex-wrap: wrap;
  }
  
  .savingsSummary {
    min-width: 140px;
  }
  
  .ctaContainer {
    max-width: 200px;
  }
}

/* Responsive container padding */
@media (max-width: 768px) {
  .calculatorContainer {
    padding: calc(var(--spacing-md)); /* Smaller padding on mobile */
  }
  
  .savingsHighlight {
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: var(--spacing-md);
    padding: var(--spacing-md);
  }

  .savingsSummary {
    width: 100%;
    max-width: 300px;
    padding: var(--spacing-md);
  }

  .savingsDetails {
    width: 100%;
    align-items: center;
  }

  .savingsBreakdown {
    width: 100%;
  }

  .savingsItem {
    justify-content: center;
    text-align: center;
  }

  .ctaContainer {
    width: 100%;
    max-width: 300px;
    margin: var(--spacing-md) auto 0;
  }

  .ctaButton {
    width: 100%;
    padding: var(--spacing-sm) var(--spacing-xl);
  }
  
  /* Scale down headings on mobile */
  .calculatorHeader h2 {
    font-size: var(--font-size-xl);
  }
  
  .savingsTitle {
    font-size: var(--font-size-md);
  }
  
  .savingAmount {
    font-size: var(--font-size-xl);
  }
}

/* Further mobile optimizations */
@media (max-width: 480px) {
  .premiumInputGroup {
    flex-direction: row;
    align-items: stretch;
    height: 48px; /* Consistent height */
  }
  
  .currencySymbol {
    padding: var(--spacing-sm);
    width: 40px; /* Fixed width for consistency */
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .premiumInput {
    flex: 1 1 30%;
    min-width: 60px;
    padding: var(--spacing-sm) var(--spacing-xs);
    text-align: left;
  }

  .premiumTypeDropdown {
    flex: 1 1 70%;
  }
  
  /* Ensure the dropdown takes the right amount of space */
  .customDropdownWrapper > div > div {
    padding: var(--spacing-sm);
    display: flex;
    align-items: center;
  }
  
  /* Ensure consistent line height */
  .currencySymbol, 
  .premiumInput, 
  .customDropdownWrapper > div > div {
    line-height: 1.2;
  }
  
  /* Stack the premium input and dropdown vertically on very small screens */
  /* .premiumInput, .premiumTypeDropdown {
    flex: 1 1 auto;
    width: 100%;
    min-width: 100%;
  } */
  
  /* Make chart container adjust its height based on width */
  .chartContainer {
    height: auto;
    aspect-ratio: 4/3; /* Maintain a reasonable aspect ratio */
    min-height: 300px;
  }
  
  .savingsHighlight {
    padding: var(--spacing-sm);
    gap: var(--spacing-sm);
  }
  
  .savingAmount {
    font-size: var(--font-size-xl); /* Slightly smaller on mobile */
  }
  
  .savingsTitle {
    font-size: var(--font-size-md);
  }
  
  .savingsItem {
    font-size: var(--font-size-xs);
    gap: var(--spacing-xs);
  }
  
  .savingsBreakdown {
    gap: var(--spacing-xxs);
  }
  
  /* Make savings info more compact on small screens */
  .savingsItem span, 
  .savingsItem strong {
    margin-right: var(--spacing-xxs);
  }
  
  .ctaButton {
    padding: var(--spacing-sm) var(--spacing-md);
    font-size: var(--font-size-sm);
  }
}

/* Tablet-specific improvements */
@media (min-width: 481px) and (max-width: 768px) {
  .premiumInput {
    flex: 1 1 40%;
  } 
  
  .premiumTypeDropdown {
    flex: 1 1 60%;
  }

  .premiumInputGroup {
    height: 48px; /* Consistent height */
  }
}

/* Add this to ensure canvas properly scales with container */
.chart {
  width: 100%;
  height: 100%;
  max-height: 100%;
  display: block;
}

/* Improve touch targets for mobile */
@media (max-width: 768px) {
  .checkboxContainer {
    padding: var(--spacing-xs);
  }
  
  .checkboxContainer input[type="checkbox"] {
    width: 20px;
    height: 20px;
  }
  
  .ctaButton {
    padding: var(--spacing-md) var(--spacing-xl);
    width: 100%; /* Full width button on mobile */
    justify-content: center;
  }
}