/* File: frontend/src/pages/DrivingScore/DrivingScore.module.css */

.drivingScorePage {
    display: flex;
    flex-direction: column;
    padding: 20px;
  }
  
  .pageContent {
    /* padding: 24px; */
  }