/* File: frontend/src/components/Logo/Logo.module.css */

.logoWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all var(--transition-normal);
    width: 140px;
    height: 48px;
    overflow: hidden;
  }
  
  .logoContainer {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: var(--spacing-xs);
  }
  
  .logoSvg {
    height: 100%;
    width: auto;
    color: var(--color-white);
    transition: transform var(--transition-normal);
  }
  
  .logoWrapper.collapsed {
    width: 48px;
  }
  
  .logoWrapper.collapsed .logoContainer {
    padding: var(--spacing-xs);
  }
  
  .logoWrapper.collapsed .logoSvg {
    transform: scale(0.6); /* Adjust this value if needed */
  }
  
  .letterA {
    transition: all var(--transition-normal);
    fill: currentColor;
  }
  
  .restOfLogo {
    transition: opacity var(--transition-normal);
    opacity: 1;
    fill: currentColor;
  }
  
  .collapsed .restOfLogo {
    opacity: 0;
  }
  
  /* Mobile styles */
  @media (max-width: 768px) {
    .logoWrapper {
      width: 120px;
    }
  
    .logoWrapper.collapsed {
      width: 48px;
    }
  }