/* File: frontend/src/pages/UMS/UserPortfolio/UserPortfolio.module.css */

.container {
  padding: var(--spacing-lg);
  max-width: 1200px;
  margin: 0 auto;
}

.title {
  font-size: var(--font-size-xl);
  font-weight: var(--font-weight-bold);
  color: var(--text-primary);
  margin-bottom: var(--spacing-md);
}

.description {
  color: var(--text-secondary);
  margin-bottom: var(--spacing-lg);
  font-size: var(--font-size-base);
  line-height: 1.5;
}

.section {
  margin-bottom: var(--spacing-xl);
  background-color: var(--bg-primary);
  border-radius: var(--border-radius-lg);
  box-shadow: var(--shadow-card);
  overflow: hidden;
}

.sectionTitle {
  font-size: var(--font-size-lg);
  font-weight: var(--font-weight-semibold);
  color: var(--text-primary);
  padding: var(--spacing-md);
  border-bottom: 1px solid var(--color-border);
  margin: 0;
}

@media (max-width: 768px) {
  .container {
    padding: var(--spacing-md);
  }
}