/* src/components/KnowledgeTransfer/InputContainer.module.css */
.container {
    display: flex;
    flex-direction: column;
    gap: 15px;
    background-color: #f5f5f5;
    padding: 20px;
    border-radius: 8px;
  }
  
  .inputTextarea {
    width: 100%;
    min-height: 150px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    resize: vertical;
  }
  
  .controls {
    display: flex;
    gap: 10px;
    align-items: center;
  }
  
  .fileInputWrapper {
    position: relative;
  }
  
  .fileInputLabel {
    background-color: #4a90e2;
    color: white;
    padding: 8px 15px;
    border-radius: 4px;
    cursor: pointer;
    display: inline-block;
  }
  
  .fileInput {
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    cursor: pointer;
  }
  
  .modelSelect {
    padding: 8px;
    border-radius: 4px;
    border: 1px solid #ccc;
  }
  
  .submitButton {
    background-color: #4caf50;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .submitButton:hover {
    background-color: #45a049;
  }