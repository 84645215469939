/* File: frontend/src/components/DrivingScore/DrivingScoreDistribution.module.css */

.chartContainer {
  width: 100%;
  height: 400px;
  overflow: hidden;
}

.chartImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.insightContainer {
  display: flex;
  align-items: center;
  background-color: #dbe6fe;
  padding: 16px;
  border-radius: 8px;
  margin-top: 16px;
}

.insightIcon {
  display: flex;
  align-items: center;
  margin-right: 16px;
}

.insightText {
  color: #3667f8;
  font-weight: 500;
}