/* File: frontend/src/pages/PropertyInsight/components/PropertyDistributionChart/PropertyDistributionChart.module.css */

.container {
  background-color: var(--bg-primary);
  border-radius: var(--border-radius-lg);
  box-shadow: var(--shadow-card);
  padding: var(--spacing-md);
  margin-bottom: var(--spacing-lg);
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: var(--spacing-md);
}

.title {
  font-size: var(--font-size-lg);
  font-weight: var(--font-weight-semibold);
  color: var(--text-primary);
  margin: 0;
}

.displayToggle {
  display: flex;
  border: 1px solid var(--color-border);
  border-radius: var(--border-radius-md);
  overflow: hidden;
}

.toggleButton {
  padding: var(--spacing-xs) var(--spacing-sm);
  background: none;
  border: none;
  font-size: var(--font-size-sm);
  cursor: pointer;
  transition: all var(--transition-fast);
}

.activeToggle {
  background-color: var(--color-primary);
  color: var(--color-white);
}

.chartContainer {
  display: flex;
  height: 300px;
  margin-bottom: var(--spacing-md);
  position: relative;
}

.yAxis {
  width: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.yAxisLabel {
  transform: rotate(-90deg);
  white-space: nowrap;
  font-size: var(--font-size-sm);
  color: var(--text-secondary);
}

.chart {
  flex: 1;
  display: flex;
  align-items: flex-end;
  justify-content: space-around;
  border-bottom: 1px solid var(--color-border);
  border-left: 1px solid var(--color-border);
  padding-left: var(--spacing-sm);
  height: 100%;
}

.barContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  max-width: 60px;
}

.bar {
  width: 30px;
  background-color: var(--color-primary);
  border-radius: var(--border-radius-sm) var(--border-radius-sm) 0 0;
  transition: height 0.5s ease;
}

.barLabel {
  font-size: var(--font-size-xs);
  color: var(--text-secondary);
  margin-bottom: var(--spacing-xxs);
}

.yearLabel {
  font-size: var(--font-size-xs);
  color: var(--text-secondary);
  margin-top: var(--spacing-xs);
}

.totalProperties {
  text-align: right;
  font-size: var(--font-size-sm);
  color: var(--text-secondary);
  margin-top: var(--spacing-sm);
}

.loading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: var(--spacing-xl);
  min-height: 300px;
}

.loadingSpinner {
  border: 3px solid var(--color-border);
  border-top: 3px solid var(--color-primary);
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 1s linear infinite;
  margin-bottom: var(--spacing-md);
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.error {
  background-color: var(--color-error-bg);
  color: var(--color-error);
  padding: var(--spacing-md);
  border-radius: var(--border-radius-md);
  margin-bottom: var(--spacing-md);
}

@media (max-width: 768px) {
  .header {
    flex-direction: column;
    align-items: flex-start;
  }
  
  .displayToggle {
    margin-top: var(--spacing-sm);
  }
  
  .chartContainer {
    height: 250px;
  }
  
  .bar {
    width: 20px;
  }
}