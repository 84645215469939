/* File: frontend/src/pages/InvestorPitch/components/slides/RevenueProjection/RevenueProjection.module.css */

.container {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-lg);
  padding: var(--spacing-md);
  width: 100%;
}

.header {
  text-align: center;
  margin-bottom: var(--spacing-lg);
}

.title {
  font-size: var(--font-size-4xl);
  font-weight: var(--font-weight-bold);
  background: linear-gradient(135deg, var(--color-primary), var(--color-blue-510));
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  margin-bottom: var(--spacing-md);
}

.subtitle {
  font-size: var(--font-size-xl);
  color: var(--color-gray-400);
  max-width: 80%;
  margin: 0 auto;
  line-height: var(--line-height-relaxed);
}

.chartWrapper {
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  margin: 0 auto;
  padding-bottom: var(--spacing-md);
}

.chartContainer {
  display: flex;
  justify-content: space-around;
  align-items: flex-end;
  gap: var(--spacing-lg);
  padding: var(--spacing-xl);
  margin: var(--spacing-sm) 0;
  background-color: rgba(255, 255, 255, 0.05);
  border-radius: var(--border-radius-lg);
  box-shadow: 0 4px 10px rgba(0,0,0,0.5);
  min-height: 350px;
  min-width: min-content; /* Ensures the container is at least as wide as its content */
  width: 100%;
}

.cycleColumn {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 80px; /* Ensures minimum width for each column */
  width: 100%;
  max-width: 120px;
}

.barContainer {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  width: 100%;
  height: 300px; /* Fixed height for the bar container */
}

.bar {
  width: 100%;
  background: linear-gradient(180deg, var(--color-primary-500), var(--color-primary-900));
  border-radius: var(--border-radius-sm) var(--border-radius-sm) 0 0;
  position: relative;
  transition: height 0.8s ease;
  box-shadow: var(--shadow-md);
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.barValue {
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translate(-50%, -5px);
  font-size: var(--font-size-lg);
  font-weight: var(--font-weight-bold);
  color: var(--color-primary-500);
  white-space: nowrap;
}

.cycleLabel {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: var(--spacing-md);
  text-align: center;
  width: 100%;
}

.cycleName {
  font-size: var(--font-size-md);
  font-weight: var(--font-weight-semibold);
  color: var(--text-on-primary);
}

.cycleYears {
  font-size: var(--font-size-sm);
  color: var(--color-gray-600);
}

.explanationContainer {
  margin-top: var(--spacing-xl);
  padding: var(--spacing-xl);
  background-color: rgba(34, 34, 34, 0.5);
  border-radius: var(--border-radius-lg);
  box-shadow: var(--shadow-md);
  color: var(--color-gray-200);
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}

.explanationTitle {
  font-size: var(--font-size-2xl);
  margin-bottom: var(--spacing-md);
  color: var(--text-on-primary);
}

.explanationSubtitle {
  font-size: var(--font-size-xl);
  margin-top: var(--spacing-lg);
  margin-bottom: var(--spacing-sm);
  color: var(--text-on-primary);
}

.explanationText {
  font-size: var(--font-size-base);
  line-height: var(--line-height-relaxed);
  margin-bottom: var(--spacing-md);
}

@media (max-width: 1024px) {
  .title {
    font-size: var(--font-size-3xl);
  }
  
  .subtitle {
    font-size: var(--font-size-lg);
    max-width: 90%;
  }
  
  .cycleColumn {
    min-width: 70px;
  }
}

@media (max-width: 768px) {
  .chartContainer {
    padding: var(--spacing-lg);
    gap: var(--spacing-md);
    min-height: 300px;
  }
  
  .cycleColumn {
    min-width: 60px;
  }
  
  .barValue {
    font-size: var(--font-size-base);
  }
  
  .explanationContainer {
    padding: var(--spacing-lg);
  }
  
  .explanationTitle {
    font-size: var(--font-size-xl);
  }
  
  .explanationSubtitle {
    font-size: var(--font-size-lg);
  }
}

@media (max-width: 480px) {
  .container {
    gap: var(--spacing-md);
  }
  
  .title {
    font-size: var(--font-size-2xl);
  }
  
  .subtitle {
    font-size: var(--font-size-base);
  }
  
  .chartContainer {
    padding: var(--spacing-md);
    gap: var(--spacing-sm);
    min-height: 250px;
  }
  
  .cycleColumn {
    min-width: 50px;
  }
  
  .cycleName {
    font-size: var(--font-size-sm);
  }
  
  .cycleYears {
    font-size: var(--font-size-xs);
  }
  
  .barValue {
    font-size: var(--font-size-xs);
  }
  
  .explanationContainer {
    padding: var(--spacing-md);
  }
  
  .explanationTitle {
    font-size: var(--font-size-lg);
  }
  
  .explanationSubtitle {
    font-size: var(--font-size-base);
  }
  
  .explanationText {
    font-size: var(--font-size-sm);
  }
}