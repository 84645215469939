/* File: frontend/src/pages/InvestorPitch/components/slides/GlobalHiringSlide/GlobalHiringSlide.module.css */

.container {
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: var(--spacing-xl);
  }
  
  .header {
    text-align: center;
    margin-bottom: var(--spacing-xl);
  }
  
  .title {
    font-size: var(--font-size-4xl);
    font-weight: var(--font-weight-bold);
    color: var(--text-on-primary);
    margin-bottom: var(--spacing-md);
  }
  
  .subtitle {
    font-size: var(--font-size-xl);
    color: var(--color-gray-400);
  }
  
  .content {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-2xl);
  }
  
  .locationsGrid {
    display: grid;
    align-items: center;
    grid-template-columns: repeat(3, 1fr);
    gap: var(--spacing-xl);
  }
  
  .infoSection {
    display: grid;
    grid-template-columns: 2fr 1fr;
    gap: var(--spacing-xl);
  }
  
  .sectionTitle {
    font-size: var(--font-size-xl);
    font-weight: var(--font-weight-bold);
    color: var(--text-on-primary);
    margin-bottom: var(--spacing-lg);
  }
  
  @media (max-width: 1024px) {
    .locationsGrid {
      grid-template-columns: repeat(2, 1fr);
    }
  
    .infoSection {
      grid-template-columns: 1fr;
    }
  }
  
  @media (max-width: 768px) {
    .locationsGrid {
      grid-template-columns: 1fr;
    }
  }