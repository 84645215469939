/* File: frontend/src/components/form/auth/SignInForm.module.css */

.signInForm {
    display: flex;
    width: 100%;
    min-height: 600px;
    max-width: 800px;
    margin: 0 auto;
    /* border: 1px solid var(--color-border);
    border-radius: var(--border-radius-lg); */
    overflow: hidden;
  }
  
  .formContent {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* padding: var(--spacing-lg) 0; */
    width: 100%;
  }
  
  .formFields {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-xs);
    max-width: 400px;
    width: 100%;
    margin: 0 auto;
  }

  /* .rememberMeWrapper {
    margin-top: var(--spacing-md);
  } */
  
  .errorMessage {
    color: var(--color-error);
    font-size: var(--font-size-sm);
    margin-top: var(--spacing-xs);
    text-align: left;
  }
  
  .inputError {
    border-color: var(--color-error) !important;
  }
  
  .termsText {
    font-size: var(--font-size-sm);
    line-height: var(--line-height-normal);
    color: var(--text-tertiary);
    text-align: left;
    margin-top: var(--spacing-md);
  }
  
  @media (max-width: 1024px) {
    .signInForm {
      flex-direction: column;
      max-width: 500px;
    }
  
    .formContent {
      width: 100%;
    }
  
    .formContent {
      padding: var(--spacing-xl) var(--spacing-lg);
    }
  }
  
  @media (max-width: 768px) {
    /* .signInForm {
      border: none;
    } */
  
    .formContent {
      padding: 0 0;
    }
  }