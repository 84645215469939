/* File: frontend/src/pages/TripDetail/components/TripRoute/TripRoute.module.css */

.container {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-md);
}

.routeInfo {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  gap: var(--spacing-md);
}

.locationCard {
  flex: 1;
  display: flex;
  align-items: flex-start;
  gap: var(--spacing-sm);
  background-color: var(--bg-secondary);
  border-radius: var(--border-radius-md);
  padding: var(--spacing-md);
}

.locationIcon {
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: var(--bg-primary);
}

.startIcon {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: var(--color-success);
}

.endIcon {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: var(--color-error);
}

.locationDetails {
  flex: 1;
}

.locationLabel {
  font-weight: var(--font-weight-semibold);
  color: var(--text-primary);
  margin-bottom: var(--spacing-xxs);
}

.locationAddress {
  font-size: var(--font-size-sm);
  color: var(--text-secondary);
  margin-bottom: var(--spacing-xxs);
}

.locationCoordinates {
  font-size: var(--font-size-xs);
  color: var(--text-tertiary);
  font-family: monospace;
}

.routeStats {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: var(--spacing-sm);
  background-color: var(--bg-secondary);
  border-radius: var(--border-radius-md);
  padding: var(--spacing-md);
  min-width: 120px;
}

.routeStat {
  text-align: center;
}

.routeStatLabel {
  font-size: var(--font-size-sm);
  color: var(--text-secondary);
  margin-bottom: var(--spacing-xxs);
}

.routeStatValue {
  font-size: var(--font-size-lg);
  font-weight: var(--font-weight-bold);
  color: var(--text-primary);
}

.mapContainer {
  height: 400px;
  background-color: var(--bg-secondary);
  border-radius: var(--border-radius-md);
  overflow: hidden;
  position: relative;
}

.loading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.loadingSpinner {
  border: 3px solid var(--color-border);
  border-top: 3px solid var(--color-primary);
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 1s linear infinite;
  margin-bottom: var(--spacing-md);
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.mapError {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  color: var(--color-error);
  text-align: center;
  padding: var(--spacing-md);
}

.mapPlaceholder {
  height: 100%;
  width: 100%;
}

.mockMap {
  height: 100%;
  width: 100%;
  background-color: #e8e8e8;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mockRoute {
  width: 80%;
  height: 2px;
  background: linear-gradient(90deg, var(--color-success) 0%, var(--color-error) 100%);
  position: relative;
}

.mockStartPoint {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: var(--color-success);
}

.mockEndPoint {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate(50%, -50%);
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: var(--color-error);
}

.mockMapText {
  position: absolute;
  font-size: var(--font-size-lg);
  color: var(--text-tertiary);
  text-align: center;
}

.routePointsContainer {
  background-color: var(--bg-primary);
  border-radius: var(--border-radius-md);
  border: 1px solid var(--color-border);
  overflow: hidden;
}

.routePointsTitle {
  font-size: var(--font-size-md);
  font-weight: var(--font-weight-semibold);
  padding: var(--spacing-sm);
  background-color: var(--bg-secondary);
  border-bottom: 1px solid var(--color-border);
  margin: 0;
}

.tableContainer {
  overflow-x: auto;
  max-height: 300px;
  overflow-y: auto;
}

.routePointsTable {
  width: 100%;
  border-collapse: collapse;
}

.routePointsTable th, .routePointsTable td {
  padding: var(--spacing-sm);
  text-align: left;
  border-bottom: 1px solid var(--color-border-light);
}

.routePointsTable th {
  background-color: var(--bg-secondary);
  font-weight: var(--font-weight-semibold);
  color: var(--text-secondary);
  position: sticky;
  top: 0;
  z-index: 1;
}

.highlightedRow {
  background-color: var(--bg-secondary);
}

@media (max-width: 768px) {
  .routeInfo {
    flex-direction: column;
  }
  
  .mapContainer {
    height: 300px;
  }
}