/* File: frontend/src/components/Button/Button.module.css */

.button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-family: var(--font-primary);
  font-weight: var(--font-weight-semibold);
  text-decoration: none;
  cursor: pointer;
  transition: var(--button-transition);
  border: none;
  outline: none;
}

.button-loading {
  position: relative;
  cursor: wait;
}

.button-spinner {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 16px;
  height: 16px;
  border: 2px solid transparent;
  border-top-color: currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: button-spin 0.5s linear infinite;
}

.button-loading .button-text {
  visibility: hidden;
}

@keyframes button-spin {
  to {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

.button-text {
  display: inline-block;
  vertical-align: middle;
}

.button:focus {
  box-shadow: var(--focus-ring);
}

.button-primary {
  background-color: var(--button-primary-bg);
  color: var(--button-primary-text);
  border: 1px solid var(--button-primary-border);
}

.button-primary:hover {
  background-color: var(--button-primary-hover-bg);
  color: var(--button-primary-text);
}

.button-secondary {
  background-color: var(--button-secondary-bg);
  color: var(--button-secondary-text);
  border: 1px solid var(--button-secondary-border);
}

.button-secondary:hover {
  background-color: var(--button-secondary-hover-bg);
  color: var(--button-primary-text);
}

.button-delete {
  background-color: var(--color-gray-100);
  color: var(--color-red-middle);
  border: 1px solid var(--color-gray-200);
}

.button-delete:hover {
  background-color: var(--color-red-lighter);
  border-color: var(--color-red-light);
  color: var(--color-red-510);
}

.button-refresh {
  background-color: var(--color-gray-100);
  color: var(--color-blue-middle);
  border: 1px solid var(--color-gray-200);
}

.button-refresh:hover {
  background-color: var(--color-blue-lighter);
  border-color: var(--color-blue-light);
  color: var(--color-blue-510);
}

.button-search {
  background-color: var(--button-search-bg);
  border: 1px solid var(--button-search-border);
}

.button-search:hover {
  border-color: var(--button-search-hover-border);
}

.button-social {
  background-color: var(--button-social-bg);
  color: var(--button-social-text);
  border: 1px solid var(--button-social-border);
}

.button-social:hover {
  border-color: var(--button-social-hover-border);
}

.button-xsmall {
  font-size: var(--button-font-size-xsmall);
  padding: var(--button-padding-xsmall);
  border-radius: var(--button-border-radius-sm);
}

.button-small {
  font-size: var(--button-font-size-small);
  padding: var(--button-padding-small);
  border-radius: var(--button-border-radius-md);
}

.button-medium {
  font-size: var(--button-font-size-medium);
  padding: var(--button-padding-medium);
  border-radius: var(--button-border-radius-lg);
}

.button-large {
  font-size: var(--button-font-size-large);
  padding: var(--button-padding-large);
  border-radius: var(--button-border-radius-xl);
}

.button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.button-loading {
  opacity: 0.7;
  cursor: wait;
}

.button-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.button-icon-left {
  /* margin-right: var(--spacing-xs); */
  margin-right: 0;
}

.button-icon-right {
  margin-left: var(--spacing-xs);
}

.button-arrow {
  margin-left: var(--spacing-sm);
  transition: transform var(--transition-fast);
}

.button:hover .button-arrow {
  transform: translateX(4px);
}

@media (max-width: 768px) {
  .button-large {
    font-size: var(--button-font-size-medium);
    padding: var(--button-padding-medium);
  }
}