/* File: frontend/src/components/NewsCard/NewsCard.module.css */

.newsCard {
    background-color: var(--color-background-light);
    border-radius: var(--border-radius-lg);
    overflow: hidden;
    box-shadow: var(--box-shadow-sm);
    transition: transform var(--transition-fast), box-shadow var(--transition-fast);
  }
  
  .newsCard:hover {
    transform: translateY(-5px);
    box-shadow: var(--box-shadow-md);
  }
  
  .featured {
    grid-column: 1 / -1;
  }
  
  .newsImage {
    width: 100%;
    height: 200px;
    object-fit: cover;
  }
  
  .newsContent {
    padding: var(--spacing-md);
  }
  
  .newsTitle {
    font-size: var(--font-size-lg);
    color: var(--color-text);
    margin-bottom: var(--spacing-sm);
  }
  
  .newsExcerpt {
    font-size: var(--font-size-base);
    color: var(--color-text-secondary);
    margin-bottom: var(--spacing-md);
  }
  
  .newsMetadata {
    display: flex;
    justify-content: space-between;
    font-size: var(--font-size-sm);
    color: var(--color-text-tertiary);
    margin-bottom: var(--spacing-sm);
  }
  
  .readMore {
    display: inline-block;
    color: var(--color-primary);
    text-decoration: none;
    font-weight: var(--font-weight-medium);
    transition: color var(--transition-fast);
  }
  
  .readMore:hover {
    color: var(--color-primary-dark);
  }
  
  @media (max-width: 768px) {
    .featured {
      grid-column: auto;
    }
  }