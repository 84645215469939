/* File: frontend/src/components/Dashboard/AllegoryIntelligenceCard.module.css */

.card {
  background-color: var(--color-white);
  border-radius: var(--border-radius-xl);
  padding: var(--spacing-2xl);
  margin-bottom: var(--spacing-md);
  box-shadow: var(--shadow-lg);
  width: 100%;
  overflow: hidden;
  position: relative;
  transition: all var(--transition-normal);
}

.contentWrapper {
  display: flex;
  justify-content: space-between;
  gap: var(--spacing-xl);
  width: 100%;
  position: relative;
  z-index: 1;
}

.textContent {
  flex: 1;
  min-width: 320px;
  padding: var(--spacing-xl);
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.title {
  font-size: var(--font-size-4xl);
  font-weight: var(--font-weight-bold);
  color: var(--text-primary);
  margin-bottom: var(--spacing-md);
  line-height: 1.2;
  letter-spacing: -0.02em;
}

.highlight {
  color: var(--color-primary);
  position: relative;
  display: inline-block;
}

.highlight::after {
  content: '';
  position: absolute;
  margin-top: 4px;
  bottom: 4px;
  left: 0;
  width: 100%;
  height: 8px;
  background-color: var(--color-primary-light);
  opacity: 0.2;
  z-index: -1;
  border-radius: var(--border-radius-sm);
}

.description {
  font-size: var(--font-size-lg);
  color: var(--text-secondary);
  margin-bottom: var(--spacing-xl);
  line-height: 1.6;
  max-width: 580px;
}

.button {
  background-color: var(--color-primary);
  color: var(--color-white);
  border: none;
  border-radius: var(--border-radius-lg);
  padding: var(--spacing-sm) var(--spacing-xl);
  font-size: var(--font-size-lg);
  font-weight: var(--font-weight-semibold);
  cursor: pointer;
  transition: all var(--transition-fast);
  align-self: flex-start;
  position: relative;
  overflow: hidden;
}

.button::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    to right,
    transparent,
    rgba(255, 255, 255, 0.1),
    transparent
  );
  transform: translateX(-100%);
  transition: transform 0.6s ease;
}

.button:hover {
  transform: translateY(-2px);
  box-shadow: var(--shadow-md);
}

.button:hover::after {
  transform: translateX(100%);
}

.button.inactive {
  background-color: var(--color-gray-400);
  cursor: not-allowed;
}

.mediaContent {
  flex: 1;
  min-width: 320px;
  aspect-ratio: 16/9;
  position: relative;
  background-color: var(--color-dark);
  border-radius: var(--border-radius-lg);
  overflow: hidden;
  box-shadow: var(--shadow-xl);
}

.animationContainer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    45deg,
    var(--color-primary-dark),
    var(--color-primary)
  );
}

.animationPlaceholder {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(
    45deg,
    var(--color-primary-dark),
    var(--color-primary)
  );
  color: var(--color-white);
}

.loadingIndicator {
  font-size: var(--font-size-md);
}

/* Large Desktop (1440px and above) */
@media (min-width: 1441px) {
  .textContent {
    padding: var(--spacing-2xl);
  }

  .title {
    font-size: var(--font-size-5xl);
  }
}

/* Desktop (1024px - 1440px) */
@media (max-width: 1440px) {
  .card {
    padding: var(--spacing-xl);
  }

  .contentWrapper {
    gap: var(--spacing-lg);
  }
}

/* Tablet Landscape (768px - 1023px) */
@media (max-width: 1023px) {
  .contentWrapper {
    flex-direction: column;
  }

  .textContent,
  .mediaContent {
    min-width: 100%;
    padding: var(--spacing-lg);
  }

  .title {
    font-size: var(--font-size-3xl);
  }

  .description {
    font-size: var(--font-size-md);
    margin-bottom: var(--spacing-lg);
  }

  .mediaContent {
    aspect-ratio: 21/9;
  }
}

/* Tablet Portrait (601px - 767px) */
@media (max-width: 767px) {
  .card {
    padding: var(--spacing-md);
    margin-bottom: var(--spacing-md);
  }

  .textContent {
    padding: var(--spacing-md);
  }

  .title {
    font-size: var(--font-size-2xl);
  }

  .button {
    width: 100%;
    padding: var(--spacing-sm) var(--spacing-lg);
    font-size: var(--font-size-md);
  }
}

/* Mobile (600px and below) */
@media (max-width: 600px) {
  .card {
    padding: var(--spacing-sm);
    border-radius: var(--border-radius-lg);
  }

  .textContent {
    padding: var(--spacing-sm);
  }

  .title {
    font-size: var(--font-size-xl);
    margin-bottom: var(--spacing-sm);
  }

  .description {
    font-size: var(--font-size-sm);
    margin-bottom: var(--spacing-md);
  }

  .mediaContent {
    aspect-ratio: 16/9;
    border-radius: var(--border-radius-md);
  }

  .button {
    font-size: var(--font-size-sm);
  }
}