/* File: frontend/src/pages/Quote/components/SavingsCalculator/SavingsCalculatorSection.module.css */

.calculatorSection {
  padding: var(--spacing-4xl) var(--spacing-md);
  background-color: var(--color-gray-100);
  position: relative;
  overflow: hidden;
}

.calculatorSection::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(circle at 75% 25%, rgba(35, 72, 237, 0.08) 0%, transparent 60%);
  pointer-events: none;
  z-index: 0;
}

.sectionContent {
  max-width: 1200px;
  margin: 0 auto;
  position: relative;
  z-index: 1;
}

.sectionHeader {
  text-align: center;
  max-width: 800px;
  margin: 0 auto var(--spacing-3xl);
  padding: 0 var(--spacing-md);
}

.sectionHeader h2 {
  font-size: clamp(var(--font-size-2xl), 4vw, var(--font-size-3xl));
  font-weight: var(--font-weight-bold);
  color: var(--text-primary);
  margin-bottom: var(--spacing-md);
}

.sectionHeader p {
  font-size: clamp(var(--font-size-base), 2vw, var(--font-size-lg));
  color: var(--text-secondary);
  line-height: var(--line-height-relaxed);
}

.calculatorWrapper {
  margin: 0 auto;
  max-width: 100%;
  /* Remove transform that could affect dropdown positioning */
  /* transition: transform 0.3s ease; */
}

.explanationText {
  margin-top: var(--spacing-3xl);
  background-color: var(--color-white);
  padding: var(--spacing-xl);
  border-radius: var(--border-radius-lg);
  box-shadow: var(--shadow-md);
  /* Remove transform that could affect positioning */
  /* transition: transform 0.3s ease, box-shadow 0.3s ease; */
  position: relative;
  z-index: 1;
}

/* Remove hover transform that could affect positioning */
/* .explanationText:hover {
  box-shadow: var(--shadow-lg);
  transform: translateY(-4px);
} */

.explanationText h3 {
  font-size: clamp(var(--font-size-lg), 3vw, var(--font-size-xl));
  font-weight: var(--font-weight-bold);
  color: var(--text-primary);
  margin-bottom: var(--spacing-lg);
  text-align: center;
}

.explanationItems {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: var(--spacing-xl);
  justify-content: center;
}

.explanationItem {
  display: flex;
  gap: var(--spacing-md);
  padding: var(--spacing-sm);
  border-radius: var(--border-radius-md);
}

.explanationItem:hover {
  background-color: var(--color-gray-50);
}

.explanationIcon {
  font-size: 24px;
  background-color: var(--color-primary-100);
  width: 52px;
  height: 52px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  box-shadow: 0 4px 12px rgba(35, 72, 237, 0.1);
}

.explanationContent {
  flex: 1;
}

.explanationContent h4 {
  font-size: var(--font-size-md);
  font-weight: var(--font-weight-semibold);
  color: var(--text-primary);
  margin-bottom: var(--spacing-xs);
}

.explanationContent p {
  font-size: var(--font-size-sm);
  color: var(--text-secondary);
  line-height: var(--line-height-normal);
}

/* Desktop-first responsive design with enhanced breakpoints */
@media (min-width: 1400px) {
  .explanationItems {
    grid-template-columns: repeat(3, 1fr);
  }
  
  .explanationItem {
    padding: var(--spacing-md);
  }
  
  .explanationIcon {
    width: 60px;
    height: 60px;
    font-size: 28px;
  }
  
  .explanationContent h4 {
    font-size: var(--font-size-lg);
  }
  
  .explanationContent p {
    font-size: var(--font-size-base);
  }
}

@media (max-width: 1200px) {
  .calculatorSection {
    padding: var(--spacing-3xl) var(--spacing-md);
  }
  
  .sectionContent {
    padding: 0 var(--spacing-sm);
  }
  
  .explanationItems {
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
    gap: var(--spacing-lg);
  }
}

@media (max-width: 992px) {
  .calculatorSection {
    padding: var(--spacing-2xl) var(--spacing-md);
  }
  
  .sectionHeader {
    margin-bottom: var(--spacing-2xl);
  }
  
  .explanationText {
    padding: var(--spacing-lg);
    margin-top: var(--spacing-2xl);
  }
  
  .explanationItems {
    gap: var(--spacing-md);
  }
}

@media (max-width: 768px) {
  .calculatorSection {
    padding: var(--spacing-2xl) var(--spacing-sm);
  }
  
  .sectionHeader h2 {
    margin-bottom: var(--spacing-sm);
  }
  
  .sectionHeader p {
    font-size: var(--font-size-base);
  }
  
  .explanationText {
    padding: var(--spacing-md);
    margin-top: var(--spacing-xl);
  }
  
  .explanationText h3 {
    font-size: var(--font-size-lg);
    margin-bottom: var(--spacing-md);
  }
  
  .explanationItems {
    grid-template-columns: 1fr;
    gap: var(--spacing-md);
  }
  
  .explanationItem {
    max-width: 100%;
  }
  
  .explanationIcon {
    margin-top: var(--spacing-xxs);
  }
}

@media (max-width: 480px) {
  .calculatorSection {
    padding: var(--spacing-lg) var(--spacing-xs);
  }
  
  .sectionHeader {
    margin-bottom: var(--spacing-lg);
  }
  
  .explanationText {
    padding: var(--spacing-md) var(--spacing-sm);
    margin-top: var(--spacing-lg);
  }
  
  .explanationItem {
    gap: var(--spacing-sm);
    padding: var(--spacing-xs);
  }
  
  .explanationIcon {
    width: 44px;
    height: 44px;
    font-size: 20px;
  }
  
  .explanationContent h4 {
    font-size: var(--font-size-base);
  }
  
  .explanationContent p {
    font-size: var(--font-size-xs);
  }
}

@media (max-width: 360px) {
  .calculatorSection {
    padding: var(--spacing-md) var(--spacing-xs);
  }
  
  .sectionHeader h2 {
    font-size: var(--font-size-xl);
  }
  
  .explanationIcon {
    width: 40px;
    height: 40px;
    font-size: 18px;
  }
  
  .explanationContent p {
    font-size: var(--font-size-2xs);
  }
}

/* Commented out since we're already removing transforms
@media (prefers-reduced-motion: reduce) {
  .calculatorSection,
  .explanationItem,
  .explanationIcon,
  .explanationText {
    transition: none;
    animation: none;
    transform: none;
  }
}
*/