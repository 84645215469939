/* File: frontend/src/pages/SuperAdmin/components/ProductManagement/ProductDetail/ProductDetail.module.css */

.container {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-md);
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: var(--spacing-md);
}

.headerContent {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xs);
}

.productName {
  font-size: var(--font-size-xl);
  font-weight: var(--font-weight-bold);
  color: var(--text-primary);
  margin: 0;
}

.productId {
  font-size: var(--font-size-sm);
  color: var(--text-secondary);
  font-family: var(--font-family-mono);
}

.statusContainer {
  display: flex;
  gap: var(--spacing-sm);
  margin-top: var(--spacing-xs);
}

.statusBadge, .categoryBadge {
  display: inline-block;
  padding: 4px 8px;
  border-radius: 12px;
  font-size: var(--font-size-xs);
  font-weight: var(--font-weight-medium);
  text-transform: capitalize;
}

.statusActive {
  background-color: var(--color-success-bg);
  color: var(--color-success);
}

.statusInactive {
  background-color: var(--color-error-bg);
  color: var(--color-error);
}

.statusDevelopment {
  background-color: var(--color-info-bg);
  color: var(--color-info);
}

.categoryBadge {
  background-color: var(--color-primary-100);
  color: var(--color-primary);
}

.headerActions {
  display: flex;
  gap: var(--spacing-sm);
}

.backButton, .featuresButton, .editButton {
  display: flex;
  align-items: center;
  gap: var(--spacing-xs);
  padding: var(--spacing-sm) var(--spacing-md);
  border-radius: var(--border-radius-md);
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-medium);
  cursor: pointer;
  transition: all var(--transition-fast);
}

.backButton {
  background-color: var(--bg-secondary);
  color: var(--text-primary);
  border: 1px solid var(--color-border);
}

.backButton:hover {
  background-color: var(--bg-hover);
}

.featuresButton {
  background-color: var(--color-info-bg);
  color: var(--color-info);
  border: 1px solid var(--color-info);
}

.featuresButton:hover {
  background-color: var(--color-info);
  color: white;
}

.editButton {
  background-color: var(--color-primary);
  color: white;
  border: none;
}

.editButton:hover {
  background-color: var(--color-primary-dark);
}

.tabs {
  display: flex;
  border-bottom: 1px solid var(--color-border);
  margin-bottom: var(--spacing-md);
  overflow-x: auto;
}

.tab {
  padding: var(--spacing-sm) var(--spacing-md);
  background: none;
  border: none;
  border-bottom: 2px solid transparent;
  font-size: var(--font-size-base);
  color: var(--text-secondary);
  cursor: pointer;
  transition: all var(--transition-fast);
  white-space: nowrap;
}

.tab:hover {
  color: var(--text-primary);
}

.activeTab {
  color: var(--color-primary);
  border-bottom-color: var(--color-primary);
  font-weight: var(--font-weight-medium);
}

.tabContent {
  background-color: var(--bg-primary);
  border-radius: var(--border-radius-md);
  padding: var(--spacing-md);
}

.section {
  margin-bottom: var(--spacing-lg);
}

.section:last-child {
  margin-bottom: 0;
}

.sectionHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: var(--spacing-md);
}

.sectionTitle {
  font-size: var(--font-size-lg);
  font-weight: var(--font-weight-semibold);
  color: var(--text-primary);
  margin: 0 0 var(--spacing-md) 0;
  padding-bottom: var(--spacing-xs);
  border-bottom: 1px solid var(--color-border-light);
}

.sectionHeader .sectionTitle {
  margin: 0;
  padding: 0;
  border: none;
}

.infoGrid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: var(--spacing-md);
}

.infoItem {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xxs);
}

.infoLabel {
  font-size: var(--font-size-sm);
  color: var(--text-secondary);
  font-weight: var(--font-weight-medium);
}

.infoValue {
  color: var(--text-primary);
  word-break: break-word;
}

.description {
  font-size: var(--font-size-base);
  line-height: 1.6;
  color: var(--text-primary);
  margin: 0;
}

.featuresList {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-md);
}

.featureItem {
  padding: var(--spacing-md);
  background-color: var(--bg-secondary);
  border-radius: var(--border-radius-md);
  border: 1px solid var(--color-border-light);
}

.featureHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: var(--spacing-sm);
}

.featureName {
  font-size: var(--font-size-lg);
  font-weight: var(--font-weight-semibold);
  color: var(--text-primary);
}

.featureBadges {
  display: flex;
  gap: var(--spacing-xs);
}

.featureTypeBadge {
  display: inline-block;
  padding: 4px 8px;
  border-radius: 12px;
  font-size: var(--font-size-xs);
  font-weight: var(--font-weight-medium);
  text-transform: capitalize;
}

.typeCore {
  background-color: var(--color-success-bg);
  color: var(--color-success);
}

.typeAdvanced {
  background-color: var(--color-info-bg);
  color: var(--color-info);
}

.typePremium {
  background-color: var(--color-warning-bg);
  color: var(--color-warning);
}

.featureDescription {
  font-size: var(--font-size-base);
  color: var(--text-primary);
  margin-bottom: var(--spacing-md);
}

.featureActions {
  display: flex;
  gap: var(--spacing-sm);
}

.featureActionButton {
  display: flex;
  align-items: center;
  gap: var(--spacing-xs);
  padding: var(--spacing-xs) var(--spacing-sm);
  background-color: var(--bg-primary);
  border: 1px solid var(--color-border);
  border-radius: var(--border-radius-sm);
  font-size: var(--font-size-xs);
  color: var(--text-primary);
  cursor: pointer;
  transition: all var(--transition-fast);
}

.featureActionButton:hover {
  background-color: var(--bg-hover);
}

.addFeatureButton {
  display: flex;
  align-items: center;
  gap: var(--spacing-xs);
  padding: var(--spacing-sm) var(--spacing-md);
  background-color: var(--color-primary);
  color: white;
  border: none;
  border-radius: var(--border-radius-md);
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-medium);
  cursor: pointer;
  transition: background-color var(--transition-fast);
}

.addFeatureButton:hover {
  background-color: var(--color-primary-dark);
}

.pricingCard {
  background-color: var(--bg-secondary);
  border-radius: var(--border-radius-md);
  border: 1px solid var(--color-border-light);
  overflow: hidden;
}

.pricingHeader {
  padding: var(--spacing-lg);
  background-color: var(--color-primary-100);
  text-align: center;
}

.pricingAmount {
  font-size: var(--font-size-3xl);
  font-weight: var(--font-weight-bold);
  color: var(--color-primary);
}

.billingCycle {
  font-size: var(--font-size-base);
  color: var(--text-secondary);
}

.pricingDetails {
  padding: var(--spacing-md);
}

.pricingDetail {
  display: flex;
  justify-content: space-between;
  padding: var(--spacing-sm) 0;
  border-bottom: 1px solid var(--color-border-light);
}

.pricingDetail:last-child {
  border-bottom: none;
}

.pricingDetailLabel {
  font-weight: var(--font-weight-medium);
  color: var(--text-secondary);
}

.pricingDetailValue {
  color: var(--text-primary);
}

.discountsList {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-sm);
}

.discountItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: var(--spacing-md);
  background-color: var(--bg-secondary);
  border-radius: var(--border-radius-md);
  border: 1px solid var(--color-border-light);
}

.discountType {
  font-weight: var(--font-weight-semibold);
  color: var(--text-primary);
}

.discountDetails {
  flex: 1;
  padding: 0 var(--spacing-md);
}

.discountDescription {
  color: var(--text-secondary);
}

.discountValue {
  font-size: var(--font-size-lg);
  font-weight: var(--font-weight-bold);
  color: var(--color-success);
}

.subscriptionsTable {
  width: 100%;
  border-collapse: collapse;
}

.subscriptionsTable th, .subscriptionsTable td {
  padding: var(--spacing-sm);
  text-align: left;
  border-bottom: 1px solid var(--color-border-light);
}

.subscriptionsTable th {
  background-color: var(--bg-secondary);
  color: var(--text-secondary);
  font-weight: var(--font-weight-semibold);
  font-size: var(--font-size-sm);
}

.tableActions {
  display: flex;
  gap: var(--spacing-xs);
}

.actionButton {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 28px;
  background-color: var(--bg-secondary);
  border: 1px solid var(--color-border);
  border-radius: var(--border-radius-sm);
  color: var(--text-secondary);
  cursor: pointer;
  transition: all var(--transition-fast);
}

.actionButton:hover {
  background-color: var(--bg-hover);
  color: var(--color-primary);
  border-color: var(--color-primary);
}

.emptyState {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: var(--spacing-xl);
  background-color: var(--bg-secondary);
  border-radius: var(--border-radius-md);
  color: var(--text-secondary);
  font-style: italic;
}

.loading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: var(--spacing-xl);
  min-height: 300px;
}

.spinner {
  border: 3px solid var(--color-border);
  border-top: 3px solid var(--color-primary);
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 1s linear infinite;
  margin-bottom: var(--spacing-md);
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.error, .notFound {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: var(--spacing-xl);
  background-color: var(--bg-secondary);
  border-radius: var(--border-radius-md);
  min-height: 200px;
}

.error {
  color: var(--color-error);
}

.notFound {
  color: var(--text-secondary);
}

@media (max-width: 992px) {
  .infoGrid {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 768px) {
  .header {
    flex-direction: column;
    gap: var(--spacing-md);
  }
  
  .headerActions {
    width: 100%;
    flex-wrap: wrap;
  }
  
  .backButton, .featuresButton, .editButton {
    flex: 1;
    justify-content: center;
  }
  
  .tabs {
    flex-wrap: nowrap;
    overflow-x: auto;
  }
  
  .tab {
    padding: var(--spacing-xs) var(--spacing-sm);
  }
  
  .featureHeader {
    flex-direction: column;
    align-items: flex-start;
    gap: var(--spacing-sm);
  }
  
  .discountItem {
    flex-direction: column;
    align-items: flex-start;
    gap: var(--spacing-sm);
  }
  
  .discountDetails {
    padding: 0;
  }
  
  .subscriptionsTable {
    display: block;
    overflow-x: auto;
  }
}