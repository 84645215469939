/* File: frontend/src/pages/Contact/Contact.module.css */

.contactContainer {
    max-width: var(--breakpoint-xl);
    margin: 0 auto;
    padding: clamp(1rem, 5vw, 2rem);
    font-family: var(--font-family-primary);
    color: var(--color-text);
  }
  
  .title {
    font-size: clamp(2rem, 5vw, 2.5rem);
    color: var(--color-primary);
    margin-bottom: clamp(1rem, 3vw, 1.5rem);
    text-align: center;
  }
  
  .contentWrapper {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-lg);
  }
  
  .formSection,
  .calendlySection {
    background-color: var(--color-background-light);
    border-radius: var(--border-radius-lg);
    padding: clamp(1rem, 5vw, 2rem);
    box-shadow: var(--box-shadow-sm);
  }
  
  .sectionTitle {
    font-size: clamp(1.5rem, 4vw, 1.8rem);
    color: var(--color-primary-dark);
    margin-bottom: clamp(0.75rem, 2vw, 1rem);
  }
  
  .description {
    font-size: clamp(1rem, 2vw, 1.1rem);
    line-height: 1.6;
    margin-bottom: var(--spacing-md);
  }
  
  @media (min-width: 769px) {
    .contentWrapper {
      flex-direction: row;
      align-items: stretch;
    }
  
    .formSection,
    .calendlySection {
      flex: 1;
    }
  }
  
  @media (prefers-color-scheme: dark) {
    .contactContainer {
      color: var(--color-text-dark);
      background-color: var(--color-background-dark);
    }
  
    .formSection,
    .calendlySection {
      background-color: var(--color-background-dark-light);
    }
  }