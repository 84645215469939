/* File: frontend/src/pages/TripInsight/components/TripStatistics/TripStatistics.module.css */

.container {
  margin-bottom: var(--spacing-lg);
}

.loading {
  text-align: center;
  padding: var(--spacing-md);
  color: var(--text-secondary);
}

.statsGrid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: var(--spacing-md);
}

.statCard {
  background-color: var(--bg-primary);
  border-radius: var(--border-radius-lg);
  padding: var(--spacing-md);
  box-shadow: var(--shadow-card);
  text-align: center;
  transition: transform var(--transition-normal);
}

.statCard:hover {
  transform: translateY(-5px);
}

.statValue {
  font-size: var(--font-size-2xl);
  font-weight: var(--font-weight-bold);
  margin-bottom: var(--spacing-xs);
}

.statLabel {
  font-size: var(--font-size-sm);
  color: var(--text-secondary);
}

.total {
  border-top: 4px solid var(--color-primary);
}

.completed {
  border-top: 4px solid var(--color-success);
}

.distance {
  border-top: 4px solid var(--color-info);
}

.duration {
  border-top: 4px solid var(--color-warning);
}

@media (max-width: 768px) {
  .statsGrid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 480px) {
  .statsGrid {
    grid-template-columns: 1fr;
  }
}