/* File: frontend/src/components/Home/InsuranceManagementSection.module.css */

.insuranceManagementSection {
  min-height: 100vh;
  background-color: var(--color-black);
  color: var(--color-white);
  display: flex;
  align-items: center;
  padding: var(--spacing-lg) 0;
  box-sizing: border-box;
}

.container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: var(--spacing-lg);
  width: 100%;
  max-width: var(--breakpoint-xl);
  margin: 0 auto;
  padding: 0 var(--spacing-lg);
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.title {
  font-size: clamp(2rem, 5vw, 3rem);
  margin-bottom: var(--spacing-md);
  line-height: 1.355;
}

.description {
  font-size: clamp(1rem, 2vw, 1.25rem);
  line-height: 1.45;
  margin-bottom: var(--spacing-lg);
}

.ctaButton {
  background-color: transparent;
  color: var(--color-primary);
  transition: background-color var(--transition-normal), color var(--transition-normal);
  padding: clamp(0.5rem, 2vw, 1rem) clamp(1rem, 4vw, 2rem);
  font-size: clamp(0.875rem, 2vw, 1rem);
  min-width: 150px;
  align-self: flex-start;
}

.ctaButton:hover {
  background-color: var(--color-primary);
  color: var(--color-white);
}

.animationContainer {
  width: 100%;
  height: clamp(500px, 70vh, 800px);
  position: relative;
  overflow: hidden;
  border-radius: var(--border-radius-lg);
  background-color: var(--color-black);
  margin: 2rem 0;
  transform: translateZ(0);
  -webkit-transform: translateZ(0);
  will-change: transform;
  contain: content;
  content-visibility: auto;
  contain-intrinsic-size: 500px;
}

.particleCanvas {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  touch-action: pan-y;
  pointer-events: none;
  transform: translateZ(0);
  -webkit-transform: translateZ(0);
  will-change: transform;
  image-rendering: -webkit-optimize-contrast;
  image-rendering: crisp-edges;
}

.refreshButton {
  position: absolute;
  bottom: var(--spacing-sm);
  right: var(--spacing-sm);
  background: none;
  border: none;
  cursor: pointer;
  color: var(--color-white);
  opacity: 0.7;
  transition: opacity var(--transition-fast);
  padding: var(--spacing-xs);
}

.refreshButton:hover {
  opacity: 1;
}

.animationPlaceholder {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--color-black);
  color: var(--color-white);
  font-size: var(--font-size-lg);
}

.controls {
  position: absolute;
  bottom: var(--spacing-md);
  right: var(--spacing-md);
  display: flex;
  gap: var(--spacing-sm);
  z-index: 10;
}

.controlButton {
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: var(--color-white);
  transition: all 0.3s ease;
  padding: 0;
}

.controlButton:hover {
  background: rgba(255, 255, 255, 0.2);
  border-color: rgba(255, 255, 255, 0.3);
}

.controlButton:focus {
  outline: none;
  box-shadow: 0 0 0 2px var(--color-primary);
}

.loadingIndicator {
  position: relative;
  display: inline-block;
  opacity: 0;
  animation: fadeIn 0.3s ease-in forwards;
  animation-delay: 0.5s;
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@media (max-width: 1024px) {
  .container {
    grid-template-columns: 1fr;
  }

  .content {
    text-align: center;
  }

  .ctaButton {
    align-self: center;
  }
}

@media (max-width: 768px) {
  .insuranceManagementSection {
    padding: var(--spacing-md) 0;
  }

  .container {
    padding: 0 var(--spacing-md);
  }

  .animationContainer {
    height: clamp(400px, 60vh, 600px);
    contain: strict;
    will-change: transform;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }

  .particleCanvas {
    transform: translateZ(0);
    -webkit-transform: translateZ(0);
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    perspective: 1000;
    -webkit-perspective: 1000;
  }

  .controlButton {
    width: 36px;
    height: 36px;
  }
}

@media (max-width: 480px) {
  .animationContainer {
    height: clamp(300px, 50vh, 500px);
  }

  .controlButton {
    width: 32px;
    height: 32px;
  }
}

@media (max-aspect-ratio: 1/1) {
  .container {
    grid-template-columns: 1fr;
  }
}

@media (prefers-reduced-motion: reduce) {
  .ctaButton,
  .refreshButton {
    transition: none;
  }
}