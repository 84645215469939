/* File: frontend/src/pages/SuperAdmin/components/common/SuperAdminTable/SuperAdminTable.module.css */

.tableContainer {
  width: 100%;
  overflow-x: auto;
  border: 1px solid var(--color-border);
  border-radius: var(--border-radius-md);
}

.table {
  width: 100%;
  border-collapse: collapse;
}

.tableHeader {
  background-color: var(--bg-secondary);
  color: var(--text-secondary);
  font-weight: var(--font-weight-semibold);
  text-align: left;
  padding: var(--spacing-sm) var(--spacing-md);
  font-size: var(--font-size-sm);
  border-bottom: 1px solid var(--color-border);
  white-space: nowrap;
}

.tableRow {
  border-bottom: 1px solid var(--color-border-light);
  transition: background-color var(--transition-fast);
}

.tableRow:last-child {
  border-bottom: none;
}

.tableRow:hover {
  background-color: var(--bg-hover);
}

.clickable {
  cursor: pointer;
}

.tableCell {
  padding: var(--spacing-sm) var(--spacing-md);
  font-size: var(--font-size-sm);
  color: var(--text-primary);
}

.loading, .empty {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: var(--spacing-xl);
  background-color: var(--bg-secondary);
  border-radius: var(--border-radius-md);
  color: var(--text-secondary);
  min-height: 200px;
}

.spinner {
  border: 3px solid var(--color-border);
  border-top: 3px solid var(--color-primary);
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 1s linear infinite;
  margin-bottom: var(--spacing-md);
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}