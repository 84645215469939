/* File: frontend/src/pages/InvestorPitch/components/slides/InvestmentOpportunitySlide/InvestmentOpportunitySlide.module.css */

/* Overall container */
.container {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xl);
  padding: var(--spacing-lg);
  width: 100%;
  height: 100%;
}

/* Main header section */
.header {
  text-align: center;
  margin-bottom: var(--spacing-lg);
}

/* Title bigger than before */
.mainTitle {
  font-size: var(--font-size-5xl);
  font-weight: var(--font-weight-bold);
  color: var(--text-on-primary);
  margin-bottom: var(--spacing-md);
  line-height: 1.1;
  background: linear-gradient(135deg, var(--color-primary), var(--color-blue-510));
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}

.subtitle {
  font-size: var(--font-size-xl);
  color: var(--color-gray-400);
  max-width: 800px;
  margin: 0 auto;
  line-height: 1.5;
}

/* Two-column layout for the main content */
.gridContainer {
  display: grid;
  grid-template-columns: 1.2fr 0.8fr;
  gap: var(--spacing-xl);
  align-items: flex-start;
  flex: 1;
}

/* Column Titles (Series A / Funding Allocation) */
.columnTitle {
  font-size: var(--font-size-2xl);
  font-weight: var(--font-weight-bold);
  color: var(--color-primary-500);
  text-align: center;
  margin-bottom: var(--spacing-md);
}

/* ============= LEFT COLUMN (Series A) ============= */
.seriesAColumn {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-md);
  height: 100%;
}

/* The main card holding all bullet points */
.seriesACard {
  background: var(--color-gray-1200);
  border-radius: var(--border-radius-lg);
  padding: var(--spacing-xl);
  box-shadow: var(--shadow-lg);
  display: flex;
  flex-direction: column;
  gap: var(--spacing-md);
  border: 1px solid var(--color-gray-1100);
  flex: 1;
  overflow-y: auto;
}

/* Each bullet item (label + value) */
.seriesAItem {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xxs);
  padding: var(--spacing-sm);
  border-radius: var(--border-radius-md);
  background-color: var(--color-gray-1100);
  border: 1px solid var(--color-gray-1000);
}

/* Highlighted items */
.highlight {
  background: linear-gradient(145deg, rgba(35, 72, 237, 0.1), rgba(35, 72, 237, 0.05));
  border-color: rgba(35, 72, 237, 0.3);
}

/* If we only have one line (like "60% Loss Ratio"), we do not split label & value */
.singleLine {
  font-size: var(--font-size-lg);
  color: var(--color-white);
  font-weight: var(--font-weight-medium);
}

/* Label in smaller text, the actual number or value is bigger */
.seriesALabel {
  font-size: var(--font-size-base);
  font-weight: var(--font-weight-medium);
  color: var(--color-gray-400);
}

.seriesAValue {
  font-size: var(--font-size-xl);
  font-weight: var(--font-weight-bold);
  color: var(--text-on-primary);
}

/* ============= RIGHT COLUMN (Funding Allocation) ============= */
.allocationColumn {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-md);
  height: 100%;
}

/* 2x2 grid for the funding cards */
.allocationGrid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: var(--spacing-lg);
  flex: 1;
}

/* Each allocation item is a card with bigger numbers */
.allocationCard {
  background: var(--color-gray-1200);
  box-shadow: var(--shadow-md);
  border-radius: var(--border-radius-lg);
  padding: var(--spacing-lg);
  display: flex;
  flex-direction: column;
  gap: var(--spacing-sm);
  border: 1px solid var(--color-gray-1100);
  position: relative;
  overflow: hidden;
}

.allocationCard::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 4px;
  height: 100%;
  background: var(--color-primary-700);
}

.allocationLabel {
  font-size: var(--font-size-base);
  font-weight: var(--font-weight-medium);
  color: var(--color-gray-400);
}

.allocationAmount {
  font-size: var(--font-size-xl);
  font-weight: var(--font-weight-bold);
  color: var(--text-on-primary);
}

.allocationPercentage {
  font-size: var(--font-size-lg);
  font-weight: var(--font-weight-semibold);
  color: var(--color-primary-500);
}

/* Responsive adjustments */
@media (max-width: 1200px) {
  .gridContainer {
    gap: var(--spacing-lg);
  }
  
  .seriesACard, .allocationCard {
    padding: var(--spacing-lg);
  }
  
  .seriesAValue {
    font-size: var(--font-size-lg);
  }
  
  .allocationAmount {
    font-size: var(--font-size-lg);
  }
  
  .allocationPercentage {
    font-size: var(--font-size-base);
  }
}

@media (max-width: 992px) {
  .mainTitle {
    font-size: var(--font-size-4xl);
  }
  
  .subtitle {
    font-size: var(--font-size-lg);
    max-width: 90%;
  }
  
  .columnTitle {
    font-size: var(--font-size-xl);
  }
  
  .singleLine {
    font-size: var(--font-size-base);
  }
  
  .seriesACard {
    gap: var(--spacing-sm);
  }
  
  .allocationGrid {
    gap: var(--spacing-md);
  }
}

@media (max-width: 768px) {
  .gridContainer {
    grid-template-columns: 1fr;
    gap: var(--spacing-xl);
  }
  
  .mainTitle {
    font-size: var(--font-size-3xl);
  }
  
  .subtitle {
    font-size: var(--font-size-md);
    max-width: 100%;
  }
  
  .seriesACard {
    padding: var(--spacing-md);
  }
  
  .seriesAItem {
    padding: var(--spacing-xs);
  }
  
  .allocationGrid {
    grid-template-columns: repeat(2, 1fr);
  }
  
  .allocationCard {
    padding: var(--spacing-md);
  }
}

@media (max-width: 576px) {
  .container {
    padding: var(--spacing-md);
  }
  
  .mainTitle {
    font-size: var(--font-size-2xl);
  }
  
  .subtitle {
    font-size: var(--font-size-base);
  }
  
  .columnTitle {
    font-size: var(--font-size-lg);
  }
  
  .seriesALabel {
    font-size: var(--font-size-sm);
  }
  
  .seriesAValue {
    font-size: var(--font-size-base);
  }
  
  .allocationGrid {
    grid-template-columns: 1fr;
    gap: var(--spacing-sm);
  }
  
  .allocationLabel {
    font-size: var(--font-size-sm);
  }
  
  .allocationAmount {
    font-size: var(--font-size-base);
  }
  
  .allocationPercentage {
    font-size: var(--font-size-sm);
  }
}

@media (max-width: 480px) {
  .mainTitle {
    font-size: var(--font-size-xl);
  }
  
  .singleLine {
    font-size: var(--font-size-sm);
  }
}