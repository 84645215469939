/* File: frontend/src/components/Sidebar/SidebarTop.module.css */

.sidebarTop {
  position: relative;
  padding: var(--spacing-md);
  border-bottom: 1px solid var(--color-gray-1200);
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--color-gray-1300);
}

.logoContainer {
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex: 1;
}

.logo {
  color: var(--color-white);
}

.closeButton {
  display: none;
}

.closeButton:hover {
  color: var(--color-gray-300);
  background-color: var(--color-gray-1200);
}

/* Desktop styles */
@media (min-width: 769px) {
  .closeButton {
    display: none;
  }

  .sidebarTop {
    justify-content: center;
  }

  .logoContainer {
    justify-content: center;
  }
}

/* Mobile styles */
@media (max-width: 768px) {
  .sidebarTop {
    padding: var(--spacing-md) var(--spacing-sm);
    min-height: 64px;
  }

  .closeButton {
    display: flex;
    align-items: center;
    justify-content: center;
    /* padding: var(--spacing-sm); */
    width: 40px;
    height: 40px;
    background: none;
    border: none;
    color: var(--color-gray-1300);
    cursor: pointer;
    border-radius: var(--border-radius-md);
    transition: all var(--transition-normal);
    background-color: var(--color-gray-100);
  }

  .closeButton:hover {
    color: var(--color-gray-300);
    background-color: var(--color-gray-1200);
  }

  .logoContainer {
    justify-content: center;
    margin-left: var(--spacing-xl);
  }

  /* Ensure logo is visible on mobile */
  .logo {
    transform: none !important;
    opacity: 1 !important;
  }
}