/* File: frontend/src/pages/InvestorPitch/components/slides/IntroSlide/IntroSlide.module.css */

.container {
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xl);
  padding: var(--spacing-md);
}

.header {
  text-align: center;
  margin-bottom: var(--spacing-xl);
}

.title {
  font-size: var(--font-size-5xl);
  font-weight: var(--font-weight-bold);
  color: var(--text-on-primary);
  margin-bottom: var(--spacing-md);
  line-height: var(--line-height-tight);
}

.subtitle {
  font-size: var(--font-size-2xl);
  color: var(--color-gray-400);
  max-width: 800px;
  margin: 0 auto;
  line-height: var(--line-height-normal);
}

.statsGrid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: var(--spacing-lg);
  padding: 0 var(--spacing-md);
}

@media (max-width: 1024px) {
  .title {
    font-size: var(--font-size-4xl);
  }
  
  .subtitle {
    font-size: var(--font-size-xl);
  }
}

@media (max-width: 768px) {
  .statsGrid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 480px) {
  .container {
    gap: var(--spacing-md);
  }
  
  .header {
    margin-bottom: var(--spacing-lg);
  }
  
  .title {
    font-size: var(--font-size-3xl);
  }
  
  .subtitle {
    font-size: var(--font-size-lg);
  }
  
  .statsGrid {
    grid-template-columns: 1fr;
    padding: 0;
  }
}