/* File: frontend/src/pages/InvestorPitch/components/slides/CompetitiveAdvantageSlide/DifferentiatorCard.module.css */

.container {
    background-color: var(--color-gray-1200);
    border-radius: var(--border-radius-lg);
    border: 1px solid var(--color-gray-1100);
    overflow: hidden;
    height: 100%;
  }
  
  .header {
    padding: var(--spacing-xl);
    text-align: center;
    position: relative;
  }
  
  .iconWrapper {
    width: 48px;
    height: 48px;
    border-radius: var(--border-radius-full);
    background-color: rgba(255, 255, 255, 0.1);
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto var(--spacing-md);
  }
  
  .icon {
    width: 24px;
    height: 24px;
    color: var(--color-white);
  }
  
  .title {
    font-size: var(--font-size-xl);
    font-weight: var(--font-weight-bold);
    color: var(--color-white);
    margin-bottom: var(--spacing-xs);
  }
  
  .comparison {
    font-size: var(--font-size-sm);
    color: var(--color-white);
    opacity: 0.8;
  }
  
  .content {
    padding: var(--spacing-xl);
  }
  
  .description {
    font-size: var(--font-size-base);
    color: var(--color-gray-400);
    margin-bottom: var(--spacing-xl);
    line-height: 1.5;
  }
  
  .metrics {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: var(--spacing-lg);
  }
  
  .metric {
    text-align: center;
  }
  
  .metricLabel {
    font-size: var(--font-size-sm);
    color: var(--color-gray-400);
    margin-bottom: var(--spacing-xs);
  }
  
  .metricValue {
    font-size: var(--font-size-lg);
    font-weight: var(--font-weight-bold);
    color: var(--text-on-primary);
  }
  
  @media (max-width: 768px) {
    .header {
      padding: var(--spacing-lg);
    }
  
    .content {
      padding: var(--spacing-lg);
    }
  }