/* File: frontend/src/pages/UMS/UserDetail/components/UserHeader/UserHeader.module.css (updated) */

.container {
  margin-bottom: var(--spacing-md);
  padding-bottom: var(--spacing-md);
  border-bottom: 1px solid var(--color-border);
}

.userInfo {
  display: flex;
  align-items: center;
  gap: var(--spacing-md);
}

.userAvatar {
  width: 64px;
  height: 64px;
  border-radius: 50%;
  object-fit: cover;
}

.userInitials {
  width: 64px;
  height: 64px;
  border-radius: 50%;
  background-color: var(--color-primary-100);
  color: var(--color-primary);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: var(--font-size-lg);
  font-weight: var(--font-weight-bold);
}

.userDetails {
  flex: 1;
}

.userMeta {
  display: flex;
  gap: var(--spacing-md);
  margin-bottom: var(--spacing-xs);
}

.metaItem {
  display: flex;
  align-items: center;
}

.metaLabel {
  font-weight: var(--font-weight-medium);
  color: var(--text-secondary);
  margin-right: var(--spacing-xxs);
}

.metaValue {
  color: var(--text-primary);
}

.statusActive {
  color: var(--color-success);
  font-weight: var(--font-weight-semibold);
}

.statusPending {
  color: var(--color-info);
  font-weight: var(--font-weight-semibold);
}

.statusSuspended, .statusDeactivated {
  color: var(--color-error);
  font-weight: var(--font-weight-semibold);
}

.statusLocked {
  color: var(--color-warning);
  font-weight: var(--font-weight-semibold);
}

.contactInfo {
  display: flex;
  gap: var(--spacing-md);
}

.contactItem {
  display: flex;
  align-items: center;
  gap: var(--spacing-xs);
  color: var(--text-secondary);
  font-size: var(--font-size-sm);
}

.verifiedBadge {
  background-color: var(--color-success-100);
  color: var(--color-success);
  padding: 2px 6px;
  border-radius: var(--border-radius-sm);
  font-size: var(--font-size-xs);
  font-weight: var(--font-weight-medium);
}

@media (max-width: 768px) {
  .userInfo {
    flex-direction: column;
    align-items: flex-start;
  }
  
  .userMeta, .contactInfo {
    flex-direction: column;
    gap: var(--spacing-xs);
  }
}