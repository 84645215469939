/* File: frontend/src/pages/AI1/components/MessageBubble/MessageBubble.module.css */

.bubbleWrapper {
  display: flex;
  flex-direction: column;
  /* max-width: 80%; */
}

.userMessage {
  align-self: flex-end;
  max-width: 80%;
}

.assistantMessage {
  align-self: flex-start;
  max-width: 95%;
}

.bubble {
  padding: var(--spacing-xxxs) var(--spacing-md);
  border-radius: var(--border-radius-lg);
  background-color: var(--color-white);
  box-shadow: var(--shadow-sm);
}

.userMessage .bubble {
  background-color: var(--color-primary);
  color: var(--color-white);
}

.timestamp {
  font-size: var(--font-size-xs);
  color: var(--text-tertiary);
  margin-top: var(--spacing-xxs);
  padding: 0 var(--spacing-xs);
}

.paragraph {
  margin: var(--spacing-xs) 0;
  line-height: var(--line-height-normal);
}

.orderedList {
  margin: 0.5rem 0;
  padding-left: 1.5rem;
  counter-reset: item;
}

.orderedList > .listItem {
  counter-increment: item;
}

.list {
  margin: var(--spacing-xs) 0;
  padding-left: var(--spacing-md);
}

.listItem {
  margin: var(--spacing-xxs) 0;
  line-height: var(--line-height-normal);
}

.link {
  color: var(--color-primary);
  text-decoration: none;
}

.userMessage .link {
  color: var(--color-white);
  text-decoration: underline;
}

.inlineCode {
  background-color: var(--bg-inline-code);
  padding: 2px 4px;
  border-radius: 4px;
  font-family: monospace;
  font-size: 0.9em;
}

.userMessage .inlineCode {
  background-color: rgba(255, 255, 255, 0.2);
  color: var(--color-white);
}

/* Ensure nested lists maintain proper spacing */
.list .list,
.orderedList .orderedList {
  margin: var(--spacing-xxxs) 0;
}