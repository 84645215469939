/* File: frontend/src/pages/InvestorPitch/components/slides/CompetitiveAdvantageSlide/MarketPosition.module.css */

.container {
    background-color: var(--color-gray-1200);
    border-radius: var(--border-radius-lg);
    padding: var(--spacing-xl);
    border: 1px solid var(--color-gray-1100);
    display: flex;
    flex-direction: column;
    gap: var(--spacing-xl);
  }
  
  .highlight {
    display: flex;
    align-items: center;
    gap: var(--spacing-lg);
  }
  
  .iconWrapper {
    width: 48px;
    height: 48px;
    border-radius: var(--border-radius-lg);
    background-color: var(--color-gray-1100);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
  }
  
  .icon {
    width: 24px;
    height: 24px;
  }
  
  .content {
    flex: 1;
  }
  
  .title {
    font-size: var(--font-size-base);
    color: var(--color-gray-400);
    margin-bottom: var(--spacing-xs);
  }
  
  .value {
    font-size: var(--font-size-lg);
    font-weight: var(--font-weight-bold);
    color: var(--text-on-primary);
  }
  
  @media (max-width: 768px) {
    .container {
      padding: var(--spacing-lg);
    }
  
    .highlight {
      gap: var(--spacing-md);
    }
  
    .iconWrapper {
      width: 40px;
      height: 40px;
    }
  
    .icon {
      width: 20px;
      height: 20px;
    }
  
    .value {
      font-size: var(--font-size-base);
    }
  }