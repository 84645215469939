/* File: frontend/src/pages/InvestorPitch/components/slides/SecuritySlide/SecuritySlide.module.css */

.container {
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-lg);
  padding: var(--spacing-md);
  width: 100%;
}

.header {
  text-align: center;
  margin-bottom: var(--spacing-md);
}

.title {
  font-size: var(--font-size-4xl);
  font-weight: var(--font-weight-bold);
  color: var(--text-on-primary);
  margin-bottom: var(--spacing-md);
  background: linear-gradient(135deg, var(--color-primary), var(--color-blue-510));
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}

.subtitle {
  font-size: var(--font-size-xl);
  color: var(--color-gray-400);
  max-width: 800px;
  margin: 0 auto;
}

.content {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xl);
  flex: 1;
  /* overflow-y: auto; */
}

.sectionTitle {
  font-size: var(--font-size-xl);
  font-weight: var(--font-weight-bold);
  color: var(--text-on-primary);
  margin-bottom: var(--spacing-md);
  display: flex;
  align-items: center;
  gap: var(--spacing-sm);
}

.sectionTitle::before {
  content: '';
  width: 4px;
  height: 20px;
  background: linear-gradient(
    to bottom,
    var(--color-primary-500),
    var(--color-primary-700)
  );
  border-radius: var(--border-radius-sm);
}

.securitySection {
  margin-bottom: var(--spacing-md);
}

.detailsSection {
  display: grid;
  grid-template-columns: 1.5fr 1fr;
  gap: var(--spacing-xl);
  flex: 1;
}

@media (max-width: 1200px) {
  .detailsSection {
    grid-template-columns: 1fr;
    gap: var(--spacing-lg);
  }
}

@media (max-width: 992px) {
  .title {
    font-size: var(--font-size-3xl);
  }
  
  .subtitle {
    font-size: var(--font-size-lg);
    max-width: 90%;
  }
  
  .sectionTitle {
    font-size: var(--font-size-lg);
  }
  
  .sectionTitle::before {
    height: 16px;
  }
}

@media (max-width: 768px) {
  .title {
    font-size: var(--font-size-2xl);
  }
  
  .subtitle {
    font-size: var(--font-size-md);
    max-width: 100%;
  }
  
  .content {
    gap: var(--spacing-md);
  }
}

@media (max-width: 480px) {
  .container {
    padding: var(--spacing-sm);
  }
  
  .title {
    font-size: var(--font-size-xl);
  }
  
  .subtitle {
    font-size: var(--font-size-base);
  }
}