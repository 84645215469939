/* File: frontend/src/pages/SuperAdmin/components/common/SuperAdminCard/SuperAdminCard.module.css */

.card {
  background-color: var(--bg-primary);
  border-radius: var(--border-radius-md);
  padding: var(--spacing-md);
  box-shadow: var(--shadow-sm);
  display: flex;
  align-items: center;
  transition: transform var(--transition-fast), box-shadow var(--transition-fast);
  border-left: 4px solid transparent;
}

.card:hover {
  transform: translateY(-3px);
  box-shadow: var(--shadow-md);
}

.clickable {
  cursor: pointer;
}

.iconContainer {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: var(--spacing-md);
  flex-shrink: 0;
}

.content {
  flex: 1;
}

.title {
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-medium);
  color: var(--text-secondary);
  margin: 0 0 var(--spacing-xs) 0;
}

.value {
  font-size: var(--font-size-xl);
  font-weight: var(--font-weight-bold);
  color: var(--text-primary);
}

.loading {
  height: 24px;
  display: flex;
  align-items: center;
}

.loadingBar {
  width: 60%;
  height: 8px;
  background-color: var(--bg-secondary);
  border-radius: 4px;
  overflow: hidden;
  position: relative;
}

.loadingBar::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 30%;
  height: 100%;
  background-color: var(--color-border);
  border-radius: 4px;
  animation: loading 1.5s infinite;
}

@keyframes loading {
  0% {
    left: -30%;
  }
  100% {
    left: 100%;
  }
}

/* Card color variants */
.primary {
  border-left-color: var(--color-primary);
}

.primary .iconContainer {
  background-color: var(--color-primary-100);
  color: var(--color-primary);
}

.success {
  border-left-color: var(--color-success);
}

.success .iconContainer {
  background-color: var(--color-success-bg);
  color: var(--color-success);
}

.info {
  border-left-color: var(--color-info);
}

.info .iconContainer {
  background-color: var(--color-info-bg);
  color: var(--color-info);
}

.warning {
  border-left-color: var(--color-warning);
}

.warning .iconContainer {
  background-color: var(--color-warning-bg);
  color: var(--color-warning);
}

.danger {
  border-left-color: var(--color-error);
}

.danger .iconContainer {
  background-color: var(--color-error-bg);
  color: var(--color-error);
}