/* File: frontend/src/pages/SuperAdmin/components/SubscriptionManagement/SubscriptionManagement.module.css */

.container {
  display: flex;
  flex-direction: column;
}

.title {
  font-size: var(--font-size-2xl);
  font-weight: var(--font-weight-bold);
  color: var(--text-primary);
  margin: 0 0 var(--spacing-lg) 0;
}