/* File: frontend/src/pages/AutoInsight/AutoInsight.module.css (updated) */

.container {
  padding: var(--spacing-lg);
  max-width: 1200px;
  margin: 0 auto;
  background-color: var(--bg-secondary, #F7F7F8);
}

.pageHeader {
  margin-bottom: var(--spacing-lg);
}

.pageTitle {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: var(--spacing-xs);
}

.pageIconArea {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: var(--spacing-xs);
}

.pageIcon {
  width: 28px;
  height: 28px;
}

.title {
  font-size: var(--font-size-xl);
  font-weight: var(--font-weight-bold);
  color: var(--text-primary);
  margin: 0;
  line-height: 36px;
}

.searchPanels {
  margin-bottom: var(--spacing-lg);
}

.statsSection {
  margin-bottom: var(--spacing-lg);
}

.chartSection {
  margin-bottom: var(--spacing-lg);
}

.reportsSection {
  margin-bottom: var(--spacing-lg);
}

.error {
  background-color: var(--color-error-bg);
  color: var(--color-error);
  padding: var(--spacing-md);
  border-radius: var(--border-radius-md);
  margin-bottom: var(--spacing-md);
}

@media (max-width: 768px) {
  .container {
    padding: var(--spacing-md);
  }
  
  .searchPanels {
    flex-direction: column;
  }
}