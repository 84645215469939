/* File: frontend/src/components/UploadSection/UploadAreaFile.module.css */

.uploadAreaFile {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: var(--spacing-md);
    background-color: var(--bg-secondary);
    border-radius: var(--border-radius-md);
  }
  
  .fileInfo {
    display: flex;
    align-items: center;
    gap: var(--spacing-sm);
  }
  
  .fileName {
    font-weight: 500;
  }
  
  .fileSize {
    font-size: var(--font-size-sm);
    color: var(--text-secondary);
    padding: var(--spacing-xs) var(--spacing-sm);
    background-color: var(--bg-tertiary);
    border-radius: var(--border-radius-sm);
  }
  
  .actions {
    display: flex;
    gap: var(--spacing-xs);
  }