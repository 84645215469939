/* File: frontend/src/pages/TripDetail/components/TripErrors/TripErrors.module.css */

.container {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-md);
}

.loading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: var(--spacing-xl);
  min-height: 200px;
}

.loadingSpinner {
  border: 3px solid var(--color-border);
  border-top: 3px solid var(--color-primary);
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 1s linear infinite;
  margin-bottom: var(--spacing-md);
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.error {
  background-color: var(--color-error-bg);
  color: var(--color-error);
  padding: var(--spacing-md);
  border-radius: var(--border-radius-md);
  margin-bottom: var(--spacing-md);
}

.errorSummary {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: var(--spacing-md);
}

.summaryCard {
  background-color: var(--bg-secondary);
  border-radius: var(--border-radius-md);
  padding: var(--spacing-md);
  text-align: center;
}

.summaryTitle {
  font-size: var(--font-size-sm);
  color: var(--text-secondary);
  margin-bottom: var(--spacing-xs);
}

.summaryValue {
  font-size: var(--font-size-2xl);
  font-weight: var(--font-weight-bold);
  color: var(--color-error);
}

.errorsContainer {
  background-color: var(--bg-primary);
  border-radius: var(--border-radius-md);
  border: 1px solid var(--color-border);
  overflow: hidden;
}

.errorsTitle {
  font-size: var(--font-size-md);
  font-weight: var(--font-weight-semibold);
  padding: var(--spacing-sm);
  background-color: var(--bg-secondary);
  border-bottom: 1px solid var(--color-border);
  margin: 0;
}

.errorsList {
  padding: var(--spacing-md);
  display: flex;
  flex-direction: column;
  gap: var(--spacing-md);
}

.errorCard {
  background-color: var(--bg-secondary);
  border-radius: var(--border-radius-md);
  border-left: 4px solid var(--color-error);
  overflow: hidden;
}

.errorHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: var(--spacing-sm);
  background-color: var(--color-error-bg);
  border-bottom: 1px solid var(--color-border);
}

.errorType {
  font-weight: var(--font-weight-semibold);
  color: var(--color-error);
}

.errorDate {
  font-size: var(--font-size-sm);
  color: var(--text-secondary);
}

.errorDetail {
  padding: var(--spacing-md);
  color: var(--text-primary);
  font-weight: var(--font-weight-medium);
  border-bottom: 1px solid var(--color-border-light);
}

.errorMeta {
  padding: var(--spacing-sm);
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: var(--spacing-sm);
}

.metaItem {
  display: flex;
  flex-direction: column;
}

.metaLabel {
  font-size: var(--font-size-xs);
  color: var(--text-secondary);
  margin-bottom: var(--spacing-xxs);
}

.metaValue {
  font-size: var(--font-size-sm);
  color: var(--text-primary);
  word-break: break-all;
}

.noErrors {
  background-color: var(--bg-secondary);
  border-radius: var(--border-radius-md);
  padding: var(--spacing-xl);
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 300px;
}

.noErrorsIcon {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: var(--color-success);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 32px;
  margin-bottom: var(--spacing-md);
}

.noErrorsTitle {
  font-size: var(--font-size-lg);
  font-weight: var(--font-weight-semibold);
  color: var(--text-primary);
  margin-bottom: var(--spacing-sm);
}

.noErrorsDescription {
  color: var(--text-secondary);
  max-width: 500px;
}

@media (max-width: 768px) {
  .errorSummary {
    grid-template-columns: 1fr;
  }
  
  .errorMeta {
    grid-template-columns: 1fr;
  }
}