/* File: frontend/src/pages/InvestorPitch/components/slides/InvestmentSlide/FundingCard.module.css */

.container {
    background-color: var(--color-gray-1200);
    border-radius: var(--border-radius-lg);
    padding: var(--spacing-xl);
    border: 1px solid var(--color-gray-1100);
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: var(--spacing-lg);
  }
  
  .iconWrapper {
    width: 56px;
    height: 56px;
    border-radius: var(--border-radius-lg);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: var(--spacing-md);
  }
  
  .icon {
    width: 28px;
    height: 28px;
    color: var(--color-white);
  }
  
  .title {
    font-size: var(--font-size-xl);
    font-weight: var(--font-weight-bold);
    color: var(--text-on-primary);
  }
  
  .amount {
    font-size: var(--font-size-3xl);
    font-weight: var(--font-weight-bold);
    color: var(--color-primary-500);
  }
  
  .details {
    list-style: none;
    padding: 0;
    margin: 0;
    color: var(--color-gray-400);
    font-size: var(--font-size-base);
    line-height: 1.6;
  }
  
  .details li {
    position: relative;
    padding-left: var(--spacing-lg);
    margin-bottom: var(--spacing-sm);
  }
  
  .details li::before {
    content: "•";
    position: absolute;
    left: 0;
    color: var(--color-primary-500);
  }
  
  @media (max-width: 768px) {
    .container {
      padding: var(--spacing-lg);
    }
  
    .amount {
      font-size: var(--font-size-2xl);
    }
  }