/* frontend/src/components/Dashboard/GlobalStatsCard.module.css */

.card {
  background-color: var(--color-white);
  border-radius: var(--border-radius-xl);
  padding: var(--spacing-xl);
  box-shadow: var(--shadow-sm);
  display: flex;
  flex-direction: column;
  gap: var(--spacing-lg);
  margin-bottom: var(--spacing-md);
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: var(--spacing-md);
}

.title {
  font-size: var(--font-size-2xl);
  font-weight: var(--font-weight-semibold);
  color: var(--text-primary);
}

.categoryButtons {
  display: flex;
  gap: var(--spacing-sm);
  flex-wrap: wrap;
}

.categoryButton {
  padding: var(--spacing-sm) var(--spacing-lg);
  border-radius: var(--border-radius-full);
  border: 1px solid var(--color-primary-light);
  background: var(--color-white);
  color: var(--color-primary);
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-medium);
  cursor: pointer;
  transition: all var(--transition-fast);
}

.categoryButton:hover:not(.active) {
  background: var(--color-primary-light);
}

.categoryButton.active {
  background: var(--color-primary);
  color: var(--color-white);
  border-color: var(--color-primary);
}

.mapContainer {
  position: relative;
  height: 480px;
  border-radius: var(--border-radius-lg);
  overflow: hidden;
  background-color: var(--color-gray-900);
}

.map {
  width: 100%;
  height: 100%;
}

.mapLoading {
  position: absolute;
  inset: 0;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--color-white);
  font-size: var(--font-size-md);
  gap: var(--spacing-sm);
  z-index: 1;
}

/* Custom popup styling */
:global(.mapboxgl-popup) {
  font-family: var(--font-primary);
}

:global(.mapboxgl-popup-content) {
  background-color: var(--color-white);
  border-radius: var(--border-radius-lg);
  padding: var(--spacing-sm) var(--spacing-md);
  box-shadow: var(--shadow-lg);
  border: 1px solid var(--color-border);
  font-size: var(--font-size-sm);
  color: var(--text-primary);
}

:global(.mapboxgl-popup-tip) {
  border-top-color: var(--color-white) !important;
  border-bottom-color: var(--color-white) !important;
}

.loadingSpinner {
  width: var(--spacing-md);
  height: var(--spacing-md);
  border: 2px solid var(--color-white);
  border-top: 2px solid transparent;
  border-radius: var(--border-radius-full);
  animation: spin 1s linear infinite;
}

.statsGrid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: var(--spacing-md);
}

.statCard {
  border-radius: var(--border-radius-xl);
  padding: var(--spacing-lg);
  display: flex;
  align-items: center;
  gap: var(--spacing-md);
  transition: all var(--transition-fast);
}

.statCard:hover {
  transform: translateY(-2px);
  box-shadow: var(--shadow-md);
}

.statIconArea {
  width: var(--spacing-3xl);
  height: var(--spacing-3xl);
  border-radius: var(--border-radius-lg);
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--color-white);
  box-shadow: var(--shadow-sm);
  transition: transform var(--transition-fast);
}

.statCard:hover .statIconArea {
  transform: scale(1.05);
}

.statIcon {
  width: var(--spacing-lg);
  height: var(--spacing-lg);
  object-fit: contain;
}

.statContent {
  flex: 1;
}

.statValue {
  font-size: var(--font-size-3xl);
  font-weight: var(--font-weight-bold);
  color: var(--text-primary);
  margin-bottom: var(--spacing-xxs);
}

.statLabel {
  font-size: var(--font-size-sm);
  color: var(--text-secondary);
  line-height: 1.4;
}

/* Card color variants */
.cardGreen {
  background: linear-gradient(180deg, rgba(203, 245, 229, 0.3), rgba(220, 255, 242, 0.3));
}

.cardRed {
  background: linear-gradient(180deg, rgba(248, 201, 210, 0.3), rgba(253, 237, 240, 0.3));
}

.cardBlue {
  background: linear-gradient(180deg, rgba(219, 230, 254, 0.3), rgba(235, 241, 255, 0.3));
}

.cardOrange {
  background: linear-gradient(180deg, rgba(251, 223, 177, 0.3), rgba(255, 244, 227, 0.3));
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* Desktop (1024px - 1440px) */
@media (max-width: 1440px) {
  .mapContainer {
    height: 400px;
  }

  .statsGrid {
    grid-template-columns: repeat(2, 1fr);
  }
}

/* Tablet (768px - 1023px) */
@media (max-width: 1023px) {
  .card {
    padding: var(--spacing-lg);
  }

  .header {
    flex-direction: column;
    align-items: flex-start;
  }

  .mapContainer {
    height: 360px;
  }

  .statValue {
    font-size: var(--font-size-2xl);
  }
}

/* Mobile (below 768px) */
@media (max-width: 767px) {
  .card {
    padding: var(--spacing-md);
    gap: var(--spacing-md);
  }

  .title {
    font-size: var(--font-size-xl);
  }

  .categoryButtons {
    width: 100%;
    overflow-x: auto;
    padding-bottom: var(--spacing-xs);
    -webkit-overflow-scrolling: touch;
  }

  .categoryButton {
    white-space: nowrap;
  }

  .mapContainer {
    height: 300px;
  }

  .statsGrid {
    grid-template-columns: 1fr;
  }

  .statCard {
    padding: var(--spacing-md);
  }

  .statIconArea {
    width: var(--spacing-2xl);
    height: var(--spacing-2xl);
  }

  .statIcon {
    width: var(--spacing-md);
    height: var(--spacing-md);
  }
}