/* File: frontend/src/components/form/Input/Input.module.css */

.inputWrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: var(--spacing-xs);
  }
  
  .input {
    width: 100%;
    height: 44px;
    border-radius: var(--input-border-radius);
    border: 1px solid var(--input-border-color);
    background-color: var(--input-background);
    box-shadow: var(--input-box-shadow);
    padding: 0 var(--spacing-md);
    font-family: var(--font-primary);
    font-size: var(--font-size-base);
    color: var(--input-text-color);
    transition: all var(--transition-fast);
  }
  
  .input::placeholder {
    color: var(--input-placeholder-color);
  }
  
  .input:hover {
    background-color: var(--input-hover-background);
  }
  
  .input:focus {
    border-color: var(--input-focus-border-color);
    box-shadow: var(--input-focus-box-shadow);
    outline: none;
  }
  
  .disabled {
    background-color: var(--input-disabled-background);
    color: var(--input-disabled-text-color);
    cursor: not-allowed;
  }
  
  .error {
    border-color: var(--input-error-border-color);
  }
  
  .withIcon {
    padding-left: calc(var(--input-icon-size) + var(--spacing-md) + var(--spacing-xs));
  }
  
  .icon {
    position: absolute;
    left: var(--spacing-md);
    top: 50%;
    transform: translateY(-50%);
    width: var(--input-icon-size);
    height: var(--input-icon-size);
    pointer-events: none;
  }
  
  .characterCount {
    font-size: var(--font-size-sm);
    color: var(--text-tertiary);
    text-align: right;
    margin-top: var(--spacing-xxs);
  }
  
  textarea.input {
    height: auto;
    min-height: 100px;
    padding: var(--spacing-sm) var(--spacing-md);
    resize: vertical;
  }
  
  @media (max-width: 768px) {
    .input {
      height: 40px;
      font-size: var(--font-size-sm);
    }
  }