/* File: frontend/src/styles/variables/variables.css */

@import '../colors/colors.css';

:root {
  /* Typography */
  --font-primary: 'Instrument Sans', sans-serif;
  --font-secondary: 'Inter', sans-serif;
  --font-size-xs: 0.75rem;
  --font-size-sm: 0.875rem;
  --font-size-base: 1rem;
  --font-size-md: 1rem;
  --font-size-lg: 1.25rem;
  --font-size-xl: 1.5rem;
  --font-size-2xl: 1.875rem;
  --font-size-3xl: 2.25rem;
  --font-size-4xl: 3rem;
  --font-size-4-5xl: 4rem;
  --font-size-5xl: 5rem;
  --font-size-6xl: 6rem;
  --font-size-7xl: 7rem;
  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-semibold: 600;
  --font-weight-bold: 700;

  /* Layout */
  --header-height: 64px;  /* or whatever your header height is */

  /* Line heights */
  --line-height-tight: 1.25;
  --line-height-snug: 1.375;
  --line-height-normal: 1.5;
  --line-height-relaxed: 1.625;
  --line-height-loose: 2;

  /* Spacing */
  --spacing-xxxs: 0.25rem;    /* 4px */
  --spacing-xxs: 0.5rem;      /* 8px */
  --spacing-xs: 0.75rem;      /* 12px */
  --spacing-sm: 1rem;         /* 16px */
  --spacing-md: 1.5rem;       /* 24px */
  --spacing-lg: 2rem;         /* 32px */
  --spacing-xl: 2.5rem;       /* 40px */
  --spacing-2xl: 3rem;        /* 48px */
  --spacing-3xl: 4rem;        /* 64px */
  --spacing-4xl: 5rem;        /* 80px */
  --spacing-5xl: 6rem;        /* 96px */
  --spacing-6xl: 8rem;        /* 128px */

  /* Border radius */
  --border-radius-sm: 8px;
  --border-radius-md: 12px;
  --border-radius-lg: 14px;
  --border-radius-xl: 16px;
  --border-radius-full: 9999px;

  /* Transitions */
  --transition-fast: 0.2s ease;
  --transition-normal: 0.3s ease;
  --transition-slow: 0.5s ease;

  /* File: frontend/src/styles/variables/variables.css */
  /* Z-index */
  --z-index-header: 100;
  --z-index-dropdown: 1000;
  --z-index-sticky: 1020;
  --z-index-fixed: 1030;
  --z-index-modal-backdrop: 1040;
  --z-index-popover: 1060;
  --z-index-modal: 1100;
  --z-index-tooltip: 1070;

  /* Shadows */
  --shadow-sm: 0px 1px 2px rgba(228, 229, 231, 0.24);
  --shadow-md: 0 4px 6px rgba(0, 0, 0, 0.1);
  --shadow-lg: 0px 10px 21px rgba(0, 0, 0, 0.03), 0px 38px 38px rgba(0, 0, 0, 0.03), 0px 86px 51px rgba(0, 0, 0, 0.02);
  --shadow-card: 0px 13px 4px rgba(0, 0, 0, 0), 0px 8px 3px rgba(0, 0, 0, 0), 0px 5px 3px rgba(0, 0, 0, 0.02), 0px 2px 2px rgba(0, 0, 0, 0.03), 0px 1px 1px rgba(0, 0, 0, 0.03);
  --focus-ring: 0px 0px 0px 2px var(--color-white), 0px 0px 0px 4px var(--color-focus);

  --box-shadow-success: 
    0px 0px 0px 2.59px #fff, 
    0px 0px 0px 3.89px var(--color-success-bg), 
    0px 5.187px 3.89px rgba(255, 255, 255, 0.44) inset, 
    0px 0px 2.59px 2.59px rgba(56, 199, 147, 0.04) inset, 
    0px 5.187px 2.59px rgba(56, 199, 147, 0.05) inset, 
    0px 10.374px 19.84px 5.19px rgba(255, 255, 255, 0.35) inset;

  --box-shadow-warning: 
    0px 0px 0px 2.59px #fff, 
    0px 0px 0px 3.89px var(--color-warning-bg), 
    0px 5.187px 3.89px rgba(255, 255, 255, 0.44) inset, 
    0px 0px 2.59px 2.59px rgba(242, 174, 64, 0.04) inset, 
    0px 5.187px 2.59px rgba(242, 174, 64, 0.05) inset, 
    0px 10.374px 19.84px 5.19px rgba(255, 255, 255, 0.35) inset;

  --box-shadow-error: 
    0px 0px 0px 2.59px #fff, 
    0px 0px 0px 3.89px var(--color-error-bg), 
    0px 5.187px 3.89px rgba(255, 255, 255, 0.44) inset, 
    0px 0px 2.59px 2.59px rgba(223, 29, 65, 0.04) inset, 
    0px 5.187px 2.59px rgba(223, 29, 65, 0.05) inset, 
    0px 10.374px 19.84px 5.19px rgba(255, 255, 255, 0.35) inset;

  /* Component-specific variables */
  /* Avatar */
  --avatar-size-small: 24px;
  --avatar-size-medium: 36px;
  --avatar-size-large: 62px;
  --avatar-border-radius-small: 8px;
  --avatar-border-radius-medium: 10px;
  --avatar-border-radius-large: 14px;
  --avatar-shadow: 
    0px 4px 10px 7px rgba(255, 255, 255, 0.25) inset, 
    0px 0px 0px 2px rgba(255, 255, 255, 0.2) inset, 
    0px 0px 0px 4px rgba(255, 255, 255, 0.15) inset;

  /* Badge */
  --badge-padding-x: 8px;
  --badge-padding-y: 6px;
  --badge-icon-size: 12px;
  --badge-font-size: var(--font-size-xs);
  --badge-font-weight: var(--font-weight-semibold);
  --badge-line-height: 1;
  --color-badge-successful-bg: var(--color-success-bg);
  --color-badge-successful-text: var(--color-success);
  --color-badge-meh-bg: var(--color-warning-bg);
  --color-badge-meh-text: var(--color-warning);
  --color-badge-fail-bg: var(--color-error-bg);
  --color-badge-fail-text: var(--color-error);
  --color-badge-progress-bg: var(--color-info-bg);
  --color-badge-progress-text: var(--color-info);
  --color-badge-number-bg: var(--bg-secondary);
  --color-badge-number-text: var(--text-secondary);

  /* Breadcrumb */
  --breadcrumb-text-color: var(--text-tertiary);
  --breadcrumb-active-text-color: var(--text-secondary);
  --breadcrumb-separator-size: 12px;
  --breadcrumb-font-size: var(--font-size-sm);
  --breadcrumb-line-height: 22px;
  --breadcrumb-padding-x: var(--spacing-lg);
  --breadcrumb-padding-y: var(--spacing-md);
  --breadcrumb-separator-margin: var(--spacing-xs);

  /* Button */
  --button-border-radius-sm: 8px;
  --button-border-radius-md: 12px;
  --button-border-radius-lg: 14px;
  --button-border-radius-xl: 16px;
  --button-padding-xsmall: 6px 8px;
  --button-padding-small: 9px 10px;
  --button-padding-medium: 12px 14px;
  --button-padding-large: 18px 20px;
  --button-font-size-xsmall: var(--font-size-xs);
  --button-font-size-small: var(--font-size-sm);
  --button-font-size-medium: var(--font-size-base);
  --button-font-size-large: var(--font-size-lg);
  --button-icon-size-xsmall: 12px;
  --button-icon-size-small: 14px;
  --button-icon-size-medium: 16px;
  --button-icon-size-large: 18px;
  --button-transition: all 0.3s ease;
  --button-primary-bg: var(--color-primary);
  --button-primary-text: var(--color-white);
  --button-primary-border: var(--color-primary-dark);
  --button-primary-hover-bg: var(--color-primary-dark);
  --button-secondary-bg: var(--color-white);
  --button-secondary-text: var(--color-primary);
  --button-secondary-border: var(--color-primary);
  --button-secondary-hover-bg: var(--color-primary-light);
  --button-search-bg: var(--color-white);
  --button-search-border: var(--color-border);
  --button-search-hover-border: var(--color-primary-light);
  --button-social-bg: var(--color-white);
  --button-social-text: var(--color-text-primary);
  --button-social-border: var(--color-border);
  --button-social-hover-border: var(--color-primary-light);

  /* Checkbox */
  --checkbox-size: 16px;
  --checkbox-border-radius: 5px;
  --checkbox-gap: 10px;
  --checkbox-font-size: var(--font-size-sm);
  --checkbox-line-height: 1;
  --checkbox-container-height: 176px;
  --checkbox-border-color: var(--color-border);
  --checkbox-border-color-hover: var(--color-border-hover);
  --checkbox-bg-color: var(--color-white);
  --checkbox-bg-color-disabled: var(--color-bg-disabled);
  --checkbox-shadow: 0 1px 1px rgba(0, 0, 0, 0.03), 0 1px 1px rgba(0, 0, 0, 0.02);
  --checkbox-shadow-focus: 0 0 0 1.5px var(--color-white), 0 0 0 3px var(--color-focus);
  --checkbox-shadow-disabled: inset 0 2px 3px rgba(0, 0, 0, 0.15);

  /* Divider */
  --divider-color: var(--color-border);
  --divider-thickness: 1px;
  --divider-spacing: var(--spacing-md);

  /* Dropdown */
  --dropdown-border-radius: var(--border-radius-md);
  --dropdown-border-color: var(--color-border);
  --dropdown-background: var(--color-white);
  --dropdown-box-shadow: var(--shadow-sm);
  --dropdown-hover-background: var(--bg-secondary);
  --dropdown-focus-border-color: var(--color-primary);
  --dropdown-focus-box-shadow: 0px 0px 0px 2px var(--color-white), 0px 0px 0px 4px var(--color-focus);
  --dropdown-disabled-background: var(--color-bg-disabled);
  --dropdown-disabled-text-color: var(--text-tertiary);
  --dropdown-error-border-color: var(--color-error);
  --dropdown-text-color: var(--text-primary);
  --dropdown-placeholder-color: var(--text-tertiary);
  --dropdown-popover-box-shadow: var(--shadow-lg);
  --dropdown-item-hover-background: var(--bg-secondary);
  --dropdown-item-padding: var(--spacing-xs) var(--spacing-sm);
  --dropdown-padding: var(--spacing-sm) var(--spacing-xs);
  --dropdown-icon-size: 14px;
  --dropdown-chevron-size: 12px;
  --dropdown-popover-border-radius: var(--border-radius-lg);

  /* Hint */
  --hint-font-size: var(--font-size-xs);
  --hint-line-height: 1.33;
  --hint-color: var(--text-tertiary);
  --hint-icon-size: 16px;
  --hint-icon-gap: var(--spacing-xs);
  --hint-padding: var(--spacing-sm);

  /* Input */
  --input-border-radius: 12px;
  --input-border-color: var(--color-border);
  --input-background: var(--color-white);
  --input-box-shadow: 0px 1px 2px rgba(228, 229, 231, 0.24);
  --input-padding: var(--spacing-sm) var(--spacing-md);
  --input-hover-background: var(--bg-secondary);
  --input-focus-border-color: var(--text-primary);
  --input-focus-box-shadow: 0px 0px 0px 2px var(--color-white), 0px 0px 0px 4px var(--color-focus);
  --input-disabled-background: var(--color-bg-disabled);
  --input-disabled-text-color: var(--color-light-gray);
  --input-error-border-color: var(--color-error);
  --input-text-color: var(--text-primary);
  --input-placeholder-color: var(--text-tertiary);
  --input-icon-size: 14px;
  --textarea-min-height: 99px;
  --search-input-border-radius: 16px;
  --search-input-icon-size: 18px;
  --search-input-button-size: 40px;

  /* Sidebar */
  --bg-sidebar: var(--color-white);
  --bg-sidebar-secondary: var(--bg-secondary);
  --bg-sidebar-hover: var(--bg-hover);
  --bg-sidebar-active: var(--bg-active);

  /* Breakpoints */
  --breakpoint-xs: 360px;
  --breakpoint-sm: 480px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 1024px;
  --breakpoint-xl: 1280px;
}

/* Responsive adjustments */
@media (max-width: 480px) {
  :root {
    --font-size-base: var(--font-size-sm);
    --spacing-base: var(--spacing-sm);
    --breadcrumb-font-size: var(--font-size-xs);
    --breadcrumb-separator-size: 10px;
    --breadcrumb-padding-x: var(--spacing-md);
    --breadcrumb-padding-y: var(--spacing-sm);
    --breadcrumb-separator-margin: 4px;
    --button-padding-large: 14px 16px;
    --button-font-size-large: var(--font-size-base);
  }
}

@media (max-width: 360px) {
  :root {
    --font-size-base: var(--font-size-xs);
    --spacing-base: var(--spacing-sx);
  }
}