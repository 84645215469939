/* File: frontend/src/pages/QMS/QuoteDetail/components/QuoteProducts/TenantDetails/TenantDetails.module.css */

.container {
  display: flex;
  flex-direction: column;
}

.tabs {
  display: flex;
  border-bottom: 1px solid var(--color-border);
  margin-bottom: var(--spacing-md);
  overflow-x: auto;
}

.tab {
  padding: var(--spacing-sm) var(--spacing-md);
  background: none;
  border: none;
  border-bottom: 2px solid transparent;
  font-size: var(--font-size-base);
  font-weight: var(--font-weight-medium);
  color: var(--text-secondary);
  cursor: pointer;
  transition: all var(--transition-fast);
  white-space: nowrap;
}

.tab:hover {
  color: var(--text-primary);
}

.activeTab {
  color: var(--color-primary);
  border-bottom-color: var(--color-primary);
}

.tabContent {
  flex: 1;
}

.card {
  background-color: var(--bg-primary);
  border-radius: var(--border-radius-md);
  border: 1px solid var(--color-border);
  margin-bottom: var(--spacing-md);
  overflow: hidden;
}

.cardHeader {
  padding: var(--spacing-sm);
  background-color: var(--bg-secondary);
  border-bottom: 1px solid var(--color-border);
}

.cardTitle {
  font-size: var(--font-size-md);
  font-weight: var(--font-weight-semibold);
  margin: 0;
}

.cardContent {
  padding: var(--spacing-md);
}

.infoGrid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: var(--spacing-md);
}

.infoGroup {
  display: flex;
  flex-direction: column;
}

.infoLabel {
  font-weight: var(--font-weight-medium);
  color: var(--text-secondary);
  margin-bottom: var(--spacing-xxs);
}

.infoValue {
  color: var(--text-primary);
}

.tableContainer {
  overflow-x: auto;
}

.table {
  width: 100%;
  border-collapse: collapse;
}

.table th, .table td {
  padding: var(--spacing-sm);
  text-align: left;
  border-bottom: 1px solid var(--color-border);
}

.table th {
  background-color: var(--bg-secondary);
  font-weight: var(--font-weight-semibold);
  color: var(--text-secondary);
}

.table tr:hover {
  background-color: var(--bg-hover);
}

.coverageName {
  font-weight: var(--font-weight-medium);
}

.coverageCode {
  font-size: var(--font-size-xs);
  color: var(--text-secondary);
  margin-top: var(--spacing-xxxs);
}

.noData {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: var(--spacing-xl);
  color: var(--text-secondary);
  font-style: italic;
  text-align: center;
}

@media (max-width: 768px) {
  .tabs {
    padding-bottom: var(--spacing-xs);
  }
  
  .tab {
    padding: var(--spacing-xs) var(--spacing-sm);
    font-size: var(--font-size-sm);
  }
  
  .infoGrid {
    grid-template-columns: 1fr;
  }
}