/* File: frontend/src/pages/QMS/QuoteDetail/components/QuoteProducts/AutoDetails/AutoDetails.module.css */

.container {
  display: flex;
  flex-direction: column;
}

.tabs {
  display: flex;
  border-bottom: 1px solid var(--color-border);
  margin-bottom: var(--spacing-md);
  overflow-x: auto;
}

.tab {
  padding: var(--spacing-sm) var(--spacing-md);
  background: none;
  border: none;
  border-bottom: 2px solid transparent;
  font-size: var(--font-size-base);
  font-weight: var(--font-weight-medium);
  color: var(--text-secondary);
  cursor: pointer;
  transition: all var(--transition-fast);
  white-space: nowrap;
}

.tab:hover {
  color: var(--text-primary);
}

.activeTab {
  color: var(--color-primary);
  border-bottom-color: var(--color-primary);
}

.tabContent {
  flex: 1;
}

.card {
  background-color: var(--bg-primary);
  border-radius: var(--border-radius-md);
  border: 1px solid var(--color-border);
  margin-bottom: var(--spacing-md);
  overflow: hidden;
}

.cardHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: var(--spacing-sm);
  background-color: var(--bg-secondary);
  border-bottom: 1px solid var(--color-border);
}

.cardTitle {
  font-size: var(--font-size-md);
  font-weight: var(--font-weight-semibold);
  margin: 0;
}

.primaryBadge {
  background-color: var(--color-primary-100);
  color: var(--color-primary);
  padding: 2px 8px;
  border-radius: 4px;
  font-size: var(--font-size-xs);
  font-weight: var(--font-weight-medium);
}

.cardContent {
  padding: var(--spacing-md);
}

.sectionTitle {
  font-size: var(--font-size-base);
  font-weight: var(--font-weight-semibold);
  color: var(--text-secondary);
  margin: var(--spacing-md) 0 var(--spacing-sm) 0;
  padding-bottom: var(--spacing-xs);
  border-bottom: 1px solid var(--color-border-light);
}

.sectionTitle:first-child {
  margin-top: 0;
}

.infoGrid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: var(--spacing-md);
}

.infoGroup {
  display: flex;
  flex-direction: column;
}

.infoLabel {
  font-weight: var(--font-weight-medium);
  color: var(--text-secondary);
  margin-bottom: var(--spacing-xxs);
}

.infoValue {
  color: var(--text-primary);
}

.tableContainer {
  overflow-x: auto;
}

.table {
  width: 100%;
  border-collapse: collapse;
}

.table th, .table td {
  padding: var(--spacing-sm);
  text-align: left;
  border-bottom: 1px solid var(--color-border);
}

.table th {
  background-color: var(--bg-secondary);
  font-weight: var(--font-weight-semibold);
  color: var(--text-secondary);
}

.table tr:hover {
  background-color: var(--bg-hover);
}

.coverageName {
  font-weight: var(--font-weight-medium);
}

.coverageCode {
  font-size: var(--font-size-xs);
  color: var(--text-secondary);
  margin-top: var(--spacing-xxxs);
}

.coverageDescription {
  font-size: var(--font-size-xs);
  color: var(--text-tertiary);
  margin-top: var(--spacing-xxxs);
}

.coverageCategory {
  display: inline-block;
  padding: 2px 8px;
  border-radius: 4px;
  font-size: var(--font-size-xs);
  font-weight: var(--font-weight-medium);
  background-color: var(--bg-secondary);
  color: var(--text-secondary);
}

.atFault {
  background-color: var(--color-error-bg);
  color: var(--color-error);
  padding: 2px 8px;
  border-radius: 4px;
  font-size: var(--font-size-xs);
  font-weight: var(--font-weight-medium);
  display: inline-block;
}

.notAtFault {
  background-color: var(--color-success-bg);
  color: var(--color-success);
  padding: 2px 8px;
  border-radius: 4px;
  font-size: var(--font-size-xs);
  font-weight: var(--font-weight-medium);
  display: inline-block;
}

.premiumGrid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: var(--spacing-md);
}

.premiumItem {
  display: flex;
  flex-direction: column;
}

.premiumLabel {
  font-weight: var(--font-weight-medium);
  color: var(--text-secondary);
  margin-bottom: var(--spacing-xxs);
}

.premiumValue {
  color: var(--text-primary);
  font-weight: var(--font-weight-medium);
}

.finalPremium, .totalCost {
  color: var(--color-primary);
  font-weight: var(--font-weight-bold);
  font-size: var(--font-size-lg);
}

.discountName {
  font-weight: var(--font-weight-medium);
}

.discountCode {
  font-size: var(--font-size-xs);
  color: var(--text-secondary);
  margin-top: var(--spacing-xxxs);
}

.applied {
  color: var(--color-success);
  font-weight: var(--font-weight-semibold);
}

.notApplied {
  color: var(--text-tertiary);
}

.competitiveChart {
  margin-top: var(--spacing-md);
}

.chartTitle {
  font-size: var(--font-size-base);
  font-weight: var(--font-weight-semibold);
  margin-bottom: var(--spacing-sm);
}

.ourPremium {
  background-color: var(--color-primary-100);
}

.ourPremiumLabel {
  font-weight: var(--font-weight-semibold);
  color: var(--color-primary);
}

.positiveDifference {
  color: var(--color-error);
}

.negativeDifference {
  color: var(--color-success);
}

.vehicleRiskSection, .driverRiskSection {
  margin-bottom: var(--spacing-md);
  padding-bottom: var(--spacing-md);
  border-bottom: 1px solid var(--color-border-light);
}

.vehicleRiskSection:last-child, .driverRiskSection:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
  border-bottom: none;
}

.vehicleRiskTitle, .driverRiskTitle, .aggregateTitle {
  font-size: var(--font-size-base);
  font-weight: var(--font-weight-semibold);
  margin: 0 0 var(--spacing-sm) 0;
}

.aggregateSection {
  margin-bottom: var(--spacing-md);
  padding-bottom: var(--spacing-md);
  border-bottom: 1px solid var(--color-border-light);
}

.aggregateSection:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
  border-bottom: none;
}

.noData, .noClaimsMessage {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: var(--spacing-xl);
  color: var(--text-secondary);
  font-style: italic;
  text-align: center;
}

@media (max-width: 768px) {
  .tabs {
    padding-bottom: var(--spacing-xs);
  }
  
  .tab {
    padding: var(--spacing-xs) var(--spacing-sm);
    font-size: var(--font-size-sm);
  }
  
  .infoGrid, .premiumGrid {
    grid-template-columns: 1fr;
  }
}