/* File: frontend/src/pages/SuperAdmin/components/AgreementManagement/AgreementList/AgreementList.module.css */

.container {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-md);
}

.toolbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: var(--spacing-md);
}

.filters {
  display: flex;
  align-items: center;
  gap: var(--spacing-md);
  flex: 1;
}

.filterGroup {
  display: flex;
  align-items: center;
  gap: var(--spacing-xs);
}

.filterLabel {
  font-size: var(--font-size-sm);
  color: var(--text-secondary);
}

.filterSelect {
  padding: var(--spacing-xs) var(--spacing-sm);
  border: 1px solid var(--color-border);
  border-radius: var(--border-radius-sm);
  background-color: var(--bg-primary);
  color: var(--text-primary);
  font-size: var(--font-size-sm);
}

.createButton {
  display: flex;
  align-items: center;
  gap: var(--spacing-xs);
  padding: var(--spacing-sm) var(--spacing-md);
  background-color: var(--color-primary);
  color: white;
  border: none;
  border-radius: var(--border-radius-md);
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-medium);
  cursor: pointer;
  transition: background-color var(--transition-fast);
}

.createButton:hover {
  background-color: var(--color-primary-dark);
}

.createButton i {
  font-size: var(--font-size-base);
}

.error {
  padding: var(--spacing-md);
  background-color: var(--color-error-bg);
  color: var(--color-error);
  border-radius: var(--border-radius-md);
  margin-bottom: var(--spacing-md);
}

.pagination {
  display: flex;
  justify-content: center;
  margin-top: var(--spacing-md);
}

.idCell {
  font-family: var(--font-family-mono);
  font-size: var(--font-size-sm);
  color: var(--text-secondary);
}

.nameCell {
  font-weight: var(--font-weight-medium);
  color: var(--color-primary);
}

.statusBadge, .typeBadge {
  display: inline-block;
  padding: 2px 8px;
  border-radius: 12px;
  font-size: var(--font-size-xs);
  font-weight: var(--font-weight-medium);
  text-transform: capitalize;
}

.statusActive {
  background-color: var(--color-success-bg);
  color: var(--color-success);
}

.statusExpired {
  background-color: var(--color-error-bg);
  color: var(--color-error);
}

.statusTerminated {
  background-color: var(--color-error-bg);
  color: var(--color-error);
}

.statusPending {
  background-color: var(--color-warning-bg);
  color: var(--color-warning);
}

.typeProduction {
  background-color: var(--color-primary-100);
  color: var(--color-primary);
}

.typeTrial {
  background-color: var(--color-info-bg);
  color: var(--color-info);
}

.typePilot {
  background-color: var(--color-warning-bg);
  color: var(--color-warning);
}

.actions {
  display: flex;
  gap: var(--spacing-xs);
}

.actionButton {
  display: flex;
  align-items: center;
  gap: var(--spacing-xxs);
  padding: var(--spacing-xs) var(--spacing-sm);
  background-color: var(--bg-secondary);
  border: 1px solid var(--color-border);
  border-radius: var(--border-radius-sm);
  font-size: var(--font-size-xs);
  color: var(--text-primary);
  cursor: pointer;
  transition: all var(--transition-fast);
}

.actionButton:hover {
  background-color: var(--bg-hover);
  color: var(--color-primary);
  border-color: var(--color-primary);
}

@media (max-width: 992px) {
  .filters {
    flex-wrap: wrap;
  }
}

@media (max-width: 768px) {
  .toolbar {
    flex-direction: column;
    align-items: stretch;
    gap: var(--spacing-sm);
  }
  
  .filters {
    flex-direction: column;
    align-items: stretch;
  }
  
  .createButton {
    width: 100%;
    justify-content: center;
  }
}