/* File: frontend/src/pages/EmbeddedInsurance/components/ApiExplorer/styles/ApiExplorer.module.css */

.apiExplorer {
  display: flex;
  flex-direction: column;
  min-height: 600px;
}

.loading, .error, .noEndpoint {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 400px;
  gap: var(--spacing-md);
  color: var(--text-secondary);
  text-align: center;
}

.spinner {
  width: 40px;
  height: 40px;
  border: 3px solid rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  border-top-color: var(--color-primary);
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.error i, .noEndpoint i {
  font-size: var(--font-size-3xl);
  opacity: 0.5;
}

/* Tag Navigation */
.tagNavigation {
  border-bottom: 1px solid var(--color-border);
  margin-bottom: var(--spacing-md);
}

.tagScroller {
  display: flex;
  overflow-x: auto;
  scrollbar-width: thin;
  scrollbar-color: var(--color-primary) transparent;
  padding-bottom: var(--spacing-xs);
  -webkit-overflow-scrolling: touch;
}

.tagScroller::-webkit-scrollbar {
  height: 4px;
}

.tagScroller::-webkit-scrollbar-track {
  background: transparent;
}

.tagScroller::-webkit-scrollbar-thumb {
  background-color: var(--color-primary);
  border-radius: 2px;
}

.tagButton {
  padding: var(--spacing-sm) var(--spacing-md);
  background: none;
  border: none;
  border-bottom: 2px solid transparent;
  color: var(--text-secondary);
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-medium);
  cursor: pointer;
  transition: all var(--transition-fast);
  white-space: nowrap;
  flex-shrink: 0;
}

.tagButton:hover {
  color: var(--text-primary);
}

.activeTag {
  color: var(--color-primary);
  border-bottom-color: var(--color-primary);
}

/* Endpoint Navigation */
.endpointNavigation {
  margin-bottom: var(--spacing-md);
  background-color: var(--bg-secondary);
  border-radius: var(--border-radius-md);
  padding: var(--spacing-xs);
}

.endpointScroller {
  display: flex;
  overflow-x: auto;
  scrollbar-width: thin;
  scrollbar-color: var(--color-primary) transparent;
  padding-bottom: var(--spacing-xs);
  -webkit-overflow-scrolling: touch;
  gap: var(--spacing-xs);
}

.endpointScroller::-webkit-scrollbar {
  height: 4px;
}

.endpointScroller::-webkit-scrollbar-track {
  background: transparent;
}

.endpointScroller::-webkit-scrollbar-thumb {
  background-color: var(--color-primary);
  border-radius: 2px;
}

.endpointButton {
  display: flex;
  align-items: center;
  gap: var(--spacing-xs);
  padding: var(--spacing-xs) var(--spacing-sm);
  background-color: var(--bg-primary);
  border: 1px solid var(--color-border);
  border-radius: var(--border-radius-md);
  color: var(--text-secondary);
  font-size: var(--font-size-xs);
  cursor: pointer;
  transition: all var(--transition-fast);
  white-space: nowrap;
  flex-shrink: 0;
}

.endpointButton:hover {
  border-color: var(--color-primary-light);
  background-color: var(--bg-hover);
}

.activeEndpoint {
  border-color: var(--color-primary);
  background-color: var(--color-primary-50);
}

.methodBadge {
  padding: 2px 4px;
  border-radius: var(--border-radius-sm);
  font-size: var(--font-size-xs);
  font-weight: var(--font-weight-semibold);
  text-transform: uppercase;
}

.methodGet {
  background-color: var(--color-success-bg);
  color: var(--color-success);
}

.methodPost {
  background-color: var(--color-primary-bg);
  color: var(--color-primary);
}

.methodPut {
  background-color: var(--color-warning-bg);
  color: var(--color-warning);
}

.methodDelete {
  background-color: var(--color-error-bg);
  color: var(--color-error);
}

.methodPatch {
  background-color: var(--color-info-bg);
  color: var(--color-info);
}

.methodDefault {
  background-color: var(--bg-tertiary);
  color: var(--text-secondary);
}

.endpointPath {
  font-family: var(--font-secondary);
}

/* Endpoint Details */
.endpointDetails {
  flex: 1;
  overflow-y: auto;
  border: 1px solid var(--color-border);
  border-radius: var(--border-radius-md);
  padding: var(--spacing-xs)
}

@media (max-width: 768px) {
  .tagButton, .endpointButton {
    padding: var(--spacing-xs) var(--spacing-sm);
  }
}