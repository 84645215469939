/* File: frontend/src/components/Sidebar/Sidebar.module.css */

.sidebar {
  height: 100%;
  min-height: -webkit-fill-available; /* Add this for iOS */
  max-height: 100vh; /* Add this for consistency */
  background-color: var(--color-gray-1300);
  width: 72px;
  transition: transform var(--transition-normal);
  position: relative;
  display: flex;
  flex-direction: column;
  overflow-y: auto; /* Add this */
  -webkit-overflow-scrolling: touch; /* Add this for smooth iOS scrolling */
}

.sidebar.expanded {
  width: 320px;
  z-index: var(--z-index-modal);
}

.sidebarContent {
  /* height: 100%; */
  height: auto;
  display: flex;
  flex-direction: column;
  background-color: var(--color-gray-1300);
  position: relative;
  width: 100%;
}

.categoriesContainer {
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
  padding: var(--spacing-md);
  padding-right: var(--spacing-lg);
  margin-right: -8px;
  -webkit-overflow-scrolling: touch;
  position: relative;
  
  /* Scrollbar styling */
  scrollbar-width: thin;
  scrollbar-color: var(--color-gray-1100) transparent;
}

.categoriesContainer::-webkit-scrollbar {
  width: 4px;
}

.categoriesContainer::-webkit-scrollbar-track {
  background: transparent;
}

.categoriesContainer::-webkit-scrollbar-thumb {
  background-color: var(--color-gray-1100);
  border-radius: var(--border-radius-full);
}

.categoryWrapper {
  cursor: pointer;
  transition: all var(--transition-normal);
  position: relative;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
}

.bottomSection {
  /* position: relative; */
  position: sticky;
  bottom: 0;
  left: 0;
  right: 0;
  flex-shrink: 0;
  border-top: 1px solid var(--color-gray-1200);
  /* margin-top: auto; */
  padding: var(--spacing-md);
  background-color: var(--color-gray-1300);
  /* z-index: 1; */
  z-index: 2;
}

/* Mobile styles */
@media (max-width: 768px) {
  .sidebar {
    position: fixed;
    top: env(safe-area-inset-top, 0); /* Add iOS safe area */
    bottom: env(safe-area-inset-bottom, 0); /* Add iOS safe area */
    left: 0;
    width: 280px;
    height: 100%;
    /* Use the full height minus safe areas */
    height: calc(100% - env(safe-area-inset-top, 0) - env(safe-area-inset-bottom, 0));
    transform: translateX(-100%);
    box-shadow: var(--shadow-lg);
    padding-top: env(safe-area-inset-top, 0);
    padding-bottom: env(safe-area-inset-bottom, 0);
    transition: transform var(--transition-normal);
  }
  
  .sidebar.mobileOpen {
    transform: translateX(0);
    box-shadow: var(--shadow-lg);
  }

  /* Overlay */
  .mobileOpen::before {
    display: none;
  }

  .sidebar:not(.expanded) {
    width: 280px;
  }

  .categoriesContainer {
    /* flex: 1 1 auto; */
    /* overflow-y: auto; */
    -webkit-overflow-scrolling: touch;
    padding: var(--spacing-md);
    /* padding-right: var(--spacing-md); */
  }

  /* .categoriesContainer::-webkit-scrollbar {
    display: none;
  } */

  .bottomSection {
    padding-bottom: calc(var(--spacing-md) + env(safe-area-inset-bottom, 0));
  }

  .categoryWrapper {
    padding: var(--spacing-xxs) 0;
  }
}

/* Tablet styles */
@media (min-width: 769px) and (max-width: 1024px) {
  .sidebar:not(.expanded) {
    width: 64px;
  }

  .categoriesContainer {
    padding: var(--spacing-sm);
  }
}

/* iOS height fix */
@supports (-webkit-touch-callout: none) {
  .sidebar {
    height: -webkit-fill-available;
  }
}

/* Prevent body scroll when sidebar is open */
:global(body.sidebar-open) {
  overflow: hidden;
  position: fixed;
  width: 100%;
  height: 100%;
}