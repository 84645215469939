/* File: frontend/src/styles/base/print.css */

@media print {
    body {
      background-color: #fff;
      color: #000;
      font-size: 12pt;
    }
  
    a {
      text-decoration: underline;
      color: #000;
    }
  
    a[href^="http"]:after {
      content: " (" attr(href) ")";
    }
  
    a[href^="#"]:after {
      content: "";
    }
  
    h1, h2, h3, h4, h5, h6 {
      page-break-after: avoid;
      page-break-inside: avoid;
    }
  
    img {
      max-width: 100% !important;
      page-break-inside: avoid;
    }
  
    table, pre {
      page-break-inside: avoid;
    }
  
    ul, ol, dl {
      page-break-before: avoid;
    }
  
    .no-print {
      display: none;
    }
  
    .print-only {
      display: block;
    }
  }