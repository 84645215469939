/* File: frontend/src/pages/InvestorPitch/components/slides/TechnicalArchitectureSlide/SystemMetric.module.css */

.container {
  background-color: var(--color-gray-1200);
  border-radius: var(--border-radius-lg);
  padding: var(--spacing-xl);
  border: 1px solid var(--color-gray-1100);
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: var(--spacing-lg);
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition: background-color 0.2s ease;
}

.container:hover,
.container:focus {
  background-color: var(--color-gray-1150);
  outline: none;
}

.container:focus-visible {
  outline: 2px solid var(--color-primary);
  outline-offset: 2px;
}
  
  .iconWrapper {
    width: 56px;
    height: 56px;
    border-radius: var(--border-radius-lg);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .icon {
    width: 28px;
    height: 28px;
  }
  
  .content {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-sm);
  }
  
  .value {
    font-size: var(--font-size-3xl);
    font-weight: var(--font-weight-bold);
    color: var(--text-on-primary);
    line-height: 1;
    display: flex;
    align-items: baseline;
    gap: var(--spacing-xs);
    justify-content: center;
  }
  
  .unit {
    font-size: var(--font-size-sm);
    color: var(--color-gray-400);
  }
  
  .title {
    font-size: var(--font-size-base);
    color: var(--color-gray-400);
  }

  .details {
    width: 100%;
    margin-top: var(--spacing-lg);
    padding-top: var(--spacing-lg);
    border-top: 1px solid var(--color-gray-1100);
    text-align: left;
  }
  
  .detailsTitle {
    font-size: var(--font-size-lg);
    color: var(--text-on-primary);
    margin-bottom: var(--spacing-md);
  }
  
  .detailsDescription {
    color: var(--color-gray-400);
    margin-bottom: var(--spacing-xl);
    line-height: 1.5;
  }
  
  .projectsSection,
  .regionsSection,
  .statsSection {
    margin-top: var(--spacing-xl);
  }
  
  .projectsList,
  .locationsList {
    list-style: none;
    padding: 0;
    margin: 0;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: var(--spacing-md);
  }
  
  .projectsList li,
  .locationsList li {
    padding: var(--spacing-sm) 0;
    color: var(--color-gray-300);
    display: flex;
    align-items: center;
    gap: var(--spacing-sm);
  }
  
  .projectsList li::before {
    content: "•";
    color: var(--color-primary);
  }
  
  .regionGroup {
    margin-bottom: var(--spacing-lg);
  }
  
  .regionTitle {
    font-size: var(--font-size-sm);
    color: var(--color-gray-400);
    text-transform: uppercase;
    letter-spacing: 0.05em;
    margin-bottom: var(--spacing-md);
    padding-bottom: var(--spacing-xs);
    border-bottom: 1px solid var(--color-gray-1100);
  }
  
  .statsGrid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    gap: var(--spacing-lg);
  }
  
  .statItem {
    text-align: center;
    padding: var(--spacing-md);
    background-color: var(--color-gray-1150);
    border-radius: var(--border-radius-md);
    display: flex;
    flex-direction: column;
    gap: var(--spacing-xs);
  }
  
  .statValue {
    font-size: var(--font-size-lg);
    font-weight: var(--font-weight-bold);
    color: var(--text-on-primary);
  }
  
  .statLabel {
    font-size: var(--font-size-sm);
    color: var(--color-gray-400);
  }
  
  @media (max-width: 768px) {
    .statsGrid {
      grid-template-columns: repeat(2, 1fr);
    }
  
    .projectsList,
    .locationsList {
      grid-template-columns: 1fr;
    }
  
    .details {
      padding: var(--spacing-md);
    }
  }
  
  .hasDetails {
    cursor: pointer;
  }
  
  .hasDetails:hover {
    background-color: var(--color-gray-1150);
  }