/* File: frontend/src/pages/UMS/components/UserSearchForm/UserSearchForm.module.css */

.container {
  background-color: var(--bg-primary);
  border-radius: var(--border-radius-lg);
  box-shadow: var(--shadow-card);
  padding: var(--spacing-md);
  margin-bottom: var(--spacing-lg);
}

.title {
  font-size: var(--font-size-lg);
  font-weight: var(--font-weight-semibold);
  color: var(--text-primary);
  margin-bottom: var(--spacing-md);
}

.form {
  display: flex;
  flex-wrap: wrap;
  gap: var(--spacing-md);
  align-items: flex-start;
}

.formGroup {
  flex: 1;
  min-width: 200px;
}

.label {
  display: block;
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-medium);
  color: var(--text-secondary);
  margin-bottom: var(--spacing-xxs);
}

.select, .input {
  width: 100%;
  padding: var(--spacing-sm);
  border: 1px solid var(--color-border);
  border-radius: var(--border-radius-md);
  font-size: var(--font-size-base);
  color: var(--text-primary);
  background-color: var(--bg-primary);
  transition: border-color var(--transition-fast);
}

.select:hover, .input:hover {
  border-color: var(--color-border-hover);
}

.select:focus, .input:focus {
  outline: none;
  box-shadow: var(--input-focus-box-shadow);
  border-color: var(--input-focus-border-color);
}

.formActions {
  display: flex;
  gap: var(--spacing-sm);
  align-items: center;
}

.button {
  padding: var(--button-padding-medium);
  background-color: var(--button-primary-bg);
  color: var(--button-primary-text);
  border: none;
  border-radius: var(--button-border-radius-md);
  font-size: var(--button-font-size-medium);
  font-weight: var(--font-weight-medium);
  cursor: pointer;
  transition: background-color var(--transition-fast);
  min-width: 120px;
}

.button:hover:not(:disabled) {
  background-color: var(--button-primary-hover-bg);
}

.button:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.advancedButton {
  padding: var(--button-padding-small);
  background-color: transparent;
  color: var(--color-primary);
  border: none;
  font-size: var(--button-font-size-small);
  font-weight: var(--font-weight-medium);
  cursor: pointer;
  transition: color var(--transition-fast);
}

.advancedButton:hover {
  color: var(--color-primary-dark);
  text-decoration: underline;
}

.advancedFilters {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: var(--spacing-md);
  margin-top: var(--spacing-md);
  padding-top: var(--spacing-md);
  border-top: 1px solid var(--color-border-light);
}

.clearButton {
  padding: var(--button-padding-small);
  background-color: var(--button-tertiary-bg);
  color: var(--button-tertiary-text);
  border: 1px solid var(--button-tertiary-border);
  border-radius: var(--button-border-radius-md);
  font-size: var(--button-font-size-small);
  font-weight: var(--font-weight-medium);
  cursor: pointer;
  transition: background-color var(--transition-fast);
  margin-left: auto;
}

.clearButton:hover:not(:disabled) {
  background-color: var(--button-tertiary-hover-bg);
}

@media (max-width: 768px) {
  .form {
    flex-direction: column;
  }
  
  .formGroup {
    width: 100%;
  }
  
  .formActions {
    width: 100%;
    flex-direction: column;
  }
  
  .button, .advancedButton, .clearButton {
    width: 100%;
  }
  
  .advancedFilters {
    flex-direction: column;
  }
}