/* File: frontend/src/components/JobApplicationForm/JobApplicationForm.module.css */

.jobApplicationForm {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-lg);
    max-width: 600px;
    margin: 0 auto;
    padding: var(--spacing-xl) var(--spacing-md);
  }
  
  .uploadSections {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-md);
  }
  
  @media (max-width: 768px) {
    .jobApplicationForm {
      padding: var(--spacing-md);
    }
  }