/* File: frontend/src/components/Auth/StatusScreen.module.css */

.statusScreen {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.statusIcon {
  width: 64px;
  height: 64px;
  margin-bottom: var(--spacing-md);
}

.statusTitle {
  font-size: var(--font-size-xl);
  font-weight: var(--font-weight-bold);
  color: var(--text-primary);
  margin-bottom: var(--spacing-sm);
}

.statusMessage {
  font-size: var(--font-size-md);
  color: var(--text-secondary);
  margin-bottom: var(--spacing-md);
}

.statusAdditionalInfo {
  font-size: var(--font-size-sm);
  color: var(--text-tertiary);
  margin-bottom: var(--spacing-md);
}

.statusButton {
  padding: var(--spacing-sm) var(--spacing-md);
  background-color: var(--color-primary);
  color: var(--color-white);
  border: none;
  border-radius: var(--border-radius-md);
  font-size: var(--font-size-md);
  cursor: pointer;
  transition: background-color var(--transition-normal);
}

.statusButton:hover {
  background-color: var(--color-primary-dark);
}

.statusScreen.success .statusIcon {
  color: var(--color-success);
}

.statusScreen.error .statusIcon {
  color: var(--color-error);
}

.statusScreen.warning .statusIcon {
  color: var(--color-warning);
}