/* File: frontend/src/pages/AI1/components/ChatHeader/ChatHeader.module.css */

.header {
  padding: var(--spacing-sm) var(--spacing-md);
  /* background-color: var(--color-white); */
  /* border-bottom: 1px solid var(--color-border); */
  display: flex;
  justify-content: flex-end;
  position: sticky;
  top: 0;
  background: none;
  z-index: var(--z-index-sticky);
}

.actions {
  display: flex;
  gap: var(--spacing-sm);
}

.actionButton {
  background: none;
  border: none;
  padding: var(--spacing-xxs);
  cursor: pointer;
  border-radius: var(--border-radius-sm);
  display: flex;
  align-items: center;
  justify-content: center;
}

.actionButton:hover {
  background-color: var(--bg-hover);
}

.shareContainer {
  position: relative;
}

.shareMenu {
  position: absolute;
  right: 0;
  top: 100%;
  background-color: var(--color-white);
  border: 1px solid var(--color-border);
  border-radius: var(--border-radius-md);
  box-shadow: var(--shadow-md);
  padding: var(--spacing-xxs);
  min-width: 200px;
}

.shareMenu button {
  width: 100%;
  padding: var(--spacing-xs) var(--spacing-sm);
  text-align: left;
  background: none;
  border: none;
  cursor: pointer;
  color: var(--text-primary);
  border-radius: var(--border-radius-sm);
}

.shareMenu button:hover {
  background-color: var(--bg-hover);
}