/* File: frontend/src/pages/AI1/ChatHistory/ChatHistory.module.css */

.container {
  max-width: 800px;
  margin: 0 auto;
  padding: var(--spacing-xl);
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: var(--spacing-xl);
}

.header h1 {
  font-size: var(--font-size-2xl);
  font-weight: var(--font-weight-semibold);
  color: var(--text-primary);
  margin: 0;
}

.newChatButton {
  background-color: var(--color-primary);
  color: var(--color-white);
  border: none;
  padding: var(--spacing-sm) var(--spacing-lg);
  border-radius: var(--border-radius-lg);
  cursor: pointer;
  font-weight: var(--font-weight-medium);
  transition: background-color var(--transition-fast);
}

.newChatButton:hover {
  background-color: var(--color-primary-dark);
}

.paginationWrapper {
  margin-top: 2rem;
  display: flex;
  justify-content: center;
  padding: 1rem 0;
}

.chatList {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-md);
  padding: var(--spacing-md);
}

.chatItem {
  background-color: var(--color-white);
  border-radius: var(--border-radius-lg);
  padding: var(--spacing-lg);
  cursor: pointer;
  transition: all var(--transition-fast);
  border: 1px solid var(--color-border);
}

.chatItem:hover {
  background-color: #f9fafb;
  transform: translateY(-1px);
  box-shadow: var(--shadow-md);
  border-color: var(--color-primary-100);
}

.chatItemHeader {
  display: flex;
  justify-content: space-between;
  margin-bottom: var(--spacing-sm);
}

.timestamp {
  color: var(--text-secondary);
  font-size: var(--font-size-sm);
}

.agent {
  color: var(--color-primary);
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-medium);
  text-transform: capitalize;
}

.preview {
  color: var(--text-primary);
  margin: 0;
  font-size: var(--font-size-md);
  line-height: 1.5;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.emptyState {
  text-align: center;
  padding: var(--spacing-2xl);
  color: var(--text-secondary);
}

.startChatButton {
  margin-top: var(--spacing-lg);
  background-color: var(--color-primary);
  color: var(--color-white);
  border: none;
  padding: var(--spacing-sm) var(--spacing-lg);
  border-radius: var(--border-radius-lg);
  cursor: pointer;
  font-weight: var(--font-weight-medium);
}

.loadingContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;
}

.loader {
  border: 3px solid var(--color-border);
  border-radius: 50%;
  border-top: 3px solid var(--color-primary);
  width: 24px;
  height: 24px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}