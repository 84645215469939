/* File: frontend/src/components/form/Label/Label.module.css */

.label {
  display: flex;
  align-items: center;
  font-size: var(--font-size-sm);
  /* color: var(--text-primary); */
  color: var(--color-gray-600);
  font-weight: var(--font-weight-medium);
  line-height: var(--line-height-normal);
}

.text {
  margin-right: var(--spacing-xxs);
}

.required {
  color: var(--color-error);
  margin-left: var(--spacing-xxs);
}

.optional {
  color: var(--text-tertiary);
  margin-left: var(--spacing-xs);
  font-size: var(--font-size-xs);
  line-height: var(--line-height-tight);
}

.icon {
  width: 14px;
  height: 14px;
  margin-left: var(--spacing-xs);
}

@media (max-width: 768px) {
  .label {
    font-size: var(--font-size-xs);
  }

  .optional {
    font-size: var(--font-size-xxs);
  }

  .icon {
    width: 12px;
    height: 12px;
  }
}