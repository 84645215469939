/* File: frontend/src/components/Header/Header.module.css */

/* Common header styles */
.landingHeader,
.dashboardHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: var(--spacing-sm) var(--spacing-lg);
  width: 100%;
}

/* Landing Header specific */
.landingHeader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: var(--z-index-header);
  background-color: rgba(10, 10, 10, 0.8);
  backdrop-filter: blur(10px);
  transition: background-color var(--transition-normal);
}

.headerContent {
  max-width: 1200px;
  margin: 0 auto;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: var(--spacing-sm) var(--spacing-lg);
}

/* Dashboard Header specific */
.dashboardHeader {
  height: var(--header-height);
  background-color: var(--color-white);
  border-bottom: 1px solid var(--color-gray-200);
  padding: var(--spacing-sm) var(--spacing-lg);
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: var(--z-index-header);
}

/* Logo styles */
.logoLink {
  display: flex;
  align-items: center;
}

.logo {
  width: 120px;
  height: auto;
}

/* Navigation */
.navigation {
  display: flex;
  gap: var(--spacing-lg);
}

.navigation a {
  color: var(--color-gray-300);
  text-decoration: none;
  font-weight: var(--font-weight-medium);
  font-size: var(--font-size-sm);
  transition: color var(--transition-normal);
}

.navigation a:hover {
  color: var(--color-white);
}

/* Header sections */
.headerLeft {
  display: flex;
  align-items: center;
  gap: var(--spacing-md);
}

.headerRight {
  display: flex;
  align-items: center;
}

/* Breadcrumbs */
.breadcrumbs {
  display: flex;
  align-items: center;
  font-size: var(--font-size-sm);
}

.breadcrumbs a {
  color: var(--color-gray-400);
  text-decoration: none;
}

.breadcrumbSeparator {
  margin: 0 var(--spacing-sm);
  color: var(--color-gray-300);
}

/* Search */
.searchButton {
  display: flex;
  align-items: center;
  gap: var(--spacing-sm);
  padding: var(--spacing-sm) var(--spacing-md);
  background-color: var(--color-gray-100);
  border: 1px solid var(--color-gray-200);
  border-radius: var(--border-radius-sm);
  color: var(--color-gray-400);
  font-size: var(--font-size-sm);
  cursor: pointer;
}

.searchIcon {
  width: 16px;
  height: 16px;
}

/* Mobile menu button */
.mobileMenuButton {
  display: none;
  background-color: var(--color-gray-100);
  border: none;
  /* padding: var(--spacing-sm); */
  cursor: pointer;
  border-radius: var(--border-radius-md);
  transition: all var(--transition-fast);
}

.mobileMenuButton img {
  width: 24px;
  height: 24px;
}

.mobileMenuButton:hover {
  background-color: var(--color-gray-100);
}

.mobileMenuButton.active {
  background-color: var(--color-gray-200);
}

.chatControls {
  display: flex;
  gap: var(--spacing-sm);
  align-items: center;
  margin-left: var(--spacing-md);
}

.shareContainer {
  position: relative;
}

.shareMenu {
  position: absolute;
  right: 0;
  top: 100%;
  background-color: var(--color-white);
  border: 1px solid var(--color-border);
  border-radius: var(--border-radius-md);
  box-shadow: var(--shadow-md);
  padding: var(--spacing-xxs);
  min-width: 200px;
  z-index: var(--z-index-dropdown);
}

.shareMenuItem {
  display: flex;
  align-items: center;
  gap: var(--spacing-sm);
  padding: var(--spacing-sm) var(--spacing-md);
  color: var(--color-gray-700);
  font-size: var(--font-size-sm);
  width: 100%;
  text-align: left;
  border: none;
  background: none;
  cursor: pointer;
  transition: all var(--transition-fast);
}

.shareMenuItem:hover {
  background-color: var(--color-gray-50);
}

.shareMenuItem img {
  width: 16px;
  height: 16px;
}

.headerActionButton {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  background-color: var(--color-gray-100);
  border: none;
  border-radius: var(--border-radius-md);
  cursor: pointer;
  transition: all var(--transition-fast);
  text-decoration: none; /* Added for Link elements */
  color: inherit; /* Added for Link elements */
}

.headerActionButton:hover {
  background-color: var(--color-gray-200);
}

.headerActionButton img {
  width: 18px;
  height: 18px;
}

/* Mobile styles */
@media (max-width: 768px) {
  .headerContent,
  .dashboardHeader {
    padding: var(--spacing-sm);
  }

  .mobileMenuButton {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    z-index: calc(var(--z-index-modal) + 2);
  }

  .mobileMenuButton.active {
    background-color: var(--color-gray-200);
  }

  .navigation {
    display: none;
  }

  .navigation.mobileMenuOpen {
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 15px;
    left: 0;
    right: 0;
    background-color: rgba(10, 10, 10, 0.95);
    padding: var(--spacing-md);
    z-index: var(--z-index-header);
  }

  .navigation a {
    padding: var(--spacing-sm) 0;
  }

  .rightSection {
    display: none;
  }

  /* Dashboard specific mobile styles */
  .dashboardHeader {
    flex-direction: row;
    align-items: center;
  }

  .headerRight {
    display: flex;
    gap: var(--spacing-sm);
  }
/* 
  .headerRight {
    display: none;
  } */

  .chatControls {
    margin-left: 0;
  }

  .logo {
    width: 100px;
  }
}

@media (max-width: 480px) {
  .headerContent,
  .dashboardHeader {
    padding: var(--spacing-xs);
  }

  .breadcrumbs {
    display: none;
  }

  .headerRight {
    display: none;
  }
}