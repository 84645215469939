/* src/components/TripDetails/TripScoreOverview.module.css */
.card {
    width: 100%;
    position: relative;
    box-shadow: 0px 13px 4px rgba(0, 0, 0, 0), 0px 8px 3px rgba(0, 0, 0, 0), 0px 5px 3px rgba(0, 0, 0, 0.02), 0px 2px 2px rgba(0, 0, 0, 0.03), 0px 1px 1px rgba(0, 0, 0, 0.03);
    border-radius: 16px;
    background-color: #fff;
    border: 2px solid #f7f7f8;
    box-sizing: border-box;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    text-align: left;
    font-size: 18px;
    color: #14151a;
    font-family: 'Instrument Sans';
  }
  
  .cardStandartTitleRow {
    align-self: stretch;
    border: 1px solid #f7f7f8;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 20px;
    gap: 16px;
  }
  
  .textParent {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 10px;
  }
  
  .text {
    align-self: stretch;
    position: relative;
    line-height: 24px;
    font-weight: 500;
  }
  
  .text1 {
    align-self: stretch;
    position: relative;
    font-size: 12px;
    line-height: 16px;
    font-family: Inter;
    color: #9ea2ad;
  }
  
  .button {
    box-shadow: 0px 4px 1px rgba(0, 0, 0, 0), 0px 2px 1px rgba(0, 0, 0, 0), 0px 1px 1px rgba(0, 0, 0, 0.02), 0px 1px 1px rgba(0, 0, 0, 0.03);
    border-radius: 10px;
    background-color: #fff;
    border: 1px solid #e9eaec;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 9px;
  }
  
  .icons18MoreHorizontal {
    width: 14px;
    position: relative;
    height: 14px;
    overflow: hidden;
    flex-shrink: 0;
  }
  
  .cardScoredContainer {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 20px;
    gap: 16px;
    font-size: 12px;
    color: #333;
    font-family: Inter;
  }
  
  .cardStandartContentA {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  
  .cardStandartContentA1 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 1px;
  }
  
  .cardStandartContentA2 {
    border-radius: 9px;
    background-color: #f7f7f8;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    padding: 6px 8px 6px 10px;
  }
  
  .div {
    position: relative;
    line-height: 18px;
    font-weight: 500;
  }
  
  .cardScoredContainerCo {
    align-self: stretch;
    border-radius: 16px;
    border: 1px solid #f7f7f8;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
  
  .cardScoredContentARo {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
  }
  
  .cardScoredContentARo1 {
    flex: 1;
    background-color: #fff;
    border: 1px solid #f7f7f8;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 16px;
    gap: 10px;
  }
  
  .badgeStatus {
    border-radius: 8px;
    background-color: #effaf6;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 6px 8px;
    color: #38c793;
  }
  
  .div1 {
    position: relative;
    line-height: 12px;
    font-weight: 600;
  }