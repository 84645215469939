/* File: frontend/src/pages/Status/Status.module.css */

.container {
    min-height: 100vh;
    background: var(--bg-secondary);
    padding: var(--spacing-lg);
}

.content {
    max-width: 1440px;
    margin: 0 auto;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: var(--spacing-lg);
}

.header h1 {
    margin: 0;
    font-size: var(--font-size-3xl);
    font-weight: var(--font-weight-semibold);
    color: var(--text-primary);
}

.refreshButton {
    display: flex;
    align-items: center;
    gap: var(--spacing-xxs);
    padding: var(--spacing-xxs) var(--spacing-sm);
    border: none;
    border-radius: var(--border-radius-md);
    background: var(--color-primary);
    color: var(--color-white);
    font-weight: var(--font-weight-semibold);
    cursor: pointer;
    transition: var(--transition-fast);
}

.refreshButton:hover {
    background: var(--color-primary-dark);
}

.refreshButton:disabled {
    background: var(--color-primary-light);
    cursor: not-allowed;
}

.refreshButton i {
    font-size: var(--font-size-base);
}

.spinning {
    animation: spin 1s linear infinite;
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.grid {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-md);
}

.section {
    width: 100%;
}

.error {
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--bg-secondary);
    padding: var(--spacing-lg);
}

.errorContent {
    text-align: center;
    background: var(--bg-primary);
    padding: var(--spacing-lg);
    border-radius: var(--border-radius-lg);
    box-shadow: var(--shadow-sm);
}

.errorContent i {
    font-size: var(--font-size-4xl);
    color: var(--color-error);
    margin-bottom: var(--spacing-sm);
}

.errorContent h2 {
    margin: 0 0 var(--spacing-sm) 0;
    color: var(--text-primary);
}

.errorContent p {
    margin: 0 0 var(--spacing-md) 0;
    color: var(--text-secondary);
}

.retryButton {
    display: inline-flex;
    align-items: center;
    gap: var(--spacing-xxs);
    padding: var(--spacing-xxs) var(--spacing-sm);
    border: none;
    border-radius: var(--border-radius-md);
    background: var(--color-primary);
    color: var(--color-white);
    font-weight: var(--font-weight-semibold);
    cursor: pointer;
    transition: var(--transition-fast);
}

.retryButton:hover {
    background: var(--color-primary-dark);
}

.footer {
    margin-top: var(--spacing-lg);
    padding-top: var(--spacing-sm);
    border-top: 1px solid var(--color-border);
}

.footerContent {
    text-align: center;
    color: var(--text-secondary);
    font-size: var(--font-size-sm);
}

@media (max-width: 768px) {
    .container {
        padding: var(--spacing-sm);
    }

    .header {
        flex-direction: column;
        gap: var(--spacing-sm);
        text-align: center;
    }

    .header h1 {
        font-size: var(--font-size-xl);
    }
}