/* src/components/KnowledgeTransfer/EditModal.module.css */
.modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modalContent {
    background-color: #fff;
    padding: 24px;
    border-radius: 16px;
    width: 80%;
    max-width: 600px;
  }
  
  .inputGroup {
    margin-bottom: 16px;
  }
  
  .inputGroup label {
    display: block;
    margin-bottom: 8px;
    font-weight: 600;
  }
  
  .inputGroup input,
  .inputGroup textarea {
    width: 100%;
    padding: 8px 12px;
    border: 1px solid #e9eaec;
    border-radius: 8px;
    font-size: 14px;
  }
  
  .inputGroup textarea {
    min-height: 100px;
    resize: vertical;
  }
  
  .modalActions {
    display: flex;
    justify-content: flex-end;
    margin-top: 24px;
  }
  
  .saveButton,
  .cancelButton {
    padding: 8px 16px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-size: 14px;
    font-weight: 600;
    margin-left: 12px;
  }
  
  .saveButton {
    background-color: #38c793;
    color: #fff;
  }
  
  .cancelButton {
    background-color: #f7f7f8;
    color: #464a53;
  }