/* File: frontend/src/pages/InvestorPitch/components/slides/GoToMarketSlide/ChannelCard.module.css */

.card {
  /* background-color: var(--color-gray-1200); */
  /* border-radius: var(--border-radius-lg); */
  padding: var(--spacing-xl);
  /* border: 1px solid var(--color-gray-1100); */
  height: 100%;
  display: flex;
  flex-direction: column;
  /* gap: var(--spacing-lg); */
}

.title {
  font-size: var(--font-size-xl);
  font-weight: var(--font-weight-bold);
  color: var(--text-on-primary);
  text-align: center;
}

.content {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}