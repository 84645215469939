/* File: frontend/src/components/form/ContactForm/ContactForm.module.css */

.contactFormContainer {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-md);
  width: 100%;
}

.form {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-md);
  width: 100%;
}

.form textarea {
  min-height: 150px;
  resize: vertical;
}

@media (prefers-color-scheme: dark) {
  .form input,
  .form textarea {
    background-color: var(--color-background-dark-light);
    color: var(--color-text-dark);
  }
}