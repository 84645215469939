/* File: frontend/src/components/form/FormField/FormField.module.css */

.formField {
    display: flex;
    flex-direction: column;
    margin-bottom: var(--spacing-xs);
  }
  
  .inputWrapper {
    margin-top: var(--spacing-xs);
  }
  
  .hint,
  .error {
    display: flex;
    align-items: flex-start;
    font-family: var(--font-secondary, Inter, sans-serif);
    font-size: var(--hint-font-size);
    line-height: var(--hint-line-height);
    padding: var(--hint-padding);
    margin-top: var(--spacing-xxs);
  }
  
  .hint {
    color: var(--hint-color);
  }
  
  .error {
    color: var(--color-error);
  }
  
  .hintIcon,
  .errorIcon {
    width: var(--hint-icon-size);
    height: var(--hint-icon-size);
    margin-right: var(--hint-icon-gap);
    flex-shrink: 0;
  }
  
  @media (max-width: 768px) {
    .formField {
      margin-bottom: var(--spacing-sm);
    }
  }