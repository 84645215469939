/* File: frontend/src/pages/Auth/SignIn.module.css */

.signInPage {
  display: flex;
  width: 100%;
  min-height: calc(100vh - 200px); /* Changed from fixed height to min-height */
  border-radius: var(--border-radius-lg);
  overflow: hidden;
  box-shadow: var(--shadow-md);
  position: relative;
}

.signInPage > div {
  width: 100%;
}

.formSection {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: var(--spacing-md);
  overflow-y: auto;
  background-color: var(--bg-primary);
  min-width: 320px;
}

.promotionSection {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: var(--spacing-xl);
  /* margin: var(--spacing-xl); */
  background: linear-gradient(135deg, #667eea 0%, #764ba2 100%);
  border-radius: var(--border-radius-lg);
}

.otpSection {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: var(--spacing-xl);
}

.error {
  margin: var(--spacing-sm);
  padding: var(--spacing-sm) var(--spacing-md);
  background-color: var(--color-error-bg);
  border: 1px solid var(--color-error-border);
  border-radius: var(--border-radius-md);
  color: var(--color-error);
  font-size: var(--font-size-sm);
  text-align: center;
}

.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: var(--spacing-sm);
  color: var(--text-secondary);
  font-size: var(--font-size-sm);
}

.fadeIn {
  animation: fadeIn var(--transition-normal);
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

/* Responsive styles */
@media (max-width: 1024px) {
  .signInPage {
    flex-direction: column;
    gap: var(--spacing-md);
    padding: var(--spacing-sm); /* Add padding to container */
    overflow: visible; /* Allow content to breathe */
  }

  .formSection {
    order: 1; /* Move form to top on mobile */
    padding: var(--spacing-md);
    margin: 0; /* Reset margin */
    width: 100%;
    border-radius: var(--border-radius-lg);
  }

  .formSection,
  .promotionSection {
    flex: none;
    width: 100%;
  }

  .promotionSection {
    order: 2; /* Move promotion below form */
    min-height: 200px;
    width: 100%; /* Ensure full width */
    margin: 0;
    padding: var(--spacing-xl);
    border-radius: var(--border-radius-lg);
  }

  .otpSection {
    padding: var(--spacing-md);
  }
}

@media (max-width: 768px) {
  .signInPage {
    padding: var(--spacing-sm);
    box-shadow: none;
    gap: var(--spacing-sm);
  }
  
  .formSection {
    padding: var(--spacing-sm);
  }

  .promotionSection {
    /* min-height: 180px; Further reduced height on mobile */
    padding: var(--spacing-xl) var(--spacing-md); /* Adjust padding */
    /* margin: var(--spacing-sm); */
  }

  .otpSection {
    padding: var(--spacing-sm);
  }

}

/* Dark mode support */
@media (prefers-color-scheme: dark) {
  .formSection {
    background-color: var(--bg-secondary);
  }

  .promotionSection {
    background: linear-gradient(135deg, #2d3748 0%, #1a202c 100%);
  }
}