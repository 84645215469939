/* File: frontend/src/pages/InvestorPitch/components/slides/SecuritySlide/ComplianceStandards.module.css */

.container {
  background-color: var(--color-gray-1200);
  border-radius: var(--border-radius-lg);
  padding: var(--spacing-lg);
  border: 1px solid var(--color-gray-1100);
  display: flex;
  flex-direction: column;
  gap: var(--spacing-lg);
  height: 100%;
}

.standard {
  padding-bottom: var(--spacing-lg);
  border-bottom: 1px solid var(--color-gray-1100);
}

.standard:last-child {
  padding-bottom: 0;
  border-bottom: none;
}

.header {
  margin-bottom: var(--spacing-md);
}

.standardInfo {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.title {
  font-size: var(--font-size-lg);
  font-weight: var(--font-weight-bold);
  color: var(--text-on-primary);
  margin: 0;
}

.status {
  display: flex;
  align-items: center;
  gap: var(--spacing-xs);
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-medium);
  padding: var(--spacing-xxs) var(--spacing-sm);
  background-color: rgba(255, 255, 255, 0.05);
  border-radius: var(--border-radius-full);
}

.icon {
  width: 16px;
  height: 16px;
}

.details {
  list-style: none;
  padding: 0;
  margin: 0;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: var(--spacing-sm);
}

.detailItem {
  position: relative;
  padding-left: var(--spacing-lg);
  color: var(--color-gray-400);
  font-size: var(--font-size-sm);
  line-height: 1.5;
}

.detailItem::before {
  content: "•";
  position: absolute;
  left: 0;
  color: var(--color-primary-500);
}

@media (max-width: 1200px) {
  .container {
    padding: var(--spacing-md);
  }
  
  .standard {
    padding-bottom: var(--spacing-md);
  }
  
  .title {
    font-size: var(--font-size-base);
  }
}

@media (max-width: 992px) {
  .details {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 768px) {
  .container {
    padding: var(--spacing-sm);
    gap: var(--spacing-md);
  }
  
  .standard {
    padding-bottom: var(--spacing-sm);
  }
  
  .standardInfo {
    flex-direction: column;
    align-items: flex-start;
    gap: var(--spacing-xs);
  }
  
  .status {
    font-size: var(--font-size-xs);
  }
  
  .detailItem {
    font-size: var(--font-size-xs);
    padding-left: var(--spacing-md);
  }
}