/* File: frontend/src/components/AppStoreButtons/AppStoreButtons.module.css */

.container {
    display: flex;
    gap: var(--spacing-md);
    flex-wrap: wrap;
    justify-content: flex-start;
  }
  
  .button {
    display: inline-block;
    transition: transform 0.2s ease-in-out;
  }
  
  .button:hover {
    transform: scale(1.05);
  }
  
  .badge {
    height: 40px;
    width: auto;
  }
  
  @media (max-width: 768px) {
    .badge {
      height: 36px;
    }
  }