/* File: frontend/src/styles/colors/colors.css */

:root {
    /* Colors */
    --color-primary: #2348ED;
    --color-primary-dark: #1A36B3;
    --color-primary-light: #6B87F5;
    --color-secondary: #F17B2C;
    --color-tertiary: #38C793;
    
    --color-gray-0: #FFFFFF;
    --color-gray-100: #F7F7F8;
    --color-gray-200: #E9EAEC;
    --color-gray-300: #DEE0E3;
    --color-gray-400: #C8CAD0;
    --color-gray-500: #BABDC5;
    --color-gray-600: #9EA2AD;
    --color-gray-700: #7E869A;
    --color-gray-800: #717684;
    --color-gray-900: #5E636E;
    --color-gray-1000: #464A53;
    --color-gray-1100: #333333;
    --color-gray-1200: #1F2228;
    --color-gray-1300: #14151A;

    --color-primary-100: #EFF4FF;
    --color-primary-200: #DBE6FE;
    --color-primary-300: #BED3FF;
    --color-primary-500: #5E91FC;
    --color-primary-600: #3667F8;
    --color-primary-700: var(--color-primary);
    --color-primary-800: #1B35DA;
    --color-primary-900: #1C2CB1;

    /* Alert Colors */
    --color-red-middle: #DF1C41;
    --color-red-lighter: #FDEDF0;
    --color-red-light: #F8C9D2;

    --color-error: var(--color-red-middle);
    --color-error-bg: var(--color-red-lighter);
    --color-error-border: var(--color-red-light);
    --color-error-shadow: rgba(223, 28, 65, 0.1);

    --color-yellow-middle: #F2AE40;
    --color-yellow-lighter: #FEF7EC;
    --color-yellow-light: #FBDFB1;

    --color-warning: var(--color-yellow-middle);
    --color-warning-bg: var(--color-yellow-lighter);
    --color-warning-border: var(--color-yellow-light);
    --color-warning-shadow: rgba(242, 174, 64, 0.1);

    --color-blue-middle: #375DFB;
    --color-blue-lighter: #EBF1FF;
    --color-blue-light: #C2D6FF;

    --color-info: var(--color-blue-middle);
    --color-info-bg: var(--color-blue-lighter);
    --color-info-border: var(--color-blue-light);
    --color-info-shadow: rgba(55, 93, 251, 0.1);

    --color-green-middle: #38C793;
    --color-green-lighter: #EFFAF6;
    --color-green-light: #CBF5E5;

    --color-success: var(--color-green-middle);
    --color-success-bg: var(--color-green-lighter);
    --color-success-border: var(--color-green-light);
    --color-success-shadow: rgba(56, 199, 147, 0.1);

    --color-hint-bg: var(--color-primary-200);
    --color-hint-label: #1D2B8B;
    --color-hint-text: var(--color-primary-600);

    /* Neutral colors */
    --color-black: #0A0A0A;
    --color-dark-gray: var(--color-gray-1300);
    --color-gray: var(--color-gray-900);
    --color-light-gray: var(--color-gray-500);
    --color-white: var(--color-gray-0);

    /* Background colors */
    --bg-primary: var(--color-white);
    --bg-secondary: var(--color-gray-100);
    --bg-tertiary: var(--color-gray-200);
    --bg-hover: #F0F0F1;
    --bg-active: #E5E5E6;
    --bg-disabled: var(--color-gray-100);

    /* Code block specific colors */
    --bg-code: var(--bg-tertiary);
    --bg-code-header: var(--bg-secondary);
    --bg-inline-code: var(--bg-secondary);

    /* Text colors */
    --text-primary: var(--color-dark-gray);
    --text-secondary: var(--color-gray);
    --text-tertiary: var(--color-light-gray);
    --text-disabled: var(--color-light-gray);
    --text-on-primary: var(--color-white);

    /* Border colors */
    --color-border: var(--color-gray-200);
    --color-border-hover: var(--color-gray-300);
    --color-border-light: var(--bg-hover);
    --color-focus: var(--color-gray-100);

    /* 5 Piece Palette - Warm */
    --color-yellow-100: #FFF33B;
    --color-yellow-200: #FDC70C;
    --color-orange-300: #F3903F;
    --color-orange-400: #ED683C;
    --color-red-500: #E93E3A;
    
    /* 5 Piece Palette - Cool */
    --color-blue-100: #577590;
    --color-green-200: #43AA8B;
    --color-green-300: #90BE6D;
    --color-yellow-400: #F9C74F;
    --color-red-600: #EE3E32;

    /* 5 Piece - Scoring & Customer - Yellow */
    --color-yellow-110: #FEF7EC;
    --color-yellow-210: #FBDFB1;
    --color-yellow-310: #F2AE40;
    --color-brown-410: #B47818;
    --color-brown-510: #693D11;

    /* 5 Piece - Scoring & Customer - Red */
    --color-red-110: #FDEDF0;
    --color-red-210: #F8C9D2;
    --color-red-310: #DF1C41;
    --color-red-410: #AF1D38;
    --color-red-510: #710E21;

    /* 5 Piece - Scoring & Customer - Green */
    --color-green-110: #EFFAF6;
    --color-green-210: #CBF5E5;
    --color-green-310: #38C793;
    --color-green-410: #2D9F75;
    --color-green-510: #176448;

    /* 5 Piece - Scoring & Customer - Orange */
    --color-orange-110: #FEF3EB;
    --color-orange-210: #FFDAC2;
    --color-orange-310: #F17B2C;
    --color-orange-410: #C2540A;
    --color-orange-510: #6E330C;

    /* 5 Piece - Scoring & Customer - Blue */
    --color-blue-110: #EBF1FF;
    --color-blue-210: #C2D6FF;
    --color-blue-310: #375DFB;
    --color-blue-410: #253EA7;
    --color-blue-510: #162664;

    /* 7 Piece Palette - Cool */
    --color-green-120: #D9ED92;
    --color-green-220: #B5E48C;
    --color-green-320: #99D98C;
    --color-green-420: #76C893;
    --color-green-520: #52B69A;
    --color-blue-620: #34A0A4;
    --color-blue-720: #168AAD;

    /* 7 Piece Palette - Warm */
    --color-green-130: #007F5F;
    --color-green-230: #2B9348;
    --color-green-330: #55A630;
    --color-yellow-430: #80B918;
    --color-yellow-530: #AACC00;
    --color-yellow-630: #BFD200;
    --color-yellow-730: #D4D700;

    /* 9 Piece Palette - Cool */
    --color-white: #FFFFFC;
    --color-pink-100: #FFC6FF;
    --color-purple-200: #BDB2FF;
    --color-blue-300: #A0C4FF;
    --color-cyan-400: #9BF6FF;
    --color-green-500: #CAFFBF;
    --color-yellow-600: #FDFFB6;
    --color-orange-700: #FFD6A5;
    --color-red-800: #FFADAD;

    /* 9 Piece Palette - Warm */
    --color-pink-110: #FAE0E4;
    --color-pink-210: #F7CAD0;
    --color-pink-310: #F9BEC7;
    --color-pink-410: #FBB1BD;
    --color-pink-510: #FF99AC;
    --color-pink-610: #FF85A1;
    --color-pink-710: #FF7096;
    --color-pink-810: #FF477E;
    --color-red-910: #FF0A54;

    /* 11 Piece - Heatmaps */
    --color-blue-140: #EFF4FF;
    --color-blue-240: #DBE6FE;
    --color-blue-340: #BED3FF;
    --color-blue-440: #92B7FE;
    --color-blue-540: #5E91FC;
    --color-blue-640: #3667F8;
    --color-blue-740: #2348ED;
    --color-blue-840: #1B35DA;
    --color-blue-940: #1C2CB1;
    --color-blue-1040: #1D2B8B;
    --color-blue-1140: #161D55;
  }