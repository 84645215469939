/* File: frontend/src/pages/InvestorPitch/components/slides/GrowthSlide/GrowthSlide.module.css */

.container {
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: var(--spacing-md);
  }
  
  .header {
    text-align: center;
    margin-bottom: var(--spacing-md);
  }
  
  .title {
    font-size: var(--font-size-4xl);
    font-weight: var(--font-weight-bold);
    color: var(--text-on-primary);
    margin-bottom: var(--spacing-md);
  }
  
  .subtitle {
    font-size: var(--font-size-xl);
    color: var(--color-gray-400);
  }
  
  .growthTrajectory {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: var(--spacing-md);
    margin: var(--spacing-md) 0;
  }
  
  .arrowWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 48px;
    height: 48px;
  }
  
  .arrow {
    width: 32px;
    height: 32px;
    color: var(--color-gray-600);
  }
  
  .metrics {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: var(--spacing-xl);
    padding: 0 var(--spacing-xl);
    margin-top: var(--spacing-2xl);
  }
  
  @media (max-width: 1024px) {
    .metrics {
      grid-template-columns: 1fr;
      gap: var(--spacing-lg);
    }
  }
  
  @media (max-width: 768px) {
    .growthTrajectory {
      flex-direction: column;
      gap: var(--spacing-lg);
    }
  
    .arrowWrapper {
      transform: rotate(90deg);
    }
  
    .metrics {
      grid-template-columns: 1fr;
      gap: var(--spacing-lg);
      padding: 0;
    }
  }