/* File: frontend/src/pages/InvestorPitch/components/slides/CompetitiveAdvantageSlide/ComparisonMatrix.module.css */

.container {
    background-color: var(--color-gray-1200);
    border-radius: var(--border-radius-lg);
    padding: var(--spacing-xl);
    border: 1px solid var(--color-gray-1100);
    overflow-x: auto;
  }
  
  .matrix {
    width: 100%;
    border-collapse: collapse;
  }
  
  .headerCell {
    text-align: left;
    padding: var(--spacing-md);
    color: var(--text-on-primary);
    font-weight: var(--font-weight-bold);
    font-size: var(--font-size-base);
    border-bottom: 1px solid var(--color-gray-1100);
  }
  
  .categoryCell {
    padding: var(--spacing-md);
    color: var(--color-primary-500);
    font-weight: var(--font-weight-bold);
    font-size: var(--font-size-base);
    background-color: var(--color-gray-1300);
  }
  
  .featureRow:hover {
    background-color: var(--color-gray-1100);
  }
  
  .featureCell {
    padding: var(--spacing-md);
    color: var(--color-gray-400);
    font-size: var(--font-size-sm);
  }
  
  .valueCell {
    padding: var(--spacing-md);
    font-size: var(--font-size-sm);
    font-weight: var(--font-weight-medium);
  }
  
  .ourValue {
    color: var(--color-primary-500);
  }
  
  .othersValue {
    color: var(--color-gray-400);
  }
  
  @media (max-width: 768px) {
    .container {
      padding: var(--spacing-lg);
    }
  
    .headerCell,
    .categoryCell,
    .featureCell,
    .valueCell {
      padding: var(--spacing-sm);
    }
  }