.container {
    height: 100vh;
    display: flex;
    flex-direction: column;
    background-color: var(--background-primary);
    padding: 0 var(--spacing-md);
    /* gap: var(--spacing-xl); */
  }
  
  .header {
    text-align: center;
    margin-bottom: var(--spacing-xl);
  }
  
  .title {
    font-size: var(--font-size-4xl);
    font-weight: var(--font-weight-bold);
    color: var(--text-on-primary);
    margin-bottom: var(--spacing-xs);
  }
  
  .subtitle {
    font-size: var(--font-size-xl);
    color: var(--color-gray-400);
  }
  
  .grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: var(--spacing-xl);
    padding: 0 var(--spacing-lg);
  }
  
  .card {
    background: rgba(255, 255, 255, 0.03);
    border: 1px solid var(--color-gray-1000);
    border-radius: var(--border-radius-lg);
    padding: var(--spacing-lg);
    display: flex;
    flex-direction: column;
    gap: var(--spacing-lg);
  }
  
  .cardTitle {
    font-size: var(--font-size-xl);
    font-weight: var(--font-weight-semibold);
    color: var(--text-on-primary);
    margin-bottom: var(--spacing-md);
    text-align: center;
  }
  
  .cardContent {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-md);
  }
  
  .parameter {
    display: grid;
    grid-template-columns: auto 1fr auto;
    gap: var(--spacing-sm);
    align-items: center;
  }
  
  .label {
    color: var(--color-gray-400);
    font-size: var(--font-size-sm);
  }
  
  .value {
    color: var(--text-on-primary);
    font-weight: var(--font-weight-semibold);
    font-size: var(--font-size-base);
    text-align: right;
  }

  .valueNew {
    color: var(--text-on-primary);
    font-weight: var(--font-weight-semibold);
    font-size: var(--font-size-sm);
    text-align: left;
  }
  
  .note {
    color: var(--color-gray-600);
    font-size: var(--font-size-xs);
    font-style: italic;
  }
  
  .footer {
    text-align: center;
    margin-top: var(--spacing-2xl);
  }
  
  .footerText {
    font-size: var(--font-size-lg);
    color: var(--color-gray-300);
    max-width: 800px;
    margin: 0 auto;
    line-height: var(--line-height-relaxed);
  }
  
  @media (max-width: var(--breakpoint-lg)) {
    .grid {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  
  @media (max-width: var(--breakpoint-md)) {
    .grid {
      grid-template-columns: 1fr;
    }
  
    .title {
      font-size: var(--font-size-3xl);
    }
  
    .subtitle {
      font-size: var(--font-size-lg);
    }
  
    .parameter {
      grid-template-columns: 1fr 1fr;
    }
  
    .note {
      grid-column: 1 / -1;
      text-align: left;
    }
  }