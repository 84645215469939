/* File: frontend/src/pages/PropertyInsight/components/LatestReports/LatestReports.module.css */

.container {
  background-color: var(--bg-primary);
  border-radius: var(--border-radius-lg);
  box-shadow: var(--shadow-card);
  padding: var(--spacing-lg);
  margin-bottom: var(--spacing-lg);
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: var(--spacing-lg);
}

.title {
  font-size: var(--font-size-lg);
  font-weight: var(--font-weight-semibold);
  color: var(--text-primary);
  margin: 0;
}

.searchContainer {
  width: 300px;
}

.searchInput {
  width: 100%;
  padding: var(--spacing-sm);
  border: 1px solid var(--color-border);
  border-radius: var(--border-radius-md);
  font-size: var(--font-size-sm);
  color: var(--text-primary);
}

.searchInput::placeholder {
  color: var(--text-tertiary);
}

.tableContainer {
  margin-bottom: var(--spacing-lg);
  overflow-x: auto;
}

.table {
  width: 100%;
  border-collapse: collapse;
}

.table th {
  background-color: var(--bg-secondary);
  padding: var(--spacing-sm);
  text-align: left;
  font-weight: var(--font-weight-semibold);
  color: var(--text-secondary);
}

.table td {
  padding: var(--spacing-sm);
  border-bottom: 1px solid var(--bg-secondary);
}

.statusBadge {
  display: inline-block;
  padding: 4px 8px;
  border-radius: var(--border-radius-sm);
  font-size: var(--font-size-xs);
  font-weight: var(--font-weight-semibold);
}

.in_progress {
  background-color: var(--color-info-bg);
  color: var(--color-info);
}

.ready {
  background-color: var(--color-success-bg);
  color: var(--color-success);
}

.downloadLink {
  color: var(--color-primary);
  text-decoration: underline;
  font-weight: var(--font-weight-semibold);
}

.pendingMessage {
  color: var(--text-primary);
  font-size: var(--font-size-sm);
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: var(--spacing-xs);
}

.paginationArrow {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  background: none;
  border: none;
  cursor: pointer;
  color: var(--text-secondary);
}

.paginationArrow:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.pageNumber {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: var(--border-radius-sm);
  background-color: var(--bg-primary);
  border: 1px solid var(--color-border);
  font-size: var(--font-size-sm);
  color: var(--text-primary);
  cursor: pointer;
}

.activePage {
  background-color: var(--color-primary-100);
  border-color: var(--color-primary-200);
  color: var(--color-primary-dark);
}

@media (max-width: 768px) {
  .header {
    flex-direction: column;
    align-items: flex-start;
    gap: var(--spacing-md);
  }
  
  .searchContainer {
    width: 100%;
  }
}