/* File: frontend/src/pages/AI1/AI1.module.css */

.aiContainer {
  display: flex;
  flex-direction: column;
  /* height: calc(100vh - var(--header-height)); */
  height: 85vh;
  background-color: var(--bg-secondary);
  position: relative;
  overflow: hidden;
}
  
.aiContainer * {
  box-sizing: border-box;
}

/* Add styles for when there's no chat container */
.aiContainer:not(:has(.chatContainer)) {
  justify-content: center; /* This will center the input section vertically */
  /* padding-bottom: 15vh; */
}

.moveToBottom {
  animation: slideToBottom 0.5s ease-out forwards;
}

.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 400px;
  gap: var(--spacing-md);
  color: var(--text-secondary);
}

.loadingSpinner {
  width: var(--spacing-md);
  height: var(--spacing-md);
  border: 2px solid var(--color-primary-light);
  border-top: 2px solid var(--color-primary);
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes slideToBottom {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(calc(100vh - 100%));
  }
}

/* Update input container styles */
.inputContainer {
  transition: transform 0.5s ease-out;
}