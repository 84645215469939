/* File: frontend/src/pages/QMS/QuoteSearch/QuoteSearch.module.css */

.container {
  padding: var(--spacing-lg);
  max-width: 1200px;
  margin: 0 auto;
}

.pageTitle {
  font-size: var(--font-size-xl);
  font-weight: var(--font-weight-bold);
  color: var(--text-primary);
  margin-bottom: var(--spacing-lg);
}

.errorMessage {
  background-color: var(--color-error-bg);
  color: var(--color-error);
  border: 1px solid var(--color-error-border);
  border-radius: var(--border-radius-md);
  padding: var(--spacing-md);
  margin-bottom: var(--spacing-md);
}

.infoMessage {
  background-color: var(--color-info-bg);
  color: var(--color-info);
  border: 1px solid var(--color-info-border);
  border-radius: var(--border-radius-md);
  padding: var(--spacing-md);
  margin-bottom: var(--spacing-md);
}

@media (max-width: 768px) {
  .container {
    padding: var(--spacing-md);
  }
}