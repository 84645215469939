/* File: frontend/src/pages/QMS/QuoteDetail/components/QuoteLocations/QuoteLocations.module.css */

.container {
  display: flex;
  flex-direction: column;
}

.locationsList {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-md);
}

.locationCard {
  background-color: var(--bg-primary);
  border-radius: var(--border-radius-md);
  border: 1px solid var(--color-border);
  overflow: hidden;
}

.locationHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: var(--spacing-sm);
  background-color: var(--bg-secondary);
  border-bottom: 1px solid var(--color-border);
}

.locationTitle {
  font-size: var(--font-size-md);
  font-weight: var(--font-weight-semibold);
  margin: 0;
}

.primaryBadge {
  background-color: var(--color-primary-100);
  color: var(--color-primary);
  padding: 2px 8px;
  border-radius: 4px;
  font-size: var(--font-size-xs);
  font-weight: var(--font-weight-medium);
}

.locationContent {
  padding: var(--spacing-md);
  display: flex;
  flex-direction: column;
  gap: var(--spacing-md);
}

.addressSection, .detailsSection, .geolocationSection, .statusSection {
  border-bottom: 1px solid var(--color-border-light);
  padding-bottom: var(--spacing-md);
}

.addressSection:last-child, .detailsSection:last-child, 
.geolocationSection:last-child, .statusSection:last-child {
  border-bottom: none;
  padding-bottom: 0;
}

.sectionTitle {
  font-size: var(--font-size-base);
  font-weight: var(--font-weight-semibold);
  color: var(--text-secondary);
  margin: 0 0 var(--spacing-sm) 0;
}

.detailsGrid, .geolocationGrid, .statusGrid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: var(--spacing-md);
}

.infoGroup {
  display: flex;
  flex-direction: column;
}

.infoLabel {
  font-weight: var(--font-weight-medium);
  color: var(--text-secondary);
  margin-bottom: var(--spacing-xxs);
}

.infoValue {
  color: var(--text-primary);
  word-break: break-word;
}

.verified {
  color: var(--color-success);
  font-weight: var(--font-weight-semibold);
}

.pending {
  color: var(--color-info);
  font-weight: var(--font-weight-semibold);
}

.failed {
  color: var(--color-error);
  font-weight: var(--font-weight-semibold);
}

.needs_user_confirmation, .needs_admin_confirmation {
  color: var(--color-warning);
  font-weight: var(--font-weight-semibold);
}

.noData {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: var(--spacing-xl);
  color: var(--text-secondary);
  font-style: italic;
  text-align: center;
}

@media (max-width: 768px) {
  .detailsGrid, .geolocationGrid, .statusGrid {
    grid-template-columns: 1fr;
  }
}