/* File: frontend/src/pages/InvestorPitch/components/slides/MarketEntrySlide/EntryPhase.module.css */

.container {
    background-color: var(--color-gray-1200);
    border-radius: var(--border-radius-lg);
    overflow: hidden;
    /* margin-bottom: var(--spacing-2xl); */
  }
  
  .header {
    background: linear-gradient(135deg, var(--color-blue-510), var(--color-blue-510));
    padding: var(--spacing-2xl) var(--spacing-xl);
    text-align: center;
  }
  
  .title {
    font-size: var(--font-size-2xl);
    font-weight: var(--font-weight-bold);
    color: var(--color-white);
    margin-bottom: var(--spacing-sm);
  }
  
  .description {
    font-size: var(--font-size-lg);
    color: var(--color-white);
    opacity: 0.9;
  }
  
  .steps {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: var(--spacing-xl);
    padding: var(--spacing-2xl);
    background-color: var(--color-gray-1300);
  }
  
  .step {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: var(--spacing-md);
  }
  
  .iconWrapper {
    width: 48px;
    height: 48px;
    border-radius: var(--border-radius-lg);
    background-color: var(--color-gray-1200);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .icon {
    width: 24px;
    height: 24px;
    color: var(--text-on-primary);
  }
  
  .stepTitle {
    font-size: var(--font-size-lg);
    font-weight: var(--font-weight-bold);
    color: var(--text-on-primary);
    margin-bottom: var(--spacing-xs);
  }
  
  .stepDescription {
    font-size: var(--font-size-sm);
    color: var(--color-gray-400);
    line-height: 1.5;
  }
  
  @media (max-width: 768px) {
    .header {
      padding: var(--spacing-xl) var(--spacing-lg);
    }
  
    .steps {
      grid-template-columns: 1fr;
      padding: var(--spacing-xl);
    }
  }