/* File: frontend/src/pages/SuperAdmin/components/DatasetManagement/DatasetDetail/DatasetDetail.module.css */

.container {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-md);
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: var(--spacing-md);
}

.headerContent {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xs);
}

.datasetTitle {
  font-size: var(--font-size-xl);
  font-weight: var(--font-weight-bold);
  color: var(--text-primary);
  margin: 0;
}

.datasetId {
  font-size: var(--font-size-sm);
  color: var(--text-secondary);
  font-family: var(--font-family-mono);
}

.statusContainer {
  display: flex;
  gap: var(--spacing-sm);
  margin-top: var(--spacing-xs);
}

.statusBadge, .typeBadge, .sensitivityBadge {
  display: inline-block;
  padding: 4px 8px;
  border-radius: 12px;
  font-size: var(--font-size-xs);
  font-weight: var(--font-weight-medium);
  text-transform: capitalize;
}

.statusActive {
  background-color: var(--color-success-bg);
  color: var(--color-success);
}

.statusInactive {
  background-color: var(--color-error-bg);
  color: var(--color-error);
}

.statusArchived {
  background-color: var(--color-warning-bg);
  color: var(--color-warning);
}

.typeStructured {
  background-color: var(--color-primary-100);
  color: var(--color-primary);
}

.typeSemiStructured {
  background-color: var(--color-info-bg);
  color: var(--color-info);
}

.typeUnstructured {
  background-color: var(--color-warning-bg);
  color: var(--color-warning);
}

.sensitivityHigh {
  background-color: var(--color-error-bg);
  color: var(--color-error);
}

.sensitivityMedium {
  background-color: var(--color-warning-bg);
  color: var(--color-warning);
}

.sensitivityLow {
  background-color: var(--color-success-bg);
  color: var(--color-success);
}

.headerActions {
  display: flex;
  gap: var(--spacing-md);
}

.editButton {
  display: flex;
  align-items: center;
  gap: var(--spacing-xs);
  padding: var(--spacing-sm) var(--spacing-md);
  background-color: var(--color-primary);
  color: white;
  border: none;
  border-radius: var(--border-radius-md);
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-medium);
  cursor: pointer;
  transition: background-color var(--transition-fast);
}

.editButton:hover {
  background-color: var(--color-primary-dark);
}

.tabs {
  display: flex;
  border-bottom: 1px solid var(--color-border);
  margin-bottom: var(--spacing-md);
}

.tab {
  padding: var(--spacing-sm) var(--spacing-md);
  background: none;
  border: none;
  border-bottom: 2px solid transparent;
  color: var(--text-secondary);
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-medium);
  cursor: pointer;
  transition: all var(--transition-fast);
}

.tab:hover {
  color: var(--text-primary);
}

.activeTab {
  color: var(--color-primary);
  border-bottom-color: var(--color-primary);
}

.tabContent {
  padding: var(--spacing-sm) 0;
}

.section {
  margin-bottom: var(--spacing-lg);
}

.infoGrid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: var(--spacing-md);
}

.infoItem {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xs);
}

.infoLabel {
  font-size: var(--font-size-sm);
  color: var(--text-secondary);
  font-weight: var(--font-weight-medium);
}

.infoValue {
  font-size: var(--font-size-base);
  color: var(--text-primary);
}

.description {
  font-size: var(--font-size-base);
  color: var(--text-primary);
  line-height: 1.6;
  margin: 0;
}

.schemaContainer {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-lg);
}

.schemaCode {
  background-color: var(--bg-secondary);
  padding: var(--spacing-md);
  border-radius: var(--border-radius-md);
  font-family: var(--font-family-mono);
  font-size: var(--font-size-sm);
  overflow-x: auto;
  white-space: pre-wrap;
  color: var(--text-primary);
  border: 1px solid var(--color-border);
}

.schemaTable {
  border: 1px solid var(--color-border);
  border-radius: var(--border-radius-md);
  overflow: hidden;
}

.schemaTableTitle {
  padding: var(--spacing-sm) var(--spacing-md);
  background-color: var(--bg-secondary);
  margin: 0;
  font-size: var(--font-size-base);
  font-weight: var(--font-weight-medium);
  border-bottom: 1px solid var(--color-border);
}

.schemaTable table {
  width: 100%;
  border-collapse: collapse;
}

.schemaTable th, .schemaTable td {
  padding: var(--spacing-sm) var(--spacing-md);
  text-align: left;
  border-bottom: 1px solid var(--color-border-light);
}

.schemaTable th {
  background-color: var(--bg-secondary);
  font-weight: var(--font-weight-medium);
  color: var(--text-secondary);
}

.schemaTable tr:last-child td {
  border-bottom: none;
}

.metadataGrid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: var(--spacing-md);
}

.qualityScore {
  display: flex;
  align-items: center;
  gap: var(--spacing-sm);
}

.qualityBar {
  height: 8px;
  background-color: var(--color-success);
  border-radius: 4px;
}

.tagsList, .rolesList, .joinsList {
  display: flex;
  flex-wrap: wrap;
  gap: var(--spacing-xs);
}

.complianceTag, .roleTag, .joinTag {
  display: inline-block;
  padding: 4px 8px;
  border-radius: 12px;
  font-size: var(--font-size-xs);
  font-weight: var(--font-weight-medium);
}

.complianceTag {
  background-color: var(--color-info-bg);
  color: var(--color-info);
}

.roleTag {
  background-color: var(--color-primary-100);
  color: var(--color-primary);
}

.joinTag {
  background-color: var(--color-warning-bg);
  color: var(--color-warning);
}

.accessInfo {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-lg);
}

.authorizedRoles, .usageDetail {
  margin-top: var(--spacing-md);
}

.subSectionTitle {
  font-size: var(--font-size-base);
  font-weight: var(--font-weight-medium);
  color: var(--text-primary);
  margin: 0 0 var(--spacing-sm) 0;
}

.usersList {
  list-style: none;
  padding: 0;
  margin: 0;
}

.userItem {
  padding: var(--spacing-xs) 0;
  border-bottom: 1px solid var(--color-border-light);
}

.userItem:last-child {
  border-bottom: none;
}

.statsGrid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: var(--spacing-md);
  margin-bottom: var(--spacing-lg);
}

.statCard {
  background-color: var(--bg-secondary);
  border-radius: var(--border-radius-md);
  padding: var(--spacing-md);
  text-align: center;
  border: 1px solid var(--color-border);
}

.statValue {
  font-size: var(--font-size-xl);
  font-weight: var(--font-weight-bold);
  color: var(--color-primary);
  margin-bottom: var(--spacing-xs);
}

.statLabel {
  font-size: var(--font-size-sm);
  color: var(--text-secondary);
}

.usageDetails {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: var(--spacing-lg);
}

.emptyState {
  padding: var(--spacing-lg);
  text-align: center;
  color: var(--text-secondary);
  background-color: var(--bg-secondary);
  border-radius: var(--border-radius-md);
  border: 1px dashed var(--color-border);
}

.loading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: var(--spacing-xl);
  gap: var(--spacing-md);
}

.loading .spinner {
  width: 40px;
  height: 40px;
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  border-top-color: var(--color-primary);
}

.error, .notFound {
  padding: var(--spacing-lg);
  text-align: center;
  color: var(--color-error);
  background-color: var(--color-error-bg);
  border-radius: var(--border-radius-md);
  margin-bottom: var(--spacing-md);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--spacing-md);
}

.notFound {
  color: var(--text-secondary);
  background-color: var(--bg-secondary);
}

.errorMessage {
  padding: var(--spacing-md);
  background-color: var(--color-error-bg);
  color: var(--color-error);
  border-radius: var(--border-radius-md);
  margin-bottom: var(--spacing-md);
  display: flex;
  align-items: center;
  gap: var(--spacing-sm);
}

@media (max-width: 992px) {
  .infoGrid, .metadataGrid {
    grid-template-columns: repeat(2, 1fr);
  }
  
  .statsGrid {
    grid-template-columns: repeat(2, 1fr);
  }
  
  .usageDetails {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 768px) {
  .header {
    flex-direction: column;
    gap: var(--spacing-md);
  }
  
  .headerActions {
    width: 100%;
  }
  
  .backButton, .editButton {
    flex: 1;
    justify-content: center;
  }
  
  .tabs {
    overflow-x: auto;
    white-space: nowrap;
    padding-bottom: var(--spacing-xs);
  }
  
  .infoGrid, .metadataGrid {
    grid-template-columns: 1fr;
  }
  
  .statsGrid {
    grid-template-columns: 1fr;
  }
}