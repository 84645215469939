/* File: frontend/src/pages/AI1/components/TypingAnimation/TypingAnimation.module.css */

.typingContainer {
    display: flex;
    gap: 4px;
    padding: var(--spacing-sm);
    align-items: center;
  }
  
  .dot {
    width: 8px;
    height: 8px;
    background-color: var(--color-gray-400);
    border-radius: 50%;
    animation: bounce 1.4s infinite ease-in-out;
    opacity: 0.6;
  }
  
  .dot:nth-child(1) { animation-delay: -0.32s; }
  .dot:nth-child(2) { animation-delay: -0.16s; }
  
  @keyframes bounce {
    0%, 80%, 100% { transform: scale(0); }
    40% { transform: scale(1); }
  }