/* File: frontend/src/pages/Auth/ProductPromotion.module.css */

.productPromotion {
  color: var(--color-white);
  padding: var(--spacing-xl) var(--spacing-lg);
  max-width: 480px;
  width: 100%;
  box-sizing: border-box;
}

.content {
  text-align: center;
}

.title {
  font-size: var(--font-size-2xl);
  font-weight: var(--font-weight-bold);
  line-height: var(--line-height-tight);
  margin-bottom: var(--spacing-md);
}

.description {
  font-size: var(--font-size-lg);
  line-height: var(--line-height-normal);
  margin-bottom: var(--spacing-lg);
  opacity: 0.9;
}

.learnMoreButton {
  border: 1px solid rgba(255, 255, 255, 0.2);
}

/* Large screens */
@media (min-width: 1200px) {
  .productPromotion {
    padding: var(--spacing-2xl) var(--spacing-xl);
  }
}

/* Tablet and below */
@media (max-width: 768px) {
  .productPromotion {
    padding: var(--spacing-lg);
  }

  .title {
    font-size: var(--font-size-xl);
  }

  .description {
    font-size: var(--font-size-base);
  }
}

/* Mobile */
@media (max-width: 480px) {
  .productPromotion {
    padding: var(--spacing-md);
  }

  .title {
    font-size: var(--font-size-lg);
  }

  .description {
    font-size: var(--font-size-sm);
  }
}