/* File: frontend/src/pages/EmbeddedInsurance/components/ChatbotIntegration/styles/ChatbotIntegration.module.css */

.chatbotIntegration {
  padding: var(--spacing-lg);
}

.tabs {
  display: flex;
  border-bottom: 1px solid var(--color-border);
  margin-bottom: var(--spacing-lg);
  overflow-x: auto;
}

.tab {
  display: flex;
  align-items: center;
  gap: var(--spacing-xs);
  padding: var(--spacing-md) var(--spacing-lg);
  background: none;
  border: none;
  border-bottom: 2px solid transparent;
  color: var(--text-secondary);
  font-size: var(--font-size-base);
  font-weight: var(--font-weight-medium);
  cursor: pointer;
  transition: all var(--transition-fast);
  white-space: nowrap;
}

.tab:hover {
  color: var(--text-primary);
}

.activeTab {
  color: var(--color-primary);
  border-bottom-color: var(--color-primary);
}

.content {
  padding: var(--spacing-md) 0;
}

/* Overview Tab */
.overviewHeader {
  margin-bottom: var(--spacing-xl);
  text-align: center;
}

.overviewTitle {
  font-size: var(--font-size-2xl);
  font-weight: var(--font-weight-bold);
  color: var(--text-primary);
  margin-bottom: var(--spacing-sm);
}

.overviewDescription {
  font-size: var(--font-size-lg);
  color: var(--text-secondary);
  max-width: 800px;
  margin: 0 auto;
}

.featureGrid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: var(--spacing-lg);
  margin-bottom: var(--spacing-2xl);
}

.featureCard {
  padding: var(--spacing-lg);
  background-color: var(--bg-secondary);
  border-radius: var(--border-radius-lg);
  border: 1px solid var(--color-border);
  text-align: center;
  transition: transform var(--transition-fast), box-shadow var(--transition-fast);
}

.featureCard:hover {
  transform: translateY(-5px);
  box-shadow: var(--shadow-lg);
}

.featureIcon {
  font-size: var(--font-size-3xl);
  color: var(--color-primary);
  margin-bottom: var(--spacing-md);
}

.featureTitle {
  font-size: var(--font-size-lg);
  font-weight: var(--font-weight-semibold);
  color: var(--text-primary);
  margin-bottom: var(--spacing-sm);
}

.featureDescription {
  color: var(--text-secondary);
  line-height: var(--line-height-relaxed);
}

.demoSection {
  margin-top: var(--spacing-2xl);
}

.demoTitle {
  font-size: var(--font-size-xl);
  font-weight: var(--font-weight-semibold);
  color: var(--text-primary);
  margin-bottom: var(--spacing-lg);
  text-align: center;
}

.demoContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: var(--spacing-xl);
}

.demoChat {
  border-radius: var(--border-radius-lg);
  overflow: hidden;
  border: 1px solid var(--color-border);
  box-shadow: var(--shadow-lg);
  display: flex;
  flex-direction: column;
  height: 500px;
}

.demoChatHeader {
  display: flex;
  align-items: center;
  padding: var(--spacing-md);
  background-color: var(--color-primary);
  color: white;
}

.agentAvatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: var(--spacing-sm);
}

.agentAvatar img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.agentInfo {
  flex: 1;
}

.agentName {
  font-weight: var(--font-weight-semibold);
}

.agentStatus {
  font-size: var(--font-size-xs);
  opacity: 0.8;
}

.closeButton {
  background: none;
  border: none;
  color: white;
  cursor: pointer;
  font-size: var(--font-size-lg);
}

.demoChatMessages {
  flex: 1;
  padding: var(--spacing-md);
  overflow-y: auto;
  background-color: var(--bg-primary);
  display: flex;
  flex-direction: column;
  gap: var(--spacing-md);
}

.agentMessage, .userMessage {
  max-width: 80%;
  padding: var(--spacing-sm) var(--spacing-md);
  border-radius: var(--border-radius-lg);
}

.agentMessage {
  align-self: flex-start;
  background-color: var(--bg-secondary);
  border: 1px solid var(--color-border);
}

.userMessage {
  align-self: flex-end;
  background-color: var(--color-primary-bg);
  color: var(--color-primary);
}

.messageContent {
  margin-bottom: var(--spacing-xs);
  line-height: var(--line-height-relaxed);
}

.messageTime {
  font-size: var(--font-size-xs);
  color: var(--text-tertiary);
  text-align: right;
}

.demoChatInput {
  display: flex;
  padding: var(--spacing-sm);
  border-top: 1px solid var(--color-border);
  background-color: var(--bg-primary);
}

.chatInput {
  flex: 1;
  padding: var(--spacing-sm) var(--spacing-md);
  border: 1px solid var(--color-border);
  border-radius: var(--border-radius-full);
  margin-right: var(--spacing-sm);
}

.sendButton {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: var(--color-primary);
  color: white;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.demoInfo {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.demoInfoTitle {
  font-size: var(--font-size-lg);
  font-weight: var(--font-weight-semibold);
  color: var(--text-primary);
  margin-bottom: var(--spacing-lg);
}

.demoSteps {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-lg);
}

.demoStep {
  display: flex;
  gap: var(--spacing-md);
}

.stepNumber {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background-color: var(--color-primary);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: var(--font-weight-bold);
  flex-shrink: 0;
}

.stepContent {
  flex: 1;
}

.stepTitle {
  font-size: var(--font-size-md);
  font-weight: var(--font-weight-semibold);
  color: var(--text-primary);
  margin-bottom: var(--spacing-xs);
}

.stepDescription {
  color: var(--text-secondary);
  line-height: var(--line-height-relaxed);
}

/* Embed Tab */
.embedHeader, .apiHeader, .customizeHeader {
  margin-bottom: var(--spacing-xl);
}

.embedTitle, .apiTitle, .customizeTitle {
  font-size: var(--font-size-xl);
  font-weight: var(--font-weight-semibold);
  color: var(--text-primary);
  margin-bottom: var(--spacing-sm);
}

.embedDescription, .apiDescription, .customizeDescription {
  color: var(--text-secondary);
  line-height: var(--line-height-relaxed);
}

.embedCode, .apiCode {
  margin-bottom: var(--spacing-xl);
}

.embedCodeTitle, .apiCodeTitle {
  font-size: var(--font-size-lg);
  font-weight: var(--font-weight-semibold);
  color: var(--text-primary);
  margin-bottom: var(--spacing-md);
}

.embedInstructions {
  margin-top: var(--spacing-xl);
}

.embedInstructionsTitle {
  font-size: var(--font-size-lg);
  font-weight: var(--font-weight-semibold);
  color: var(--text-primary);
  margin-bottom: var(--spacing-lg);
}

.instructionsList {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-lg);
}

.instructionItem {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xs);
  padding: var(--spacing-md);
  background-color: var(--bg-secondary);
  border-radius: var(--border-radius-md);
  border: 1px solid var(--color-border);
}

.instructionTitle {
  font-size: var(--font-size-md);
  font-weight: var(--font-weight-semibold);
  color: var(--text-primary);
  margin: 0;
}

.instructionDescription {
  color: var(--text-secondary);
  line-height: var(--line-height-relaxed);
  margin: 0;
}

/* API Tab */
.apiEndpoints {
  margin-top: var(--spacing-xl);
}

.apiEndpointsTitle {
  font-size: var(--font-size-lg);
  font-weight: var(--font-weight-semibold);
  color: var(--text-primary);
  margin-bottom: var(--spacing-lg);
}

.endpoint {
  margin-bottom: var(--spacing-md);
  padding: var(--spacing-md);
  background-color: var(--bg-secondary);
  border-radius: var(--border-radius-md);
  border: 1px solid var(--color-border);
}

.endpointHeader {
  display: flex;
  align-items: center;
  gap: var(--spacing-md);
  margin-bottom: var(--spacing-xs);
}

.endpointMethod {
  padding: var(--spacing-xxs) var(--spacing-xs);
  background-color: var(--color-primary-bg);
  color: var(--color-primary);
  border-radius: var(--border-radius-sm);
  font-size: var(--font-size-xs);
  font-weight: var(--font-weight-semibold);
}

.endpointPath {
  font-family: var(--font-secondary);
  font-size: var(--font-size-sm);
  color: var(--text-primary);
}

.endpointDescription {
  color: var(--text-secondary);
  font-size: var(--font-size-sm);
  margin: 0;
}

/* Customize Tab */
.customizeForm {
  margin-bottom: var(--spacing-xl);
  padding: var(--spacing-lg);
  background-color: var(--bg-secondary);
  border-radius: var(--border-radius-lg);
  border: 1px solid var(--color-border);
}

.formRow {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: var(--spacing-md);
  margin-bottom: var(--spacing-md);
}

.formGroup {
  margin-bottom: var(--spacing-md);
}

.formLabel {
  display: block;
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-medium);
  color: var(--text-secondary);
  margin-bottom: var(--spacing-xs);
}

.formSelect, .formInput {
  width: 100%;
  padding: var(--spacing-sm);
  border: 1px solid var(--color-border);
  border-radius: var(--border-radius-md);
  background-color: var(--bg-primary);
}