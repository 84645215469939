/* File: frontend/src/pages/EmbeddedInsurance/components/common/styles/CodeBlock.module.css */

.codeBlockContainer {
  margin-bottom: var(--spacing-md);
  border-radius: var(--border-radius-md);
  overflow: hidden;
  border: 1px solid var(--color-border);
}

.codeBlockHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: var(--spacing-sm) var(--spacing-md);
  background-color: var(--bg-secondary);
  border-bottom: 1px solid var(--color-border);
}

.codeBlockTitle {
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-medium);
  color: var(--text-primary);
}

.codeBlockLanguage {
  font-size: var(--font-size-xs);
  color: var(--text-secondary);
  padding: 2px var(--spacing-xs);
  background-color: var(--bg-tertiary);
  border-radius: var(--border-radius-sm);
}

.codeBlock {
  position: relative;
  background-color: var(--color-gray-900);
}

.pre {
  margin: 0;
  padding: var(--spacing-md);
  overflow-x: auto;
}

.code {
  font-family: var(--font-secondary);
  font-size: var(--font-size-sm);
  color: var(--color-white);
  line-height: var(--line-height-relaxed);
}

.copyButton {
  position: absolute;
  top: var(--spacing-sm);
  right: var(--spacing-sm);
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.1);
  color: var(--color-white);
  border: none;
  border-radius: var(--border-radius-sm);
  cursor: pointer;
  transition: background-color var(--transition-fast);
}

.copyButton:hover {
  background-color: rgba(255, 255, 255, 0.2);
}

@media (max-width: 768px) {
  .pre {
    padding: var(--spacing-sm);
  }
  
  .code {
    font-size: var(--font-size-xs);
  }
}