/* File: frontend/src/pages/ArticleDetail/ArticleDetail.module.css */

.articleContainer {
  max-width: var(--breakpoint-md);
  margin: 0 auto;
  padding: clamp(1rem, 5vw, 2rem);
  font-family: var(--font-family-primary);
  color: var(--color-text);
  line-height: 1.6;
}

.chartContainer {
  /* margin: var(--spacing-2xl) 0; */
  padding: var(--spacing-md) 0;
  background: var(--color-background-light);
  border-radius: var(--border-radius-lg);
  box-shadow: var(--box-shadow-sm);
}

.chartTableContainer {
  /* margin: var(--spacing-xl) 0; */
  background: var(--color-background-light);
  border-radius: var(--border-radius-lg);
  padding: var(--spacing-lg);
  box-shadow: var(--box-shadow-sm);
}

.tableContainer {
  margin-top: var(--spacing-lg);
  overflow-x: auto;
}

.dataTable {
  width: 100%;
  border-collapse: collapse;
  font-family: var(--font-family-mono);
  font-size: var(--font-size-sm);
}

.dataTable th,
.dataTable td {
  padding: var(--spacing-sm);
  text-align: right;
  border: 1px solid var(--color-border);
}

.dataTable th:first-child,
.dataTable td:first-child {
  text-align: left;
  font-weight: 600;
}

.totalRow {
  font-weight: 600;
  background-color: var(--color-background);
}

.rowTotal,
.grandTotal {
  font-weight: 600;
  color: var(--color-primary);
}

.grandTotal {
  background-color: var(--color-primary-light);
}

.articleHeader {
  margin-bottom: var(--spacing-xl);
}

.articleTitle {
  font-size: clamp(2rem, 5vw, 2.5rem);
  color: var(--color-primary);
  margin-bottom: var(--spacing-sm);
  line-height: 1.2;
}

.articleMeta {
  display: flex;
  justify-content: space-between;
  font-size: var(--font-size-sm);
  color: var(--color-text-secondary);
}

.imageContainer {
  margin-bottom: var(--spacing-xl);
  border-radius: var(--border-radius-lg);
  overflow: hidden;
  box-shadow: var(--box-shadow-md);
}

.articleImage {
  width: 100%;
  height: auto;
  object-fit: cover;
}

/* Enhanced Article Content Styles with Markdown Support */
.articleContent {
  font-size: var(--font-size-lg);
  margin-bottom: var(--spacing-xl);
}

/* Markdown Typography */
.articleContent h1 {
  font-size: clamp(1.8rem, 4vw, 2.2rem);
  color: var(--color-primary);
  margin: var(--spacing-xl) 0 var(--spacing-md);
  line-height: 1.2;
  font-weight: 700;
}

.articleContent h2 {
  font-size: clamp(1.5rem, 3.5vw, 1.8rem);
  color: var(--color-primary);
  margin: var(--spacing-lg) 0 var(--spacing-md);
  line-height: 1.3;
  font-weight: 600;
}

.articleContent h3 {
  font-size: clamp(1.2rem, 3vw, 1.5rem);
  color: var(--color-primary);
  margin: var(--spacing-md) 0;
  line-height: 1.4;
  font-weight: 600;
}

.articleContent p {
  margin: var(--spacing-md) 0;
  line-height: 1.8;
}

/* Lists */
.articleContent ul,
.articleContent ol {
  margin: var(--spacing-md) 0;
  padding-left: var(--spacing-xxs);
}

.articleContent ul {
  list-style-type: disc;
}

.articleContent ol {
  list-style-type: decimal;
}

.articleContent li {
  margin: var(--spacing-xs) 0;
  padding-left: var(--spacing-xs);
}

/* Inline Text Styles */
.articleContent strong {
  font-weight: 700;
  color: var(--color-text-emphasis);
}

.articleContent em {
  font-style: italic;
}

/* Code Blocks */
.articleContent code {
  background-color: var(--color-background-light);
  padding: 0.2em 0.4em;
  border-radius: var(--border-radius-sm);
  font-family: var(--font-family-mono);
  font-size: 0.9em;
}

.articleContent pre {
  background-color: var(--color-background-light);
  padding: var(--spacing-md);
  border-radius: var(--border-radius-md);
  overflow-x: auto;
  margin: var(--spacing-md) 0;
}

.articleContent pre code {
  background-color: transparent;
  padding: 0;
}

/* Blockquotes */
blockquote {
  margin: var(--spacing-lg) 0;
  padding: var(--spacing-md) var(--spacing-lg);
  border-left: 4px solid var(--color-primary);
  background: var(--color-background-light);
  font-style: italic;
  color: var(--color-text-emphasis);
}

/* Blockquotes */
.articleContent blockquote {
  border-left: 4px solid var(--color-primary);
  margin: var(--spacing-md) 0;
  padding: var(--spacing-sm) var(--spacing-md);
  background-color: var(--color-background-light);
  font-style: italic;
  color: var(--color-text-secondary);
}

/* Links */
.articleContent a {
  color: var(--color-primary);
  text-decoration: none;
  border-bottom: 1px solid transparent;
  transition: border-color var(--transition-fast);
}

.articleContent a:hover {
  border-bottom-color: var(--color-primary);
}

/* Tables */
.articleContent table {
  width: 100%;
  border-collapse: collapse;
  margin: var(--spacing-md) 0;
}

.articleContent th,
.articleContent td {
  padding: var(--spacing-sm);
  border: 1px solid var(--color-border);
  text-align: left;
}

.articleContent th {
  background-color: var(--color-background-light);
  font-weight: 600;
}

/* Horizontal Rule */
.articleContent hr {
  border: 0;
  height: 1px;
  background-color: var(--color-border);
  margin: var(--spacing-lg) 0;
}

/* Typography Hierarchy */
.h1 {
  font-size: clamp(2.5rem, 5vw, 3rem);
  line-height: 1.2;
  font-weight: 800;
  color: var(--color-primary);
  margin: var(--spacing-2xl) 0 var(--spacing-xl);
  letter-spacing: -0.02em;
}

.h2 {
  font-size: clamp(2rem, 4vw, 2.5rem);
  line-height: 1.25;
  font-weight: 700;
  color: var(--color-primary);
  margin: var(--spacing-2xl) 0 var(--spacing-lg);
  letter-spacing: -0.01em;
}

.h3 {
  font-size: clamp(1.5rem, 3vw, 1.875rem);
  line-height: 1.3;
  font-weight: 600;
  color: var(--color-primary);
  margin: var(--spacing-xl) 0 var(--spacing-md);
}

.h4, .h5, .h6 {
  font-size: clamp(1.25rem, 2.5vw, 1.5rem);
  line-height: 1.4;
  font-weight: 600;
  color: var(--color-primary);
  margin: var(--spacing-lg) 0 var(--spacing-md);
}

/* Content Elements */
.paragraph {
  font-size: var(--font-size-lg);
  line-height: 1.8;
  margin: var(--spacing-md) 0;
  color: var(--color-text);
}

.spacer {
  height: var(--spacing-md);
}

/* Lists */
.unorderedList,
.orderedList {
  margin: var(--spacing-md) 0;
  padding-left: var(--spacing-xl);
}

.listItem,
.numberedListItem {
  font-size: var(--font-size-lg);
  line-height: 1.7;
  margin: var(--spacing-sm) 0;
  padding-left: var(--spacing-xs);
}

.listItem::marker {
  color: var(--color-primary);
}

.numberedListItem::marker {
  color: var(--color-primary);
  font-weight: 600;
}

/* Inline Styles */
.paragraph strong,
.listItem strong,
.numberedListItem strong,
.h1 strong,
.h2 strong,
.h3 strong {
  color: var(--color-primary);
  font-weight: 700;
}

.paragraph em,
.listItem em,
.numberedListItem em,
.h1 em,
.h2 em,
.h3 em {
  font-style: italic;
  color: var(--color-text-emphasis);
}

/* Numbers and Metrics Highlighting */
.paragraph span[data-type="metric"],
.listItem span[data-type="metric"],
.numberedListItem span[data-type="metric"] {
  color: var(--color-primary);
  font-weight: 600;
  font-feature-settings: "tnum" 1;
}

.chartTitle {
  font-size: var(--font-size-xl);
  color: var(--color-primary);
  margin-bottom: var(--spacing-md);
  font-weight: 600;
}

.articleFooter {
  border-top: 1px solid var(--color-border);
  padding-top: var(--spacing-md);
}

.shareTitle {
  font-size: var(--font-size-lg);
  margin-bottom: var(--spacing-md);
  color: var(--color-primary);
}

.shareButtons {
  display: flex;
  gap: var(--spacing-sm);
}

.shareButton {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: var(--color-background-light);
  color: var(--color-text);
  transition: all var(--transition-fast);
}

.shareButton img {
  width: 18px;
  height: 18px;
} 

.shareButton:hover {
  background-color: var(--color-primary);
  color: var(--color-white);
  transform: translateY(-2px);
}

.notFound {
  text-align: center;
  font-size: var(--font-size-xl);
  color: var(--color-text-secondary);
  margin-top: var(--spacing-2xl);
}

.tooltip {
  background-color: var(--color-background-light);
  border: 1px solid var(--color-border);
  padding: var(--spacing-sm);
  border-radius: var(--border-radius-md);
  box-shadow: var(--box-shadow-sm);
}

.tooltipLabel {
  font-weight: 600;
  margin-bottom: var(--spacing-xs);
  color: var(--color-text);
}

.tooltipRow {
  font-size: var(--font-size-sm);
  margin: var(--spacing-xs) 0;
  display: flex;
  justify-content: space-between;
  gap: var(--spacing-sm);
}

.comparison-chart {
  padding: 2rem;
  background: var(--color-background-secondary);
  border-radius: 8px;
  max-width: 1000px;
  margin: 0 auto;
}

.metric-group {
  margin-bottom: 2rem;
}

.metric-label {
  font-size: 1.1rem;
  font-weight: 600;
  margin-bottom: 1rem;
  color: var(--color-text-primary);
}

.metric-bars {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.bar-container {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.category-label {
  width: 150px;
  font-size: 0.9rem;
  color: var(--color-text-secondary);
  text-align: right;
}

.horizontal-bar {
  height: 40px;
  border-radius: 4px;
  transition: width 0.3s ease;
  display: flex;
  align-items: center;
  padding-left: 1rem;
}

.bar-value {
  color: white;
  font-weight: 500;
}

.savings-annotation {
  margin-top: 2rem;
  padding: 1rem;
  background: var(--color-success-light);
  border-radius: 4px;
  text-align: center;
}

.savings-amount {
  font-size: 1.4rem;
  font-weight: 600;
  color: var(--color-success);
}

.savings-percentage {
  font-size: 1.1rem;
  color: var(--color-text-secondary);
  margin-top: 0.5rem;
}

.barChartContainer {
  padding: var(--spacing-xl);
  background: var(--color-gray-0);
  border-radius: var(--border-radius-lg);
  box-shadow: var(--box-shadow-sm);
}

.metricContainer {
  margin-bottom: var(--spacing-lg);
}

.metricLabel {
  font-size: var(--font-size-base);
  color: var(--color-gray-900);
  margin-bottom: var(--spacing-xs);
}

.barContainer {
  width: 100%;
  height: 40px;
  background: var(--color-gray-100);
  border-radius: var(--border-radius-sm);
  overflow: hidden;
}

.bar {
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 var(--spacing-sm);
  color: var(--color-gray-0);
  font-weight: 500;
  transition: width var(--transition-fast);
}

.metricsFooter {
  margin-top: var(--spacing-lg);
  padding-top: var(--spacing-md);
  border-top: 1px solid var(--color-gray-200);
}

.improvement {
  font-size: var(--font-size-lg);
  color: var(--color-blue-310);
  font-weight: 600;
  text-align: right;
}

.metricHeader {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin-bottom: var(--spacing-xs);
}

.metricValue {
  font-size: var(--font-size-sm);
  color: var(--color-blue-310);
  font-weight: 600;
  font-family: var(--font-family-mono);
}

.qualityNote {
  font-size: var(--font-size-sm);
  color: var(--color-green-310);
  font-weight: 500;
  text-align: center;
  padding: var(--spacing-sm);
  background: var(--color-green-110);
  border-radius: var(--border-radius-sm);
}

.bar {
  height: 100%;
  background-color: var(--color-blue-310);
  border-radius: var(--border-radius-sm);
  transition: width var(--transition-fast);
}

.savingsContainer {
  margin-top: var(--spacing-xl);
  padding: var(--spacing-lg);
  background: var(--color-green-110);
  border-radius: var(--border-radius-lg);
  text-align: center;
}

.savingsAmount {
  font-size: var(--font-size-xl);
  color: var(--color-green-310);
  font-weight: 700;
  margin-bottom: var(--spacing-xs);
}

.savingsPercentage {
  font-size: var(--font-size-lg);
  color: var(--color-green-410);
  font-weight: 500;
}

.bar {
  height: 100%;
  background-color: var(--color-blue-310);
  border-radius: var(--border-radius-sm);
  transition: width var(--transition-fast);
  position: relative;
}

.bar::after {
  content: attr(data-value);
  position: absolute;
  right: var(--spacing-sm);
  color: var(--color-gray-0);
  font-weight: 500;
  font-size: var(--font-size-sm);
  line-height: 40px;
}

.stackedBarChartContainer {
  padding: var(--spacing-xl);
  background: var(--color-gray-0);
  border-radius: var(--border-radius-lg);
  box-shadow: var(--box-shadow-sm);
}

.stackedBarGroup {
  margin-bottom: var(--spacing-xl);
}

.categoryLabel {
  font-size: var(--font-size-lg);
  font-weight: 600;
  color: var(--color-gray-900);
  margin-bottom: var(--spacing-sm);
}

.stackedBar {
  height: 40px;
  display: flex;
  border-radius: var(--border-radius-sm);
  overflow: hidden;
}

.stackedBarSegment {
  height: 100%;
  transition: width var(--transition-fast);
}

.segmentLabels {
  display: flex;
  flex-wrap: wrap;
  gap: var(--spacing-md);
  margin-top: var(--spacing-sm);
}

.segmentLabel {
  display: flex;
  align-items: center;
  font-size: var(--font-size-sm);
  color: var(--color-gray-900);
}

.colorDot {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-right: var(--spacing-xs);
}

.chartLegend {
  display: flex;
  flex-wrap: wrap;
  gap: var(--spacing-lg);
  margin-top: var(--spacing-xl);
  padding-top: var(--spacing-md);
  border-top: 1px solid var(--color-gray-200);
}

.legendItem {
  display: flex;
  align-items: center;
  font-size: var(--font-size-sm);
  color: var(--color-gray-900);
}

.legendDot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: var(--spacing-xs);
}

.lineChartContainer {
  padding: var(--spacing-xl);
  background: var(--color-gray-0);
  border-radius: var(--border-radius-lg);
  box-shadow: var(--box-shadow-sm);
}

.customTooltip {
  background: var(--color-gray-0);
  border: 1px solid var(--color-gray-200);
  padding: var(--spacing-sm);
  border-radius: var(--border-radius-sm);
  box-shadow: var(--box-shadow-sm);
}

.tooltipLabel {
  font-weight: 600;
  margin-bottom: var(--spacing-xs);
  color: var(--color-gray-900);
}

.tooltipValue {
  font-size: var(--font-size-sm);
  margin: var(--spacing-xs) 0;
}

.lineChartContainer {
  padding: var(--spacing-xl);
  background: var(--color-gray-0);
  border-radius: var(--border-radius-lg);
  box-shadow: var(--box-shadow-sm);
  margin-bottom: var(--spacing-xl);
}

.customTooltip {
  background: var(--color-gray-0);
  border: 1px solid var(--color-gray-200);
  padding: var(--spacing-md);
  border-radius: var(--border-radius-md);
  box-shadow: var(--box-shadow-sm);
}

.tooltipLabel {
  font-weight: 600;
  margin-bottom: var(--spacing-xs);
  color: var(--color-gray-900);
  font-size: var(--font-size-sm);
}

.tooltipValue {
  font-size: var(--font-size-sm);
  margin: var(--spacing-xs) 0;
  font-family: var(--font-family-mono);
}

.focusShiftContainer {
  padding: var(--spacing-xl);
  background: var(--color-gray-0);
  border-radius: var(--border-radius-lg);
  box-shadow: var(--box-shadow-sm);
}

.insightBox {
  margin-top: var(--spacing-xl);
  padding: var(--spacing-lg);
  background: var(--color-blue-110);
  border-radius: var(--border-radius-md);
  border: 1px solid var(--color-blue-210);
}

.insightTitle {
  font-size: var(--font-size-lg);
  font-weight: 600;
  color: var(--color-blue-310);
  margin-bottom: var(--spacing-md);
}

.insightContent {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: var(--spacing-md);
}

.insightItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: var(--spacing-sm);
  background: var(--color-gray-0);
  border-radius: var(--border-radius-sm);
}

.insightLabel {
  font-size: var(--font-size-sm);
  color: var(--color-gray-900);
}

.insightValue {
  font-size: var(--font-size-sm);
  font-weight: 600;
  color: var(--color-blue-310);
  font-family: var(--font-family-mono);
}

.benefitsContainer {
  padding: var(--spacing-xl);
  background: var(--color-gray-0);
  border-radius: var(--border-radius-lg);
  box-shadow: var(--box-shadow-sm);
}

.metricsSection {
  margin-bottom: var(--spacing-xl);
}

.metricGroup {
  margin-bottom: var(--spacing-lg);
}

.metricHeader {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin-bottom: var(--spacing-xs);
}

.metricValue {
  font-size: var(--font-size-lg);
  font-weight: 600;
  font-family: var(--font-family-mono);
}

.insightsGrid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: var(--spacing-lg);
  margin-top: var(--spacing-xl);
}

.insightCard {
  padding: var(--spacing-lg);
  background: var(--color-gray-100);
  border-radius: var(--border-radius-md);
  border: 1px solid var(--color-gray-200);
}

.insightTitle {
  font-size: var(--font-size-lg);
  font-weight: 600;
  color: var(--color-primary);
  margin-bottom: var(--spacing-md);
}

.insightList {
  list-style: none;
  padding: 0;
}

.insightItem {
  display: flex;
  align-items: center;
  padding: var(--spacing-xs) 0;
  color: var(--color-gray-900);
  font-size: var(--font-size-base);
}

.insightItem::before {
  content: "•";
  color: var(--color-primary);
  font-weight: bold;
  margin-right: var(--spacing-sm);
}

/* Responsive Design */
@media (max-width: 768px) {
  .paragraph,
  .listItem,
  .numberedListItem {
    font-size: var(--font-size-base);
  }

  .chartContainer {
    margin: var(--spacing-xl) -1rem;
    border-radius: 0;
  }

  .tableContainer {
    margin-top: var(--spacing-md);
  }

  .dataTable {
      font-size: var(--font-size-xs);
  }
}

/* Print Styles */
@media print {
  .articleContainer {
    max-width: none;
    padding: 0;
  }

  .chartContainer {
    break-inside: avoid;
    page-break-inside: avoid;
  }
}