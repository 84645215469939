/* File: frontend/src/components/MileMonitor/StatisticsCard.module.css */

.cardStandartContent {
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 20px;
    box-sizing: border-box;
    gap: 16px;
    text-align: left;
    font-size: 32px;
    color: #710e21;
    font-family: 'Instrument Sans', sans-serif;
  }
  
  .cardBentoParent {
    width: 541px;
    position: relative;
    height: 275px;
  }
  
  .cardBento {
    position: absolute;
    top: 89px;
    left: 0px;
    border-radius: 18px 0px 18px 18px;
    background: linear-gradient(180deg, #f8c9d2, #fdedf0);
    width: 362px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 16px;
    box-sizing: border-box;
    gap: 16px;
  }
  
  .cardBentoIconArea {
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.05);
    backdrop-filter: blur(13px);
    border-radius: 14px;
    background-color: rgba(255, 255, 255, 0.4);
    border: 1px solid rgba(255, 255, 255, 0.3);
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 10px;
  }
  
  .icons18Crash {
    width: 26px;
    position: relative;
    height: 26px;
    overflow: hidden;
    flex-shrink: 0;
  }
  
  .mParent {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 10px;
  }
  
  .m {
    position: relative;
    line-height: 52px;
    font-weight: 600;
  }
  
  .kilometersOfDriving {
    align-self: stretch;
    position: relative;
    font-size: 14px;
    line-height: 18px;
    font-weight: 500;
    font-family: Inter, sans-serif;
    color: #14151a;
  }
  
  .frameWrapper {
    position: absolute;
    top: 89px;
    left: 376px;
    border-radius: 0px 18px 18px 0px;
    background: linear-gradient(180deg, #fbdfb1, #fef7ec);
    width: 165px;
    height: 186px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    padding: 16px;
    box-sizing: border-box;
    color: #693d11;
  }
  
  .cardBentoIconAreaParent {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    gap: 16px;
  }
  
  .mGroup {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    gap: 12px;
  }
  
  .cardBentoIconAreaGroup {
    position: absolute;
    top: 0px;
    left: 0px;
    border-radius: 18px 0px 18px 18px;
    background: linear-gradient(180deg, #cbf5e5, #effaf6);
    width: 541px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 16px;
    box-sizing: border-box;
    gap: 16px;
    color: #176448;
  }
  
  .cardBentoIconAreaContainer {
    position: absolute;
    top: 196px;
    left: 0px;
    border-radius: 18px 18px 0px 18px;
    background: linear-gradient(180deg, #d9e5ff, #eff4ff);
    width: 362px;
    height: 79px;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 16px;
    box-sizing: border-box;
    gap: 16px;
    color: #1d2b8b;
  }
  
  .card3CardInsideContent {
    align-self: stretch;
    box-shadow: 0px 0px 0px 7px #fff inset, 0px 0px 0px 8px rgba(0, 0, 0, 0.05) inset, 0px 8px 15px 4px rgba(255, 255, 255, 0.5) inset, 0px 5px 1px rgba(0, 0, 0, 0), 0px 3px 1px rgba(0, 0, 0, 0), 0px 2px 1px rgba(0, 0, 0, 0.02), 0px 1px 1px rgba(0, 0, 0, 0.03);
    border-radius: 16px;
    background-color: #fff;
    border: 1px solid #e9eaec;
    box-sizing: border-box;
    height: 255px;
    overflow: hidden;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
  
  .image148Icon {
    align-self: stretch;
    position: relative;
    max-width: 100%;
    overflow: hidden;
    height: 255px;
    flex-shrink: 0;
    object-fit: cover;
  }
  
  /* Responsive styles */
  @media (max-width: 768px) {
    .cardStandartContent {
      padding: 10px;
    }
  
    .cardBentoParent {
      width: 100%;
      height: auto;
    }
  
    .cardBento,
    .frameWrapper,
    .cardBentoIconAreaGroup,
    .cardBentoIconAreaContainer {
      position: static;
      width: 100%;
      border-radius: 18px;
      margin-bottom: 10px;
    }
  
    .cardBentoIconAreaGroup {
      flex-direction: column;
    }
  
    .card3CardInsideContent {
      height: auto;
    }
  
    .image148Icon {
      height: auto;
    }
  }