/* File: frontend/src/pages/InvestorPitch/PitchDeck.module.css */

.container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: var(--color-gray-1300);
  color: var(--color-white);
  position: relative;
}

.header {
  padding: 16px 24px;
  width: 100%;
}

.headerContent {
  padding: 0;
}

.slideQuickNav {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logoContainer {
  display: flex;
  align-items: center;
}

.logo {
  height: 32px;
}

.main {
  flex: 1;
  padding: 0 24px;
  position: relative;
  max-width: 1280px;
  margin: 0 auto;
  width: 100%;
}

.slideContainer {
  position: relative;
  width: 100%;
  height: 100%;
  padding-bottom: 32px;
}

.footer {
  padding: 16px 24px;
  border-top: 1px solid var(--color-gray-1200);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
}

.footerInfo {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  text-align: center;
}

.confidentialInfo {
  display: flex;
  align-items: center;
  gap: 16px;
  font-size: var(--font-size-sm);
  color: var(--color-gray-400);
}

.confidential {
  color: var(--color-red-middle);
  font-weight: var(--font-weight-bold);
  letter-spacing: 0.05em;
}

.separator {
  color: var(--color-gray-800);
}

.enterprise_id {
  font-family: var(--font-mono);
}

.copyright {
  font-size: var(--font-size-sm);
  color: var(--color-gray-500);
}

/* Authentication styles */
.authContainer {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--color-gray-1300);
  padding: 24px;
}

.authCard {
  background-color: var(--color-gray-1200);
  border-radius: 14px;
  padding: 32px;
  width: 100%;
  max-width: 480px;
  border: 1px solid var(--color-gray-1100);
}

.authTitle {
  font-size: var(--font-size-2xl);
  font-weight: var(--font-weight-bold);
  color: var(--color-white);
  margin-bottom: 16px;
  text-align: center;
}

.authDescription {
  font-size: var(--font-size-base);
  color: var(--color-gray-400);
  margin-bottom: 24px;
  text-align: center;
}

.authForm {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.formGroup {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.label {
  font-size: var(--font-size-sm);
  color: var(--color-gray-300);
}

.input {
  padding: 12px;
  background-color: var(--color-gray-1100);
  border: 1px solid var(--color-gray-1000);
  border-radius: 12px;
  color: var(--color-white);
  font-size: var(--font-size-base);
  transition: border-color 0.2s ease;
}

.input:focus {
  outline: none;
  border-color: var(--color-primary-700);
}

.error {
  color: var(--color-red-middle);
  font-size: var(--font-size-sm);
  margin-top: 8px;
  padding: 8px;
  background-color: rgba(220, 38, 38, 0.1);
  border-radius: 12px;
}

.submitButton {
  padding: 12px;
  background-color: var(--color-primary-700);
  color: var(--color-white);
  border: none;
  border-radius: 12px;
  font-size: var(--font-size-base);
  font-weight: var(--font-weight-medium);
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.submitButton:hover:not(:disabled) {
  background-color: var(--color-primary-600);
}

.submitButton:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

@media (max-width: 1024px) {
  .main {
    padding: 0 16px;
  }
}

@media (max-width: 768px) {
  .container {
    padding: 0;
  }
  
  .header {
    padding: 12px 16px;
  }
  
  .main {
    padding: 0 12px;
  }
  
  .footer {
    padding: 12px 16px;
  }
  
  .confidentialInfo {
    flex-direction: column;
    gap: 8px;
  }
  
  .separator {
    display: none;
  }
}

@media (max-width: 480px) {
  .authCard {
    padding: 24px 16px;
  }
  
  .slideContainer {
    padding-bottom: 24px;
  }
  
  .footer {
    padding: 12px;
    gap: 12px;
  }
}