/* File: frontend/src/pages/InvestorPitch/components/slides/PartnershipsSlide/MarketPartnership.module.css */

.container {
    background-color: var(--color-gray-1200);
    border-radius: var(--border-radius-lg);
    padding: var(--spacing-xl);
    border: 1px solid var(--color-gray-1100);
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  
  .header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: var(--spacing-lg);
  }
  
  .market {
    font-size: var(--font-size-xl);
    font-weight: var(--font-weight-bold);
    color: var(--text-on-primary);
  }
  
  .status {
    font-size: var(--font-size-sm);
    padding: var(--spacing-xs) var(--spacing-sm);
    border-radius: var(--border-radius-full);
  }
  
  .status.active {
    background-color: var(--color-success-bg);
    color: var(--color-success);
  }
  
  .status.active-in-discussion {
    background-color: var(--color-info-bg);
    color: var(--color-info);
  }
  
  .status.in-discussion {
    background-color: var(--color-warning-bg);
    color: var(--color-warning);
  }
  
  .details {
    flex: 1;
    list-style: none;
    padding: 0;
    margin: 0 0 var(--spacing-lg) 0;
  }
  
  .details li {
    position: relative;
    padding-left: var(--spacing-lg);
    margin-bottom: var(--spacing-sm);
    color: var(--color-gray-400);
  }
  
  .details li::before {
    content: "•";
    position: absolute;
    left: 0;
    color: var(--color-primary-500);
  }
  
  .timeline {
    display: flex;
    align-items: center;
    gap: var(--spacing-sm);
    color: var(--color-gray-400);
    font-size: var(--font-size-sm);
  }
  
  .timelineIcon {
    width: 16px;
    height: 16px;
  }