.card {
    width: 100%;
    position: relative;
    box-shadow: 0px 13px 4px rgba(0, 0, 0, 0), 0px 8px 3px rgba(0, 0, 0, 0), 0px 5px 3px rgba(0, 0, 0, 0.02), 0px 2px 2px rgba(0, 0, 0, 0.03), 0px 1px 1px rgba(0, 0, 0, 0.03);
    border-radius: 16px;
    background-color: #fff;
    border: 2px solid #f7f7f8;
    box-sizing: border-box;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    text-align: left;
    font-size: 18px;
    color: #14151a;
    font-family: 'Instrument Sans';
  }
  
  .button {
    align-self: stretch;
    border: 1px solid #f7f7f8;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 20px;
    gap: 20px;
  }
  
  .avatarUser {
    width: 62px;
    position: relative;
    box-shadow: 0px 4px 10px 7px rgba(255, 255, 255, 0.25) inset, 0px 0px 0px 2px rgba(255, 255, 255, 0.2) inset, 0px 0px 0px 4px rgba(255, 255, 255, 0.15) inset;
    border-radius: 14px;
    background-color: #fff;
    height: 62px;
    overflow: hidden;
    flex-shrink: 0;
  }
  
  .imgIcon {
    position: absolute;
    height: 101.61%;
    width: 101.61%;
    top: 0%;
    right: 0%;
    bottom: -1.61%;
    left: -1.61%;
    max-width: 100%;
    overflow: hidden;
    max-height: 100%;
    object-fit: cover;
  }
  
  .blurredImage {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: #e0e0e0;
    filter: blur(5px);
  }
  
  .icons18Privacy {
    position: absolute;
    top: calc(50% - 12px);
    left: calc(50% - 12px);
    width: 24px;
    height: 24px;
  }
  
  .paragraphContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 4px;
  }
  
  .userName {
    position: relative;
    line-height: 24px;
    font-weight: 500;
  }
  
  .userEmail {
    position: relative;
    font-size: 14px;
    line-height: 14px;
    font-family: Inter;
    color: #9ea2ad;
  }
  
  .cardStandartContent {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 20px;
    box-sizing: border-box;
    font-size: 12px;
    color: #333;
    font-family: Inter;
  }
  
  .cardStandartContentA {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  
  .cardStandartContentA1 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 1px;
  }
  
  .cardStandartContentA2 {
    border-radius: 9px;
    background-color: #f7f7f8;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    padding: 6px 8px 6px 10px;
    gap: 10px;
  }
  
  .text {
    position: relative;
    line-height: 18px;
    font-weight: 500;
  }
  
  .icons18CopyPaste {
    width: 14px;
    position: relative;
    height: 14px;
    overflow: hidden;
    flex-shrink: 0;
    object-fit: cover;
  }