/* File: frontend/src/pages/UMS/UserDetail/components/UserActions/UserActions.module.css */

.container {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-md);
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: var(--spacing-md);
}

.title {
  font-size: var(--font-size-lg);
  font-weight: var(--font-weight-semibold);
  color: var(--text-primary);
  margin: 0;
}

.filters {
  display: flex;
  gap: var(--spacing-md);
  margin-bottom: var(--spacing-md);
  background-color: var(--bg-secondary);
  padding: var(--spacing-md);
  border-radius: var(--border-radius-md);
}

.filterGroup {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.label {
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-medium);
  color: var(--text-secondary);
  margin-bottom: var(--spacing-xxs);
}

.select {
  padding: var(--spacing-sm);
  border: 1px solid var(--color-border);
  border-radius: var(--border-radius-md);
  font-size: var(--font-size-base);
  color: var(--text-primary);
  background-color: var(--bg-primary);
  transition: border-color var(--transition-fast);
}

.select:hover {
  border-color: var(--color-border-hover);
}

.select:focus {
  outline: none;
  box-shadow: var(--input-focus-box-shadow);
  border-color: var(--input-focus-border-color);
}

.searchButton {
  align-self: flex-end;
  padding: var(--button-padding-medium);
  background-color: var(--button-primary-bg);
  color: var(--button-primary-text);
  border: none;
  border-radius: var(--button-border-radius-md);
  font-size: var(--button-font-size-medium);
  font-weight: var(--font-weight-medium);
  cursor: pointer;
  transition: background-color var(--transition-fast);
  min-width: 120px;
}

.searchButton:hover:not(:disabled) {
  background-color: var(--button-primary-hover-bg);
}

.searchButton:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.actionsTable {
  overflow-x: auto;
}

.table {
  width: 100%;
  border-collapse: collapse;
}

.table th, .table td {
  padding: var(--spacing-sm);
  text-align: left;
  border-bottom: 1px solid var(--color-border);
}

.table th {
  background-color: var(--bg-secondary);
  font-weight: var(--font-weight-semibold);
  color: var(--text-secondary);
}

.table tr:hover {
  background-color: var(--bg-hover);
}

.detailsButton {
  padding: var(--button-padding-xs);
  background-color: var(--button-secondary-bg);
  color: var(--button-secondary-text);
  border: 1px solid var(--button-secondary-border);
  border-radius: var(--button-border-radius-sm);
  font-size: var(--button-font-size-xs);
  font-weight: var(--font-weight-medium);
  cursor: pointer;
  transition: background-color var(--transition-fast);
}

.detailsButton:hover {
  background-color: var(--button-secondary-hover-bg);
}

.loading {
  text-align: center;
  padding: var(--spacing-xl);
  color: var(--text-secondary);
}

.noActions {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: var(--spacing-xl);
  text-align: center;
  color: var(--text-secondary);
  background-color: var(--bg-secondary);
  border-radius: var(--border-radius-md);
}

@media (max-width: 768px) {
  .filters {
    flex-direction: column;
    gap: var(--spacing-sm);
  }
  
  .searchButton {
    align-self: stretch;
    margin-top: var(--spacing-sm);
  }
}