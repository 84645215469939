/* File: frontend/src/pages/EmbeddedInsurance/components/ProductBuilder/styles/JurisdictionSelector.module.css */

.jurisdictionSelector {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-lg);
}

.sectionHeader {
  text-align: center;
  margin-bottom: var(--spacing-lg);
}

.sectionTitle {
  font-size: var(--font-size-xl);
  font-weight: var(--font-weight-bold);
  color: var(--text-primary);
  margin-bottom: var(--spacing-sm);
}

.sectionDescription {
  color: var(--text-secondary);
  max-width: 600px;
  margin: 0 auto;
}

.jurisdictionControls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: var(--spacing-lg);
}

.searchContainer {
  position: relative;
  flex: 1;
  max-width: 400px;
}

.searchInput {
  width: 100%;
  padding: var(--spacing-sm) var(--spacing-sm) var(--spacing-sm) var(--spacing-xl);
  border: 1px solid var(--color-border);
  border-radius: var(--border-radius-md);
  font-size: var(--font-size-base);
}

.searchContainer i {
  position: absolute;
  left: var(--spacing-sm);
  top: 50%;
  transform: translateY(-50%);
  color: var(--text-tertiary);
}

.clearSearch {
  position: absolute;
  right: var(--spacing-sm);
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  color: var(--text-tertiary);
  cursor: pointer;
}

.clearSearch:hover {
  color: var(--text-secondary);
}

.viewToggle {
  display: flex;
  border: 1px solid var(--color-border);
  border-radius: var(--border-radius-md);
  overflow: hidden;
}

.viewButton {
  padding: var(--spacing-sm) var(--spacing-md);
  background: var(--bg-primary);
  border: none;
  cursor: pointer;
  color: var(--text-secondary);
  transition: all var(--transition-fast);
}

.viewButton:hover {
  color: var(--text-primary);
}

.activeView {
  background: var(--color-primary);
  color: white !important;
}

.jurisdictionGrid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: var(--spacing-md);
}

.jurisdictionCard {
  display: flex;
  align-items: center;
  padding: var(--spacing-md);
  background-color: var(--bg-primary);
  border: 1px solid var(--color-border);
  border-radius: var(--border-radius-md);
  cursor: pointer;
  transition: all var(--transition-fast);
  position: relative;
}

.jurisdictionCard:hover {
  transform: translateY(-2px);
  box-shadow: var(--shadow-sm);
  border-color: var(--color-primary-light);
}

.selectedCard {
  border-color: var(--color-primary);
  background-color: var(--color-primary-50);
}

.jurisdictionFlag {
  font-size: var(--font-size-xl);
  margin-right: var(--spacing-md);
  min-width: 32px;
  text-align: center;
}

.jurisdictionInfo {
  flex: 1;
}

.jurisdictionName {
  font-size: var(--font-size-md);
  font-weight: var(--font-weight-semibold);
  color: var(--text-primary);
  margin: 0 0 var(--spacing-xxs) 0;
}

.jurisdictionCode {
  font-size: var(--font-size-sm);
  color: var(--text-secondary);
}

.selectedIndicator {
  position: absolute;
  top: var(--spacing-sm);
  right: var(--spacing-sm);
  color: var(--color-primary);
  font-size: var(--font-size-lg);
}

.jurisdictionMap {
  height: 400px;
  background-color: var(--bg-primary);
  border: 1px solid var(--color-border);
  border-radius: var(--border-radius-md);
  overflow: hidden;
}

.mapPlaceholder {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: var(--text-tertiary);
}

.mapPlaceholder i {
  font-size: var(--font-size-3xl);
  margin-bottom: var(--spacing-md);
}

@media (max-width: 768px) {
  .jurisdictionControls {
    flex-direction: column;
    align-items: stretch;
    gap: var(--spacing-md);
  }
  
  .searchContainer {
    max-width: none;
  }
  
  .jurisdictionGrid {
    grid-template-columns: 1fr;
  }
}