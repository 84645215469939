/* File: frontend/src/pages/API/API.module.css */

.container {
  max-width: var(--breakpoint-xl);
  margin: 0 auto;
  padding: clamp(1rem, 5vw, 2rem);
  font-family: var(--font-primary);
  min-height: 120vh;
}

.header {
  text-align: center;
  margin-bottom: var(--spacing-2xl);
  padding-top: var(--spacing-2xl);
}

.titleContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: var(--spacing-md);
  margin-bottom: var(--spacing-md);
}

.title {
  font-size: var(--font-size-4xl);
  font-weight: var(--font-weight-bold);
  color: var(--text-primary);
  margin: 0;
}

.badge {
  padding: 0.25rem 0.75rem;
  border-radius: 9999px;
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-medium);
  text-transform: uppercase;
  letter-spacing: 0.025em;
}

.development {
  background-color: var(--color-warning-light);
  color: var(--color-warning-dark);
}

.staging {
  background-color: var(--color-info-light);
  color: var(--color-info-dark);
}

.production {
  background-color: var(--color-success-light);
  color: var(--color-success-dark);
}

.description {
  font-size: var(--font-size-lg);
  color: var(--text-secondary);
  max-width: 800px;
  margin: 0 auto;
  line-height: var(--line-height-relaxed);
}

.content {
  position: relative;
  margin-left: 320px;
}

.main {
  min-width: 0;
}

.endpoints {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-lg);
  padding-bottom: var(--spacing-2xl);
}

.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 400px;
  gap: var(--spacing-md);
  color: var(--text-secondary);
}

.loadingSpinner {
  width: var(--spacing-md);
  height: var(--spacing-md);
  border: 2px solid var(--color-primary-light);
  border-top: 2px solid var(--color-primary);
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

.error {
  text-align: center;
  color: var(--color-error);
  padding: var(--spacing-2xl);
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@media (max-width: 1024px) {
  .content {
    margin-left: 280px;
  }
  
  .titleContainer {
    flex-direction: column;
    gap: var(--spacing-sm);
  }
}

@media (max-width: 768px) {
  .content {
    margin-left: 0;
  }
  
  .header {
    padding-top: var(--spacing-xl);
  }
  
  .title {
    font-size: var(--font-size-3xl);
  }
}