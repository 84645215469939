/* File: frontend/src/pages/SuperAdmin/components/SubscriptionManagement/SubscriptionDetail/SubscriptionDetail.module.css */

.container {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-md);
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: var(--spacing-md);
}

.headerContent {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xs);
}

.subscriptionTitle {
  font-size: var(--font-size-xl);
  font-weight: var(--font-weight-bold);
  color: var(--text-primary);
  margin: 0;
}

.subscriptionId {
  font-size: var(--font-size-sm);
  color: var(--text-secondary);
  font-family: var(--font-family-mono);
}

.statusContainer {
  display: flex;
  gap: var(--spacing-sm);
  margin-top: var(--spacing-xs);
}

.statusBadge, .tierBadge {
  display: inline-block;
  padding: 4px 8px;
  border-radius: 12px;
  font-size: var(--font-size-xs);
  font-weight: var(--font-weight-medium);
  text-transform: capitalize;
}

.statusActive {
  background-color: var(--color-success-bg);
  color: var(--color-success);
}

.statusExpired {
  background-color: var(--color-error-bg);
  color: var(--color-error);
}

.statusPending {
  background-color: var(--color-warning-bg);
  color: var(--color-warning);
}

.statusPaid {
  background-color: var(--color-success-bg);
  color: var(--color-success);
}

.statusUnpaid {
  background-color: var(--color-error-bg);
  color: var(--color-error);
}

.tierBadge {
  background-color: var(--color-primary-100);
  color: var(--color-primary);
}

.headerActions {
  display: flex;
  gap: var(--spacing-sm);
}

.backButton, .editButton {
  display: flex;
  align-items: center;
  gap: var(--spacing-xs);
  padding: var(--spacing-sm) var(--spacing-md);
  border-radius: var(--border-radius-md);
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-medium);
  cursor: pointer;
  transition: all var(--transition-fast);
}

.backButton {
  background-color: var(--bg-secondary);
  color: var(--text-primary);
  border: 1px solid var(--color-border);
}

.backButton:hover {
  background-color: var(--bg-hover);
}

.editButton {
  background-color: var(--color-primary);
  color: white;
  border: none;
}

.editButton:hover {
  background-color: var(--color-primary-dark);
}

.tabs {
  display: flex;
  border-bottom: 1px solid var(--color-border);
  margin-bottom: var(--spacing-md);
  overflow-x: auto;
}

.tab {
  padding: var(--spacing-sm) var(--spacing-md);
  background: none;
  border: none;
  border-bottom: 2px solid transparent;
  font-size: var(--font-size-base);
  color: var(--text-secondary);
  cursor: pointer;
  transition: all var(--transition-fast);
  white-space: nowrap;
}

.tab:hover {
  color: var(--text-primary);
}

.activeTab {
  color: var(--color-primary);
  border-bottom-color: var(--color-primary);
  font-weight: var(--font-weight-medium);
}

.tabContent {
  background-color: var(--bg-primary);
  border-radius: var(--border-radius-md);
  padding: var(--spacing-md);
}

.section {
  margin-bottom: var(--spacing-lg);
}

.section:last-child {
  margin-bottom: 0;
}

.sectionHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: var(--spacing-md);
}

.sectionTitle {
  font-size: var(--font-size-lg);
  font-weight: var(--font-weight-semibold);
  color: var(--text-primary);
  margin: 0 0 var(--spacing-md) 0;
  padding-bottom: var(--spacing-xs);
  border-bottom: 1px solid var(--color-border-light);
}

.sectionHeader .sectionTitle {
  margin: 0;
  padding: 0;
  border: none;
}

.infoGrid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: var(--spacing-md);
}

.infoItem {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xxs);
}

.infoLabel {
  font-size: var(--font-size-sm);
  color: var(--text-secondary);
  font-weight: var(--font-weight-medium);
}

.infoValue {
  color: var(--text-primary);
  word-break: break-word;
}

.tierCard {
  background-color: var(--bg-secondary);
  border-radius: var(--border-radius-md);
  border: 1px solid var(--color-border);
  overflow: hidden;
}

.tierHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: var(--spacing-md);
  background-color: var(--color-primary-100);
  color: var(--color-primary);
}

.tierName {
  font-size: var(--font-size-lg);
  font-weight: var(--font-weight-bold);
}

.tierPrice {
  font-size: var(--font-size-xl);
  font-weight: var(--font-weight-bold);
}

.billingCycle {
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-normal);
  color: var(--text-secondary);
}

.tierDescription {
  padding: var(--spacing-md);
  border-bottom: 1px solid var(--color-border-light);
}

.tierFeatures {
  padding: var(--spacing-md);
}

.tierFeaturesTitle {
  font-size: var(--font-size-base);
  font-weight: var(--font-weight-semibold);
  margin: 0 0 var(--spacing-sm) 0;
}

.featuresList {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xs);
}

.featureItem {
  display: flex;
  align-items: center;
  gap: var(--spacing-sm);
}

.featureIncluded i {
  color: var(--color-success);
}

.featureExcluded i {
  color: var(--color-error);
}

.productsTable, .billingTable {
  width: 100%;
  border-collapse: collapse;
}

.productsTable th, .productsTable td,
.billingTable th, .billingTable td {
  padding: var(--spacing-sm);
  text-align: left;
  border-bottom: 1px solid var(--color-border-light);
}

.productsTable th, .billingTable th {
  background-color: var(--bg-secondary);
  color: var(--text-secondary);
  font-weight: var(--font-weight-semibold);
  font-size: var(--font-size-sm);
}

.tableActions {
  display: flex;
  gap: var(--spacing-xs);
}

.actionButton {
  display: flex;
  align-items: center;
  gap: var(--spacing-xxs);
  padding: var(--spacing-xs) var(--spacing-sm);
  background-color: var(--bg-secondary);
  border: 1px solid var(--color-border);
  border-radius: var(--border-radius-sm);
  font-size: var(--font-size-xs);
  color: var(--text-primary);
  cursor: pointer;
  transition: all var(--transition-fast);
}

.actionButton:hover {
  background-color: var(--bg-hover);
  color: var(--color-primary);
  border-color: var(--color-primary);
}

.addButton {
  display: flex;
  align-items: center;
  gap: var(--spacing-xs);
  padding: var(--spacing-sm) var(--spacing-md);
  background-color: var(--color-primary);
  color: white;
  border: none;
  border-radius: var(--border-radius-md);
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-medium);
  cursor: pointer;
  transition: background-color var(--transition-fast);
}

.addButton:hover {
  background-color: var(--color-primary-dark);
}

.emptyState {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: var(--spacing-xl);
  background-color: var(--bg-secondary);
  border-radius: var(--border-radius-md);
  color: var(--text-secondary);
  font-style: italic;
}

.loading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: var(--spacing-xl);
  min-height: 300px;
}

.spinner {
  border: 3px solid var(--color-border);
  border-top: 3px solid var(--color-primary);
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 1s linear infinite;
  margin-bottom: var(--spacing-md);
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.error, .notFound {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: var(--spacing-xl);
  background-color: var(--bg-secondary);
  border-radius: var(--border-radius-md);
  min-height: 200px;
}

.error {
  color: var(--color-error);
}

.notFound {
  color: var(--text-secondary);
}

@media (max-width: 992px) {
  .infoGrid {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 768px) {
  .header {
    flex-direction: column;
    gap: var(--spacing-md);
  }
  
  .headerActions {
    width: 100%;
  }
  
  .backButton, .editButton {
    flex: 1;
    justify-content: center;
  }
  
  .tabs {
    flex-wrap: nowrap;
    overflow-x: auto;
  }
  
  .tab {
    padding: var(--spacing-xs) var(--spacing-sm);
  }
  
  .productsTable, .billingTable {
    display: block;
    overflow-x: auto;
  }
}