/* File: /frontend/src/pages/Quote/components/Hero/Hero.module.css */

/* Ensure the hero container doesn't have overflow issues */
.heroContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: var(--spacing-2xl) var(--spacing-xl);
  background-color: var(--color-black);
  color: var(--color-white);
  position: relative;
  /* Change from overflow: visible to overflow: hidden */
  overflow: hidden;
  height: 100vh;
  max-height: 900px;
  min-height: 500px;
  /* Add these properties to help with mobile rendering */
  transform: translateZ(0);
  -webkit-transform: translateZ(0);
  will-change: transform;
}

.disabled {
  opacity: 0.5;
  cursor: not-allowed;
  pointer-events: none;
  box-shadow: none !important;
}

.disabled:hover {
  background-color: var(--color-tertiary);
  transform: none;
  box-shadow: none;
}

.backgroundShapes {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  overflow: hidden;
  pointer-events: none;
  contain: strict;
}

.shape1 {
  position: absolute;
  width: clamp(250px, 40vw, 400px); /* Responsive size */
  height: clamp(250px, 40vw, 400px); /* Responsive size */
  border-radius: 50%;
  background: radial-gradient(circle, rgba(56, 199, 147, 0.15) 0%, rgba(56, 199, 147, 0) 70%);
  top: -100px;
  right: -100px;
  /* animation: float 15s ease-in-out infinite; */
  animation: none !important;
  will-change: transform; /* Performance optimization */
  transform: translate(10px, -10px);
}

.shape2 {
  position: absolute;
  width: clamp(350px, 50vw, 600px); /* Responsive size */
  height: clamp(350px, 50vw, 600px); /* Responsive size */
  border-radius: 50%;
  background: radial-gradient(circle, rgba(35, 72, 237, 0.15) 0%, rgba(35, 72, 237, 0) 70%);
  bottom: -200px;
  left: -200px;
  /* animation: float 20s ease-in-out infinite reverse; */
  animation: none !important;
  will-change: transform; /* Performance optimization */
  transform: translate(10px, -10px);
}

@keyframes float {
  0% {
    transform: translate(0px, 0px);
  }
  50% {
    transform: translate(30px, -30px);
  }
  100% {
    transform: translate(0px, 0px);
  }
}

.heroContent {
  flex: 1;
  max-width: 50%;
  z-index: 2;
  /* padding-right: var(--spacing-md); */
  padding-left: var(--spacing-lg);
}

.title {
  font-size: clamp(var(--font-size-2xl), 4vw, var(--font-size-4xl));
  font-weight: var(--font-weight-bold);
  margin-bottom: var(--spacing-md);
  color: var(--color-white);
  line-height: var(--line-height-tight);
  font-family: var(--font-primary);
}

.highlight {
  color: var(--color-tertiary);
  position: relative;
  display: inline-block; /* Ensures the highlight works properly */
  white-space: nowrap; /* Prevent breaking highlight on small screens */
}

.highlight::after {
  content: '';
  position: absolute;
  bottom: -5px;
  left: 0;
  width: 100%;
  height: 8px;
  background-color: rgba(56, 199, 147, 0.25);
  z-index: -1;
  border-radius: 4px;
}

.subtitle {
  font-size: clamp(var(--font-size-base), 1.5vw, var(--font-size-lg));
  color: rgba(255, 255, 255, 0.8);
  margin-bottom: var(--spacing-lg);
  line-height: var(--line-height-normal);
  max-width: 450px;
  font-family: var(--font-primary);
}

.ctaButton {
  padding: var(--spacing-sm) var(--spacing-lg);
  background-color: var(--color-tertiary);
  color: var(--color-white);
  border: none;
  border-radius: var(--border-radius-full);
  font-size: var(--font-size-base);
  font-weight: var(--font-weight-semibold);
  cursor: pointer;
  transition: all var(--transition-normal);
  box-shadow: 0 4px 20px rgba(56, 199, 147, 0.3);
  display: inline-flex;
  align-items: center;
  font-family: var(--font-primary);
  position: relative; /* For focus styles */
}

.ctaButton:hover {
  background-color: #2d9f75; /* Darker shade of tertiary color */
  transform: translateY(-2px);
  box-shadow: 0 6px 25px rgba(56, 199, 147, 0.4);
}

.ctaButton:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(56, 199, 147, 0.5);
}

.ctaButton:active {
  transform: translateY(1px);
}

.arrow {
  display: inline-block;
  margin-left: var(--spacing-xs);
  transition: transform var(--transition-normal);
}

.ctaButton:hover .arrow {
  transform: translateX(5px);
}

/* Ensure the visual section stays in view */
.visualSection {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  /* Increase height by 15% */
  height: clamp(345px, 40vh, 518px); /* Increased from clamp(300px, 35vh, 450px) */
  /* Increase max-width by 15% */
  max-width: 805px; /* Increased from 700px */
  margin: 0 auto;
  /* Add these properties to help with mobile rendering */
  transform: translateZ(0);
  -webkit-transform: translateZ(0);
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  will-change: transform;
}

.comparisonCanvas {
  width: 100%;
  /* Increase max-height by 15% */
  height: 100%;
  max-height: 460px; /* Increased from 400px */
  /* Add these properties to help with mobile rendering */
  transform: translateZ(0);
  -webkit-transform: translateZ(0);
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  perspective: 1000;
  -webkit-perspective: 1000;
  will-change: transform;
  /* Force hardware acceleration */
  transform: translate3d(0, 0, 0);
}

.savingsBadge {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -110px);
  /* Increase size by 15% */
  width: clamp(80px, 11.5vw, 104px); /* Increased from clamp(70px, 10vw, 90px) */
  height: clamp(80px, 11.5vw, 104px); /* Increased from clamp(70px, 10vw, 90px) */
  background-color: var(--color-tertiary);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 6px 20px rgba(56, 199, 147, 0.4);
  animation: pulse 2s infinite;
  z-index: 3;
}

.badgeContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.savingsText {
  /* Increase font size by 15% */
  font-size: clamp(23px, 3.45vw, 32px); /* Increased from clamp(20px, 3vw, 28px) */
  font-weight: var(--font-weight-bold);
  color: var(--color-white);
  line-height: 1;
}

.savingsLabel {
  /* Increase font size by 15% */
  font-size: clamp(11.5px, 1.38vw, 14px); /* Increased from clamp(10px, 1.2vw, 12px) */
  font-weight: var(--font-weight-semibold);
  color: var(--color-white);
  letter-spacing: 0.5px;
  text-transform: uppercase;
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(56, 199, 147, 0.6);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(56, 199, 147, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(56, 199, 147, 0);
  }
}

/* Tablet responsive adjustments */
@media (min-width: 1024px) {
  .heroContainer {
    padding: var(--spacing-xl) var(--spacing-lg);
  }
  
  .heroContent {
    max-width: 55%;
  }
  
  .visualSection {
    transform: scale(0.95);
  }
}

/* Begin moving chart underneath at tablet breakpoint */
@media (max-width: 1023px) {
  .heroContainer {
    flex-direction: column;
    text-align: center;
    gap: var(--spacing-xl);
  }
  
  .heroContent {
    max-width: 80%;
    padding-right: 0;
    padding-left: 0;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .subtitle {
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
  }
  
  .visualSection {
    width: 100%;
    margin-top: var(--spacing-md);
    transform: scale(1);
  }
}

/* Medium-sized screens adjustments */
@media (max-width: 900px) {
  .savingsBadge {
    transform: translate(-50%, -100px);
  }
}

/* Mobile responsive adjustments */
@media (max-width: 768px) {
  .heroContainer {
    flex-direction: column;
    padding: var(--spacing-lg) var(--spacing-md);
    text-align: center;
    gap: var(--spacing-lg);
  }
  
  .heroContent {
    max-width: 100%;
    padding-right: 0;
    padding-left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: var(--spacing-lg); /* Added top margin to center content better */
  }
  
  .subtitle {
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
  
  .visualSection {
    width: 100%;
    margin-top: var(--spacing-xl); /* Increased space between text and visualization */
    transform: scale(1);
    height: 280px; /* Fixed height for mobile */
  }
  
  .savingsBadge {
    transform: translate(-50%, -120px);
  }
  
  /* Adjust background shapes for mobile */
  .shape1 {
    top: -50px;
    right: -50px;
  }
  
  .shape2 {
    bottom: -100px;
    left: -100px;
  }
}

/* Small mobile responsive adjustments */
@media (max-width: 480px) {
  .heroContainer {
    /* padding: var(--spacing-md) var(--spacing-sm); */
    /* min-height: 580px; */
    min-height: auto;
    height: auto;
    padding-top: var(--spacing-xl);
    padding-bottom: var(--spacing-xl);
    justify-content: center;
  }
  
  .title {
    font-size: var(--font-size-xl);
    margin-top: var(--spacing-md); /* Add space at the top */
  }
  
  .visualSection {
    height: 240px; /* Slightly taller for small screens */
    /* margin-top: var(--spacing-xl); */
  }
  
  .savingsBadge {
    width: 70px;
    height: 70px;
    transform: translate(-50%, -90px);
  }
  
  .savingsText {
    font-size: 20px;
  }
  
  .savingsLabel {
    font-size: 9px;
  }
  
  .ctaButton {
    padding: var(--spacing-xs) var(--spacing-md);
    font-size: var(--font-size-base);
    width: 100%; /* Full-width button on small screens */
    max-width: 280px; /* Prevent excessive stretching */
    justify-content: center;
    margin-top: var(--spacing-md); /* Add more space above the button */
  }
}

/* Add support for ultra-wide screens */
@media (min-width: 1800px) {
  .heroContainer {
    max-width: 1800px;
    margin: 0 auto;
  }
  
  .visualSection {
    max-width: 800px;
  }
}

/* Reduce animations for users who prefer reduced motion */
@media (prefers-reduced-motion: reduce) {
  .shape1,
  .shape2 {
    animation: none;
  }
  
  .savingsBadge {
    animation: none;
  }
  
  .ctaButton:hover {
    transform: none;
  }
  
  .ctaButton:hover .arrow {
    transform: none;
  }
}