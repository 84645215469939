/* File: frontend/src/pages/AIAgents/AIAgents.module.css */

/* Page Layout */
.wrapper {
    width: 100%;
    min-height: 100%;
    background-color: var(--color-gray-1300);
    padding: var(--spacing-2xl) 0;
  }
  
  .container {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 var(--spacing-md);
  }
  
  /* Hero Section */
  .hero {
    width: 100%;
    max-width: 800px;
    margin: 0 auto var(--spacing-3xl);
    padding: var(--spacing-2xl) var(--spacing-md);
    text-align: center;
  }
  
  /* .title {
    font-family: var(--font-primary);
    font-size: var(--font-size-4xl);
    font-weight: var(--font-weight-bold);
    line-height: var(--line-height-tight);
    margin-bottom: var(--spacing-lg);
    background: linear-gradient(135deg, 
      var(--color-blue-310), 
      var(--color-blue-510)
    );
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
  } */
  
  .title {
    font-size: clamp(2rem, 5vw, var(--font-size-6xl));
    margin-bottom: var(--spacing-6xl);
    line-height: 1.2;
    background: linear-gradient(135deg, 
      var(--color-blue-310), 
      var(--color-blue-510)
    );
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
  }

  .subtitle {
    font-family: var(--font-primary);
    font-size: var(--font-size-xl);
    font-weight: var(--font-weight-regular);
    line-height: var(--line-height-relaxed);
    color: var(--color-gray-400);
    max-width: 600px;
    margin: 0 auto var(--spacing-2xl);
  }
  
  /* CTA Button */
  /* .ctaButton {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    min-width: 200px;
    padding: var(--spacing-md) var(--spacing-2xl);
    font-family: var(--font-primary);
    font-size: var(--font-size-lg);
    font-weight: var(--font-weight-semibold);
    color: var(--color-white);
    background: linear-gradient(135deg, 
      var(--color-blue-310), 
      var(--color-blue-510)
    );
    border: none;
    border-radius: var(--border-radius-full);
    cursor: pointer;
    transition: transform var(--transition-normal),
                box-shadow var(--transition-normal);
    box-shadow: 0 4px 20px rgba(55, 93, 251, 0.3);
  } */

  .ctaButton {
    padding: clamp(var(--spacing-md), 2vw, var(--spacing-lg));
    font-size: clamp(var(--font-size-sm), 2vw, var(--font-size-base));
    margin-top: var(--spacing-2xl);
  }
  
  
  .ctaButton:hover {
    transform: translateY(-2px);
    box-shadow: 0 6px 24px rgba(55, 93, 251, 0.4);
  }
  
  .ctaButton:active {
    transform: translateY(0);
  }
  
  /* Content Section */
  .content {
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
    padding: var(--spacing-2xl) var(--spacing-md);
    display: flex;
    flex-direction: column;
    gap: var(--spacing-3xl);
  }
  
  /* Responsive Styles */
  @media (max-width: 1024px) {
    .container {
      padding: 0 var(--spacing-lg);
    }
  
    .title {
      font-size: var(--font-size-6xl);
    }
  
    .subtitle {
      font-size: var(--font-size-xl);
    }
  }
  
  @media (max-width: 768px) {
    .wrapper {
      padding: var(--spacing-xl) 0;
    }
  
    .container {
      padding: 0 var(--spacing-md);
    }
  
    .hero {
      padding: var(--spacing-xl) var(--spacing-md);
      margin-bottom: var(--spacing-2xl);
    }
  
    .title {
      font-size: var(--font-size-5xl);
      margin-bottom: var(--spacing-4xl);
    }
  
    .subtitle {
      font-size: var(--font-size-lg);
      margin-bottom: var(--spacing-2xl);
    }
  
    .ctaButton {
      padding: var(--spacing-sm) var(--spacing-xl);
      font-size: var(--font-size-base);
      min-width: 180px;
    }
  
    .content {
      padding: var(--spacing-xl) var(--spacing-md);
      gap: var(--spacing-2xl);
    }
  }
  
  @media (max-width: 480px) {
    .wrapper {
      padding: var(--spacing-lg) 0;
    }
  
    .container {
      padding: 0 var(--spacing-sm);
    }
  
    .hero {
      padding: var(--spacing-lg) var(--spacing-sm);
    }
  
    .title {
      font-size: var(--font-size-4xl);
    }
  
    .subtitle {
      font-size: var(--font-size-md);
    }
  
    .content {
      padding: var(--spacing-lg) var(--spacing-sm);
      gap: var(--spacing-xl);
    }
  }