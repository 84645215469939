/* File: frontend/src/pages/SuperAdmin/components/SystemSettings/SystemSettings.module.css */

.container {
  display: flex;
  flex-direction: column;
}

.title {
  font-size: var(--font-size-2xl);
  font-weight: var(--font-weight-bold);
  color: var(--text-primary);
  margin: 0 0 var(--spacing-lg) 0;
}

.tabs {
  display: flex;
  border-bottom: 1px solid var(--color-border);
  margin-bottom: var(--spacing-lg);
  overflow-x: auto;
}

.tab {
  display: flex;
  align-items: center;
  gap: var(--spacing-xs);
  padding: var(--spacing-md) var(--spacing-lg);
  background: none;
  border: none;
  border-bottom: 2px solid transparent;
  color: var(--text-secondary);
  font-size: var(--font-size-base);
  font-weight: var(--font-weight-medium);
  cursor: pointer;
  transition: all var(--transition-fast);
  white-space: nowrap;
}

.tab:hover {
  color: var(--text-primary);
}

.activeTab {
  color: var(--color-primary);
  border-bottom-color: var(--color-primary);
}

.tabContent {
  flex: 1;
}

.error, .success {
  padding: var(--spacing-md);
  border-radius: var(--border-radius-md);
  margin-bottom: var(--spacing-md);
  display: flex;
  align-items: center;
  gap: var(--spacing-sm);
}

.error {
  background-color: var(--color-error-bg);
  color: var(--color-error);
}

.success {
  background-color: var(--color-success-bg);
  color: var(--color-success);
}

.loading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: var(--spacing-xl);
  gap: var(--spacing-md);
}

.spinner {
  width: 24px;
  height: 24px;
  border: 2px solid rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  border-top-color: var(--color-primary);
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.emptyState {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: var(--spacing-xl);
  background-color: var(--bg-secondary);
  border-radius: var(--border-radius-md);
  color: var(--text-secondary);
  text-align: center;
  gap: var(--spacing-md);
}

.emptyState i {
  font-size: var(--font-size-3xl);
  opacity: 0.5;
}

.noSelectionState {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: var(--spacing-xl);
  background-color: var(--bg-secondary);
  border-radius: var(--border-radius-md);
  color: var(--text-secondary);
  text-align: center;
  gap: var(--spacing-md);
  height: 100%;
}

.noSelectionState i {
  font-size: var(--font-size-3xl);
  opacity: 0.5;
}

/* Form Styles */
.formGroup {
  margin-bottom: var(--spacing-md);
}

.formLabel {
  display: block;
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-medium);
  color: var(--text-secondary);
  margin-bottom: var(--spacing-xs);
}

.formInput, .formSelect, .formTextarea {
  width: 100%;
  padding: var(--spacing-sm);
  border: 1px solid var(--color-border);
  border-radius: var(--border-radius-md);
  font-size: var(--font-size-base);
  color: var(--text-primary);
  background-color: var(--bg-primary);
  transition: border-color var(--transition-fast), box-shadow var(--transition-fast);
}

.formInput:focus, .formSelect:focus, .formTextarea:focus {
  outline: none;
  border-color: var(--color-primary);
  box-shadow: 0 0 0 2px var(--color-primary-100);
}

.formTextarea {
  resize: vertical;
  min-height: 100px;
}

.formHelp {
  font-size: var(--font-size-xs);
  color: var(--text-tertiary);
  margin-top: var(--spacing-xxs);
}

.required {
  color: var(--color-error);
}

.formActions {
  display: flex;
  justify-content: flex-end;
  gap: var(--spacing-md);
  margin-top: var(--spacing-lg);
}

.cancelButton, .saveButton, .deleteButton, .editButton {
  display: flex;
  align-items: center;
  gap: var(--spacing-xs);
  padding: var(--spacing-sm) var(--spacing-lg);
  border-radius: var(--border-radius-md);
  font-size: var(--font-size-base);
  font-weight: var(--font-weight-medium);
  cursor: pointer;
  transition: all var(--transition-fast);
}

.cancelButton {
  background-color: var(--bg-secondary);
  color: var(--text-primary);
  border: 1px solid var(--color-border);
}

.cancelButton:hover:not(:disabled) {
  background-color: var(--bg-hover);
}

.saveButton {
  background-color: var(--color-primary);
  color: white;
  border: none;
}

.saveButton:hover:not(:disabled) {
  background-color: var(--color-primary-dark);
}

.editButton {
  background-color: var(--color-primary-100);
  color: var(--color-primary);
  border: 1px solid var(--color-primary);
}

.editButton:hover:not(:disabled) {
  background-color: var(--color-primary);
  color: white;
}

.deleteButton {
  background-color: var(--color-error-bg);
  color: var(--color-error);
  border: 1px solid var(--color-error);
}

.deleteButton:hover:not(:disabled) {
  background-color: var(--color-error);
  color: white;
}

.cancelButton:disabled, .saveButton:disabled, .deleteButton:disabled, .editButton:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.formTitle {
  font-size: var(--font-size-lg);
  font-weight: var(--font-weight-semibold);
  color: var(--text-primary);
  margin: 0 0 var(--spacing-lg) 0;
  padding-bottom: var(--spacing-sm);
  border-bottom: 1px solid var(--color-border-light);
}

.checkboxGroup {
  display: flex;
  flex-wrap: wrap;
  gap: var(--spacing-md);
}

.checkboxLabel {
  display: flex;
  align-items: center;
  gap: var(--spacing-xs);
  cursor: pointer;
}

.checkbox {
  width: 16px;
  height: 16px;
}

/* Language Management Styles */
.languageTab {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-md);
}

.languageHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: var(--spacing-md);
}

.searchContainer {
  position: relative;
  width: 300px;
}

.searchInput {
  width: 100%;
  padding: var(--spacing-sm) var(--spacing-sm) var(--spacing-sm) var(--spacing-xl);
  border: 1px solid var(--color-border);
  border-radius: var(--border-radius-md);
  font-size: var(--font-size-base);
  color: var(--text-primary);
  background-color: var(--bg-primary);
}

.searchContainer i {
  position: absolute;
  left: var(--spacing-sm);
  top: 50%;
  transform: translateY(-50%);
  color: var(--text-tertiary);
}

.addButton {
  display: flex;
  align-items: center;
  gap: var(--spacing-xs);
  padding: var(--spacing-sm) var(--spacing-md);
  background-color: var(--color-primary);
  color: white;
  border: none;
  border-radius: var(--border-radius-md);
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-medium);
  cursor: pointer;
  transition: background-color var(--transition-fast);
}

.addButton:hover:not(:disabled) {
  background-color: var(--color-primary-dark);
}

.addButton:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.languageContent {
  display: flex;
  gap: var(--spacing-lg);
  height: 600px;
}

.languageList {
  flex: 1;
  border: 1px solid var(--color-border);
  border-radius: var(--border-radius-md);
  overflow: hidden;
}

.languageTable {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.languageTableHeader {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  background-color: var(--bg-secondary);
  border-bottom: 1px solid var(--color-border);
  font-weight: var(--font-weight-semibold);
}

.languageTableCell {
  padding: var(--spacing-sm);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.languageTableBody {
  flex: 1;
  overflow-y: auto;
}

.languageTableRow {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  border-bottom: 1px solid var(--color-border-light);
  cursor: pointer;
  transition: background-color var(--transition-fast);
}

.languageTableRow:hover {
  background-color: var(--bg-hover);
}

.selectedRow {
  background-color: var(--color-primary-50) !important;
}

.languageDetail {
  width: 400px;
  border: 1px solid var(--color-border);
  border-radius: var(--border-radius-md);
  overflow: hidden;
}

.languageForm {
  padding: var(--spacing-md);
  height: 100%;
  overflow-y: auto;
}

.languageDetails {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.detailsHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: var(--spacing-md);
  border-bottom: 1px solid var(--color-border);
  background-color: var(--bg-secondary);
}

.detailsTitle {
  font-size: var(--font-size-lg);
  font-weight: var(--font-weight-semibold);
  color: var(--text-primary);
  margin: 0;
}

.detailsActions {
  display: flex;
  gap: var(--spacing-xs);
}

.detailsContent {
  padding: var(--spacing-md);
  flex: 1;
  overflow-y: auto;
}

.detailsSection {
  margin-bottom: var(--spacing-lg);
}

.detailsSection:last-child {
  margin-bottom: 0;
}

.detailsSectionTitle {
  font-size: var(--font-size-base);
  font-weight: var(--font-weight-semibold);
  color: var(--text-primary);
  margin: 0 0 var(--spacing-md) 0;
  padding-bottom: var(--spacing-xs);
  border-bottom: 1px solid var(--color-border-light);
}

.translationsList {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-md);
}

.translationItem {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xs);
  padding: var(--spacing-sm);
  background-color: var(--bg-secondary);
  border-radius: var(--border-radius-md);
  border: 1px solid var(--color-border-light);
}

.translationLanguage {
  display: flex;
  align-items: center;
  gap: var(--spacing-sm);
}

.languageCode {
  font-family: var(--font-family-mono);
  font-size: var(--font-size-xs);
  color: var(--text-tertiary);
  background-color: var(--bg-primary);
  padding: 2px 4px;
  border-radius: 4px;
}

.languageName {
  font-weight: var(--font-weight-medium);
  color: var(--text-secondary);
}

.translationValue {
  font-size: var(--font-size-base);
  color: var(--text-primary);
  word-break: break-word;
}

.pagesList {
  display: flex;
  flex-wrap: wrap;
  gap: var(--spacing-xs);
}

.pageTag {
  display: inline-block;
  padding: 2px 8px;
  background-color: var(--color-primary-100);
  color: var(--color-primary);
  border-radius: 12px;
  font-size: var(--font-size-xs);
  font-weight: var(--font-weight-medium);
}

.metadataGrid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: var(--spacing-md);
}

.metadataItem {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xxs);
}

.metadataLabel {
  font-size: var(--font-size-sm);
  color: var(--text-secondary);
  font-weight: var(--font-weight-medium);
}

.metadataValue {
  color: var(--text-primary);
}

/* Category Management Styles */
.categoriesTab {
  display: flex;
  flex-direction: column;
}

.categoriesContent {
  display: flex;
  gap: var(--spacing-lg);
  height: 600px;
}

.categoriesSidebar {
  width: 250px;
  border: 1px solid var(--color-border);
  border-radius: var(--border-radius-md);
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.sidebarHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: var(--spacing-md);
  background-color: var(--bg-secondary);
  border-bottom: 1px solid var(--color-border);
}

.sidebarTitle {
  font-size: var(--font-size-base);
  font-weight: var(--font-weight-semibold);
  color: var(--text-primary);
  margin: 0;
}

.addCategoryButton {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 28px;
  background-color: var(--color-primary);
  color: white;
  border: none;
  border-radius: var(--border-radius-sm);
  font-size: var(--font-size-sm);
  cursor: pointer;
  transition: background-color var(--transition-fast);
}

.addCategoryButton:hover:not(:disabled) {
  background-color: var(--color-primary-dark);
}

.addCategoryButton:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.categoryTypesList {
  flex: 1;
  overflow-y: auto;
}

.categoryTypeItem {
  padding: var(--spacing-md);
  border-bottom: 1px solid var(--color-border-light);
  cursor: pointer;
  transition: background-color var(--transition-fast);
}

.categoryTypeItem:hover {
  background-color: var(--bg-hover);
}

.selectedCategoryType {
  background-color: var(--color-primary-50) !important;
}

.categoryTypeName {
  font-weight: var(--font-weight-medium);
  color: var(--text-primary);
}

.categoriesMain {
  flex: 1;
  border: 1px solid var(--color-border);
  border-radius: var(--border-radius-md);
  overflow: hidden;
}

.categoryTypeForm, .categoryValueForm {
  padding: var(--spacing-md);
  height: 100%;
  overflow-y: auto;
}

.categoryTypeDetails {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.categoryTypeHeader {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: var(--spacing-md);
  border-bottom: 1px solid var(--color-border);
  background-color: var(--bg-secondary);
}

.categoryTypeInfo {
  flex: 1;
}

.categoryTypeName {
  font-size: var(--font-size-lg);
  font-weight: var(--font-weight-semibold);
  color: var(--text-primary);
  margin: 0 0 var(--spacing-xs) 0;
}

.categoryTypeDescription {
  font-size: var(--font-size-sm);
  color: var(--text-secondary);
  margin: 0;
}

.categoryTypeActions {
  display: flex;
  gap: var(--spacing-xs);
}

.categoryValuesSection {
  padding: var(--spacing-md);
  flex: 1;
  overflow-y: auto;
}

.categoryValuesHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: var(--spacing-md);
}

.categoryValuesSectionTitle {
  font-size: var(--font-size-base);
  font-weight: var(--font-weight-semibold);
  color: var(--text-primary);
  margin: 0;
}

.addValueButton {
  display: flex;
  align-items: center;
  gap: var(--spacing-xs);
  padding: var(--spacing-xs) var(--spacing-sm);
  background-color: var(--color-primary);
  color: white;
  border: none;
  border-radius: var(--border-radius-sm);
  font-size: var(--font-size-xs);
  cursor: pointer;
  transition: background-color var(--transition-fast);
}

.addValueButton:hover:not(:disabled) {
  background-color: var(--color-primary-dark);
}

.addValueButton:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.categoryValuesTable {
  border: 1px solid var(--color-border);
  border-radius: var(--border-radius-md);
  overflow: hidden;
}

.categoryValuesTableHeader {
  display: grid;
  grid-template-columns: 1fr 100px 2fr 100px;
  background-color: var(--bg-secondary);
  border-bottom: 1px solid var(--color-border);
  font-weight: var(--font-weight-semibold);
}

.categoryValuesTableCell {
  padding: var(--spacing-sm);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.categoryValuesTableBody {
  max-height: 400px;
  overflow-y: auto;
}

.categoryValuesTableRow {
  display: grid;
  grid-template-columns: 1fr 100px 2fr 100px;
  border-bottom: 1px solid var(--color-border-light);
}

.categoryValuesTableRow:last-child {
  border-bottom: none;
}

.statusBadge {
  display: inline-block;
  padding: 2px 8px;
  border-radius: 12px;
  font-size: var(--font-size-xs);
  font-weight: var(--font-weight-medium);
}

.statusActive {
  background-color: var(--color-success-bg);
  color: var(--color-success);
}

.statusInactive {
  background-color: var(--color-error-bg);
  color: var(--color-error);
}

.categoryValueActions {
  display: flex;
  gap: var(--spacing-xs);
}

.editValueButton, .deleteValueButton {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: var(--border-radius-sm);
  font-size: var(--font-size-xs);
  cursor: pointer;
  transition: all var(--transition-fast);
}

.editValueButton {
  background-color: var(--color-primary-100);
  color: var(--color-primary);
  border: 1px solid var(--color-primary);
}

.editValueButton:hover:not(:disabled) {
  background-color: var(--color-primary);
  color: white;
}

.deleteValueButton {
  background-color: var(--color-error-bg);
  color: var(--color-error);
  border: 1px solid var(--color-error);
}

.deleteValueButton:hover:not(:disabled) {
  background-color: var(--color-error);
  color: white;
}

.editValueButton:disabled, .deleteValueButton:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

/* Geography Management Styles */
.geographyTab {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-md);
}

.geographyHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: var(--spacing-md);
}

.geographyFilters {
  display: flex;
  gap: var(--spacing-md);
  align-items: center;
}

.filterContainer {
  display: flex;
  align-items: center;
  gap: var(--spacing-xs);
}

.filterLabel {
  font-size: var(--font-size-sm);
  color: var(--text-secondary);
}

.filterSelect {
  padding: var(--spacing-xs) var(--spacing-sm);
  border: 1px solid var(--color-border);
  border-radius: var(--border-radius-sm);
  background-color: var(--bg-primary);
  color: var(--text-primary);
  font-size: var(--font-size-sm);
}

.geographyActions {
  display: flex;
  gap: var(--spacing-md);
}

.uploadButton {
  display: flex;
  align-items: center;
  gap: var(--spacing-xs);
  padding: var(--spacing-sm) var(--spacing-md);
  background-color: var(--bg-secondary);
  color: var(--text-primary);
  border: 1px solid var(--color-border);
  border-radius: var(--border-radius-md);
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-medium);
  cursor: pointer;
  transition: all var(--transition-fast);
}

.uploadButton:hover:not(:disabled) {
  background-color: var(--bg-hover);
}

.uploadButton:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.fileInput {
  display: none;
}

.fileUploadBar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: var(--spacing-sm) var(--spacing-md);
  background-color: var(--bg-secondary);
  border: 1px solid var(--color-border);
  border-radius: var(--border-radius-md);
  margin-bottom: var(--spacing-md);
}

.fileInfo {
  display: flex;
  align-items: center;
  gap: var(--spacing-sm);
}

.fileName {
  font-weight: var(--font-weight-medium);
  color: var(--text-primary);
}

.fileSize {
  font-size: var(--font-size-sm);
  color: var(--text-secondary);
}

.fileActions {
  display: flex;
  gap: var(--spacing-xs);
}

.uploadFileButton {
  display: flex;
  align-items: center;
  gap: var(--spacing-xs);
  padding: var(--spacing-xs) var(--spacing-sm);
  background-color: var(--color-primary);
  color: white;
  border: none;
  border-radius: var(--border-radius-sm);
  font-size: var(--font-size-xs);
  cursor: pointer;
  transition: background-color var(--transition-fast);
}

.uploadFileButton:hover:not(:disabled) {
  background-color: var(--color-primary-dark);
}

.uploadFileButton:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.cancelUploadButton {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  background-color: var(--bg-primary);
  color: var(--text-secondary);
  border: 1px solid var(--color-border);
  border-radius: var(--border-radius-sm);
  font-size: var(--font-size-xs);
  cursor: pointer;
  transition: all var(--transition-fast);
}

.cancelUploadButton:hover:not(:disabled) {
  background-color: var(--bg-hover);
  color: var(--color-error);
  border-color: var(--color-error);
}

.cancelUploadButton:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.geographyContent {
  display: flex;
  gap: var(--spacing-lg);
  height: 600px;
}

.geographyList {
  flex: 1;
  border: 1px solid var(--color-border);
  border-radius: var(--border-radius-md);
  overflow: hidden;
}

.geoTable {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.geoTableHeader {
  display: grid;
  grid-template-columns: 2fr 1fr 1fr 2fr;
  background-color: var(--bg-secondary);
  border-bottom: 1px solid var(--color-border);
  font-weight: var(--font-weight-semibold);
}

.geoTableCell {
  padding: var(--spacing-sm);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.geoTableBody {
  flex: 1;
  overflow-y: auto;
}

.geoTableRow {
  display: grid;
  grid-template-columns: 2fr 1fr 1fr 2fr;
  border-bottom: 1px solid var(--color-border-light);
  cursor: pointer;
  transition: background-color var(--transition-fast);
}

.geoTableRow:hover {
  background-color: var(--bg-hover);
}

.geoTypeBadge {
  display: inline-block;
  padding: 2px 8px;
  background-color: var(--color-primary-100);
  color: var(--color-primary);
  border-radius: 12px;
  font-size: var(--font-size-xs);
  font-weight: var(--font-weight-medium);
  text-transform: capitalize;
}

.geographyDetail {
  width: 400px;
  border: 1px solid var(--color-border);
  border-radius: var(--border-radius-md);
  overflow: hidden;
}

.geoForm {
  padding: var(--spacing-md);
  height: 100%;
  overflow-y: auto;
}

.geoDetails {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.geoDetailsHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: var(--spacing-md);
  border-bottom: 1px solid var(--color-border);
  background-color: var(--bg-secondary);
}

.geoDetailsTitle {
  display: flex;
  align-items: center;
  gap: var(--spacing-sm);
}

.geoName {
  font-size: var(--font-size-lg);
  font-weight: var(--font-weight-semibold);
  color: var(--text-primary);
  margin: 0;
}

.geoDetailsActions {
  display: flex;
  gap: var(--spacing-xs);
}

.geoDetailsContent {
  padding: var(--spacing-md);
  flex: 1;
  overflow-y: auto;
}

.geoDetailsSection {
  margin-bottom: var(--spacing-lg);
}

.geoDetailsSection:last-child {
  margin-bottom: 0;
}

.geoDetailsSectionTitle {
  font-size: var(--font-size-base);
  font-weight: var(--font-weight-semibold);
  color: var(--text-primary);
  margin: 0 0 var(--spacing-md) 0;
  padding-bottom: var(--spacing-xs);
  border-bottom: 1px solid var(--color-border-light);
}

.geoDetailsGrid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: var(--spacing-md);
}

.geoDetailsItem {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xxs);
}

.geoDetailsLabel {
  font-size: var(--font-size-sm);
  color: var(--text-secondary);
  font-weight: var(--font-weight-medium);
}

.geoDetailsValue {
  color: var(--text-primary);
  word-break: break-word;
}

.propertiesForm {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-sm);
  padding: var(--spacing-sm);
  background-color: var(--bg-secondary);
  border-radius: var(--border-radius-md);
  border: 1px solid var(--color-border-light);
}

.propertyRow {
  display: flex;
  align-items: center;
}

.propertyLabel {
  width: 100px;
  font-size: var(--font-size-sm);
  color: var(--text-secondary);
}

.propertyInput {
  flex: 1;
  padding: var(--spacing-xs) var(--spacing-sm);
  border: 1px solid var(--color-border);
  border-radius: var(--border-radius-sm);
  font-size: var(--font-size-sm);
  color: var(--text-primary);
  background-color: var(--bg-primary);
}

@media (max-width: 1200px) {
  .languageContent, .categoriesContent, .geographyContent {
    flex-direction: column;
    height: auto;
  }
  
  .languageDetail, .geographyDetail {
    width: 100%;
    height: 500px;
  }
  
  .categoriesSidebar {
    width: 100%;
    height: 200px;
  }
  
  .categoryTypesList {
    display: flex;
    flex-wrap: wrap;
    gap: var(--spacing-xs);
    padding: var(--spacing-sm);
  }
  
  .categoryTypeItem {
    padding: var(--spacing-xs) var(--spacing-sm);
    border: 1px solid var(--color-border);
    border-radius: var(--border-radius-sm);
  }
}

@media (max-width: 768px) {
  .tabs {
    flex-wrap: nowrap;
    overflow-x: auto;
  }
  
  .tab {
    padding: var(--spacing-sm) var(--spacing-md);
  }
  
  .languageHeader, .geographyHeader {
    flex-direction: column;
    gap: var(--spacing-sm);
    align-items: stretch;
  }
  
  .searchContainer {
    width: 100%;
  }
  
  .geographyFilters {
    flex-direction: column;
    align-items: stretch;
  }
  
  .geographyActions {
    flex-direction: column;
    gap: var(--spacing-sm);
  }
  
  .uploadButton, .addButton {
    width: 100%;
    justify-content: center;
  }
  
  .formActions {
    flex-direction: column;
  }
  
  .cancelButton, .saveButton {
    width: 100%;
  }
  
  .geoTableHeader, .geoTableRow {
    grid-template-columns: 2fr 1fr 1fr;
  }
  
  .geoTableHeader div:nth-child(4), .geoTableRow div:nth-child(4) {
    display: none;
  }
  
  .geoDetailsGrid {
    grid-template-columns: 1fr;
  }
}