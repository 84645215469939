/* File: frontend/src/components/LoadingSpinner/LoadingSpinner.module.css */

.spinner {
  display: inline-block;
  position: relative;
}

.spinnerInner {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 2px solid transparent;
  animation: spin 1s cubic-bezier(0.42, 0, 0.58, 1) infinite;
}

/* Sizes */
.small {
  width: 16px;
  height: 16px;
}

.small .spinnerInner {
  border-width: 2px;
}

.medium {
  width: 24px;
  height: 24px;
}

.medium .spinnerInner {
  border-width: 3px;
}

.large {
  width: 32px;
  height: 32px;
}

.large .spinnerInner {
  border-width: 4px;
}

/* Colors */
.primary .spinnerInner {
  border-top-color: var(--color-primary);
  border-right-color: var(--color-primary-300);
  border-bottom-color: var(--color-primary-200);
  border-left-color: var(--color-primary-100);
}

.secondary .spinnerInner {
  border-top-color: var(--color-secondary);
  border-right-color: var(--color-orange-300);
  border-bottom-color: var(--color-orange-210);
  border-left-color: var(--color-orange-110);
}

.white .spinnerInner {
  border-top-color: var(--color-gray-0);
  border-right-color: rgba(255, 255, 255, 0.7);
  border-bottom-color: rgba(255, 255, 255, 0.4);
  border-left-color: rgba(255, 255, 255, 0.1);
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.visuallyHidden {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}