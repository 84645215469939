/* File: frontend/src/pages/AllegoryApp/AllegoryApp.module.css */

.container {
    position: relative;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    background-color: var(--color-primary);
  }
  
  .contentOverlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.7);
    opacity: 0;
    transition: opacity 1s ease-out;
  }
  
  .contentOverlay.visible {
    opacity: 1;
  }
  
  .content {
    max-width: 600px;
    padding: var(--spacing-xl);
    margin-left: 10%;
  }
  
  .title {
    font-size: var(--font-size-5xl);
    font-weight: var(--font-weight-semibold);
    margin-bottom: var(--spacing-lg);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  
  .highlight {
    color: var(--color-gray-1300);
    line-height: 1.2;
    opacity: 0;
    transform: translateY(20px);
  }
  
  .animateTitle {
    animation: fadeInUp 0.5s ease-out forwards;
  }
  
  .animateTitle:nth-child(2) {
    animation-delay: 0.3s;
  }
  
  @keyframes fadeInUp {
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  .description {
    font-size: var(--font-size-lg);
    margin-bottom: var(--spacing-xl);
    color: var(--color-gray-1300);
  }
  
  @media (max-width: 768px) {
    .content {
      margin-left: 5%;
    }
  
    .title {
      font-size: var(--font-size-5xl);
    }
  
    .description {
        font-size: var(--font-size-lg);
    }
  }