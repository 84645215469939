/* File: frontend/src/components/layout/DashboardLayout/PageTitle.module.css */

.pageTitleContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 24px;
}

.titleWrapper {
  display: flex;
  align-items: center;
}

.title {
  font-size: 24px;
  font-weight: 500;
  color: #14151a;
  margin: 0;
}

.rightSection {
  display: flex;
  align-items: center;
  gap: 16px;
}

@media (max-width: 768px) {
  .pageTitleContainer {
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
  }
  
  .rightSection {
    width: 100%;
  }
}