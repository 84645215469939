.container {
    height: 100vh;
    display: flex;
    flex-direction: column;
    background-color: #14151A;
    background-color: var(--background-primary);
  }
  
  .header {
    text-align: center;
    margin-bottom: var(--spacing-xl);
  }
  
  .title {
    font-size: var(--font-size-4xl);
    font-weight: var(--font-weight-bold);
    color: var(--text-on-primary);
    margin-bottom: var(--spacing-xs);
  }
  
  .subtitle {
    font-size: var(--font-size-xl);
    color: var(--color-gray-400);
    margin-bottom: var(--spacing-md);
  }
  
  .highlight {
    font-size: var(--font-size-2xl);
    font-weight: var(--font-weight-semibold);
    color: var(--color-success);
    margin-bottom: var(--spacing-lg);
  }
  
  .chartWrapper {
    flex-grow: 1;
    position: relative;
    margin-top: -40px;
  }
  
  .tooltip {
    background: var(--color-gray-1200);
    border: 1px solid var(--color-gray-1000);
    border-radius: var(--border-radius-md);
    padding: var(--spacing-sm);
    box-shadow: var(--shadow-md);
  }
  
  .tooltipLabel {
    font-size: 12px;
    font-weight: var(--font-weight-medium);
    color: var(--color-gray-300);
    margin-bottom: var(--spacing-xxs);
  }
  
  .tooltipValue {
    font-size: 14px;
    font-weight: var(--font-weight-semibold);
    color: var(--text-on-primary);
    margin-bottom: var(--spacing-xxs);
  }
  
  .tooltipPercentage {
    font-size: 12px;
    color: var(--color-gray-400);
  }
  
  .summary {
    text-align: center;
    margin-top: var(--spacing-xl);
  }
  
  .savingsMessage {
    font-size: var(--font-size-xl);
    color: var(--color-success);
    font-weight: var(--font-weight-semibold);
  }
  
  .savingsPercentage {
    margin-left: var(--spacing-sm);
    color: var(--color-gray-400);
    font-weight: var(--font-weight-regular);
  }
  
  :global(.recharts-cartesian-grid-horizontal) line,
  :global(.recharts-cartesian-grid-vertical) line {
    stroke: rgba(255, 255, 255, 0.1);
  }
  
  :global(.recharts-legend-item-text) {
    color: var(--color-gray-300) !important;
    font-size: 12px;
  }