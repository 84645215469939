/* File: frontend/src/pages/EmbeddedInsurance/components/ApiExplorer/styles/ApiParameters.module.css */

.section {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-lg);
}

.sectionTitle {
  font-size: var(--font-size-md);
  font-weight: var(--font-weight-semibold);
  color: var(--text-primary);
  margin: 0;
  padding-bottom: var(--spacing-xs);
  border-bottom: 1px solid var(--color-border-light);
}

.parameterGroup {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-md);
}

.groupTitle {
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-medium);
  color: var(--text-secondary);
  margin: 0;
}

.parameters {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-md);
}

.parameter {
  padding: var(--spacing-md);
  background-color: var(--bg-secondary);
  border-radius: var(--border-radius-md);
  border: 1px solid var(--color-border);
}

.parameterHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: var(--spacing-sm);
}

.parameterName {
  display: flex;
  align-items: center;
  gap: var(--spacing-xs);
}

.parameterName code {
  font-family: var(--font-secondary);
  font-size: var(--font-size-sm);
  color: var(--text-primary);
  font-weight: var(--font-weight-medium);
}

.requiredBadge {
  font-size: var(--font-size-xs);
  padding: 2px var(--spacing-xs);
  background-color: var(--color-error-bg);
  color: var(--color-error);
  border-radius: var(--border-radius-sm);
  font-weight: var(--font-weight-medium);
}

.parameterType {
  font-size: var(--font-size-xs);
  padding: 2px var(--spacing-xs);
  background-color: var(--color-primary-bg);
  color: var(--color-primary);
  border-radius: var(--border-radius-sm);
  font-weight: var(--font-weight-medium);
}

.parameterDescription {
  font-size: var(--font-size-sm);
  color: var(--text-secondary);
  line-height: var(--line-height-relaxed);
  margin: 0 0 var(--spacing-sm) 0;
}

.enumValues {
  margin-top: var(--spacing-sm);
}

.enumLabel {
  font-size: var(--font-size-xs);
  color: var(--text-secondary);
  margin-right: var(--spacing-xs);
}

.enumList {
  display: flex;
  flex-wrap: wrap;
  gap: var(--spacing-xs);
  margin-top: var(--spacing-xxs);
}

.enumValue {
  font-family: var(--font-secondary);
  font-size: var(--font-size-xs);
  padding: 2px var(--spacing-xs);
  background-color: var(--bg-tertiary);
  border-radius: var(--border-radius-sm);
  color: var(--text-secondary);
}

@media (max-width: 768px) {
  .parameterHeader {
    flex-direction: column;
    align-items: flex-start;
    gap: var(--spacing-xs);
  }
  
  .parameter {
    padding: var(--spacing-sm);
  }
}