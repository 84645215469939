/* File: frontend/src/pages/Auth/SignUp.module.css */

.signUpPage {
  display: flex;
  width: 100%;
  height: 100%;
  /* min-height: calc(100vh - var(--header-height)); */
  border-radius: var(--border-radius-lg);
  position: relative;
  gap: var(--spacing-xl);
  z-index: var(--z-index-fixed);
}

.stepIndicatorWrapper {
  width: 320px;
  flex-shrink: 0;
  padding: var(--spacing-md);
  background-color: var(--bg-secondary);
  border-radius: var(--border-radius-xl);
  border: 1px solid var(--color-border);
}

.formWrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: var(--spacing-md);
  position: relative;
  z-index: var(--z-index-dropdown);
  background-color: var(--bg-primary);
}

.formSection {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: var(--spacing-md);
  overflow-y: auto;
  background-color: var(--bg-primary);
  min-width: 320px;
}

/* Large screens (1200px and up) */
@media (min-width: 1200px) {
  .signUpPage {
    max-width: var(--breakpoint-xl);
    margin: 0 auto;
  }
}

/* Medium-large screens (1024px and down) */
@media (max-width: 1024px) {
  .signUpPage {
    flex-direction: column;
    gap: var(--spacing-md);
  }

  .stepIndicatorWrapper {
    width: 100%;
    border-right: none;
    border-bottom: 1px solid var(--color-border);
    padding: var(--spacing-sm) var(--spacing-md);
  }

  .formWrapper {
    padding: var(--spacing-md);
  }
}

/* Tablet screens (768px and down) */
@media (max-width: 768px) {
  .signUpPage {
    gap: var(--spacing-sm);
    min-height: calc(100vh - var(--header-height) - var(--spacing-xl));
  }

  .stepIndicatorWrapper {
    padding: var(--spacing-sm);
  }

  .formWrapper {
    padding: var(--spacing-sm);
  }

  .formSection {
    padding: var(--spacing-sm);
    min-width: auto;
  }
}

/* Mobile screens (480px and down) */
@media (max-width: 480px) {
  .signUpPage {
    gap: var(--spacing-xs);
  }

  .stepIndicatorWrapper {
    padding: var(--spacing-xs);
  }

  .formWrapper {
    padding: var(--spacing-xs) var(--spacing-sm);
  }

  .formSection {
    padding: var(--spacing-xs);
  }
}

/* Small mobile screens (360px and down) */
@media (max-width: 360px) {
  .signUpPage {
    gap: var(--spacing-xxxs);
  }

  .formWrapper,
  .stepIndicatorWrapper,
  .formSection {
    padding: var(--spacing-xxxs) var(--spacing-xxs);
  }
}