/* File: frontend/src/pages/PropertyDetail/components/PropertyOccupancy/PropertyOccupancy.module.css */

.container {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-md);
}

.grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: var(--spacing-md);
}

.card {
  background-color: var(--bg-primary);
  border-radius: var(--border-radius-md);
  border: 1px solid var(--color-border);
  overflow: hidden;
}

.cardTitle {
  font-size: var(--font-size-md);
  font-weight: var(--font-weight-semibold);
  padding: var(--spacing-sm);
  background-color: var(--bg-secondary);
  border-bottom: 1px solid var(--color-border);
  margin: 0;
}

.cardContent {
  padding: var(--spacing-sm);
}

.occupancyInfo, .unitInfo, .businessInfo {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xs);
}

.infoGroup {
  display: flex;
  margin-bottom: var(--spacing-xs);
}

.infoGroup:last-child {
  margin-bottom: 0;
}

.infoLabel {
  font-weight: var(--font-weight-medium);
  color: var(--text-secondary);
  width: 180px;
  flex-shrink: 0;
}

.infoValue {
  color: var(--text-primary);
  word-break: break-word;
}

.occupantsGrid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: var(--spacing-md);
  margin-bottom: var(--spacing-md);
}

.occupantItem {
  padding: var(--spacing-sm);
  border-radius: var(--border-radius-sm);
  border: 1px solid var(--color-border);
  background-color: var(--bg-secondary);
}

.occupantHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: var(--spacing-xs);
}

.occupantName {
  font-weight: var(--font-weight-semibold);
  font-size: var(--font-size-base);
}

.primaryBadge {
  background-color: var(--color-success-bg);
  color: var(--color-success);
  padding: 2px 8px;
  border-radius: 4px;
  font-size: var(--font-size-xs);
  font-weight: var(--font-weight-medium);
}

.occupantDetails {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xxs);
}

.occupantDetail {
  display: flex;
  justify-content: space-between;
  font-size: var(--font-size-sm);
}

.occupantDetailLabel {
  color: var(--text-secondary);
}

.occupantDetailValue {
  color: var(--text-primary);
  font-weight: var(--font-weight-medium);
}

.addOccupantContainer {
  display: flex;
  justify-content: center;
}

.addOccupantButton {
  padding: var(--button-padding-small);
  background-color: var(--button-primary-bg);
  color: var(--button-primary-text);
  border: none;
  border-radius: var(--button-border-radius-md);
  font-size: var(--button-font-size-small);
  font-weight: var(--font-weight-medium);
  cursor: pointer;
  transition: background-color var(--transition-fast);
}

.addOccupantButton:hover {
  background-color: var(--button-primary-hover-bg);
}

.noData {
  color: var(--text-secondary);
  font-style: italic;
  text-align: center;
  padding: var(--spacing-md);
}

@media (max-width: 768px) {
  .grid {
    grid-template-columns: 1fr;
  }
  
  .occupantsGrid {
    grid-template-columns: 1fr;
  }
  
  .infoGroup {
    flex-direction: column;
  }
  
  .infoLabel {
    width: 100%;
    margin-bottom: var(--spacing-xxxs);
  }
}