/* File: frontend/src/pages/InvestorPitch/components/slides/ProductEvolutionSlide/FeatureTimeline.module.css */

.container {
  background-color: var(--color-gray-1200);
  border-radius: var(--border-radius-lg);
  padding: var(--spacing-md);
  border: 1px solid var(--color-gray-1100);
  display: flex;
  flex-direction: column;
  gap: var(--spacing-lg);
  height: 100%;
}

.feature {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-md);
}

.header {
  display: flex;
  align-items: center;
  gap: var(--spacing-md);
}

.iconWrapper {
  width: 40px;
  height: 40px;
  border-radius: var(--border-radius-lg);
  background-color: var(--color-gray-1100);
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon {
  width: 20px;
  height: 20px;
}

.title {
  font-size: var(--font-size-lg);
  font-weight: var(--font-weight-bold);
  color: var(--text-on-primary);
  margin: 0;
}

.milestones {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: var(--spacing-md);
  margin-left: calc(40px + var(--spacing-md));
}

.milestone {
  text-align: center;
  background-color: var(--color-gray-1100);
  border-radius: var(--border-radius-md);
  padding: var(--spacing-sm);
  border: 1px solid var(--color-gray-1000);
}

.version {
  font-size: var(--font-size-sm);
  color: var(--color-gray-400);
  margin-bottom: var(--spacing-xs);
}

.value {
  font-size: var(--font-size-base);
  font-weight: var(--font-weight-medium);
  color: var(--text-on-primary);
}

@media (max-width: 992px) {
  .title {
    font-size: var(--font-size-base);
  }
  
  .iconWrapper {
    width: 32px;
    height: 32px;
  }
  
  .icon {
    width: 16px;
    height: 16px;
  }
  
  .value {
    font-size: var(--font-size-sm);
  }
}

@media (max-width: 768px) {
  .container {
    padding: var(--spacing-sm);
    gap: var(--spacing-md);
  }
  
  .milestones {
    grid-template-columns: repeat(2, 1fr);
    margin-left: 0;
    gap: var(--spacing-sm);
  }
  
  .milestone {
    padding: var(--spacing-xs);
  }
}

@media (max-width: 480px) {
  .header {
    gap: var(--spacing-sm);
  }
  
  .version {
    font-size: var(--font-size-xs);
  }
  
  .value {
    font-size: var(--font-size-xs);
  }
}