/* File: frontend/src/pages/PropertyDetail/components/PropertyImages/PropertyImages.module.css */

.container {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-md);
}

.mainImageContainer {
  width: 100%;
  height: 400px;
  border-radius: var(--border-radius-md);
  overflow: hidden;
  background-color: var(--bg-secondary);
  display: flex;
  justify-content: center;
  align-items: center;
}

.mainImage, .mainMedia {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.thumbnailsContainer {
  display: flex;
  gap: var(--spacing-sm);
  overflow-x: auto;
  padding-bottom: var(--spacing-sm);
}

.thumbnail {
  width: 100px;
  height: 100px;
  border-radius: var(--border-radius-sm);
  overflow: hidden;
  cursor: pointer;
  border: 2px solid transparent;
  transition: border-color var(--transition-fast);
  position: relative;
}

.thumbnail:hover {
  border-color: var(--color-border-hover);
}

.activeThumbnail {
  border-color: var(--color-primary);
}

.thumbnailImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.thumbnailVideo {
  position: relative;
  width: 100%;
  height: 100%;
}

.videoIcon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.uploadButton {
  padding: var(--button-padding-small);
  background-color: var(--button-secondary-bg);
  color: var(--button-secondary-text);
  border: 1px solid var(--button-secondary-border);
  border-radius: var(--button-border-radius-md);
  font-size: var(--button-font-size-small);
  font-weight: var(--font-weight-medium);
  cursor: pointer;
  transition: background-color var(--transition-fast);
  white-space: nowrap;
}

.uploadButton:hover {
  background-color: var(--button-secondary-hover-bg);
}

.noImages {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: var(--spacing-xl);
  text-align: center;
  color: var(--text-secondary);
  background-color: var(--bg-secondary);
  border-radius: var(--border-radius-md);
  min-height: 300px;
}

.imageDetails {
  background-color: var(--bg-primary);
  border-radius: var(--border-radius-md);
  border: 1px solid var(--color-border);
  overflow: hidden;
  margin-top: var(--spacing-md);
}

.imageDetailsTitle {
  font-size: var(--font-size-md);
  font-weight: var(--font-weight-semibold);
  padding: var(--spacing-sm);
  background-color: var(--bg-secondary);
  border-bottom: 1px solid var(--color-border);
  margin: 0;
}

.imageDetailsContent {
  padding: var(--spacing-md);
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: var(--spacing-md);
}

.infoGroup {
  display: flex;
  flex-direction: column;
}

.infoLabel {
  font-weight: var(--font-weight-medium);
  color: var(--text-secondary);
  margin-bottom: var(--spacing-xxs);
}

.infoValue {
  color: var(--text-primary);
}

@media (max-width: 768px) {
  .mainImageContainer {
    height: 300px;
  }
  
  .imageDetailsContent {
    grid-template-columns: 1fr;
  }
}