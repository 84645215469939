/* File: frontend/src/components/Sidebar/SidebarBot.module.css */

.sidebarBot {
  position: relative;
  padding: var(--spacing-md);
  border-top: 1px solid var(--color-gray-1200);
}

.profileButton {
  width: 100%;
  display: flex;
  align-items: center;
  gap: var(--spacing-sm);
  padding: var(--spacing-sm);
  background: none;
  border: none;
  border-radius: var(--border-radius-lg);
  cursor: pointer;
  transition: all var(--transition-normal);
}

.profileButton:hover {
  background-color: var(--color-gray-1200);
}

.profileButton.active {
  background-color: var(--color-gray-1200);
}

.avatarContainer {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  overflow: hidden;
  flex-shrink: 0;
  background-color: var(--color-gray-1100);
  display: flex;
  align-items: center;
  justify-content: center;
}

.initialAvatar {
  font-size: var(--font-size-md);
  font-weight: var(--font-weight-medium);
  color: var(--color-gray-300);
  line-height: 1;
}

.userInfo {
  flex: 1;
  min-width: 0;
  text-align: left;
}

.userName {
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-medium);
  color: var(--color-gray-300);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.userRole {
  font-size: var(--font-size-xs);
  color: var(--color-gray-500);
}

.chevronWrapper {
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.chevronIcon {
  width: 16px;
  height: 16px;
  opacity: 0.6;
  transition: transform var(--transition-normal);
}

.active .chevronIcon {
  opacity: 1;
}

.menu {
  position: absolute;
  bottom: 100%;
  left: var(--spacing-md);
  right: var(--spacing-md);
  background-color: var(--color-gray-1200);
  border-radius: var(--border-radius-lg);
  padding: var(--spacing-xs);
  margin-bottom: var(--spacing-xs);
  box-shadow: var(--shadow-lg);
  animation: slideUp 0.2s ease-out;
}

.menuItem {
  display: flex;
  align-items: center;
  gap: var(--spacing-sm);
  width: 100%;
  padding: var(--spacing-sm);
  background: none;
  border: none;
  color: var(--color-gray-300);
  font-size: var(--font-size-sm);
  text-decoration: none;
  border-radius: var(--border-radius-md);
  cursor: pointer;
  transition: all var(--transition-normal);
}

.menuItem:hover {
  background-color: var(--color-gray-1100);
}

.menuIconWrapper {
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.menuIcon {
  width: 18px;
  height: 18px;
  opacity: 0.8;
  filter: invert(70%);
}

.menuItem:hover .menuIcon {
  opacity: 1;
}

.signOut {
  color: var(--color-error);
}

.signOut .menuIcon {
  filter: invert(40%) sepia(89%) saturate(2614%) hue-rotate(335deg) brightness(100%) contrast(110%);
}

@keyframes slideUp {
  from {
    opacity: 0;
    transform: translateY(8px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Collapsed state */
.sidebarBot:not(.expanded) {
  padding: var(--spacing-sm);
}

.sidebarBot:not(.expanded) .profileButton {
  justify-content: center;
  padding: var(--spacing-xs);
}

/* Mobile styles */
@media (max-width: 768px) {
  .sidebarBot {
    padding: var(--spacing-sm);
  }

  .menu {
    left: var(--spacing-sm);
    right: var(--spacing-sm);
  }
}