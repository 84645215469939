/* File: frontend/src/components/form/TextArea/TextArea.module.css */

.textareaWrapper {
    position: relative;
    display: flex;
    flex-direction: column;
  }
  
  .textarea {
    width: 100%;
    min-height: var(--textarea-min-height, 99px);
    border-radius: var(--input-border-radius);
    border: 1px solid var(--input-border-color);
    background-color: var(--input-background);
    box-shadow: var(--input-box-shadow);
    padding: var(--spacing-sm) var(--spacing-md);
    font-family: var(--font-secondary);
    font-size: var(--font-size-base);
    color: var(--input-text-color);
    transition: all var(--transition-fast);
    resize: vertical;
  }
  
  .textarea::placeholder {
    color: var(--input-placeholder-color);
  }
  
  .textarea:hover {
    background-color: var(--input-hover-background);
  }
  
  .textarea:focus {
    border-color: var(--input-focus-border-color);
    box-shadow: var(--input-focus-box-shadow);
    outline: none;
  }
  
  .disabled {
    background-color: var(--input-disabled-background);
    color: var(--input-disabled-text-color);
    cursor: not-allowed;
  }
  
  .error {
    border-color: var(--input-error-border-color);
  }
  
  .filled {
    color: var(--text-primary);
  }
  
  .focused {
    border-color: var(--input-focus-border-color);
    box-shadow: var(--input-focus-box-shadow);
  }
  
  .characterCount {
    position: absolute;
    bottom: var(--spacing-xs);
    right: var(--spacing-sm);
    font-size: var(--font-size-sm);
    color: var(--text-tertiary);
    display: flex;
    gap: var(--spacing-xxs);
  }
  
  @media (max-width: 768px) {
    .textarea {
      font-size: var(--font-size-sm);
    }
  
    .characterCount {
      font-size: var(--font-size-xs);
    }
  }