/* File: frontend/src/components/Dashboard/ProductSuiteCard.module.css */

.card {
  background-color: var(--color-white);
  border-radius: var(--border-radius-xl);
  padding: var(--spacing-xl);
  margin-bottom: var(--spacing-md);
  box-shadow: var(--shadow-sm);
  width: 100%;
}

.title {
  font-size: var(--font-size-2xl);
  font-weight: var(--font-weight-semibold);
  color: var(--text-primary);
  margin-bottom: var(--spacing-xl);
}

.contentContainer {
  display: flex;
  gap: var(--spacing-xl);
  align-items: flex-start;
}

.leftSection,
.rightSection {
  flex: 1;
  width: 50%;
  min-width: 0; /* Prevents flex items from overflowing */
}

.productInfo {
  background-color: var(--color-primary-100);
  border-radius: var(--border-radius-lg);
  padding: var(--spacing-sm);
  margin-bottom: var(--spacing-lg);
}

.infoBackground {
  background-color: var(--color-white);
  border: 1px solid var(--color-primary-100);
  border-radius: var(--border-radius-lg);
  padding: var(--spacing-lg);
  height: 100%;
}

.productTitle {
  font-size: var(--font-size-xl);
  font-weight: var(--font-weight-semibold);
  color: var(--text-primary);
  margin-bottom: var(--spacing-sm);
}

.productDescription {
  font-size: var(--font-size-md);
  line-height: 1.6;
  color: var(--text-secondary);
  margin-bottom: var(--spacing-md);
}

.productLink {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: var(--spacing-sm);
  text-decoration: none;
  color: var(--color-primary);
  font-weight: var(--font-weight-medium);
  transition: gap var(--transition-fast);
  margin-top: var(--spacing-sm);
}

.productLink:hover {
  gap: var(--spacing-md);
}

.productLink img {
  width: 20px;
  height: 20px;
  transition: transform var(--transition-fast);
}

.productLink:hover img {
  transform: translateX(4px);
}

.iconGroup {
  display: flex;
  gap: var(--spacing-md);
  background-color: var(--color-primary-100);
  border-radius: var(--border-radius-lg);
  padding: var(--spacing-sm);
}

.iconWrapper {
  background-color: var(--color-white);
  border: 1px solid var(--color-primary-100);
  border-radius: var(--border-radius-lg);
  padding: var(--spacing-md);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--spacing-sm);
  flex: 1;
  transition: all var(--transition-fast);
}

.iconWrapper:hover {
  transform: translateY(-2px);
  box-shadow: var(--shadow-md);
}

.icon {
  width: 32px;
  height: 32px;
}

.iconLabel {
  font-size: var(--font-size-sm);
  color: var(--text-primary);
  font-weight: var(--font-weight-medium);
  text-align: center;
}

.rightSection {
  flex: 1;
  display: flex;
  align-items: flex-start;
}

.videoContainer {
  position: relative;
  width: 100%;
  aspect-ratio: 16/9;
  border-radius: var(--border-radius-lg);
  overflow: hidden;
  background-color: var(--color-gray-100);
}

.videoPlaceholder {
  position: absolute;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--color-gray-900);
  color: var(--color-white);
}

/* Ensure the video player maintains aspect ratio */
.videoContainer :global(video),
.videoContainer :global(iframe) {
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  background-color: var(--color-gray-900);
}

.navigationContainer {
  margin-top: var(--spacing-xl);
}

.navigation {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: var(--spacing-lg);
}

.navButton {
  width: 40px;
  height: 40px;
  border-radius: var(--border-radius-full);
  border: 1px solid var(--color-border);
  background-color: var(--color-white);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all var(--transition-fast);
}

.navButton:hover:not(:disabled) {
  background-color: var(--color-primary-light);
  border-color: var(--color-primary);
}

.navButton:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.dots {
  display: flex;
  gap: var(--spacing-sm);
}

.dot {
  width: 8px;
  height: 8px;
  border-radius: var(--border-radius-full);
  background-color: var(--color-gray-300);
  border: none;
  padding: 0;
  cursor: pointer;
  transition: all var(--transition-fast);
}

.dot.activeDot {
  width: 24px;
  background-color: var(--color-primary);
}

@media (max-width: 1024px) {
  .contentContainer {
    flex-direction: column;
  }

  .leftSection,
  .rightSection {
    width: 100%;
  }

  .videoContainer {
    aspect-ratio: 16/9;
    margin-top: var(--spacing-md);
  }
}

@media (max-width: 768px) {
  .card {
    padding: var(--spacing-lg);
  }

  .title {
    font-size: var(--font-size-xl);
    margin-bottom: var(--spacing-lg);
  }

  .iconGroup {
    flex-wrap: wrap;
  }

  .iconWrapper {
    min-width: calc(50% - var(--spacing-sm));
    padding: var(--spacing-sm);
  }

  .navigation {
    gap: var(--spacing-md);
  }
}

@media (max-width: 480px) {
  .card {
    padding: var(--spacing-md);
  }

  .title {
    font-size: var(--font-size-lg);
    margin-bottom: var(--spacing-md);
  }

  .productInfo,
  .iconGroup {
    padding: var(--spacing-xs);
  }

  .infoBackground {
    padding: var(--spacing-sm);
  }

  .iconWrapper {
    padding: var(--spacing-xs);
  }

  .icon {
    width: 24px;
    height: 24px;
  }

  .iconLabel {
    font-size: var(--font-size-xs);
  }

  .dot.activeDot {
    width: 20px;
  }

  .navButton {
    width: 32px;
    height: 32px;
  }
}

/* Animation Keyframes */
@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateX(100%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes slideOut {
  from {
    opacity: 1;
    transform: translateX(0);
  }
  to {
    opacity: 0;
    transform: translateX(-100%);
  }
}

/* Add smooth transition for color changes */
.productCard {
  transition: background-color 0.5s ease;
}

/* Optional: Add hover effects for interactive elements */
.iconWrapper {
  cursor: pointer;
}

.iconWrapper:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}