/* File: frontend/src/pages/InvestorPitch/InvestorPitch.module.css */

.container {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    background-color: var(--color-gray-1300);
    color: var(--color-white);
    position: relative;
    padding: 0 var(--spacing-xl);
  }
  
  .enterpriseHeader {
    padding: var(--spacing-lg) 0;
    display: flex;
    justify-content: flex-end;
  }
  
  .enterpriseInfo {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
  
  .legalDisclaimer {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-xs);
  }
  
  .confidential {
    font-family: var(--font-mono);
    font-size: var(--font-size-sm);
    color: var(--color-red-middle);
    font-weight: var(--font-weight-bold);
    letter-spacing: 0.05em;
  }
  
  .legalDisclaimer p {
    font-size: var(--font-size-sm);
    color: var(--color-gray-400);
    margin: 0;
  }
  
  .enterpriseName {
    font-family: var(--font-mono);
    font-size: var(--font-size-base) !important;
    color: var(--color-gray-200) !important;
    font-weight: var(--font-weight-medium);
  }
  
  .enterprise_id {
    font-family: var(--font-mono);
    font-size: var(--font-size-sm);
    color: var(--color-gray-400);
  }
  
  .slideCount {
    font-family: var(--font-mono);
    font-size: var(--font-size-base);
    color: var(--color-gray-400);
    padding: var(--spacing-sm) var(--spacing-md);
    background-color: var(--color-gray-1200);
    border-radius: var(--border-radius-md);
  }
  
  /* Update main title styling */
  .slideTitle {
    font-size: var(--font-size-5xl);
    font-weight: var(--font-weight-bold);
    background: linear-gradient(to right, var(--color-primary-500), var(--color-primary-700));
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    margin-bottom: var(--spacing-lg);
    text-align: center;
  }
  
  .slideSubtitle {
    font-size: var(--font-size-xl);
    color: var(--color-gray-400);
    text-align: center;
    margin-bottom: var(--spacing-3xl);
  }
  
  /* Update main content area */
  .main {
    flex: 1;
    padding: 0 var(--spacing-3xl);
    position: relative;
    max-width: var(--breakpoint-xl);
    margin: 0 auto;
    width: 100%;
  }
  
  .slideContainer {
    position: relative;
    width: 100%;
    height: 100%;
    padding-bottom: var(--spacing-2xl);
  }
  
  /* Update footer */
  .footer {
    padding: var(--spacing-lg) 0;
    border-top: 1px solid var(--color-gray-1200);
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: var(--spacing-md);
  }

  .footerInfo {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: var(--spacing-md);
    text-align: center;
  }

  .confidentialInfo {
    display: flex;
    align-items: center;
    gap: var(--spacing-sm);
    font-size: var(--font-size-sm);
    color: var(--color-gray-400);
  }

  .confidential {
    color: var(--color-red-middle);
    font-weight: var(--font-weight-bold);
    letter-spacing: 0.05em;
  }
  
  .separator {
    color: var(--color-gray-800);
  }
  
  .enterprise_id {
    font-family: var(--font-mono);
  }
  
  .copyright {
    font-size: var(--font-size-sm);
    color: var(--color-gray-500);
  }
  
  @media (max-width: 768px) {
    .container {
      padding: 0 var(--spacing-md);
    }
  
    .slideTitle {
      font-size: var(--font-size-4xl);
    }
  
    .slideSubtitle {
      font-size: var(--font-size-lg);
    }
  
    .enterpriseInfo {
      flex-direction: column;
      gap: var(--spacing-md);
    }
  
    .slideCount {
      align-self: flex-end;
    }

    .confidentialInfo {
        flex-direction: column;
        gap: var(--spacing-xs);
      }
    
      .separator {
        display: none;
      }
  }