/* File: frontend/src/components/API/ApiResponses.module.css */

.section {
    margin-bottom: var(--spacing-xl);
  }
  
  .sectionTitle {
    font-size: var(--font-size-md);
    font-weight: var(--font-weight-semibold);
    color: var(--text-primary);
    margin-bottom: var(--spacing-lg);
  }
  
  .responseTabs {
    display: flex;
    flex-wrap: wrap;
    gap: var(--spacing-xs);
    margin-bottom: var(--spacing-lg);
  }
  
  .responseTab {
    display: flex;
    align-items: center;
    gap: var(--spacing-xs);
    padding: var(--spacing-xs) var(--spacing-sm);
    border-radius: var(--border-radius-md);
    border: 1px solid var(--color-border);
    background: var(--color-white);
    cursor: pointer;
    transition: all var(--transition-fast);
  }
  
  .responseTab:hover {
    border-color: var(--color-primary);
  }
  
  .responseTab.active {
    background: var(--color-primary-bg);
    border-color: var(--color-primary);
  }
  
  .statusCode {
    font-family: var(--font-secondary);
    font-size: var(--font-size-sm);
    font-weight: var(--font-weight-semibold);
  }
  
  .statusText {
    font-size: var(--font-size-sm);
    color: var(--text-secondary);
  }
  
  .success .statusCode { color: var(--color-success); }
  .redirect .statusCode { color: var(--color-warning); }
  .clientError .statusCode { color: var(--color-error); }
  .serverError .statusCode { color: var(--color-error); }
  .default .statusCode { color: var(--text-primary); }
  
  .responseContent {
    background: var(--bg-secondary);
    border-radius: var(--border-radius-lg);
    border: 1px solid var(--color-border);
    overflow: hidden;
  }
  
  .responseDescription {
    padding: var(--spacing-md);
    font-size: var(--font-size-sm);
    color: var(--text-secondary);
    line-height: var(--line-height-relaxed);
    border-bottom: 1px solid var(--color-border);
  }
  
  .responseExample {
    background: var(--color-gray-900);
  }
  
  .responseExampleHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: var(--spacing-sm) var(--spacing-md);
    background: var(--color-gray-800);
    border-bottom: 1px solid var(--color-gray-700);
  }
  
  .responseExampleTitle {
    font-size: var(--font-size-sm);
    font-weight: var(--font-weight-medium);
    color: var(--color-white);
  }
  
  .contentType {
    font-family: var(--font-secondary);
    font-size: var(--font-size-xs);
    color: var(--color-gray-400);
    padding: 2px var(--spacing-xs);
    background: var(--color-gray-700);
    border-radius: var(--border-radius-sm);
  }
  
  .responseExampleCode {
    margin: 0;
    padding: var(--spacing-md);
    overflow-x: auto;
  }
  
  .responseExampleCode code {
    font-family: var(--font-secondary);
    font-size: var(--font-size-sm);
    color: var(--color-white);
    line-height: var(--line-height-relaxed);
  }
  
  @media (max-width: 768px) {
    .responseTabs {
      flex-direction: column;
    }
  
    .responseTab {
      width: 100%;
    }
  
    .responseExampleHeader {
      flex-direction: column;
      align-items: flex-start;
      gap: var(--spacing-xs);
    }
  }