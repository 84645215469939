/* ComparisonColumnChart.module.css */
/* ComparisonColumnChart.module.css */
.chartsContainer {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-xl);
    background: var(--color-white);
    border-radius: var(--border-radius-lg);
    padding: var(--spacing-lg);
    box-shadow: var(--shadow-card);
}

.singleChartContainer {
    width: 100%;
    padding: var(--spacing-lg);
    background: var(--color-white);
    border-radius: var(--border-radius-lg);
    border: 1px solid var(--color-border);
}

.metricTitle {
    font-family: var(--font-primary);
    font-size: var(--font-size-lg);
    font-weight: var(--font-weight-semibold);
    color: var(--text-primary);
    margin-bottom: var(--spacing-md);
    text-align: center;
    line-height: var(--line-height-snug);
}

.tooltip {
    background-color: var(--color-white);
    border: 1px solid var(--color-border);
    padding: var(--spacing-md);
    border-radius: var(--border-radius-md);
    box-shadow: var(--shadow-lg);
    min-width: 160px;
}

.tooltipLabel {
    font-family: var(--font-primary);
    font-weight: var(--font-weight-semibold);
    margin-bottom: var(--spacing-xs);
    color: var(--text-primary);
    font-size: var(--font-size-sm);
}

.tooltipValue {
    font-family: var(--font-primary);
    font-weight: var(--font-weight-semibold);
    color: var(--text-primary);
}

/* Savings Section */
.savingsAnnotation {
    margin-top: var(--spacing-xl);
    padding: var(--spacing-xl);
    background: var(--color-success-bg);
    border-radius: var(--border-radius-lg);
    text-align: center;
    box-shadow: var(--box-shadow-success);
    transition: transform var(--transition-fast);
}

.savingsAnnotation:hover {
    transform: translateY(-2px);
}

.savingsAmount {
    font-family: var(--font-primary);
    font-size: var(--font-size-2xl);
    color: var(--color-success);
    font-weight: var(--font-weight-bold);
    margin-bottom: var(--spacing-xs);
    line-height: var(--line-height-tight);
}

.savingsPercentage {
    font-size: var(--font-size-lg);
    color: var(--color-success);
    font-weight: var(--font-weight-medium);
    line-height: var(--line-height-snug);
}

/* Averages Section */
.averagesAnnotation {
    margin-top: var(--spacing-xl);
    padding: var(--spacing-xl);
    background: var(--bg-secondary);
    border-radius: var(--border-radius-lg);
    border: 1px solid var(--color-border);
    box-shadow: var(--shadow-sm);
}

.averagesTitle {
    font-family: var(--font-primary);
    font-size: var(--font-size-xl);
    color: var(--text-primary);
    font-weight: var(--font-weight-semibold);
    margin-bottom: var(--spacing-lg);
    text-align: center;
    line-height: var(--line-height-snug);
}

.averagesGrid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
    gap: var(--spacing-lg);
}

.averageMetric {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: var(--spacing-sm);
    padding: var(--spacing-lg);
    background: var(--color-white);
    border-radius: var(--border-radius-lg);
    border: 1px solid var(--color-border);
    transition: transform var(--transition-fast), box-shadow var(--transition-fast);
}

.averageMetric:hover {
    transform: translateY(-2px);
    box-shadow: var(--shadow-md);
}

.averageLabel {
    font-size: var(--font-size-sm);
    color: var(--text-secondary);
    text-align: center;
    line-height: var(--line-height-snug);
}

.averageValue {
    font-family: var(--font-primary);
    font-size: var(--font-size-2xl);
    font-weight: var(--font-weight-bold);
    color: var(--color-primary);
    line-height: var(--line-height-tight);
}

/* Changes Section */
.changesAnnotation {
    margin-top: var(--spacing-xl);
    padding: var(--spacing-xl);
    background: var(--bg-secondary);
    border-radius: var(--border-radius-lg);
    border: 1px solid var(--color-border);
    box-shadow: var(--shadow-sm);
}

.changesGrid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
    gap: var(--spacing-xl);
}

.changesColumn {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-md);
}

.changesTitle {
    font-family: var(--font-primary);
    font-size: var(--font-size-lg);
    font-weight: var(--font-weight-semibold);
    color: var(--text-primary);
    margin-bottom: var(--spacing-md);
    text-align: center;
    line-height: var(--line-height-snug);
}

.changeItem {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: var(--spacing-md);
    background: var(--color-white);
    border-radius: var(--border-radius-md);
    border: 1px solid var(--color-border);
    transition: transform var(--transition-fast);
}

.changeItem:hover {
    transform: translateX(4px);
}

.changeMetric {
    font-size: var(--font-size-sm);
    color: var(--text-secondary);
    line-height: var(--line-height-snug);
}

.changeValue {
    font-weight: var(--font-weight-semibold);
    font-size: var(--font-size-sm);
    line-height: var(--line-height-snug);
}

.changeValue.positive {
    color: var(--color-success);
}

.changeValue.negative {
    color: var(--color-error);
}

/* Insights Section */
.insightsContainer {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: var(--spacing-lg);
    margin-top: var(--spacing-xl);
    padding-top: var(--spacing-xl);
    border-top: 1px solid var(--color-border);
}

.insightCard {
    padding: var(--spacing-lg);
    background: var(--color-white);
    border-radius: var(--border-radius-lg);
    border: 1px solid var(--color-border);
    box-shadow: var(--shadow-sm);
    transition: transform var(--transition-fast), box-shadow var(--transition-fast);
}

.insightCard:hover {
    transform: translateY(-2px);
    box-shadow: var(--shadow-md);
}

.insightTitle {
    font-family: var(--font-primary);
    font-size: var(--font-size-lg);
    font-weight: var(--font-weight-semibold);
    color: var(--text-primary);
    margin-bottom: var(--spacing-lg);
    line-height: var(--line-height-snug);
}

.insightList {
    list-style: none;
    padding: 0;
    margin: 0;
}

.insightItem {
    display: flex;
    align-items: flex-start;
    padding: var(--spacing-xs) 0;
    color: var(--text-secondary);
    font-size: var(--font-size-sm);
    line-height: var(--line-height-normal);
}

.insightItem::before {
    content: "•";
    color: var(--color-primary);
    font-weight: var(--font-weight-bold);
    margin-right: var(--spacing-sm);
    margin-top: 2px;
}