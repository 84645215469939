/* File: frontend/src/pages/QMS/QMS.module.css */

.container {
  padding: var(--spacing-lg, 24px);
  max-width: 1200px;
  margin: 0 auto;
}

.title {
  font-size: var(--font-size-xl, 24px);
  font-weight: var(--font-weight-bold, 700);
  color: var(--text-primary, #1a202c);
  margin-bottom: var(--spacing-lg, 24px);
}

.error {
  background-color: var(--color-error-bg, #fde8e8);
  color: var(--color-error, #e53e3e);
  padding: var(--spacing-md, 16px);
  border-radius: var(--border-radius-md, 4px);
  margin-bottom: var(--spacing-md, 16px);
}

@media (max-width: 768px) {
  .container {
    padding: var(--spacing-md, 16px);
  }
}