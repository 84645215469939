/* File: frontend/src/components/form/auth/OTPVerificationSignUp.module.css */

.otpVerification {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: var(--breakpoint-md);
  margin: 0 auto;
}

.contentWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: var(--spacing-3xl) var(--spacing-md);
  width: 100%;
}

.otpIcon {
  width: 186px;
  height: 103px;
  border-radius: var(--border-radius-lg);
  object-fit: contain;
  margin-bottom: var(--spacing-md);
}

.textContent {
  text-align: center;
  margin-bottom: var(--spacing-xl);
}

.title {
  font-size: var(--font-size-2xl);
  line-height: var(--line-height-snug);
  font-weight: var(--font-weight-medium);
  color: var(--text-primary);
  margin-bottom: var(--spacing-xs);
}

.description {
  font-size: var(--font-size-sm);
  line-height: var(--line-height-normal);
  color: var(--text-secondary);
}

.otpInputContainer {
  display: flex;
  justify-content: center;
  gap: var(--spacing-xs);
  background-color: var(--bg-secondary);
  border-radius: var(--border-radius-xl);
  padding: var(--spacing-md);
  transition: var(--transition-normal);
  outline: none;
  margin: var(--spacing-md) 0;
}

.otpInputContainer.error {
  background-color: var(--color-error-bg);
}

.otpInputContainer.success {
  background-color: var(--color-success-bg);
}

.otpInput {
  width: 46px;
  height: 46px;
  border-radius: var(--border-radius-lg);
  background-color: var(--bg-primary);
  border: 1px solid var(--color-border);
  box-shadow: var(--shadow-sm);
  font-size: var(--font-size-xl);
  text-align: center;
  color: var(--text-primary);
  transition: var(--transition-normal);
  caret-color: var(--color-primary);
}

.otpInput:focus {
  border-color: var(--color-primary);
  box-shadow: var(--focus-ring);
  outline: none;
}

.otpInput.error {
  border-color: var(--color-error);
}

.otpInput.success {
  border-color: var(--color-success);
}

.errorMessage {
  color: var(--color-error);
  font-size: var(--font-size-sm);
  margin-top: var(--spacing-xs);
}

.resendButton {
  margin-top: var(--spacing-md);
  background: none;
  border: none;
  color: var(--color-primary);
  font-size: var(--font-size-sm);
  cursor: pointer;
  transition: var(--transition-fast);
  padding: var(--spacing-xs) var(--spacing-sm);
  border-radius: var(--border-radius-sm);
}

.resendButton:hover:not(:disabled) {
  background-color: var(--bg-secondary);
}

.resendButton:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

/* Large screens (1200px and up) */
@media (min-width: 1200px) {
  .contentWrapper {
    padding: var(--spacing-4xl) var(--spacing-xl);
  }
}

/* Medium-large screens (1024px and down) */
@media (max-width: 1024px) {
  .otpIcon {
    width: 160px;
    height: 89px;
  }
}

/* Tablet screens (768px and down) */
@media (max-width: 768px) {
  .otpInputContainer {
    padding: var(--spacing-sm);
    gap: var(--spacing-xxs);
  }

  .contentWrapper {
    padding: var(--spacing-2xl) var(--spacing-md);
  }

  .otpIcon {
    width: 140px;
    height: 77px;
  }

  .title {
    font-size: var(--font-size-xl);
  }

  .description {
    font-size: var(--font-size-sm);
  }

  .otpInput {
    width: 40px;
    height: 40px;
    font-size: var(--font-size-lg);
  }
}

/* Mobile screens (480px and down) */
@media (max-width: 480px) {
  .contentWrapper {
    padding: var(--spacing-xl) var(--spacing-sm);
  }

  .otpIcon {
    width: 120px;
    height: 66px;
  }

  .otpInput {
    width: 36px;
    height: 36px;
    font-size: var(--font-size-md);
  }

  .otpInputContainer {
    padding: var(--spacing-xs);
    gap: var(--spacing-xxxs);
  }
}

/* Autofill styles */
.otpInputContainer input:-webkit-autofill,
.otpInputContainer input:-webkit-autofill:hover,
.otpInputContainer input:-webkit-autofill:focus {
  -webkit-text-fill-color: var(--text-primary);
  -webkit-box-shadow: 0 0 0px 1000px var(--bg-primary) inset;
  transition: background-color 5000s ease-in-out 0s;
}

/* Dark mode support */
@media (prefers-color-scheme: dark) {
  .otpInput {
    background-color: var(--bg-secondary);
    border-color: var(--color-border);
    color: var(--text-primary);
  }

  .otpInput:focus {
    border-color: var(--color-primary);
    box-shadow: var(--focus-ring);
  }
}

/* Safari-specific styles */
@supports (-webkit-appearance: none) {
  .otpInputContainer input {
    transition: all var(--transition-fast);
  }
  
  .otpInputContainer input:autofill {
    background-color: var(--bg-primary) !important;
  }
}