/* File: frontend/src/pages/Status/components/UptimeChart.module.css */

.container {
    background: var(--bg-primary);
    border-radius: var(--border-radius-lg);
    padding: var(--spacing-md);
    margin-bottom: var(--spacing-md);
    box-shadow: var(--shadow-sm);
}

.title {
    margin: 0 0 var(--spacing-md) 0;
    font-size: var(--font-size-lg);
    font-weight: var(--font-weight-semibold);
    color: var(--text-primary);
}

.chart {
    margin-bottom: var(--spacing-sm);
}

.loading, .empty {
    padding: var(--spacing-lg);
    text-align: center;
    color: var(--text-secondary);
}

.tooltip {
    background: var(--bg-primary);
    border-radius: var(--border-radius-md);
    padding: var(--spacing-xs);
    box-shadow: var(--shadow-lg);
    border: 1px solid var(--color-border);
}

.tooltipTime {
    font-size: var(--font-size-sm);
    font-weight: var(--font-weight-semibold);
    color: var(--text-primary);
    margin-bottom: var(--spacing-xxs);
    padding-bottom: var(--spacing-xxs);
    border-bottom: 1px solid var(--color-border);
}

.tooltipContent {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-xxxs);
}

.tooltipRow {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: var(--font-size-sm);
}

.tooltipRow span:first-child {
    color: var(--text-secondary);
    margin-right: var(--spacing-sm);
}

.tooltipRow span:last-child {
    font-weight: var(--font-weight-medium);
    color: var(--text-primary);
}

.tooltipRow .ok {
    color: var(--color-success);
}

.tooltipRow .degraded {
    color: var(--color-warning);
}

.tooltipRow .error {
    color: var(--color-error);
}

.legend {
    display: flex;
    justify-content: center;
    gap: var(--spacing-lg);
    padding-top: var(--spacing-sm);
    border-top: 1px solid var(--color-border);
}

.legendItem {
    display: flex;
    align-items: center;
    gap: var(--spacing-xxs);
    font-size: var(--font-size-sm);
    color: var(--text-secondary);
}

.legendDot {
    width: var(--spacing-xxs);
    height: var(--spacing-xxs);
    border-radius: var(--border-radius-full);
}

.legendDot.ok {
    background-color: var(--color-success);
}

.legendDot.degraded {
    background-color: var(--color-warning);
}

.legendDot.error {
    background-color: var(--color-error);
}

@media (max-width: 768px) {
    .legend {
        flex-wrap: wrap;
        gap: var(--spacing-sm);
    }
}