/* File: frontend/src/pages/AIAgents/components/AgentCard.module.css */

.container {
    /* Structure */
    width: 100%;
    max-width: 700px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    
    /* Visual */
    background: linear-gradient(180deg, 
      var(--color-gray-1200) 0%,
      var(--color-gray-1300) 100%);
    border-radius: var(--border-radius-xl);
    border: 1px solid rgba(255, 255, 255, 0.1);
    
    /* Animation */
    transition: transform var(--transition-normal),
                box-shadow var(--transition-normal);
  }
  
  .container:hover {
    transform: translateY(-4px);
    box-shadow: 0 var(--spacing-md) var(--spacing-2xl) rgba(0, 0, 0, 0.2);
  }
  
  .header {
    /* Layout */
    padding: var(--spacing-2xl);
    text-align: center;
    position: relative;
  }
  
  .iconWrapper {
    /* Size */
    width: var(--spacing-4xl);
    height: var(--spacing-4xl);
    margin: 0 auto var(--spacing-lg);
    
    /* Layout */
    display: flex;
    align-items: center;
    justify-content: center;
    
    /* Visual */
    background: rgba(255, 255, 255, 0.1);
    border-radius: var(--border-radius-lg);
    backdrop-filter: blur(5px);
  }
  
  .icon {
    width: var(--spacing-xl);
    height: var(--spacing-xl);
    color: var(--color-white);
  }
  
  .name {
    font-size: var(--font-size-2xl);
    font-weight: var(--font-weight-bold);
    color: var(--color-white);
    margin-bottom: var(--spacing-xs);
    line-height: var(--line-height-tight);
  }
  
  .title {
    font-size: var(--font-size-lg);
    color: var(--color-gray-300);
    line-height: var(--line-height-snug);
  }
  
  .content {
    /* Layout */
    padding: var(--spacing-2xl);
    display: flex;
    flex-direction: column;
    flex: 1;
    
    /* Visual */
    border-top: 1px solid rgba(255, 255, 255, 0.1);
  }
  
  .description {
    font-size: var(--font-size-base);
    color: var(--color-gray-400);
    line-height: var(--line-height-relaxed);
    margin-bottom: var(--spacing-xl);
  }
  
  .capabilities {
    margin-top: auto;
  }
  
  .capabilitiesTitle {
    text-transform: uppercase;
    font-size: var(--font-size-xs);
    font-weight: var(--font-weight-semibold);
    color: var(--color-gray-400);
    margin-bottom: var(--spacing-lg);
    letter-spacing: 0.1em;
  }
  
  .capabilitiesList {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    gap: var(--spacing-md);
  }
  
  .capability {
    display: flex;
    align-items: center;
    gap: var(--spacing-md);
    color: var(--color-gray-300);
    font-size: var(--font-size-base);
    transition: all var(--transition-fast);
  }
  
  .capability:hover {
    color: var(--color-white);
    transform: translateX(var(--spacing-xxs));
  }
  
  .bullet {
    width: var(--spacing-xxs);
    height: var(--spacing-xxs);
    border-radius: var(--border-radius-full);
    background: linear-gradient(135deg, 
      var(--color-blue-310),
      var(--color-blue-510));
    flex-shrink: 0;
  }
  
  .agentCta {
    background-color: transparent;
    color: var(--color-primary);
    transition: background-color var(--transition-normal), 
                color var(--transition-normal);
    padding: clamp(0.5rem, 2vw, 1rem) clamp(1rem, 4vw, 2rem);
    font-size: clamp(0.875rem, 2vw, 1rem);
    min-width: 150px;
    align-self: flex-start;
    margin-top: var(--spacing-2xl);
    border: 1px solid var(--color-primary);
    border-radius: var(--border-radius-full);
    text-align: center;
    width: 100%;
  }
  
  .agentCta:hover {
    background-color: var(--color-primary);
    color: var(--color-white);
  }
  
  .ctaIcon {
    width: var(--spacing-md);
    height: var(--spacing-md);
    transition: transform var(--transition-fast);
  }
  
  .agentCta:hover .ctaIcon {
    transform: translateX(var(--spacing-xxs));
  }
  
  /* Responsive Adjustments */
  @media (max-width: 768px) {
    .header {
      padding: var(--spacing-xl);
    }
  
    .content {
      padding: var(--spacing-xl);
    }
  
    .iconWrapper {
      width: var(--spacing-3xl);
      height: var(--spacing-3xl);
    }
  
    .icon {
      width: var(--spacing-lg);
      height: var(--spacing-lg);
    }
  
    .name {
      font-size: var(--font-size-xl);
    }
  
    .title {
      font-size: var(--font-size-base);
    }
  }
  
  @media (max-width: 480px) {
    .header,
    .content {
      padding: var(--spacing-lg);
    }
  
    .description {
      font-size: var(--font-size-sm);
    }
  
    .capability {
      font-size: var(--font-size-sm);
    }
  }

  @media (prefers-reduced-motion: reduce) {
    .agentCta {
      transition: none;
    }
  }