/* File: frontend/src/pages/SuperAdmin/components/ReportManagement/ReportDetail/ReportDetail.module.css */

.container {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-md);
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: var(--spacing-md);
}

.headerContent {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xs);
}

.reportTitle {
  font-size: var(--font-size-xl);
  font-weight: var(--font-weight-bold);
  color: var(--text-primary);
  margin: 0;
}

.reportId {
  font-size: var(--font-size-sm);
  color: var(--text-secondary);
  font-family: var(--font-family-mono);
}

.statusContainer {
  display: flex;
  gap: var(--spacing-sm);
  margin-top: var(--spacing-xs);
}

.statusBadge, .typeBadge {
  display: inline-block;
  padding: 4px 8px;
  border-radius: 12px;
  font-size: var(--font-size-xs);
  font-weight: var(--font-weight-medium);
  text-transform: capitalize;
}

.statusCompleted {
  background-color: var(--color-success-bg);
  color: var(--color-success);
}

.statusScheduled {
  background-color: var(--color-info-bg);
  color: var(--color-info);
}

.statusRunning {
  background-color: var(--color-primary-100);
  color: var(--color-primary);
}

.statusFailed {
  background-color: var(--color-error-bg);
  color: var(--color-error);
}

.statusDraft {
  background-color: var(--color-warning-bg);
  color: var(--color-warning);
}

.typeAnalytics {
  background-color: var(--color-primary-100);
  color: var(--color-primary);
}

.typeFinancial {
  background-color: var(--color-success-bg);
  color: var(--color-success);
}

.typeSecurity {
  background-color: var(--color-error-bg);
  color: var(--color-error);
}

.typeSurvey {
  background-color: var(--color-info-bg);
  color: var(--color-info);
}

.typeMarketing {
  background-color: var(--color-warning-bg);
  color: var(--color-warning);
}

.headerActions {
  display: flex;
  gap: var(--spacing-md);
}

.backButton, .runButton, .editButton {
  display: flex;
  align-items: center;
  gap: var(--spacing-xs);
  padding: var(--spacing-sm) var(--spacing-md);
  border-radius: var(--border-radius-md);
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-medium);
  cursor: pointer;
  transition: all var(--transition-fast);
}

.backButton {
  background-color: var(--bg-secondary);
  color: var(--text-primary);
  border: 1px solid var(--color-border);
}

.backButton:hover:not(:disabled) {
  background-color: var(--bg-hover);
}

.runButton {
  background-color: var(--color-success-bg);
  color: var(--color-success);
  border: 1px solid var(--color-success);
}

.runButton:hover:not(:disabled) {
  background-color: var(--color-success);
  color: white;
}

.editButton {
  background-color: var(--color-primary);
  color: white;
  border: none;
}

.editButton:hover {
  background-color: var(--color-primary-dark);
}

.backButton:disabled, .runButton:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.tabs {
  display: flex;
  border-bottom: 1px solid var(--color-border);
  margin-bottom: var(--spacing-md);
}

.tab {
  padding: var(--spacing-sm) var(--spacing-md);
  background: none;
  border: none;
  border-bottom: 2px solid transparent;
  color: var(--text-secondary);
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-medium);
  cursor: pointer;
  transition: all var(--transition-fast);
}

.tab:hover {
  color: var(--text-primary);
}

.activeTab {
  color: var(--color-primary);
  border-bottom-color: var(--color-primary);
}

.tabContent {
  padding: var(--spacing-sm) 0;
}

.section {
  margin-bottom: var(--spacing-lg);
}

.sectionTitle {
  font-size: var(--font-size-lg);
  font-weight: var(--font-weight-semibold);
  color: var(--text-primary);
  margin: 0 0 var(--spacing-md) 0;
  padding-bottom: var(--spacing-xs);
  border-bottom: 1px solid var(--color-border-light);
}

.subSectionTitle {
  font-size: var(--font-size-base);
  font-weight: var(--font-weight-medium);
  color: var(--text-primary);
  margin: var(--spacing-md) 0 var(--spacing-sm) 0;
}

.infoGrid, .parametersGrid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: var(--spacing-md);
}

.infoItem {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xs);
}

.infoLabel {
  font-size: var(--font-size-sm);
  color: var(--text-secondary);
  font-weight: var(--font-weight-medium);
}

.infoValue {
  font-size: var(--font-size-base);
  color: var(--text-primary);
}

.description {
  font-size: var(--font-size-base);
  color: var(--text-primary);
  line-height: 1.6;
  margin: 0;
}

.tagsList, .rolesList {
  display: flex;
  flex-wrap: wrap;
  gap: var(--spacing-xs);
}

.metricTag, .segmentTag, .regionTag, .chartTypeTag, .roleTag {
  display: inline-block;
  padding: 4px 8px;
  border-radius: 12px;
  font-size: var(--font-size-xs);
  font-weight: var(--font-weight-medium);
  text-transform: capitalize;
}

.metricTag {
  background-color: var(--color-primary-100);
  color: var(--color-primary);
}

.segmentTag {
  background-color: var(--color-info-bg);
  color: var(--color-info);
}

.regionTag {
  background-color: var(--color-success-bg);
  color: var(--color-success);
}

.chartTypeTag {
  background-color: var(--color-warning-bg);
  color: var(--color-warning);
}

.roleTag {
  background-color: var(--color-primary-100);
  color: var(--color-primary);
}

.executionList {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-md);
}

.executionItem {
  padding: var(--spacing-md);
  background-color: var(--bg-secondary);
  border-radius: var(--border-radius-md);
  border: 1px solid var(--color-border);
}

.executionHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: var(--spacing-sm);
}

.executionTime {
  font-size: var(--font-size-sm);
  color: var(--text-tertiary);
}

.executionDetails {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xs);
  margin-bottom: var(--spacing-sm);
}

.executionDetail {
  display: flex;
  font-size: var(--font-size-sm);
}

.executionDetailLabel {
  color: var(--text-secondary);
  margin-right: var(--spacing-xs);
  font-weight: var(--font-weight-medium);
  min-width: 100px;
}

.executionDetailValue {
  color: var(--text-primary);
}

.errorText {
  color: var(--color-error);
}

.executionActions {
  display: flex;
  justify-content: flex-end;
  margin-top: var(--spacing-sm);
}

.downloadButton {
  display: flex;
  align-items: center;
  gap: var(--spacing-xs);
  padding: var(--spacing-xs) var(--spacing-sm);
  background-color: var(--color-primary-100);
  color: var(--color-primary);
  border: 1px solid var(--color-primary);
  border-radius: var(--border-radius-sm);
  font-size: var(--font-size-xs);
  font-weight: var(--font-weight-medium);
  text-decoration: none;
  cursor: pointer;
  transition: all var(--transition-fast);
}

.downloadButton:hover {
  background-color: var(--color-primary);
  color: white;
}

.accessInfo {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-lg);
}

.sharedUsersList {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-md);
}

.sharedUser {
  display: flex;
  justify-content: space-between;
  padding: var(--spacing-md);
  background-color: var(--bg-secondary);
  border-radius: var(--border-radius-md);
  border: 1px solid var(--color-border-light);
}

.sharedUserInfo {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xxs);
}

.sharedUserName {
  font-weight: var(--font-weight-medium);
  color: var(--text-primary);
}

.sharedUserEmail {
  font-size: var(--font-size-sm);
  color: var(--text-secondary);
}

.sharedUserMeta {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xxs);
  text-align: right;
}

.sharedUserDetail {
  display: flex;
  font-size: var(--font-size-sm);
  gap: var(--spacing-xs);
}

.sharedUserDetailLabel {
  color: var(--text-secondary);
  font-weight: var(--font-weight-medium);
}

.sharedUserDetailValue {
  color: var(--text-primary);
}

.emptyState {
  padding: var(--spacing-lg);
  text-align: center;
  color: var(--text-secondary);
  background-color: var(--bg-secondary);
  border-radius: var(--border-radius-md);
  border: 1px dashed var(--color-border);
}

.loading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: var(--spacing-xl);
  gap: var(--spacing-md);
}

.loading .spinner {
  width: 40px;
  height: 40px;
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  border-top-color: var(--color-primary);
  animation: spin 1s linear infinite;
}

.error, .notFound {
  padding: var(--spacing-lg);
  text-align: center;
  color: var(--color-error);
  background-color: var(--color-error-bg);
  border-radius: var(--border-radius-md);
  margin-bottom: var(--spacing-md);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--spacing-md);
}

.notFound {
  color: var(--text-secondary);
  background-color: var(--bg-secondary);
}

.errorMessage {
  padding: var(--spacing-md);
  background-color: var(--color-error-bg);
  color: var(--color-error);
  border-radius: var(--border-radius-md);
  margin-bottom: var(--spacing-md);
  display: flex;
  align-items: center;
  gap: var(--spacing-sm);
}

/* Form styles */
.form {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-lg);
}

.formSection {
  background-color: var(--bg-primary);
  border-radius: var(--border-radius-md);
  border: 1px solid var(--color-border);
  padding: var(--spacing-md);
}

.formGrid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: var(--spacing-md);
}

.formGroup {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xs);
}

.formGroupFull {
  grid-column: 1 / -1;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xs);
}

.label {
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-medium);
  color: var(--text-secondary);
}

.required {
  color: var(--color-error);
}

.input, .select, .textarea {
  padding: var(--spacing-sm);
  border: 1px solid var(--color-border);
  border-radius: var(--border-radius-md);
  font-size: var(--font-size-base);
  color: var(--text-primary);
  background-color: var(--bg-primary);
  transition: border-color var(--transition-fast), box-shadow var(--transition-fast);
}

.input:focus, .select:focus, .textarea:focus {
  outline: none;
  border-color: var(--color-primary);
  box-shadow: 0 0 0 2px var(--color-primary-100);
}

.input:disabled, .select:disabled, .textarea:disabled {
  background-color: var(--bg-secondary);
  cursor: not-allowed;
}

.textarea {
  resize: vertical;
  min-height: 80px;
}

.checkboxGroup {
  display: flex;
  align-items: center;
  gap: var(--spacing-sm);
}

.checkbox {
  width: 18px;
  height: 18px;
  cursor: pointer;
}

.checkboxLabel {
  font-size: var(--font-size-base);
  color: var(--text-primary);
}

.formActions {
  display: flex;
  justify-content: flex-end;
  gap: var(--spacing-md);
  margin-top: var(--spacing-md);
}

.cancelButton, .submitButton {
  padding: var(--spacing-sm) var(--spacing-lg);
  border-radius: var(--border-radius-md);
  font-size: var(--font-size-base);
  font-weight: var(--font-weight-medium);
  cursor: pointer;
  transition: all var(--transition-fast);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: var(--spacing-xs);
  min-width: 120px;
}

.cancelButton {
  background-color: var(--bg-secondary);
  color: var(--text-primary);
  border: 1px solid var(--color-border);
}

.cancelButton:hover:not(:disabled) {
  background-color: var(--bg-hover);
}

.submitButton {
  background-color: var(--color-primary);
  color: white;
  border: none;
}

.submitButton:hover:not(:disabled) {
  background-color: var(--color-primary-dark);
}

.cancelButton:disabled, .submitButton:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.spinner {
  display: inline-block;
  width: 16px;
  height: 16px;
  border: 2px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top-color: white;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@media (max-width: 992px) {
  .infoGrid, .parametersGrid {
    grid-template-columns: repeat(2, 1fr);
  }
  
  .formGrid {
    grid-template-columns: 1fr;
  }
  
  .sharedUser {
    flex-direction: column;
    gap: var(--spacing-md);
  }
  
  .sharedUserMeta {
    text-align: left;
  }
}

@media (max-width: 768px) {
  .header {
    flex-direction: column;
    gap: var(--spacing-md);
  }
  
  .headerActions {
    width: 100%;
    flex-wrap: wrap;
  }
  
  .backButton, .runButton, .editButton {
    flex: 1;
    justify-content: center;
  }
  
  .tabs {
    overflow-x: auto;
    white-space: nowrap;
    padding-bottom: var(--spacing-xs);
  }
  
  .infoGrid, .parametersGrid {
    grid-template-columns: 1fr;
  }
  
  .formActions {
    flex-direction: column;
  }
  
  .cancelButton, .submitButton {
    width: 100%;
  }
}