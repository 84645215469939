/* File: frontend/src/components/form/Checkbox/Checkbox.module.css */

.checkboxWrapper {
    display: flex;
    align-items: center;
    font-family: var(--font-secondary);
    font-size: var(--font-size-sm);
    color: var(--text-primary);
    cursor: pointer;
    margin-top: var(--spacing-xxs);
  }
  
  .checkboxInput {
    position: absolute;
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .checkboxLabel {
    display: inline-flex;
    align-items: center;
    line-height: var(--line-height-normal);
    font-weight: var(--font-weight-medium);
    padding-left: calc(var(--checkbox-size) + var(--spacing-xs));
    position: relative;
    cursor: pointer;
  }
  
  .checkboxLabel::before {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: var(--checkbox-size, 18px);
    height: var(--checkbox-size, 18px);
    border: 1px solid var(--input-border-color);
    border-radius: var(--checkbox-border-radius, 4px);
    background-color: var(--color-white);
    transition: all 0.2s ease-in-out;
  }
  
  .checkboxInput:checked + .checkboxLabel::before {
    background-color: var(--color-primary);
    border-color: var(--color-primary);
  }
  
  .checkboxInput:checked + .checkboxLabel::after {
    content: '';
    position: absolute;
    left: 6px;
    top: 50%;
    transform: translateY(-65%) rotate(45deg);
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 2px 2px 0;
  }
  
  .checkboxInput:focus + .checkboxLabel::before {
    box-shadow: var(--input-focus-box-shadow);
  }
  
  .checkboxWrapper:hover .checkboxLabel::before {
    border-color: var(--color-primary);
  }
  
  .checkboxInput:disabled + .checkboxLabel::before {
    background-color: var(--input-disabled-background);
    border-color: var(--input-border-color);
    cursor: not-allowed;
  }
  
  .checkboxInput:disabled + .checkboxLabel {
    color: var(--text-disabled);
    cursor: not-allowed;
  }
  
  .checkboxInput:indeterminate + .checkboxLabel::after {
    content: '';
    position: absolute;
    left: 4px;
    top: 50%;
    transform: translateY(-50%);
    width: 10px;
    height: 2px;
    background-color: var(--color-white);
  }
  
  @media (max-width: 768px) {
    .checkboxWrapper {
      font-size: var(--font-size-sm);
    }
  }