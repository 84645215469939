/* File: frontend/src/pages/PrivacyPolicy/PrivacyPolicy.module.css */

.privacyPolicyContainer {
  max-width: 800px;
  margin: 0 auto;
  padding: 2rem;
  font-family: 'Instrument Sans', sans-serif;
  color: #333;
}

.title {
  font-size: 2.5rem;
  color: #3667F8;
  margin-bottom: 1rem;
}

.lastUpdated,
.effectiveDate {
  font-size: 0.9rem;
  color: #666;
  margin-bottom: 0.5rem;
}

.section {
  margin-bottom: 2rem;
}

.section h2 {
  font-size: 1.8rem;
  color: #2348ed;
  margin-bottom: 1rem;
}

.section h3 {
  font-size: 1.4rem;
  color: #333;
  margin-bottom: 0.5rem;
}

.section p {
  line-height: 1.6;
  margin-bottom: 1rem;
}

@media (max-width: 768px) {
  .privacyPolicyContainer {
    padding: 1rem;
  }

  .title {
    font-size: 2rem;
  }

  .section h2 {
    font-size: 1.5rem;
  }

  .section h3 {
    font-size: 1.2rem;
  }
}