/* File: frontend/src/pages/InvestorPitch/components/slides/GoToMarketExecutionSlide/GoToMarketExecutionSlide.module.css */

.container {
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-lg);
  padding: var(--spacing-md);
  width: 100%;
}

.header {
  text-align: center;
  margin-bottom: var(--spacing-md);
}

.title {
  font-size: var(--font-size-4xl);
  font-weight: var(--font-weight-bold);
  color: var(--text-on-primary);
  margin-bottom: var(--spacing-md);
  background: linear-gradient(135deg, var(--color-primary), var(--color-blue-510));
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}

.subtitle {
  font-size: var(--font-size-xl);
  color: var(--color-gray-400);
  max-width: 800px;
  margin: 0 auto;
}

.content {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xl);
  flex: 1;
  overflow-y: auto;
}

.sectionTitle {
  font-size: var(--font-size-xl);
  font-weight: var(--font-weight-bold);
  color: var(--text-on-primary);
  margin-bottom: var(--spacing-md);
  display: flex;
  align-items: center;
  gap: var(--spacing-sm);
}

.sectionTitle::before {
  content: '';
  width: 4px;
  height: 20px;
  background: linear-gradient(
    to bottom,
    var(--color-primary-500),
    var(--color-primary-700)
  );
  border-radius: var(--border-radius-sm);
}

/* Advantages Section */
.advantagesSection {
  margin-bottom: var(--spacing-md);
}

.advantagesGrid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: var(--spacing-lg);
}

.advantageCard {
  background-color: var(--color-gray-1200);
  border-radius: var(--border-radius-lg);
  padding: var(--spacing-lg);
  border: 1px solid var(--color-gray-1100);
  display: flex;
  flex-direction: column;
  gap: var(--spacing-md);
  height: 100%;
}

.iconWrapper {
  width: 48px;
  height: 48px;
  border-radius: var(--border-radius-lg);
  background-color: var(--color-gray-1100);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: var(--spacing-sm);
}

.icon {
  width: 24px;
  height: 24px;
}

.advantageContent {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.advantageTitle {
  font-size: var(--font-size-lg);
  font-weight: var(--font-weight-bold);
  color: var(--text-on-primary);
  margin-bottom: var(--spacing-xs);
}

.advantageDescription {
  font-size: var(--font-size-sm);
  color: var(--color-gray-400);
  line-height: 1.5;
}

/* Middle Section Layout */
.middleSection {
  display: grid;
  grid-template-columns: 1.5fr 1fr;
  gap: var(--spacing-xl);
}

/* Execution Timeline */
.executionSection {
  display: flex;
  flex-direction: column;
}

.executionTimeline {
  background-color: var(--color-gray-1200);
  border-radius: var(--border-radius-lg);
  padding: var(--spacing-lg);
  border: 1px solid var(--color-gray-1100);
  display: flex;
  flex-direction: column;
  gap: var(--spacing-lg);
  height: 100%;
}

.executionPhase {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-md);
}

.phaseHeader {
  margin-bottom: var(--spacing-sm);
}

.phaseInfo {
  display: flex;
  align-items: center;
  gap: var(--spacing-sm);
  margin-bottom: var(--spacing-xs);
}

.phaseIndicator {
  width: 12px;
  height: 12px;
  border-radius: var(--border-radius-full);
}

.phaseName {
  font-size: var(--font-size-sm);
  color: var(--color-gray-400);
  font-weight: var(--font-weight-medium);
}

.phaseStatus {
  font-size: var(--font-size-xs);
  padding: var(--spacing-xxs) var(--spacing-sm);
  border-radius: var(--border-radius-full);
  background-color: var(--color-gray-1100);
  color: var(--color-gray-300);
  margin-left: auto;
  border: 1px solid transparent;
}

.phaseTitle {
  font-size: var(--font-size-lg);
  font-weight: var(--font-weight-bold);
  color: var(--text-on-primary);
  margin: 0;
}

.phaseActions {
  list-style: none;
  padding: 0;
  margin: 0;
}

.phaseAction {
  position: relative;
  padding-left: var(--spacing-lg);
  margin-bottom: var(--spacing-sm);
  color: var(--color-gray-400);
  font-size: var(--font-size-sm);
  line-height: 1.4;
}

.phaseAction::before {
  content: "•";
  position: absolute;
  left: 0;
  color: var(--color-primary-500);
}

/* Success Metrics */
.metricsSection {
  display: flex;
  flex-direction: column;
}

.metricsGrid {
  background-color: var(--color-gray-1200);
  border-radius: var(--border-radius-lg);
  padding: var(--spacing-lg);
  border: 1px solid var(--color-gray-1100);
  display: flex;
  flex-direction: column;
  gap: var(--spacing-lg);
  height: 100%;
}

.metricCard {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-md);
}

.metricHeader {
  display: flex;
  align-items: center;
  gap: var(--spacing-md);
}

.metricIconWrapper {
  width: 36px;
  height: 36px;
  border-radius: var(--border-radius-lg);
  background-color: var(--color-gray-1100);
  display: flex;
  align-items: center;
  justify-content: center;
}

.metricIcon {
  width: 18px;
  height: 18px;
}

.metricName {
  font-size: var(--font-size-lg);
  font-weight: var(--font-weight-bold);
  color: var(--text-on-primary);
}

.metricValues {
  display: flex;
  justify-content: space-between;
  padding-left: calc(36px + var(--spacing-md));
}

.targetValue, .currentValue {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xs);
}

.valueLabel {
  font-size: var(--font-size-xs);
  color: var(--color-gray-400);
  text-transform: uppercase;
  letter-spacing: 0.05em;
}

.value {
  font-size: var(--font-size-lg);
  font-weight: var(--font-weight-bold);
}

.currentValue .value {
  color: var(--color-gray-400);
}

/* Partnerships Section */
.partnershipsSection {
  margin-top: var(--spacing-sm);
}

.partnershipsGrid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: var(--spacing-lg);
}

.partnershipCard {
  background-color: var(--color-gray-1200);
  border-radius: var(--border-radius-lg);
  padding: var(--spacing-lg);
  border: 1px solid var(--color-gray-1100);
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: var(--spacing-md);
  height: 100%;
}

.partnershipIconWrapper {
  width: 40px;
  height: 40px;
  border-radius: var(--border-radius-lg);
  background-color: var(--color-gray-1100);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: var(--spacing-xs);
}

.partnershipIcon {
  width: 20px;
  height: 20px;
}

.partnershipTitle {
  font-size: var(--font-size-sm);
  color: var(--color-gray-300);
  line-height: 1.5;
  margin: 0;
}

/* Responsive Design */
@media (max-width: 1200px) {
  .advantagesGrid {
    grid-template-columns: repeat(3, 1fr);
    gap: var(--spacing-md);
  }
  
  .middleSection {
    grid-template-columns: 1fr;
    gap: var(--spacing-lg);
  }
  
  .partnershipsGrid {
    grid-template-columns: repeat(2, 1fr);
    gap: var(--spacing-md);
  }
  
  .metricValues {
    flex-direction: row;
    justify-content: space-between;
  }
}

@media (max-width: 992px) {
  .title {
    font-size: var(--font-size-3xl);
  }
  
  .subtitle {
    font-size: var(--font-size-lg);
    max-width: 90%;
  }
  
  .advantagesGrid {
    grid-template-columns: repeat(2, 1fr);
  }
  
  .sectionTitle {
    font-size: var(--font-size-lg);
  }
  
  .sectionTitle::before {
    height: 16px;
  }
  
  .advantageTitle, .phaseTitle, .metricName {
    font-size: var(--font-size-base);
  }
  
  .value {
    font-size: var(--font-size-base);
  }
}

@media (max-width: 768px) {
  .advantagesGrid {
    grid-template-columns: 1fr;
  }
  
  .title {
    font-size: var(--font-size-2xl);
  }
  
  .subtitle {
    font-size: var(--font-size-md);
    max-width: 100%;
  }
  
  .executionTimeline, .metricsGrid {
    padding: var(--spacing-md);
  }
  
  .partnershipsGrid {
    grid-template-columns: repeat(2, 1fr);
  }
  
  .iconWrapper, .partnershipIconWrapper {
    width: 32px;
    height: 32px;
  }
  
  .icon, .partnershipIcon {
    width: 16px;
    height: 16px;
  }
  
  .metricIconWrapper {
    width: 28px;
    height: 28px;
  }
  
  .metricIcon {
    width: 14px;
    height: 14px;
  }
  
  .metricValues {
    padding-left: calc(28px + var(--spacing-md));
  }
}

@media (max-width: 480px) {
  .container {
    padding: var(--spacing-sm);
  }
  
  .title {
    font-size: var(--font-size-xl);
  }
  
  .subtitle {
    font-size: var(--font-size-base);
  }
  
  .content {
    gap: var(--spacing-md);
  }
  
  .partnershipsGrid {
    grid-template-columns: 1fr;
  }
  
  .advantageDescription, .phaseAction, .partnershipTitle {
    font-size: var(--font-size-xs);
  }
  
  .metricValues {
    flex-direction: column;
    gap: var(--spacing-sm);
  }
  
  .executionPhase {
    gap: var(--spacing-sm);
  }
}