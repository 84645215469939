/* File: frontend/src/pages/UMS/UserPortfolio/components/PortfolioOverview/PortfolioOverview.module.css */

.container {
  padding: var(--spacing-md);
}

.section {
  margin-bottom: var(--spacing-lg);
  background-color: var(--bg-primary);
  border-radius: var(--border-radius-md);
  border: 1px solid var(--color-border);
  overflow: hidden;
}

.sectionHeader {
  padding: var(--spacing-sm) var(--spacing-md);
  background-color: var(--bg-secondary);
  border-bottom: 1px solid var(--color-border);
}

.sectionTitle {
  font-size: var(--font-size-md);
  font-weight: var(--font-weight-semibold);
  margin: 0;
}

.sectionContent {
  padding: var(--spacing-md);
}

.tabs {
  display: flex;
  background-color: var(--bg-secondary);
  border-radius: var(--border-radius-md);
  padding: 2px;
  margin-bottom: var(--spacing-md);
}

.tab {
  flex: 1;
  padding: var(--spacing-sm);
  background: none;
  border: none;
  border-radius: var(--border-radius-sm);
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-medium);
  color: var(--text-secondary);
  cursor: pointer;
  transition: all var(--transition-fast);
}

.tab:hover {
  color: var(--text-primary);
}

.activeTab {
  background-color: var(--bg-primary);
  color: var(--text-primary);
  box-shadow: var(--shadow-sm);
}

.chartContainer {
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.insightCard {
  display: flex;
  align-items: center;
  gap: var(--spacing-md);
  padding: var(--spacing-md);
  margin-bottom: var(--spacing-lg);
  background-color: var(--color-info-100);
  border-radius: var(--border-radius-md);
  border: 1px solid var(--color-info-300);
}

.insightIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: var(--color-info);
  color: white;
  flex-shrink: 0;
}

.insightContent {
  flex: 1;
}

.insightContent p {
  margin: 0;
  color: var(--color-info-900);
  font-size: var(--font-size-sm);
  line-height: 1.5;
}

@media (max-width: 768px) {
  .tabs {
    flex-direction: column;
    gap: 2px;
  }
  
  .tab {
    border-radius: var(--border-radius-sm);
  }
  
  .chartContainer {
    height: 300px;
  }
  
  .insightCard {
    flex-direction: column;
    text-align: center;
  }
}