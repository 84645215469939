/* src/components/KnowledgeTransfer/RecentKnowledgeTable.module.css */
.recentKnowledgeTable {
    background-color: #fff;
    border-radius: 16px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    overflow: hidden;
  }
  
  .tableHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    background-color: #f7f7f8;
  }
  
  .searchInput {
    display: flex;
    align-items: center;
  }
  
  .searchInput input {
    padding: 8px 12px;
    border: 1px solid #e9eaec;
    border-radius: 8px 0 0 8px;
    font-size: 14px;
  }
  
  .searchButton {
    background-color: #fff;
    border: 1px solid #e9eaec;
    border-left: none;
    border-radius: 0 8px 8px 0;
    padding: 8px 12px;
    cursor: pointer;
  }
  
  .table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .table th,
  .table td {
    padding: 12px 16px;
    text-align: left;
    border-bottom: 1px solid #f7f7f8;
  }
  
  .table th {
    background-color: #f7f7f8;
    font-weight: 600;
    color: #464a53;
  }
  
  .actionButton {
    margin-right: 8px;
    padding: 6px 12px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 12px;
    font-weight: 600;
  }
  
  .actionButton:nth-child(1) {
    background-color: #effaf6;
    color: #38c793;
  }
  
  .actionButton:nth-child(2) {
    background-color: #eff4ff;
    color: #161d55;
  }
  
  .actionButton:nth-child(3) {
    background-color: #fff1f0;
    color: #ff4d4f;
  }
  
  .pagination {
    display: flex;
    justify-content: center;
    padding: 20px;
  }