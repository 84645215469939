/* File: frontend/src/components/Footer/Footer.module.css */

.footer {
  background-color: var(--color-black);
  color: var(--color-white);
  font-family: var(--font-primary);
  padding: var(--spacing-xl) var(--spacing-md) var(--spacing-md);
  z-index: var(--z-index-header);
}

.footerContent {
  max-width: 1200px;
  margin: 0 auto;
}

.topSection {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: var(--spacing-xl);
}

.logoSection {
  flex: 1;
  max-width: 250px;
}

.logo {
  max-width: 150px;
  margin-bottom: var(--spacing-md);
}

.socialIcons {
  display: flex;
  gap: var(--spacing-sm);
  margin-top: var(--spacing-md);
}

.socialIcons img {
  width: 24px;
  height: 24px;
}

.linksSection {
  flex: 2;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.linkColumn {
  min-width: 120px;
}

.linkColumn h3 {
  font-size: var(--font-size-base);
  font-weight: var(--font-weight-semibold);
  color: var(--color-white);
  margin-bottom: var(--spacing-md);
}

.linkColumn ul {
  list-style-type: none;
  padding: 0;
}

.linkColumn li {
  margin-bottom: var(--spacing-sm);
}

.linkColumn a {
  font-family: var(--font-secondary);
  font-size: var(--font-size-sm);
  color: var(--color-gray-300);
  text-decoration: none;
  transition: color var(--transition-normal);
}

.linkColumn a:hover {
  color: var(--color-white);
}

.subscribeSection {
  flex: 1;
  max-width: 300px;
}

.subscribeSection h3 {
  font-size: var(--font-size-xl);
  font-weight: var(--font-weight-semibold);
  color: var(--color-white);
  margin-bottom: var(--spacing-sm);
}

.subscribeSection p {
  font-family: var(--font-secondary);
  font-size: var(--font-size-sm);
  line-height: 1.5;
  color: var(--color-gray-300);
  margin-bottom: var(--spacing-md);
}

.subscribeForm {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-sm);
}

.inputWrapper {
  display: flex;
  align-items: center;
  padding: var(--spacing-sm);
  background-color: var(--color-gray-600);
  border: 1px solid var(--color-gray-500);
  border-radius: var(--border-radius-md);
}

.inputIcon {
  width: 14px;
  height: 14px;
  margin-right: var(--spacing-sm);
}

.subscribeForm input {
  flex-grow: 1;
  border: none;
  background: transparent;
  font-family: var(--font-secondary);
  font-size: var(--font-size-sm);
  color: var(--color-white);
}

.subscribeForm input::placeholder {
  color: var(--color-gray-300);
}

.bottomSection {
  border-top: 1px solid var(--color-gray-500);
  margin-top: var(--spacing-xl);
  padding-top: var(--spacing-md);
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: var(--spacing-md);
  position: relative;
}

.languageSelector {
  flex: 0 0 auto;
}

.legalLinks {
  display: flex;
  gap: var(--spacing-md);
  align-items: center;
}

.legalLinks a {
  color: var(--color-gray-300);
  text-decoration: none;
  font-size: var(--font-size-sm);
  transition: color var(--transition-normal);
}

.legalLinks a:hover {
  color: var(--color-white);
}

.divider {
  width: 1px;
  height: 16px;
  background-color: var(--color-gray-500);
}

/* Legal Information Section */
.legalInfoSection {
  margin-top: var(--spacing-xl);
  padding: var(--spacing-md);
  background-color: rgba(255, 255, 255, 0.05);
  border-radius: var(--border-radius-md);
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.legalInfoSection h3 {
  font-size: var(--font-size-md);
  font-weight: var(--font-weight-semibold);
  margin-bottom: var(--spacing-sm);
  color: var(--color-white);
}

.legalInfoSection h4 {
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-semibold);
  margin-bottom: var(--spacing-xxs);
  color: var(--color-gray-300);
}

.legalInfoSection p {
  font-size: var(--font-size-xs);
  line-height: 1.5;
  color: var(--color-gray-400);
  margin-bottom: var(--spacing-md);
}

.legalGrid {
  display: flex;
  gap: var(--spacing-xl);
  margin: var(--spacing-sm) 0;
}

.legalColumn {
  flex: 1;
}

.legalContact {
  text-align: center;
  margin-top: var(--spacing-md);
  padding-top: var(--spacing-sm);
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  font-style: italic;
  color: var(--color-gray-300);
}

@media (max-width: 1024px) {
  .topSection {
    flex-direction: column;
  }

  .logoSection, .subscribeSection {
    max-width: 100%;
  }

  .linksSection {
    width: 100%;
    justify-content: space-between;
  }
}

@media (max-width: 768px) {
  .bottomSection {
    flex-direction: column;
    align-items: flex-start;
  }

  .languageSelector {
    order: -1;
    margin-bottom: var(--spacing-md);
  }

  .bottomSection .languageSelector {
    position: static; /* This ensures the dropdown can position relative to viewport */
  }

  .bottomSection .languageSelector :global(.Dropdown_options) {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    z-index: var(--z-index-modal);
  }

  .legalLinks {
    flex-direction: column;
    align-items: flex-start;
    gap: var(--spacing-sm);
  }

  .divider {
    display: none;
  }

  .legalGrid {
    flex-direction: column;
    gap: var(--spacing-sm);
  }
  
  .legalInfoSection h3 {
    font-size: var(--font-size-base);
  }
  
  .legalInfoSection h4 {
    font-size: var(--font-size-xs);
    margin-top: var(--spacing-sm);
  }
}

@media (max-width: 480px) {
  .linksSection {
    flex-direction: column;
    gap: var(--spacing-lg);
  }
}