/* File: frontend/src/pages/InvestorPitch/components/slides/SecuritySlide/DataProtection.module.css */

.container {
  background-color: var(--color-gray-1200);
  border-radius: var(--border-radius-lg);
  padding: var(--spacing-lg);
  border: 1px solid var(--color-gray-1100);
  height: 100%;
}

.features {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-md);
  height: 100%;
}

.feature {
  display: flex;
  align-items: flex-start;
  gap: var(--spacing-md);
  padding: var(--spacing-md);
  border-radius: var(--border-radius-lg);
  background-color: var(--color-gray-1100);
  border: 1px solid var(--color-gray-1000);
}

.iconWrapper {
  width: 48px;
  height: 48px;
  border-radius: var(--border-radius-lg);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}

.icon {
  width: 24px;
  height: 24px;
  color: var(--color-white);
}

.content {
  flex: 1;
}

.title {
  font-size: var(--font-size-lg);
  font-weight: var(--font-weight-bold);
  color: var(--text-on-primary);
  margin-bottom: var(--spacing-xs);
  margin-top: 0;
}

.description {
  font-size: var(--font-size-sm);
  color: var(--color-gray-400);
  line-height: 1.5;
  margin: 0;
}

@media (max-width: 1200px) {
  .container {
    padding: var(--spacing-md);
  }
  
  .feature {
    padding: var(--spacing-sm);
  }
  
  .title {
    font-size: var(--font-size-base);
  }
}

@media (max-width: 992px) {
  .iconWrapper {
    width: 40px;
    height: 40px;
  }
  
  .icon {
    width: 20px;
    height: 20px;
  }
}

@media (max-width: 768px) {
  .container {
    padding: var(--spacing-sm);
  }
  
  .feature {
    padding: var(--spacing-xs);
    gap: var(--spacing-sm);
  }
  
  .iconWrapper {
    width: 32px;
    height: 32px;
  }
  
  .icon {
    width: 16px;
    height: 16px;
  }
  
  .title {
    font-size: var(--font-size-sm);
  }
  
  .description {
    font-size: var(--font-size-xs);
  }
}