/* File: frontend/src/pages/QMS/QuoteDetail/components/QuoteOverview/QuoteOverview.module.css */

.container {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-md);
}

.grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: var(--spacing-md);
}

.card {
  background-color: var(--bg-primary);
  border-radius: var(--border-radius-md);
  border: 1px solid var(--color-border);
  overflow: hidden;
}

.cardTitle {
  font-size: var(--font-size-md);
  font-weight: var(--font-weight-semibold);
  padding: var(--spacing-sm);
  background-color: var(--bg-secondary);
  border-bottom: 1px solid var(--color-border);
  margin: 0;
}

.cardContent {
  padding: var(--spacing-sm);
}

.infoGroup {
  display: flex;
  margin-bottom: var(--spacing-xs);
}

.infoGroup:last-child {
  margin-bottom: 0;
}

.infoLabel {
  font-weight: var(--font-weight-medium);
  color: var(--text-secondary);
  width: 150px;
  flex-shrink: 0;
}

.infoValue {
  color: var(--text-primary);
  word-break: break-word;
}

.tag {
  display: inline-block;
  background-color: var(--bg-secondary);
  padding: 2px 8px;
  border-radius: 4px;
  font-size: var(--font-size-xs);
  font-weight: var(--font-weight-medium);
}

.completed {
  color: var(--color-success);
  font-weight: var(--font-weight-semibold);
}

.collecting_data {
  color: var(--color-info);
  font-weight: var(--font-weight-semibold);
}

.abandoned, .abandoned_by_system {
  color: var(--color-error);
  font-weight: var(--font-weight-semibold);
}

.locked {
  color: var(--text-secondary);
  font-weight: var(--font-weight-semibold);
}

.premiumGrid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: var(--spacing-md);
}

.premiumCard {
  padding: var(--spacing-sm);
  background-color: var(--bg-secondary);
  border-radius: var(--border-radius-sm);
}

.finalPremium {
  font-weight: var(--font-weight-bold);
  font-size: var(--font-size-lg);
  color: var(--color-success);
}

.noData {
  color: var(--text-secondary);
  font-style: italic;
  text-align: center;
  padding: var(--spacing-md);
}

.verificationGrid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: var(--spacing-md);
}

.verificationItem {
  display: flex;
  flex-direction: column;
}

.verificationLabel {
  font-weight: var(--font-weight-medium);
  color: var(--text-secondary);
  margin-bottom: var(--spacing-xxs);
}

.verificationStatus {
  font-weight: var(--font-weight-semibold);
  padding: 4px 8px;
  border-radius: 4px;
  display: inline-block;
  text-align: center;
}

.verified {
  background-color: var(--color-success-bg);
  color: var(--color-success);
}

.notVerified {
  background-color: var(--color-error-bg);
  color: var(--color-error);
}

.verificationValue {
  color: var(--text-primary);
}

@media (max-width: 768px) {
  .grid, .premiumGrid, .verificationGrid {
    grid-template-columns: 1fr;
  }
  
  .infoGroup {
    flex-direction: column;
  }
  
  .infoLabel {
    width: 100%;
    margin-bottom: var(--spacing-xxxs);
  }
}