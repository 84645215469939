/* File: frontend/src/pages/InvestorPitch/components/slides/GlobalHiringSlide/LocationCard.module.css */

.container {
    background-color: var(--color-gray-1200);
    border-radius: var(--border-radius-lg);
    padding: var(--spacing-xl);
    border: 1px solid var(--color-gray-1100);
    display: flex;
    flex-direction: column;
    gap: var(--spacing-lg);
  }
  
  .header {
    display: flex;
    align-items: center;
    gap: var(--spacing-md);
  }
  
  .iconWrapper {
    width: 48px;
    height: 48px;
    border-radius: var(--border-radius-lg);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .icon {
    width: 24px;
    height: 24px;
    color: var(--color-white);
  }
  
  .location {
    flex: 1;
  }
  
  .city {
    font-size: var(--font-size-xl);
    font-weight: var(--font-weight-bold);
    color: var(--text-on-primary);
  }
  
  .country {
    font-size: var(--font-size-sm);
    color: var(--color-gray-400);
  }
  
  .timeline {
    display: flex;
    align-items: center;
    gap: var(--spacing-sm);
    color: var(--color-gray-400);
    font-size: var(--font-size-sm);
  }
  
  .timelineIcon {
    width: 16px;
    height: 16px;
  }
  
  .headcount {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-sm);
  }
  
  .headcountHeader {
    display: flex;
    align-items: center;
    gap: var(--spacing-sm);
    color: var(--text-on-primary);
    font-size: var(--font-size-sm);
  }
  
  .headcountIcon {
    width: 16px;
    height: 16px;
  }
  
  .roles {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-sm);
  }
  
  .role {
    font-size: var(--font-size-sm);
    color: var(--color-gray-400);
    padding-left: var(--spacing-md);
    position: relative;
  }
  
  .role::before {
    content: "•";
    position: absolute;
    left: 0;
    color: var(--color-primary-500);
  }