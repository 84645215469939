/* File: frontend/src/components/Dashboard/WelcomeCard.module.css */

.welcomeCard {
  background-color: var(--color-white);
  border-radius: var(--border-radius-xl);
  padding: var(--spacing-md);
  margin-bottom: var(--spacing-md);
  box-shadow: var(--shadow-card);
}

.welcomeTitle {
  font-size: var(--font-size-2xl);
  font-weight: var(--font-weight-semibold);
  color: var(--text-primary);
  margin-bottom: var(--spacing-xxs);
  font-family: var(--font-primary);
}

.welcomeSubtitle {
  font-size: var(--font-size-sm);
  color: var(--text-tertiary);
  margin-bottom: var(--spacing-md);
  font-family: var(--font-primary);
}

/* Grid layout with 3 columns */
.cardContent {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: var(--spacing-md);
}

/* Base section styling */
.section {
  position: relative;
  border-radius: var(--border-radius-xl);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--spacing-md);
  box-sizing: border-box;
  gap: var(--spacing-xxs);
  min-width: 0;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.section:hover {
  transform: translateY(-2px);
  box-shadow: var(--shadow-md);
}

/* Specific section background colors based on section type */
/* Insurance Products - Green theme */
.section[data-section="build_insurance_products_title"] {
  background: linear-gradient(180deg, rgba(203, 245, 229, 0.25), rgba(220, 255, 242, 0.25));
}

/* New Business - Orange theme */
.section[data-section="new_business_title"] {
  background: linear-gradient(180deg, rgba(255, 218, 194, 0.25), rgba(255, 236, 224, 0.25));
}

/* Renewal - Blue theme */
.section[data-section="renewal_title"] {
  background: linear-gradient(180deg, rgba(219, 230, 254, 0.25), rgba(235, 241, 255, 0.25));
}

/* Customer Management - Purple theme */
.section[data-section="customer_management_title"] {
  background: linear-gradient(180deg, rgba(233, 213, 255, 0.25), rgba(243, 232, 255, 0.25));
}

/* Operations Management - Yellow theme */
.section[data-section="operations_management_title"] {
  background: linear-gradient(180deg, rgba(255, 236, 179, 0.25), rgba(255, 245, 213, 0.25));
}

/* Business Intelligence - Teal theme */
.section[data-section="business_intelligence_title"] {
  background: linear-gradient(180deg, rgba(179, 236, 236, 0.25), rgba(213, 245, 245, 0.25));
}

/* Admin Tools - Gray theme */
.section[data-section="admin_tools_title"] {
  background: linear-gradient(180deg, rgba(224, 224, 235, 0.25), rgba(240, 240, 245, 0.25));
}

/* AI Tools - Pink theme */
.section[data-section="ai_tools_title"] {
  background: linear-gradient(180deg, rgba(255, 213, 227, 0.25), rgba(255, 232, 240, 0.25));
}

/* Advanced Analytics - Cyan theme */
.section[data-section="advanced_analytics_title"] {
  background: linear-gradient(180deg, rgba(179, 236, 255, 0.25), rgba(213, 245, 255, 0.25));
}

.sectionTitle {
  font-size: var(--font-size-lg);
  font-weight: var(--font-weight-semibold);
  color: var(--text-primary);
  margin-bottom: var(--spacing-xs);
  display: flex;
  align-items: center;
  gap: var(--spacing-xs);
  font-family: var(--font-primary);
}

/* Section title icons */
.sectionTitle svg {
  color: var(--color-primary);
  margin-right: var(--spacing-xs);
}

/* Section title icons with theme colors */
.section[data-section="build_insurance_products_title"] .sectionTitle svg {
  color: #38C793;
}

.section[data-section="new_business_title"] .sectionTitle svg {
  color: #F17B2C;
}

.section[data-section="renewal_title"] .sectionTitle svg {
  color: #375DFB;
}

.section[data-section="customer_management_title"] .sectionTitle svg {
  color: #9966CC;
}

.section[data-section="operations_management_title"] .sectionTitle svg {
  color: #F2AE40;
}

.section[data-section="business_intelligence_title"] .sectionTitle svg {
  color: #33CCCC;
}

.section[data-section="admin_tools_title"] .sectionTitle svg {
  color: #8899AA;
}

.section[data-section="ai_tools_title"] .sectionTitle svg {
  color: #FF6699;
}

.section[data-section="advanced_analytics_title"] .sectionTitle svg {
  color: #33AAFF;
}

/* Section description */
.sectionDescription {
  font-size: var(--font-size-xs);
  color: var(--text-tertiary);
  margin-bottom: var(--spacing-sm);
  padding: 0 var(--spacing-xs);
  line-height: 1.4;
  font-family: var(--font-primary);
}

.sectionItems {
  position: relative;
  width: 100%;
  z-index: 1;
}

.sectionItem {
  text-decoration: none;
  margin-bottom: var(--spacing-sm);
  display: block;
}

.sectionItemContent {
  align-self: stretch;
  box-shadow: var(--shadow-card);
  border-radius: var(--border-radius-lg);
  background-color: var(--color-white);
  border: 1px solid var(--color-border);
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: var(--spacing-sm);
  margin-bottom: var(--spacing-sm);
  margin-left: var(--spacing-xl);
  transition: var(--transition-fast);
  min-height: 70px;
}

.sectionItemTextContainer {
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding-right: var(--spacing-xs);
}

.sectionItemText {
  font-size: var(--font-size-sm);
  color: var(--text-primary);
  line-height: var(--line-height-tight);
  font-weight: var(--font-weight-medium);
  font-family: var(--font-primary);
  margin-bottom: 2px;
}

.sectionItemDescription {
  font-size: var(--font-size-xs);
  color: var(--text-tertiary);
  line-height: 1.4;
  font-weight: var(--font-weight-regular);
  font-family: var(--font-primary);
  max-width: 210px;
  padding-left: 1px;
}

/* Base dot styling */
.dotIcon {
  position: absolute;
  left: 24px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.11);
  border: 1.5px solid var(--color-white);
  box-sizing: border-box;
  z-index: 1;
}

/* Base dot positioning */
.section .dotIcon:nth-child(4) { top: 90px; }
.section .dotIcon:nth-child(5) { top: 153px; }
.section .dotIcon:nth-child(6) { top: 216px; }

/* Matching dot colors to section themes */
.section[data-section="build_insurance_products_title"] .dotIcon {
  background-color: #38C793;
}

.section[data-section="new_business_title"] .dotIcon {
  background-color: #F17B2C;
}

.section[data-section="renewal_title"] .dotIcon {
  background-color: #375DFB;
}

.section[data-section="customer_management_title"] .dotIcon {
  background-color: #9966CC;
}

.section[data-section="operations_management_title"] .dotIcon {
  background-color: #F2AE40;
}

.section[data-section="business_intelligence_title"] .dotIcon {
  background-color: #33CCCC;
}

.section[data-section="admin_tools_title"] .dotIcon {
  background-color: #8899AA;
}

.section[data-section="ai_tools_title"] .dotIcon {
  background-color: #FF6699;
}

.section[data-section="advanced_analytics_title"] .dotIcon {
  background-color: #33AAFF;
}

/* Section item icon base styling */
.sectionItemIcon {
  width: var(--spacing-md);
  height: var(--spacing-md);
  border-radius: var(--border-radius-sm);
  background-color: var(--color-white);
  border: 1.5px solid var(--color-primary);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: var(--transition-fast);
}

.sectionItemIcon svg {
  color: var(--color-primary);
  transition: var(--transition-fast);
}

/* Section item hover effects */
.sectionItem:hover .sectionItemContent {
  border-color: var(--color-primary);
  box-shadow: var(--shadow-md);
  transform: translateX(2px);
}

.sectionItem:hover .sectionItemIcon {
  background-color: var(--color-primary);
}

.sectionItem:hover .sectionItemIcon svg {
  color: var(--color-white);
  transform: translateX(2px);
}

/* Section item icons with theme colors */
.section[data-section="build_insurance_products_title"] .sectionItemIcon {
  border-color: #38C793;
}
.section[data-section="build_insurance_products_title"] .sectionItemIcon svg {
  color: #38C793;
}
.section[data-section="build_insurance_products_title"] .sectionItem:hover .sectionItemIcon {
  background-color: #38C793;
}
.section[data-section="build_insurance_products_title"] .sectionItem:hover .sectionItemContent {
  border-color: #38C793;
}

.section[data-section="new_business_title"] .sectionItemIcon {
  border-color: #F17B2C;
}
.section[data-section="new_business_title"] .sectionItemIcon svg {
  color: #F17B2C;
}
.section[data-section="new_business_title"] .sectionItem:hover .sectionItemIcon {
  background-color: #F17B2C;
}
.section[data-section="new_business_title"] .sectionItem:hover .sectionItemContent {
  border-color: #F17B2C;
}

.section[data-section="renewal_title"] .sectionItemIcon {
  border-color: #375DFB;
}
.section[data-section="renewal_title"] .sectionItemIcon svg {
  color: #375DFB;
}
.section[data-section="renewal_title"] .sectionItem:hover .sectionItemIcon {
  background-color: #375DFB;
}
.section[data-section="renewal_title"] .sectionItem:hover .sectionItemContent {
  border-color: #375DFB;
}

.section[data-section="customer_management_title"] .sectionItemIcon {
  border-color: #9966CC;
}
.section[data-section="customer_management_title"] .sectionItemIcon svg {
  color: #9966CC;
}
.section[data-section="customer_management_title"] .sectionItem:hover .sectionItemIcon {
  background-color: #9966CC;
}
.section[data-section="customer_management_title"] .sectionItem:hover .sectionItemContent {
  border-color: #9966CC;
}

.section[data-section="operations_management_title"] .sectionItemIcon {
  border-color: #F2AE40;
}
.section[data-section="operations_management_title"] .sectionItemIcon svg {
  color: #F2AE40;
}
.section[data-section="operations_management_title"] .sectionItem:hover .sectionItemIcon {
  background-color: #F2AE40;
}
.section[data-section="operations_management_title"] .sectionItem:hover .sectionItemContent {
  border-color: #F2AE40;
}

.section[data-section="business_intelligence_title"] .sectionItemIcon {
  border-color: #33CCCC;
}
.section[data-section="business_intelligence_title"] .sectionItemIcon svg {
  color: #33CCCC;
}
.section[data-section="business_intelligence_title"] .sectionItem:hover .sectionItemIcon {
  background-color: #33CCCC;
}
.section[data-section="business_intelligence_title"] .sectionItem:hover .sectionItemContent {
  border-color: #33CCCC;
}

.section[data-section="admin_tools_title"] .sectionItemIcon {
  border-color: #8899AA;
}
.section[data-section="admin_tools_title"] .sectionItemIcon svg {
  color: #8899AA;
}
.section[data-section="admin_tools_title"] .sectionItem:hover .sectionItemIcon {
  background-color: #8899AA;
}
.section[data-section="admin_tools_title"] .sectionItem:hover .sectionItemContent {
  border-color: #8899AA;
}

.section[data-section="ai_tools_title"] .sectionItemIcon {
  border-color: #FF6699;
}
.section[data-section="ai_tools_title"] .sectionItemIcon svg {
  color: #FF6699;
}
.section[data-section="ai_tools_title"] .sectionItem:hover .sectionItemIcon {
  background-color: #FF6699;
}
.section[data-section="ai_tools_title"] .sectionItem:hover .sectionItemContent {
  border-color: #FF6699;
}

.section[data-section="advanced_analytics_title"] .sectionItemIcon {
  border-color: #33AAFF;
}
.section[data-section="advanced_analytics_title"] .sectionItemIcon svg {
  color: #33AAFF;
}
.section[data-section="advanced_analytics_title"] .sectionItem:hover .sectionItemIcon {
  background-color: #33AAFF;
}
.section[data-section="advanced_analytics_title"] .sectionItem:hover .sectionItemContent {
  border-color: #33AAFF;
}

.vectorIcon {
  position: absolute;
  width: 200%;
  height: 200%;
  top: -50%;
  left: -50%;
  opacity: 0.05;
  z-index: 0;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  pointer-events: none;
}

/* Responsive styles */
/* Large screens */
@media (min-width: 1025px) and (max-width: 1280px) {
  .sectionItemContent {
    min-height: 65px;
    padding: var(--spacing-xs) var(--spacing-sm);
  }
  
  .sectionItemDescription {
    max-width: 180px;
  }
}

/* Medium screens */
@media (max-width: 1024px) {
  .sectionItemContent {
    min-height: 60px;
    padding: var(--spacing-xs) var(--spacing-sm);
  }
  
  .sectionItemDescription {
    max-width: 170px;
    font-size: calc(var(--font-size-xs) - 1px);
    line-height: 1.35;
  }
}

/* Tablet breakpoint */
@media (min-width: 769px) and (max-width: 1024px) {
  .cardContent {
    grid-template-columns: repeat(2, 1fr);
  }

  .welcomeTitle {
    font-size: var(--font-size-2xl);
  }

  .welcomeSubtitle {
    max-width: 80%;
  }

  .sectionTitle {
    height: var(--font-size-3xl);
  }
  
  .sectionDescription {
    font-size: var(--font-size-xs);
    margin-bottom: var(--spacing-xs);
  }

  .sectionItemContent {
    margin-left: var(--spacing-2xl);
  }
  
  .section .dotIcon:nth-child(4) { top: 82px; }
  .section .dotIcon:nth-child(5) { top: 145px; }
  .section .dotIcon:nth-child(6) { top: 208px; }
}

/* Mobile breakpoint */
@media (max-width: 768px) {
  .cardContent {
    grid-template-columns: 1fr;
  }
  
  .welcomeTitle {
    font-size: var(--font-size-xl);
  }
  
  .welcomeSubtitle {
    font-size: var(--font-size-xs);
  }
  
  .section {
    padding: var(--spacing-sm);
    width: 100%;
  }
  
  .sectionDescription {
    font-size: var(--font-size-xxs);
    margin-bottom: var(--spacing-xs);
  }

  .sectionItemContent {
    min-height: 55px;
    padding: 8px 12px;
  }
  
  .sectionItemDescription {
    max-width: 150px;
    font-size: calc(var(--font-size-xs) - 2px);
    line-height: 1.3;
  }

  .sectionItemTextContainer {
    gap: 2px;
  }
  
  .section .dotIcon:nth-child(4) { top: 70px; }
  .section .dotIcon:nth-child(5) { top: 123px; }
  .section .dotIcon:nth-child(6) { top: 176px; }
}

/* Small mobile breakpoint */
@media (max-width: 480px) {
  .welcomeCard {
    padding: var(--spacing-sm);
    margin-bottom: var(--spacing-sm);
    border-radius: var(--border-radius-lg);
  }

  .welcomeTitle {
    font-size: var(--font-size-xl);
  }

  .welcomeSubtitle {
    font-size: var(--font-size-xs);
    margin-bottom: var(--spacing-sm);
  }

  .section {
    padding: var(--spacing-xs);
  }

  .sectionTitle {
    font-size: var(--font-size-md);
    margin-bottom: var(--spacing-sm);
  }
  
  .sectionDescription {
    font-size: var(--font-size-xxs);
    padding: 0;
  }

  .sectionItemContent {
    min-height: 50px;
    padding: 6px 10px;
    margin-left: var(--spacing-xl);
  }
  
  .sectionItemDescription {
    max-width: 130px;
    font-size: var(--font-size-xxs);
    line-height: 1.25;
  }

  .sectionItemText {
    font-size: var(--font-size-xs);
  }

  .sectionItemIcon {
    width: var(--spacing-sm);
    height: var(--spacing-sm);
  }
}

/* Extra small mobile breakpoint */
@media (max-width: 375px) {
  .welcomeCard {
    padding: var(--spacing-xs);
  }

  .cardContent {
    gap: var(--spacing-xs);
  }

  .section {
    padding: var(--spacing-xxs) var(--spacing-xs);
  }
}