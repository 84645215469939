/* File: frontend/src/pages/PropertyDetail/components/PropertyRisk/PropertyRisk.module.css */

.container {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-md);
}

.grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: var(--spacing-md);
}

.card {
  background-color: var(--bg-primary);
  border-radius: var(--border-radius-md);
  border: 1px solid var(--color-border);
  overflow: hidden;
}

.cardTitle {
  font-size: var(--font-size-md);
  font-weight: var(--font-weight-semibold);
  padding: var(--spacing-sm);
  background-color: var(--bg-secondary);
  border-bottom: 1px solid var(--color-border);
  margin: 0;
}

.cardContent {
  padding: var(--spacing-sm);
}

.riskAssessment {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: var(--spacing-md);
}

.riskScore {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: var(--spacing-lg);
}

.scoreValue {
  font-size: var(--font-size-4xl);
  font-weight: var(--font-weight-bold);
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin-bottom: var(--spacing-sm);
}

.riskLow {
  background-color: var(--color-success-bg);
  color: var(--color-success);
}

.riskMediumLow {
  background-color: var(--color-success-light-bg);
  color: var(--color-success-dark);
}

.riskMedium {
  background-color: var(--color-warning-bg);
  color: var(--color-warning);
}

.riskMediumHigh {
  background-color: var(--color-warning-light-bg);
  color: var(--color-warning-dark);
}

.riskHigh {
  background-color: var(--color-error-bg);
  color: var(--color-error);
}

.scoreLabel {
  font-size: var(--font-size-md);
  font-weight: var(--font-weight-semibold);
  color: var(--text-primary);
}

.riskDetails {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xs);
}

.riskDetail {
  display: flex;
  justify-content: space-between;
}

.riskDetailLabel {
  font-weight: var(--font-weight-medium);
  color: var(--text-secondary);
}

.riskDetailValue {
  color: var(--text-primary);
}

.zoneInfo {
  padding: var(--spacing-md);
}

.zoneGrid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: var(--spacing-md);
}

.zoneItem {
  display: flex;
  flex-direction: column;
}

.zoneLabel {
  font-weight: var(--font-weight-medium);
  color: var(--text-secondary);
  margin-bottom: var(--spacing-xxs);
}

.zoneValue {
  color: var(--text-primary);
  font-weight: var(--font-weight-semibold);
}

.additionalZones {
  margin-top: var(--spacing-lg);
}

.additionalZonesTitle {
  font-size: var(--font-size-base);
  font-weight: var(--font-weight-semibold);
  margin-bottom: var(--spacing-md);
  color: var(--text-primary);
}

.riskFactorsGrid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: var(--spacing-md);
}

.riskFactorItem {
  display: flex;
  flex-direction: column;
}

.riskFactorLabel {
  font-weight: var(--font-weight-medium);
  color: var(--text-secondary);
  margin-bottom: var(--spacing-xxs);
}

.riskFactorValue {
  color: var(--text-primary);
  font-weight: var(--font-weight-semibold);
}

.riskMap {
  background-color: var(--bg-primary);
  border-radius: var(--border-radius-md);
  border: 1px solid var(--color-border);
  overflow: hidden;
}

.riskMapTitle {
  font-size: var(--font-size-md);
  font-weight: var(--font-weight-semibold);
  padding: var(--spacing-sm);
  background-color: var(--bg-secondary);
  border-bottom: 1px solid var(--color-border);
  margin: 0;
}

.riskMapContent {
  padding: var(--spacing-md);
}

.riskMapPlaceholder {
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--bg-secondary);
  border-radius: var(--border-radius-md);
  color: var(--text-secondary);
  font-style: italic;
}

.noData {
  color: var(--text-secondary);
  font-style: italic;
  text-align: center;
  padding: var(--spacing-md);
}

@media (max-width: 992px) {
  .riskFactorsGrid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .grid, .zoneGrid {
    grid-template-columns: 1fr;
  }
  
  .riskFactorsGrid {
    grid-template-columns: 1fr;
  }
}