/* /charts/v2/types/LineChart.module.css */
.lineChartContainer {
    width: 100%;
    background: var(--color-white);
    border-radius: var(--border-radius-lg);
    padding: var(--spacing-lg);
    box-shadow: var(--shadow-card);
}

.tooltip {
    background-color: var(--color-white);
    border: 1px solid var(--color-border);
    padding: var(--spacing-md);
    border-radius: var(--border-radius-md);
    box-shadow: var(--shadow-lg);
    min-width: 150px;
}

.tooltipLabel {
    font-family: var(--font-primary);
    font-weight: var(--font-weight-semibold);
    margin-bottom: var(--spacing-xs);
    color: var(--text-primary);
    font-size: var(--font-size-sm);
    line-height: var(--line-height-snug);
}

.tooltipRow {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: var(--spacing-sm);
    margin: var(--spacing-xxs) 0;
    font-size: var(--font-size-sm);
    line-height: var(--line-height-snug);
}

.tooltipMetric {
    font-weight: var(--font-weight-medium);
    color: var(--text-secondary);
}

.tooltipValue {
    font-family: var(--font-primary);
    font-weight: var(--font-weight-semibold);
    color: var(--text-primary);
}

.insightsContainer {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: var(--spacing-lg);
    margin-top: var(--spacing-xl);
    padding-top: var(--spacing-lg);
    border-top: 1px solid var(--color-border);
}

.insightCard {
    padding: var(--spacing-lg);
    background: var(--color-white);
    border-radius: var(--border-radius-lg);
    border: 1px solid var(--color-border);
    box-shadow: var(--shadow-sm);
    transition: transform var(--transition-fast), box-shadow var(--transition-fast);
}

.insightCard:hover {
    transform: translateY(-2px);
    box-shadow: var(--shadow-md);
}

.insightTitle {
    font-family: var(--font-primary);
    font-size: var(--font-size-md);
    font-weight: var(--font-weight-semibold);
    color: var(--text-primary);
    margin-bottom: var(--spacing-md);
    line-height: var(--line-height-snug);
}

.insightList {
    list-style: none;
    padding: 0;
    margin: 0;
}

.insightItem {
    display: flex;
    align-items: flex-start;
    padding: var(--spacing-xs) 0;
    color: var(--text-secondary);
    font-size: var(--font-size-sm);
    line-height: var(--line-height-normal);
}

.insightItem::before {
    content: "•";
    color: var(--color-primary);
    font-weight: var(--font-weight-bold);
    margin-right: var(--spacing-sm);
    margin-top: 2px;
}