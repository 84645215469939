/* File: frontend/src/pages/InvestorPitch/components/navigation/SlideNavigation.module.css */

.navigation {
  position: fixed;
  top: 50%;
  left: 0;
  right: 0;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;
  pointer-events: none;
  z-index: var(--z-index-fixed);
}

.navButton {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  border-radius: var(--border-radius-full);
  background-color: rgba(20, 21, 26, 0.8);
  border: 1px solid var(--color-gray-1100);
  cursor: pointer;
  transition: all var(--transition-fast);
  pointer-events: auto;
  backdrop-filter: blur(4px);
}

.prevButton {
  margin-right: auto;
}

.nextButton {
  margin-left: auto;
}

.navButton:hover {
  background-color: var(--color-gray-1100);
  border-color: var(--color-gray-1000);
}

.navButton:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.navIcon {
  width: 24px;
  height: 24px;
  color: var(--color-white);
}

.slideCounter {
  display: none;
  background: rgba(20, 21, 26, 0.8);
  border-radius: var(--border-radius-md);
  color: var(--color-gray-400);
  padding: 8px 12px;
  font-family: var(--font-mono);
  font-size: var(--font-size-sm);
  backdrop-filter: blur(4px);
}

@media (max-width: 1024px) {
  .navButton {
    width: 40px;
    height: 40px;
  }

  .navIcon {
    width: 20px;
    height: 20px;
  }
}

@media (max-width: 768px) {
  .navButton {
    width: 36px;
    height: 36px;
  }

  .navIcon {
    width: 18px;
    height: 18px;
  }
}

@media (max-width: 480px) {
  .navButton {
    width: 32px;
    height: 32px;
  }

  .navIcon {
    width: 16px;
    height: 16px;
  }
}