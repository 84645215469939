/* // StackedBarChart.module.css */
.stackedBarChartContainer {
    width: 100%;
    background: var(--color-white);
    border-radius: var(--border-radius-lg);
    padding: var(--spacing-lg);
    box-shadow: var(--shadow-card);
}

.tooltip {
    background-color: var(--color-white);
    border: 1px solid var(--color-border);
    padding: var(--spacing-md);
    border-radius: var(--border-radius-md);
    box-shadow: var(--shadow-lg);
    min-width: 180px;
}

.tooltipCategory {
    font-family: var(--font-primary);
    font-weight: var(--font-weight-semibold);
    margin-bottom: var(--spacing-sm);
    color: var(--text-primary);
    font-size: var(--font-size-sm);
    line-height: var(--line-height-snug);
    padding-bottom: var(--spacing-xs);
    border-bottom: 1px solid var(--color-border);
}

.tooltipRow {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: var(--spacing-sm);
    margin: var(--spacing-xxs) 0;
    padding: var(--spacing-xs) 0;
}

.tooltipLabel {
    font-size: var(--font-size-sm);
    font-weight: var(--font-weight-medium);
    display: flex;
    align-items: center;
    gap: var(--spacing-xs);
}

.tooltipLabel::before {
    content: "";
    display: inline-block;
    width: 8px;
    height: 8px;
    border-radius: var(--border-radius-full);
}

.tooltipValue {
    font-family: var(--font-primary);
    font-size: var(--font-size-sm);
    font-weight: var(--font-weight-semibold);
    color: var(--text-primary);
}

.legendContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: var(--spacing-lg);
    padding: var(--spacing-lg) var(--spacing-md);
    margin-top: var(--spacing-md);
    border-top: 1px solid var(--color-border);
}

.legendItem {
    display: flex;
    align-items: center;
    gap: var(--spacing-sm);
    padding: var(--spacing-xs) var(--spacing-sm);
    border-radius: var(--border-radius-md);
    transition: background-color var(--transition-fast);
    cursor: pointer;
}

.legendItem:hover {
    background-color: var(--bg-hover);
}

.legendColor {
    width: 16px;
    height: 16px;
    border-radius: var(--border-radius-full);
    position: relative;
}

.legendColor::after {
    content: "";
    position: absolute;
    top: -2px;
    left: -2px;
    right: -2px;
    bottom: -2px;
    border-radius: inherit;
    border: 2px solid transparent;
    transition: border-color var(--transition-fast);
}

.legendItem:hover .legendColor::after {
    border-color: var(--color-border);
}

.legendText {
    font-family: var(--font-primary);
    font-size: var(--font-size-sm);
    color: var(--text-secondary);
    font-weight: var(--font-weight-medium);
    transition: color var(--transition-fast);
}

.legendItem:hover .legendText {
    color: var(--text-primary);
}