/* File: frontend/src/pages/InvestorPitch/components/slides/GlobalHiringSlide/HiringTimelineCard.module.css */

.container {
    background-color: var(--color-gray-1200);
    border-radius: var(--border-radius-lg);
    padding: var(--spacing-xl);
    border: 1px solid var(--color-gray-1100);
    display: flex;
    flex-direction: column;
    gap: var(--spacing-xl);
  }
  
  .phase {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-md);
  }
  
  .phaseHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .period {
    font-family: var(--font-mono);
    font-size: var(--font-size-sm);
    color: var(--text-on-primary);
    font-weight: var(--font-weight-medium);
  }
  
  .hires {
    font-size: var(--font-size-sm);
    color: var(--color-gray-400);
  }
  
  .phaseContent {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-sm);
  }
  
  .phaseName {
    font-size: var(--font-size-base);
    color: var(--text-on-primary);
  }