/* File: frontend/src/pages/SuperAdmin/components/common/SuperAdminSearch/SuperAdminSearch.module.css */

.searchForm {
  flex: 1;
  max-width: 400px;
}

.searchContainer {
  position: relative;
  display: flex;
  align-items: center;
}

.searchIcon {
  position: absolute;
  left: var(--spacing-sm);
  color: var(--text-tertiary);
  font-size: var(--font-size-sm);
}

.searchInput {
  width: 100%;
  padding: var(--spacing-sm) var(--spacing-sm) var(--spacing-sm) var(--spacing-xl);
  border: 1px solid var(--color-border);
  border-radius: var(--border-radius-md);
  font-size: var(--font-size-sm);
  color: var(--text-primary);
  background-color: var(--bg-primary);
  transition: border-color var(--transition-fast), box-shadow var(--transition-fast);
}

.searchInput:focus {
  outline: none;
  border-color: var(--color-primary);
  box-shadow: 0 0 0 2px var(--color-primary-100);
}

.clearButton {
  position: absolute;
  right: var(--spacing-sm);
  background: none;
  border: none;
  color: var(--text-tertiary);
  cursor: pointer;
  font-size: var(--font-size-sm);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  transition: background-color var(--transition-fast);
}

.clearButton:hover {
  background-color: var(--bg-secondary);
  color: var(--text-secondary);
}

@media (max-width: 768px) {
  .searchForm {
    max-width: 100%;
  }
}