/* File: frontend/src/components/Avatar/Avatar.module.css */

:root {
  --avatar-size-small: 24px;
  --avatar-size-medium: 32px;
  --avatar-size-large: 64px;
  --avatar-border-radius-small: 4px;
  --avatar-border-radius-medium: 6px;
  --avatar-border-radius-large: 8px;
  --avatar-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  --color-white: #ffffff;
}

.avatar {
  position: relative;
  display: inline-block;
  overflow: hidden;
  background-color: var(--color-white);
}

.avatar-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.avatar-small {
  width: var(--avatar-size-small);
  height: var(--avatar-size-small);
  border-radius: var(--avatar-border-radius-small);
}

.avatar-medium {
  width: var(--avatar-size-medium);
  height: var(--avatar-size-medium);
  border-radius: var(--avatar-border-radius-medium);
}

.avatar-large {
  width: var(--avatar-size-large);
  height: var(--avatar-size-large);
  border-radius: var(--avatar-border-radius-large);
}

.avatar-privacy {
  box-shadow: var(--avatar-shadow);
}

.privacy-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.avatar-small .privacy-icon {
  width: 10px;
  height: 10px;
}

.avatar-medium .privacy-icon {
  width: 14px;
  height: 14px;
}

.avatar-large .privacy-icon {
  width: 24px;
  height: 24px;
}

@media (max-width: 768px) {
  .avatar-large {
    width: 48px;
    height: 48px;
  }
}