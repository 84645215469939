/* File: frontend/src/pages/SuperAdmin/components/common/SuperAdminBreadcrumbs/SuperAdminBreadcrumbs.module.css */

.breadcrumbs {
  display: flex;
  align-items: center;
  padding: var(--spacing-sm) 0;
  font-size: var(--font-size-sm);
  color: var(--text-secondary);
}

.crumbLink {
  color: var(--color-primary);
  text-decoration: none;
  transition: color var(--transition-fast);
}

.crumbLink:hover {
  color: var(--color-primary-dark);
  text-decoration: underline;
}

.currentCrumb {
  color: var(--text-primary);
  font-weight: var(--font-weight-medium);
}

.separator {
  margin: 0 var(--spacing-xs);
  color: var(--text-tertiary);
}