/* File: frontend/src/styles/themes/default.css */

:root {
    /* Theme colors */
    --theme-primary: var(--color-primary);
    --theme-secondary: var(--color-secondary);
    --theme-accent: var(--color-tertiary);
    
    --theme-background: var(--color-white);
    --theme-surface: var(--bg-secondary);
    
    --theme-text-primary: var(--text-primary);
    --theme-text-secondary: var(--text-secondary);
    
    --theme-border: var(--color-light-gray);
    
    /* Theme-specific component styles */
    --theme-button-primary-bg: var(--theme-primary);
    --theme-button-primary-text: var(--color-white);
    
    --theme-input-border: var(--theme-border);
    --theme-input-focus: var(--theme-primary);
    
    /* Add more theme variables as needed */
  }
  
  /* You can add a dark theme by creating a new class */
  .theme-dark {
    --theme-background: var(--color-dark-gray);
    --theme-surface: var(--color-gray);
    --theme-text-primary: var(--color-white);
    --theme-text-secondary: var(--color-light-gray);
    
    /* Adjust other theme variables for dark mode */
  }