/* File: frontend/src/pages/AutoInsight/VehicleDetail/components/VehicleImages/VehicleImages.module.css */

.container {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-md);
}

.mainImageContainer {
  width: 100%;
  height: 400px;
  border-radius: var(--border-radius-md);
  overflow: hidden;
  background-color: var(--bg-secondary);
  display: flex;
  justify-content: center;
  align-items: center;
}

.mainImage {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.thumbnailsContainer {
  display: flex;
  gap: var(--spacing-sm);
  overflow-x: auto;
  padding-bottom: var(--spacing-sm);
}

.thumbnail {
  width: 100px;
  height: 100px;
  border-radius: var(--border-radius-sm);
  overflow: hidden;
  cursor: pointer;
  border: 2px solid transparent;
  transition: border-color var(--transition-fast);
}

.thumbnail:hover {
  border-color: var(--color-border-hover);
}

.activeThumbnail {
  border-color: var(--color-primary);
}

.thumbnailImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.uploadButton {
  padding: var(--button-padding-small);
  background-color: var(--button-secondary-bg);
  color: var(--button-secondary-text);
  border: 1px solid var(--button-secondary-border);
  border-radius: var(--button-border-radius-md);
  font-size: var(--button-font-size-small);
  font-weight: var(--font-weight-medium);
  cursor: pointer;
  transition: background-color var(--transition-fast);
  white-space: nowrap;
}

.uploadButton:hover {
  background-color: var(--button-secondary-hover-bg);
}

.noImages {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: var(--spacing-xl);
  text-align: center;
  color: var(--text-secondary);
  background-color: var(--bg-secondary);
  border-radius: var(--border-radius-md);
  min-height: 300px;
}

.imageDetails {
  background-color: var(--bg-primary);
  border-radius: var(--border-radius-md);
  border: 1px solid var(--color-border);
  overflow: hidden;
  margin-top: var(--spacing-md);
}

.imageDetailsTitle {
  font-size: var(--font-size-md);
  font-weight: var(--font-weight-semibold);
  padding: var(--spacing-sm);
  background-color: var(--bg-secondary);
  border-bottom: 1px solid var(--color-border);
  margin: 0;
}

.imageDetailsContent {
  padding: var(--spacing-md);
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: var(--spacing-md);
}

.infoGroup {
  display: flex;
  flex-direction: column;
}

.infoLabel {
  font-weight: var(--font-weight-medium);
  color: var(--text-secondary);
  margin-bottom: var(--spacing-xxs);
}

.infoValue {
  color: var(--text-primary);
}

/* Report container styles */
.reportContainer {
  padding: var(--spacing-md);
  border-top: 1px solid var(--color-border);
}

.toggleReportButton {
  padding: var(--button-padding-small);
  background-color: var(--color-primary);
  color: white;
  border: none;
  border-radius: var(--border-radius-md);
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-medium);
  cursor: pointer;
  transition: background-color var(--transition-fast);
}

.toggleReportButton:hover {
  background-color: var(--color-primary-dark);
}

.detailedReport {
  margin-top: var(--spacing-md);
  padding: var(--spacing-md);
  background-color: var(--bg-secondary);
  border-radius: var(--border-radius-md);
  max-height: 500px;
  overflow-y: auto;
}

.reportTitle {
  margin-top: 0;
  margin-bottom: var(--spacing-md);
  font-size: var(--font-size-lg);
  font-weight: var(--font-weight-semibold);
}

.reportContent {
  font-family: monospace;
  white-space: pre-wrap;
  margin: 0;
  line-height: 1.5;
  font-size: var(--font-size-sm);
  overflow-x: auto;
}

@media (max-width: 768px) {
  .mainImageContainer {
    height: 300px;
  }
  
  .imageDetailsContent {
    grid-template-columns: 1fr;
  }
  
  .detailedReport {
    max-height: 400px;
  }
}