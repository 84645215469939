/* File: frontend/src/components/form/Dropdown/Dropdown.module.css */

.dropdown {
  position: relative;
  width: 100%;
  font-family: var(--font-primary);
  font-size: var(--font-size-base);
  color: var(--dropdown-text-color);
  z-index: var(--z-index-dropdown);
}

.dropdown.open {
  z-index: calc(var(--z-index-dropdown) + 1);
}

/* Selected/Trigger element */
.selected {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--dropdown-padding);
  border-radius: var(--dropdown-border-radius);
  background-color: var(--dropdown-background);
  border: 1px solid var(--dropdown-border-color);
  box-shadow: var(--dropdown-box-shadow);
  cursor: pointer;
  transition: all var(--transition-fast);
  z-index: var(--z-index-dropdown);
  min-height: var(--dropdown-height);
}

/* Multiple selection styles */
.dropdown.multiple .selected {
  flex-wrap: wrap;
  gap: var(--spacing-xs);
  padding: var(--dropdown-padding);
  min-height: var(--dropdown-height);
  /* min-height: calc(var(--dropdown-height) + var(--spacing-xs)); */
}

.dropdown.multiple .selected > span {
  display: flex;
  flex-wrap: wrap;
  gap: var(--spacing-xs);
  flex: 1;
}

.selectedTag {
  display: inline-flex;
  align-items: center;
  background: var(--bg-tertiary);
  padding: var(--spacing-xs) var(--spacing-sm);
  border-radius: var(--border-radius-sm);
  font-size: var(--font-size-sm);
  gap: var(--spacing-xs);
}

.selectedTag:hover {
  background: var(--bg-secondary);
}

/* Chevron icon */
.chevron {
  width: var(--dropdown-chevron-size);
  height: var(--dropdown-chevron-size);
  transition: transform var(--transition-fast);
  flex-shrink: 0;
  margin-left: var(--spacing-xs);
}

.open .chevron {
  transform: rotate(180deg);
}

/* Options panel */
.options {
  position: absolute;
  left: 0;
  width: 100%;
  background-color: var(--dropdown-background);
  border: 1px solid var(--dropdown-border-color);
  border-radius: var(--dropdown-popover-border-radius);
  box-shadow: var(--dropdown-popover-box-shadow);
  z-index: var(--z-index-dropdown);
  max-height: 200px;
  overflow-y: auto;
}

/* Directional styles */
.dropdown.down .options {
  top: 100%;
  margin-top: var(--spacing-xs);
}

.dropdown.up .options {
  bottom: 100%;
  margin-bottom: var(--spacing-xs);
}

/* Option items */
.option {
  padding: var(--dropdown-item-padding);
  cursor: pointer;
  transition: background-color var(--transition-fast);
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.option:hover {
  background-color: var(--dropdown-item-hover-background);
}

.option.selected {
  font-weight: var(--font-weight-medium);
  background-color: var(--bg-tertiary);
}

/* .option.selected::after {
  content: '';
  width: 16px;
  height: 16px;
  background: url('../../../../public/icons/18/misc/check.svg') no-repeat center;
  opacity: 0.8;
} */

/* Category headers in options */
.categoryHeader {
  padding: var(--dropdown-item-padding);
  font-weight: var(--font-weight-medium);
  color: var(--text-secondary);
  background-color: var(--bg-secondary);
  pointer-events: none;
}

/* Hover and focus states */
.dropdown:not(.disabled):hover .selected,
.dropdown:not(.disabled).open .selected {
  background-color: var(--dropdown-hover-background);
  border-color: var(--color-gray-1200);
  box-shadow: var(--dropdown-focus-box-shadow);
}

.dropdown:not(.disabled):focus-within .selected {
  border-color: var(--dropdown-focus-border-color);
  box-shadow: var(--dropdown-focus-box-shadow);
  margin: var(--spacing-xxs) 0;
}

/* Disabled state */
.disabled .selected {
  background-color: var(--dropdown-disabled-background);
  color: var(--dropdown-disabled-text-color);
  cursor: not-allowed;
}

/* Standalone variant */
.standalone .selected {
  background-color: var(--color-gray-0);
  color: var(--color-gray-1200);
  border-color: var(--color-gray-200);
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

/* Language picker variant */
.language-picker .selected {
  border: none;
  padding: var(--spacing-xs) var(--spacing-sm);
  font-size: var(--font-size-sm);
}

.language-picker .options {
  background-color: var(--color-gray-800);
  border-color: var(--color-gray-700);
}

.language-picker .option {
  color: var(--color-gray-500);
  padding: var(--spacing-xs) var(--spacing-sm);
  font-size: var(--font-size-sm);
}

.language-picker .option:hover {
  background-color: var(--color-gray-700);
}

.language-picker .option.selected {
  background-color: var(--color-gray-600);
}

/* Mobile styles */
@media (max-width: 768px) {
  .options {
    position: fixed;
    top: auto;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    max-height: 50vh;
    margin: 0;
    border-radius: var(--border-radius-lg) var(--border-radius-lg) 0 0;
    z-index: var(--z-index-modal);
    background-color: var(--bg-primary);
    padding-bottom: env(safe-area-inset-bottom);
  }

  .option {
    padding: var(--spacing-md);
    font-size: var(--font-size-base);
    /* border-bottom: 1px solid var(--color-border); */
  }

  .option:last-child {
    border-bottom: none;
  }

  /* Override directional styles on mobile */
  .dropdown.up .options,
  .dropdown.down .options {
    bottom: 0;
    top: auto;
    margin: 0;
  }

  .language-picker .options {
    padding: var(--spacing-md);
    background-color: var(--color-gray-800);
    border-color: var(--color-gray-700);
  }
}