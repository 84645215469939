/* File: frontend/src/components/DrivingScore/TopDrivers.module.css */

.riskLevelGroup {
    display: flex;
    align-items: center;
    gap: 8px;
  }
  
  .riskLevelDotStatus {
    width: 8px;
    height: 8px;
  }
  
  .riskCategoryText {
    font-size: 14px;
    color: #1f2228;
  }
  
  .badgeStatus {
    display: inline-flex;
    padding: 6px 8px;
    border-radius: 8px;
    background-color: #effaf6;
  }
  
  .badgeText {
    font-size: 12px;
    font-weight: 600;
    color: #38c793;
  }
  
  .countryBadge {
    display: inline-flex;
    padding: 7px 8px;
    border-radius: 8px;
    border: 1px solid #e9eaec;
  }
  
  .countryText {
    font-size: 14px;
    color: #1f2228;
  }