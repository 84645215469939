/* File: frontend/src/components/DrivingScore/DrivingScoreTable.module.css */

.tableDescription {
    font-size: 13px;
    color: #7e869a;
    margin-bottom: 16px;
  }
  
  .frameParent {
    border-radius: 14px;
    background-color: #f7f7f8;
    padding: 0 6px 6px 0;
  }
  
  .frameGroup {
    display: flex;
    justify-content: space-between;
    padding: 32px 0;
  }
  
  .frameWrapper {
    padding: 6px;
  }
  
  .decileWrapper {
    display: flex;
    justify-content: center;
  }
  
  .decile {
    font-weight: 500;
    line-height: 18px;
  }
  
  .frameDiv {
    display: flex;
    flex-direction: column;
  }
  
  .frameParent1 {
    display: flex;
    justify-content: space-between;
    padding: 10px;
  }
  
  .frameParent2 {
    background-color: #fff;
    border-radius: 10px;
    overflow: hidden;
  }
  
  .frameParent3 {
    display: flex;
  }
  
  .frameParent4 {
    flex: 1;
    display: flex;
  }
  
  .instanceParent {
    flex: 1;
    display: flex;
    flex-direction: column;
  }
  
  .scoreWrapper, .tableItem {
    border: 1px solid #f7f7f8;
    padding: 6px 4px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .widgetTableItem {
    flex: 1;
    border: 1px solid #f7f7f8;
    padding: 10px 8px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .riskLevelGroup {
    display: flex;
    align-items: center;
    gap: 8px;
  }
  
  .riskLevelDotStatus {
    width: 6px;
    height: 6px;
  }