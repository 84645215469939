/* File: frontend/src/components/form/PhoneInput/PhoneInput.module.css */

.phoneInputContainer {
  display: flex;
  align-items: stretch;
  width: 100%;
  position: relative;
  z-index: 4;
}
.countryCodeWrapper {
  width: 120px;
  flex-shrink: 0;
  position: relative;
  z-index: 5;
}

.numberWrapper {
  display: flex;
  flex-grow: 1;
  border: 1px solid var(--input-border-color);
  border-left: none;
  border-radius: 0 var(--input-border-radius) var(--input-border-radius) 0;
  background-color: var(--input-background);
}

.area_code,
.phone_number {
  padding: 0 var(--spacing-md);
  /* height: 44px; */
  font-family: var(--font-primary);
  font-size: var(--font-size-base);
  color: var(--input-text-color);
  border: none;
  background: none;
  width: auto;
}

.area_code {
  width: 30%;
  border-right: 1px solid var(--input-border-color);
}

.phone_number {
  width: 70%;
}

.area_code:focus,
.phone_number:focus {
  outline: none;
}

.numberWrapper:focus-within {
  border-color: var(--input-focus-border-color);
  box-shadow: var(--input-focus-box-shadow);
}

@media (max-width: 768px) {
  .phoneInputContainer {
    flex-direction: column;
  }

  .countryCodeWrapper,
  .numberWrapper {
    width: 100%;
  }

  .area_code,
  .phone_number {
  height: 44px;
  }

  .numberWrapper {
    border-left: 1px solid var(--input-border-color);
    border-top: none;
    border-radius: 0 0 var(--input-border-radius) var(--input-border-radius);
  }
}