/* File: frontend/src/components/Sidebar/SidebarNavItem.module.css */

.navItem {
  position: relative;
}

.navLink {
  display: flex;
  align-items: center;
  padding: var(--spacing-sm);
  text-decoration: none;
  color: var(--color-gray-500);
  border-radius: var(--border-radius-md);
  transition: all var(--transition-normal);
}

.iconWrapper {
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}

.icon {
  width: 20px;
  height: 20px;
  transition: transform var(--transition-normal);
}

.navContent {
  margin-left: var(--spacing-sm);
  flex: 1;
  min-width: 0; /* Ensures text truncation works */
}

.label {
  display: block;
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-medium);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.description {
  display: block;
  font-size: var(--font-size-xs);
  color: var(--color-gray-600);
  margin-top: 2px;
}

/* Hover and active states */
.navLink:hover {
  background-color: var(--color-gray-1200);
  color: var(--color-gray-300);
}

.navLink:hover .icon {
  transform: scale(1.1);
}

.active .navLink {
  color: var(--color-primary-700);
  background-color: var(--color-gray-1200);
}

.active .icon {
  color: var(--color-primary-700);
}

@media (max-width: 768px) {
  .navLink {
    padding: var(--spacing-sm);
  }
}