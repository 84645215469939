/* File: frontend/src/pages/SuperAdmin/components/CommunicationManagement/EmailTemplates/EmailTemplates.module.css */

.container {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-md);
}

.toolbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: var(--spacing-md);
}

.filters {
  display: flex;
  align-items: center;
  gap: var(--spacing-md);
  flex: 1;
}

.filterGroup {
  display: flex;
  align-items: center;
  gap: var(--spacing-xs);
}

.filterLabel {
  font-size: var(--font-size-sm);
  color: var(--text-secondary);
}

.filterSelect {
  padding: var(--spacing-xs) var(--spacing-sm);
  border: 1px solid var(--color-border);
  border-radius: var(--border-radius-sm);
  background-color: var(--bg-primary);
  color: var(--text-primary);
  font-size: var(--font-size-sm);
}

.createButton {
  display: flex;
  align-items: center;
  gap: var(--spacing-xs);
  padding: var(--spacing-sm) var(--spacing-md);
  background-color: var(--color-primary);
  color: white;
  border: none;
  border-radius: var(--border-radius-md);
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-medium);
  cursor: pointer;
  transition: background-color var(--transition-fast);
}

.createButton:hover {
  background-color: var(--color-primary-dark);
}

.error {
  padding: var(--spacing-md);
  background-color: var(--color-error-bg);
  color: var(--color-error);
  border-radius: var(--border-radius-md);
  margin-bottom: var(--spacing-md);
}

.pagination {
  display: flex;
  justify-content: center;
  margin-top: var(--spacing-md);
}

.nameCell {
  font-weight: var(--font-weight-medium);
  color: var(--color-primary);
}

.subjectCell {
  color: var(--text-secondary);
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.statusBadge, .categoryBadge {
  display: inline-block;
  padding: 2px 8px;
  border-radius: 12px;
  font-size: var(--font-size-xs);
  font-weight: var(--font-weight-medium);
  text-transform: capitalize;
}

.statusActive {
  background-color: var(--color-success-bg);
  color: var(--color-success);
}

.statusInactive {
  background-color: var(--color-error-bg);
  color: var(--color-error);
}

.categoryGeneral {
  background-color: var(--color-primary-100);
  color: var(--color-primary);
}

.categoryOnboarding {
  background-color: var(--color-success-bg);
  color: var(--color-success);
}

.categoryAccount {
  background-color: var(--color-info-bg);
  color: var(--color-info);
}

.categoryBilling {
  background-color: var(--color-warning-bg);
  color: var(--color-warning);
}

.categoryMarketing {
  background-color: var(--color-error-bg);
  color: var(--color-error);
}

.actions {
  display: flex;
  gap: var(--spacing-xs);
}

.actionButton {
  display: flex;
  align-items: center;
  gap: var(--spacing-xxs);
  padding: var(--spacing-xs) var(--spacing-sm);
  background-color: var(--bg-secondary);
  border: 1px solid var(--color-border);
  border-radius: var(--border-radius-sm);
  font-size: var(--font-size-xs);
  color: var(--text-primary);
  cursor: pointer;
  transition: all var(--transition-fast);
}

.actionButton:hover {
  background-color: var(--bg-hover);
  color: var(--color-primary);
  border-color: var(--color-primary);
}

/* Template Detail Styles */
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: var(--spacing-md);
}

.backButton {
  display: flex;
  align-items: center;
  gap: var(--spacing-xs);
  padding: var(--spacing-sm) var(--spacing-md);
  background-color: var(--bg-secondary);
  color: var(--text-primary);
  border: 1px solid var(--color-border);
  border-radius: var(--border-radius-md);
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-medium);
  cursor: pointer;
  transition: all var(--transition-fast);
}

.backButton:hover:not(:disabled) {
  background-color: var(--bg-hover);
}

.backButton:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.headerActions {
  display: flex;
  gap: var(--spacing-sm);
}

.editButton, .deleteButton {
  display: flex;
  align-items: center;
  gap: var(--spacing-xs);
  padding: var(--spacing-sm) var(--spacing-md);
  border-radius: var(--border-radius-md);
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-medium);
  cursor: pointer;
  transition: all var(--transition-fast);
}

.editButton {
  background-color: var(--color-primary);
  color: white;
  border: none;
}

.editButton:hover {
  background-color: var(--color-primary-dark);
}

.deleteButton {
  background-color: var(--color-error-bg);
  color: var(--color-error);
  border: 1px solid var(--color-error);
}

.deleteButton:hover {
  background-color: var(--color-error);
  color: white;
}

.templateDetail {
  background-color: var(--bg-primary);
  border-radius: var(--border-radius-md);
  padding: var(--spacing-md);
  border: 1px solid var(--color-border);
}

.templateHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: var(--spacing-md);
  padding-bottom: var(--spacing-sm);
  border-bottom: 1px solid var(--color-border-light);
}

.templateName {
  font-size: var(--font-size-xl);
  font-weight: var(--font-weight-bold);
  color: var(--text-primary);
  margin: 0;
}

.templateMeta {
  display: flex;
  gap: var(--spacing-sm);
}

.templateDescription {
  margin-bottom: var(--spacing-lg);
  line-height: 1.6;
  color: var(--text-secondary);
}

.section {
  margin-bottom: var(--spacing-lg);
}

.section:last-child {
  margin-bottom: 0;
}

.sectionTitle {
  font-size: var(--font-size-lg);
  font-weight: var(--font-weight-semibold);
  color: var(--text-primary);
  margin: 0 0 var(--spacing-md) 0;
  padding-bottom: var(--spacing-xs);
  border-bottom: 1px solid var(--color-border-light);
}

.subjectLine {
  font-size: var(--font-size-base);
  color: var(--text-primary);
  padding: var(--spacing-md);
  background-color: var(--bg-secondary);
  border-radius: var(--border-radius-md);
  border: 1px solid var(--color-border-light);
}

.htmlPreview {
  padding: var(--spacing-md);
  background-color: var(--bg-secondary);
  border-radius: var(--border-radius-md);
  border: 1px solid var(--color-border-light);
  overflow: auto;
}

.textPreview {
  padding: var(--spacing-md);
  background-color: var(--bg-secondary);
  border-radius: var(--border-radius-md);
  border: 1px solid var(--color-border-light);
  white-space: pre-wrap;
  font-family: var(--font-family-mono);
  font-size: var(--font-size-sm);
}

.variablesList {
  display: flex;
  flex-wrap: wrap;
  gap: var(--spacing-sm);
}

.variableBadge {
  display: inline-block;
  padding: var(--spacing-xs) var(--spacing-sm);
  background-color: var(--color-primary-100);
  color: var(--color-primary);
  border-radius: var(--border-radius-sm);
  font-family: var(--font-family-mono);
  font-size: var(--font-size-sm);
}

.infoGrid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: var(--spacing-md);
}

.infoItem {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xxs);
}

.infoLabel {
  font-size: var(--font-size-sm);
  color: var(--text-secondary);
  font-weight: var(--font-weight-medium);
}

.infoValue {
  color: var(--text-primary);
}

/* Form Styles */
.form {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-lg);
}

.formSection {
  background-color: var(--bg-primary);
  border-radius: var(--border-radius-md);
  border: 1px solid var(--color-border);
  padding: var(--spacing-md);
}

.formGrid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: var(--spacing-md);
}

.formGroup {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xs);
}

.formGroupFull {
  grid-column: 1 / -1;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xs);
}

.label {
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-medium);
  color: var(--text-secondary);
}

.required {
  color: var(--color-error);
}

.input, .select, .textarea {
  padding: var(--spacing-sm);
  border: 1px solid var(--color-border);
  border-radius: var(--border-radius-md);
  font-size: var(--font-size-base);
  color: var(--text-primary);
  background-color: var(--bg-primary);
  transition: border-color var(--transition-fast), box-shadow var(--transition-fast);
}

.input:focus, .select:focus, .textarea:focus {
  outline: none;
  border-color: var(--color-primary);
  box-shadow: 0 0 0 2px var(--color-primary-100);
}

.input:disabled, .select:disabled, .textarea:disabled {
  background-color: var(--bg-secondary);
  cursor: not-allowed;
}

.textarea {
  resize: vertical;
  min-height: 80px;
  font-family: inherit;
}

.helpText {
  font-size: var(--font-size-xs);
  color: var(--text-tertiary);
  margin-top: var(--spacing-xxs);
}

.formActions {
  display: flex;
  justify-content: flex-end;
  gap: var(--spacing-md);
  margin-top: var(--spacing-md);
}

.cancelButton, .submitButton {
  padding: var(--spacing-sm) var(--spacing-lg);
  border-radius: var(--border-radius-md);
  font-size: var(--font-size-base);
  font-weight: var(--font-weight-medium);
  cursor: pointer;
  transition: all var(--transition-fast);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: var(--spacing-xs);
  min-width: 120px;
}

.cancelButton {
  background-color: var(--bg-secondary);
  color: var(--text-primary);
  border: 1px solid var(--color-border);
}

.cancelButton:hover:not(:disabled) {
  background-color: var(--bg-hover);
}

.submitButton {
  background-color: var(--color-primary);
  color: white;
  border: none;
}

.submitButton:hover:not(:disabled) {
  background-color: var(--color-primary-dark);
}

.cancelButton:disabled, .submitButton:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.spinner {
  display: inline-block;
  width: 16px;
  height: 16px;
  border: 2px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top-color: white;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@media (max-width: 992px) {
  .formGrid {
    grid-template-columns: 1fr;
  }
  
  .infoGrid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .toolbar {
    flex-direction: column;
    align-items: stretch;
    gap: var(--spacing-sm);
  }
  
  .filters {
    width: 100%;
    flex-direction: column;
    align-items: stretch;
  }
  
  .createButton {
    width: 100%;
    justify-content: center;
  }
  
  .header {
    flex-direction: column;
    gap: var(--spacing-sm);
  }
  
  .backButton {
    width: 100%;
    justify-content: center;
  }
  
  .headerActions {
    width: 100%;
    flex-direction: column;
  }
  
  .editButton, .deleteButton {
    width: 100%;
    justify-content: center;
  }
  
  .infoGrid {
    grid-template-columns: 1fr;
  }
  
  .formActions {
    flex-direction: column;
  }
  
  .cancelButton, .submitButton {
    width: 100%;
  }
}