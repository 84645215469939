.container {
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xl);
}

.header {
  text-align: center;
  margin-bottom: var(--spacing-lg);
}

.title {
  font-size: var(--font-size-4xl);
  font-weight: var(--font-weight-bold);
  color: var(--text-on-primary);
  margin-bottom: var(--spacing-md);
  line-height: var(--line-height-tight);
}

.subtitle {
  font-size: var(--font-size-xl);
  color: var(--color-gray-400);
  line-height: var(--line-height-relaxed);
}

.chartContainer {
  flex-grow: 1;
  min-height: 400px;
}

.tooltip {
  background-color: var(--bg-primary);
  border: 1px solid var(--color-border);
  border-radius: var(--border-radius-md);
  padding: var(--spacing-sm);
  box-shadow: var(--shadow-md);
}

.tooltipTitle {
  font-weight: var(--font-weight-semibold);
  margin-bottom: var(--spacing-xs);
  color: var(--text-primary);
}

.tooltipRow {
  display: grid;
  grid-template-columns: auto auto auto;
  gap: var(--spacing-sm);
  margin-bottom: var(--spacing-xxs);
  color: var(--text-secondary);
}

.tooltipTotal {
  display: flex;
  justify-content: space-between;
  margin-top: var(--spacing-xs);
  padding-top: var(--spacing-xs);
  border-top: 1px solid var(--color-border);
  font-weight: var(--font-weight-semibold);
  color: var(--text-primary);
}

.summary {
  display: flex;
  justify-content: center;
  margin-top: var(--spacing-xl);
}

.summaryCard {
  text-align: center;
  padding: var(--spacing-lg);
  background-color: var(--bg-secondary);
  border-radius: var(--border-radius-lg);
}

.summaryCard h3 {
  font-size: var(--font-size-lg);
  color: var(--text-secondary);
  margin-bottom: var(--spacing-xs);
}

.savingsAmount {
  display: block;
  font-size: var(--font-size-3xl);
  font-weight: var(--font-weight-bold);
  color: var(--color-green-310);
  margin-bottom: var(--spacing-xs);
}

@media (max-width: var(--breakpoint-md)) {
  .title {
    font-size: var(--font-size-3xl);
  }

  .subtitle {
    font-size: var(--font-size-lg);
  }

  .chartContainer {
    min-height: 300px;
  }
}