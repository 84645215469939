/* File: frontend/src/pages/InvestorPitch/components/slides/AI1Engineering/AI1Engineering.module.css */

.container {
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-lg);
  padding: var(--spacing-md);
  width: 100%;
}

.header {
  text-align: center;
  margin-bottom: var(--spacing-md);
}

.title {
  font-size: var(--font-size-4xl);
  font-weight: var(--font-weight-bold);
  color: var(--text-on-primary);
  margin-bottom: var(--spacing-md);
  line-height: 1.1;
}

.subtitle {
  font-size: var(--font-size-xl);
  color: var(--color-gray-400);
  max-width: 80%;
  margin: 0 auto;
  line-height: 1.3;
}

.metricsGrid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: var(--spacing-lg);
  width: 100%;
  margin: 0 auto;
  max-width: 1200px;
}

.componentCard {
  background: var(--color-gray-1200);
  border-radius: var(--border-radius-lg);
  border: 1px solid var(--color-gray-1100);
  padding: var(--spacing-lg);
  display: flex;
  flex-direction: column;
  gap: var(--spacing-md);
  position: relative;
  overflow: hidden;
  height: 100%;
}

.componentCard::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 4px;
  height: 100%;
  background: var(--component-color);
}

.componentHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* margin-bottom: var(--spacing-md); */
  flex-wrap: wrap;
  gap: var(--spacing-xs);
}

.componentName {
  font-size: var(--font-size-xl);
  font-weight: var(--font-weight-bold);
  color: var(--component-color);
  line-height: 1.2;
  text-transform: capitalize;
}

.metricsContainer {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: var(--spacing-md) var(--spacing-lg);
  flex: 1;
}

.metric {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xxs);
}

.metricLabel {
  font-size: var(--font-size-sm);
  color: var(--color-gray-400);
  text-transform: capitalize;
}

.metricValue {
  font-size: var(--font-size-md);
  font-weight: var(--font-weight-medium);
  color: var(--text-on-primary);
  word-break: break-word;
}

@media (max-width: 1200px) {
  .metricsGrid {
    gap: var(--spacing-md);
  }
  
  .componentCard {
    padding: var(--spacing-md);
  }
  
  .metricsContainer {
    gap: var(--spacing-sm) var(--spacing-md);
  }
}

@media (max-width: 992px) {
  .title {
    font-size: var(--font-size-3xl);
  }

  .subtitle {
    font-size: var(--font-size-lg);
    max-width: 90%;
  }
  
  .componentName {
    font-size: var(--font-size-lg);
  }
  
  .metricValue {
    font-size: var(--font-size-base);
  }
}

@media (max-width: 768px) {
  .metricsGrid {
    grid-template-columns: 1fr;
    gap: var(--spacing-md);
  }
  
  .container {
    gap: var(--spacing-md);
  }

  .title {
    font-size: var(--font-size-2xl);
    margin-bottom: var(--spacing-sm);
  }

  .subtitle {
    font-size: var(--font-size-md);
    max-width: 100%;
  }
  
  .metricsContainer {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 576px) {
  .componentCard {
    padding: var(--spacing-md) var(--spacing-sm);
  }
  
  .componentName {
    font-size: var(--font-size-base);
  }

  .metricLabel {
    font-size: var(--font-size-xs);
  }
  
  .metricValue {
    font-size: var(--font-size-sm);
  }
}

@media (max-width: 480px) {
  .title {
    font-size: var(--font-size-xl);
  }

  .subtitle {
    font-size: var(--font-size-base);
  }
  
  .metricsContainer {
    grid-template-columns: 1fr;
    gap: var(--spacing-sm);
  }
  
  .metric {
    gap: var(--spacing-xxxs);
  }
}