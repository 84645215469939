/* File: frontend/src/components/ErrorMessage/ErrorMessage.module.css */

.errorContainer {
  display: flex;
  align-items: center;
  background-color: var(--color-error-bg);
  border: 1px solid var(--color-error-border);
  box-shadow: 0px 2px 6px var(--color-error-shadow);
  color: var(--color-error);
  padding: var(--spacing-md);
  border-radius: var(--border-radius-md);
  margin-top: var(--spacing-md);
  font-size: var(--font-size-sm);
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
}

.floating {
  position: fixed;
  top: var(--spacing-lg);
  left: 50%;
  transform: translateX(-50%);
  width: auto;
  max-width: 600px;
  z-index: 1000;
}

.errorIcon {
  flex-shrink: 0;
  width: 18px;
  height: 18px;
  margin-right: var(--spacing-sm);
}

.closeButton {
  background: none;
  border: none;
  cursor: pointer;
  padding: var(--spacing-xxs);
  margin-left: auto;
  transition: opacity var(--transition-fast);
}

.closeButton:hover,
.closeButton:focus {
  opacity: 0.7;
}

.closeButton:focus {
  outline: none;
  box-shadow: var(--focus-ring);
}

.closeIcon {
  width: 12px;
  height: 12px;
}


/* // File: frontend/src/components/ErrorMessage/ErrorMessage.module.css */
/* .errorMessage {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.75rem 1rem;
  border-radius: 4px;
  background-color: var(--color-error-bg, #fff2f0);
  border: 1px solid var(--color-error-border, #ffccc7);
  color: var(--color-error-text, #ff4d4f);
  font-size: 0.875rem;
  line-height: 1.5;
}

.inline {
  width: 100%;
}

.floating {
  position: fixed;
  bottom: 24px;
  left: 50%;
  transform: translateX(-50%);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  z-index: 1000;
  animation: slideUp 0.3s ease-out;
}

.icon {
  flex-shrink: 0;
  font-size: 1rem;
}

.text {
  flex-grow: 1;
}

.closeButton {
  flex-shrink: 0;
  background: none;
  border: none;
  color: inherit;
  cursor: pointer;
  padding: 0 0.25rem;
  font-size: 1.25rem;
  line-height: 1;
  opacity: 0.7;
  transition: opacity 0.2s;
}

.closeButton:hover {
  opacity: 1;
}

@keyframes slideUp {
  from {
    transform: translate(-50%, 100%);
    opacity: 0;
  }
  to {
    transform: translate(-50%, 0);
    opacity: 1;
  }
} */