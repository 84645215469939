/* File: frontend/src/pages/News/News.module.css */

.newsContainer {
    max-width: var(--breakpoint-xl);
    margin: 0 auto;
    padding: clamp(1rem, 5vw, 2rem);
    font-family: var(--font-family-primary);
    color: var(--color-text);
  }
  
  .title {
    font-size: clamp(2rem, 5vw, 2.5rem);
    color: var(--color-primary);
    margin-bottom: clamp(1rem, 3vw, 1.5rem);
  }
  
  .sectionTitle {
    font-size: clamp(1.5rem, 4vw, 1.8rem);
    color: var(--color-primary-dark);
    margin-bottom: clamp(0.75rem, 2vw, 1rem);
  }
  
  .featuredNews {
    margin-bottom: var(--spacing-xl);
  }
  
  .newsGrid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: var(--spacing-lg);
    margin: var(--spacing-xl) 0;
  }
  
  .inquirySection {
    margin-top: var(--spacing-xl);
    padding: var(--spacing-lg);
    background-color: var(--color-background-light);
    border-radius: var(--border-radius-lg);
  }
  
  .inquiryContent {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: var(--spacing-md);
  }
  
  .inquiryEmails p {
    margin: var(--spacing-xs) 0;
  }
  
  .socialLinks {
    display: flex;
    gap: var(--spacing-md);
  }
  
  .socialLinks a {
    color: var(--color-primary);
    text-decoration: none;
    transition: color var(--transition-fast);
  }
  
  .socialLinks a:hover {
    color: var(--color-primary-dark);
  }
  
  @media (max-width: 768px) {
    .inquiryContent {
      flex-direction: column;
    }
  
    .socialLinks {
      margin-top: var(--spacing-md);
    }
  }