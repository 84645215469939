/* File: frontend/src/pages/InvestorPitch/components/slides/SecuritySlide/SecurityFramework.module.css */

.container {
  background-color: var(--color-gray-1200);
  border-radius: var(--border-radius-lg);
  border: 1px solid var(--color-gray-1100);
  overflow: hidden;
  height: 100%;
}

.features {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1px;
  background-color: var(--color-gray-1100);
  height: 100%;
}

.feature {
  background-color: var(--color-gray-1200);
  display: flex;
  flex-direction: column;
  height: 100%;
}

.header {
  padding: var(--spacing-lg);
  text-align: center;
  color: var(--color-gray-0);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.iconWrapper {
  width: 48px;
  height: 48px;
  border-radius: var(--border-radius-full);
  background-color: rgba(255, 255, 255, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: var(--spacing-md);
}

.icon {
  width: 24px;
  height: 24px;
  color: var(--color-white);
}

.title {
  font-size: var(--font-size-lg);
  font-weight: var(--font-weight-bold);
  margin-bottom: var(--spacing-sm);
}

.description {
  font-size: var(--font-size-sm);
  opacity: 0.9;
  line-height: 1.5;
}

.metrics {
  padding: var(--spacing-md);
  display: flex;
  flex-direction: column;
  gap: var(--spacing-sm);
  flex: 1;
}

.metric {
  background-color: var(--color-gray-1100);
  padding: var(--spacing-sm) var(--spacing-md);
  border-radius: var(--border-radius-md);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.metricLabel {
  font-size: var(--font-size-sm);
  color: var(--color-gray-400);
}

.metricValue {
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-bold);
  color: var(--text-on-primary);
}

@media (max-width: 1200px) {
  .features {
    grid-template-columns: repeat(3, 1fr);
  }
  
  .header {
    padding: var(--spacing-md);
  }
  
  .iconWrapper {
    width: 40px;
    height: 40px;
  }
  
  .icon {
    width: 20px;
    height: 20px;
  }
  
  .title {
    font-size: var(--font-size-base);
  }
  
  .description {
    font-size: var(--font-size-xs);
  }
}

@media (max-width: 992px) {
  .features {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .features {
    grid-template-columns: 1fr;
  }
  
  .header {
    padding: var(--spacing-sm);
  }
  
  .metrics {
    padding: var(--spacing-sm);
  }
  
  .metric {
    padding: var(--spacing-xs) var(--spacing-sm);
  }
  
  .metricLabel, .metricValue {
    font-size: var(--font-size-xs);
  }
}