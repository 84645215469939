/* File: frontend/src/pages/InvestorPitch/components/slides/ProductEvolutionSlide/ProductEvolutionSlide.module.css */

.container {
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-lg);
  padding: var(--spacing-md);
  width: 100%;
}

.header {
  text-align: center;
  margin-bottom: var(--spacing-md);
}

.title {
  font-size: var(--font-size-4xl);
  font-weight: var(--font-weight-bold);
  color: var(--text-on-primary);
  margin-bottom: var(--spacing-md);
  background: linear-gradient(135deg, var(--color-primary), var(--color-blue-510));
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}

.subtitle {
  font-size: var(--font-size-xl);
  color: var(--color-gray-400);
  max-width: 800px;
  margin: 0 auto;
}

.content {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xl);
  flex: 1;
  overflow-y: auto;
}

.sectionTitle {
  font-size: var(--font-size-xl);
  font-weight: var(--font-weight-bold);
  color: var(--text-on-primary);
  margin-bottom: var(--spacing-md);
}

.roadmapSection {
  margin-bottom: var(--spacing-lg);
}

.roadmapGrid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: var(--spacing-lg);
}

.roadmapItem {
  height: 100%;
}

.detailsSection {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: var(--spacing-xl);
}

.capabilityWrapper, .featureWrapper {
  height: 100%;
}

@media (max-width: 1200px) {
  .roadmapGrid {
    grid-template-columns: repeat(2, 1fr);
    gap: var(--spacing-md);
  }
  
  .detailsSection {
    grid-template-columns: 1fr;
    gap: var(--spacing-lg);
  }
}

@media (max-width: 992px) {
  .title {
    font-size: var(--font-size-3xl);
  }
  
  .subtitle {
    font-size: var(--font-size-lg);
    max-width: 90%;
  }
  
  .sectionTitle {
    font-size: var(--font-size-lg);
  }
}

@media (max-width: 768px) {
  .roadmapGrid {
    grid-template-columns: 1fr;
  }
  
  .title {
    font-size: var(--font-size-2xl);
  }
  
  .subtitle {
    font-size: var(--font-size-md);
    max-width: 100%;
  }
}

@media (max-width: 480px) {
  .container {
    padding: var(--spacing-sm);
  }
  
  .title {
    font-size: var(--font-size-xl);
  }
  
  .subtitle {
    font-size: var(--font-size-base);
  }
  
  .content {
    gap: var(--spacing-md);
  }
}