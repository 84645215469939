/* File: frontend/src/components/Divider/Divider.module.css */

.divider {
  display: flex;
  align-items: center;
  width: 100%;
  margin: var(--spacing-xxs) 0;
}

.divider__line {
  flex-grow: 1;
  height: var(--divider-thickness);
  background-color: var(--divider-color);
}

.divider__content {
  padding: 0 var(--spacing-md);
  font-size: var(--font-size-sm);
  color: var(--text-secondary);
  white-space: nowrap;
}

@media (min-width: 769px) {
  .divider--vertical {
    flex-direction: column;
    height: auto;
    width: var(--divider-thickness);
    margin: 0 var(--spacing-md);
  }

  .divider--vertical .divider__line {
    width: var(--divider-thickness);
    height: auto;
  }

  .divider--vertical .divider__content {
    padding: var(--spacing-md) 0;
    writing-mode: vertical-rl;
    text-orientation: mixed;
  }
}