/* File: frontend/src/pages/InvestorPitch/components/slides/LeadershipSlide/TrackRecordCard.module.css */

.container {
  background-color: var(--color-gray-1200);
  border-radius: var(--border-radius-lg);
  padding: var(--spacing-lg);
  border: 1px solid var(--color-gray-1100);
  display: flex;
  align-items: flex-start;
  gap: var(--spacing-lg);
}

.iconWrapper {
  width: 48px;
  height: 48px;
  border-radius: var(--border-radius-lg);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}

.icon {
  width: 24px;
  height: 24px;
}

.content {
  flex: 1;
}

.title {
  font-size: var(--font-size-lg);
  font-weight: var(--font-weight-bold);
  color: var(--text-on-primary);
  margin-bottom: var(--spacing-sm);
}

.details {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xs);
}

.detailItem {
  color: var(--color-gray-400);
  font-size: var(--font-size-base);
  line-height: 1.5;
  position: relative;
  padding-left: var(--spacing-md);
}

.detailItem::before {
  content: "•";
  position: absolute;
  left: 0;
  color: var(--color-primary-500);
}

@media (max-width: 992px) {
  .container {
    padding: var(--spacing-md);
    gap: var(--spacing-md);
  }
  
  .title {
    font-size: var(--font-size-base);
  }
  
  .detailItem {
    font-size: var(--font-size-sm);
  }
}

@media (max-width: 768px) {
  .iconWrapper {
    width: 40px;
    height: 40px;
  }
  
  .icon {
    width: 20px;
    height: 20px;
  }
}

@media (max-width: 480px) {
  .container {
    padding: var(--spacing-sm);
    gap: var(--spacing-sm);
  }
  
  .iconWrapper {
    width: 32px;
    height: 32px;
  }
  
  .icon {
    width: 16px;
    height: 16px;
  }
  
  .detailItem {
    font-size: var(--font-size-xs);
    padding-left: var(--spacing-sm);
  }
}